import { useEffect, useMemo, useState, useRef, VFC, useCallback } from "react";
import GenericTemplate from "@template/index";
import { Colors } from "@template/style";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
  Button,
  Typography,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import {
  DraggableProvided,
  DroppableProvided,
  DropResult,
  Draggable,
  Droppable,
  DragDropContext,
} from "react-beautiful-dnd";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import { sort } from "@utils/index";
import TlgtMasterEditDialog, {
  IData,
} from "../TlgtMaster/TlgtMasterEditDialog";
import TlgtMasterMaEditDialog, {
  IMaMasterData,
} from "../TlgtMaster/TlgtMasterMaEditDialog";
import TlgtMasterModelNameEditDialog, {
  IModelNameMasterData,
} from "../TlgtMaster/TlgtMasterModelNameEditDialog";
import {
  insertTlgtMaster,
  updateTlgtMaster,
  getListTlgtMaster,
  deleteTlgtMaster,
  updateSettingSort,
  insertTlgtMaMaster,
  updateTlgtMaMaster,
  insertTlgtModelNameMaster,
  updateTlgtModelNameMaster,
} from "@api/settings";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import SelectLabel from "components/atoms/SelectLabel";
import { TLGT_MASTER_TYPE } from "@shared-constants";
import { getUserInfo } from "@utils/index";
import { getModelNameMaster } from "@api/master";
import InfiniteScroll from "react-infinite-scroller";
import ScrollLoader from "components/atoms/ScrollLoader";

/* MAマスタかどうかを判定するための定数、複数箇所で判定があるのでここで定数として定義 */
const _maName = TLGT_MASTER_TYPE.find(
  (item: { id: string; name: string }) => item.id === "ma_master",
)?.name;

const initial_data: IData = {
  name: "",
  isDefault: false,
};
const initial_ma_data: IMaMasterData = {
  nonconformity_detail: "",
  occurance_process: "",
  process_of_root_cause: "",
  responsibility: "",
  responsibility_name: "",
  department_of_pic: "",
  department_of_pic_name: "",
  process_in_logistics: "",
  process_in_logistics_name: "",
  responsibility_in_process: "",
  responsibility_in_process_name: "",
  classification_of_nonconformity: "",
  classification_of_nonconformity_name: "",
  classification_of_cause: "",
  classification_of_cause_name: "",
  is_default: false,
};

const initial_ModelName_data: IModelNameMasterData = {
  name: "",
  code: "",
  isDefault: false,
};

const TlgtMasterEditScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [data, setData] = useState<any[]>([]);
  const [originData, setOriginData] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [maopen, setMaOpen] = useState<boolean>(false);
  const [ModelNameopen, setModelNameOpen] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [editData, setEditData] = useState<IData>(initial_data);
  const [maEditData, setMaEditData] = useState<IMaMasterData>(initial_ma_data);
  const [ModelNameEditData, setModelNameEditData] =
    useState<IModelNameMasterData>(initial_ModelName_data);
  const [selectDisabled, setSelectDisabled] = useState<boolean>(true);
  const [tlgtMasterTypeId, setTlgtMasterTypeId] = useState<string>("");
  const [tlgtMasterTypeName, settlgtMasterTypeName] = useState<string>("");
  const [listNonconformityDetail, setListNonconformityDetail] = useState<any[]>(
    [],
  );
  const [listNonconformityOverview, setListNonconformityOverview] = useState<
    any[]
  >([]);
  const [listOccuranceProcess, setListOccuranceProcess] = useState<any[]>([]);
  const [listProcessOfRootCause, setListProcessOfRootCause] = useState<any[]>(
    [],
  );
  const [listResponsibility, setListResponsibility] = useState<any[]>([]);
  const [listDepartmentOfPic, setListDepartmentOfPic] = useState<any[]>([]);
  const [listProcessInLogistics, setListProcessInLogistics] = useState<any[]>(
    [],
  );
  const [listResponsibilityInProcess, setListResponsibilityInProcess] =
    useState<any[]>([]);
  const [
    listClassificationOfNonconformity,
    setListClassificationOfNonconformity,
  ] = useState<any[]>([]);
  const [listClassificationOfCause, setListClassificationOfCause] = useState<
    any[]
  >([]);
  const [lastKey, setLastKey] = useState<any | null>(null);
  const [loadMore, setLoadMore] = useState<boolean>(true);
  const [PK, setPK] = useState<string | null>(null);
  const [SK, setSK] = useState<string | null>(null);
  const [masterExtract, setMasterExtract] = useState<string | null>(null);
  const [seq, setSeq] = useState<string | null>(null);
  const cancelFetchRef = useRef<boolean>(false);

  const showLoadMore = useMemo(() => {
    return (
      tlgtMasterTypeName === "Model Name" &&
      loadMore &&
      lastKey &&
      data.length > 0
    );
  }, [data, loadMore, lastKey]);

  const handleDnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const sorted = sort(data, result.source.index, result.destination.index);
    setData(sorted);
  };

  const handleAdd = () => {
    if (tlgtMasterTypeName === "MA") {
      setMaEditData(initial_ma_data);
      setMaOpen(true);
    } else if (tlgtMasterTypeName === "Model Name") {
      setModelNameEditData(initial_ModelName_data);
      setModelNameOpen(true);
    } else {
      setEditData(initial_data);
      setOpen(true);
    }
  };

  const handleDelete = (SK: string) => {
    if (!ModalController.isShowing()) {
      const confirmMessage = disabled
        ? messages.COMMON.MSG_COMMON_DELETE_CONFIRM_001
        : messages.COMMON.MSG_COMMON_DELETE_CONFIRM_SORT_001;
      ModalController.show({
        message: confirmMessage,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => handleDeleteSettings(SK),
      });
    }
    return;
  };

  const handleDeleteSettings = async (SK: string) => {
    try {
      const { contract_id } = getUserInfo();

      LoadingOverlayController.show();
      const res = await deleteTlgtMaster(contract_id, SK);
      if (res) {
        ModalController.show({
          message:
            messages.COMMON.MSG_COMMON_DELETE_SUCCESS_001(tlgtMasterTypeName),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData();
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleDeleteSettings", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleEdit = (d: any, i: number) => {
    setEditData({
      name: d.name,
      isDefault: d.is_default,
    });
    setEditIndex(i);
    showConfirm(setOpen);
  };

  const handleMaEdit = (d: any, i: number) => {
    setMaEditData({
      nonconformity_detail: d.nonconformity_detail,
      occurance_process: d.occurance_process,
      process_of_root_cause: d.process_of_root_cause,
      responsibility: d.responsibility,
      responsibility_name: d.responsibility_name,
      department_of_pic: d.department_of_pic,
      department_of_pic_name: d.department_of_pic_name,
      process_in_logistics: d.process_in_logistics,
      process_in_logistics_name: d.process_in_logistics_name,
      responsibility_in_process: d.responsibility_in_process,
      responsibility_in_process_name: d.responsibility_in_process_name,
      classification_of_nonconformity: d.classification_of_nonconformity,
      classification_of_nonconformity_name:
        d.classification_of_nonconformity_name,
      classification_of_cause: d.classification_of_cause,
      classification_of_cause_name: d.classification_of_cause_name,
      is_default: d.is_default,
    });
    setEditIndex(i);
    showConfirm(setMaOpen);
  };

  const handleModelNameEdit = (d: any, i: number) => {
    setModelNameEditData({
      name: d.name,
      code: d.code,
      isDefault: d.is_default,
    });
    setEditIndex(i);
    showConfirm(setModelNameOpen);
  };

  const showConfirm = (f: (flg: boolean) => void) => {
    if (!ModalController.isShowing() && !disabled) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_UPDATE_CONFIRM_SORT_001,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => {
          f(true);
        },
      });
    } else {
      f(true);
    }
  };

  const handleChange = async (value: IData) => {
    let newData = JSON.parse(JSON.stringify(data));
    let message = "";
    let res = null;
    const formData = {
      name: value.name,
      is_default: value.isDefault,
      index: "",
      is_manual_input: false,
    };
    try {
      LoadingOverlayController.show();
      if (editIndex >= 0) {
        res = await updateTlgtMaster(formData, newData[editIndex].SK);
        if (res) {
          message = tlgtMasterTypeName + "の更新";
        }
      } else {
        res = await insertTlgtMaster(formData, tlgtMasterTypeId);
        if (res) {
          message = tlgtMasterTypeName + "の登録";
        }
      }
      setEditIndex(-1);
      if (res) {
        setData(newData);
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001(message),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData();
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleChange", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleMaChange = async (value: IMaMasterData) => {
    let newData = JSON.parse(JSON.stringify(data));
    let message = "";
    let res = null;
    const formData = {
      nonconformity_detail: value.nonconformity_detail,
      occurance_process: value.occurance_process,
      process_of_root_cause: value.process_of_root_cause,
      responsibility: value.responsibility,
      department_of_pic: value.department_of_pic,
      process_in_logistics: value.process_in_logistics,
      responsibility_in_process: value.responsibility_in_process,
      classification_of_nonconformity: value.classification_of_nonconformity,
      classification_of_cause: value.classification_of_cause,
      index: "",
      is_manual_input: false,
      is_default: value.is_default,
    };
    try {
      LoadingOverlayController.show();
      if (editIndex >= 0) {
        res = await updateTlgtMaMaster(formData, newData[editIndex].SK);
        if (res) {
          message = tlgtMasterTypeName + "の更新";
        }
      } else {
        res = await insertTlgtMaMaster(formData);
        if (res) {
          message = tlgtMasterTypeName + "の登録";
        }
      }
      setEditIndex(-1);
      if (res) {
        setData(newData);
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001(message),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData();
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleMaChange", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleModelNameChange = async (value: IModelNameMasterData) => {
    let newData = JSON.parse(JSON.stringify(data));
    let message = "";
    let res = null;
    const formData = {
      name: value.name,
      code: value.code,
      index: "",
      is_manual_input: false,
      is_default: value.isDefault,
    };
    try {
      LoadingOverlayController.show();
      if (editIndex >= 0) {
        res = await updateTlgtModelNameMaster(formData, newData[editIndex].SK);
        if (res) {
          message = tlgtMasterTypeName + "の更新";
        }
      } else {
        res = await insertTlgtModelNameMaster(formData);
        if (res) {
          message = tlgtMasterTypeName + "の登録";
        }
      }
      setEditIndex(-1);
      if (res) {
        setData(newData);
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001(message),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData();
          },
        });
      }
    } catch (error: any) {
      message = error?.detail?.message ?? error?.detail;
      if (!ModalController.isShowing())
        ModalController.show({
          message: message,
          visibleButton2: true,
        });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setEditIndex(-1);
  };

  const handleMaCancel = () => {
    setMaOpen(false);
    setEditIndex(-1);
  };

  const handleModelNameCancel = () => {
    setModelNameOpen(false);
    setEditIndex(-1);
  };

  const handleSave = async () => {
    try {
      LoadingOverlayController.show();
      let formData: any[] = [];
      data.map((item) => {
        const datilData = {
          PK: item.PK,
          SK: item.SK,
        };
        formData.push(datilData);
      });
      const res = await updateSettingSort(formData);
      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001(
            tlgtMasterTypeName + "の並び順の更新",
          ),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData();
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleSave", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleChangeMasterType = (e: SelectChangeEvent<string>) => {
    const matchData = TLGT_MASTER_TYPE.find(
      (item: { id: string; name: string }) => item.name == e.target.value,
    );
    if (matchData == undefined) {
      /* 選択肢はTLGT_MASTER_TYPEから作成しているため、MissMatchはありえない */
      setTlgtMasterTypeId("");
      settlgtMasterTypeName("");
      return;
    }
    setTlgtMasterTypeId(matchData?.id);
    settlgtMasterTypeName(e.target.value);
  };

  const fetchData = async () => {
    setLoadMore(false);
    cancelFetchRef.current = false;
    LoadingOverlayController.show();
    if (tlgtMasterTypeName === "Model Name") {
      await getModelNameMaster(undefined)
        .then((res) => {
          if (cancelFetchRef.current) return;
          if (res?.data.length === 0 && !res?.lastKey) {
            setLoadMore(false);
          }
          if (res?.data) {
            setData(res.data.Items);
            setOriginData(res.data.Items);
          }
          if (res?.last_key) {
            setLastKey(res?.last_key ?? null);
            setMasterExtract(res?.last_key.master_extract_key ?? null);
            setSeq(res?.last_key.seq ?? null);
            setPK(res?.last_key.PK ?? null);
            setSK(res?.last_key.SK ?? null);
            setLoadMore(true);
          } else {
            setLastKey(null);
            setLoadMore(false);
          }
        })
        .finally(() => LoadingOverlayController.hide());
    } else {
      await getListTlgtMaster(tlgtMasterTypeId)
        .then((res) => {
          if (res?.data) {
            setData(res.data);
            setOriginData(res.data);
          }
        })
        .finally(() => LoadingOverlayController.hide());
    }
  };

  const fetchDataConfirm = async () => {
    if (!ModalController.isShowing() && !tlgtMasterTypeId) {
      ModalController.show({
        message: messages.COMMON.ERROR.MSG_RQUIRED_SELECT("マスタ種類"),
        visibleButton2: true,
      });
    }

    if (!ModalController.isShowing() && !disabled) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_DISPLAY_CONFIRM_SORT_001,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => {
          cancelFetchRef.current = true;
          setLoadMore(false);
          // fetchData();
          setData([]);
          setOriginData([]);
          setSelectDisabled(true);
          if (tlgtMasterTypeName === _maName) {
            getListNonconformityDetail();
            getListNonconformityOverview();
            getListOccuranceProcess();
            getListProcessOfRootCause();
            getListResponsibility();
            getListDepartmentOfPic();
            getListProcessInLogistics();
            getListResponsibilityInProcess();
            getListClassificationOfNonconformity();
            getListClassificationOfCause();
          }
        },
      });
    }
    if (disabled) {
      if (selectDisabled) {
        if (tlgtMasterTypeId) {
          setSelectDisabled(false);
          cancelFetchRef.current = false;
          if (tlgtMasterTypeName === _maName) {
            getListNonconformityDetail();
            getListNonconformityOverview();
            getListOccuranceProcess();
            getListProcessOfRootCause();
            getListResponsibility();
            getListDepartmentOfPic();
            getListProcessInLogistics();
            getListResponsibilityInProcess();
            getListClassificationOfNonconformity();
            getListClassificationOfCause();
          }
          fetchData();
        }
      } else {
        cancelFetchRef.current = true;
        setData([]);
        setOriginData([]);
        setSelectDisabled(true);
        setLastKey(null);
      }
    }
  };

  const getListNonconformityDetail = async () => {
    await getListTlgtMaster("nonconformity_detail").then((res) => {
      if (res?.data) {
        setListNonconformityDetail(res.data.length > 0 ? res.data : []);
      }
    });
  };
  const getListNonconformityOverview = async () => {
    await getListTlgtMaster("nonconformity_overview").then((res) => {
      if (res?.data) {
        setListNonconformityOverview(res.data.length > 0 ? res.data : []);
      }
    });
  };
  const getListOccuranceProcess = async () => {
    await getListTlgtMaster("occurance_process").then((res) => {
      if (res?.data) {
        setListOccuranceProcess(res.data.length > 0 ? res.data : []);
      }
    });
  };
  const getListProcessOfRootCause = async () => {
    await getListTlgtMaster("process_of_root_cause").then((res) => {
      if (res?.data) {
        setListProcessOfRootCause(res.data.length > 0 ? res.data : []);
      }
    });
  };

  const getListResponsibility = async () => {
    await getListTlgtMaster("responsibility").then((res) => {
      if (res?.data) {
        setListResponsibility(res.data.length > 0 ? res.data : []);
      }
    });
  };

  const getListDepartmentOfPic = async () => {
    await getListTlgtMaster("department_of_pic").then((res) => {
      if (res?.data) {
        setListDepartmentOfPic(res.data.length > 0 ? res.data : []);
      }
    });
  };

  const getListProcessInLogistics = async () => {
    await getListTlgtMaster("process_in_logistics").then((res) => {
      if (res?.data) {
        setListProcessInLogistics(res.data.length > 0 ? res.data : []);
      }
    });
  };

  const getListResponsibilityInProcess = async () => {
    await getListTlgtMaster("responsibility_in_process").then((res) => {
      if (res?.data) {
        setListResponsibilityInProcess(res.data.length > 0 ? res.data : []);
      }
    });
  };

  const getListClassificationOfNonconformity = async () => {
    await getListTlgtMaster("classification_of_nonconformity").then((res) => {
      if (res?.data) {
        setListClassificationOfNonconformity(
          res.data.length > 0 ? res.data : [],
        );
      }
    });
  };

  const getListClassificationOfCause = async () => {
    await getListTlgtMaster("classification_of_cause").then((res) => {
      if (res?.data) {
        setListClassificationOfCause(res.data.length > 0 ? res.data : []);
      }
    });
  };
  const loadMoreData = async (isMerge: boolean = false) => {
    if (!loadMore || !lastKey || selectDisabled) return;
    LoadingOverlayController.show();
    try {
      const params = {
        jan_code: "",
        last_key_PK: PK ?? undefined,
        last_key_SK: SK ?? undefined,
        master_extra: masterExtract ?? undefined,
        seq: seq ?? undefined,
      };

      if (!PK || !SK) return;
      const res = await getModelNameMaster(params);
      if (cancelFetchRef.current) return;
      if (res?.last_key === null) {
        if (cancelFetchRef.current) return;
        setLastKey(null);
        setLoadMore(false);
        setData((prev) => {
          const newData = [...prev, ...res.data.Items];
          return Array.from(new Set(newData.map((item) => item.SK))).map((SK) =>
            newData.find((item) => item.SK === SK),
          );
        });
        setOriginData((prev) => {
          const newData = [...prev, ...res.data.Items];
          return Array.from(new Set(newData.map((item) => item.SK))).map((SK) =>
            newData.find((item) => item.SK === SK),
          );
        });
      } else {
        setData((prev) => {
          const newData = [...prev, ...res.data.Items];
          return Array.from(new Set(newData.map((item) => item.SK))).map((SK) =>
            newData.find((item) => item.SK === SK),
          );
        });
        setOriginData((prev) => {
          const newData = [...prev, ...res.data.Items];
          return Array.from(new Set(newData.map((item) => item.SK))).map((SK) =>
            newData.find((item) => item.SK === SK),
          );
        });
        setLastKey(res?.last_key);
        setLoadMore(true);
        setSeq(res?.last_key.seq);
        setPK(res?.last_key.PK);
        setSK(res?.last_key.SK);
      }
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    setDisabled(JSON.stringify(data) === JSON.stringify(originData));
  }, [data, originData]);

  const getNonconformityDetailName = useCallback(
    (SK: string) => {
      const selected = listNonconformityDetail.find((v) => v.SK === SK);
      return selected?.name ?? SK;
    },
    [listNonconformityDetail],
  );

  return (
    <GenericTemplate title="TLGTマスタ管理">
      {/* MA以外マスタ管理用ダイアログ */}
      <TlgtMasterEditDialog
        data={editData}
        open={open}
        onChange={handleChange}
        onClose={handleCancel}
      />

      {/* MAマスタ管理用ダイアログ */}
      <TlgtMasterMaEditDialog
        data={maEditData}
        open={maopen}
        onChange={handleMaChange}
        onClose={handleMaCancel}
        listNonconformityDetail={listNonconformityDetail}
        listNonconformityOverview={listNonconformityOverview}
        listOccuranceProcess={listOccuranceProcess}
        listProcessOfRootCause={listProcessOfRootCause}
        listResponsibility={listResponsibility}
        listDepartmentOfPic={listDepartmentOfPic}
        listProcessInLogistics={listProcessInLogistics}
        listResponsibilityInProcess={listResponsibilityInProcess}
        listClassificationOfNonconformity={listClassificationOfNonconformity}
        listClassificationOfCause={listClassificationOfCause}
      />

      {/* MODELNAMEマスタ管理用ダイアログ */}
      <TlgtMasterModelNameEditDialog
        data={ModelNameEditData}
        open={ModelNameopen}
        onChange={handleModelNameChange}
        onClose={handleModelNameCancel}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          mt: 3,
          mb: 3,
        }}
      >
        <SelectLabel
          label="マスタ種類"
          value={tlgtMasterTypeName}
          isIgnoreExtractRuleLabel={false}
          isIgnoreExtractRuleSelect={true}
          onChange={(e) => handleChangeMasterType(e)}
          sx={{ width: 500 }}
          disabled={!selectDisabled}
        >
          {TLGT_MASTER_TYPE.map((item: { id: string; name: string }) => (
            <MenuItem value={item.name} key={item.id}>
              <span className="IgnoreExtractRuleTarget">{item.name}</span>
            </MenuItem>
          ))}
        </SelectLabel>
        <Button
          onClick={fetchDataConfirm}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mt: 3,
            ml: 2,
          }}
        >
          {selectDisabled ? "選択" : "解除"}
        </Button>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant="body2">ドラッグ＆ドロップで並べ替え</Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Button
          onClick={handleAdd}
          disabled={selectDisabled}
          endIcon={<AddCircle />}
        >
          追加
        </Button>
      </Box>
      <Box>
        <DragDropContext onDragEnd={handleDnd}>
          <Droppable droppableId="droppableId-1">
            {(provided: DroppableProvided) => (
              <InfiniteScroll
                pageStart={0}
                loadMore={() => {
                  if (
                    tlgtMasterTypeName === "Model Name" &&
                    loadMore &&
                    lastKey &&
                    data.length > 0
                  ) {
                    loadMoreData(true);
                  }
                }}
                hasMore={showLoadMore}
                loader={<ScrollLoader key={0} />}
              >
                <List ref={provided.innerRef} {...provided.droppableProps}>
                  {data.map((item, i) => (
                    <Draggable
                      key={`${item.SK}-${i}`}
                      draggableId={item.SK}
                      index={i}
                    >
                      {(provided: DraggableProvided) => (
                        <ListItem
                          sx={{ bgcolor: "white" }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          secondaryAction={
                            <>
                              <Tooltip title="編集">
                                <IconButton
                                  edge="end"
                                  sx={{ mx: 1 }}
                                  onClick={() => {
                                    if (tlgtMasterTypeName === "MA") {
                                      handleMaEdit(item, i);
                                    } else if (
                                      tlgtMasterTypeName === "Model Name"
                                    ) {
                                      handleModelNameEdit(item, i);
                                    } else {
                                      handleEdit(item, i);
                                    }
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="削除">
                                <IconButton
                                  edge="end"
                                  onClick={() => handleDelete(item.SK)}
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            </>
                          }
                          divider
                        >
                          <span className="IgnoreExtractRuleTarget">
                            <ListItemText
                              primary={
                                tlgtMasterTypeName === _maName
                                  ? getNonconformityDetailName(
                                      item.nonconformity_detail,
                                    )
                                  : item.name
                              }
                              primaryTypographyProps={{
                                style: {
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  marginRight: "40px",
                                  color: item.is_default
                                    ? Colors.REQUIRE
                                    : Colors.TEXT,
                                },
                              }}
                            />
                          </span>
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              </InfiniteScroll>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box sx={{ height: 30 }} />
      <Box
        sx={{
          position: "fixed",
          margin: 0,
          right: 0,
          bottom: 20,
          textAlign: "center",
          width: "100%",
        }}
      >
        <Button onClick={handleSave} color="secondary" disabled={disabled}>
          保存
        </Button>
      </Box>
    </GenericTemplate>
  );
};

export default TlgtMasterEditScreen;
