import {
  METHOD_API,
  createConnector,
  getParamsHelp,
  prefixApi,
} from "./Connector";

interface ICheckLoadingReport {
  activity_base_id: string;
  csv_data: any[];
  loading_date?: string;
}

interface LoadingReportKey {
  PK: string;
  SK: string;
}

export const getListLoadingReport = (
  activity_base_id: string,
  loading_date: string,
) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/loading-report/${encodeURIComponent(
      activity_base_id,
    )}?${getParamsHelp({
      loading_date: loading_date,
    })}`,
    null,
  );
};

export const checkLoadingReport = (dataBody: ICheckLoadingReport) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/check-loading-report`,
    dataBody,
  );
};

export const updateLoadingReport = (dataBody: ICheckLoadingReport) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/update-loading-report`,
    dataBody,
  );
};

export const deleteLoadingReport = (dataBody: LoadingReportKey[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/delete-loading-report`,
    dataBody,
  );
};

export const updateListWorkPdf = (dataBody: object) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/update-list-work-pdf`,
    dataBody,
  );
};


  export const checkContainerNoExist = (dataBody: object) => {
    return createConnector(
      METHOD_API.UPLOAD_FORM_POST,
      `${prefixApi}/check-container-no-exist`,
      dataBody,
    );
  }

export const checkLoadingReportWorkStatus = (dataBody: any) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/check-loading-report-work-status`,
    dataBody,
  );
}