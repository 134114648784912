import { Box, Button, Stack, TextField } from "@mui/material";
import { MAX_LENGTH_30 } from "@shared-constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { Colors } from "@template/style";
import { ApproveState } from "store/reducer/ApproveFlowReducer";
import { useEffect, useState } from "react";
import { TYPES } from "store/types";
import messages from "config/messages";
import ModalController from "@shared-components/modal/ModalController";
import { Validation } from "@validation";
import LabelRequired from "components/atoms/LabelRequired";

export const NameApprovalFlowInput = () => {
  const approveFlowState: ApproveState = useSelector(
    (state: RootState) => state.approveFlow,
  );
  const {
    flowInfo,
    flowList,
    onClickCreateNew,
    isTheSameOrigin,
    recheck,
    editedFlowName,
  } = approveFlowState;
  const dispatch = useDispatch();
  const [flowName, setFlowName] = useState<string>(flowInfo.flow_name);
  const [errorMessage, setErrorMessage] = useState<string>("");
  // const [isDuplicateFlowName, setIsDuplicateFlowName] =
  //   useState<boolean>(false);

  useEffect(() => {
    setFlowName(flowInfo.flow_name);
    setErrorMessage("");
  }, [flowInfo]);
  const handleChange = (event: any) => {
    const currentValue = event.target.value;
    setFlowName(currentValue);
    dispatch({
      type: TYPES.SET_EDITED_FLOW_NAME,
      payload: currentValue,
    });
  };
  useEffect(() => {
    if (errorMessage.trim().length > 0) return;
    const isDuplicate = flowList.some(
      (item) =>
        item.flow_name === editedFlowName.trim() && item.SK !== flowInfo.SK,
    );
    // setIsDuplicateFlowName(isDuplicate);
    if (isDuplicate) {
      const errorMessage = messages.ManageScreen.MSG_DUPLICATE_FLOW_NAME;
      setErrorMessage(errorMessage);
    } else {
      setErrorMessage("");
    }
    dispatch({
      type: TYPES.SET_FLOW_NAME_READY_FOR_SUBMIT,
      payload: !isDuplicate,
    });
  }, [recheck]);

  // useEffect(() => {
  //   const isDuplicate = flowList.some(
  //     (item) => item.flow_name === flowName.trim() && item.SK !== flowInfo.SK,
  //   );
  //   dispatch({
  //     type: TYPES.SET_FLOW_NAME_READY_FOR_SUBMIT,
  //     payload: !isDuplicate,
  //   });
  // }, [flowName]);

  const handleConfirmDelete = () => {
    ModalController.show({
      message: `承認フロー「${flowInfo.flow_name}」を削除しますか？`,
      visibleButton1: true,
      visibleButton2: true,
      handlePressButton2: () => {
        dispatch({
          type: TYPES.SET_ON_CLICK_DELETE_FLOW,
        });
      },
    });
  };
  const handlingDisableDeleteBtn = () => {
    if (
      onClickCreateNew ||
      (!editedFlowName && !flowInfo.SK) ||
      flowInfo.flow_name != flowName
    ) {
      return true;
    }
    return false;
  };
  const handlingDisableInputField = () => {
    if (onClickCreateNew) {
      return false;
    } else {
      return !editedFlowName && !flowInfo.SK;
    }
  };

  const onValidateText = (value: string) => {
    let errorMessage = "";
    // 重複チェック
    const isDuplicate = flowList.some(
      (item) => item.flow_name === flowName.trim() && item.SK !== flowInfo.SK,
    );
    if (isDuplicate) {
      errorMessage = messages.ManageScreen.MSG_DUPLICATE_FLOW_NAME;
    } else {
      // 必須チェック
      errorMessage = validateText(value);
    }
    setErrorMessage(errorMessage);
    dispatch({
      type: TYPES.SET_FLOW_NAME_READY_FOR_SUBMIT,
      payload: errorMessage.trim().length == 0,
    });
  };
  const validateText = (value: string) => {
    let message = "";
    message = Validation.validate({
      type: "text",
      name: "名称",
      value: value,
      required: true,
      max_length: MAX_LENGTH_30,
    });
    return message;
  };
  return (
    <Stack direction={"row"} spacing={0}>
      <TextField
        disabled={handlingDisableInputField()}
        label={<LabelRequired title="名称" />}
        value={flowName}
        sx={{ width: "45%" }}
        onChange={handleChange}
        inputProps={{ maxLength: MAX_LENGTH_30 }}
        className="TextInputFlowName"
        onBlur={() => onValidateText(flowName)}
        helperText={errorMessage}
        error={errorMessage.trim().length > 0}
      />
      <Box sx={styles.BtnBox}>
        <Button
          color="error"
          sx={styles.searchBtnCustom}
          onClick={handleConfirmDelete}
          disabled={handlingDisableDeleteBtn() || !isTheSameOrigin}
        >
          削除
        </Button>
      </Box>
    </Stack>
  );
};
const styles = {
  card: { mb: 2 },
  title: { mt: 2, mb: 1 },
  selectLabel: { width: "100% !important" },
  button: { width: 150, ml: 1, height: 56 },
  formContent: {
    my: 2,
    mr: 2,
    p: 2,
    border: 2,
    borderRadius: 1,
    borderColor: Colors.MAIN_GREEN,
    position: "relative",
  },
  clearButton: { position: "absolute", right: 0, top: 0 },
  clearIcon: {
    color: "#fff",
    backgroundColor: Colors.TEXT,
    borderRadius: 50,
  },
  searchBtnCustom: {
    marginLeft: 2,
  },
  BtnBox: {
    display: "flex",
    alignItems: "flex-start",
    pt: "36px",
  },
} as const;
