import { useEffect, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
  Button,
  Typography,
  MenuItem,
} from "@mui/material";
import {
  DraggableProvided,
  DroppableProvided,
  DropResult,
  Draggable,
  Droppable,
  DragDropContext,
} from "react-beautiful-dnd";
import { AddCircle, Circle, Delete, Edit } from "@mui/icons-material";
import { Colors } from "@template/style";
import { getUserInfo, sort } from "@utils/index";
import MasterEditDialog, { IData } from "screens/MasterEdit/MasterEditDialog";
import { getListSpan, deleteSpan, updateSpanSort } from "@api/itemReport";
import { insertMaster, updateMaster } from "@api/master";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import { getListActivityBase } from "@api/groupMaster";
import SelectLabel from "components/atoms/SelectLabel";
import { USER_ROLES } from "@shared-constants";

const initial_data: IData = {
  type: "スパン名",
  name: "",
  color: "",
  color_id: "",
  color_name: "",
  color_needs: false,
  is_manual_input: false,
  is_default: false,
};

const SpanEditScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [data, setData] = useState<any[]>([]);
  const [originData, setOriginData] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [editData, setEditData] = useState<IData>(initial_data);
  const [selectDisabled, setSelectDisabled] = useState<boolean>(true);
  const [userRole, setUserRole] = useState<string>("");
  const [activityBaseId, setActivityBaseId] = useState<string>("");
  const [listGroup, setListGroup] = useState([]);

  useEffect(() => {
    getListGroup();
  }, []);

  const getListGroup = async (keyword?: string) => {
    const user_info = getUserInfo();
    setUserRole(user_info.user_role);
    if (user_info.user_role == USER_ROLES.OWNER.value) {
      await getListActivityBase(keyword)
        .then((res) => {
          if (res) {
            setListGroup(res);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      console.log(user_info);
      setActivityBaseId(user_info.location_id);
      setSelectDisabled(false);
      fetchData(user_info.location_id);
    }
  };

  const handleDnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const sorted = sort(data, result.source.index, result.destination.index);
    setData(sorted);
  };

  const handleAdd = () => {
    setEditData(initial_data);
    setOpen(true);
  };

  const handleDelete = (SK: string, activity_base_id: string) => {
    if (!ModalController.isShowing()) {
      const confirmMessage = disabled
        ? messages.COMMON.MSG_COMMON_DELETE_CONFIRM_001
        : messages.COMMON.MSG_COMMON_DELETE_CONFIRM_SORT_001;
      ModalController.show({
        message: confirmMessage,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => handleDeleteSpan(SK, activity_base_id),
      });
    }
    return;
  };

  const handleDeleteSpan = async (SK: string, activity_base_id: string) => {
    try {
      LoadingOverlayController.show();
      const res = await deleteSpan(SK, activity_base_id);
      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_DELETE_SUCCESS_001("スパン"),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData(activityBaseId);
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleUpdateAccount", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleEdit = (d: any, i: number) => {
    setEditData({
      type: "スパン名",
      name: d.span_name,
      color_id: d.color_id,
      color: d.color_code,
      color_name: d.color_name,
      color_needs: false,
      is_manual_input: d.is_manual_input,
      is_default: d.is_default,
    });
    setEditIndex(i);
    if (!ModalController.isShowing() && !disabled) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_UPDATE_CONFIRM_SORT_001,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => {
          setOpen(true);
        },
      });
    } else {
      setOpen(true);
    }
  };

  const handleChange = async (value: IData) => {
    let newData = JSON.parse(JSON.stringify(data));
    let message = "";
    let res = null;
    const formData = {
      type: "SPAN_LIST",
      name: value.name,
      color: value.color,
      color_id: value.color_id,
      color_name: value.color_name,
      index: "",
      parent: "",
      activity_base_id: activityBaseId,
      is_manual_input: value.is_manual_input,
      is_default: value.is_default,
    };
    try {
      LoadingOverlayController.show();
      if (editIndex >= 0) {
        res = await updateMaster(formData, newData[editIndex].SK);
        if (res) {
          message = "スパンの更新";
        }
      } else {
        res = await insertMaster(formData);
        if (res) {
          message = "スパンの登録";
        }
      }
      setEditIndex(-1);
      if (res) {
        setData(newData);
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001(message),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData(activityBaseId);
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleUpdateAccount", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleSave = async () => {
    try {
      LoadingOverlayController.show();
      let formData: any[] = [];
      data.map((item) => {
        const datilData = {
          PK: item.PK,
          SK: item.SK,
        };
        formData.push(datilData);
      });
      const res = await updateSpanSort(formData);
      if (res) {
        ModalController.show({
          message:
            messages.COMMON.MSG_COMMON_SUCCESS_001("スパンの並び順の更新"),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData(activityBaseId);
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleUpdateAccount", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setEditIndex(-1);
  };

  const fetchDataConfirm = async () => {
    if (!ModalController.isShowing() && !activityBaseId) {
      ModalController.show({
        message: messages.COMMON.ERROR.MSG_RQUIRED_SELECT("拠点"),
        visibleButton2: true,
      });
    }

    if (!ModalController.isShowing() && !disabled) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_DISPLAY_CONFIRM_SORT_001,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => {
          // fetchData(activityBaseId);
          setData([]);
          setOriginData([]);
          setSelectDisabled(true);
        },
      });
    }
    if (disabled) {
      if (selectDisabled) {
        if (activityBaseId) {
          setSelectDisabled(false);
          fetchData(activityBaseId);
        }
      } else {
        setData([]);
        setOriginData([]);
        setSelectDisabled(true);
      }
    }
  };

  const fetchData = async (activityBaseId: string) => {
    LoadingOverlayController.show();
    await getListSpan(activityBaseId)
      .then((res) => {
        if (res?.data) {
          setData(res.data);
          setOriginData(res.data);
        }
      })
      .finally(() => LoadingOverlayController.hide());
  };

  useEffect(() => {
    setDisabled(JSON.stringify(data) === JSON.stringify(originData));
  }, [data, originData]);

  return (
    <GenericTemplate title="マスタ管理">
      <Typography variant="h6">スパン</Typography>

      <MasterEditDialog
        data={editData}
        open={open}
        onChange={handleChange}
        onClose={handleCancel}
      />

      {userRole == USER_ROLES.OWNER.value && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: 3,
              mb: 3,
            }}
          >
            <SelectLabel
              label="拠点"
              value={activityBaseId}
              isIgnoreExtractRuleLabel={false}
              isIgnoreExtractRuleSelect={true}
              onChange={(e) => setActivityBaseId(e.target.value)}
              sx={{ width: 500 }}
              disabled={!selectDisabled}
            >
              {listGroup.map((item: any) => (
                <MenuItem value={item.SK} key={item.SK}>
                  <span className="IgnoreExtractRuleTarget">
                    {item.activity_base_name}
                  </span>
                </MenuItem>
              ))}
            </SelectLabel>
            <Button
              onClick={fetchDataConfirm}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 3,
                ml: 2,
              }}
            >
              {selectDisabled ? "選択" : "解除"}
            </Button>
          </Box>
        </>
      )}

      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant="body2">ドラッグ＆ドロップで並べ替え</Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Button
          onClick={handleAdd}
          endIcon={<AddCircle />}
          disabled={selectDisabled}
        >
          追加
        </Button>
      </Box>
      <Box>
        <DragDropContext onDragEnd={handleDnd}>
          <Droppable droppableId="droppableId-1">
            {(provided: DroppableProvided) => (
              <List ref={provided.innerRef} {...provided.droppableProps}>
                {data.map((item, i) => (
                  <Draggable key={item.SK} draggableId={item.SK} index={i}>
                    {(provided: DraggableProvided) => (
                      <ListItem
                        sx={{ bgcolor: "white" }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        secondaryAction={
                          <>
                            <Tooltip title="編集">
                              <IconButton
                                edge="end"
                                sx={{ mx: 1 }}
                                onClick={() => handleEdit(item, i)}
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="削除">
                              <IconButton
                                edge="end"
                                onClick={() =>
                                  handleDelete(item.SK, item.activity_base_id)
                                }
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                        divider
                      >
                        <span className="IgnoreExtractRuleTarget">
                          <ListItemText
                            primary={item.span_name}
                            primaryTypographyProps={{
                              style: {
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                marginRight: "40px",
                                color: item.is_default
                                  ? Colors.REQUIRE
                                  : Colors.TEXT,
                              },
                            }}
                          />
                        </span>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box sx={{ height: 30 }} />
      <Box
        sx={{
          position: "fixed",
          margin: 0,
          right: 0,
          bottom: 20,
          textAlign: "center",
          width: "100%",
        }}
      >
        <Button onClick={handleSave} color="secondary" disabled={disabled}>
          保存
        </Button>
      </Box>
    </GenericTemplate>
  );
};

export default SpanEditScreen;
