import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Colors } from "@template/style";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { TYPES } from "store/types";
import { IconButton, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroller";
import ScrollLoader from "components/atoms/ScrollLoader";
import formatDateToString from "@utils/DateFormat";

export interface HeadCell {
  id: string;
  label: string;
  width?: string | number;
  numeric?: boolean;
  disablePadding?: boolean;
  align?: "inherit" | "left" | "center" | "right" | "justify" | undefined;
  orderBy?: string; // [id]以外でソートする場合、ソートキーを指定
  isIgnoreExtractRuleCell?: boolean;
  message?: string;
}

export interface TableCustomProps {
  rows: any[]; // テーブルデータ
  headCells: HeadCell[]; // テーブルヘッダー
  buttons?: { cell_id: string; button: React.ReactChild; id: string }[]; // ボタンデータ
  idName: string; // 一意になるデータのid
  isCheckRow?: boolean; // true:チェックボックス表示/false:チェックボックス非表示
  maxHeight?: string | number;
  noWrap?: boolean;
  isEditRow?: boolean; // true:編集ボタン表示/false:編集ボタン非表示
  handleEdit?: Function; //編集ボタンを押したときの処理
  message?: string;
  loadMore?: (page: number) => void;
  hasMore?: boolean;
}

interface TableHeadProps {
  headCells: HeadCell[];
  rowCount: number;
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isCheckRow: boolean;
  isEditRow: boolean;
}

/**
 * テーブルヘッダー
 * @param param0
 * @returns TableHead
 */
const TableHeader = ({
  headCells,
  rowCount,
  numSelected,
  onSelectAllClick,
  isCheckRow,
  isEditRow,
}: TableHeadProps) => {
  return (
    <TableHead>
      <TableRow>
        {isCheckRow && (
          <TableCell
            align="center"
            padding="none"
            sx={{ ...styles.border, bgcolor: Colors.MAIN_GREEN }}
          >
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              sx={{
                color: "white",
                "&.Mui-checked": {
                  color: "white",
                },
                "&.MuiCheckbox-indeterminate": {
                  color: "white",
                },
              }}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.align ?? "center"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sx={{
                ...styles.border,
                color: "white",
                minWidth: headCell.width,
                bgcolor: Colors.MAIN_GREEN,
              }}
            >
              {headCell.label}
            </TableCell>
          );
        })}
        {isEditRow && (
          <TableCell
            align="center"
            padding="none"
            sx={{ ...styles.border, bgcolor: Colors.MAIN_GREEN }}
          />
        )}
      </TableRow>
    </TableHead>
  );
};

/**
 * カスタムテーブル
 * @param props
 * @returns TableContainer
 */
export const TableCustom = (props: TableCustomProps) => {
  const {
    rows,
    headCells,
    buttons = [],
    idName,
    isCheckRow = false,
    maxHeight = 440,
    noWrap,
    isEditRow = false,
    handleEdit = Function,
    message,
    loadMore,
    hasMore,
  } = props;
  const { check_list = [] } = useSelector(
    (state: RootState) => state.tableCustom,
  );
  const dispatch = useDispatch();

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isCheckRow) return;
    if (event.target.checked) {
      let newSelectIds: any[] = [];
      rows.map((n: any) => {
        const _n = JSON.parse(JSON.stringify(n));
        if (!_n["is_delivered"]) newSelectIds.push(n[idName]);
      });

      dispatch({
        type: TYPES.SET_CHECK_TABLE,
        check_list: newSelectIds,
      });
    }
    if (check_list.length > 0 && check_list.length <= rows.length) {
      dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: [] });
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    if (!isCheckRow) return;
    const selectedIndex = check_list.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(check_list, name);
    } else {
      newSelected = newSelected.concat(
        check_list.slice(0, selectedIndex),
        check_list.slice(selectedIndex + 1),
      );
    }
    dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: newSelected });
  };

  const isSelected = (name: string) => check_list.indexOf(name) !== -1;

  return (
    <TableContainer sx={{ maxHeight: maxHeight, overflow: "auto" }}>
  
        <Table stickyHeader size="medium" sx={{ maxHeight: maxHeight }}>
          <TableHeader
            headCells={headCells}
            numSelected={check_list.length}
            rowCount={rows.length}
            onSelectAllClick={handleSelectAllClick}
            isCheckRow={isCheckRow}
            isEditRow={isEditRow}
          />

          {rows && !message ? (
            <TableBody sx={{ flexDirection: "column", bgcolor: "white" }}>
              {rows.map((row, index) => {
                const rowjson = JSON.parse(JSON.stringify(row));
                const id = rowjson[idName];
                const isItemSelected = isSelected(id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) => {
                      if (!rowjson["is_delivered"]) handleClick(event, id);
                    }}
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    {isCheckRow && (
                      <TableCell
                        align="center"
                        padding="none"
                        sx={styles.border}
                      >
                        {!rowjson["is_delivered"] && (
                          <>
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </>
                        )}
                      </TableCell>
                    )}
                    {headCells.map((headCell) => {
                      const keyname = headCell.id;
                      const ignoreExtractRuleCell =
                        headCell.isIgnoreExtractRuleCell
                          ? "IgnoreExtractRuleTarget"
                          : "";
                      let value;
                      if (keyname in rowjson) {
                        if (keyname === "date_of_delivery") {
                          value = formatDateToString(
                            rowjson[keyname],
                            "YMD_sl",
                          );
                        } else if (
                          keyname === "is_accepted" ||
                          keyname === "is_delivered"
                        ) {
                          value = rowjson[keyname] ? "〇" : "";
                        } else {
                          value = rowjson[keyname];
                        }
                      } else if(keyname === "not_accepted" && !rowjson["is_accepted"] && !rowjson["is_delivered"]){
                          value = !rowjson["is_accepted"] ? "〇" : ""; 
                      }else {
                        const button = buttons.find(
                          (item) => item.cell_id === keyname && item.id === id,
                        );

                        if (button) value = button.button;
                      }
                      if (headCell.numeric && value) {
                        value = value
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }

                      return (
                        <TableCell
                          key={headCell.id}
                          align={headCell.align ?? "center"}
                          className={ignoreExtractRuleCell}
                          padding={headCell.disablePadding ? "none" : "normal"}
                          width={headCell.width}
                          sx={[
                            styles.border,
                            noWrap
                              ? { ...styles.ellipsis, maxWidth: headCell.width }
                              : styles.wrap,
                          ]}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                    {isEditRow && (
                      <TableCell
                        align="center"
                        padding="none"
                        sx={[styles.border, { minWidth: 40 }]}
                      >
                        {!rowjson["is_delivered"] && (
                          <Tooltip title="編集">
                            <IconButton
                              onClick={() => {
                                handleEdit(row);
                              }}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody sx={{ flexDirection: "column", bgcolor: "white" }}>
              <TableRow>
                <TableCell
                  colSpan={headCells.length + (isCheckRow ? 1 : 0)}
                  align="center"
                  padding="normal"
                >
                  <Typography>{message}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
    </TableContainer>
  );
};

const styles = {
  border: {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    "&:last-child": {
      borderRight: "none",
    },
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  wrap: {
    wordBreak: "break-all",
  },
} as const;
