import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import messages from "config/messages";
import ModalController from "@shared-components/modal/ModalController";

interface IProps {
  // If it returns `false`, the `useRouterPrompt` does not route
  onOK?: () => Promise<boolean> | Promise<void> | boolean | void;
  // If it returns `false`, the `useRouterPrompt` does not route
  onCancel?: () => Promise<boolean> | Promise<void> | boolean | void;
  message?: string;
  okText?: string;
  cancelText?: string;
}

const useRouterPrompt = (props: IProps) => {
  const {
    onOK = () => true,
    onCancel = () => true,
    message = messages.COMMON.MSG_CONFIRM_GO_BACK,
    okText = messages.COMMON.BUTTON.OK,
    cancelText = messages.COMMON.BUTTON.CANCEL,
  } = props;

  const history = useHistory();

  const [isEdited, setIsEdited] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  const blockRef = useRef<Function>();
  const prevIsEditedRef = useRef(false);
  const gobackRef = useRef(false);

  // 遷移制御 --------------------------------------------------
  const overridePopstate = () => {
    gobackRef.current = true;
    setShowPrompt(true);
  };

  useEffect(() => {
    if (isEdited) {
      blockRef.current = history.block((location) => {
        setCurrentPath(location.pathname);
        setShowPrompt(true);
        return "";
      });

      window.history.pushState(null, "", null);
      window.addEventListener("popstate", overridePopstate, false);
    } else {
      blockRef.current = history.block(() => {});
      window.removeEventListener("popstate", overridePopstate, false);
      if (prevIsEditedRef.current) {
        window.history.back();
      }
    }
    prevIsEditedRef.current = isEdited;

    return () => {
      blockRef.current && blockRef.current();
      window.removeEventListener("popstate", overridePopstate, false);
    };
  }, [isEdited]);

  // 画面遷移 --------------------------------------------------
  const doRoute = useCallback(() => {
    // history.blockをクリア
    prevIsEditedRef.current = false;
    setIsEdited(false);

    if (gobackRef.current) {
      blockRef.current && blockRef.current();
      window.removeEventListener("popstate", overridePopstate, false);
      window.history.back();
    } else {
      blockRef.current && blockRef.current();
      window.removeEventListener("popstate", overridePopstate, false);
      window.history.back();
      setTimeout(() => history.push(currentPath), 10);
    }
  }, [currentPath, history, gobackRef.current, blockRef.current]);

  // OK --------------------------------------------------------
  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        doRoute();
        return;
      }
    }
    handleClose();
  }, [onOK, doRoute]);

  // キャンセル ------------------------------------------------
  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        doRoute();
        return;
      }
    }
    handleClose();
  }, [onCancel, doRoute]);

  // 閉じる ----------------------------------------------------
  const handleClose = () => {
    if (gobackRef.current) window.history.pushState(null, "", null);
    setShowPrompt(false);
    gobackRef.current = false;
  };

  // ダイアログ ------------------------------------------------
  useEffect(() => {
    if (showPrompt) {
      ModalController.show({
        message: message,
        visibleButton1: true,
        visibleButton2: true,
        button1: {
          title: cancelText,
        },
        button2: {
          title: okText,
        },
        disableFeedback: true,
        handleFeedback: () => {
          // 閉じる
          handleClose();
        },
        handlePressButton1: () => {
          // キャンセル
          handleCancel();
        },
        handlePressButton2: () => {
          // OK
          handleOK();
        },
      });
    }
  }, [showPrompt]);

  return { setIsEdited, isEdited, gobackRef };
};

export { useRouterPrompt };
