import { getBarcodeLayoutData } from "@api/barcode";
import { STORAGE, storeData } from "@utils/Storage";

export function* setBarcodeSage(action: any): any {
  /**
   * 2次元バーコードレイアウト情報
   */
  try {
    const { location_id } = action.payload;
    // スーパーユーザーは拠点コードがないので処理しない
    if (!location_id) return;
    var data = yield getBarcodeLayoutData(location_id);
    storeData(STORAGE.BARCODE_LAYOUT, JSON.stringify(data?.data));
  } catch (error) {
    console.log(error);
  }
}
