import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { RootState } from "store/reducer";
import { STORAGE } from "@utils/Storage";
import { USER_ROLES } from "@shared-constants";
import { getUserInfo } from "@utils/index";

// Screens
import LoginScreen from "screens/Login/Login";
import AppScreen from "screens/Main";
import WorkScreen from "screens/Work";
import ManageScreen from "screens/Manage";
import ManageFolderScreen from "screens/ManageFolder";
import ListFileScreen from "screens/ManageFolder/ListFileScreen";
import MasterEditScreen from "screens/MasterEdit";
import CategoryEditScreen from "screens/CategoryEdit";
import AccidentClassEditScreen from "screens/ItemReport/AccidentClassEdit";
import PositionEditScreen from "screens/PositionEdit";
import ExteriorEditScreen from "screens/ItemReport/ExteriorEdit";
import PackingEditScreen from "screens/ItemReport/PackingEdit";
import CauseSituationEditScreen from "screens/ItemReport/CauseSituationEdit";
import PrintingEditScreen from "screens/ItemReport/PrintingEdit";
import CompleteEditScreen from "screens/ItemReport/CompleteEdit";
import PlaceEditScreen from "screens/ItemReport/PlaceEdit";
import ProductEditScreen from "screens/ItemReport/ProductEdit";
import GroupLevelScreen from "screens/GroupLevel";
import GroupInfoScreen from "screens/GroupInfo";
import GroupActivityBaseScreen from "screens/GroupActivityBase";
import PublisherEditScreen from "screens/ItemReport/PublisherEdit";
import ProductStatusEditScreen from "screens/ItemReport/ProductStatusEdit";
import AccidentReasonEditScreen from "screens/ItemReport/AccidentReasonEdit";
import AccidentHandlingEditScreen from "screens/ItemReport/AccidentHandlingEdit";
import ShipperEditScreen from "screens/ItemReport/ShipperEdit";
import SpanEditScreen from "screens/ItemReport/SpanEdit";
import ImportDivisionEditScreen from "screens/ItemReport/ImportDivisionEdit";
import ReportScreen from "screens/ReportEdit";
import ReportTypeScreen from "screens/ReportEdit/ReportTypeEdit";
import WorkplaceEditScreen from "screens/ForkliftItem/WorkplaceEdit";
import VehiclesEditScreen from "screens/ForkliftItem/VehiclesEdit";
import ForkliftItemScreen from "screens/ForkliftItemReport";
import FuelEditScreen from "screens/ForkliftItem/Fuel";
import SampleScreen from "screens/Sample";
import MemberListScreen from "screens/MemberList";
import AccountScreen from "screens/Account";
import ExportMasterScreen from "screens/ExportMaster";
// TLOG --->>
// import CreateProjectScreen from "screens/CreateProject";
// import EditProjectScreen from "screens/EditProject";
// import WorkGroupEditScreen from "screens/WorkGroupEdit";
import CreateProjectScreen from "screens/CreateProjectTlog";
import EditProjectScreen from "screens/EditProjectTlog";
import WorkGroupEditScreen from "screens/WorkGroupEditStatus";
// TLOG <<---
import WorkCopyScreen from "screens/WorkCopy";
// TLOG --->>
// import CreateReportScreen from "screens/CreateReport";
// import WorkConfirmScreen from "screens/WorkConfirm";
// import WorkRejectScreen from "screens/WorkReject";
// import PreviewScreen from "screens/Preview";
import CreateReportScreen from "screens/CreateReportTlog";
import WorkConfirmScreen from "screens/WorkConfirmTlog";
import WorkRejectScreen from "screens/WorkRejectTlog";
import PreviewScreen from "screens/PreviewTlog";
import DashboardScreen from "screens/Dashboard";
import WorkStatusScreen from "screens/WorkStatus";
import ProjectListTlogScreen from "screens/ProjectListTlog";
import MailConfirmScreen from "screens/MailConfirm";
import WorkSearchTlogScreen from "screens/WorkSearchTlog";
import DevanImportScreen from "screens/DevanImport";
import DevanListScreen from "screens/DevanList";
import DevanSearch from "screens/DevanSearch/DevanScreen";
import SelectReportScreen from "screens/SelectReport";
import HandoverReportListScreen from "screens/HandoverReportList";
import CustomTemplateListScreen from "screens/CustomTemplateList";
import CustomTemplateTrachScreen from "screens/CustomTemplateTrash";
import ProjectViewTlogScreen from "screens/ProjectViewTlog";
import BarcodeLayoutScreen from "screens/BarcodeLayout";
import CustomMasterListScreen from "screens/CustomMasterList";
import CustomMasterInfoScreen from "screens/CustomMasterInfo";
import MasterImportScreen from "screens/MasterImport";
import LoadingReportImportScreen from "screens/LoadingReportImport";
import ContractLogin from "screens/ContractLogin";
// TLOG <<---

//翻訳用ページ
import TranslationScreen from "screens/Translation";

// PDF復元ページ
import RestorePdfScreen from "screens/RestorePdf";
import TlgtMasterEditScreen from "screens/TlgtMaster";

// ----------------------------------------------------------------------
const NOT_ADMIN_ROLE = [
  USER_ROLES.MEMBER.value,
  USER_ROLES.EDITOR.value,
] as const;

const referrer = [
  { path: "/work", referrer: "/" },
  { path: "/work", referrer: "/project/create" },
  { path: "/work", referrer: "/project/info" },
  { path: "/work", referrer: "/folder" },
  { path: "/work", referrer: "/work/group" },
  { path: "/work", referrer: "/work/create" },
  // TLOG --->>
  { path: "/work-status", referrer: "/" },
  { path: "/work-status", referrer: "/project/create" },
  { path: "/work-status", referrer: "/project/info" },
  { path: "/work-status", referrer: "/folder" },
  { path: "/work-status", referrer: "/work/group" },
  { path: "/work-status", referrer: "/work/create" },
  { path: "/work/group", referrer: "/work-status" },
  { path: "/work/confirm", referrer: "*" },
  { path: "/work/create", referrer: "/" },
  { path: "/work/create", referrer: "/work-status" },
  { path: "/work/create", referrer: "/select-report" },
  { path: "/work/create", referrer: "/select-report/handover" },
  { path: "/project", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/project/info", referrer: "/project" },
  { path: "/project/create", referrer: "/project" },
  { path: "/work/search", referrer: "*" },
  { path: "/devan/import", referrer: "*" },
  { path: "/devan/list", referrer: "*" },
  {
    path: "/devan/search-devan",
    referrer: "*",
  },
  { path: "/loading-report/import", referrer: "*" },
  { path: "/select-report", referrer: "/" },
  { path: "/select-report/handover", referrer: "/select-report" },
  { path: "/custom-template/list", referrer: "*" },
  {
    path: "/custom-template/trash",
    referrer: "/custom-template/list",
    disableRole: [USER_ROLES.MEMBER.value],
  },
  { path: "/project/create", referrer: "/custom-template/list" },
  { path: "/project/create", referrer: "/project/view" },
  { path: "/project/create", referrer: "/project/info" },
  { path: "/project/info", referrer: "/custom-template/list" },
  { path: "/project/view", referrer: "*" },
  { path: "/barcode-layout", referrer: "/project/create" },
  { path: "/barcode-layout", referrer: "/project/info" },
  { path: "/custom-master/list", referrer: "*" },
  { path: "/custom-master/info", referrer: "/custom-master/list" },
  { path: "/master/import-csv", referrer: "*" },
  { path: "/master/export-master", referrer: "*" },
  // TLOG <<---
  { path: "/work/create", referrer: "/work" },
  { path: "/work/create", referrer: "/work/copy" },
  { path: "/work/create", referrer: "/work/confirm" },
  { path: "/work/group", referrer: "/work" },
  { path: "/work/confirm", referrer: "/work" },
  { path: "/work/copy", referrer: "/work" },
  { path: "/work/detail", referrer: "/work" },
  { path: "/work/reject", referrer: "/work/confirm" },
  { path: "/project/create", referrer: "/" },
  { path: "/folder", referrer: "/work" },
  { path: "/folder/detail", referrer: "/folder" },
  { path: "/project/info", referrer: "/work" },
  { path: "/project/info", referrer: "/folder" },
  { path: "/manage", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/master", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/category", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/accident", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/position", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/exterior", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/packing", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/cause", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/printing", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/complete", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/place", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/publisher", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/product_status", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/accident_reason", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/accident_handling", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/shipper", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/span", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/import_division", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/report", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/report_type", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/product", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/account/list", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/account", referrer: "*" },
  { path: "/preview", referrer: "*" },
  { path: "/group/level", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/group/info", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/group/activitybase", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/workplace", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/vehicles", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/forklift-item", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/fuel", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  { path: "/master/tlgt", referrer: "*", disableRole: NOT_ADMIN_ROLE },
  //翻訳用ページ
  { path: "/translation", referrer: "*" },
  { path: "/sample", referrer: "*" },
];

/**
 * 認証前ルート
 */
function GuestRoute(props: any) {
  const isContractLoggedIn = useSelector(
    (state: RootState) => state.contract.is_contract_logged_in,
  );
  const isLoggedIn = useSelector((state: RootState) => state.authInfo.loggedIn);
  const { work_id: mail_work_id, user_id: mail_user_id } = useSelector(
    (state: RootState) => state.mailConfirm.work,
  );
  const is_password_reset = useSelector(
    (state: RootState) => state.authInfo.is_password_reset,
  );
  const token = localStorage.getItem(STORAGE.ACCESS_TOKEN);
  const user_info = getUserInfo(); // Phase1

  return isContractLoggedIn ? (
    isLoggedIn && token && !is_password_reset ? (
      // Phase1 delete
      // // 案件一覧
      // <Route {...props} component={AppScreen} />
      mail_work_id != "" ? (
        mail_user_id == user_info.SK ? (
          // メール通知ユーザーがログインしたとき
          // 作業内容確認
          <Redirect to="/work/confirm" />
        ) : (
          // メール通知ユーザー以外がログインしたとき
          // 作業内容確認
          <Redirect to="/work/confirm" />
        )
      ) : user_info.user_role === USER_ROLES.OWNER.value ? (
        // 管理者メニュー
        <Redirect to="/manage" />
      ) : (
        // ダッシュボード
        <Route {...props} component={DashboardScreen} />
      )
    ) : (
      // ログイン
      <Route {...props} component={LoginScreen} />
    )
  ) : (
    // 契約決定
    <Route {...props} component={ContractLogin} />
    // return <Route {...props} component={LoginScreen} />;
  );
}

/**
 * 認証後ルート
 */
function PrivateRoute(props: any) {
  const isLoggedIn = useSelector((state: RootState) => state.authInfo.loggedIn);
  const isContractLoggedIn = useSelector(
    (state: RootState) => state.contract.is_contract_logged_in,
  );
  const token = localStorage.getItem(STORAGE.ACCESS_TOKEN);
  const location = useLocation();
  const state: any = location.state;
  const user_info = getUserInfo();

  // return isLoggedIn ? <Route {...props} /> : <Redirect to="/" />;

  if (!isLoggedIn || !token || !isContractLoggedIn) return <Redirect to="/" />;

  const newReferrer = referrer.find(
    (item) =>
      item.path === location.pathname &&
      ((state && item.referrer === state.referrer) || item.referrer === "*"),
  );

  // check user role, if user role = member -> deny access /manage screen
  if (newReferrer) {
    if (
      !newReferrer.disableRole ||
      newReferrer.disableRole.indexOf(user_info.user_role) < 0
    )
      return <Route {...props} />;
  }

  return <Redirect to="/" />;
}

export default function Router() {
  return (
    <Switch>
      <GuestRoute exact path="/" />
      <PrivateRoute exact path="/work" children={<WorkScreen />} />
      <PrivateRoute
        exact
        path="/work/create"
        children={<CreateReportScreen />}
      />
      <PrivateRoute
        exact
        path="/work/group"
        children={<WorkGroupEditScreen />}
      />
      <PrivateRoute exact path="/work/copy" children={<WorkCopyScreen />} />
      <PrivateRoute
        exact
        path="/work/confirm"
        children={<WorkConfirmScreen />}
      />
      <PrivateRoute exact path="/work/reject" children={<WorkRejectScreen />} />
      <PrivateRoute exact path="/manage" children={<ManageScreen />} />
      <PrivateRoute exact path="/folder" children={<ManageFolderScreen />} />
      <PrivateRoute exact path="/folder/detail" children={<ListFileScreen />} />
      <PrivateRoute
        exact
        path="/project/create"
        children={<CreateProjectScreen />}
      />
      <PrivateRoute
        exact
        path="/project/info"
        children={<EditProjectScreen />}
      />
      <PrivateRoute exact path="/master" children={<MasterEditScreen />} />
      <PrivateRoute exact path="/category" children={<CategoryEditScreen />} />
      <PrivateRoute
        exact
        path="/accident"
        children={<AccidentClassEditScreen />}
      />
      <PrivateRoute exact path="/position" children={<PositionEditScreen />} />
      <PrivateRoute exact path="/exterior" children={<ExteriorEditScreen />} />
      <PrivateRoute exact path="/packing" children={<PackingEditScreen />} />
      <PrivateRoute
        exact
        path="/cause"
        children={<CauseSituationEditScreen />}
      />
      <PrivateRoute exact path="/printing" children={<PrintingEditScreen />} />
      <PrivateRoute exact path="/complete" children={<CompleteEditScreen />} />
      <PrivateRoute exact path="/place" children={<PlaceEditScreen />} />
      <PrivateRoute exact path="/group/level" children={<GroupLevelScreen />} />
      <PrivateRoute exact path="/group/info" children={<GroupInfoScreen />} />
      <PrivateRoute
        ACTIVITY_BASE
        exact
        path="/group/activitybase"
        children={<GroupActivityBaseScreen />}
      />
      <PrivateRoute
        exact
        path="/publisher"
        children={<PublisherEditScreen />}
      />
      <PrivateRoute
        exact
        path="/product_status"
        children={<ProductStatusEditScreen />}
      />
      <PrivateRoute
        exact
        path="/accident_reason"
        children={<AccidentReasonEditScreen />}
      />
      <PrivateRoute
        exact
        path="/accident_handling"
        children={<AccidentHandlingEditScreen />}
      />
      <PrivateRoute exact path="/shipper" children={<ShipperEditScreen />} />
      <PrivateRoute exact path="/span" children={<SpanEditScreen />} />
      <PrivateRoute
        exact
        path="/import_division"
        children={<ImportDivisionEditScreen />}
      />
      <PrivateRoute exact path="/report" children={<ReportScreen />} />
      <PrivateRoute exact path="/report_type" children={<ReportTypeScreen />} />
      <PrivateRoute exact path="/product" children={<ProductEditScreen />} />
      <PrivateRoute
        exact
        path="/workplace"
        children={<WorkplaceEditScreen />}
      />
      <PrivateRoute exact path="/vehicles" children={<VehiclesEditScreen />} />
      <PrivateRoute
        exact
        path="/forklift-item"
        children={<ForkliftItemScreen />}
      />
      <PrivateRoute exact path="/fuel" children={<FuelEditScreen />} />
      <PrivateRoute
        exact
        path="/account/list"
        children={<MemberListScreen />}
      />
      <PrivateRoute exact path="/account" children={<AccountScreen />} />
      <PrivateRoute exact path="/work-status" children={<WorkStatusScreen />} />
      <PrivateRoute
        exact
        path="/project"
        children={<ProjectListTlogScreen />}
      />
      <PrivateRoute
        exact
        path="/translation"
        children={<TranslationScreen />}
      />

      <Route path="/preview" children={<PreviewScreen />} />
      <Route
        path="/mail-confirm/:work_id/:user_id"
        children={<MailConfirmScreen />}
      />
      <PrivateRoute
        exact
        path="/work/search"
        children={<WorkSearchTlogScreen />}
      />
      <PrivateRoute
        exact
        path="/devan/import"
        children={<DevanImportScreen />}
      />
      <PrivateRoute exact path="/devan/list" children={<DevanListScreen />} />
      <PrivateRoute
        exact
        path="/devan/search-devan"
        children={<DevanSearch />}
      />
      <PrivateRoute
        exact
        path="/loading-report/import"
        children={<LoadingReportImportScreen />}
      />
      <PrivateRoute
        exact
        path="/select-report"
        children={<SelectReportScreen />}
      />
      <PrivateRoute
        exact
        path="/select-report/handover"
        children={<HandoverReportListScreen />}
      />
      <PrivateRoute
        exact
        path="/custom-template/list"
        children={<CustomTemplateListScreen />}
      />
      <PrivateRoute
        exact
        path="/custom-template/trash"
        children={<CustomTemplateTrachScreen />}
      />
      <PrivateRoute
        exact
        path="/project/view"
        children={<ProjectViewTlogScreen />}
      />
      <PrivateRoute
        exact
        path="/barcode-layout"
        children={<BarcodeLayoutScreen />}
      />
      <PrivateRoute
        exact
        path="/custom-master/list"
        children={<CustomMasterListScreen />}
      />
      <PrivateRoute
        exact
        path="/custom-master/info"
        children={<CustomMasterInfoScreen />}
      />
      <PrivateRoute
        exact
        path="/master/import-csv"
        children={<MasterImportScreen />}
      />
      <PrivateRoute
        exact
        path="/master/export-master"
        children={<ExportMasterScreen />}
      />
      <PrivateRoute
        exact
        path="/master/tlgt"
        children={<TlgtMasterEditScreen />}
      />
      <Route path="/sample" children={<SampleScreen />} />
      <Route path="/restore-pdf" children={<RestorePdfScreen />} />
    </Switch>
  );
}
