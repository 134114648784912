import React, { Fragment, useState } from "react";
import {
  Box,
  Button,
  InputLabel,
  Paper,
} from "@mui/material";
import Dropzone from "components/atoms/Dropzone";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import { createObjectURL } from "@utils/index";
import { StateFormType } from "@utils/template/receivingProblemTag";
import { Colors } from "@template/style";



const imageDefault: Type.ImageInfoType = {
  type: "",
  uri: "",
  name: "",
  path_file: "",
};

interface CustomImagePickerProps {
  title: string;
  props: [StateFormType, Function];
  maxImage: number;
  imageArrayName: keyof StateFormType;
}

const CustomImagePicker = ({ title, props, maxImage, imageArrayName } : CustomImagePickerProps) => {

  const [stateForm, setStateForm] = props;


  const handleAddImageItem = () => {
    if (stateForm?.[imageArrayName].length < maxImage) {
      setStateForm((prevState : any) => {
        const new_image_array = [...prevState?.[imageArrayName], imageDefault];
        return { ...prevState, [imageArrayName]: new_image_array };
      });
    }
  };


  const handleChangeImage = (index: number,  imageInfo: Type.ImageInfoType,) => {
    const newStateForm: StateFormType = { ...stateForm };
    newStateForm[imageArrayName][index] = imageInfo;
    setStateForm(newStateForm);
  }



  const handleDeleteImageItems = (deleteIndex: number) => {
    if (deleteIndex >= 0) {
      const newStateForm: StateFormType = { ...stateForm };
      newStateForm[imageArrayName].splice(deleteIndex, 1);
      setStateForm(newStateForm);
    }
  };

  return (
    <>
      <Box sx={{ bgcolor: Colors.YELLOW, width: "100%", mb: "2px", p: "4px" }}>
          <div style={{ "textAlign": "center" }}>{ title }</div>
      </Box>
      {stateForm?.[imageArrayName].map((item: any, index :number) => (
        <Fragment  key={index}>
          <CancelBtnBadge
            onClick={() => {
              if (item.uri !== "") {
                handleChangeImage(index, imageDefault);
              } else {
                ModalController.show({
                  message: "報告内容を削除しますか?",
                  visibleButton1: true,
                  visibleButton2: true,
                  button1: { title: messages.COMMON.BUTTON.CANCEL },
                  handlePressButton2: () => handleDeleteImageItems(index),
                });
              }
            }}
            key={index}
            sx={{ width: "100%" }}
            componentsProps={{ badge: { style: { right: "7%" } } }}
          >
            {item.uri !== "" ? (
              <Box
                component="img"
                src={
                  item?.type === "image/svg+xml"
                    ? item?.non_Local
                      ? item.uri_jpg
                      : item.uriThumb
                    : item?.uri
                }
                sx={{
                  height: 140,
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: 1,
                }}
              />
            ) : (
              <Box width={"100%"}>
                <Dropzone
                  onChoose={(res) => {
                    const uri = createObjectURL(res[0]);
                    res[0]["uri"] = uri;
                    handleChangeImage(index, res[0]);
                  }}
                  maxFiles={1}
                  showFileList={false}
                  key={index}
                    />
              </Box>
            )}
          </CancelBtnBadge>
        </Fragment>
        ))
      }

      <Button
          onClick={handleAddImageItem}
          disabled={stateForm?.[imageArrayName].length >= maxImage}
        >
          Add photos
      </Button>
    </>
  )
}

export default CustomImagePicker;
