import { HeadCell } from "components/organisms/SortableTable/SortableTable";
export const columnsInfo: { [key: string]: { key: string; header: string }[] } =
  {
    RISKY_REPORT: [
      { key: "report_type", header: "帳票種類" },
      { key: "project_id", header: "帳票" },
      { key: "applied_at", header: "申請日" },
      { key: "approved_at", header: "最終承認日" },
      { key: "updated_at", header: "最終更新日" },
      { key: "work_status_name", header: "帳票状況" },
      { key: "approver", header: "承認者" },
      { key: "created_by_name", header: "申請者" },
      { key: "risky_id", header: "帳票番号" },
      { key: "flow_approve_name", header: "承認ルート" },
      { key: "place_found_issue", header: "発行元" },
      { key: "incident_date", header: "発生年月日" },
      { key: "issue_place_floor", header: "発生場所フロア" },
      { key: "issue_place_span", header: "発生場所スパン" },
      { key: "issue_type", header: "事故区分" },
      { key: "responsible_person", header: "事故当事者" },
      { key: "person_found_issue", header: "発見者氏名" },
      { key: "product_type", header: "分類" },
      { key: "product_name", header: "商品名" },
      { key: "amount_of_product", header: "数量" },
      { key: "product_id", header: "機番" },
      { key: "product_packaging_status", header: "荷姿状態" },
      { key: "reason_cause_issue", header: "事故発生理由" },
      { key: "warehouse_place_floor", header: "格納場所フロア" },
      { key: "warehouse_place_span", header: "格納場所スパン" },
      { key: "bad_place", header: "不良ロケ" },
      { key: "importance_type", header: "重要度区分" },
      { key: "date_shipper_take", header: "荷主報告日時" },
      { key: "shipper_name", header: "荷主報告者" },
      { key: "receiver", header: "報告相手" },
      { key: "instruction_solve_issue", header: "事故処理（指示内容）" },
      { key: "completion_confirm_date", header: "処理完了確認（日付）" },
      {
        key: "completion_confirm_person_in_charge",
        header: "処理完了確認（担当）",
      },
      { key: "expense_claim", header: "事故費請求費用" },
      { key: "is_image_exist", header: "写真添付有無" },
    ],
    INSPECTION_REPORT: [
      { key: "risky_id", header: "帳票番号" },
      { key: "report_type", header: "帳票種類" },
      { key: "project_id", header: "帳票" },
      { key: "applied_at", header: "申請日" },
      { key: "approved_at", header: "最終承認日" },
      { key: "updated_at", header: "最終更新日" },
      { key: "work_status_name", header: "帳票状況" },
      { key: "approver", header: "承認者" },
      { key: "created_by_name", header: "申請者" },
      { key: "flow_approve_name", header: "承認ルート" },
      { key: "date_of_delivery", header: "搬入年月日" },
      { key: "container_no", header: "コンテナ番号" },
      { key: "container_size", header: "コンテナサイズ" },
      { key: "delivery_time", header: "納入時間" },
      { key: "carrier_name", header: "搬入者名" },
      { key: "unit_class", header: "Unit区分" },
      { key: "delivery_location", header: "搬入場所" },
      { key: "invoice_no", header: "インボイスナンバー" },
      { key: "seal_no", header: "シールNO." },
      { key: "berth_number", header: "バース番号" },
      { key: "sealed", header: "封印" },
      { key: "person_worker", header: "作業者名" },
      { key: "container_status", header: "コンテナの状態" },
      { key: "product_class", header: "入荷予定情報・商品分類" },
      { key: "model_name_1", header: "入荷予定情報・機種名" },
      { key: "number_of_moves", header: "入荷予定情報・搬入数" },
      { key: "unpacked_number", header: "入荷予定情報・開梱対象総台数" },
      { key: "no_abnomality_number", header: "入荷予定情報・開梱後異常なし" },
      { key: "model_name", header: "製品の状態・機種名" },
      { key: "count", header: "製品の状態・数量カウント" },
      { key: "good_products_number", header: "製品の状態・良品数" },
      { key: "defective_products_number", header: "製品の状態・不適合数" },
      { key: "total_number", header: "製品の状態・合計" },
      { key: "memo", header: "備考" },
      //{ key: "defective_products0", header: "製品の状態・不適合数内容①数量" },//defective_productsにある分出力するので、プログラムで追加
      { key: "unit_abnomality_report", header: "異常報告" }, //旧異常報告{ key: "abnomality_report", header: "異常報告" }
      {
        key: "bool_attention",
        header: "V.2段積み冷蔵庫、3段積み洗濯機は2人作業を実施",
      },
      { key: "is_image_exist", header: "写真添付有無" },
    ],
    TEMPLATE_FORKLIFT_REPORT: [
      { key: "risky_id", header: "帳票番号" },
      { key: "report_type", header: "帳票種類" },
      { key: "project_id", header: "帳票" },
      { key: "applied_at", header: "申請日" },
      { key: "approved_at", header: "最終承認日" },
      { key: "updated_at", header: "最終更新日" },
      { key: "work_status_name", header: "帳票状況" },
      { key: "approver", header: "承認者" },
      { key: "created_by_name", header: "申請者" },
      { key: "flow_approve_name", header: "承認ルート" },
      { key: "vehicles_frame_no", header: "車体番号" },
      { key: "vehicles_workplace", header: "事業場名" },
      { key: "vehicles_model", header: "車種名" },
      { key: "vehicles_fuel_type", header: "燃料" },
      { key: "vehicles_name", header: "車両番号" },
      { key: "driver", header: "担当者名" },
      { key: "year_month", header: "実施年月" },
      { key: "section", header: "点検箇所" },
      { key: "item", header: "点検項目" },
      { key: "result_1", header: "1日結果" },
      { key: "check_user_1", header: "1日点検実施者名" },
      { key: "result_2", header: "2日結果" },
      { key: "check_user_2", header: "2日点検実施者名" },
      { key: "result_3", header: "3日結果" },
      { key: "check_user_3", header: "3日点検実施者名" },
      { key: "result_4", header: "4日結果" },
      { key: "check_user_4", header: "4日点検実施者名" },
      { key: "result_5", header: "5日結果" },
      { key: "check_user_5", header: "5日点検実施者名" },
      { key: "result_6", header: "6日結果" },
      { key: "check_user_6", header: "6日点検実施者名" },
      { key: "result_7", header: "7日結果" },
      { key: "check_user_7", header: "7日点検実施者名" },
      { key: "result_8", header: "8日結果" },
      { key: "check_user_8", header: "8日点検実施者名" },
      { key: "result_9", header: "9日結果" },
      { key: "check_user_9", header: "9日点検実施者名" },
      { key: "result_10", header: "10日結果" },
      { key: "check_user_10", header: "10日点検実施者名" },
      { key: "result_11", header: "11日結果" },
      { key: "check_user_11", header: "11日点検実施者名" },
      { key: "result_12", header: "12日結果" },
      { key: "check_user_12", header: "12日点検実施者名" },
      { key: "result_13", header: "13日結果" },
      { key: "check_user_13", header: "13日点検実施者名" },
      { key: "result_14", header: "14日結果" },
      { key: "check_user_14", header: "14日点検実施者名" },
      { key: "result_15", header: "15日結果" },
      { key: "check_user_15", header: "15日点検実施者名" },
      { key: "result_16", header: "16日結果" },
      { key: "check_user_16", header: "16日点検実施者名" },
      { key: "result_17", header: "17日結果" },
      { key: "check_user_17", header: "17日点検実施者名" },
      { key: "result_18", header: "18日結果" },
      { key: "check_user_18", header: "18日点検実施者名" },
      { key: "result_19", header: "19日結果" },
      { key: "check_user_19", header: "19日点検実施者名" },
      { key: "result_20", header: "20日結果" },
      { key: "check_user_20", header: "20日点検実施者名" },
      { key: "result_21", header: "21日結果" },
      { key: "check_user_21", header: "21日点検実施者名" },
      { key: "result_22", header: "22日結果" },
      { key: "check_user_22", header: "22日点検実施者名" },
      { key: "result_23", header: "23日結果" },
      { key: "check_user_23", header: "23日点検実施者名" },
      { key: "result_24", header: "24日結果" },
      { key: "check_user_24", header: "24日点検実施者名" },
      { key: "result_25", header: "25日結果" },
      { key: "check_user_25", header: "25日点検実施者名" },
      { key: "result_26", header: "26日結果" },
      { key: "check_user_26", header: "26日点検実施者名" },
      { key: "result_27", header: "27日結果" },
      { key: "check_user_27", header: "27日点検実施者名" },
      { key: "result_28", header: "28日結果" },
      { key: "check_user_28", header: "28日点検実施者名" },
      { key: "result_29", header: "29日結果" },
      { key: "check_user_29", header: "29日点検実施者名" },
      { key: "result_30", header: "30日結果" },
      { key: "check_user_30", header: "30日点検実施者名" },
      { key: "result_31", header: "31日結果" },
      { key: "check_user_31", header: "31日点検実施者名" },
    ],
    TEMPLATE_EXCEL_REPORT: [
      { key: "risky_id", header: "帳票番号" },
      { key: "report_type", header: "帳票種類" },
      { key: "project_id", header: "帳票" },
      { key: "applied_at", header: "申請日" },
      { key: "approved_at", header: "最終承認日" },
      { key: "updated_at", header: "最終更新日" },
      { key: "work_status_name", header: "帳票状況" },
      { key: "approver", header: "承認者" },
      { key: "created_by_name", header: "申請者" },
      { key: "flow_approve_name", header: "承認ルート" },
      { key: "is_image_exist", header: "写真添付有無" },
      { key: "is_attached_file", header: "添付ファイル有無" },
    ],
    LOADING_REPORT: [
      { key: "risky_id", header: "帳票番号" },
      { key: "report_type", header: "帳票種類" },
      { key: "project_id", header: "帳票" },
      { key: "applied_at", header: "申請日" },
      { key: "approved_at", header: "最終承認日" },
      { key: "updated_at", header: "最終更新日" },
      { key: "work_status_name", header: "帳票状況" },
      { key: "approver", header: "承認者" },
      { key: "created_by_name", header: "申請者" },
      { key: "flow_approve_name", header: "承認ルート" },
      { key: "loading_date", header: "Loading Date(YYYY/MM/DD)" },
      { key: "warehouse", header: "Warehouse" },
      { key: "load_by", header: "Load by" },
      { key: "invoice_no", header: "Invoice No." },
      { key: "booking_no", header: "Booking No." },
      { key: "commodity", header: "Comomdity" },
      { key: "container_type", header: "Container Type" },
      { key: "container_no", header: "Container No." },
      { key: "seal_no", header: "Seal No." },
      { key: "remark", header: "Remark" },
      { key: "model", header: "Model Name" },
      { key: "qty", header: "Quantity" },
      { key: "total_qty", header: "Total Quantity" },
      { key: "reserve_status", header: "Reserve Status" },
      { key: "is_image_exist", header: "写真添付有無" },
    ],
    TEMPLATE_INCIDENT_DAMAGE_REPORT: [
      { key: "risky_id", header: "ReportNo." },
      { key: "report_type", header: "帳票種類" },
      { key: "project_id", header: "帳票" },
      { key: "applied_at", header: "申請日" },
      { key: "approved_at", header: "最終承認日" },
      { key: "updated_at", header: "最終更新日" },
      { key: "work_status_name", header: "帳票状況" },
      { key: "approver", header: "承認者" },
      { key: "created_by_name", header: "申請者" },
      { key: "flow_approve_name", header: "承認ルート" },
      { key: "date", header: "Date(YYYY/MM/DD)" },
      { key: "time", header: "Time" },
      { key: "warehouse", header: "Warehouse" },
      { key: "area", header: "Area" },
      { key: "reported_by", header: "Reported by" },
      { key: "customer", header: "Customer (Shipper)" },
      { key: "commodity", header: "Comomdity" },
      { key: "model_name", header: "Model Name" },
      { key: "quantity", header: "Quantity" },
      { key: "serial_no_n", header: "Serial No." },
      { key: "damage_location", header: "Damage Location" },
      { key: "cause", header: "Cause (Site Opinion)" },
      { key: "qa_authorizer", header: "QA Authorizer" },
      { key: "occurred_company", header: "Occurred Company" },
      { key: "invoice_no", header: "Invoice No." },
      { key: "affiliation_of_employee", header: "Employee's Affiliation" },
      {
        key: "experience_of_employee_years",
        header: "Employee's Experience Years",
      },
      {
        key: "experience_of_employee_months",
        header: "Employee's Experience Months",
      },
      { key: "system_transaction_no", header: "System Transaction No." },
      { key: "waste_claim_category", header: "Waste /Claim Category" },
      { key: "accident_rank", header: "Accident Rank" },
      { key: "cpar_no", header: "CPAR No." },
      { key: "nonconformity_detail", header: "Nonconformity Detail" },
      { key: "nonconformity_overview", header: "Nonconformity Overview" },
      { key: "occurance_process", header: "Occurance Process" },
      { key: "process_of_root_cause", header: "Process of Root Cause" },
      { key: "responsibility", header: "Responsibility" },
      { key: "department_of_pic", header: "Department of PIC" },
      { key: "process_in_logistics", header: "Process in Logistics" },
      { key: "responsibility_in_process", header: "Responsibility in Process" },
      {
        key: "classification_of_nonconformity",
        header: "Classification of Nonconformity",
      },
      { key: "classification_of_cause", header: "Classification of Cause" },
      { key: "cause_overview", header: "Cause Overview" },
      { key: "root_cause", header: "Root Cause" },
      { key: "logistics_equipments", header: "Logistics Equipments" },
      {
        key: "corrective_action_improvement",
        header: "Corrective Action /Improvement",
      },
      {
        key: "completion_improvement_date",
        header: "Completion improvement Date",
      },
      { key: "scrap_unit", header: "Scrap Unit" },
      { key: "repair_unit", header: "Repair Unit" },
      { key: "no_problem_unit", header: "No Problem Unit" },
      { key: "final_payer", header: "Final Payer" },
      { key: "expected_payment_month", header: "Expected Payment Month" },
      { key: "est_acc", header: "Est/Acc" },
      { key: "amount_cost_est", header: "Amount Cost (Est)" },
      { key: "amount_cost_act", header: "Amount Cost (Act)" },
      { key: "actual_payment_month", header: "Actual Payment Month" },
      { key: "insurance_sub_con_amount", header: "Insurance ,Sub-con Amount" },
      { key: "final_tlgt_amount", header: "Final TLGT Amount" },
      { key: "received_invoice_no", header: "Receivied Invoice No." },
      { key: "closing_date", header: "Closing Date" },
      { key: "remark", header: "Remark" },
      { key: "is_image_exist", header: "写真添付有無" },
    ],
    TEMPLATE_RECEIVING_PROBLEM_TAG: [
      { key: "risky_id", header: "帳票番号" },
      { key: "report_type", header: "帳票種類" },
      { key: "project_id", header: "帳票" },
      { key: "applied_at", header: "申請日" },
      { key: "approved_at", header: "最終承認日" },
      { key: "updated_at", header: "最終更新日" },
      { key: "work_status_name", header: "帳票状況" },
      { key: "approver", header: "承認者" },
      { key: "created_by_name", header: "申請者" },
      { key: "flow_approve_name", header: "承認ルート" },
      { key: "date", header: "Date(YYYY/MM/DD)" },
      { key: "time", header: "Time" },
      { key: "model_name", header: "Model Name" },
      { key: "quantity", header: "Quantity" },
      { key: "tl_serial_no_n", header: "Serial No." },
      { key: "reported_by", header: "Reported by" },
      { key: "defect", header: "Defect" },
      { key: "damage_location", header: "Damage Location" },
      { key: "ctc_qa_judgement", header: "CTC QA Judgement" },
      { key: "ctc_qa_inspector_by", header: "CTC QA Inspector by" },
      { key: "ctc_checked_date", header: "CTC Checked Date(YYYY/MM/DD)" },
      { key: "checked_quantity", header: "Checked Quantity" },
      { key: "qa_serial_no", header: "Serial No." },
      { key: "ctc_ma_repair_by", header: "CTC MA Repair by" },
      { key: "ctc_ma_inspector_by", header: "CTC MA Inspector by" },
      { key: "ctc_inspection_date", header: "CTC Inspection Date(YYYY/MM/DD)" },
      { key: "repaired_quantity", header: "Repaired Quantity" },
      { key: "repaired_date", header: "Repaired Date(YYYY/MM/DD)" },
      { key: "returning_quantity", header: "Returning Quantity" },
      { key: "repaired_ac_receive_by", header: "Repaired AC Received by" },
      { key: "returning_time", header: "Returning Time" },
      { key: "is_image_exist", header: "写真添付有無" },
    ],
    EXTERIOR_DAMAGED_TC_REPORT: [
      { key: "risky_id", header: "帳票番号" },
      { key: "report_type", header: "帳票種類" },
      { key: "project_id", header: "帳票" },
      { key: "applied_at", header: "申請日" },
      { key: "approved_at", header: "最終承認日" },
      { key: "updated_at", header: "最終更新日" },
      { key: "work_status_name", header: "帳票状況" },
      { key: "approver", header: "承認者" },
      { key: "created_by_name", header: "申請者" },
      { key: "flow_approve_name", header: "承認ルート" },
      { key: "date", header: "日付" },
      { key: "arrival_date", header: "入荷日" },
      { key: "exterior_damaged_location", header: "外装破損発見場所" },
      { key: "written_by", header: "庫内記入者" },
      { key: "checked_by", header: "確認者" },
      { key: "jan_code", header: "JANコード" },
      { key: "supplier_code", header: "仕入先コード" },
      { key: "supplier_name", header: "仕入先名" },
      { key: "product_name", header: "商品名" },
      { key: "product_type", header: "型名" },
      { key: "total", header: "総外装破損数量" },
      { key: "status_1", header: "外装破損状況_1" },
      { key: "status_text_1", header: "外装破損状況_1" },
      { key: "status_2", header: "外装破損状況_2" },
      { key: "status_text_2", header: "外装破損状況_2" },
      { key: "status_3", header: "外装破損状況_3" },
      { key: "status_text_3", header: "外装破損状況_3" },
      { key: "status_4", header: "外装破損状況_4" },
      { key: "status_text_4", header: "外装破損状況_4" },
      { key: "status_5", header: "外装破損状況_5" },
      { key: "status_text_5", header: "外装破損状況_5" },
      { key: "status_6", header: "外装破損状況_6" },
      { key: "status_text_6", header: "外装破損状況_6" },
      { key: "purchasing", header: "仕入れました" },
      { key: "order_customer_count", header: "お客様注文商品数量" },
      { key: "order_replenishment_count", header: "補充商品、割当商品数量" },
      { key: "non_purchasing", header: "仕入不可" },
      { key: "non_purchasing_count", header: "仕入不可商品数量" },
      { key: "memo", header: "備考" },
      { key: "item_check_user", header: "現品確認" },
      { key: "store_check_user", header: "格納確認" },
      { key: "is_image_exist", header: "写真添付有無" },
    ],
    EXTERIOR_DAMAGED_DC_REPORT: [
      { key: "risky_id", header: "帳票番号" },
      { key: "report_type", header: "帳票種類" },
      { key: "project_id", header: "帳票" },
      { key: "applied_at", header: "申請日" },
      { key: "approved_at", header: "最終承認日" },
      { key: "updated_at", header: "最終更新日" },
      { key: "work_status_name", header: "帳票状況" },
      { key: "approver", header: "承認者" },
      { key: "created_by_name", header: "申請者" },
      { key: "flow_approve_name", header: "承認ルート" },
      { key: "date", header: "日付" },
      { key: "arrival_date", header: "入荷日" },
      { key: "exterior_damaged_location", header: "外装破損発見場所" },
      { key: "written_by", header: "庫内記入者" },
      { key: "checked_by", header: "確認者" },
      { key: "jan_code", header: "JANコード" },
      { key: "supplier_code", header: "仕入先コード" },
      { key: "supplier_name", header: "仕入先名" },
      { key: "product_name", header: "商品名" },
      { key: "product_type", header: "型名" },
      { key: "total", header: "総外装破損数量" },
      { key: "status_1", header: "外装破損状況_1" },
      { key: "status_text_1", header: "外装破損状況_1" },
      { key: "status_2", header: "外装破損状況_2" },
      { key: "status_text_2", header: "外装破損状況_2" },
      { key: "status_3", header: "外装破損状況_3" },
      { key: "status_text_3", header: "外装破損状況_3" },
      { key: "status_4", header: "外装破損状況_4" },
      { key: "status_text_4", header: "外装破損状況_4" },
      { key: "status_5", header: "外装破損状況_5" },
      { key: "status_text_5", header: "外装破損状況_5" },
      { key: "status_6", header: "外装破損状況_6" },
      { key: "status_text_6", header: "外装破損状況_6" },
      { key: "purchasing", header: "仕入れました" },
      { key: "order_customer_count", header: "お客様注文商品数量" },
      { key: "order_replenishment_count", header: "補充商品、割当商品数量" },
      { key: "non_purchasing", header: "仕入不可" },
      { key: "non_purchasing_count", header: "仕入不可商品数量" },
      { key: "memo", header: "備考" },
      { key: "item_check_user", header: "現品確認" },
      { key: "store_check_user", header: "格納確認" },
      { key: "is_image_exist", header: "写真添付有無" },
    ],
  };

export const SEALED_MESSAGE = {
  TRUE: "異常あり",
  FALSE: "異常なし",
};

export const headCells: { [key: string]: HeadCell[] } = {
  RISKY_REPORT: [
    { id: "risky_id", label: "事故番号", sortable: true, width: 200 },
    { id: "product_name", label: "品名", sortable: true, width: 250 },
    {
      id: "work_status_name",
      label: "状況",
      sortable: true,
      width: 100,
    },
    {
      id: "applied_at",
      label: "申請日",
      sortable: true,
      width: 150,
    },
    {
      id: "created_by_name",
      label: "申請者",
      sortable: true,
      width: 200,
    },
    {
      id: "is_image_array_text",
      label: "添付写真",
      sortable: true,
      width: 200,
    },
    {
      id: "btn_detail",
      label: "",
      sortable: false,
      width: 100,
      disablePadding: true,
    },
  ],
  INSPECTION_REPORT: [
    { id: "risky_id", label: "帳票番号", sortable: true, width: 150 },
    { id: "date_of_delivery", label: "搬入年月日", sortable: true, width: 100 },
    {
      id: "container_no",
      label: "コンテナ番号",
      sortable: true,
      width: 150,
    },
    {
      id: "work_status_name",
      label: "状況",
      sortable: true,
      width: 50,
    },
    {
      id: "applied_at",
      label: "申請日",
      sortable: true,
      width: 150,
    },
    {
      id: "created_by_name",
      label: "申請者",
      sortable: true,
      width: 200,
    },
    {
      id: "product_class_text",
      label: "商品分類",
      sortable: true,
      width: 200,
    },
    {
      id: "is_image_array_text",
      label: "添付写真",
      sortable: true,
      width: 200,
    },
    {
      id: "btn_detail",
      label: "",
      sortable: false,
      width: 100,
      disablePadding: true,
    },
  ],
  TEMPLATE_FORKLIFT_REPORT: [
    { id: "risky_id", label: "帳票番号", sortable: true, width: 150 },
    { id: "vehicles_frame_no", label: "車体番号", sortable: true, width: 150 },
    { id: "vehicles_workplace", label: "事業場名", sortable: true, width: 200 },
    {
      id: "work_status_name",
      label: "状況",
      sortable: true,
      width: 50,
    },
    {
      id: "applied_at",
      label: "申請日",
      sortable: true,
      width: 150,
    },
    {
      id: "created_by_name",
      label: "申請者",
      sortable: true,
      width: 100,
    },
    {
      id: "btn_detail",
      label: "",
      sortable: false,
      width: 100,
      disablePadding: true,
    },
  ],
  TEMPLATE_EXCEL_REPORT: [
    { id: "risky_id", label: "帳票番号", sortable: true, width: 150 },
    {
      id: "work_status_name",
      label: "状況",
      sortable: true,
      width: 50,
    },
    {
      id: "applied_at",
      label: "申請日",
      sortable: true,
      width: 150,
    },
    {
      id: "created_by_name",
      label: "申請者",
      sortable: true,
      width: 100,
    },
    {
      id: "is_image_array_text",
      label: "添付写真",
      sortable: true,
      width: 200,
    },
    {
      id: "is_attached_file_text",
      label: "添付ファイル",
      sortable: true,
      width: 200,
    },
    {
      id: "btn_detail",
      label: "",
      sortable: false,
      width: 100,
      disablePadding: true,
    },
  ],
  LOADING_REPORT: [
    { id: "risky_id", label: "Report No.", sortable: true, width: 150 },
    {
      id: "loading_date",
      label: "Loading Date  (YYYY/MM/DD)",
      sortable: true,
      width: 150,
    },
    {
      id: "warehouse",
      label: "Warehouse",
      sortable: true,
      width: 100,
    },
    {
      id: "commodity",
      label: "Comomdity",
      sortable: true,
      width: 100,
    },
    {
      id: "load_by",
      label: "Load by",
      sortable: true,
      width: 130,
    },
    {
      id: "invoice_no",
      label: "Invoice No.",
      sortable: true,
      width: 150,
    },
    {
      id: "container_no",
      label: "Container No.",
      sortable: true,
      width: 150,
    },
    {
      id: "model",
      label: "Model",
      sortable: true,
      width: 130,
    },
    {
      id: "qty",
      label: "Quantity",
      sortable: true,
      width: 80,
      numeric: true,
    },
    {
      id: "work_status_name",
      label: "Status",
      sortable: true,
      width: 50,
    },
    {
      id: "reserve_status",
      label: "Reserve Status",
      sortable: true,
      width: 170,
    },
    {
      id: "is_image_array_text",
      label: "Photo Attachment Status",
      sortable: true,
      width: 200,
    },
    {
      id: "btn_detail",
      label: "",
      sortable: false,
      width: 100,
      disablePadding: true,
    },
  ],
  TEMPLATE_INCIDENT_DAMAGE_REPORT: [
    { id: "risky_id", label: "Report No.", sortable: true, width: 150 },
    {
      id: "date",
      label: "Date(YYYY/MM/DD)​	",
      sortable: true,
      width: 150,
    },
    {
      id: "warehouse",
      label: "Warehouse",
      sortable: true,
      width: 100,
    },
    {
      id: "reported_by",
      label: "Reported by",
      sortable: true,
      width: 100,
    },
    {
      id: "area",
      label: "Area",
      sortable: true,
      width: 100,
    },
    {
      id: "commodity",
      label: "Comomdity",
      sortable: true,
      width: 100,
    },
    {
      id: "model_name",
      label: "Model Name",
      sortable: true,
      width: 130,
    },
    {
      id: "quantity",
      label: "Quantity",
      sortable: true,
      width: 150,
    },
    {
      id: "serial_no",
      label: "Serial No.",
      sortable: true,
      width: 170,
    },
    {
      id: "cpar_no",
      label: "CPAR No.",
      sortable: true,
      width: 170,
    },
    {
      id: "work_status_name",
      label: "Status",
      sortable: true,
      width: 50,
    },
    {
      id: "is_image_array_text",
      label: "Photo Attachment Status",
      sortable: true,
      width: 200,
    },
    {
      id: "btn_detail",
      label: "",
      sortable: false,
      width: 100,
      disablePadding: true,
    },
  ],
  TEMPLATE_RECEIVING_PROBLEM_TAG: [
    { id: "risky_id", label: "Report No.", sortable: true, width: 150 },
    {
      id: "date",
      label: "Date  (YYYY/MM/DD)",
      sortable: true,
      width: 80,
    },
    {
      id: "time",
      label: "Time",
      sortable: true,
      width: 100,
    },
    {
      id: "model_name",
      label: "Model Name",
      sortable: true,
      width: 300,
    },
    {
      id: "quantity",
      label: "Quantity",
      sortable: true,
      width: 100,
    },
    {
      id: "tl_serial_no",
      label: "Serial No.",
      sortable: true,
      width: 250,
    },
    {
      id: "reported_by",
      label: "Reported by",
      sortable: true,
      width: 150,
    },
    {
      id: "defect",
      label: "Defect",
      sortable: true,
      width: 130,
    },
    {
      id: "damage_location",
      label: "Damage Location",
      sortable: true,
      width: 200,
    },
    {
      id: "ctc_qa_judgement",
      label: "CTC QA Judgement",
      sortable: true,
      width: 200,
    },
    {
      id: "ctc_qa_inspector_by",
      label: "CTC QA Inspector by",
      sortable: true,
      width: 200,
    },
    {
      id: "ctc_checked_date",
      label: "CTC Checked Date  (YYYY/MM/DD)",
      sortable: false,
      width: 200,
      disablePadding: true,
    },
    {
      id: "ctc_ma_repair_by",
      label: "CTC MA Repair by",
      sortable: true,
      width: 300,
    },
    {
      id: "ctc_ma_inspector_by",
      label: "CTC MA Inspector by",
      sortable: true,
      width: 300,
    },
    {
      id: "ctc_inspection_date",
      label: "CTC Inspection Date  (YYYY/MM/DD)",
      sortable: false,
      width: 200,
      disablePadding: true,
    },
    {
      id: "repaired_date",
      label: "Repaired Date  (YYYY/MM/DD)",
      sortable: true,
      width: 200,
    },
    {
      id: "repaired_ac_receive_by",
      label: "Repaired AC Received by",
      sortable: false,
      width: 200,
    },
    {
      id: "returning_date",
      label: "Returning Date  (YYYY/MM/DD)",
      sortable: true,
      width: 200,
    },
    {
      id: "returning_time",
      label: "Returning Time",
      sortable: false,
      width: 150,
    },
    {
      id: "work_status_name",
      label: "Status",
      sortable: true,
      width: 50,
    },
    {
      id: "is_image_array_text",
      label: "Photo Attachment Status",
      sortable: true,
      width: 200,
    },
    {
      id: "btn_detail",
      label: "",
      sortable: false,
      width: 100,
      disablePadding: true,
    },
  ],
  EXTERIOR_DAMAGED_TC_REPORT: [
    { id: "risky_id", label: "帳票番号", sortable: true, width: 150 },
    {
      id: "supplier_code",
      label: "仕入先コード",
      sortable: true,
      width: 100,
    },
    {
      id: "supplier_name",
      label: "仕入先",
      sortable: true,
      width: 130,
    },
    {
      id: "product_name",
      label: "品名",
      sortable: true,
      width: 130,
    },
    {
      id: "jan_code",
      label: "JANコード",
      sortable: true,
      width: 130,
    },
    {
      id: "work_status_name",
      label: "状況",
      sortable: true,
      width: 100,
    },
    {
      id: "arrival_date",
      label: "入荷日",
      sortable: true,
      width: 110,
    },
    {
      id: "is_image_array_text",
      label: "添付写真有無",
      sortable: true,
      align: "center",
      width: 145,
    },
    {
      id: "btn_detail",
      label: "",
      sortable: false,
      width: 100,
      disablePadding: true,
    },
  ],
  EXTERIOR_DAMAGED_DC_REPORT: [
    { id: "risky_id", label: "帳票番号", sortable: true, width: 150 },
    {
      id: "supplier_code",
      label: "仕入先コード",
      sortable: true,
      width: 100,
    },
    {
      id: "supplier_name",
      label: "仕入先",
      sortable: true,
      width: 130,
    },
    {
      id: "product_name",
      label: "品名",
      sortable: true,
      width: 130,
    },
    {
      id: "jan_code",
      label: "JANコード",
      sortable: true,
      width: 130,
    },
    {
      id: "work_status_name",
      label: "状況",
      sortable: true,
      width: 100,
    },
    {
      id: "arrival_date",
      label: "入荷日",
      sortable: true,
      width: 110,
    },
    {
      id: "is_image_array_text",
      label: "添付写真有無",
      sortable: true,
      align: "center",
      width: 145,
    },
    {
      id: "btn_detail",
      label: "",
      sortable: false,
      width: 100,
      disablePadding: true,
    },
  ],
};
