import dayjs from "dayjs";

export type IFormatKey =
  | "YMD"
  | "YMd"
  | "YMD_sl"
  | "YMD_jp"
  | "YMDTHms"
  | "YMDHms"
  | "YMDHms_sl"
  | "YMDHm"
  | "YMDHHmm"
  | "YMDHm_jp"
  | "YMDHHmm_jp"
  | "YM"
  | "YM_sl"
  | "Hm"
  | "HHmm";

const FORMAT: { [key in IFormatKey]: string } = {
  YMD: "YYYY-MM-DD",
  YMd: "YYYY-MM-D",
  YMD_sl: "YYYY/MM/DD",
  YMD_jp: "YYYY[年] M[月] D[日]",
  YMDTHms: "YYYY-MM-DDTHH:mm:ss",
  YMDHms: "YYYYMMDDHHmmss",
  YMDHms_sl: "YYYY/MM/DD HH:mm:ss",
  YMDHm: "YYYY/MM/DD H:mm",
  YMDHHmm: "YYYY/MM/DD HH:mm",
  YMDHm_jp: "YYYY[年] M[月] D[日] H:mm",
  YMDHHmm_jp: "YYYY[年] M[月] D[日] HH:mm",
  YM: "YYYY-MM",
  YM_sl: "YYYY/MM",
  Hm: "H: mm",
  HHmm: "HH:mm",
} as const;

const formatDateToString = (
  date?: dayjs.ConfigType,
  format_key?: IFormatKey,
  changeLocalTimeZone?: boolean,
) => {
  if (!date) return "";

  const d = dayjs(changeLocalTimeZone ? convertLocalDateTime(date) : date);
  const format = format_key ? FORMAT[format_key] : undefined;

  return d.format(format);
};

export const convertJPEraDate = (date?: dayjs.ConfigType) => {
  // 元号の開始日
  var jaCalender = [
    {
      era: "明治",
      start: "1868-01-25",
    },
    {
      era: "大正",
      start: "1912-07-30",
    },
    {
      era: "昭和",
      start: "1926-12-25",
    },
    {
      era: "平成",
      start: "1989-01-08",
    },
    {
      era: "令和",
      start: "2019-05-01",
    },
  ];

  const d = dayjs(date);
  for (var i = jaCalender.length - 1; i >= 0; i--) {
    var t = dayjs(jaCalender[i]["start"]);
    if (d >= t) {
      // 和暦に変換
      const year = d.year() - t.year() + 1;
      const year_str = year == 1 ? "元" : year.toString();
      return `${jaCalender[i]["era"]}${year_str}年${d.format("M[月]D[日]")}`;
    }
  }
  return "";
};

/**
 * 日本時間から現地時間に変換
 * @param date
 * @returns
 */
export const convertLocalDateTime = (date?: dayjs.ConfigType) => {
  if (!date) return "";

  const dt = new Date(dayjs(date).format());

  // 日本時間との時差を計算
  var tz = dt.getTimezoneOffset();
  tz = (tz + 540) * 60 * 1000;

  return new Date(dt.getTime() - tz);
};

/**
 * 現地時間から日本時間に変換
 * @param date
 * @returns
 */
export const convertJPDateTime = (date?: dayjs.ConfigType) => {
  if (!date) return "";

  const dt = new Date(dayjs(date).format());

  // 日本時間との時差を計算
  var tz = dt.getTimezoneOffset();
  tz = (tz + 540) * 60 * 1000;

  return new Date(dt.getTime() + tz);
};

export default formatDateToString;
