import { useCallback, useEffect, useMemo, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Box,
  Button,
  Stack,
  TextField,
  InputLabel,
  Typography,
  FormControl,
} from "@mui/material";
import DateRangePicker, {
  IDateRestriction,
} from "components/atoms/DatePickerCustom/DateRangePicker";
import { HeadCell, TableCustom } from "./tableCustom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { TYPES } from "store/types";
import ModalController from "@shared-components/modal/ModalController";
import formatDateToString from "@utils/DateFormat";
import { Validation } from "@validation";
import DevanEditScreen from "screens/DevanEdit";
import {
  getDateRangeDevanPlan,
  updateDevanPlan,
  getDevanPlan,
  getDevanByContainer,
  deleteDevanSchedule,
} from "@api/devan";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import messages from "config/messages";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import { LIST_DEVAN_STATUS } from "@shared-constants";
import { cloneDeep } from "lodash";
import { downloadXlsx } from "@utils/CSV";
import LabelRequired from "components/atoms/LabelRequired";
import { Colors } from "@template/style";

const headCells: HeadCell[] = [
  {
    id: "date_of_delivery",
    label: "搬入年月日",
    width: 110,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "container_no",
    label: "コンテナ番号",
    width: 130,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "product_class",
    label: "商品分類",
    width: 90,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "model_name",
    label: "機種名",
    width: 125,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "number_of_moves",
    label: "搬入数",
    width: 80,
    numeric: true,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "container_size",
    label: "コンテナサイズ",
    width: 70,
    disablePadding: true,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "delivery_time",
    label: "納入時間",
    width: 90,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "berth_number",
    label: "バース番号",
    width: 110,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "carrier_name",
    label: "搬入者名",
    width: 90,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "unit_class",
    label: "Unit区分",
    width: 90,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "delivery_location",
    label: "搬入場所",
    width: 90,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "invoice_no",
    label: "インボイスナンバー",
    width: 160,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "seal_no",
    label: "シールナンバー",
    width: 150,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "not_accepted",
    label: "未受付",
    width: 80,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "is_accepted",
    label: "受付済",
    width: 80,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "is_delivered",
    label: "搬入済",
    width: 80,
    isIgnoreExtractRuleCell: true,
  },
];

const devanColumnsInfo = [
  { key: "date_of_delivery", header: "搬入年月日" },
  { key: "container_no", header: "コンテナ番号" },
  { key: "product_class", header: "商品分類" },
  { key: "model_name", header: "機種名" },
  { key: "number_of_moves", header: "搬入数" },
  { key: "container_size", header: "コンテナサイズ" },
  { key: "delivery_time", header: "搬入時間" },
  { key: "berth_number", header: "バース番号" },
  { key: "carrier_name", header: "搬入者名" },
  { key: "unit_class", header: "Unit区分" },
  { key: "delivery_location", header: "搬入場所" },
  { key: "invoice_no", header: "インボイスナンバー" },
  { key: "seal_no", header: "シールナンバー" },
  { key: "not_accepted", header: "未受付" },
  { key: "is_accepted", header: "受付済み" },
  { key: "is_delivered", header: "搬入済み" },
];

interface IDevanStatus {
  id: string;
  name: string;
  isSelected: boolean;
}

export interface IStateForm {
  date_start: string;
  date_end: string;
  container_no: string;
  model_name: string;
  product_class: string;
  devan_status: string;
  [key: string]: string;
}

const initialState = {
  date_start: "",
  date_end: "",
  container_no: "",
  model_name: "",
  product_class: "",
  devan_status: "",
};

export interface IUpdateDataDevanPlan {
  PK: string;
  SK: string;
  date_of_delivery: string;
  container_no: string;
  product_class: string;
  model_name: string;
  number_of_moves: string;
  container_size: string;
  delivery_time: string;
  berth_number: string;
  carrier_name: string;
  unit_class: string;
  delivery_location: string;
  invoice_no: string;
  seal_no: string;
  is_accepted?: boolean;
  is_delivered?: boolean;
  not_accepted?: boolean;
}

type OptionalType = "is_accepted" | "is_delivered" | "not_accepted";

export const initialData = {
  PK: "",
  SK: "",
  date_of_delivery: "",
  container_no: "",
  product_class: "",
  model_name: "",
  number_of_moves: "",
  container_size: "",
  delivery_time: "",
  berth_number: "",
  carrier_name: "",
  unit_class: "",
  delivery_location: "",
  invoice_no: "",
  seal_no: "",
};

const devanStatus: Array<IDevanStatus> = cloneDeep(LIST_DEVAN_STATUS);

const initDateRestriction = {
  start_date_restriction: null,
  end_date_restriction: null,
};

const getCalculatedDate = (value: Date, calc: string, days: number) => {
  const y = value.getFullYear();
  const m = value.getMonth();
  let d;
  switch (calc) {
    case "plus":
      d = value.getDate() + (days - 1);
      break;
    case "minus":
      d = value.getDate() - (days - 1);
      break;
  }
  return new Date(y, m, d);
};

const DevanListScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const { check_list } = useSelector((state: RootState) => state.tableCustom);
  const [listDevan, setListDevan] = useState<IUpdateDataDevanPlan[]>([]);
  const [listDevanLength, setListDevanLength] = useState<number>(0);
  const [stateForm, setStateForm] = useState<IStateForm>(initialState);
  const [formError, setFormError] = useState<IStateForm>(initialState);
  const [editData, setEditData] = useState<IUpdateDataDevanPlan>(initialData);
  const [open, setOpen] = useState<boolean>(false);
  const [isNotExistMessage, setIsNotExistMessage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dateRestriction, setDateRestriction] =
    useState<IDateRestriction<string | null>>(initDateRestriction);
  const [devanStatusList, setDevanStatusList] = useState(devanStatus);
  const dispatch = useDispatch();
  const disabled = useMemo(() => listDevan.length == 0, [listDevan]);
  const error = useMemo(
    () => Object.keys(formError).some((v: string) => formError[v] !== ""),
    [formError],
  );

  // Clean Up処理
  useEffect(() => {
    return () => {
      setIsNotExistMessage(false);
      dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: [] });
    };
  }, []);

  const handleOpenEdit = (
    editDevanData: Omit<IUpdateDataDevanPlan, OptionalType>,
  ) => {
    LoadingOverlayController.show();

    getDevanPlan(editDevanData.PK, editDevanData.SK)
      .then((res) => {
        if (res.data) {
          setEditData(res?.data);
          setOpen(true);
        }
      })
      .catch((error: any) => {
        console.log("error handleOpenEdit", error);
      })
      .finally(() => {
        LoadingOverlayController.hide();
      });
  };

  const handleClear = () => {
    let newStateForm = cloneDeep(initialState);
    let newFormError = cloneDeep(initialState);
    let newDateRestriction = cloneDeep(initDateRestriction);
    let newDevanStatusList = cloneDeep(devanStatus);
    setStateForm(newStateForm);
    setFormError(newFormError);
    setDateRestriction(newDateRestriction);
    setDevanStatusList(newDevanStatusList);
  };

  const handleSearch = async () => {
    dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: [] });
    setIsNotExistMessage(true);
    setLoading(true);
    LoadingOverlayController.show();
    const formData = {
      date_start: stateForm.date_start,
      date_end: stateForm.date_end,
      container_no: stateForm.container_no,
      model_name: stateForm.model_name,
      product_class: stateForm.product_class
        .replace(/^\s+|\s+$/g, "")
        .replace(/\s*,\s*/g, ","),
      devan_status: stateForm.devan_status.replace(/\s+/g, ""),
    };
    await getDateRangeDevanPlan(formData)
      .then((res) => {
        if (res?.data) {
          setListDevan(res?.data);
          setListDevanLength(res?.data.length);
        }
      })
      .catch((error: any) => {
        console.log("error handleSearch", error);
      })
      .finally(() => {
        LoadingOverlayController.hide();
        setLoading(false);
      });
  };

  const onChangeText = (field: keyof IStateForm) => (newText: string) => {
    setStateForm({ ...stateForm, [field]: newText });
  };

  const onChangeDevanStatus = (index: number, checked: boolean) => {
    const deepCopyDevanStatusList = cloneDeep(devanStatusList);
    deepCopyDevanStatusList[index].isSelected = checked;
    setDevanStatusList(deepCopyDevanStatusList);

    const selectedDevanStatusString = deepCopyDevanStatusList
      .filter((status) => status.isSelected)
      .map((status) => status.id)
      .join(",");
    setStateForm((prev) => ({
      ...prev,
      devan_status: selectedDevanStatusString,
    }));
  };

  const onChangeDate = (newValue: string | null, field: keyof IStateForm) => {
    let displayValue = "";
    if (!newValue) {
      newValue = "";
    } else {
      // YMDだと二桁の日が直接入力不可だが、バリデーションには使う
      newValue = formatDateToString(newValue, "YMD");
      displayValue = formatDateToString(newValue, "YMd");
    }

    let mess = "";
    let newStateForm = { ...stateForm, [field]: displayValue };
    if (newValue) {
      mess = validator(field, newValue);
    }
    setFormError({ ...formError, [field]: mess });
    const otherField = field == "date_start" ? "date_end" : "date_start";
    if (
      mess.length === 0 &&
      (!formError[otherField] ||
        formError[otherField] == messages.COMMON.ERROR.MSG_DATE_RANGE ||
        formError[otherField] == messages.COMMON.ERROR.MSG_DATE_ONE_WEEK_RANGE)
    ) {
      const start = field == "date_start" ? newValue : stateForm.date_start;
      const end = field == "date_end" ? newValue : stateForm.date_end;
      let mess_start = "";
      let mess_end = "";
      if (typeof start == "string" && typeof end == "string") {
        if (start && end) {
          mess_start = Validation.validateDateRange(
            //setHours(0, 0, 0, 0)で、時間、分、秒を00:00:00に統一し、日付のみを比較対象にする
            new Date(new Date(start).setHours(0, 0, 0, 0)),
            new Date(new Date(end).setHours(0, 0, 0, 0)),
            true,
          );
          if (mess_start) {
            mess_end = mess_start;
          }
        }
        setFormError((prev) => {
          return {
            ...prev,
            date_start: mess_start,
            date_end: mess_end,
          };
        });
      }
    }

    setStateForm(newStateForm);
  };

  const onAcceptStartDate = (value: Date) => {
    const calcResult = getCalculatedDate(value, "plus", 7);
    setDateRestriction((prev) => ({
      ...prev,
      end_date_restriction: formatDateToString(calcResult, "YMD"),
    }));
  };

  const onAcceptEndDate = (value: Date) => {
    const calcResult = getCalculatedDate(value, "minus", 7);
    setDateRestriction((prev) => ({
      ...prev,
      start_date_restriction: formatDateToString(calcResult, "YMD"),
    }));
  };

  const onBlurStartDate = (field: keyof IStateForm) => {
    const name = "搬入年月日開始";
    const mess = Validation.validateDate(stateForm[field], name, true);
    setFormError({ ...formError, [field]: mess });
    const start = new Date(
      new Date(stateForm?.date_start).setHours(0, 0, 0, 0),
    );
    const calcResult = getCalculatedDate(start, "plus", 7);
    setDateRestriction((prev) => ({
      ...prev,
      end_date_restriction: formatDateToString(calcResult, "YMD"),
    }));

    if (
      stateForm.date_end.length > 0 &&
      formError[field] !== messages.COMMON.ERROR.MSG_DATE(name)
    ) {
      const start = new Date(
        new Date(stateForm.date_start).setHours(0, 0, 0, 0),
      );
      const end = new Date(new Date(stateForm.date_end).setHours(0, 0, 0, 0));
      const diff = new Date(end).getTime() - new Date(start).getTime();
      // 日時の差 (日)
      const diffDays = diff / (1000 * 60 * 60 * 24);
      let dateRangeError: string = "";
      if (diffDays >= 7) {
        dateRangeError = messages.COMMON.ERROR.MSG_DATE_ONE_WEEK_RANGE;
      }
      if (diffDays < 0) {
        dateRangeError = messages.COMMON.ERROR.MSG_DATE_RANGE;
      }
      setFormError({
        ...formError,
        date_start: dateRangeError,
        date_end: dateRangeError,
      });
    }
  };

  const onBlurEndDate = (field: keyof IStateForm) => {
    const name = "搬入年月日終了";
    let mess = Validation.validateDate(stateForm[field], name, true);
    setFormError({ ...formError, [field]: mess });
    const end = new Date(new Date(stateForm.date_end).setHours(0, 0, 0, 0));
    const calcResult = getCalculatedDate(end, "minus", 7);
    setDateRestriction((prev) => ({
      ...prev,
      start_date_restriction: formatDateToString(calcResult, "YMD"),
    }));

    if (
      stateForm.date_start.length > 0 &&
      formError[field] !== messages.COMMON.ERROR.MSG_DATE(name)
    ) {
      const start = new Date(
        new Date(stateForm.date_start).setHours(0, 0, 0, 0),
      );
      const end = new Date(new Date(stateForm.date_end).setHours(0, 0, 0, 0));
      const diff = new Date(end).getTime() - new Date(start).getTime();
      // 日時の差 (日)
      const diffDays = diff / (1000 * 60 * 60 * 24);
      let dateRangeError: string = "";
      if (diffDays >= 7) {
        dateRangeError = messages.COMMON.ERROR.MSG_DATE_ONE_WEEK_RANGE;
      }
      if (diffDays < 0) {
        dateRangeError = messages.COMMON.ERROR.MSG_DATE_RANGE;
      }
      setFormError({
        ...formError,
        date_start: dateRangeError,
        date_end: dateRangeError,
      });
    }
  };

  const onValidateText = (field: keyof IStateForm) => {
    const mess = validator(field, stateForm[field]);
    setFormError({ ...formError, [field]: mess });
  };

  const validator = (
    field: keyof IStateForm,
    value: string,
    required: boolean = false,
  ) => {
    let mess: string = "";

    switch (field) {
      case "date_start":
        mess = Validation.validateDate(value, "搬入年月日開始", required);
        break;
      case "date_end":
        mess = Validation.validateDate(value, "搬入年月日終了", required);
        break;
    }
    return mess;
  };

  const checkEditContainerInfo = (data: IUpdateDataDevanPlan) => {
    //編集された項目が機種名、商品分類、搬入数以外ならtrue、編集されてないor機種名、商品分類、搬入数のみの編集ならfalse
    const keys: (keyof IUpdateDataDevanPlan)[] = Object.keys(
      editData,
    ) as (keyof IUpdateDataDevanPlan)[];
    for (const key of keys) {
      if (
        editData[key] !== data[key] &&
        (key === "date_of_delivery" ||
          key === "container_no" ||
          key === "container_size" ||
          key === "delivery_time" ||
          key === "carrier_name" ||
          key === "unit_class" ||
          key === "delivery_location" ||
          key === "invoice_no" ||
          key === "seal_no")
      ) {
        return true;
      }
    }
    return false;
  };

  // ------------------------------------------------------------------
  //  帳票選択 CSVダウンロード
  // ------------------------------------------------------------------
  const handleCSV = useCallback(async () => {
    const csv_data = listDevan.map((item) => ({
      ...item,
      is_accepted: item?.is_accepted ? "◯" : "",
      not_accepted: !item?.is_accepted && !item?.is_delivered ? "◯" : "",
      is_delivered: item?.is_delivered ? "◯" : "",
      date_of_delivery: formatDateToString(item?.date_of_delivery, "YMD_sl"),
    }));

    downloadXlsx({
      data: csv_data,
      columns: devanColumnsInfo,
      filename: `デバン予定一覧${formatDateToString(new Date(), "YMDHms")}.csv`,
    });
  }, [listDevan]);

  // ------------------------------------------------------------------
  //  帳票選択 Excelダウンロード
  // ------------------------------------------------------------------
  const handelExcel = useCallback(async () => {
    const csv_data = listDevan.map((item) => ({
      ...item,
      is_accepted: item?.is_accepted ? "◯" : "",
      not_accepted: !item?.is_accepted && !item?.is_delivered ? "◯" : "",
      is_delivered: item?.is_delivered ? "◯" : "",
      date_of_delivery: formatDateToString(item?.date_of_delivery, "YMD_sl"),
    }));

    downloadXlsx({
      data: csv_data,
      columns: devanColumnsInfo,
      filename: `デバン予定一覧${formatDateToString(
        new Date(),
        "YMDHms",
      )}.xlsx`,
      format: "xlsx",
    });
  }, [listDevan]);

  const handleSaveComfirm = (data: IUpdateDataDevanPlan) => {
    LoadingOverlayController.show();
    //機種名、商品分類、搬入数以外が編集されたとき
    if (checkEditContainerInfo(data)) {
      const formData = {
        container_no: editData.container_no,
        date: editData.date_of_delivery,
        time: editData.delivery_time,
        location_id: "",
      };
      getDevanByContainer(formData)
        .then((res) => {
          LoadingOverlayController.hide();
          //コンテナ番号、搬入日一致のデータが2件以上ある場合
          if (res?.data && res?.data.length > 1) {
            ModalController.show({
              message: messages.DEVAN.MSG_CONFIRM_SAVE,
              visibleButton1: true,
              visibleButton2: true,
              handlePressButton2: () => {
                handleSave(data);
              },
            });
          } else {
            handleSave(data);
          }
        })
        .catch((error: any) => {
          console.log("error getDevanByContainer", error);
          LoadingOverlayController.hide();
        })
        .finally(() => {
          LoadingOverlayController.hide();
        });
    } else {
      handleSave(data);
    }
  };

  const handleSave = (data: IUpdateDataDevanPlan) => {
    LoadingOverlayController.show();
    const formData = {
      PK: data.PK,
      SK: data.SK,
      date_of_delivery: data.date_of_delivery,
      container_no: data.container_no,
      product_class: data.product_class,
      model_name: data.model_name,
      number_of_moves: data.number_of_moves,
      container_size: data.container_size,
      delivery_time: data.delivery_time,
      berth_number: data.berth_number,
      carrier_name: data.carrier_name,
      unit_class: data.unit_class,
      delivery_location: data.delivery_location,
      invoice_no: data.invoice_no,
      seal_no: data.seal_no,
    };
    updateDevanPlan(formData)
      .then((res) => {
        if (res?.data) {
          ModalController.show({
            message: messages.COMMON.MSG_COMMON_SUCCESS_001("デバン予定の更新"),
            visibleButton2: true,
            handlePressButton2: () => {
              handleSearch();
            },
          });
        }
      })
      .catch((error: any) => {
        console.log("error handleSave", error);
      })
      .finally(() => {
        LoadingOverlayController.hide();
        setOpen(false);
      });
  };
  const handleDeleteDialog = () => {
    ModalController.show({
      message: messages.DEVAN.MSG_DELETE_SELECT_DEVAN_PLAN,
      visibleButton1: true,
      visibleButton2: true,
      handlePressButton2: () => {
        handleDelete();
      },
    });
  };
  const handleDelete = () => {
    LoadingOverlayController.show();
    const selectedListDevan = listDevan
      .filter((d) => check_list.includes(d.SK))
      .map(({ PK, SK, date_of_delivery, container_no }) => ({
        PK,
        SK,
        date_of_delivery,
        container_no,
      }));

    deleteDevanSchedule(selectedListDevan)
      .then((res) => {
        if (res?.data) {
          ModalController.show({
            message:
              messages.COMMON.MSG_COMMON_DELETE_SUCCESS_001("デバン予定"),
            visibleButton2: true,
            handlePressButton2: () => {
              dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: [] });
              handleSearch();
            },
          });
        }
      })
      .catch((error: any) => {
        console.log("error handleSave", error);
      })
      .finally(() => {
        LoadingOverlayController.hide();
        setOpen(false);
      });
  };

  return (
    <GenericTemplate title="デバン予定一覧">
      <DevanEditScreen
        data={editData}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSave={handleSaveComfirm}
      />
      <Stack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 2, md: 4 }}
        >
          <DateRangePicker
            label={<LabelRequired title={"搬入年月日"} />}
            value={[stateForm.date_start, stateForm.date_end]}
            onChange={[
              (v) => {
                onChangeDate(v, "date_start");
              },
              (v) => {
                onChangeDate(v, "date_end");
              },
            ]}
            helperText={[formError.date_start, formError.date_end]}
            error={[
              formError.date_start.length > 0,
              formError.date_end.length > 0,
            ]}
            onAccept={[
              (v: Date) => {
                onAcceptStartDate(v);
              },
              (v: Date) => {
                onAcceptEndDate(v);
              },
            ]}
            onBlur={[
              () => {
                onBlurStartDate("date_start");
              },
              () => {
                onBlurEndDate("date_end");
              },
            ]}
            dateRestriction={dateRestriction}
          />
          <FormControl>
            <InputLabel>進捗状態</InputLabel>
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{ gap: 1 }}
              spacing={0}
            >
              {devanStatusList.map((item, index) => {
                return (
                  <CheckboxLabel
                    label={item?.name}
                    checked={item?.isSelected}
                    onChange={(_, checked) => {
                      onChangeDevanStatus(index, checked);
                    }}
                    formSx={{ mt: { xs: 0, md: "5px" } }}
                    key={item?.id}
                  />
                );
              })}
            </Stack>
          </FormControl>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }}>
          <TextField
            label="コンテナ番号"
            value={stateForm.container_no}
            onChange={(e) => onChangeText("container_no")(e.target.value)}
            onBlur={() => onValidateText("container_no")}
            sx={{ width: { sx: "100%", md: "45%" } }}
          />
          <TextField
            label="機種名"
            value={stateForm.model_name}
            onChange={(e) => onChangeText("model_name")(e.target.value)}
            onBlur={() => onValidateText("model_name")}
            sx={{ width: { sx: "100%", md: "45%" } }}
          />
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }}>
          <TextField
            label="商品分類(複数検索する場合は,で区切ってください)"
            value={stateForm.product_class}
            onChange={(e) => onChangeText("product_class")(e.target.value)}
            sx={{ width: { sx: "100%", md: "45%" } }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: { sx: "center", md: "flex-end" },
              pb: { sx: 0, md: 0.5 },
            }}
          >
            <Typography style={{ color: Colors.REQUIRE }}>
              コンテナ番号、機種名、商品分類は、
              <br />
              *を使用することで前後一致検索ができます
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              onClick={handleSearch}
              disabled={
                error ||
                stateForm.date_start.length === 0 ||
                stateForm.date_end.length === 0
              }
            >
              検索
            </Button>
            <Button onClick={handleClear}>条件クリア</Button>
          </Box>
          <Box
            sx={{
              position: "absolute",
              right: 0,
            }}
          >
            <Box>
              <Typography>{listDevanLength}件</Typography>
            </Box>
          </Box>
        </Box>
        <TableCustom
          rows={listDevan}
          message={
            isNotExistMessage && listDevan.length < 1
              ? messages.COMMON.MSG_NOT_EXIST("デバン予定")
              : ""
          }
          headCells={headCells}
          buttons={[]}
          idName={"SK"}
          isCheckRow={true}
          isEditRow={true}
          handleEdit={handleOpenEdit}
        />
      </Stack>
      <Box sx={{ mt: 2, textAlign: "center", width: "100%" }}>
        <Button
          onClick={handleDeleteDialog}
          color="error"
          disabled={check_list.length < 1}
        >
          削除
        </Button>
      </Box>

      {/* =================== 帳票作成ボタン =================== */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          margin: 0,
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Button
          onClick={handleCSV}
          color="secondary"
          sx={{
            width: "120px",
            mr: 2,
          }}
          disabled={listDevan.length == 0}
        >
          CSV出力
        </Button>
        <Button
          onClick={handelExcel}
          sx={{ width: "120px" }}
          disabled={listDevan.length == 0}
        >
          Excel出力
        </Button>
      </Box>
    </GenericTemplate>
  );
};

export default DevanListScreen;
