import {
  METHOD_API,
  createConnector,
  getParamsHelp,
  prefixApi,
} from "./Connector";
interface sortData {
  PK: String;
  SK: String;
}

interface IDataUpdateTlgtMaster {
  name: string;
  index: string;
  is_manual_input: boolean;
}

interface IDataUpdateTlgtMaMaster {
  nonconformity_detail: string;
  occurance_process: string;
  process_of_root_cause: string;
  responsibility: string;
  department_of_pic: string;
  process_in_logistics: string;
  responsibility_in_process: string;
  classification_of_nonconformity: string;
  classification_of_cause: string;
}

interface IDataUpdateTlgtModelNameMaster {
  name: string;
  code: string;
}

export const getListTlgtMaster = (type: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/${encodeURIComponent(type)}`,
    null,
  );
};

export const insertTlgtMaster = (
  dataBody: IDataUpdateTlgtMaster,
  type: string,
) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/setting/tlgt/${encodeURIComponent(type)}`,
    dataBody,
  );
};

export const updateTlgtMaster = (
  dataBody: IDataUpdateTlgtMaster,
  SK: string,
) => {
  return createConnector(
    METHOD_API.PUT,
    `${prefixApi}/setting/tlgt/m/${encodeURIComponent(SK)}`,
    dataBody,
  );
};

export const deleteTlgtMaster = (PK: string, SK: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/setting/${encodeURIComponent(PK)}/${encodeURIComponent(SK)}`,
    null,
  );
};

export const insertTlgtMaMaster = (dataBody: IDataUpdateTlgtMaMaster) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/setting/tlgt/ma_master`,
    dataBody,
  );
};

export const updateTlgtMaMaster = (
  dataBody: IDataUpdateTlgtMaMaster,
  SK: string,
) => {
  return createConnector(
    METHOD_API.PUT,
    `${prefixApi}/setting/tlgt/ma_master/${encodeURIComponent(SK)}`,
    dataBody,
  );
};

export const insertTlgtModelNameMaster = (
  dataBody: IDataUpdateTlgtModelNameMaster,
) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/setting/tlgt/model_name_master`,
    dataBody,
  );
};

export const updateTlgtModelNameMaster = (
  dataBody: IDataUpdateTlgtModelNameMaster,
  SK: string,
) => {
  return createConnector(
    METHOD_API.PUT,
    `${prefixApi}/setting/tlgt/model_name_master/${encodeURIComponent(SK)}`,
    dataBody,
  );
};

export const updateSettingSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/setting/update-order`,
    dataBody,
  );
};
