import React, {
  forwardRef,
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  useMemo,
} from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  FormControl,
  InputLabel,
  ListItem,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import { createFileNameFollowUri, getUserInfo } from "@utils/index";
import {
  CountType,
  DevanModelInfo,
  DevanReceptionFormType,
  getImageArray,
  getInfoFromTemplate,
  insertReportLocal,
  makeNewHtml,
  initDevanModelInfo,
} from "@utils/template/inspectionReport";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import SelectLabel from "components/atoms/SelectLabel";
import LabelRequired from "components/atoms/LabelRequired";
import { cloneDeep, debounce, isEqual } from "lodash";
import { ProductStatus, User } from "services/models";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { getListUserByLocationId } from "@api/User";
import { Validation } from "@validation";
import { uploadImageToS3 } from "@utils/template";
import LabelValueText from "components/atoms/LabelValueText";
import AccordionSection from "components/molecules/AccordionSection";
import StepperInputLabel from "components/atoms/StepperInputLabel";
import DevanSearch from "screens/DevanSearch";
import { getDavanByContainerApi } from "@api/devan";
import { DEVAN_SEARCH_TYPE } from "@shared-constants";
import {
  getListContainerApi,
  getListContainerDefectApi,
} from "@api/itemReport";
import ProduStatusItem from "./ProduStatusItem";
import SelectMultipleOption from "components/molecules/ModalSelectMultipleOption";
import ImagePickerList from "components/molecules/ImagePickerList";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";

const originData: DevanReceptionFormType = {
  risky_id: "",
  report_number: "", //帳票番号
  report_type_code: "",
  report_name: "",
  SK: "",
  date_of_delivery: "",
  delivery_location: "",
  container_no: "",
  container_size: "",
  delivery_time: "",
  carrier_name: "",
  unit_class: "",
  invoice_no: "",
  berth_number: "",
  seal_no: "",
  sealed: undefined,
  container_status: [],
  model_info: [],
  unpacked_number: "",
  no_abnomality_number: "",
  abnomality_report: "",
  abnomality_image: [],
  person_worker: null,
  person_worker_other: "",
  bool_attention: true,
  bool_persons: false,
  devan_schedule_id: "",
  bool_seal_no: false,
  unexpected_list: [],
  // unit_abnomality_report: ""
};

const maxNumberImageDefault: number = 6;

interface IFormError {
  unpacked_number: string;
  no_abnomality_number: string;
  model_info: IFormErrorModelInfo[];
}

interface IFormUnexpectedItemError {
  model_name: any[];
  model_info: IFormErrorModelInfo[];
}

interface IInspectionReport {
  infoToEdit?: any;
  htmlString: string;
  setHtmlString: Function;
  isManage?: boolean;
  open: boolean;
  handleSetTemplate: () => void;
  handleClose: () => void;
}

interface IFormErrorModelInfo {
  number_of_moves: string[];
  good_products_number: string[];
  defective_products_number: string[];
  count: string[];
  defective_products_number_count: string[];
  count_select: string[];
  count_good_products: string[];
  count_defective_products: string[];
  pallet_good_products: string[];
  pallet_defective_products: string[];
}

const initFormErrorModelInfo = {
  number_of_moves: [],
  good_products_number: [],
  defective_products_number: [],
  count: [],
  defective_products_number_count: [],
  count_select: [],
  count_good_products: [],
  count_defective_products: [],
  pallet_good_products: [],
  pallet_defective_products: [],
};
const InspectionReport = (
  {
    infoToEdit,
    htmlString,
    setHtmlString,
    isManage = false,
    open,
    handleSetTemplate,
    handleClose,
  }: IInspectionReport,
  ref: React.Ref<unknown>,
) => {
  const [stateForm, setStateForm] = useState<DevanReceptionFormType>(
    cloneDeep(originData),
  );
  const [originStateForm, setOriginStateForm] =
    useState<DevanReceptionFormType>(cloneDeep(originData));
  const [formError, setFormError] = useState<IFormError>({
    unpacked_number: "",
    no_abnomality_number: "",
    model_info: [],
  });

  const [unexpectedListFormError, setUnexpectedListFormError] =
    useState<IFormUnexpectedItemError>({
      model_name: [],
      model_info: [],
    });

  const initModelInfoData = cloneDeep(initDevanModelInfo);

  const [addIsManualListUser, setAddIsManualListUser] = useState<Array<any>>(
    [],
  );
  const [listContainerStatus, setContainerStatus] = useState<any[]>([]);
  const [defectList, setDefectList] = useState<any[]>([]);
  const [completeGetData, setCompleteGetData] = useState<boolean>(false);
  const [isMultipleOption, setIsMultipleOption] = useState(false);
  const [listSelectedTempt, setListSelectedTempt] = useState<
    Array<ProductStatus>
  >([]);
  const [openDevanSearch, setOpenDevanSearch] = useState(false);
  const [unexpectedList, setUnexpectedList] = useState<DevanModelInfo[]>([]);
  const user: User = getUserInfo();

  useImperativeHandle(ref, () => ({
    getStateForm: () => {
      // プレビュー用の値を削除
      let cloneStateForm = cloneDeep(stateForm);
      cloneStateForm.abnomality_image.map((item: any) => {
        delete item.base64;
      });
      cloneStateForm.container_status.forEach((item: any) => {
        if (item.container_status_img) {
          // 署名付きURLをパスに変換
          const fileName = createFileNameFollowUri(item.container_status_img);
          let path = item.container_status_img
            .replace(fileName, "")
            .split("?")[0]
            .split("/");
          item.container_status_img = path.splice(3).join("/") + fileName;
        }
      });
      return cloneStateForm;
    },
    getOriginStateForm: () => originStateForm,
    onSubmit: validateModelInfoTotal,
    disabledPreview: () => ErrorMessage.length > 0,
    disabledSubmit: () => ErrorMessage.length > 0,
  }));

  // マスタデータ取得 =====================================
  const addWhiteSpace = (data: Array<any>) => {
    if (data.length == 0) {
      return data;
    }
    data.splice(0, 0, undefined);
    return data;
  };

  const addIsManual = (data: Array<any>) => {
    if (data.length == 0) {
      return data;
    }
    data.splice(1, 0, {
      PK: "0",
      SK: "0",
      full_name: "その他(手入力)",
      is_manual_input: true,
      is_vertical_writing_sign: false,
      seal_display_name: "",
      seal_file_path: null,
    });
    return data;
  };

  const getData = async () => {
    try {
      LoadingOverlayController.show();
      const res_container = await getListContainerApi();
      if (res_container?.data) {
        setContainerStatus(res_container?.data);
      }
      const res_defect = await getListContainerDefectApi();
      if (res_defect?.data) {
        setDefectList(res_defect?.data);
      }
      const resListUser = await getListUserByLocationId();
      if (resListUser?.length) {
        const newListUser = addWhiteSpace(resListUser).concat();
        setAddIsManualListUser(addIsManual(newListUser));
      }
      setCompleteGetData(true);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    getData();
    return () => {
      setCompleteGetData(false);
    };
  }, []);

  // useEffect(() => {
  //   const unit_model_list = stateForm.unexpected_list
  //     ? [...stateForm.model_info, ...stateForm.unexpected_list]
  //     : stateForm.model_info;
  //   let memoList: string[] = [];
  //   unit_model_list.forEach((item) => {
  //     if (item.model_name && item.total_number && item.memo) {
  //       const memo = item.memo.split("\n").join("");
  //       memoList.push(item.model_name + " " + item.total_number + "台:" + memo);
  //     }
  //   });
  //   const JOIN_TXT = "　";
  //   let unit_abnomality_report =
  //     memoList.join(JOIN_TXT).replace(/,/g, "") + JOIN_TXT + stateForm.abnomality_report;
  //   setStateForm({ ...stateForm, unit_abnomality_report: unit_abnomality_report })
  // }, [stateForm.model_info, stateForm.unexpected_list, stateForm.abnomality_report]);

  useEffect(() => {
    if (open && completeGetData) {
      if (typeof infoToEdit?.abnomality_report === "string") {
        const selectedContainerStatusList = listContainerStatus.filter(
          (containerInfo) => {
            return infoToEdit.container_status.some(
              (selectedContainer: any) =>
                selectedContainer.SK === containerInfo.SK,
            );
          },
        );
        setStateForm(cloneDeep(infoToEdit));
        setStateForm((prev) => ({
          ...prev,
          container_status: selectedContainerStatusList,
        }));
        let unexpected_list = cloneDeep(infoToEdit).unexpected_list;
        if (unexpected_list) {
          let initModelnameList = [];
          let initModelInfoList = [];
          setUnexpectedList(unexpected_list);
          for (var i = 0; i < unexpected_list.length; i++) {
            initModelnameList.push([]);
            initModelInfoList.push(initFormErrorModelInfo);
          }
          setUnexpectedListFormError({
            model_name: [
              ...unexpectedListFormError.model_name,
              ...initModelnameList,
            ],
            model_info: [
              ...unexpectedListFormError.model_info,
              ...initModelInfoList,
            ],
          });
        }

        setOriginStateForm(cloneDeep(infoToEdit));
        const form_error_model_info: IFormErrorModelInfo[] =
          infoToEdit?.model_info.map((item: any) => {
            return {
              number_of_moves: [],
              good_products_number: [],
              defective_products_number: [],
              count: Array(item?.defective_products?.length ?? 0).fill(""),
              defective_products_number_count: [],
              count_select: [],
              count_good_products: [],
              count_defective_products: [],
              pallet_good_products: [],
              pallet_defective_products: [],
            };
          });
        setFormError({
          unpacked_number: "",
          no_abnomality_number: "",
          model_info: form_error_model_info,
        });
      } else {
        const info_edit = {
          ...cloneDeep(originData),
          risky_id: infoToEdit?.risky_id ?? "",
          person_worker: getSelectUserDefault(
            "person_worker",
            addIsManualListUser,
          ),
        };
        setStateForm(cloneDeep(info_edit));
        setOriginStateForm(cloneDeep(info_edit));
      }
    }
  }, [open, completeGetData, addIsManualListUser]);

  const getSelectUserDefault = (
    field: keyof DevanReceptionFormType,
    listObject: Array<any>,
    SK?: string,
  ) => {
    const default_SK = SK ? SK : user.SK;
    const default_data = listObject.find((item) => default_SK === item?.SK);

    if (default_data) {
      return default_data;
    } else {
      return originData[field];
    }
  };

  const setDefaultValue = (
    field: keyof DevanReceptionFormType,
    listObject: Array<any>,
  ) => {
    const default_data = listObject.find((item) => item?.is_default === true);

    if (default_data) {
      return default_data;
    } else {
      return originData[field];
    }
  };

  // HTML取得 =====================================
  const getHtmlString = useCallback(
    async (state_form: DevanReceptionFormType, html_String: string) => {
      setHtmlString(""); // エラーメッセージの変更時に再描画する為、htmlを変更
      const res = getInfoFromTemplate(html_String);
      const image_array = await getImageArray(state_form.abnomality_image);
      let newHtml: string = await makeNewHtml(
        html_String,
        state_form,
        res.addPageExample,
        res.addAbnomalReportPageExample,
      );
      newHtml = insertReportLocal(
        newHtml,
        state_form,
        res.imageProductExample,
        res.pageImageExample,
        image_array,
      );
      setHtmlString(newHtml);
      if (!isEqual(image_array, state_form.abnomality_image)) {
        setStateForm({ ...state_form, abnomality_image: image_array });
      }
    },
    [],
  );

  const changeForm = useCallback(debounce(getHtmlString, 300), [getHtmlString]);

  useEffect(() => {
    changeForm(stateForm, htmlString);
  }, [stateForm, htmlString, formError]);

  // 入力制御 =====================================
  const onChangeText = (field: string) => (value: any) => {
    setStateForm({ ...stateForm, [field]: value });
  };

  const onChangeSelect =
    (field: string) => (list: any[], e: SelectChangeEvent<string>) => {
      const selected = list.find((item) => item && item.SK === e.target.value);
      setStateForm((prev) => ({ ...prev, [field]: selected }));
    };

  useEffect(() => {
    setStateForm({
      ...stateForm,
      unexpected_list: unexpectedList,
    });
  }, [unexpectedList]);

  const getPersonFoundIssueOther = useCallback(
    (person_found_issue_other: string) => {
      const otherSelectionItem: any = {
        PK: "0",
        SK: "0",
        full_name: "入力",
        is_manual_input: true,
        is_vertical_writing_sign: false,
        seal_display_name: person_found_issue_other,
        seal_file_path: null,
      };
      return otherSelectionItem;
    },
    [],
  );

  const validation = (
    setErrorForm: Function,
    errorForm: any,
    field:
      | keyof DevanReceptionFormType
      | keyof DevanModelInfo
      | "count_good_products"
      | "count_defective_products"
      | "pallet_good_products"
      | "pallet_defective_products",
    value: string,
    index?: number,
    index_count?: number,
  ) => {
    let mess: any = "";
    const list_field_name = {
      number_of_moves: "搬入数",
      unpacked_number: "開梱対象総台数",
      no_abnomality_number: "開梱後異常無し",
      good_products_number: "良品数",
      defective_products_number: "不適合数",
      count: "不適合内容（数量）",
      count_good_products: "良品数（数量）",
      count_defective_products: "不適合数（数量）",
      pallet_good_products: "良品数（パレット/箱数）",
      pallet_defective_products: "不適合数（パレット/箱数）",
    };
    const arr_model_info = [
      "number_of_moves",
      "good_products_number",
      "defective_products_number",
      "count",
      "count_good_products",
      "count_defective_products",
      "pallet_good_products",
      "pallet_defective_products",
    ];
    const arr_count_input = [
      "count",
      "count_good_products",
      "count_defective_products",
      "pallet_good_products",
      "pallet_defective_products",
    ];
    const arr_unexpected_item_input = ["model_name"];
    switch (field) {
      case "unpacked_number":
      case "no_abnomality_number":
      case "good_products_number":
      case "defective_products_number":
      case "count":
      case "count_good_products":
      case "count_defective_products":
      case "pallet_good_products":
      case "pallet_defective_products":
        mess = Validation.validate({
          type: "number",
          value: value,
          name: list_field_name[field],
        });
        break;
      case "number_of_moves":
        mess = Validation.validate({
          type: "number",
          value: value,
          required: true,
          name: list_field_name[field],
        });
        break;
      case "model_name":
        mess = Validation.validate({
          type: "text",
          value: value,
          required: true,
          name: "機種名",
        });
        break;
    }
    mess = mess.length > 0 ? mess : "";
    if (arr_model_info.includes(field as any) && index !== undefined) {
      let tmp = cloneDeep(errorForm.model_info);
      let message_field: keyof IFormErrorModelInfo = field as any;
      if (arr_count_input.includes(field as any) && index_count !== undefined) {
        tmp[index][message_field][index_count] = mess;
      } else {
        tmp[index][message_field] = [mess];
      }
      setErrorForm((prev: any) => ({ ...prev, model_info: tmp }));
    } else if (
      arr_unexpected_item_input.includes(field as any) &&
      index !== undefined
    ) {
      let tmp = cloneDeep(errorForm[field]);
      tmp[index] = [mess];
      setErrorForm((prev: any) => ({ ...prev, [field]: tmp }));
    } else {
      setErrorForm((prev: any) => ({ ...prev, [field]: mess }));
    }
    return mess.length == 0;
  };

  const createErrorMessages = (errorObject: any) => {
    let list: string[] = [];
    Object.keys(errorObject).forEach((value: any) => {
      const key: keyof typeof errorObject = value;
      if (key === "model_info") {
        errorObject.model_info.forEach((value: any) => {
          list = list.concat(
            value["number_of_moves"].filter((c: any) => c != "") ?? [],
          );
          list = list.concat(
            value["good_products_number"].filter((c: any) => c != "") ?? [],
          );
          list = list.concat(
            value["defective_products_number"].filter((c: any) => c != "") ??
              [],
          );
          list = list.concat(
            value["defective_products_number_count"].filter(
              (c: any) => c != "",
            ) ?? [],
          );
          list = list.concat(
            value["count_select"].filter((c: any) => c != "") ?? [],
          );
          list = list.concat(
            value["count_good_products"].filter((c: any) => c != "") ?? [],
          );
          list = list.concat(
            value["pallet_good_products"].filter((c: any) => c != "") ?? [],
          );
          list = list.concat(
            value["count_defective_products"].filter((c: any) => c != "") ?? [],
          );
          list = list.concat(
            value["pallet_defective_products"].filter((c: any) => c != "") ??
              [],
          );
          list = list.concat(value["count"].filter((c: any) => c != "") ?? []);
        });
      } else if (key === "model_name") {
        errorObject.model_name.forEach((value: any) => {
          list = list.concat(value.filter((c: any) => c != "") ?? []);
        });
      } else {
        list = list.concat(errorObject[key] || []);
      }
    });
    const result: string[] = [...new Set(list)];
    return result;
  };

  const ErrorMessage = useMemo(() => {
    let list: string[] = [];
    let formErrorList = createErrorMessages(formError);
    let unexpectedListFormErrorList = createErrorMessages(
      unexpectedListFormError,
    );
    list = [...formErrorList, ...unexpectedListFormErrorList];
    const result: string[] = [...new Set(list)];
    return result;
  }, [formError, unexpectedListFormError]);

  // 描画 =====================================
  const renderItem1 = () => {
    return (
      <Box>
        <FormControl fullWidth>
          <InputLabel>コンテナの状態</InputLabel>
          {stateForm?.container_status &&
            (!Array.isArray(stateForm?.container_status) ||
              stateForm.container_status.length > 0) && (
              <Card variant="outlined" sx={{ p: 1, m: 0 }} component={"ul"}>
                {stateForm.container_status?.map((container, i) => (
                  <ListItem sx={{ p: 0, pt: i == 0 ? 0 : 1 }} key={i}>
                    <Chip
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            height: 20,
                          }}
                        >
                          {container.container_status_name ?? ""}
                          {container.container_status_img && (
                            <Box
                              component="img"
                              src={container.container_status_img}
                              sx={{
                                height: "100%",
                                objectFit: "contain",
                                ml: 1,
                              }}
                            />
                          )}
                        </Box>
                      }
                      onDelete={() => {
                        const tmp = stateForm.container_status.filter(
                          (item: any) => item.SK !== container.SK,
                        );
                        setStateForm({
                          ...stateForm,
                          container_status: tmp,
                        });
                      }}
                    />
                  </ListItem>
                ))}
              </Card>
            )}
        </FormControl>
        <Button
          onClick={() => {
            setIsMultipleOption(true);
            if (stateForm.container_status) {
              setListSelectedTempt([...stateForm.container_status]);
            }
          }}
          fullWidth
          sx={{ mt: 1 }}
        >
          コンテナ状態の検索
        </Button>
      </Box>
    );
  };

  const renderItem2 = () => {
    const handleAddItem = () => {
      //予定外を追加
      setUnexpectedListFormError({
        model_name: [...unexpectedListFormError.model_name, []],
        model_info: [
          ...unexpectedListFormError.model_info,
          initFormErrorModelInfo,
        ],
      });
      setUnexpectedList([...unexpectedList, initModelInfoData]);
    };

    const handleRemoveItem = (index: number) => {
      //予定外を削除
      const updatedList = [
        ...unexpectedList.slice(0, index),
        ...unexpectedList.slice(index + 1),
      ];
      setUnexpectedList(updatedList);
      setUnexpectedListFormError({
        model_name: [
          ...unexpectedListFormError.model_name.slice(0, index),
          ...unexpectedListFormError.model_name.slice(index + 1),
        ],
        model_info: [
          ...unexpectedListFormError.model_info.slice(0, index),
          ...unexpectedListFormError.model_info.slice(index + 1),
        ],
      });
    };

    const onChangeText = (
      index: number,
      field: keyof DevanModelInfo,
      value: string,
    ) => {
      validation(
        setUnexpectedListFormError,
        unexpectedListFormError,
        field,
        value,
        index,
      );
      let updatedList = [...unexpectedList];
      updatedList[index] = { ...updatedList[index], [field]: value };
      setUnexpectedList(updatedList);
    };

    const ExpireInfo = (item: DevanModelInfo, index: number) => {
      const error_message =
        formError.model_info.length > index &&
        formError.model_info[index].number_of_moves.length > 0
          ? formError.model_info[index].number_of_moves[0]
          : "";
      return (
        <Paper variant="outlined" sx={{ p: 2 }} key={index}>
          <Stack>
            <LabelValueText
              label="商品分類"
              value={item?.product_class ?? ""}
            />
            <LabelValueText label="機種名" value={item?.model_name ?? ""} />
            <LabelValueText
              label="搬入数"
              value={item?.number_of_moves ?? ""}
            />
          </Stack>
        </Paper>
      );
    };

    const unexpectedExpireInfo = (item: DevanModelInfo, index: number) => {
      let numberOfMovesError =
        unexpectedListFormError.model_info[index].number_of_moves;
      let modelINameError = unexpectedListFormError.model_name[index];
      let errorMessageModelName = modelINameError.join("\n");
      let errorMessageNumberOfMoves = numberOfMovesError.join("\n");
      return (
        <CancelBtnBadge
          onClick={() => {
            ModalController.show({
              message: messages.TEMPLATE.MSG_COMFIRM_DELETE_UNEXPECTED_ITEM,
              visibleButton1: true,
              visibleButton2: true,
              handlePressButton2: () => handleRemoveItem(index),
            });
          }}
          key={index}
          sx={{
            "& .MuiBadge-badge": {
              top: 5,
              right: 5,
            },
          }}
        >
          <Paper variant="outlined" sx={{ width: "100%", p: 2 }} key={index}>
            <Stack>
              <TextField
                label="機種名"
                required
                value={item.model_name}
                inputProps={{ maxLength: 40 }}
                onChange={(e) =>
                  onChangeText(index, "model_name", e.target.value)
                }
                onBlur={(e) =>
                  onChangeText(index, "model_name", e.target.value)
                }
                sx={{
                  "& .MuiInputLabel-asterisk": {
                    color: "red",
                  },
                }}
              />
              <TextField
                label="搬入数"
                required
                value={item.number_of_moves}
                inputProps={{ maxLength: 7 }}
                onChange={(e) =>
                  onChangeText(index, "number_of_moves", e.target.value)
                }
                onBlur={(e) =>
                  onChangeText(index, "number_of_moves", e.target.value)
                }
                sx={{
                  "& .MuiInputLabel-asterisk": {
                    color: "red",
                  },
                }}
              />
              {modelINameError.length > 0 && modelINameError[0] && (
                <FormHelperText error>{errorMessageModelName}</FormHelperText>
              )}
              {numberOfMovesError.length > 0 && numberOfMovesError[0] && (
                <FormHelperText error>
                  {errorMessageNumberOfMoves}
                </FormHelperText>
              )}
            </Stack>
          </Paper>
        </CancelBtnBadge>
      );
    };

    return (
      <Stack>
        {stateForm.model_info.map((item, index) => ExpireInfo(item, index))}
        {unexpectedList.map((item, index) => unexpectedExpireInfo(item, index))}
        <Button onClick={handleAddItem} fullWidth sx={{ mt: 1 }}>
          予定外機種を追加
        </Button>
        <StepperInputLabel
          label="開梱対象総台数"
          value={stateForm.unpacked_number}
          onChange={(v) => {
            setStateForm({ ...stateForm, unpacked_number: v });
          }}
          onBlur={() =>
            validation(
              setFormError,
              formError,
              "unpacked_number",
              stateForm.unpacked_number,
            )
          }
          error={formError.unpacked_number.length > 0}
          helperText={formError.unpacked_number}
          maxValue={9999999}
        />
        <StepperInputLabel
          label="開梱後異常無し"
          value={stateForm.no_abnomality_number}
          onChange={(v) => {
            setStateForm({ ...stateForm, no_abnomality_number: v });
          }}
          onBlur={() =>
            validation(
              setFormError,
              formError,
              "no_abnomality_number",
              stateForm.no_abnomality_number,
            )
          }
          error={formError.no_abnomality_number.length > 0}
          helperText={formError.no_abnomality_number}
          maxValue={9999999}
        />
      </Stack>
    );
  };

  const renderItem3 = () => {
    return (
      <Stack>
        {stateForm.model_info.map((item, index) => (
          <ProduStatusItem
            item={item}
            defectList={defectList}
            formError={formError.model_info[index]}
            setItem={(d) => {
              let clone = cloneDeep(stateForm);
              clone.model_info[index] = d;
              setStateForm(clone);
            }}
            setFormError={(d) => {
              let clone = cloneDeep(formError);
              clone.model_info[index] = d;
              setFormError(clone);
            }}
            validation={(field, value, i) => {
              validation(setFormError, formError, field, value, index, i);
            }}
            key={index}
            unexpected={false}
          />
        ))}
        {unexpectedList.map((item, index) => (
          <ProduStatusItem
            item={item}
            defectList={defectList}
            formError={unexpectedListFormError.model_info[index]}
            setItem={(d) => {
              let clone = cloneDeep(unexpectedList);
              clone[index] = d;
              setUnexpectedList(clone);
            }}
            setFormError={(d) => {
              let clone = cloneDeep(unexpectedListFormError);
              clone.model_info[index] = d;
              setUnexpectedListFormError(clone);
            }}
            validation={(field, value, i) => {
              validation(
                setUnexpectedListFormError,
                unexpectedListFormError,
                field,
                value,
                index,
                i,
              );
            }}
            key={index}
            unexpected={true}
          />
        ))}
      </Stack>
    );
  };

  const renderItem4 = () => {
    return (
      <Stack>
        <TextField
          label="異常報告"
          value={stateForm.abnomality_report}
          multiline
          rows={5}
          placeholder={"異常報告（最大300文字）"}
          inputProps={{ maxLength: 300 }}
          onChange={(e) => {
            if (e.target.value.split("\n").length > 5) {
              return;
            }
            onChangeText("abnomality_report")(e.target.value);
          }}
        />
        <FormControl>
          <InputLabel>不適合写真</InputLabel>
          <ImagePickerList
            addButtonText={"不適合写真を追加"}
            maxImage={maxNumberImageDefault}
            imagesList={stateForm.abnomality_image}
            onChange={(image_array) => {
              setStateForm((prev) => ({
                ...prev,
                abnomality_image: image_array,
              }));
            }}
          />
        </FormControl>
      </Stack>
    );
  };

  const renderMenuItem = useCallback(
    (list: Array<any>, value: string, name: string) => {
      return list
        ? list.map((item, index) => (
            <MenuItem value={item ? item[value] : ""} key={index}>
              {item ? (
                item[value] !== "0" ? (
                  <span className="IgnoreExtractRuleTarget">{item[name]}</span>
                ) : (
                  // 発見者氏名のその他(手入力)は翻訳
                  <em>{item[name]}</em>
                )
              ) : (
                <em>未選択</em>
              )}
            </MenuItem>
          ))
        : null;
    },
    [],
  );

  const handleSelectDevan = async (value: any) => {
    // 機種情報取得
    const param = {
      container_no: value.container_no,
      date: value.date_of_delivery,
      time: value.delivery_time,
    };

    const model_info = await getDavanByContainerApi(param)
      .then((res) => {
        if (res?.data) {
          return res?.data.map((item: any) => ({
            SK: item.SK,
            product_class: item.product_class,
            model_name: item.model_name,
            number_of_moves: item.number_of_moves,
            count: "",
            good_products_number: item.number_of_moves,
            defective_products_number: "",
            total_number: item.number_of_moves,
            defective_products: [],
            count_type: CountType.UNIT,
            good_products_pallet: [],
            defective_products_pallet: [],
          }));
        } else {
          return [];
        }
      })
      .catch(() => {
        return [];
      });

    // デバンスケジュールを設定
    setStateForm((prev) => ({
      ...prev,
      date_of_delivery: value.date_of_delivery,
      delivery_location: value.delivery_location,
      container_no: value.container_no,
      container_size: value.container_size,
      delivery_time: value.delivery_time,
      carrier_name: value.carrier_name,
      unit_class: value.unit_class,
      invoice_no: value.invoice_no,
      seal_no: value.seal_no,
      berth_number: value.berth_number,
      sealed: undefined,
      model_info: model_info,
      container_status: [],
      unpacked_number: "",
      no_abnomality_number: "",
      devan_schedule_id: value.SK,
      bool_seal_no: false,
    }));
    const form_error_model_info: IFormErrorModelInfo[] = model_info.map(() => ({
      number_of_moves: [],
      good_products_number: [],
      defective_products_number: [],
      count: [],
      defective_products_number_count: [],
      count_select: [],
      count_good_products: [],
      count_defective_products: [],
      pallet_good_products: [],
      pallet_defective_products: [],
    }));
    setFormError((prev) => ({
      ...prev,
      model_info: form_error_model_info,
      unpacked_number: "",
      no_abnomality_number: "",
    }));
  };

  // 保存 =====================================
  const onSubmit = async () => {
    // 画像アップロード
    let newImageArray: Array<Type.ImageInfoType> =
      stateForm.abnomality_image ?? [];
    if (stateForm.abnomality_image) {
      newImageArray = await uploadImageToS3(stateForm.abnomality_image);
    }
    let newStateForm = {
      ...stateForm,
      abnomality_image: newImageArray,
      unexpected_list: unexpectedList,
    };
    await setStateForm(newStateForm);
    handleSetTemplate();
    handleClose();
  };

  const validationAllUnexpectedItem = () => {
    let existError = false;
    let modelNameList = [] as any[];
    let tmpModelInfo = cloneDeep(unexpectedListFormError["model_info"]);
    for (let i = 0; i < unexpectedList.length; i++) {
      let mess_number_of_moves = Validation.validate({
        type: "number",
        value: unexpectedList[i].number_of_moves,
        required: true,
        name: "搬入数",
      });
      tmpModelInfo[i]["number_of_moves"] = [mess_number_of_moves];
      if (mess_number_of_moves.length > 0) existError = true;
      let mess_model_name = Validation.validate({
        type: "text",
        value: unexpectedList[i].model_name,
        required: true,
        name: "機種名",
      });
      modelNameList.push([mess_model_name]);
      if (mess_model_name.length > 0) existError = true;
    }
    setUnexpectedListFormError({
      model_info: tmpModelInfo,
      model_name: modelNameList,
    });

    return existError;
  };

  const validateModelInfoTotal = async () => {
    let response = true;
    let existError = await validationAllUnexpectedItem();
    if (existError) return false;
    for (let i = 0; i < stateForm.model_info.length; i++) {
      const elm_mf = stateForm.model_info[i];
      if (elm_mf.number_of_moves != elm_mf.total_number) {
        response = false;
      }
    }
    if (response) {
      await onSubmit();
    } else {
      ModalController.hide();
      setTimeout(() => {
        ModalController.show({
          message: messages.TEMPLATE.MSG_CONFIRM_TOTAL_MISMATCH,
          visibleButton1: true,
          visibleButton2: true,
          handlePressButton2: () => onSubmit(),
        });
      }, 100);
    }
    return false;
  };

  return (
    <Box height={"auto"}>
      <DevanSearch
        open={openDevanSearch}
        type={DEVAN_SEARCH_TYPE.DEVANIST}
        onClose={() => {
          setOpenDevanSearch(false);
        }}
        onSelect={handleSelectDevan}
      />
      <SelectMultipleOption
        commonHeaderTitle="コンテナの状態選択"
        isVisible={isMultipleOption}
        itemTextKey="container_status_name"
        itemImageKey="container_status_img"
        closeModal={() => setIsMultipleOption(false)}
        originData={listContainerStatus}
        onPressButton={(data: any) => {
          onChangeText("container_status")(data);
        }}
        listItemSelected={listSelectedTempt}
        setListItemSelected={(item: any) => setListSelectedTempt(item)}
      />
      <Button
        children="デバン検索"
        fullWidth
        sx={{ mb: 1 }}
        onClick={() => setOpenDevanSearch(true)}
        disabled={isManage}
      />
      <Card>
        <Stack>
          <CardContent>
            <Stack>
              <LabelValueText label="帳票番号" value={stateForm.risky_id} />
              <LabelValueText
                label="搬入年月日"
                value={stateForm.date_of_delivery}
              />
              <LabelValueText
                label="コンテナ番号"
                value={stateForm.container_no}
              />
              <LabelValueText
                label="コンテナサイズ"
                value={stateForm.container_size}
              />
              <LabelValueText
                label="納入時間"
                value={stateForm.delivery_time}
              />
              <LabelValueText label="搬入者名" value={stateForm.carrier_name} />
              <LabelValueText label="Unit区分" value={stateForm.unit_class} />
              <LabelValueText
                label="搬入場所"
                value={stateForm.delivery_location}
              />
              <TextField
                label="インボイスナンバー"
                inputProps={{ maxLength: 20 }}
                value={stateForm.invoice_no}
                onChange={(e) => {
                  setStateForm({
                    ...stateForm,
                    invoice_no: e.target.value,
                  });
                }}
              />
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <TextField
                  label="シールNo."
                  inputProps={{ maxLength: 20 }}
                  value={stateForm.seal_no}
                  onChange={(e) => {
                    setStateForm({
                      ...stateForm,
                      seal_no: e.target.value,
                    });
                  }}
                  sx={{ width: "90%" }}
                />
                <CheckboxLabel
                  label=""
                  checked={stateForm.bool_seal_no}
                  onChange={(_, checked) => {
                    setStateForm((prevState) => ({
                      ...prevState,
                      bool_seal_no: checked,
                    }));
                  }}
                  formSx={{ ml: 2, mt: 2 }}
                />
              </Box>

              <LabelValueText
                label="バース番号"
                value={stateForm.berth_number}
              />
              <FormControl>
                <InputLabel>
                  <LabelRequired title="封印" />
                </InputLabel>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                >
                  <Typography variant="body2">異常</Typography>
                  <ButtonGroup sx={{ ml: 1 }}>
                    <Button
                      variant={stateForm.sealed ? "contained" : "outlined"}
                      onClick={() => {
                        setStateForm((prevState) => ({
                          ...prevState,
                          sealed: prevState.sealed ? undefined : true,
                        }));
                      }}
                    >
                      有
                    </Button>
                    <Button
                      variant={
                        stateForm.sealed === false ? "contained" : "outlined"
                      }
                      onClick={() => {
                        setStateForm((prevState) => ({
                          ...prevState,
                          sealed:
                            prevState.sealed === false ? undefined : false,
                        }));
                      }}
                    >
                      無
                    </Button>
                  </ButtonGroup>
                </Box>
              </FormControl>
              <SelectLabel
                label="作業者名"
                value={stateForm.person_worker?.SK ?? ""}
                onChange={(e) => {
                  const selected = addIsManualListUser.find(
                    (item: any) => item && item.SK === e.target.value,
                  );
                  if (selected?.PK == "0") {
                    const otherSelectionItem = getPersonFoundIssueOther(
                      stateForm.person_worker_other,
                    );
                    setStateForm({
                      ...stateForm,
                      person_worker: otherSelectionItem,
                    });
                  } else {
                    onChangeSelect("person_worker")(addIsManualListUser, e);
                  }
                }}
              >
                {renderMenuItem(addIsManualListUser, "SK", "full_name")}
              </SelectLabel>
              <TextField
                label="その他（作業者名）"
                inputProps={{ maxLength: 30 }}
                value={stateForm.person_worker_other}
                disabled={!stateForm.person_worker?.is_manual_input ?? true}
                onChange={(e) => {
                  const person_found_issue = getPersonFoundIssueOther(
                    e.target.value,
                  );
                  setStateForm({
                    ...stateForm,
                    person_worker_other: e.target.value,
                    person_worker: person_found_issue,
                  });
                }}
              />
            </Stack>
          </CardContent>

          <AccordionSection
            title={"Ⅰ.コンテナの状態"}
            children={renderItem1()}
          />

          <AccordionSection title={"Ⅱ.入荷予定情報"} children={renderItem2()} />

          <AccordionSection title={"Ⅲ.製品の状態"} children={renderItem3()} />

          <AccordionSection title={"Ⅳ.異常報告"} children={renderItem4()} />

          <Box paddingX={2} pb={2}>
            <CheckboxLabel
              label="Ⅴ.2段積み冷蔵庫、3段積み洗濯機は2人作業を実施"
              checked={stateForm.bool_persons}
              onChange={(_, checked) => {
                setStateForm((prevState) => ({
                  ...prevState,
                  bool_persons: checked,
                }));
              }}
            />
            <CheckboxLabel
              label="注意事項を表示する"
              checked={stateForm.bool_attention}
              onChange={(_, checked) => {
                setStateForm((prevState) => ({
                  ...prevState,
                  bool_attention: checked,
                }));
              }}
            />
          </Box>
        </Stack>
      </Card>
    </Box>
  );
};

export default forwardRef(InspectionReport);
