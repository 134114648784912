import React, { useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  FormGroup
} from "@mui/material";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import TimePickerCustom from "components/atoms/TimePickerCustom";
import { IUpdateDataDevanPlan, initialData } from "screens/DevanList";
import { useState } from "react";
import { Validation } from "@validation";
import LabelRequired from "components/atoms/LabelRequired";
import _ from "lodash";
import formatDateToString from "@utils/DateFormat";
import { Colors } from "@template/style";
import LabelValueText from "components/atoms/LabelValueText";

interface IDevanEditScreen {
  data: IUpdateDataDevanPlan;
  open: boolean;
  onClose: () => void;
  onSave: (data: IUpdateDataDevanPlan) => void;
}

export interface IStateForm {
  date_of_delivery: string;
  container_no: string;
  product_class: string;
  model_name: string;
  number_of_moves: string;
  container_size: string;
  delivery_time: string;
  berth_number: string;
  carrier_name: string;
  unit_class: string;
  delivery_location: string;
  invoice_no: string;
  seal_no: string;
}

const initalFormError = {
  date_of_delivery: "",
  container_no: "",
  product_class: "",
  model_name: "",
  number_of_moves: "",
  container_size: "",
  delivery_time: "",
  berth_number: "",
  carrier_name: "",
  unit_class: "",
  delivery_location: "",
  invoice_no: "",
  seal_no: "",
};

const DevanEditScreen = ({ data, open, onClose, onSave }: IDevanEditScreen) => {
  const [stateForm, setStateForm] = useState(initialData);
  const [originData, setOriginData] = useState(initialData);
  const [formError, setFormError] = useState(initalFormError);
  const [delivery_time_date, setDeliveryTimeDate] = useState<Date | null>(null);

  // 入力制御 ------------------------------------------------
  const onChangeText =
    (field: keyof IUpdateDataDevanPlan) => (newText: string | null) => {
      setStateForm({ ...stateForm, [field]: newText });
    };

  const onChangeDate =
    (field: keyof IUpdateDataDevanPlan) => (newValue: string | null) => {
      newValue = formatDateToString(newValue, "YMD_sl");
      setStateForm({ ...stateForm, [field]: newValue });
    };

  const onChangeTime =
    (field: keyof IUpdateDataDevanPlan) => (newValue: Date | null) => {
      const time = formatDateToString(newValue, "HHmm");
      setDeliveryTimeDate(newValue);
      setStateForm({ ...stateForm, [field]: time });
    };

  useEffect(() => {
    setOriginData(data);
    setStateForm(data);
    setDeliveryTimeDate(
      new Date(data.date_of_delivery + " " + data.delivery_time),
    );
    setFormError(initalFormError);
  }, [data]);

  const hasUpdateData = useMemo(() => {
    return stateForm && originData && !_.isEqual(stateForm, originData);
  }, [stateForm, originData]);

  const disabledSaveBtn = useMemo(() => {
    const error_messages = Object.values(formError).filter(
      (item) => item.length > 0,
    );
    if (!hasUpdateData || error_messages.length > 0) return true;
    return false;
  }, [formError, hasUpdateData]);

  const onValidateText = (field: keyof IStateForm) => {
    const mess = validator(field, String(stateForm[field]));
    setFormError({ ...formError, [field]: mess });
  };

  const validator = (field: keyof IUpdateDataDevanPlan, value: string) => {
    let mess: string = "";
    switch (field) {
      case "date_of_delivery":
        mess = Validation.validateDate(value, "搬入年月日", true);
        break;
      case "container_no":
        mess = Validation.validate({
          type: "text",
          name: "コンテナ番号",
          value: value,
          required: true,
          max_length: 20,
        });
        break;
      case "product_class":
        mess = Validation.validate({
          type: "text",
          name: "商品分類",
          value: value,
          required: true,
          max_length: 10,
        });
        break;
      case "model_name":
        mess = Validation.validate({
          type: "text",
          name: "機種名",
          value: value,
          required: true,
          max_length: 40,
        });
        break;
      case "number_of_moves":
        mess = Validation.validate({
          type: "number",
          name: "搬入数",
          value: value,
          required: true,
          max_length: 7,
        });
        break;
      case "container_size":
        mess = Validation.validate({
          type: "text",
          name: "コンテナサイズ",
          value: value,
          required: true,
          max_length: 10,
        });
        break;
      case "delivery_time":
        mess = Validation.validate({
          type: "time",
          name: "納入時間",
          value: value,
          required: true,
        });
        break;
      case "berth_number":
        mess = Validation.validate({
          type: "text",
          name: "バース番号",
          value: value,
          required: true,
          max_length: 5,
        });
        break;
      case "carrier_name":
        mess = Validation.validate({
          type: "text",
          name: "搬入者名",
          value: value,
          required: true,
          max_length: 10,
        });
        break;
      case "unit_class":
        mess = Validation.validate({
          type: "text",
          name: "Unit区分",
          value: value,
          required: false,
          max_length: 5,
        });
        break;
      case "delivery_location":
        mess = Validation.validate({
          type: "text",
          name: "搬入場所",
          value: value,
          required: true,
          max_length: 20,
        });
        break;
      case "invoice_no":
        mess = Validation.validate({
          type: "text",
          name: "インボイスナンバー",
          value: value,
          required: true,
          max_length: 20,
        });
        break;
      case "seal_no":
        mess = Validation.validate({
          type: "text",
          name: "シールナンバー",
          value: value,
          required: false,
          max_length: 20,
        });
        break;
    }
    return mess;
  };

  return (
    <Dialog
      maxWidth="lg"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <Stack sx={{ width: "90%" }}>
          <FormGroup sx={{ width: "20%" }}>
            <DatePickerCustom
              label={
                <>
                  <LabelRequired title={"搬入年月日"} />
                </>
              }
              value={stateForm?.date_of_delivery}
              onChange={(e) => {
                onChangeDate("date_of_delivery")(e);
              }}
              onBlur={() => onValidateText("date_of_delivery")}
              error={formError.date_of_delivery.length > 0}
              helperText={formError.date_of_delivery}
            />
          </FormGroup>
          <TextField
            label={
              <>
                <LabelRequired title={"コンテナ番号"} />
              </>
            }
            value={stateForm?.container_no}
            name="container_no"
            onChange={(e) => onChangeText("container_no")(e.target.value)}
            onBlur={() => onValidateText("container_no")}
            error={formError.container_no.length > 0}
            helperText={formError.container_no}
          />
          <TextField
            label={
              <>
                <LabelRequired title={"商品分類"} />
              </>
            }
            value={stateForm?.product_class}
            name="product_class"
            onChange={(e) => onChangeText("product_class")(e.target.value)}
            onBlur={() => onValidateText("product_class")}
            error={formError.product_class.length > 0}
            helperText={formError.product_class}
          />
          <TextField
            label={
              <>
                <LabelRequired title={"機種名"} />
              </>
            }
            value={stateForm?.model_name}
            name="model_name"
            onChange={(e) => onChangeText("model_name")(e.target.value)}
            onBlur={() => onValidateText("model_name")}
            error={formError.model_name.length > 0}
            helperText={formError.model_name}
          />
          <TextField
            label={
              <>
                <LabelRequired title={"搬入数"} />
              </>
            }
            value={stateForm?.number_of_moves}
            name="number_of_moves"
            onChange={(e) => onChangeText("number_of_moves")(e.target.value)}
            onBlur={() => onValidateText("number_of_moves")}
            error={formError.number_of_moves.length > 0}
            helperText={formError.number_of_moves}
          />
          <TextField
            label={
              <>
                <LabelRequired title={"コンテナサイズ"} />
              </>
            }
            value={stateForm?.container_size}
            name="container_size"
            onChange={(e) => onChangeText("container_size")(e.target.value)}
            onBlur={() => onValidateText("container_size")}
            error={formError.container_size.length > 0}
            helperText={formError.container_size}
          />
          <FormGroup sx={{ width: "20%" }}>
            <TimePickerCustom
              label={
                <>
                  <LabelRequired title={"納入時間"} />
                </>
              }
              // name="delivery_time"
              value={delivery_time_date}
              onChange={(e) => onChangeTime("delivery_time")(e)}
              onBlur={() => onValidateText("delivery_time")}
              error={formError.delivery_time.length > 0}
              helperText={formError.delivery_time}
            />
          </FormGroup>
          <Box>
            <LabelValueText
              label="バース番号"
              value={stateForm?.berth_number ? stateForm.berth_number : " "}
            />
          </Box>
          <TextField
            label={
              <>
                <LabelRequired title={"搬入者名"} />
              </>
            }
            value={stateForm?.carrier_name}
            name="carrier_name"
            onChange={(e) => onChangeText("carrier_name")(e.target.value)}
            onBlur={() => onValidateText("carrier_name")}
            error={formError.carrier_name.length > 0}
            helperText={formError.carrier_name}
          />
          <TextField
            label={"Unit区分"}
            value={stateForm?.unit_class}
            name="unit_class"
            onChange={(e) => onChangeText("unit_class")(e.target.value)}
            onBlur={() => onValidateText("unit_class")}
            error={formError.unit_class.length > 0}
            helperText={formError.unit_class}
          />
          <TextField
            label={
              <>
                <LabelRequired title={"搬入場所"} />
              </>
            }
            value={stateForm?.delivery_location}
            name="delivery_location"
            onChange={(e) => onChangeText("delivery_location")(e.target.value)}
            onBlur={() => onValidateText("delivery_location")}
            error={formError.delivery_location.length > 0}
            helperText={formError.delivery_location}
          />
          <TextField
            label={
              <>
                <LabelRequired title={"インボイスナンバー"} />
              </>
            }
            value={stateForm?.invoice_no}
            name="invoice_no"
            onChange={(e) => onChangeText("invoice_no")(e.target.value)}
            onBlur={() => onValidateText("invoice_no")}
            error={formError.invoice_no.length > 0}
            helperText={formError.invoice_no}
          />
          <TextField
            label={"シールナンバー"}
            value={stateForm?.seal_no}
            name="seal_no"
            onChange={(e) => onChangeText("seal_no")(e.target.value)}
            onBlur={() => onValidateText("seal_no")}
            error={formError.seal_no.length > 0}
            helperText={formError.seal_no}
          />
          <Box sx={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                onSave(stateForm);
              }}
              disabled={disabledSaveBtn}
              color="secondary"
            >
              保存
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DevanEditScreen;
