import {
  METHOD_API,
  createConnector,
  getParamsHelp,
  prefixApi,
} from "./Connector";
import { httpClient } from "./HttpClient";

interface IParams {
  project_id: string;
  sort_type: string;
  filter_condition: string;
  keyword: string;
}

interface IParamsV2 {
  work_type: string;
  sort_type: string;
  filter_condition: string;
  keyword: string;
}

interface IParamsUserProject {
  project_id: string;
  keyword: string;
  user_id?: string;
  ignore_me: boolean;
}

export const getListWorkApi = (params: IParams) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/work?${getParamsHelp(params)}`,
    null,
  );
};

export const getListHistoryWorkApi = (work_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/work_history/${encodeURIComponent(work_id)}`,
    null,
  );
};

export const getListWorkV2Api = (params: IParamsV2) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/work_v2?${getParamsHelp(params)}`,
    null,
  );
};

export const createWorkApi = (work_data: FormData, params: any) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/work/${encodeURIComponent(params.project_id)}`,
    work_data,
  );
};

export const getWorkByIdApi = (params: any) => {
  const { project_id, work_id } = params;
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/work/${encodeURIComponent(project_id)}/${encodeURIComponent(
      work_id,
    )}`,
    null,
  );
};
export const getWorkListDetailApi = (dataBody: object) => {
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/work-list-detail`,
    dataBody,
  );
};
export const updateWorkByIdApi = (
  project_id: string,
  work_id: string,
  dataBody: object,
) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_PUT,
    `${prefixApi}/work/${encodeURIComponent(project_id)}/${encodeURIComponent(
      work_id,
    )}`,
    dataBody,
  );
};
export const updateWorkBatchApi = (dataBody: object) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_PUT,
    `${prefixApi}/work-update-batch`,
    dataBody,
  );
};

export const getListUserProjectApi = (params: IParamsUserProject) => {
  const { project_id, ...otherParams } = params;
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/project/${encodeURIComponent(
      project_id,
    )}/users?${getParamsHelp(otherParams)}`,
    null,
  );
};

export const updateStatusGroupWorkItemApi = (dataBody: {
  work_ids: string[];
  is_delete: boolean;
  project_info: any[];
}) => {
  return createConnector(
    METHOD_API.PUT,
    `${prefixApi}/work-update-status`,
    dataBody,
  );
};

export const createWorkRejectApi = (data: FormData, params: any) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/work/work-reject/${encodeURIComponent(
      params?.project_id,
    )}/${encodeURIComponent(params?.work_id)}`,
    data,
  );
};

export const getListHistoryRejectApi = (work_id: any, lastKey?: any) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/work/reject/${encodeURIComponent(work_id)}?last_key=${
      lastKey ? encodeURIComponent(lastKey) : ""
    }`,
    null,
  );
};

export const getRiskyNumberApi = (location_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/work/${encodeURIComponent(location_id)}`,
    null,
  );
};

export const getDashboardInfoApi = (data: any) => {
  data["is_force_count"] = true;
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/work_dashboard?${getParamsHelp(data)}`,
    null,
  );
};

export const getListWorkSearchApi = (body: any) => {
  return createConnector(METHOD_API.POST, `${prefixApi}/work-search`, body);
};

export const getCountHandoverWorkList = (data: any) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/work/count_handover_work_list?${getParamsHelp(data)}`,
    null,
  );
};

// export const getHandoverWorkList = (data: any) => {
//   return createConnector(
//     METHOD_API.GET,
//     `${prefixApi}/work/handover_work_list?${getParamsHelp(data)}`,
//     null,
//   );
// };

export const getHandoverWorkList = (data: any) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/work/handover_work_list_v2?${getParamsHelp(data)}`,
    null,
  );
};

export const checkForkliftWorkExist = (data: any) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/work/check-forklift-exist?${getParamsHelp(data)}`,
    null,
  );
};

export const getMaxApprovalsApi = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/max-approvals`, null);
};

export const getCompressedTemplatePDF = async (dataBody: any) => {
  const DOMAIN = process.env.REACT_APP_API_URL;
  return await httpClient
    .post(`${DOMAIN}/${prefixApi}/get-compressed-template`, dataBody, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      responseType: "blob",
    })
    .then((x) => (x ? x.data : undefined));
};

export const updateWorkPdf = (
  project_id: string,
  work_id: string,
  dataBody: object,
) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/update-work-pdf/${encodeURIComponent(
      project_id,
    )}/${encodeURIComponent(work_id)}`,
    dataBody,
  );
};

export const getMergedTemplatePDF = async (dataBody: any) => {
  const DOMAIN = process.env.REACT_APP_API_URL;
  return await httpClient
    .post(`${DOMAIN}/${prefixApi}/get-merged-template`, dataBody, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      responseType: "blob",
    })
    .then((x) => (x ? x.data : undefined));
};

export const updateWorkTemplateInfoApi = (
  work_id: string,
  template_id: string,
  dataBody: object,
) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_PUT,
    `${prefixApi}/update-work-template-info/${encodeURIComponent(
      work_id,
    )}/${encodeURIComponent(template_id)}`,
    dataBody,
  );
};

export const getCompressedAttachedFile = async (dataBody: any) => {
  const DOMAIN = process.env.REACT_APP_API_URL;
  return await httpClient
    .get(
      `${DOMAIN}/${prefixApi}/get-compressed-attached-file?${getParamsHelp(
        dataBody,
      )}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
        responseType: "blob",
      },
    )
    .then((x) => (x ? x.data : undefined));
};
