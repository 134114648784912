import { useMemo, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Stack,
} from "@mui/material";
import Dropzone from "components/atoms/Dropzone";
import DateRangePicker from "components/atoms/DatePickerCustom/DateRangePicker";
import DropzoneDialog from "components/molecules/DropzoneDialog";
import { HeadCell, TableCustom } from "components/organisms/TableCustom";
import formatDateToString from "@utils/DateFormat";
import { Validation, ValidateType } from "@validation";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import {
  createDevanPlan,
  IDataDevanPlan,
  getExistDevanPlan,
  ExistDevanPlanResultCode,
} from "@api/devan";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import { Colors } from "@template/style";
import * as XLSX from "xlsx";
import { forEach, result } from "lodash";
const headCells: HeadCell[] = [
  {
    id: "date_of_delivery",
    label: "搬入年月日",
    width: 110,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "container_no",
    label: "コンテナ番号",
    width: 130,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "product_class",
    label: "商品分類",
    width: 90,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "model_name",
    label: "機種名",
    width: 125,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "number_of_moves",
    label: "搬入数",
    width: 80,
    numeric: true,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "container_size",
    label: "コンテナサイズ",
    width: 70,
    disablePadding: true,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "delivery_time",
    label: "納入時間",
    width: 90,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "carrier_name",
    label: "搬入者名",
    width: 90,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "unit_class",
    label: "Unit区分",
    width: 90,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "delivery_location",
    label: "搬入場所",
    width: 90,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "invoice_no",
    label: "インボイスナンバー",
    width: 160,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "seal_no",
    label: "シールナンバー",
    width: 150,
    isIgnoreExtractRuleCell: true,
  },
];

const header = [
  "搬入年月日",
  "コンテナ番号",
  "商品分類",
  "機種名",
  "搬入数",
  "コンテナサイズ",
  "納入時間",
  "搬入者名",
  "Unit区分",
  "搬入場所",
  "インボイスナンバー",
  "シールNo.",
];

const DateOfDeliveryNum = 0;
const ContainerNoNum = 1;
const ProductClassNum = 2;
const ModelNameNum = 3;
const NumberOfMovesNum = 4;
const ContainerSizeNum = 5;
const DeliveryTimeNum = 6;
const CarrierNameNum = 7;
const UnitClassNum = 8;
const DeliveryLocationNum = 9;
const InvoiceNoNum = 10;
const SealNoNum = 11;

//必須ではない項目
const notCheckList = ["seal_no", "invoice_no", "unit_class"];

//コンテナ情報に代表値を設定する項目
const representativeList = [
  { id: "invoice_no", number: InvoiceNoNum },
  { id: "unit_class", number: UnitClassNum },
];

const validateList = [
  {
    id: "date_of_delivery",
    validation: {
      type: "date" as ValidateType,
      name: "搬入年月日",
      required: true,
    },
  },
  {
    id: "container_no",
    validation: {
      type: "text" as ValidateType,
      name: "コンテナ番号",
      required: true,
      max_length: 20,
    },
  },
  {
    id: "product_class",
    validation: {
      type: "text" as ValidateType,
      name: "商品分類",
      required: true,
      max_length: 10,
    },
  },
  {
    id: "model_name",
    validation: {
      type: "text" as ValidateType,
      name: "機種名",
      required: true,
      max_length: 40,
    },
  },
  {
    id: "number_of_moves",
    validation: {
      type: "number" as ValidateType,
      name: "搬入数",
      required: true,
      max_length: 7,
    },
  },
  {
    id: "container_size",
    validation: {
      type: "text" as ValidateType,
      name: "コンテナサイズ",
      required: true,
      max_length: 10,
    },
  },
  {
    id: "delivery_time",
    validation: {
      type: "date" as ValidateType,
      name: "納入時間",
      required: true,
    },
  },
  {
    id: "carrier_name",
    validation: {
      type: "text" as ValidateType,
      name: "搬入者名",
      required: true,
      max_length: 10,
    },
  },
  {
    id: "unit_class",
    validation: {
      type: "text" as ValidateType,
      name: "Unit区分",
      required: false,
      max_length: 5,
    },
  },
  {
    id: "delivery_location",
    validation: {
      type: "text" as ValidateType,
      name: "搬入場所",
      required: true,
      max_length: 20,
    },
  },
  {
    id: "invoice_no",
    validation: {
      type: "text" as ValidateType,
      name: "インボイスナンバー",
      required: false,
      max_length: 20,
    },
  },
  {
    id: "seal_no",
    validation: {
      type: "text" as ValidateType,
      name: "シールナンバー",
      required: false,
      max_length: 20,
    },
  },
];

interface IStateForm {
  target_date: string;
}

interface IStateFormByContainer {
  date_of_delivery: string;
  container_no: string;
  container_size: string;
  delivery_time: string;
  carrier_name: string;
  delivery_location: string;
  invoice_no: string;
  unit_class: string;
  seal_no: string;
  key?: string;
  [key: string]: any;
}

const ErrorType = {
  RequiredValueValidationError: "RequiredValueValidationError", //データのバリデーションエラー
  InvalidHeaderError: "InvalidHeaderError", //ファイル形式が異なるためのエラー(ヘッダーがおかしい)
  ValueMismatchedError: "ValueMismatchedError", //コンテナ番号、搬入年月日、搬入時間が一致のデータで、機種情報を除くデータの中で不一致があるときのエラー
};

const initialState = { target_date: "" };

const DevanImportScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [listDevan, setListDevan] = useState<IDataDevanPlan[]>([]);
  const [stateForm, setStateForm] = useState<IStateForm>(initialState);
  const [formError, setFormError] = useState<IStateForm>(initialState);
  const disabled = useMemo(() => listDevan.length == 0, [listDevan]);
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [listDevanByContainer, setListDevanByContainer] = useState<
    IStateFormByContainer[]
  >([]); //搬入年月日、コンテナ、搬入時間ごとのデバン予定
  const rowCount = useMemo(() => {
    return listDevan.length;
  }, [listDevan]);

  // 多重保存リクエスト送信回避用のフラグ
  let isSaving: boolean = false;

  const importDisabled = useMemo(
    () =>
      file === null ||
      stateForm.target_date === "" ||
      formError.target_date !== "",
    [stateForm, file, formError],
  );

  const handleClear = () => {
    setListDevan([]);
    setListDevanByContainer([]);
  };

  const handleImport = () => {
    if (file) {
      //初期化
      setListDevan([]);
      setListDevanByContainer([]);
      //データの整形
      parseSheet(file);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 月は 0-indexed なので +1 し、2桁にパディング
    const day = String(date.getDate()).padStart(2, "0"); // 日を2桁にパディング // フォーマット済みの日付文字列を作成
    return `${year}/${month}/${day}`;
  };

  const handleComfirmSave = async () => {
    try {
      LoadingOverlayController.show();
      const formData = {
        listDevan: listDevan,
        listDevanByContainer: listDevanByContainer.map((item) => {
          //キーを削除
          const newItem = { ...item };
          if ("key" in newItem) {
            delete newItem["key"];
          }
          return newItem;
        }),
      };
      const resultCode = await getExistDevanPlan(formData);
      if (resultCode == ExistDevanPlanResultCode.EXISTS) {
        LoadingOverlayController.hide();
        ModalController.show({
          message: messages.DEVAN.MSG_UPDATE_DEVAN_LIST,
          visibleButton1: true,
          handlePressButton1: () => {},
          visibleButton2: true,
          handlePressButton2: () => {
            handleSave(true);
          },
          disableFeedback: true,
        });
      } else if (resultCode == ExistDevanPlanResultCode.EXISTS_DELIVERED) {
        LoadingOverlayController.hide();
        ModalController.show({
          message: messages.DEVAN.MSG_EXISTS_DELIVERED,
          visibleButton2: true,
          handlePressButton2: () => {},
        });
      } else {
        handleSave();
      }
    } catch (error: any) {
      LoadingOverlayController.hide();
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_ERROR_002("デバン予定表"),
        visibleButton2: true,
        handlePressButton2: () => {},
      });
    }
  };

  const handleSave = async (isOverride: boolean = false) => {
    if (isSaving) {
      console.log("保存リクエスト中のため操作を破棄");
      return;
    }
    isSaving = true;

    try {
      if (!LoadingOverlayController.isShowing())
        LoadingOverlayController.show();

      const formData = {
        listDevan: listDevan,
        listDevanByContainer: listDevanByContainer.map((item) => {
          //キーを削除
          const newItem = { ...item };
          if ("key" in newItem) {
            delete newItem["key"];
          }
          return newItem;
        }),
        is_override: isOverride,
      };
      const res = await createDevanPlan(formData);
      if (res) {
        ModalController.show({
          message:
            messages.COMMON.MSG_COMMON_SUCCESS_001("デバン予定表の保存") +
            `（取込件数：${res.count}件）`,
          visibleButton2: true,
          handlePressButton2: () => {},
        });
      }
    } catch (error: any) {
      const msg =
        error == undefined || error.detail == ""
          ? messages.COMMON.MSG_COMMON_ERROR_002("デバン予定表")
          : error.detail;
      ModalController.show({
        message: msg,
        visibleButton2: true,
        handlePressButton2: () => {},
      });
    } finally {
      LoadingOverlayController.hide();
      isSaving = false;
    }
  };

  const onChangeDate = (field: string) => (value: string | null) => {
    const mess = Validation.validateDate(value ?? "", "取込対象日", true);
    setFormError({ ...formError, [field]: mess });
    setStateForm((prev) => ({ ...prev, [field]: value }));
  };

  const chooseFile = (response: any) => {
    setFileName(response[0].name);
    setFile(response[0]);
    setOpen(false);
  };

  const isVaildDate = (date: any) => {
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate.getTime())) {
      return parsedDate;
    }
    return false;
  };

  const isVaildNum = (num: any) => {
    const parsedInt = parseInt(num);
    if (!isNaN(parsedInt) && isFinite(num)) {
      return parsedInt;
    }
    return false;
  };

  function isValidTimeFormat(input: any) {
    // 半角数字が1桁または2桁、コロン(:)、半角数字が1桁または2桁の正規表現パターン
    var pattern = /^\d{1,2}:\d{1,2}$/;
    return pattern.test(input);
  }

  // フォームで入力されたExcelのsheetNameシートをオブジェクトにする。
  const parseSheet = (file: File) => {
    var reader = new FileReader();
    reader.addEventListener("load", function (e: any) {
      var errorContainerNo = ""; //データ不一致が起きたコンテナ番号
      try {
        var unit8 = new Uint8Array(e.target?.result);
        var workbook = XLSX.read(unit8, { type: "array", cellDates: true });
        var listDevanScheduleByContainer: IStateFormByContainer[] = [];
        const sheetName = workbook.SheetNames[0];
        // 通常は1行目がヘッダ行となる。{header: 1} を指定で配列形式となる。
        var sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
        });
        var sheetList: IDataDevanPlan[] = [];
        var targetDate = new Date(stateForm.target_date);
        sheet.forEach((item: any, i: number) => {
          //sheetの0番目は、ヘッダーなので、除外
          if (i !== 0 && item.length > 0) {
            if (
              item[DateOfDeliveryNum] &&
              !(item[DateOfDeliveryNum] instanceof Date) &&
              isVaildDate(item[DateOfDeliveryNum])
            ) {
              //搬入年月日がDate型に変換可能な場合
              //データ型で値を書き換える
              item[DateOfDeliveryNum] = isVaildDate(item[DateOfDeliveryNum]);
            }
            if (item[UnitClassNum] === undefined) item[UnitClassNum] = "";
            if (
              item[DateOfDeliveryNum] instanceof Date &&
              item[DateOfDeliveryNum].getFullYear() ===
                targetDate.getFullYear() &&
              item[DateOfDeliveryNum].getMonth() === targetDate.getMonth() &&
              item[DateOfDeliveryNum].getDate() === targetDate.getDate()
            ) {
              //必須項目のチェック
              for (var j = 0; j < headCells.length; j++) {
                if (
                  j === DeliveryTimeNum &&
                  !(item[j] instanceof Date) &&
                  isValidTimeFormat(item[j])
                ) {
                  //納入時間がDate型でないかつ、「半角数字が1桁または2桁、コロン(:)、半角数字が1桁または2桁」の文字列な場合
                  //データ型で値を書き換える
                  //納入時間で例(文字列の23:00:00)のようなデータが入ってきたときに、年月日を補うことで、日付型に変換可能とする
                  item[j] = isVaildDate("1900/1/1 " + item[j]);
                } else if (
                  j === DeliveryTimeNum &&
                  !(item[j] instanceof Date) &&
                  !isValidTimeFormat(item[j])
                ) {
                  //納入時間がDate型でないかつ、「半角数字が1桁または2桁、コロン(:)、半角数字が1桁または2桁」の文字列ではない場合
                  console.log(
                    i +
                      1 +
                      "行目「" +
                      headCells[j].label +
                      "」の列で、有効な値を入力してください",
                  );
                  throw new Error(ErrorType.RequiredValueValidationError);
                }

                if (
                  isVaildNum(item[NumberOfMovesNum]) &&
                  !(item[j] instanceof Number)
                ) {
                  //搬入数がNumber型でないかつ、Number型に変換可能な場合
                  //データ型で値を書き換える
                  item[NumberOfMovesNum] = isVaildNum(item[NumberOfMovesNum]);
                }

                var index = validateList.findIndex(
                  (validate) => validate.id === headCells[j]?.id,
                );
                if (
                  (item[j] !== undefined &&
                    !notCheckList.includes(headCells[index].id)) ||
                  notCheckList.includes(headCells[index].id)
                ) {
                  if (index > -1) {
                    var error = Validation.validate({
                      ...validateList[index].validation,
                      value: String(item[j]),
                    });
                    if (error) {
                      //ヘッダー行の分、行数に1足す
                      console.log(
                        i +
                          1 +
                          "行目「" +
                          headCells[index].label +
                          "」の列で、" +
                          error,
                      );
                      throw new Error(ErrorType.RequiredValueValidationError);
                    }
                  } else {
                    //ここでErrorが投げられた場合はソースがおかしい
                    throw Error;
                  }
                } else {
                  console.log(
                    i +
                      1 +
                      "行目「" +
                      headCells[index].label +
                      "」の列で、値を入力してください",
                  );
                  throw new Error(ErrorType.RequiredValueValidationError);
                }
              }

              var delivery_time = "";
              if (item[DeliveryTimeNum] instanceof Date) {
                const hours = item[DeliveryTimeNum].getHours();
                const minutes = item[DeliveryTimeNum].getMinutes();
                delivery_time = `${hours.toString().padStart(2, "0")}:${minutes
                  .toString()
                  .padStart(2, "0")}`;
              }

              var date_of_delivery = item[DateOfDeliveryNum].toLocaleDateString(
                "ja-JP",
                {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                },
              );

              var row = {
                date_of_delivery: date_of_delivery,
                container_no: item[ContainerNoNum],
                product_class: item[ProductClassNum],
                model_name: item[ModelNameNum],
                number_of_moves: item[NumberOfMovesNum],
                container_size: item[ContainerSizeNum],
                delivery_time: delivery_time,
                carrier_name: item[CarrierNameNum],
                unit_class: item[UnitClassNum],
                delivery_location: item[DeliveryLocationNum],
                invoice_no: item[InvoiceNoNum],
                seal_no: item[SealNoNum],
              };

              //コンテナ番号、搬入日一致で機種情報以外の一致を確認
              var key = item[ContainerNoNum] + date_of_delivery;
              var comparativeData = listDevanScheduleByContainer.filter(
                (item: any) => item["key"] === key,
              );
              if (comparativeData.length > 0) {
                if (
                  //データの一致を確認
                  comparativeData.length === 1 &&
                  comparativeData[0].date_of_delivery === date_of_delivery &&
                  comparativeData[0].delivery_time === delivery_time &&
                  comparativeData[0].container_no === item[ContainerNoNum] &&
                  comparativeData[0].container_size ===
                    item[ContainerSizeNum] &&
                  comparativeData[0].carrier_name === item[CarrierNameNum] &&
                  comparativeData[0].delivery_location ===
                    item[DeliveryLocationNum] &&
                  comparativeData[0].seal_no === item[SealNoNum]
                ) {
                  //代表値作成
                  //comparativeDataのinvoice_no,unit_classがundefinedだった場合は、値を上書き
                  representativeList.forEach((k) => {
                    const id = k.id;
                    const number = k.number;
                    if (
                      comparativeData[0][id] === undefined &&
                      item[number] !== undefined
                    ) {
                      let containerDataIndex =
                        listDevanScheduleByContainer.findIndex(
                          (item: any) => item["key"] === key,
                        );
                      listDevanScheduleByContainer[containerDataIndex][id] =
                        item[number];
                    }
                  });
                  sheetList.push(row);
                } else {
                  //エラー発生
                  errorContainerNo = item[ContainerNoNum];
                  console.log(
                    "コンテナ番号「" +
                      errorContainerNo +
                      "」のデータに不一致があります",
                  );
                  throw new Error(ErrorType.ValueMismatchedError);
                }
              } else {
                listDevanScheduleByContainer.push({ ...row, key: key });
                sheetList.push(row);
              }
            }
          } else if (i === 0) {
            if (item.toString() !== header.toString()) {
              console.log("ヘッダー行が間違っています。");
              throw new Error(ErrorType.InvalidHeaderError);
            }
          }
        });
        if (sheetList.length === 0) {
          ModalController.show({
            message: messages.DEVAN.MSG_NOT_EXIST_DEVAN_PLAN,
            visibleButton2: true,
            handlePressButton2: () => {},
          });
        } else {
          setListDevan(sheetList);
          setListDevanByContainer(listDevanScheduleByContainer);
        }
      } catch (e: any) {
        if (e.message === ErrorType.RequiredValueValidationError) {
          ModalController.show({
            message: messages.DEVAN.MSG_ERROR_DEVAN_PLAN,
            visibleButton2: true,
            handlePressButton2: () => {},
          });
        } else if (e.message === ErrorType.InvalidHeaderError) {
          ModalController.show({
            message: messages.DEVAN.MSG_ERROR_FILE,
            visibleButton2: true,
            handlePressButton2: () => {},
          });
        } else if (e.message === ErrorType.ValueMismatchedError) {
          ModalController.show({
            message: messages.DEVAN.MSG_MISMATCHED_VALUE(errorContainerNo),
            visibleButton2: true,
            handlePressButton2: () => {},
          });
        }
      }
    });
    reader.readAsArrayBuffer(file);
  };

  return (
    <GenericTemplate title="デバン予定表取込">
      <Stack>
        <DropzoneDialog
          open={open}
          onClose={() => setOpen(false)}
          onChoose={chooseFile}
          accept="excel"
          maxFiles={1}
        />
        <FormControl>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <InputLabel style={{ color: Colors.MAIN_GREEN }}>
              デバン予定表Excelファイルを選択してください
            </InputLabel>
            <Button
              onClick={() => {
                setOpen(true);
              }}
              sx={{ ml: 1 }}
            >
              ファイル選択
            </Button>
          </Box>
          <Typography variant="body2" sx={{ mr: 1 }}>
            {fileName}
          </Typography>
        </FormControl>
        <Stack direction={{ xs: "column", md: "row" }}>
          <DatePickerCustom
            label="取込対象日"
            value={stateForm.target_date}
            onChange={onChangeDate("target_date")}
            helperText={formError.target_date}
            error={formError.target_date.length > 0}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "flex-start",
              justifyContent: "center",
              pt: { xs: 0, md: "36px" },
            }}
          >
            <Button onClick={handleImport} disabled={importDisabled}>
              取込
            </Button>
            <Button onClick={handleClear} disabled={disabled}>
              クリア
            </Button>
          </Box>
        </Stack>
        {rowCount > 0 && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {rowCount}件
          </div>
        )}
        <TableCustom rows={listDevan} headCells={headCells} idName={"id"} />
      </Stack>
      <Box sx={{ height: 30 }} />
      <Box
        sx={{
          position: "fixed",
          margin: 0,
          right: `calc(50% - 100px / 2)`,
          bottom: 20,
          textAlign: "center",
        }}
      >
        <Button
          color="secondary"
          onClick={handleComfirmSave}
          disabled={disabled}
        >
          保存
        </Button>
      </Box>
    </GenericTemplate>
  );
};

export default DevanImportScreen;
