import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Pagination,
  Stack,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { cloneDeep, debounce, isEqual } from "lodash";
import { useSelector } from "react-redux";
import { getProjectLocationIdStore } from "selector/projectSelector";
import AccordionSection from "components/molecules/AccordionSection";
import { getListUserByLocationId } from "@api/User";
import { getListCustomMaster, getMasterType } from "@api/masterType";
import { TemplateExcelDataInfo } from "services/models";
import formatDateToString from "@utils/DateFormat";
import { getUserInfo } from "@utils/index";
import {
  ICheckboxValue,
  IMasterValue,
  IRadioValue,
  StateFormType,
  getExcelTemplateCalcText,
  getHTMLTextValue,
  getImageArray,
  getInfoFromTemplate,
  getInputGroupKey,
  getPageKey,
  getStateFormByTemplate,
  getTemplateKey,
  insertReportLocal,
  makeNewHtml,
} from "@utils/template/excelTemplate";
import { STORAGE, storeData } from "@utils/Storage";
import { uploadFileToS3, uploadImageToS3 } from "@utils/template";
import { Validation } from "@validation";
import { getNewReportItem, getUserMasterItem } from "sagas/setting";
import {
  CUSTOM_MASTER_NAME_ATTRIBUTE,
  EXCEL_TEMPLATE_INPUT,
  EXCEL_TEMPLATE_MASTER_NAME,
} from "@shared-constants";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import TextInputField from "./InputField/TextInputField";
import MasterInputField from "./InputField/MasterInputField";
import DateInputField from "./InputField/DateInputField";
import ImageInputField from "./InputField/ImageInputField";
import ImagePageField from "./InputField/ImagePageField";
import AccountInputField from "./InputField/AccountInputField";
import SignatureInputField from "./InputField/SignatureInputField";
import DrawImageInputField from "./InputField/DrawImageInputField";
import NumberInputField from "./InputField/NumberInputField";
import RadioInputField from "./InputField/RadioInputField";
import TimeInputField from "./InputField/TimeInputField";
import CheckboxInputField from "./InputField/CheckboxInputField";
import InputGroupField from "./InputField/InputGroupField";
import AttachedFileField from "./InputField/AttachedFileField";

interface ITemplateExcelReport {
  infoToEdit?: any;
  htmlString: string;
  setHtmlString: Function;
  isManage?: boolean;
  excelDataInfo: Array<TemplateExcelDataInfo>;
  open: boolean;
  isSetting: boolean;
  activityBaseId?: string;
  step: number;
  isMultiple?: boolean;
  extraPage?: Array<Array<TemplateExcelDataInfo>>;
  onChangePage?: (page: number) => void;
  listHtmlString: Array<string>;
}

const imageDefault: Type.ImageInfoType = {
  type: "",
  uri: "",
  name: "",
  path_file: "",
};

const masterDefault: IMasterValue = {
  id: null,
  name: "",
  is_manual_input: false,
};

const radioDefault: IRadioValue = {
  index: 0,
  value: "",
};

const list_input_not_disp = [
  EXCEL_TEMPLATE_INPUT.REPORT_NO,
  EXCEL_TEMPLATE_INPUT.USER_SEAL,
  EXCEL_TEMPLATE_INPUT.APPROVED_SEAL,
  EXCEL_TEMPLATE_INPUT.APPROVED_DATE,
  EXCEL_TEMPLATE_INPUT.CALC,
  EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM,
];

const TemplateExcelReport = (
  {
    infoToEdit,
    htmlString,
    setHtmlString,
    isManage = false,
    excelDataInfo,
    open,
    isSetting,
    activityBaseId,
    step,
    isMultiple,
    extraPage = [],
    onChangePage = () => {},
    listHtmlString,
  }: ITemplateExcelReport,
  ref: React.Ref<unknown>,
) => {
  const [allStateForm, setAllStateForm] = useState<StateFormType>({});
  const [stateForm, setStateForm] = useState<StateFormType>({});
  const [originStateForm, setOriginStateForm] = useState<StateFormType>({});
  const [formError, setFormError] = useState<{ [key: string]: string }>({});
  const [completeGetData, setCompleteGetData] = useState<boolean>(false);
  const [listReportItem, setListReportItem] = useState<any>(null);
  const [listUser, setListUser] = useState<any>(null);
  const [listMasterType, setListMasterType] = useState<any>({});
  const [inputSetting, setInputSetting] =
    useState<Array<TemplateExcelDataInfo>>(excelDataInfo);
  const [templateNo, setTemplateNo] = useState<number>(0);
  const [listHtml, setListHtml] = useState<Array<string>>([...listHtmlString]);
  const user_info = getUserInfo();
  const location_id = useSelector(getProjectLocationIdStore);
  const signatureRef = useRef<any>({});

  useImperativeHandle(ref, () => ({
    getStateForm: () => {
      let cloneStateForm = cloneDeep(allStateForm);
      // プレビュー用の値を削除
      cloneStateForm.image_array?.map((item: any) => {
        delete item.base64;
      });
      const listInputSetting = [[...excelDataInfo]];
      extraPage.map((value) => listInputSetting.push(value));
      listInputSetting.forEach((inputSetting, template_no) => {
        let tmpStateForm = getStateFormByTemplate(template_no, cloneStateForm);
        const all_page = tmpStateForm["page"] ?? 1;
        for (let index = 0; index < all_page; index++) {
          inputSetting
            .filter((setting) => setting.input === EXCEL_TEMPLATE_INPUT.CALC)
            .forEach((setting) => {
              const coordinate = getPageKey(setting.coordinate, index + 1);
              tmpStateForm[coordinate] = getExcelTemplateCalcText(
                tmpStateForm,
                setting,
                inputSetting,
                index === 0 ? undefined : index + 1,
              );
            });
        }
        tmpStateForm = Object.keys(tmpStateForm).map((key) => {
          const newKey = getTemplateKey(key, template_no);
          return { [newKey]: tmpStateForm[key] };
        });
        cloneStateForm = Object.assign(cloneStateForm, ...tmpStateForm);
      });
      return cloneStateForm;
    },
    getOriginStateForm: () => originStateForm,
    disabledSubmit: () => Object.values(formError).some((item) => item != ""),
    onSubmit: onSubmit,
    getHtmlString: async () => {
      // 各テンプレートのHTMLをまとめる
      let newHtmlString = "";
      listHtml.map((html_string, index) => {
        if (index == 0) {
          newHtmlString += html_string;
        } else {
          newHtmlString += `
          <!-- extra-html -->
          ${html_string}
          `;
        }
      });
      return newHtmlString;
    },
  }));

  // データ取得 =====================================
  useEffect(() => {
    if (open) {
      getData();
      setTemplateNo(0);
      onChangePage(0);
    }
    return () => {
      setCompleteGetData(false);
    };
  }, [open]);

  useEffect(() => {
    setInputSetting(excelDataInfo);
  }, [excelDataInfo]);

  const getData = async () => {
    try {
      LoadingOverlayController.show();

      // マスタ入力がある場合、マスタデータを取得
      let master: any = [];
      let master_type: any = [];
      let hasMaster =
        inputSetting.some(
          (item) => item.input === EXCEL_TEMPLATE_INPUT.MASTER,
        ) ||
        extraPage
          ?.map((value) => {
            return value.some(
              (item) => item.input === EXCEL_TEMPLATE_INPUT.MASTER,
            );
          })
          .some((item) => item === true);
      if (hasMaster) {
        const res = await getMasterData();
        master = res.resReportItem;
        master_type = res.resMasterType;
      }

      // アカウント名がある場合、マスタデータを取得
      let hasUser =
        inputSetting.some(
          (item) => item.input === EXCEL_TEMPLATE_INPUT.USER_NAME,
        ) ||
        extraPage
          ?.map((value) => {
            return value.some(
              (item) => item.input === EXCEL_TEMPLATE_INPUT.USER_NAME,
            );
          })
          .some((item) => item === true);
      if (listUser === null && hasUser) {
        await getListUser();
      }

      // 初期データ作成
      let { new_state_form, new_form_error } = await getInitialStateForm(
        inputSetting,
        master,
        master_type,
      );
      let newAllStateForm: StateFormType = cloneDeep(new_state_form);
      for (let index = 0; index < extraPage.length; index++) {
        const data_info = extraPage[index];
        let {
          new_state_form: extra_state_form,
          new_form_error: extra_form_error,
        } = await getInitialStateForm(data_info, master, master_type);
        let newStateForm = Object.keys(extra_state_form).map((key) => {
          const newKey = getTemplateKey(key, index + 1);
          return { [newKey]: extra_state_form[key] };
        });
        newAllStateForm = Object.assign(newAllStateForm, ...newStateForm);
      }

      setAllStateForm(newAllStateForm);
      setStateForm(new_state_form);
      setOriginStateForm(newAllStateForm);
      setFormError(new_form_error);
      setCompleteGetData(true);
    } catch (err) {
      console.log(err);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const getMasterData = async () => {
    let resReportItem: any = cloneDeep(listReportItem);
    let resMasterType: any = cloneDeep(listMasterType);
    const param_location = activityBaseId ?? location_id;

    // マスタの更新チェック
    if (listReportItem === null) {
      resReportItem = await getNewReportItem(param_location);
      resMasterType = await getUserMasterItem(param_location);
    }
    // // ユーザー作成マスタ種類を取得
    // await getMasterType(param_location).then((res) => {
    //   if (res?.data) {
    //     const master_type_data: any[] = res.data;
    //     master_type_data.forEach((v) => {
    //       resMasterType[v.SK.replace(param_location + "|", "")] = v;
    //     });
    //   }
    // });
    // ユーザー作成マスタ取得
    let list_user_master = inputSetting.filter(
      (item) =>
        item.input === EXCEL_TEMPLATE_INPUT.MASTER &&
        !Object.keys(resReportItem).includes(item.master),
    );
    extraPage.forEach((ep) => {
      ep.forEach((item) => {
        if (
          item.input === EXCEL_TEMPLATE_INPUT.MASTER &&
          !Object.keys(resReportItem).includes(item.master)
        ) {
          if (!list_user_master.find((value) => value.master == item.master)) {
            list_user_master.push(item);
          }
        }
      });
    });
    for (let index = 0; index < list_user_master.length; index++) {
      const item = list_user_master[index];
      if (Object.keys(resReportItem).includes(item.master)) {
        // 取得済みの場合は何もしない
        continue;
      }
      if (
        resMasterType[item.master] &&
        resMasterType[item.master].custom_item &&
        resMasterType[item.master].custom_item.some((v: any) => v.barcode)
      ) {
        // バーコード検索の場合は何もしない
        continue;
      }
      // await getListCustomMaster(param_location + "|" + item.master).then(
      //   (res) => {
      //     if (res?.data) {
      //       resReportItem[item.master] = res.data;
      //     }
      //   },
      // );
      if (
        Object.keys(resMasterType).includes(param_location + "|" + item.master)
      ) {
        resReportItem[item.master] =
          resMasterType[param_location + "|" + item.master];
      }
    }

    setListReportItem(resReportItem);
    setListMasterType(resMasterType);
    return { resReportItem, resMasterType };
  };

  const getListUser = async () => {
    // アカウント一覧データ取得
    let resListUser = await getListUserByLocationId();
    const list_user = resListUser ?? [];
    setListUser(list_user);
    return list_user;
  };

  const getInitialStateForm = (
    input_setting: TemplateExcelDataInfo[],
    master: any,
    master_type: any,
  ) => {
    let new_state_form: { [key: string]: any } = {};
    let new_form_error: { [key: string]: string } = {};
    input_setting.forEach((item) => {
      let value: any = undefined;
      if (item.default) {
        value = item.default;
        if (
          item.input == EXCEL_TEMPLATE_INPUT.DATE &&
          item.default == "today"
        ) {
          value = formatDateToString(new Date(), "YMD_sl");
        }
        if (
          item.input == EXCEL_TEMPLATE_INPUT.TIME &&
          item.default == "today"
        ) {
          value = formatDateToString(new Date(), "HHmm");
        }
      }
      if (item.input == EXCEL_TEMPLATE_INPUT.MASTER) {
        const list = master[item.master] ? master[item.master] : [];
        const nameField =
          EXCEL_TEMPLATE_MASTER_NAME[item.master] ??
          CUSTOM_MASTER_NAME_ATTRIBUTE;
        const index_default = list.findIndex(
          (item: any) => item?.is_default === true,
        );
        const custom_item: any[] =
          master_type[item.master] && master_type[item.master]?.custom_item
            ? master_type[item.master].custom_item
            : [];
        if (index_default !== -1) {
          value = {
            id: list[index_default].SK,
            name: list[index_default][nameField],
            is_manual_input: list[index_default].is_manual_input,
          };
          // マスタ追加項目をマスタデータに追加
          if (custom_item) {
            custom_item.forEach((v, index) => {
              let key = v.barcode ? "barcode_item" : `item${index + 1}`;
              value[key] = list[index_default][key];
            });
          }
        } else {
          value = { ...masterDefault };
          // マスタ追加項目をマスタデータに追加
          if (custom_item) {
            custom_item.forEach((v, index) => {
              let key = v.barcode ? "barcode_item" : `item${index + 1}`;
              value[key] = "";
            });
          }
        }
      }
      if (item.input == EXCEL_TEMPLATE_INPUT.IMAGE) {
        value = { ...imageDefault };
      }
      if (item.input == EXCEL_TEMPLATE_INPUT.USER_NAME) {
        if (item.default == "login") {
          value = {
            id: user_info.SK,
            name: user_info.full_name,
            is_manual_input: false,
          };
        } else {
          value = { ...masterDefault };
        }
      }
      if (item.input == EXCEL_TEMPLATE_INPUT.USER_SEAL) {
        if (item.default == "login") {
          value = user_info.SK;
        } else {
          value = "";
        }
      }
      if (item.input == EXCEL_TEMPLATE_INPUT.DRAW_IMAGE) {
        value = { ...item.image };
      }
      if (item.input == EXCEL_TEMPLATE_INPUT.RADIO) {
        value = { ...radioDefault };
        if (item.radio && item.radio.length > 0) {
          value.value = item.radio[0].text;
        }
      }
      if (item.input == EXCEL_TEMPLATE_INPUT.CHECKBOX) {
        const checkboxDefaultValue =
          item.default == "true"
            ? item.checkbox?.checked_text
            : item.checkbox?.unchecked_text;
        const checkboxDefault: ICheckboxValue = {
          checked: item.default == "true",
          value: checkboxDefaultValue ?? "",
        };
        value = { ...checkboxDefault };
      }
      if (
        item.input == EXCEL_TEMPLATE_INPUT.CALC ||
        item.input == EXCEL_TEMPLATE_INPUT.INPUT_GROUP
      ) {
        value = "";
      }
      if (item.input == EXCEL_TEMPLATE_INPUT.ATTACHED_FILE) {
        value = [];
      }
      new_state_form[item.coordinate] = value;
      new_form_error[item.coordinate] = "";
    });

    new_state_form["image_array"] = [];

    input_setting.forEach((v) => {
      if (v.master_coordinate && v.master_attr) {
        if (new_state_form[v.master_coordinate]) {
          new_state_form = {
            ...new_state_form,
            [v.coordinate]: new_state_form[v.master_coordinate][v.master_attr],
          };
        }
      }
    });

    return { new_state_form, new_form_error };
  };

  useEffect(() => {
    if (!open || !completeGetData) return;
    if (isSetting) {
      const newStateForm = getStateFormByTemplate(0, infoToEdit);
      setAllStateForm((prev) => ({
        ...prev,
        ...cloneDeep(infoToEdit),
      }));
      setStateForm((prev) => ({
        ...prev,
        ...cloneDeep(newStateForm),
      }));
      setOriginStateForm((prev) => ({
        ...prev,
        ...cloneDeep(infoToEdit),
      }));
    } else if (isUpdate) {
      const newStateForm = getStateFormByTemplate(0, infoToEdit);
      setAllStateForm(cloneDeep(infoToEdit));
      setStateForm(cloneDeep(newStateForm));
      setOriginStateForm(cloneDeep(infoToEdit));
    } else {
      setAllStateForm((prev) => ({
        ...prev,
        risky_id: infoToEdit?.risky_id ?? "",
      }));
      setStateForm((prev) => ({
        ...prev,
        risky_id: infoToEdit?.risky_id ?? "",
      }));
      setOriginStateForm((prev) => ({
        ...prev,
        risky_id: infoToEdit?.risky_id ?? "",
      }));
    }
  }, [open, completeGetData, listReportItem]);

  const isUpdate = useMemo(() => {
    if (infoToEdit) {
      // infoToEditに入力値の登録があるかチェック
      // メイン
      const pageUpdate =
        inputSetting.length > 0 &&
        Object.keys(infoToEdit).some(
          (key) =>
            inputSetting.findIndex((item) => item.coordinate == key) >= 0,
        );
      // 追加テンプレート
      const extraPageUpdate = extraPage.some((value, index) => {
        let exist = false;
        const tmpStateForm = getStateFormByTemplate(index + 1, infoToEdit);
        const all_page = tmpStateForm["page"] ?? 1;
        for (let index = 0; index < all_page; index++) {
          const coordinate_exists = Object.keys(tmpStateForm).some(
            (key) =>
              value.findIndex(
                (item) => getPageKey(item.coordinate, index + 1) == key,
              ) >= 0,
          );
          if (coordinate_exists) {
            exist = true;
            break;
          }
        }
        return exist;
      });
      return pageUpdate || extraPageUpdate;
    } else {
      return false;
    }
  }, [infoToEdit, inputSetting, extraPage]);

  // テンプレートHTML =====================================
  const getHtmlString = useCallback(
    async (
      state_form: any,
      input_setting: Array<TemplateExcelDataInfo>,
      html_string: string,
      template_no: number,
    ) => {
      const res = getInfoFromTemplate(html_string);
      const image_array = await getImageArray(state_form.image_array);
      let newHtml: string = await makeNewHtml(
        html_string,
        state_form,
        input_setting,
      );

      newHtml = await insertReportLocal(
        newHtml,
        state_form,
        input_setting,
        res.imageContentExample,
        res.pageImageExample,
        res.mainPageExample,
        image_array,
      );
      setHtmlString(newHtml);

      setListHtml((pre) => {
        pre[template_no] = newHtml;
        return pre;
      });

      if (!isEqual(image_array, state_form.image_array)) {
        setStateForm({ ...state_form, image_array: image_array });
      }
    },
    [],
  );

  const changeForm = useCallback(debounce(getHtmlString, 300), [getHtmlString]);

  useEffect(() => {
    changeForm(stateForm, inputSetting, htmlString, templateNo);
  }, [stateForm, inputSetting, htmlString, templateNo]);

  // 入力制御 =====================================
  const onChangeText = (field: string) => (newText: any) => {
    setStateForm((prev) => ({ ...prev, [field]: newText }));
  };

  const onChangeDate = (field: string, name: string) => (newText: string) => {
    const mess = Validation.validateDate(newText ?? "", name, false);
    setFormError({ ...formError, [field]: mess });
    setStateForm({ ...stateForm, [field]: newText });
  };

  const onChangeTime =
    (field: string, name: string) =>
    (newText: string, keyboardInputValue?: string) => {
      const mess = Validation.validate({
        type: "time",
        name: name,
        value: keyboardInputValue,
      });
      setFormError({ ...formError, [field]: mess });
      setStateForm({ ...stateForm, [field]: newText });
    };

  useEffect(() => {
    var newStateForm = cloneDeep(stateForm);
    // マスタが変更された時、追加項目を表示するセルの値を変更する ====================
    const list_custom_input = inputSetting.filter(
      (v) => v.input >= EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM,
    );
    list_custom_input.forEach((v) => {
      const all_page = stateForm.page ?? 1;
      for (let page = 0; page < all_page; page++) {
        // ページ毎に値を更新
        if (v.master_coordinate && v.master_attr) {
          const coordinate = getPageKey(v.coordinate, page + 1);
          const master_coordinate = getPageKey(v.master_coordinate, page + 1);
          if (stateForm[master_coordinate]) {
            newStateForm = {
              ...newStateForm,
              [coordinate]: stateForm[master_coordinate][v.master_attr],
            };
          }
        }
      }
    });
    // 入力グループの入力項目が変更されたとき、入力グループのセルの値を変更する =========
    const list_input_group = inputSetting.filter(
      (v) => v.input == EXCEL_TEMPLATE_INPUT.INPUT_GROUP,
    );
    list_input_group.forEach((v) => {
      const all_page = stateForm.page ?? 1;
      for (let page = 0; page < all_page; page++) {
        // ページ毎に値を更新
        const coordinate = getPageKey(v.coordinate, page + 1);
        if (Object.keys(stateForm).indexOf(coordinate) !== -1) {
          let newValue: string[] = [];
          const listGroupIndex: number[] =
            cloneDeep(stateForm[coordinate + "_group"]) ?? [];
          listGroupIndex.forEach((count) => {
            let tmpValue: string[] = [];
            // 各項目の入力値を取得
            v.group_info?.forEach((e) => {
              let setting = cloneDeep(e);
              setting.coordinate = getInputGroupKey(
                coordinate,
                setting.coordinate,
                count,
              );
              // マスタ追加項目を表示するセルの値を変更する
              if (
                setting.input >= EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM &&
                setting.master_coordinate &&
                setting.master_attr
              ) {
                const master_coordinate = getInputGroupKey(
                  coordinate,
                  setting.master_coordinate,
                  count,
                );
                if (stateForm[master_coordinate]) {
                  newStateForm = {
                    ...newStateForm,
                    [setting.coordinate]:
                      stateForm[master_coordinate][setting.master_attr],
                  };
                }
              }
              // 表示テキストを取得
              tmpValue.push(getHTMLTextValue(newStateForm, setting));
            });
            if (tmpValue.some((v) => v.length > 0)) {
              newValue = newValue.concat(tmpValue);
            }
          });
          newStateForm = {
            ...newStateForm,
            [coordinate]: newValue.join(","),
          };
        }
      }
    });
    // AllStateFormを変更する ===========================
    let updateAllStateForm = Object.keys(newStateForm).map((key) => {
      const newKey = getTemplateKey(key, templateNo);
      return { [newKey]: newStateForm[key] };
    });
    setAllStateForm((prev) => {
      Object.keys(prev)
        .filter((key) => {
          if (templateNo == 0) {
            return !key.includes("TEMPLATE#");
          } else {
            return key.includes(getTemplateKey("", templateNo));
          }
        })
        .forEach((key) => {
          delete prev[key];
        });
      return Object.assign(prev, ...updateAllStateForm);
    });

    if (!isEqual(newStateForm, stateForm)) {
      setStateForm(newStateForm);
    }
  }, [stateForm, inputSetting]);

  const listSignature = useMemo(() => {
    let response: string[] = [];
    let list_signature_setting = inputSetting
      .filter((item) => item.input === EXCEL_TEMPLATE_INPUT.SIGNATURE)
      .map((item) => item.coordinate);
    response = [...list_signature_setting];
    // 追加ページのサイン入力を追加
    if (stateForm.page) {
      for (let page = 2; page < stateForm.page + 1; page++) {
        list_signature_setting.forEach((value) => {
          response.push(getPageKey(value, page));
        });
      }
    }
    return response;
  }, [inputSetting, stateForm.page]);

  const getNextSignatureRef = (coordinate: string) => {
    const list_index = listSignature.indexOf(coordinate);
    if (list_index < listSignature.length - 1 && signatureRef.current) {
      return signatureRef.current[listSignature[list_index + 1]];
    }
    return undefined;
  };

  const getPrevSignatureRef = (coordinate: string) => {
    const list_index = listSignature.indexOf(coordinate);
    if (list_index > 0 && signatureRef.current) {
      return signatureRef.current[listSignature[list_index - 1]];
    }
    return undefined;
  };

  const onValidateNumber = (setting: TemplateExcelDataInfo) => {
    const field = setting.coordinate;
    const value = stateForm[field];
    const mess = Validation.validate({
      type: "number",
      name: setting.name,
      value: value,
    });
    setFormError({ ...formError, [field]: mess });
  };

  // 保存 =====================================
  const onSubmit = async () => {
    try {
      LoadingOverlayController.show();
      let newStateForm = { ...allStateForm };
      if (!isSetting) {
        // 画像アップロード
        let newImageArray: Array<Type.ImageInfoType> =
          stateForm.image_array ?? [];
        if (stateForm.image_array) {
          newImageArray = await uploadImageToS3(stateForm.image_array);
        }
        newStateForm = {
          ...newStateForm,
          image_array: newImageArray,
        };
        // 添付画像ページアップロード
        let custom_image: Array<Type.ImageInfoType> = [];
        const image_input = [
          excelDataInfo.filter(
            (item) =>
              item.input === EXCEL_TEMPLATE_INPUT.IMAGE ||
              item.input === EXCEL_TEMPLATE_INPUT.DRAW_IMAGE,
          ),
        ];
        extraPage.forEach((value) => {
          image_input.push(
            value.filter(
              (item) =>
                item.input === EXCEL_TEMPLATE_INPUT.IMAGE ||
                item.input === EXCEL_TEMPLATE_INPUT.DRAW_IMAGE,
            ),
          );
        });
        for (let index = 0; index < image_input.length; index++) {
          const settings = image_input[index];
          const tmpStateForm = getStateFormByTemplate(index, allStateForm);
          const all_page = tmpStateForm.page ?? 1;
          for (let n = 0; n < settings.length; n++) {
            const item = settings[n];
            for (let page = 0; page < all_page; page++) {
              const coordinate = getTemplateKey(
                getPageKey(item.coordinate, page + 1),
                index,
              );
              const new_input_image = await uploadImageToS3(
                [allStateForm[coordinate]],
                Date.now(),
              );
              newStateForm = {
                ...newStateForm,
                [coordinate]: new_input_image[0],
              };
              if (new_input_image[0] && new_input_image[0]["path_file"] != "") {
                custom_image.push(new_input_image[0]);
              }
            }
          }
        }
        newStateForm = { ...newStateForm, custom_image: custom_image };
        // サインアップロード
        const signature_input = [
          excelDataInfo.filter(
            (item) => item.input === EXCEL_TEMPLATE_INPUT.SIGNATURE,
          ),
        ];
        extraPage.forEach((value) => {
          signature_input.push(
            value.filter(
              (item) => item.input === EXCEL_TEMPLATE_INPUT.SIGNATURE,
            ),
          );
        });
        for (let index = 0; index < signature_input.length; index++) {
          const settings = signature_input[index];
          const tmpStateForm = getStateFormByTemplate(index, allStateForm);
          const all_page = tmpStateForm.page ?? 1;
          for (let n = 0; n < settings.length; n++) {
            const item = settings[n];
            for (let page = 0; page < all_page; page++) {
              const coordinate = getTemplateKey(
                getPageKey(item.coordinate, page + 1),
                index,
              );
              const new_signature_image = await uploadImageToS3(
                [allStateForm[coordinate]],
                Date.now(),
              );
              newStateForm = {
                ...newStateForm,
                [coordinate]: new_signature_image[0],
              };
            }
          }
        }
        // 添付ファイルアップロード
        let attached_file_array: Array<Type.ImageInfoType> = [];
        const attached_file = [
          excelDataInfo.filter(
            (item) => item.input === EXCEL_TEMPLATE_INPUT.ATTACHED_FILE,
          ),
        ];
        extraPage.forEach((value) => {
          attached_file.push(
            value.filter(
              (item) => item.input === EXCEL_TEMPLATE_INPUT.ATTACHED_FILE,
            ),
          );
        });

        for (let index = 0; index < attached_file.length; index++) {
          const settings = attached_file[index];
          const tmpStateForm = getStateFormByTemplate(index, allStateForm);
          const all_page = tmpStateForm.page ?? 1;
          for (let n = 0; n < settings.length; n++) {
            const item = settings[n];
            for (let page = 0; page < all_page; page++) {
              const coordinate = getTemplateKey(
                getPageKey(item.coordinate, page + 1),
                index,
              );
              const new_attached_file = await uploadFileToS3(
                allStateForm[coordinate],
              );
              newStateForm = {
                ...newStateForm,
                [coordinate]: new_attached_file,
              };
              attached_file_array =
                attached_file_array.concat(new_attached_file);
            }
          }
        }
        newStateForm = {
          ...newStateForm,
          attached_file_array: attached_file_array,
        };
      }
      await setAllStateForm(newStateForm);

      for (let index = 0; index < listHtmlString.length; index++) {
        let input_setting: TemplateExcelDataInfo[] = [];
        if (index == 0) {
          input_setting = excelDataInfo;
        } else if (extraPage && extraPage.length > index - 1) {
          input_setting = extraPage[index - 1];
        }
        await getHtmlString(
          getStateFormByTemplate(index, newStateForm),
          input_setting,
          listHtmlString[index],
          index,
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // 画面表示 =====================================
  const _render = (item: TemplateExcelDataInfo, index: number) => {
    // 表示設定をチェック
    const display_step = item.display_step ?? 0;
    if (display_step > step) return null;

    let setting = cloneDeep(item);

    // 必須ステップをチェック
    if (item.required && item.required_step) {
      setting.required = item.required_step <= step;
    }

    // 入力項目
    if (item.input == EXCEL_TEMPLATE_INPUT.TEXT) {
      return (
        <TextInputField
          setting={setting}
          value={stateForm[item.coordinate]}
          onChange={onChangeText(item.coordinate)}
          key={`${templateNo}-${index}`}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.MASTER) {
      return (
        <MasterInputField
          setting={setting}
          value={stateForm[item.coordinate]}
          onChange={onChangeText(item.coordinate)}
          reportItem={listReportItem}
          listMasterType={listMasterType}
          activityBaseId={activityBaseId ?? location_id}
          key={`${templateNo}-${index}`}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.DATE) {
      return (
        <DateInputField
          setting={setting}
          value={stateForm[item.coordinate]}
          onChange={onChangeDate(item.coordinate, item.name)}
          error={formError[item.coordinate]?.length > 0}
          helperText={formError[item.coordinate]}
          key={`${templateNo}-${index}`}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.IMAGE) {
      return (
        <ImageInputField
          setting={setting}
          value={stateForm[item.coordinate]}
          onChange={onChangeText(item.coordinate)}
          key={`${templateNo}-${index}`}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.IMAGE_PAGE) {
      return (
        <ImagePageField
          title={item.titleImage}
          imagesList={stateForm?.image_array || []}
          addButtonText={"写真を追加"}
          onChange={onChangeText("image_array")}
          key={`${templateNo}-${index}`}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.USER_NAME) {
      return (
        <AccountInputField
          setting={setting}
          value={stateForm[item.coordinate]}
          onChange={(value) => {
            onChangeText(item.coordinate)(value);
            // 当該アカウントの電子印があれば電子印も変更
            const seal = inputSetting.filter(
              (v) =>
                v.input === EXCEL_TEMPLATE_INPUT.USER_SEAL &&
                v.default === item.coordinate,
            );
            if (seal) {
              seal.forEach((v) => {
                onChangeText(v.coordinate)(
                  value.is_manual_input ? value.name : value.id,
                );
              });
            }
          }}
          userList={listUser}
          key={`${templateNo}-${index}`}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.SIGNATURE) {
      return (
        <SignatureInputField
          setRef={(v) => {
            if (v) {
              signatureRef.current = {
                ...signatureRef.current,
                [item.coordinate]: v,
              };
            }
          }}
          setting={setting}
          value={stateForm[item.coordinate]}
          onChange={onChangeText(item.coordinate)}
          disabledNext={
            listSignature.indexOf(item.coordinate) === listSignature.length - 1
          }
          nextRef={getNextSignatureRef(item.coordinate)}
          disabledPrev={listSignature.indexOf(item.coordinate) === 0}
          prevRef={getPrevSignatureRef(item.coordinate)}
          key={`${templateNo}-${index}`}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.DRAW_IMAGE) {
      return (
        <DrawImageInputField
          setting={setting}
          value={stateForm[item.coordinate]}
          onChange={onChangeText(item.coordinate)}
          key={`${templateNo}-${index}`}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.NUMBER) {
      return (
        <NumberInputField
          setting={setting}
          value={stateForm[item.coordinate]}
          onChange={onChangeText(item.coordinate)}
          onBlur={() => {
            onValidateNumber(item);
          }}
          error={formError[item.coordinate]?.length > 0}
          helperText={formError[item.coordinate]}
          key={`${templateNo}-${index}`}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.RADIO) {
      return (
        <RadioInputField
          setting={setting}
          value={stateForm[item.coordinate]}
          onChange={onChangeText(item.coordinate)}
          key={`${templateNo}-${index}`}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.TIME) {
      return (
        <TimeInputField
          setting={setting}
          value={stateForm[item.coordinate]}
          onChange={onChangeTime(item.coordinate, item.name)}
          error={formError[item.coordinate]?.length > 0}
          helperText={formError[item.coordinate]}
          key={`${templateNo}-${index}`}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.BARCODE) {
      return (
        <TextInputField
          setting={setting}
          value={stateForm[item.coordinate]}
          onChange={onChangeText(item.coordinate)}
          key={`${templateNo}-${index}`}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.CHECKBOX) {
      return (
        <CheckboxInputField
          setting={setting}
          value={stateForm[item.coordinate]}
          onChange={onChangeText(item.coordinate)}
          key={`${templateNo}-${index}`}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.INPUT_GROUP) {
      return (
        <InputGroupField
          setting={setting}
          listIndex={stateForm[item.coordinate + "_group"] ?? []}
          renderItem={_render}
          onAdd={handleAddInputGroup}
          onRemove={handleRemoveInputGroup}
          key={`${templateNo}-${index}`}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.ATTACHED_FILE) {
      return (
        <AttachedFileField
          setting={setting}
          value={stateForm[item.coordinate]}
          onChange={onChangeText(item.coordinate)}
          key={`${templateNo}-${index}`}
        />
      );
    }
  };

  const handleAddInputGroup = (
    setting: TemplateExcelDataInfo,
    index: number,
  ) => {
    if (!setting.group_info) return;
    let index_list: number[] = [];
    const index_list_key = setting.coordinate + "_group";
    if (index > 1 && stateForm[index_list_key]) {
      index_list = cloneDeep(stateForm[index_list_key]);
    }
    index_list.push(index);
    // 初期値を取得
    const group_info = cloneDeep(setting.group_info);
    group_info.forEach((item) => {
      item.coordinate = getInputGroupKey(
        setting.coordinate,
        item.coordinate,
        index,
      );
    });
    let { new_state_form, new_form_error } = getInitialStateForm(
      group_info,
      listReportItem,
      listMasterType,
    );
    // stateFormに追加
    const cloneStateForm = {
      ...cloneDeep(stateForm),
      ...new_state_form,
      [index_list_key]: index_list,
    };
    const cloneFormError = { ...cloneDeep(formError), ...new_form_error };
    setStateForm(cloneStateForm);
    setFormError(cloneFormError);
  };

  const handleRemoveInputGroup = (
    setting: TemplateExcelDataInfo,
    index: number,
  ) => {
    let cloneStateForm = cloneDeep(stateForm);
    setting.group_info?.map((item) => {
      delete cloneStateForm[
        getInputGroupKey(setting.coordinate, item.coordinate, index)
      ];
    });
    const index_list_key = setting.coordinate + "_group";
    let newIndexList: number[] =
      cloneDeep(cloneStateForm[index_list_key]) ?? [];
    const removeIndex = newIndexList.findIndex((v) => v == index);
    if (removeIndex >= 0) {
      newIndexList.splice(removeIndex, 1);
      cloneStateForm[index_list_key] = newIndexList;
    }
    setStateForm(cloneStateForm);
  };

  const handleAddPage = () => {
    const new_page = (stateForm.page ?? 1) + 1;
    let cloneInputSetting = cloneDeep(inputSetting);
    // ページ番号をcoordinateに追加
    cloneInputSetting = cloneInputSetting.map((v) => {
      v.coordinate = getPageKey(v.coordinate, new_page);
      return v;
    });
    let { new_state_form, new_form_error } = getInitialStateForm(
      cloneInputSetting,
      listReportItem,
      listMasterType,
    );
    setStateForm((prev) => ({
      ...prev,
      ...new_state_form,
      image_array: prev.image_array,
      page: new_page,
    }));
    setFormError((prev) => ({ ...prev, ...new_form_error }));
  };

  const handleRemovePage = (removePage: number) => {
    let newStateForm: StateFormType = {};
    let newFormError: { [key: string]: string } = {};
    const all_page = (stateForm.page ?? 0) + 1;

    for (let page = 0; page < all_page; page++) {
      // 各ページのインデックスキーを取得
      let listKey = [];
      if (page == 0) {
        listKey = Object.keys(stateForm).filter(
          (key) => !key.includes("PAGE#"),
        );
      } else {
        listKey = Object.keys(stateForm).filter((key) =>
          key.includes(`PAGE#${page + 1}|`),
        );
      }
      // newStateForm、newFormErrorを作成
      listKey.forEach((key) => {
        if (page < removePage) {
          // 1ページ目から削除ページより前のページの情報
          newStateForm[key] = stateForm[key];
          newFormError[key] = formError[key];
        } else if (page > removePage) {
          // 削除ページより後のページの情報
          const new_key = getPageKey(key.split(`PAGE#${page + 1}|`)[1], page);
          newStateForm[new_key] = stateForm[key];
          newFormError[new_key] = formError[key];
        }
      });
    }
    newStateForm["page"] = stateForm.page ? stateForm.page - 1 : 0;
    setStateForm(newStateForm);
    setFormError(newFormError);
  };

  const handleChangeTemplate = (template_no: number) => {
    if (template_no == 0) {
      setInputSetting(excelDataInfo);
    } else if (extraPage && extraPage.length > template_no - 1) {
      setInputSetting(extraPage[template_no - 1]);
    }
    const new_state_form = getStateFormByTemplate(template_no, allStateForm);
    const new_form_error: { [key: string]: string } = {};
    Object.keys(new_state_form).forEach((key) => {
      new_form_error[key] = "";
    });
    setStateForm(new_state_form);
    setFormError(new_form_error);

    setTemplateNo(template_no);
    onChangePage(template_no);
  };

  return (
    <Box>
      <Stack>
        <Pagination
          count={extraPage ? extraPage.length + 1 : 1}
          defaultPage={1}
          page={templateNo + 1}
          siblingCount={0}
          onChange={(e, p) => {
            handleChangeTemplate(p - 1);
          }}
          disabled={extraPage === undefined}
        />
        {inputSetting.length > 0 && stateForm ? (
          <Card>
            <CardContent>
              <Stack>
                {[...Array(stateForm.page ?? 1)].map((_, p) => {
                  let cloneInputSetting = cloneDeep(inputSetting);
                  if (p > 0) {
                    // ページ番号をcoordinateに追加
                    cloneInputSetting = cloneInputSetting.map((v) => {
                      v.coordinate = getPageKey(v.coordinate, p + 1);
                      return v;
                    });
                  }
                  return (
                    <React.Fragment key={p}>
                      {p > 0 && (
                        <Divider>
                          {`${p + 1}ページ`}
                          <IconButton
                            sx={{ ml: 1 }}
                            onClick={() => handleRemovePage(p)}
                          >
                            <Cancel />
                          </IconButton>
                        </Divider>
                      )}
                      {cloneInputSetting.map((item, index) => {
                        if (item.categoryTitle) {
                          if (
                            index == 0 ||
                            item.categoryTitle !=
                              cloneInputSetting[index - 1].categoryTitle
                          ) {
                            const input_setting = cloneInputSetting.filter(
                              (f) =>
                                f.categoryTitle == item.categoryTitle &&
                                !list_input_not_disp.includes(f.input as any),
                            );
                            if (input_setting.length > 0) {
                              return (
                                <AccordionSection
                                  title={item.categoryTitle}
                                  key={index}
                                >
                                  <Stack>
                                    {input_setting
                                      .filter(
                                        (item1) =>
                                          !isMultiple ||
                                          item1.input !==
                                            EXCEL_TEMPLATE_INPUT.IMAGE_PAGE,
                                      )
                                      .map((item1, index1) =>
                                        _render(item1, index1),
                                      )}
                                  </Stack>
                                </AccordionSection>
                              );
                            }
                          }
                          return null;
                        } else {
                          if (
                            !isMultiple ||
                            item.input !== EXCEL_TEMPLATE_INPUT.IMAGE_PAGE
                          )
                            return _render(item, index);
                        }
                      })}
                    </React.Fragment>
                  );
                })}
                {isMultiple && (
                  <Button variant="outlined" onClick={handleAddPage}>
                    ページ追加
                  </Button>
                )}
                {isMultiple &&
                  inputSetting.map((item, index) => {
                    // 添付画像ページ
                    if (item.input === EXCEL_TEMPLATE_INPUT.IMAGE_PAGE) {
                      return _render(item, index);
                    } else {
                      return null;
                    }
                  })}
              </Stack>
            </CardContent>
          </Card>
        ) : (
          <></>
        )}
      </Stack>
    </Box>
  );
};

export default forwardRef(TemplateExcelReport);
