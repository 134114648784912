import React, {
  forwardRef,
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
} from "react";
import { getListUserByLocationId } from "@api/User";
import {
  Autocomplete,
  Box,
  Button,
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Colors } from "@template/style";
import formatDateToString from "@utils/DateFormat";
import {
  StateFormType,
  getInfoFromTemplate,
  getImageArray,
  makeNewHtml,
  insertReportLocal,
} from "@utils/template/receivingProblemTag";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import SelectLabel from "components/atoms/SelectLabel";
import TimePickerCustom from "components/atoms/TimePickerCustom";
import { User } from "services/models";
import { cloneDeep, debounce, isEqual } from "lodash";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { Validation } from "@validation";
import { uploadImageToS3 } from "@utils/template";
import AccordionSection from "components/molecules/AccordionSection";
import { getUserInfo } from "@utils/index";
import { getM6, getM19, getM20, getM23, getModelNameMaster } from "@api/master";
import CustomImagePicker from "./CustomImagePiker";
import { Cancel } from "@mui/icons-material";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";

const originData: StateFormType = {
  risky_id: "",
  date: formatDateToString(new Date(), "YMD"),
  time: "",
  model_name: "",
  quantity: 0,
  tl_serial_no: [""],
  reported_by: "",
  defect: null,
  damage_location: null,
  damage_location_photo: [], // 起票者
  ctc_qa_judgement: null,
  ctc_qa_inspector_by: "",
  ctc_checked_date: "",
  checked_quantity: 0,
  qa_serial_no: [""],
  ctc_ma_repair_by: "",
  ctc_ma_inspector_by: "",
  ctc_inspection_date: "",
  repaired_quantity: 0,
  repaired_date: "",
  repaired_time: "",
  qa_ma_ctc_part_photo: [],
  repaired_ac_receive_by: null, // 第1承認者以降
  returning_quantity: 0,
  returning_date: "",
  returning_time: "", // 第2承認者以降
  qrcode: "",
};

const REPORTER_STEP = 0;
const APPROVER_STEP_1 = 1;
const APPROVER_STEP_2 = 2;

const yyyyMMddString = "2024-04-24 ";

const maxNumberDamageLocationPhoto: number = 2;
const maxNumberQAMACTCPartPhoto: number = 1;
const maxNumberSerialNo: number = 20;

const quantityList = Array.from({ length: 20 }, (_, index) =>
  String(index + 1),
);


interface IDateTime {
  time: Date | null;
  repaired_time: Date | null;
  returning_time: Date | null;
}
interface IFormError {
  quantity: string;
  tl_serial_no: string;
  qa_serial_no: string;
  checked_quantity: string;
  repaired_quantity: string;
  returning_quantity: string;
}

interface IReceivingProblemTag {
  infoToEdit?: any;
  htmlString: string;
  setHtmlString: Function;
  step: number;
  open: boolean;
  isManage: boolean;
}

const ReceivingProblemTag = (
  { infoToEdit, htmlString, setHtmlString, open, step }: IReceivingProblemTag,
  ref: React.Ref<unknown>,
) => {
  //AutoCompleteのinput
  const [inputReportedByValue, setInputReportedByValue] = useState<string>("");
  const [stateForm, setStateForm] = useState<StateFormType>(
    cloneDeep(originData),
  );
  const [originStateForm, setOriginStateForm] = useState<StateFormType>(
    cloneDeep(originData),
  );
  const [completeGetData, setCompleteGetData] = useState<boolean>(false);
    // AutoCompleteのinput
  const [quantityParameter, setQuantityParameter] = useState<string>("");
  const [serialNoParameter, setSerialNumberParameter] = useState<string[]>([
    "",
  ]);
  const [maxSerialNo, _] = useState<number>(maxNumberSerialNo);
  const [dateTime, setDateTime] = useState<IDateTime>({
    time: null,
    repaired_time: null,
    returning_time: null,
  });
  const [janCode, setJanCode] = useState<string>("");
  const [serialNoErrorIndex, setSerialNoErrorIndex] = useState<number[]>([]);
  const [listReportItem, setListReportItem] = useState<{
    listDefect: Array<any>;
    listDamageLocation: Array<any>;
    listRepairedACReceiveBy: Array<any>;
    listJudgement: Array<any>;
    listReportedBy: Array<any>;
  }>({
    listDefect: [],
    listDamageLocation: [],
    listRepairedACReceiveBy: [],
    listJudgement: [],
    listReportedBy: [],
  });
  const [formError, setFormError] = useState<IFormError>({
    quantity: "",
    tl_serial_no: "",
    qa_serial_no: "",
    checked_quantity: "",
    repaired_quantity: "",
    returning_quantity: "",
  });

  const user: User = getUserInfo();

  useImperativeHandle(ref, () => ({
    getStateForm: () => {
      // プレビュー用の値を削除
      let cloneStateForm = cloneDeep(stateForm);
      cloneStateForm.damage_location_photo.map((item: any) => {
        delete item.base64;
      });
      cloneStateForm.qa_ma_ctc_part_photo.map((item: any) => {
        delete item.base64;
      });
      return cloneStateForm;
    },
    getOriginStateForm: () => originStateForm,
    onSubmit: onSubmit,
    disabledPreview: () => Object.values(formError).some((v) => v != ""),
    disabledSubmit: () => Object.values(formError).some((v) => v != ""),
  }));

  // マスタデータ取得 =====================================
  const addWhiteSpace = (data: Array<any>) => {
    if (data.length == 0) {
      return data;
    }
    data.splice(0, 0, undefined);
    return data;
  };

  const getData = async () => {
    try {
      LoadingOverlayController.show();

      let listReportItemClone = cloneDeep(listReportItem);

      // Defect
      const resDefect = await getM19();
      if (resDefect?.data) {
        listReportItemClone.listDefect = addWhiteSpace(resDefect.data);
      }

      // DamageLocation
      const resDamageLocation = await getM6();
      if (resDamageLocation?.data) {
        listReportItemClone.listDamageLocation = addWhiteSpace(
          resDamageLocation.data,
        );
      }
      //Reported by
      const resListUser = await getListUserByLocationId();

      if (resListUser?.length) {
        listReportItemClone.listReportedBy = resListUser;
      }

      if (REPORTER_STEP < step) {
        // 第1承認者以降

        // Judgement
        const resJudgement = await getM23();
        if (resJudgement?.data) {
          listReportItemClone.listJudgement = addWhiteSpace(resJudgement.data);
        }
      }

      if (APPROVER_STEP_1 < step) {
        // 第2承認者以降
        // RepairedACReceiveBy
        const resRepairedACReceiveBy = await getM20();
        if (resRepairedACReceiveBy?.data) {
          listReportItemClone.listRepairedACReceiveBy = addWhiteSpace(
            resRepairedACReceiveBy.data,
          );
        }
      }

      setListReportItem(listReportItemClone);
      setCompleteGetData(true);
    } catch (err) {
      console.log(err);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    getData();

    return () => {
      setListReportItem({
        listDefect: [],
        listDamageLocation: [],
        listRepairedACReceiveBy: [],
        listJudgement: [],
        listReportedBy: [],
      });
      setCompleteGetData(false);
    };
  }, []);

  useEffect(() => {
    if (open && completeGetData) {
      if (typeof infoToEdit?.model_name === "string") {
        // 別画面からの遷移
        let tmpInfoToEdit = cloneDeep(infoToEdit);
        // time,repaired_timeはHH:mmで渡ってくるため、Date型にキャストする。
        const dStringTime = yyyyMMddString + infoToEdit.time;
        const dStringRepairedTime = yyyyMMddString + infoToEdit.repaired_time;
        setDateTime({
          time: new Date(dStringTime),
          repaired_time: null,
          returning_time: null
        });
        // formatされた日付を変数でセット
        const formattedDate = formatDateToString(new Date(), "YMD");
        setSerialNumberParameter(infoToEdit.tl_serial_no);
        //AutoCompleteのinputにデフォルト値を設定する
        setInputReportedByValue(
          typeof infoToEdit.reported_by === "object" &&
            "full_name" in infoToEdit.reported_by
            ? infoToEdit.reported_by.full_name
            : infoToEdit.reported_by,
        );
        // 承認ステップ1
        if (REPORTER_STEP < step) {
          const dateForRepairedTime = new Date();
          const timeString = formatDateToString(dateForRepairedTime, "HHmm");
          setDateTime({
            time: new Date(dStringTime),
            repaired_time: new Date(),
            returning_time: null
          });
          tmpInfoToEdit = {
            ...tmpInfoToEdit,
            checked_quantity: infoToEdit.checked_quantity === 0 ? infoToEdit.quantity : infoToEdit.checked_quantity,
            repaired_quantity: infoToEdit.repaired_quantity === 0 ? infoToEdit.quantity : infoToEdit.repaired_quantity,
            qa_serial_no: infoToEdit.tl_serial_no,
            ctc_checked_date:
              infoToEdit.ctc_checked_date === ""
                ? formattedDate
                : infoToEdit.ctc_checked_date,
            ctc_inspection_date:
              infoToEdit.ctc_inspection_date === ""
                ? formattedDate
                : infoToEdit.ctc_inspection_date,
            repaired_time:
                infoToEdit.repaired_time === ""
                ? timeString
                : infoToEdit.repaired_time,
            repaired_date:
              infoToEdit.repaired_date === ""
                ? formattedDate
                : infoToEdit.repaired_date,
            customer_judgement: setDefaultValue(
              "defect",
              listReportItem.listDefect)
          };
        }
        // 承認ステップ2以降
        if (APPROVER_STEP_1 < step) {
          const dateForReturningTime = new Date();
          const timeString = formatDateToString(dateForReturningTime, "HHmm");
          setDateTime({
            time: new Date(dStringTime),
            repaired_time: new Date(dStringRepairedTime),
            returning_time: new Date(),
          });
          tmpInfoToEdit = {
            ...tmpInfoToEdit,
            returning_quantity:
              infoToEdit.returning_quantity === 0
                ? infoToEdit.quantity
                : infoToEdit.returning_quantity,
            returning_date:
              infoToEdit.returning_date === ""
                ? formattedDate
                : infoToEdit.returning_date,
            returning_time:
              infoToEdit.returning_time === ""
                ? timeString
                : infoToEdit.returning_time,
            repaired_ac_receive_by: setDefaultValue(
              "repaired_ac_receive_by",
              listReportItem.listRepairedACReceiveBy)
          };
        }
        setStateForm(tmpInfoToEdit);
        setOriginStateForm(tmpInfoToEdit);
      } else {
        // 報告作成時の遷移
        if (!step) {
          const date = new Date();
          const timeString = formatDateToString(date, "HHmm");
          setDateTime({
              time: date,
              repaired_time: null,
              returning_time: null
            });

          setStateForm({
            ...cloneDeep(originData),
            time: timeString,
            reported_by: user,
            risky_id: infoToEdit?.risky_id ?? "",
            defect: setDefaultValue(
              "defect",
              listReportItem.listDefect),
            damage_location: setDefaultValue(
              "damage_location",
              listReportItem.listDamageLocation,
            )
          });
          setOriginStateForm({
            ...cloneDeep(originData),
            time: timeString,
            reported_by: user,
            risky_id: infoToEdit?.risky_id ?? "",
            defect: setDefaultValue(
              "defect",
              listReportItem.listDefect),
            damage_location: setDefaultValue(
              "damage_location",
              listReportItem.listDamageLocation,
            )
          });
          //AutoCompleteのinputにデフォルト値を設定する
          setInputReportedByValue(user.full_name);
        }
      }
    }
  }, [open, completeGetData, listReportItem]);

  // 初期値の設定
  const setDefaultValue = (
    field: keyof StateFormType,
    listObject: Array<any>,
  ) => {
    const default_data = listObject.find((item) => item?.is_default === true);

    if (default_data) {
      return default_data;
    } else {
      return originData[field];
    }
  };

  // HTML取得 =====================================
  const getHtmlString = useCallback(
    async (state_form: StateFormType, html_String: string) => {
      setHtmlString(""); // エラーメッセージの変更時に再描画する為、htmlを変更
      const res = getInfoFromTemplate(html_String);

      const damage_location_photo_array = await getImageArray(
        state_form.damage_location_photo,
      );
      const qa_ma_ctc_part_photo_array = await getImageArray(
        state_form.qa_ma_ctc_part_photo,
      );

      let newHtml: string = makeNewHtml(
        html_String,
        state_form,
        res.qrcodeImageExample,
        res.addMorePageExample,
        res.addCtcImagePageExample,
      );

      newHtml = insertReportLocal(
        newHtml,
        state_form,
        res.imageProductExample,
        res.pageImageExample,
        res.addPageImageExample,
      );
      setHtmlString(newHtml);

      if (
        !isEqual(damage_location_photo_array, state_form.damage_location_photo)
      ) {
        setStateForm({
          ...state_form,
          damage_location_photo: damage_location_photo_array,
        });
      }
      if (
        !isEqual(qa_ma_ctc_part_photo_array, state_form.qa_ma_ctc_part_photo)
      ) {
        setStateForm({
          ...state_form,
          qa_ma_ctc_part_photo: qa_ma_ctc_part_photo_array,
        });
      }
    },
    [],
  );

  const changeForm = useCallback(debounce(getHtmlString, 300), [getHtmlString]);

  useEffect(() => {
    changeForm(stateForm, htmlString);
  }, [stateForm, htmlString, formError]);

  // 入力制御 =====================================
  const onChangeText =
    (field: string) =>
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setStateForm({ ...stateForm, [field]: e.target.value });
    };

  const onChangeSelect =
    (field: string) => (list: any[], e: SelectChangeEvent<string>) => {
      const selected = list.find((item) => item && item.SK === e.target.value);
      setStateForm((prev) => ({ ...prev, [field]: selected }));
    };

  const onChangeDate = (field: string) => (value: string | null) => {
    if (!value) {
      value = "";
    } else {
      value = formatDateToString(value, "YMD");
    }
    setStateForm((prev) => ({ ...prev, [field]: value }));
  };

  const onChangeTime = (field: string) => (newValue: Date | null | string) => {
    const time = formatDateToString(newValue, "HHmm");
    //TimeComponentsに表示させるためのDate型へのキャスト
    const dateValue = new Date(yyyyMMddString + time);
    setDateTime((prev) => ({ ...prev, [field]: dateValue }));
    setStateForm((prev) => ({ ...prev, [field]: time }));
  };

  const onChangeQuantity = (field: string, quantity: string) => {
    // validation
    setStateForm((prevState) => ({
      ...prevState,
      [field]: quantity,
    }));
  };

  // QA CTC PartのSeral Noの変更を検知してTLGT PartのSerial NoをQA CTCのSerial Noと同期させる
  useEffect(() => {
    if (step > REPORTER_STEP) {
      setStateForm((prevState) => ({
        ...prevState,
        tl_serial_no: prevState.qa_serial_no,
      }));
    }
  }, [stateForm.qa_serial_no]);

  /** バリデーション */

  const validation = (
    field: keyof StateFormType,
    value: any,
    index?: number,
  ) => {
    let mess: any = "";
    switch (field) {
      case "quantity":
        mess = Validation.validate({
          type: "number",
          value: value,
          required: false,
          name: "Quantity",
        });
        break;
      case "checked_quantity":
        mess = Validation.validate({
          type: "number",
          value: value,
          required: false,
          name: "Checked Quantity",
        });
        break;
      case "qa_serial_no":
        mess = Validation.validate({
          type: "alpha_num",
          value: value,
          required: false,
          name: "Serial No.",
        });
        break;
      case "repaired_quantity":
        mess = Validation.validate({
          type: "number",
          value: value,
          required: false,
          name: "Repaired Quantity",
        });
        break;
      case "returning_quantity":
        mess = Validation.validate({
          type: "number",
          value: value,
          required: false,
          name: "Returning Quantity",
        });
        break;
    }
    return mess;
  };

  const onValidateText = (field: keyof StateFormType) => {
    const mess = validation(field, String(stateForm[field]));
    setFormError({ ...formError, [field]: mess });
  };

  const onValidateSerialNo = (key: keyof StateFormType) => {
    let mess: any = [];
    const newErrorIndex: number[] = [];
    stateForm[key].forEach((value: string, index: number) => {
      const original_mess = Validation.validate({
        type: "alpha_num",
        name: "Serial No.",
        value: value.toString(),
      });
      if (original_mess.length > 0) {
        newErrorIndex.push(index);
        mess.push(original_mess);
      }
    });
    setSerialNoErrorIndex(newErrorIndex);
    mess = [...new Set(mess)];
    setFormError({ ...formError, [key]: mess });
  };

  const renderMenuItem = useCallback(
    (list: Array<any>, value: string, name: string) => {
      return list
        ? list.map((item, index) => (
            <MenuItem value={item ? item[value] : ""} key={index}>
              {item ? (
                item[value] !== "0" ? (
                  <span className="IgnoreExtractRuleTarget">{item[name]}</span>
                ) : (
                  <em>{item[name]}</em>
                )
              ) : (
                <em>未選択</em>
              )}
            </MenuItem>
          ))
        : null;
    },
    [],
  );

  /**  アコーディオンセクション① TLGT Part */
  const renderItem1 = () => {
    const onChangeTextArray = (index: number, val: string) => {
      const new_tl_serial_no = [...stateForm.tl_serial_no];
      new_tl_serial_no[index] = val;
      setSerialNumberParameter(new_tl_serial_no);
      setStateForm((prevState) => ({
        ...prevState,
        tl_serial_no: new_tl_serial_no,
      }));
    };

    const handleSearchModelNameByJANCode = async () => {
      try {
        LoadingOverlayController.show();
        // JanCode検索
        let model_name = "";
        if (janCode) {
          const param = {
            jan_code: janCode,
          };
          const response = await getModelNameMaster(param);
          if (response?.data && response.data.length > 0) {
            model_name = response.data[0].name;
          } else {
            ModalController.show({
              message: messages.TEMPLATE.MSG_MODEL_NAME_NOT_EXIST,
              visibleButton2: true,
            });
          }
        }
        setStateForm((prevState) => ({
          ...prevState,
          model_name,
        }));
      } finally {
        LoadingOverlayController.hide();
      }
    };

    const handleAddNewTlSerialNo = () => {
      if (stateForm?.tl_serial_no.length < maxSerialNo) {
        const new_tl_serial_no = [...stateForm.tl_serial_no, ""];
        setSerialNumberParameter(new_tl_serial_no);
        setStateForm((prevState) => ({
          ...prevState,
          tl_serial_no: new_tl_serial_no,
        }));
      }
    };

    const handleRemoveTlSerialNo = (index: number) => {
      const new_tl_serial_no = [...stateForm.tl_serial_no];
      new_tl_serial_no.splice(index, 1);
      setSerialNumberParameter(new_tl_serial_no);
      setStateForm((prevState) => ({
        ...prevState,
        tl_serial_no: new_tl_serial_no,
      }));
      const newSerialNoErrorIndex = serialNoErrorIndex.filter(
        (item) => item !== index,
      );
      setSerialNoErrorIndex(newSerialNoErrorIndex);
    };

    const renderTLSerialNo = (data: Array<string>) => {
      return data.map((item, index) => (
        <React.Fragment key={index}>
          <OutlinedInput
            id={"tl_serial_no" + index}
            inputProps={{ maxLength: 30 }}
            value={item}
            sx={{ mb: 1 }}
            fullWidth
            disabled={REPORTER_STEP < step}
            onChange={(e) => {
              onChangeTextArray(index, e.target.value);
            }}
            onBlur={() => onValidateSerialNo("tl_serial_no")}
            error={
              serialNoErrorIndex.length > 0 &&
              serialNoErrorIndex.includes(index)
            }
            endAdornment={
              index > 0 && (
                <IconButton
                  onClick={() => {
                    if (REPORTER_STEP < step) return;
                    handleRemoveTlSerialNo(index);
                  }}
                >
                  <Cancel />
                </IconButton>
              )
            }
            key={index}
          />
          {serialNoErrorIndex.includes(index) && REPORTER_STEP === step && (
            // helperTextをPropsとして使用できないためspanタグで代用
            <span style={{ fontSize: "12px", color: "#FF4B01" }}>
              {formError.tl_serial_no}
            </span>
          )}
        </React.Fragment>
      ));
    };

    return (
      <FormGroup>
        <Stack sx={styles.formStack}>
          <DatePickerCustom
            label="Date(YYYY/MM/DD)"
            value={stateForm.date}
            onChange={onChangeDate("date")}
          />

          <TimePickerCustom
            label="Time"
            value={dateTime.time}
            onChange={(e) => onChangeTime("time")(e)}
          />

          <Box sx={{ display: "flex" }}>
            <TextField
              label={"EAN/JANCode"}
              value={janCode}
              onChange={(e) => setJanCode(e.target.value)}
              sx={{ flex: 1 }}
            />
            <Box sx={styles.searchBtn}>
              <Button
                sx={{ minWidth: 60 }}
                onClick={handleSearchModelNameByJANCode}
              >
                検索
              </Button>
            </Box>
          </Box>

          <TextField
            label="Model Name"
            inputProps={{ maxLength: 30 }}
            value={stateForm.model_name}
            onChange={onChangeText("model_name")}
          />

          <Autocomplete
            disablePortal
            id="quantity"
            options={quantityList}
            inputValue={stateForm.quantity !== 0 ? String(stateForm.quantity) : ""}
            onChange={(e, newValue) => {
              if(step >= APPROVER_STEP_1) return;
              onChangeQuantity("quantity", newValue ?? "");
            }}
            onInputChange={(e, newValue) => {
              if(step >= APPROVER_STEP_1) return;
              onChangeQuantity("quantity", newValue ?? "");
            }}
            onBlur={() => onValidateText("quantity")}
            renderInput={(params) => (
              <div>
                <TextField
                  {...params}
                  disabled={step >= APPROVER_STEP_1}
                  error={formError.quantity.length > 0}
                  inputProps={{
                    ...params.inputProps,
                    maxLength: 3,
                  }}
                  label={"Quantity"}
                />
                {formError.quantity.length > 0 && (
                  <Typography variant="caption" color="error">
                    {formError.quantity}
                  </Typography>
                )}
              </div>
            )}
            freeSolo
          />
          <FormLabel>Serial No.</FormLabel>
          {renderTLSerialNo(serialNoParameter)}
          <Button
            onClick={handleAddNewTlSerialNo}
            disabled={
              step > REPORTER_STEP ||
              stateForm?.tl_serial_no.length >= maxSerialNo
            }
          >
            Add Serial No.
          </Button>

          <Autocomplete
            disablePortal
            id="reported_by"
            options={listReportItem.listReportedBy ?? []}
            getOptionLabel={(option) => option?.full_name ?? ""}
            inputValue={inputReportedByValue}
            onChange={(e, newValue) => {
              setInputReportedByValue(newValue.full_name ?? "");
              setStateForm({
                ...stateForm,
                reported_by: newValue.full_name ?? "",
              });
            }}
            onInputChange={(e, newValue) => {
              setInputReportedByValue(newValue);
              setStateForm({
                ...stateForm,
                reported_by: newValue,
              });
            }}
            onBlur={() => onValidateText("reported_by")}
            renderInput={(params) => (
              <div>
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    maxLength: 40,
                  }}
                  label="Reported by"
                />
              </div>
            )}
            freeSolo
          />

          <SelectLabel
            label="Defect"
            value={stateForm.defect?.SK ?? ""}
            onChange={(e) => {
              onChangeSelect("defect")(listReportItem.listDefect, e);
            }}
          >
            {renderMenuItem(listReportItem.listDefect, "SK", "name")}
          </SelectLabel>

          <SelectLabel
            label="Damage Location"
            value={stateForm.damage_location?.SK ?? ""}
            onChange={(e) => {
              onChangeSelect("damage_location")(
                listReportItem.listDamageLocation,
                e,
              );
            }}
          >
            {renderMenuItem(listReportItem.listDamageLocation, "SK", "name")}
          </SelectLabel>

          <CustomImagePicker
            title={"Damage Location Photo"}
            props={[stateForm, setStateForm]}
            maxImage={maxNumberDamageLocationPhoto}
            imageArrayName={"damage_location_photo"}
          />
        </Stack>
      </FormGroup>
    );
  };

  /**  アコーディオンセクション② QA Customer Part */
  const renderItem2 = () => {
    const onChangeTextArray = (index: number, val: string) => {
      if (REPORTER_STEP < step) {
        const new_qa_serial_no = [...stateForm.qa_serial_no];
        new_qa_serial_no[index] = val;
        setSerialNumberParameter(new_qa_serial_no);
        setStateForm((prevState) => ({
          ...prevState,
          qa_serial_no: new_qa_serial_no,
        }));
      }
    };

    const handelAddNewQASerialNo = () => {
      if (
        stateForm?.qa_serial_no.length < maxSerialNo &&
        REPORTER_STEP < step
      ) {
        const new_qa_serial_no = [...stateForm.qa_serial_no, ""];
        setSerialNumberParameter(new_qa_serial_no);
        setStateForm((prevState) => ({
          ...prevState,
          qa_serial_no: new_qa_serial_no,
        }));
      }
    };

    const handelRemoveQASerialNo = (index: number) => {
      const new_qa_serial_no = [...stateForm.qa_serial_no];
      new_qa_serial_no.splice(index, 1);
      setSerialNumberParameter(new_qa_serial_no);
      setStateForm((prevState) => ({
        ...prevState,
        qa_serial_no: new_qa_serial_no,
      }));
      const newSerialNoErrorIndex = serialNoErrorIndex.filter(
        (item) => item !== index,
      );
      setSerialNoErrorIndex(newSerialNoErrorIndex);
    };

    const renderQASerialNo = (data: Array<string>) => {
      return data.map((item, index) => (
        <React.Fragment key={index}>
          <OutlinedInput
            id={"qa_serial_no" + index}
            inputProps={{ maxLength: 30 }}
            value={REPORTER_STEP < step && item}
            sx={{ mb: 1 }}
            fullWidth
            onChange={(e) => {
              onChangeTextArray(index, e.target.value);
            }}
            onBlur={() => onValidateSerialNo("qa_serial_no")}
            error={
              serialNoErrorIndex.length > 0 &&
              serialNoErrorIndex.includes(index)
            }
            endAdornment={
              index > 0 && (
                <IconButton
                  onClick={() => {
                    handelRemoveQASerialNo(index);
                  }}
                >
                  <Cancel />
                </IconButton>
              )
            }
            key={index}
          />
          {serialNoErrorIndex.includes(index) && (
            // helperTextをPropsとして使用できないためspanタグで代用
            <span style={{ fontSize: "12px", color: "#FF4B01" }}>
              {formError.qa_serial_no}
            </span>
          )}
        </React.Fragment>
      ));
    };

    return (
      <FormGroup>
        <Stack sx={styles.formStack}>
          <SelectLabel
            label="Customer Judgement"
            value={stateForm.ctc_qa_judgement?.SK ?? ""}
            onChange={(e) => {
              onChangeSelect("ctc_qa_judgement")(
                listReportItem.listJudgement,
                e,
              );
            }}
          >
            {renderMenuItem(listReportItem.listJudgement, "SK", "name")}
          </SelectLabel>

          <TextField
            label="Customer Inspector by"
            inputProps={{ maxLength: 40 }}
            value={stateForm.ctc_qa_inspector_by}
            onChange={onChangeText("ctc_qa_inspector_by")}
          />

          <DatePickerCustom
            label="Customer Checked Date(YYYY/MM/DD)"
            value={stateForm.ctc_checked_date}
            onChange={onChangeDate("ctc_checked_date")}
          />

        <Autocomplete
            disablePortal
            id="checked_quantity"
            options={quantityList}
            inputValue={stateForm.checked_quantity !== 0 ? String(stateForm.checked_quantity) : ""}
            onChange={(e, newValue) => {
              if(APPROVER_STEP_2 <= step) return;
              onChangeQuantity("checked_quantity", newValue ?? "");
            }}
            onInputChange={(e, newValue) => {
              if(APPROVER_STEP_2 <= step) return;
              onChangeQuantity("checked_quantity", newValue ?? "");
            }}
            onBlur={() => onValidateText("checked_quantity")}
            renderInput={(params) => (
              <div>
                <TextField
                  {...params}
                  disabled={APPROVER_STEP_2 <= step}
                  error={formError.checked_quantity.length > 0}
                  inputProps={{
                    ...params.inputProps,
                    maxLength: 3,
                  }}
                  label={"Checked Quantity"}
                />
                {formError.checked_quantity.length > 0 && (
                  <Typography variant="caption" color="error">
                    {formError.checked_quantity}
                  </Typography>
                )}
              </div>
            )}
            freeSolo
          />

          <FormLabel>Serial No.</FormLabel>
          {renderQASerialNo(serialNoParameter)}
          <Button
            onClick={handelAddNewQASerialNo}
            disabled={stateForm.qa_serial_no.length >= maxSerialNo}
          >
            Add Serial No.
          </Button>
        </Stack>
      </FormGroup>
    );
  };

  /**  アコーディオンセクション③ MA Customer Part */
  const renderItem3 = () => {
    return (
      <FormGroup>
        <Stack sx={styles.formStack}>
          <TextField
            label="Customer Repair by"
            inputProps={{ maxLength: 40 }}
            value={stateForm.ctc_ma_repair_by}
            onChange={onChangeText("ctc_ma_repair_by")}
          />

          <TextField
            label="Customer Inspector by"
            inputProps={{ maxLength: 40 }}
            value={stateForm.ctc_ma_inspector_by}
            onChange={onChangeText("ctc_ma_inspector_by")}
          />

          <DatePickerCustom
            label="Customer Inspection Date(YYYY/MM/DD)"
            value={stateForm.ctc_inspection_date}
            onChange={onChangeDate("ctc_inspection_date")}
          />

        <Autocomplete
          disablePortal
          id="repaired_quantity"
          options={quantityList}
          inputValue={stateForm.repaired_quantity !== 0  ? String(stateForm.repaired_quantity) : ""}
          onChange={(e, newValue) => {
            if(APPROVER_STEP_2 <= step) return;
            onChangeQuantity("repaired_quantity", newValue ?? "");
          }}
          onInputChange={(e, newValue) => {
            if(APPROVER_STEP_2 <= step) return;
            onChangeQuantity("repaired_quantity", newValue ?? "");
          }}
          onBlur={() => onValidateText("repaired_quantity")}
          renderInput={(params) => (
            <div>
              <TextField
                {...params}
                disabled={APPROVER_STEP_2 <= step}
                error={formError.repaired_quantity.length > 0}
                inputProps={{
                  ...params.inputProps,
                  maxLength: 3,
                }}
                label={"Repaired Quantity"}
              />
              {formError.repaired_quantity.length > 0 && (
                <Typography variant="caption" color="error">
                  {formError.repaired_quantity}
                </Typography>
              )}
            </div>
          )}
          freeSolo
        />

          <DatePickerCustom
            label="Repaired Date(YYYY/MM/DD)"
            value={stateForm.repaired_date}
            onChange={onChangeDate("repaired_date")}
          />

          <TimePickerCustom
            label="Repaired Time"
            value={dateTime.repaired_time}
            onChange={(e) => onChangeTime("repaired_time")(e)}
          />

        </Stack>
      </FormGroup>
    );
  };

  /**  アコーディオンセクション④ TLGT Part 2  */
  const renderItem4 = () => {
    return (
      <FormGroup>
        <Stack sx={styles.formStack}>
          <SelectLabel
            label="Repaired Cargo Receive by"
            value={stateForm.repaired_ac_receive_by?.SK ?? ""}
            onChange={(e) => {
              onChangeSelect("repaired_ac_receive_by")(
                listReportItem.listRepairedACReceiveBy,
                e,
              );
            }}
          >
            {renderMenuItem(
              listReportItem.listRepairedACReceiveBy,
              "SK",
              "name",
            )}
          </SelectLabel>

        <Autocomplete
          disablePortal
          id="returning_quantity"
          options={quantityList}
          inputValue={stateForm.returning_quantity !== 0  ? String(stateForm.returning_quantity) : ""}
          onChange={(e, newValue) => {
            onChangeQuantity("returning_quantity", newValue ?? "");
          }}
          onInputChange={(e, newValue) => {
            onChangeQuantity("returning_quantity", newValue ?? "");
          }}
          onBlur={() => onValidateText("returning_quantity")}
          renderInput={(params) => (
            <div>
              <TextField
                {...params}
                error={formError.returning_quantity.length > 0}
                inputProps={{
                  ...params.inputProps,
                  maxLength: 3,
                }}
                label={"Returning Quantity"}
              />
              {formError.returning_quantity.length > 0 && (
                <Typography variant="caption" color="error">
                  {formError.returning_quantity}
                </Typography>
              )}
            </div>
          )}
          freeSolo
        />

          <DatePickerCustom
            label="Returning Date(YYYY/MM/DD)"
            value={stateForm.returning_date}
            onChange={onChangeDate("returning_date")}
          />

          <TimePickerCustom
            label="Returning Time"
            value={dateTime.returning_time}
            onChange={(e) => onChangeTime("returning_time")(e)}
          />

          <CustomImagePicker
            title={"Customer Part Photo"}
            props={[stateForm, setStateForm]}
            maxImage={maxNumberQAMACTCPartPhoto}
            imageArrayName={"qa_ma_ctc_part_photo"}
          />
        </Stack>
      </FormGroup>
    );
  };

  // 保存 =====================================
  const onSubmit = async () => {
    // 画像アップロード
    let newContainerConditionPhoto: Array<Type.ImageInfoType> =
      stateForm.damage_location_photo ?? [];
    let newOtherDocumentPhoto: Array<Type.ImageInfoType> =
      stateForm.qa_ma_ctc_part_photo ?? [];
    if (stateForm.damage_location_photo) {
      newContainerConditionPhoto = await uploadImageToS3(
        stateForm.damage_location_photo,
      );
    }
    if (stateForm.qa_ma_ctc_part_photo) {
      newOtherDocumentPhoto = await uploadImageToS3(
        stateForm.qa_ma_ctc_part_photo,
      );
    }
    let newStateForm = {
      ...stateForm,
      damage_location_photo: newContainerConditionPhoto,
      qa_ma_ctc_part_photo: newOtherDocumentPhoto,
    };
    setStateForm(newStateForm);
  };

  return (
    <Box height={"auto"}>
      <Stack>
        <AccordionSection
          title={"TLGT Part"}
          children={renderItem1()}
          defaultExpanded={REPORTER_STEP === step}
          isIgnoreExtractRule={true}
        />
        {REPORTER_STEP < step && (
          <>
            <AccordionSection
              title={"QA Customer Part"}
              children={renderItem2()}
              defaultExpanded={APPROVER_STEP_1 === step}
              isIgnoreExtractRule={true}
            />
            <AccordionSection
              title={"MA Customer Part"}
              children={renderItem3()}
              defaultExpanded={APPROVER_STEP_1 === step}
              isIgnoreExtractRule={true}
            />
          </>
        )}
        {APPROVER_STEP_1 < step && (
          <AccordionSection
            title={"TLGT Part 2"}
            children={renderItem4()}
            defaultExpanded={true}
            isIgnoreExtractRule={true}
          />
        )}
      </Stack>
    </Box>
  );
};

export default forwardRef(ReceivingProblemTag);

const styles = {
  header: { bgcolor: Colors.MAIN_GREEN_LIGHT },
  formStack: { pb: 0, width: "100%" },
  searchBtn: {
    display: "flex",
    alignSelf: "flex-end",
    ml: 1,
    mb: "10px",
  },
} as const;
