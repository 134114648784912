import React, { VFC, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  Stack,
  Grid,
  Box,
  Typography,
  MenuItem,
} from "@mui/material";
import _ from "lodash";
import SelectLabel from "components/atoms/SelectLabel";
import { GroupInfo, GroupMaster } from "services/models";
import { getListGroup, getListGroupMaster } from "@api/groupMaster";
import TreeViewCustom from "components/atoms/TreeViewCustom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useSize } from "shared/hook/useSize";
import "./index.css";
import { Colors } from "@template/style";

export interface IData {
  SK: string;
  name: string;
  nameAll: string;
  group?: GroupInfo;
  selectedData?: ISelectedData;
}

type ISelectedData = { [key: string]: string };

interface IStateForm extends GroupInfo {
  children?: IStateForm[];
  title?: string;
  isLeaf?: boolean;
  level?: number;
  loaded?: boolean;
}
type ILoopMatch = (
  item: IStateForm,
  isChild: boolean | undefined,
  check_child: { val: boolean },
  data: IStateForm[],
) => boolean;

type ILoopCallback = (
  item: IStateForm,
  index: number,
  data: IStateForm[],
  level: number,
  isChild: boolean | undefined,
) => any;

/**
 * ツリーデータループ処理
 */
const loop = (
  data: IStateForm[],
  isMatch: ILoopMatch,
  callback: ILoopCallback,
  level: number = 1,
  isChild?: boolean,
) => {
  for (let index = 0; index < data.length; index++) {
    let item = data[index];
    let check_child = { val: false };
    if (isMatch(item, isChild, check_child, data)) {
      callback(item, index, data, level, isChild);
      return;
    }
    if (item.children) {
      loop(item.children, isMatch, callback, level + 1, check_child.val);
    }
  }
};

interface IProps {
  open: boolean;
  setOpen: Function;
  onChange: Function;
  initialSelect?: ISelectedData;
  readOnly?: { [key: string]: boolean };
}

const GroupSelectDialog: VFC<IProps> = ({
  open,
  setOpen,
  onChange,
  initialSelect = {},
  readOnly = {},
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [name, setName] = useState(""); // グループ名
  const [nameAll, setNameAll] = useState(""); // 全階層グループ名

  const [stateForm, setStateForm] = useState<IStateForm[]>([]);
  const [listGroupMaster, setListGroupMaster] = useState<GroupMaster[]>([]);
  const [listAllGroup, setListAllGroup] = useState<GroupInfo[]>([]);

  const [maxLevel, setMaxLevel] = useState<number>(0);
  const treeRef = React.useRef<any>(null);
  const [loadDataFlg, setLoadDataFlg] = useState(false);
  const [initialDataFlg, setInitialDataFlg] = useState(false);
  const initialDataRef = React.useRef<IStateForm[]>();

  const [selectedData, setSelectedData] = useState<ISelectedData>({});
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  const { isMdUp } = useSize();

  const setInitialData = () => {
    setName("");
    setNameAll("");
    setSelectedData({});
    setSelectedKeys([]);
  };

  useEffect(() => {
    if (initialDataFlg) {
      setInitialData();

      if (initialSelect && !_.isEmpty(initialSelect)) {
        setSelectedData({ ...initialSelect });
        const key = Object.keys(initialSelect).reduce((a, b) => {
          if (initialSelect[b] === "") return a;
          return Math.max(Number(a), Number(b)).toString();
        });
        setSelectedKeys([initialSelect[key]]);

        changeGroup(Number(key), initialSelect[key], initialSelect);
      }
      setInitialDataFlg(false);
      initialDataRef.current = undefined;
    }
  }, [initialDataFlg, initialSelect]);

  useEffect(() => {
    if (open) {
      if (stateForm.length === 0) {
        fetchData().then(() => setInitialDataFlg(true));
      } else {
        setInitialDataFlg(true);
      }
    }
  }, [open]);

  // ------------------------------------------------------------------
  // データ取得
  // ------------------------------------------------------------------
  const fetchData = async (groupParentID?: string) => {
    try {
      let isOpen = groupParentID === undefined;
      let max_level = maxLevel;
      let res: GroupInfo[] = [];

      if (isOpen) {
        // グループ階層を取得
        const res_master: GroupMaster[] = await getListGroupMaster();
        groupParentID = res_master[0].PK;
        max_level = res_master.length;
        setListGroupMaster(res_master);
        setMaxLevel(res_master.length);
      }

      // グループを取得
      res = await getListGroup({
        group_parentid: groupParentID ?? "CONTRACT#",
      });

      if (isOpen) {
        const value = setInitialDataArr(res, max_level === 1, 1);
        setStateForm(JSON.parse(JSON.stringify(value)));
        setSelectedData({ "1": "" });
      } else {
        // 初期表示の際はrefを仕様
        let new_data: IStateForm[] = JSON.parse(
          JSON.stringify(
            initialDataFlg ? initialDataRef.current ?? stateForm : stateForm,
          ),
        );
        const callback: ILoopCallback = (item, index, data, level) => {
          const value = setInitialDataArr(
            res,
            maxLevel === level + 1,
            level + 1,
          );
          item.children = value;
          item.isLeaf = value.length > 0 ? false : true;
        };
        loop(new_data, (item) => item.SK === groupParentID, callback, 1);

        setStateForm(() => new_data);
        if (initialDataFlg) initialDataRef.current = new_data;
      }

      setListAllGroup((pre) => [...pre, ...JSON.parse(JSON.stringify(res))]);
      return res;
    } catch (error: any) {
      console.log("error fetchData", error);
    }
  };

  const setInitialDataArr = (
    data: IStateForm[],
    isLeaf?: boolean,
    level?: number,
  ) => {
    const initial_data: IStateForm[] = [];
    data.forEach((item) => {
      const new_item = Object.assign({}, item);
      new_item.title = "";
      new_item.isLeaf = isLeaf;
      new_item.level = level;
      new_item.loaded = true;

      initial_data.push(new_item);
    });
    return initial_data;
  };

  // ------------------------------------------------------------------
  // ツリービュー
  // ------------------------------------------------------------------
  const fieldNames = {
    key: "SK",
  };

  const switcherIcon = (obj: any) => {
    if (obj.isLeaf) {
      return <Box sx={{ width: "24px" }} />;
    }
    return obj.expanded ? <ExpandLess /> : <ExpandMore />;
  };

  const titleRender = (node: IStateForm) => {
    return (
      <Typography noWrap display={"block"} sx={{ py: 1 }}>
        {node.group_name}
      </Typography>
    );
  };

  const onLoadData = (treeNode: any) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        // StrictModeの2回発火を回避
        if (!treeRef.current) {
          if (expandedKeys.indexOf(treeNode.SK) === -1) {
            treeRef.current = treeNode;
            setLoadDataFlg(true);
            setExpandedKeys((pre) => {
              pre.push(treeNode.SK);
              return pre;
            });
          }
        }
        resolve(undefined);
      }, 500);
    });
  };

  useEffect(() => {
    if (loadDataFlg && treeRef.current) {
      fetchData(treeRef.current.SK).then(() => {
        setLoadDataFlg(false);
        treeRef.current = null;
      });
    }
  }, [treeRef.current, loadDataFlg]);

  // ------------------------------------------------------------------
  // セレクトボックス
  // ------------------------------------------------------------------
  const renderSelect = () => {
    let listParentGroup: IStateForm[] = [];
    return listGroupMaster.map((gm, i) => {
      const level = i + 1;
      let listItem: IStateForm[] = [];

      if (i === 0) {
        listItem = stateForm;
      } else {
        let tmp = listParentGroup.find((d) => d.SK === selectedData[level - 1]);
        if (tmp && tmp.children) {
          listItem = tmp.children;
        }
      }
      listParentGroup = listItem;
      if (!readOnly[level]) {
        return (
          <SelectLabel
            label={gm.group_master_name}
            key={gm.SK}
            disabled={listItem ? listItem.length === 0 : true}
            displayEmpty
            value={listItem.length > 0 ? selectedData[level] ?? "" : ""}
            isIgnoreExtractRuleLabel={true}
            isIgnoreExtractRuleSelect={true}
            onChange={async (e) => {
              await changeGroup(level, e.target.value);
            }}
          >
            <MenuItem value={""} key={0} disabled={readOnly[level]}>
              <span>未選択</span>
            </MenuItem>
            {listItem &&
              listItem.map((item) => (
                <MenuItem
                  value={item.SK}
                  key={item.SK}
                  disabled={readOnly[level] && selectedData[level] != item.SK}
                >
                  <span className="IgnoreExtractRuleTarget">
                    {item.group_name}
                  </span>
                </MenuItem>
              ))}
          </SelectLabel>
        );
      } else {
        let groupName = listItem.find((d) => d.SK === selectedData[level]);
        return (
          <div key={i}>
            <Typography>{gm.group_master_name}</Typography>
            <Typography sx={{ color: Colors.DISABLE_BTN_TEXT, ml: 2 }}>
              {groupName?.group_name}
            </Typography>
          </div>
        );
      }
    });
  };

  const changeGroup = async (
    level: number,
    SK: string,
    selected_data?: any,
  ) => {
    let item_list: IStateForm[] = JSON.parse(JSON.stringify(stateForm));
    let group_name = "";
    let name_all = "";
    let current_level = SK === "" ? level - 1 : level;
    let selectedSK = "";
    selected_data = selected_data ?? { ...selectedData };

    // 選択したグループの子階層のグループを取得
    for (let index = 0; index < current_level; index++) {
      selectedSK = index + 1 === level ? SK : selected_data[index + 1];
      if (selectedSK === "") continue;

      let tmp = item_list.find((d) => d.SK === selectedSK);
      if (tmp) {
        name_all += tmp.group_name + " ";
        if (index + 1 === current_level) group_name = tmp.group_name;
      }

      if (tmp && tmp.children) {
        item_list = tmp.children ?? [];
      } else {
        const res = await fetchData(selectedSK);
        item_list = res ?? [];
        setExpandedKeys((pre) => {
          pre.push(selectedSK);
          return pre;
        });
      }
    }

    // 選択したグループより下の階層をリセット
    for (let index = level + 1; index <= listGroupMaster.length; index++) {
      selected_data[index] = "";
    }

    setSelectedData({ ...selected_data, [level]: SK });
    setSelectedKeys([selectedSK]);
    setName(group_name);
    setNameAll(name_all.slice(0, -1));
  };

  return (
    <Dialog
      maxWidth={/*"lg"*/ "sm"}
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={0}
            md={/*7*/ 0}
            display={/*isMdUp ? undefined : "none"*/ "none"}
          >
            {/* <TreeViewCustom
              prefixCls="rc-tree-activity-base"
              fieldNames={fieldNames}
              treeData={stateForm}
              showIcon={false}
              switcherIcon={switcherIcon}
              loadData={onLoadData}
              titleRender={titleRender}
              selectedKeys={selectedKeys}
              onSelect={(checked, info) => {
                if (info.node.level) {
                  let select_data = { ...selectData };

                  // 選択階層を初期化
                  [...Array(listGroupMaster.length)].map(
                    (_, i) => (select_data[i + 1] = ""),
                  );

                  const loop_select = (
                    data: IStateForm[],
                    level: number,
                    isfound: { val: boolean },
                  ) => {
                    for (let index = 0; index < data.length; index++) {
                      let item = data[index];
                      if (item.SK === info.node.SK) {
                        select_data[level] = item.SK;
                        isfound.val = true;
                        return;
                      }
                      if (item.children) {
                        loop_select(item.children, level + 1, isfound);
                        if (isfound.val) {
                          if (select_data[level] === "")
                            select_data[level] = item.SK;
                          if (level === 1) return;
                        }
                      }
                    }
                  };

                  // 選択されたグループの全階層を取得
                  loop_select(stateForm, 1, { val: false });
                  changeGroup(info.node.level, info.node.SK, select_data);
                }
              }}
            /> */}
          </Grid>
          <Grid item xs={12} md={/*5*/ 12} position="sticky">
            <FormGroup>
              <Stack>{renderSelect()}</Stack>
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          variant="outlined"
        >
          キャンセル
        </Button>

        <Button
          onClick={() => {
            const SK = selectedKeys.length > 0 ? selectedKeys[0] : "";
            const group = listAllGroup.find((d) => d.SK === SK);
            const response: IData = {
              SK: SK,
              name: name,
              nameAll: nameAll,
              group: group,
              selectedData: selectedData,
            };
            onChange(response);
            setOpen(false);
          }}
          color="secondary"
        >
          確定
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupSelectDialog;
