import { createTheme } from "@mui/material/styles";
import { jaJP } from "@mui/material/locale";

// Color 定義
export const Colors = {
  BG_GRAY: "#f2f2f2",
  MAIN_GREEN: "#029F98",
  MAIN_GREEN_LIGHT: "#7FD5D1",
  SUCCESS: "#0ACF84",
  DANGER: "#E7515A",
  TEXT: "#27504e",
  ERROR_BG: "#ffe2ea",
  ERROR_TEXT: "#FF4B00",
  DISABLE_BTN: "#c7cccc",
  DISABLE_BTN_TEXT: "#8a9594",
  REQUIRE: "#FF4B00",
  SECONDARY: "#F7951F",
  YELLOW: "#FECA07",
  BTN_GREEN: "#34ac58",
  HELPER_TEXT: "#8a9594",
  BORDER: "#c7cccc",
  CAUTIONS: "#f8f0d5",
  IMAGE_SELECT_BLOCK: "#f3f3f3",
  DISABLE_INPUT_BG: "#e0e0e0",
  HEADER_COLOR_GREEN: "#dbf2f1",
  LIGHT_GRAY: "#adadad",
  LIGHT_GREEN: "rgb(18,191,183)",
  LIGHT_RED: "rgb(255,128,130)",
  LIGHT_BLUE: "rgb(131,162,255)",
};

const primary = Colors.MAIN_GREEN;
const secondary = Colors.SECONDARY;
const info = "#3c363c";
const success = Colors.BTN_GREEN;
const warning = Colors.YELLOW;
const error = Colors.REQUIRE;
const light_shades = Colors.DISABLE_BTN_TEXT;
// const dark_shades = "#3C363B";
const dark_accent = Colors.BG_GRAY;

const basetheme = createTheme({
  palette: {
    primary: {
      main: primary,
      contrastText: "white",
    },
    secondary: {
      main: secondary,
      contrastText: "white",
    },
    info: {
      main: info,
      contrastText: "white",
    },
    success: {
      main: success,
      contrastText: "white",
    },
    warning: {
      main: warning,
      contrastText: Colors.TEXT,
    },
    error: {
      main: error,
    },
    background: {
      default: Colors.BG_GRAY,
    },
    text: {
      primary: Colors.TEXT,
      secondary: Colors.TEXT,
      disabled: Colors.DISABLE_BTN_TEXT,
    },
  },
  typography: {
    fontFamily: ["'Noto Sans JP'"].join(","),
    fontSize: 14,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
    body1: { fontWeight: "bold" },
    button: { fontWeight: "bold" },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 67,
    },
  },
});

export const theme = createTheme(
  {
    ...basetheme,
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            // backgroundColor: "dark_shades",
            backgroundColor: "white",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            minWidth: 100,
          },
          contained: {
            boxShadow: "none",
            "&:active": {
              boxShadow: "none",
            },
          },
        },
        defaultProps: {
          variant: "contained",
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            marginLeft: basetheme.spacing(1),
          },
          indicator: {
            height: 3,
            // borderTopLeftRadius: 3,
            // borderTopRightRadius: 3,
            // backgroundColor: basetheme.palette.common.white,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
            margin: "0 16px",
            minWidth: 0,
            padding: 0,
            [basetheme.breakpoints.up("md")]: {
              padding: 0,
              minWidth: 0,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: basetheme.spacing(1),
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: 4,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: "rgb(255,255,255,0.15)",
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            color: light_shades,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            color: light_shades,
            "&:hover, &:focus": {
              backgroundColor: dark_accent,
            },
            "&.Mui-selected": {
              color: light_shades,
              backgroundColor: dark_accent,
              "&:hover": { color: light_shades, backgroundColor: dark_accent },
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontSize: 14,
            color: light_shades,
            fontWeight: basetheme.typography.fontWeightMedium,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: "inherit",
            minWidth: "auto",
            marginRight: basetheme.spacing(2),
            "& svg": {
              fontSize: 20,
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            width: 38,
            height: 38,
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 1,
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              color: Colors.TEXT,
            },
          },
          formControl: {
            position: "static",
            transform: "none",
            transition: "none",
            pointerEvents: "none",
            marginBottom: "3px",
            whiteSpace: "normal",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: "white",
            "&.Mui-disabled": {
              backgroundColor: Colors.DISABLE_INPUT_BG,
            },
          },
          notchedOutline: {
            top: 0,
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiStack: {
        defaultProps: { spacing: 2 },
      },
      MuiButtonBase:{
        styleOverrides:{
          root:{
            "&.MuiPickersDay-root":{
              fontWeight: "bold"
            },
            "&.MuiPickersDay-root.Mui-disabled":{
              fontWeight: 100
            }
          }
        }
      }
      // MuiFormControl: {
      //   styleOverrides: {
      //     root: {
      //       marginBottom: "10px",
      //     },
      //   },
      // },
    },
  },
  jaJP,
);

// メニューバーの幅
export const navigatorWidth = 256;
