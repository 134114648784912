import { type Dispatch } from "react";
import { clearData, storeData, STORAGE } from "@utils/Storage";
import { METHOD_API, createConnector } from "@api/Connector";
import { apiGetLoginUserInfo } from "@api/User";
import ModalController from "@shared-components/modal/ModalController";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { updateAccessToken } from "@api/HttpClient";
import { TYPES } from "store/types";


// 認証パラメータ
export interface LoginSericesProps {
  username_id: string;
  password: string;
  contract_login_id: string;
}
export interface AuthInfo extends LoginSericesProps {
  username: string;
}

export const updateToken = async (
  accessToken: string,
  refreshToken: string,
) => {
  try {
    updateAccessToken(accessToken);
    await clearData(STORAGE.ACCESS_TOKEN);
    await storeData(STORAGE.ACCESS_TOKEN, accessToken);
    await clearData(STORAGE.REFRESH_TOKEN);
    await storeData(STORAGE.REFRESH_TOKEN, refreshToken);
  } catch (e) {
    // saving error
  }
};

export const getUserInfo = async () => {
  const resUser = await apiGetLoginUserInfo()
    .then((res) => {
      const resUser = JSON.parse(JSON.stringify(res));
      return resUser;
    })
    .catch((err) => {
      console.log(err);
      console.log("error getUserInfo");
      return "";
    });
  await storeData(STORAGE.USER_INFO, JSON.stringify(resUser.data));
  return resUser.data;
};

/**
 * ログイン処理
 * @param props
 * @returns
 */
export const apiLogin = async (
  props: LoginSericesProps,
  SuccessCallBack: Function,
  ErrorCallback: Function,
  dispatch: Dispatch<any>,
) => {
  LoadingOverlayController.show();
  const res = await createConnector(METHOD_API.POST, `api/v1/login-contract-split`, props)
    .then((res) => {
      const resConvert = JSON.parse(JSON.stringify(res));
      updateToken(resConvert.access_token, resConvert.refresh_token);

      return true;
    })
    .catch((err) => {
      console.log("error apiLogin");
      console.log(err);

      ModalController.show({
        message: err.detail,
        visibleButton2: true,
      });
      ErrorCallback();

      return false;
    })
    .finally(() => {
      LoadingOverlayController.hide();
    });

  if (res) {
    const name = await getUserInfo();
    dispatch({ type: TYPES.SET_MASTER_COLOR_DATA });
    SuccessCallBack(name);
  }
  return res;
};
