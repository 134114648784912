import React, { useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import { BaseDateValidationProps } from "@mui/x-date-pickers/internals/hooks/validation/models";
import { DatePickerProps } from "@mui/x-date-pickers";
import DatePickerCustom from "./index";

export interface IDateRestriction<TDate> {
  start_date_restriction: BaseDateValidationProps<TDate>["minDate"];
  end_date_restriction: BaseDateValidationProps<TDate>["maxDate"];
}

interface IDateRangePicker<TInputValue, TValue> {
  value: Array<DatePickerProps<TInputValue, TValue>["value"]>;
  onChange: Array<(value: TValue, keyboardInputValue?: string) => void>;
  label?: React.ReactNode;
  required?: Array<boolean | undefined>;
  helperText?: Array<React.ReactNode>;
  error?: Array<boolean>;
  onBlur?: Array<Function>;
  onAccept?: Array<(value: any) => void>;
  dateRestriction?: IDateRestriction<TInputValue>;
  defaultCalendarMonth?: any;
}

const DateRangePicker = <TInputDate, TDate = TInputDate>({
  value,
  onChange,
  label,
  required,
  helperText,
  error,
  onBlur,
  onAccept,
  dateRestriction,
  defaultCalendarMonth,
}: IDateRangePicker<TInputDate, TDate>) => {
  const getValue = useCallback(
    (i: number) => (value.length > i && value[i] ? value[i] : null),
    [value],
  );
  const handleOnChange = useCallback(
    (i) => (v: any, k: any) =>
      onChange.length > i ? onChange[i](v, k) : () => {},
    [onChange],
  );
  const getRequired = useCallback(
    (i: number) => (required && required.length > i ? required[i] : undefined),
    [required],
  );
  const getHelperText = useCallback(
    (i: number) =>
      helperText && helperText.length > i ? helperText[i] : undefined,
    [helperText],
  );
  const getError = useCallback(
    (i: number) => (error && error.length > i ? error[i] : undefined),
    [error],
  );
  const handleOnBlur = useCallback(
    (i) => (onBlur && onBlur.length > i ? () => onBlur[i]() : undefined),
    [onBlur],
  );
  const handleOnAccept = useCallback(
    (i) => (v: any) =>
      onAccept && onAccept.length > i ? onAccept[i](v) : () => {},
    [onAccept],
  );

  return (
    <Stack direction={{ xs: "column", md: "row" }} spacing={0}>
      <DatePickerCustom
        value={getValue(0) ?? null}
        onChange={handleOnChange(0)}
        required={getRequired(0)}
        helperText={getHelperText(0)}
        error={getError(0)}
        onBlur={handleOnBlur(0)}
        onAccept={handleOnAccept(0)}
        label={label}
        minDate={dateRestriction?.start_date_restriction}
        maxDate={dateRestriction?.end_date_restriction}
        defaultCalendarMonth={defaultCalendarMonth}
      />
      <Typography
        variant="h4"
        sx={{
          marginX: 2,
          mt: label ? 4 : 1,
          alignSelf: "flex-start",
          display: { xs: "none", md: "inline-flex" },
        }}
      >
        ~
      </Typography>
      <DatePickerCustom
        value={getValue(1) ?? null}
        onChange={handleOnChange(1)}
        required={getRequired(1)}
        helperText={getHelperText(1)}
        error={getError(1)}
        onBlur={handleOnBlur(1)}
        onAccept={handleOnAccept(1)}
        label={label ? "　" : undefined}
        minDate={dateRestriction?.start_date_restriction}
        maxDate={dateRestriction?.end_date_restriction}
        defaultCalendarMonth={defaultCalendarMonth}
      />
    </Stack>
  );
};

export default DateRangePicker;
