import React, { useEffect, useMemo, useState } from "react";

import GenericTemplate from "@template/index";
import { Work } from "services/models";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getConditionFilter,
  getHasFilterConditionProject,
} from "selector/filterSelector";
import { getSortOfScreen } from "selector/sortSelector";
import { Box, Button, Checkbox, Grid, Stack, Typography } from "@mui/material";
import InfiniteScroll from "react-infinite-scroller";
import { toString } from "lodash";
import PopupSort from "components/atoms/PopupSort";
import FilterInput from "components/molecules/FilterInput";
import ScrollLoader from "components/atoms/ScrollLoader";
import WorkItemTlog from "components/molecules/WorkItem/WorkItemTlog";
import {
  LIST_TYPE_SORT_WORK,
  STATUS_WORK_TLOG,
  screenIdSupportMergeTemplate,
  deleteImageDataWhenFinish,
} from "@shared-constants";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import { getParamsFilter } from "@utils/index";
import useDeepCompareEffect from "shared/hook/useDeepCompareEffect";
import {
  getListWorkV2Api,
  updateStatusGroupWorkItemApi,
  getWorkByIdApi,
  updateWorkByIdApi,
  getMaxApprovalsApi,
  updateWorkBatchApi,
  getWorkListDetailApi,
} from "@api/work";
import { TYPES } from "store/types";
import { RootState } from "store/reducer";
import messages from "config/messages";
import { Colors } from "@template/style";
import { StatisticalBoardType } from "@shared-constants";
import { cloneDeep } from "lodash";
import {
  createFormUpload,
  getFileByUrl,
  createFileNameFollowUri,
} from "shared/utils";
import { screenIdSupportGetImageUrl } from "screens/CreateReportTlog/templateFunctionSupport";
import WorkDetailDialog from "screens/WorkSearchTlog/WorkDetailDialog";

let paramsFilter: any;

const WorkGroupEditStatusScreen: React.VFC = () => {
  const location = useLocation<any>();
  const typeAction = location.state?.typeAction;
  const typeScreen = "work_status";
  const work_type = location.state?.work_type ?? STATUS_WORK_TLOG.NOT_APPROVED;
  const type = location.state?.type ?? "";
  const dispatch = useDispatch();
  const { selected_project_id } = useSelector(
    (state: RootState) => state.projectInfo,
  );
  const [selectedWorkItem, setSelectedWorkItem] = useState<Work[]>([]);
  const hasCondition = useSelector(getHasFilterConditionProject(typeScreen));
  const workGroupFilter = useSelector(getConditionFilter(typeScreen));
  const typeSort = useSelector(getSortOfScreen(typeScreen));
  const [listWorkItem, setListWorkItem] = useState<Work[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [lastKey, setLastKey] = useState<any | null>(null);
  const [loadMore, setLoadMore] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [PK, setPK] = useState<string | null>(null);
  const [searchedKeyword, setSearchKeyword] = useState<string>("");
  const [isDisableFilter, setIsDisableFilter] = useState<boolean>(false);
  const [max_approvals, setMaxApprovals] = useState<number>(0);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [detailData, setDetailData] = useState<any>();

  // ------------------------------------------------------------------
  // チェック
  // ------------------------------------------------------------------
  const handleSelectItem = (item: Work) => {
    const indexItem = selectedWorkItem.findIndex(
      (wItem: Work) => wItem.work_id === item.work_id,
    );
    if (selectedWorkItem.length == max_approvals && indexItem <= -1) {
      ModalController.show({
        message: messages.WORK_ITEM.MSG_APPROVED_LIMIT(max_approvals),
        visibleButton2: true,
      });
      return;
    }
    if (selectedWorkItem.length === 0) {
      setSelectedWorkItem([...selectedWorkItem, item]);
      return;
    }
    if (indexItem <= -1) {
      setSelectedWorkItem([...selectedWorkItem, item]);
    }
    if (indexItem > -1) {
      let updateList = selectedWorkItem.filter(
        (wItem: Work) => wItem.work_id !== item.work_id,
      );
      setSelectedWorkItem(updateList);
    }
  };

  const getIsCheck = (id: string) => {
    return selectedWorkItem
      .map((workItem: Work) => workItem.work_id)
      .includes(id);
  };

  // ------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------
  const _handleSearch = (str: string) => {
    setSearchKeyword(str);
    setLoadMore(true);
    setLastKey(null);
    setSortBy(null);
    setPK(null);
    LoadingOverlayController.show();
    fetchListWorkItem(str, undefined, "", "");
  };

  const fetchListWorkItem = async (
    keyWord?: string,
    sortType?: string,
    lastKeyword?: string,
    sort_by?: string,
    isMerge: boolean = false,
    pk?: string,
  ) => {
    setLoading(true);
    if (listWorkItem.length === 0) {
      LoadingOverlayController.show();
    }
    let params = {
      keyword: keyWord ?? searchedKeyword,
      sort_type: sortType ?? typeSort,
      last_key: lastKeyword ?? lastKey,
      sort_by: sort_by ?? sortBy,
      pk: pk ?? PK,
      filter_condition: paramsFilter,
      work_type: work_type,
      is_work_owner: toString(type === StatisticalBoardType.register),
    };

    await getListWorkV2Api(params)
      .then((res) => {
        if (res?.data.length === 0 && !res?.lastKey) {
          setLoadMore(false);
        }
        if (isMerge) {
          setListWorkItem((prevValue) => [...prevValue, ...res?.data]);
        } else {
          setListWorkItem(res?.data);
        }

        if (res?.last_key) {
          setLastKey(res?.last_key?.SK ?? null);
          setSortBy(res?.last_key?.sort_by ?? null);
          setPK(res?.last_key?.PK ?? null);
          setLoadMore(true);
        } else {
          setLastKey(null);
          setSortBy(null);
          setPK(null);
          setLoadMore(false);
        }
        if (res?.data.length < 10 && res?.last_key) {
          fetchListWorkItem(
            params.keyword,
            params.sort_type,
            res?.last_key?.SK,
            res?.last_key?.sort_by,
            true,
            res?.last_key?.PK,
          );
        }
        if (!res?.last_key || (res.data.length >= 10 && res.last_key)) {
          LoadingOverlayController.hide();
        }
      })
      .catch((error) => {
        ModalController.show({
          message: error?.response?.detail?.message,
          visibleButton2: true,
        });
      })
      .finally(() => {
        setLoading(false);
        LoadingOverlayController.hide();
      });
  };

  // ------------------------------------------------------------------
  // 完了・削除
  // ------------------------------------------------------------------
  const handleActionButton = () => {
    ModalController.show({
      message:
        typeAction === "1"
          ? messages.WORK_ITEM.MSG_CONFIRM_COMPLETE_WORK
          : typeAction === "2"
          ? messages.WORK_ITEM.MSG_CONFIRM_DELETE_WORK
          : messages.WORK_ITEM.MSG_CONFIRM_APPROVED_WORK,
      visibleButton1: true,
      visibleButton2: true,
      handlePressButton2: () =>
        typeAction !== "3" ? handleConfirmAction() : handleApprovedAction(),
    });
  };

  const CheckFinish = (approval_status: string) => {
    const str = approval_status;
    const index = str?.indexOf("/");
    if (index != -1) {
      const start = Number(str?.slice(0, index));
      const end = Number(str?.slice(index + 1));
      return start + 1 == end;
    }
  };

  // 自作Sleep関数
  const _sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const handleApprovedAction = async () => {
    try {
      LoadingOverlayController.show();
      var formData: FormData = new FormData();
      const params = selectedWorkItem.map((item) => ({
        project_id: item.project_id,
        work_id: item?.SK?.startsWith("WORK#") ? item?.SK : item?.PK,
      }));
      formData = createFormUpload(null, { params });
      const res = await getWorkListDetailApi(formData);
      if (!res?.data) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_ERROR_001,
          visibleButton2: true,
          handlePressButton2: () => {
            fetchListWorkItem();
          },
        });
        return;
      }

      formData = new FormData();
      for (let i = 0; i < res.data.length; i++) {
        const data = res.data[i];
        const templateData = data?.work_template_id;
        let work_data;
        const {
          PK,
          SK,
          work_template_id,
          memo,
          flow_approve_data,
          flow_approve_id,
        } = data;
        const { template_path, screen_id, work_template_data } = templateData;

        let newStateFormEditInfo = JSON.parse(
          templateData.work_template_data ?? "null",
        );
        const fileName = createFileNameFollowUri(template_path);
        let localFile = template_path;
        let newPath: string | null = null;
        if (newStateFormEditInfo) {
          newStateFormEditInfo = await screenIdSupportGetImageUrl[screen_id](
            newStateFormEditInfo,
          );
          newPath = await screenIdSupportMergeTemplate[screen_id](
            localFile,
            newStateFormEditInfo,
            templateData,
          );
        }
        let templateEditInfoNew = cloneDeep(work_template_data);

        if (
          CheckFinish(selectedWorkItem[i].approval_status) &&
          work_template_id
        ) {
          templateEditInfoNew =
            deleteImageDataWhenFinish[work_template_id?.screen_id ?? ""](
              templateEditInfoNew,
            );
        }

        work_data = {
          project_id: PK,
          work_id: SK,
          work_status: "FINISH",
          files: null,
          images: null,
          memo: memo,
          work_template_data: templateEditInfoNew,
          work_template_id: `TEMPLATE_WORK#${work_template_id?.template_id}`,
          template_work_sk: work_template_id?.SK,
          flow_data: JSON.stringify({ data: flow_approve_data }),
          flow_id: flow_approve_id?.SK,
          request_or_register: "request",
        };
        formData.append("params", JSON.stringify(work_data));
        const uri = newPath ? newPath : localFile;
        if (uri) {
          const f = await getFileByUrl(uri, fileName, "text/html");
          formData.append("work_template_file", f);
        }
      }
      await updateWorkBatchApi(formData);
      ModalController.show({
        message: messages.WORK_ITEM.MSG_APPROVED_GROUP_SUCCESS,
        visibleButton2: true,
        handlePressButton2: () => history.back(),
      });
    } catch (error: any) {
      ModalController.show({
        message: error?.response?.detail.message
          ? error?.response?.detail.message
          : messages.WORK_ITEM.MSG_ERROR_APPROVED_LIMIT,
        visibleButton2: true,
        handlePressButton2: () => {
          fetchListWorkItem();
        },
      });
    } finally {
      setSelectedWorkItem([]);
      LoadingOverlayController.hide();
    }
  };

  const handleConfirmAction = async () => {
    try {
      LoadingOverlayController.show();
      const workItemIds = selectedWorkItem.map((item) =>
        item.SK.includes("WORK#") ? item.SK : item.PK,
      );
      const isDelete = typeAction === "1" ? false : true;

      const projectInfo = selectedWorkItem.map((item) => ({
        project_id: item.project_id,
        work_id: item.SK.includes("WORK#") ? item.SK : item.PK,
      }));

      const res = await updateStatusGroupWorkItemApi({
        work_ids: workItemIds,
        is_delete: isDelete,
        project_info: projectInfo,
      });
      if (res?.data) {
        setSelectedWorkItem([]);
        ModalController.show({
          message: isDelete
            ? messages.WORK_ITEM.MSG_DELETE_GROUP_SUCCESS
            : messages.WORK_ITEM.MSG_COMPLETE_GROUP_SUCCESS,
          visibleButton2: true,
          handlePressButton2: () => history.back(),
        });
      }
    } catch (error: any) {
      ModalController.show({
        message: error?.response?.detail.message,
        visibleButton2: true,
      });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // ------------------------------------------------------------------
  // 先頭から選択
  // ------------------------------------------------------------------
  const handleCheckButton = () => {
    let arr: Work[] = [];
    for (let i = 0; i < max_approvals; i++) {
      if (listWorkItem[i]) arr.push(listWorkItem[i]);
    }
    setSelectedWorkItem(arr);
  };

  // ------------------------------------------------------------------
  // 詳細ボタン
  // ------------------------------------------------------------------
  const handleClickDetail = (item: Work) => {
    LoadingOverlayController.show();
    getWorkByIdApi({ project_id: item.project_id, work_id: item.PK })
      .then((res) => {
        if (res.data) {
          setDetailData(res.data);
          setTimeout(() => {
            setOpenDetailDialog(true);
          }, 100);
        }
      })
      .finally(() => {
        LoadingOverlayController.hide();
      });
  };

  // ------------------------------------------------------------------
  // 描画
  // ------------------------------------------------------------------
  useEffect(() => {
    paramsFilter = getParamsFilter(workGroupFilter, typeScreen);
    fetchListWorkItem();
    getMaxApprovals();
  }, []);

  const getMaxApprovals = async () => {
    const res = await getMaxApprovalsApi();
    if (res[0]) {
      setMaxApprovals(Number(res[0].max_approvals));
    }
  };

  const showLoadMore: boolean = useMemo(() => {
    return loadMore && lastKey && listWorkItem.length > 0;
  }, [listWorkItem, loadMore, lastKey]);

  const hasActiveButton = useMemo(() => {
    if (typeAction != "3") {
      return selectedWorkItem.length > 0 && !loading;
    } else {
      return (
        selectedWorkItem.length > 0 &&
        selectedWorkItem.length <= max_approvals &&
        !loading
      );
    }
  }, [selectedWorkItem, loading]);

  const _renderItem = (item: Work) => {
    const checked = getIsCheck(item.work_id);
    const disabled = typeAction === "1" && item.work_status === "FINISH";
    return (
      <Stack direction="row" sx={{ alignItems: "center" }} spacing={0}>
        <Checkbox
          id={"check" + item.SK}
          checked={checked}
          onChange={() => handleSelectItem(item)}
          disabled={disabled}
          sx={{ p: 0, pr: 1 }}
        />
        <WorkItemTlog
          data={item}
          onClick={() => handleSelectItem(item)}
          checked={checked}
          sx={{ flexGrow: 1 }}
          disabled={disabled}
          showPrinted={item.print_2d_barcode}
          showDetailButton={true}
          onClickDetail={() => {
            handleClickDetail(item);
          }}
        />
      </Stack>
    );
  };

  // ------------------------------------------------------------------
  // 並べ替え
  // ------------------------------------------------------------------
  const handleSort = (type_sort: string) => {
    LoadingOverlayController.show();
    const payload = {
      type_screen: typeScreen,
      value: type_sort,
    };
    setSortBy(null);
    setLastKey(null);
    setPK(null);
    dispatch({ type: TYPES.SET_SORT_SCREEN, payload });
    dispatch({ type: TYPES.SET_SORT_CONDITION, payload });
    fetchListWorkItem(undefined, type_sort, "", "");
  };

  const sortDefaultIndex = useMemo(
    () => {
      const defaultSort = Object.values(LIST_TYPE_SORT_WORK).filter(
        (item) => item.value === typeSort,
      );
      if (defaultSort.length === 0) {
        return 0;
      } else {
        return Object.values(LIST_TYPE_SORT_WORK).indexOf(defaultSort[0]);
      }
    },
    [] /* 画面初期表示時のみ判定 */,
  );

  // ------------------------------------------------------------------
  // 絞り込み
  // ------------------------------------------------------------------
  useEffect(() => {
    if (!listWorkItem.length && !hasCondition) {
      setIsDisableFilter(true);
    } else {
      setIsDisableFilter(false);
    }
  }, [listWorkItem]);

  useDeepCompareEffect(() => {
    if (sortBy) {
      setSortBy(null);
    }
    if (lastKey) {
      setLastKey(null);
    }
    if (PK) {
      setPK(null);
    }
    paramsFilter = getParamsFilter(workGroupFilter, typeScreen);
  }, [workGroupFilter]);

  return (
    <GenericTemplate
      title={
        typeAction === "1"
          ? "完了にする作業を選択"
          : typeAction === "2"
          ? "削除する作業を選択"
          : "一括承認する作業を選択"
      }
      isSearch={true}
      showMenu={false}
      searchPlaceholder="帳票番号、品名で検索"
      onSearch={(v) => _handleSearch(v)}
      onSearchClear={() => _handleSearch("")}
    >
      <WorkDetailDialog
        open={openDetailDialog}
        onClose={() => {
          setOpenDetailDialog(false);
        }}
        data={detailData}
      />
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12} md="auto">
          {/* =================== 並べ替え =================== */}
          <PopupSort
            data={Object.values(LIST_TYPE_SORT_WORK).map((item) => {
              return {
                title: item.title,
                onClick: () => handleSort(item.value),
              };
            })}
            defaultIndex={sortDefaultIndex}
            disabled={listWorkItem.length === 0}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md="auto"
          sx={{ width: { md: "40%" }, marginLeft: "auto" }}
        >
          {/* =================== 絞り込み =================== */}
          <FilterInput
            typeScreen={typeScreen}
            disabled={isDisableFilter}
            onFetchData={() => {
              LoadingOverlayController.show();
              fetchListWorkItem(undefined, undefined, "", "");
            }}
          />
        </Grid>
      </Grid>

      {/* =================== 作業 =================== */}
      <InfiniteScroll
        loadMore={() => {
          if (loadMore && lastKey && listWorkItem.length > 0 && !loading) {
            fetchListWorkItem(undefined, undefined, lastKey, undefined, true);
          }
        }}
        hasMore={showLoadMore}
        loader={<ScrollLoader key={0} />}
        style={{ paddingBottom: 50 }}
      >
        <Grid container spacing={3} sx={{ pt: 2 }}>
          {listWorkItem.map((d: Work, i) => {
            return (
              <Grid item xs={12} md={4} lg={4} key={i}>
                {_renderItem(d)}
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
      {listWorkItem.length === 0 && !loading && (
        <Typography>
          {searchedKeyword === ""
            ? messages.COMMON.MSG_NOT_EXIST("作業")
            : messages.COMMON.MSG_NON_SEARCH_RESULT(searchedKeyword)}
        </Typography>
      )}

      {/* =================== ボタン =================== */}
      <Box
        sx={{
          position: "fixed",
          margin: 0,
          right: 0,
          bottom: 20,
          textAlign: "center",
          width: "100%",
        }}
      >
        {typeAction === "3" && (
          <Button
            onClick={handleCheckButton}
            color="secondary"
            size="large"
            sx={{
              mr: 3,
            }}
          >
            先頭から最大まで選択
          </Button>
        )}
        <Button
          onClick={handleActionButton}
          color={typeAction == 1 ? "primary" : "error"}
          size="large"
          disabled={!hasActiveButton}
          sx={{
            "&.Mui-disabled": {
              bgcolor: Colors.DISABLE_INPUT_BG,
            },
          }}
        >
          {typeAction === "1"
            ? "決定"
            : typeAction === "2"
            ? "削除"
            : "一括承認"}
        </Button>
      </Box>
    </GenericTemplate>
  );
};

export default WorkGroupEditStatusScreen;
