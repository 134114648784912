import React, { useCallback, useMemo, useRef, useState, VFC } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalController from "@shared-components/modal/ModalController";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { getTemplateApi } from "@api/template";
import GenericTemplate from "@template/index";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import SearchDialog, {
  initialStateForm,
  IWorkSearchForm,
} from "./SearchDialog";
import { columnsInfo, SEALED_MESSAGE, headCells } from "./ColumnsInfo";
import {
  EXCEL_TEMPLATE_INPUT,
  LEDGER_TYPE,
  screenIdSupport,
} from "@shared-constants";
import {
  STATUS_WORK_TLOG,
  TITLE_STATUS_WORK_TLOG,
  TEMPLATE_EDIT_TYPE,
  IMAGE_EXIST_TYPE,
} from "@shared-constants";
import messages from "config/messages";
import {
  getCompressedTemplatePDF,
  getListWorkSearchApi,
  getMergedTemplatePDF,
} from "@api/work";
import { issueType } from "@utils/template/riskyId";
import {
  SortableTable,
  HeadCell,
  ChildRowProps,
} from "components/organisms/SortableTable/SortableTable";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import { useTheme } from "@mui/system";
import formatDateToString, { convertJPDateTime } from "@utils/DateFormat";
import { RootState } from "store/reducer";
import { downloadXlsx } from "@utils/CSV";
import { TYPES } from "store/types";
import WorkDetailDialog from "./WorkDetailDialog";
import { createObjectURL } from "@utils/index";
import {
  getExcelTemplateCalcText,
  getPageKey,
  getStateFormByTemplate,
  getTemplateKey,
} from "@utils/template/excelTemplate";

const BASE_URL = process.env.REACT_APP_API_URL;

const flow_step_title = "flow_step_";

const LIST_SEARCH_CONDITION_TITLE: { [key: string]: string } = {
  report_type: "帳票種類",
  report_type_name: "",
  project_id: "帳票",
  project_id_name: "",
  applied_at_s: "申請日",
  applied_at_e: "",
  approved_at_s: "最終承認日",
  approved_at_e: "",
  updated_at_s: "最終更新日",
  updated_at_e: "",
  work_status: "帳票状況",
  approver: "承認者",
  author: "申請者",
  template_id: "帳票番号",
  approval_flow: "承認フロー",
  approval_flow_name: "",
  next_user_name: "ネクストプロセス",
  image_exist: "写真添付",
  place_found_issue: "発行元",
  incident_date_s: "発生年月日",
  incident_date_e: "",
  issue_place_floor: "発生場所フロア",
  issue_place_span: "発生場所スパン",
  is_product_issue: "事故区分",
  is_packaging_issue: "",
  responsible_person: "事故当事者",
  person_found_issue: "発見者氏名",
  product_type: "分類",
  product_name: "商品名",
  amount_of_product: "数量",
  product_id: "機番",
  product_packaging_status: "荷姿状態",
  reason_cause_issue: "事故発生理由",
  warehouse_place_floor: "格納場所フロア",
  warehouse_place_span: "格納場所スパン",
  bad_place: "不良ロケ",
  vehicles_frame_no: "車体番号",
  vehicles_workplace: "事業場名",
  vehicles_model: "車種",
  vehicles_fuel_type: "燃料",
  vehicles_name: "車両番号",
  driver: "担当者名",
  year_month: "実施年月",
  check_user: "点検実施者名",
  memo: "備考",
  date_of_delivery_s: "搬入年月日",
  date_of_delivery_e: "",
  container_no: "コンテナ番号",
  container_size: "コンテナサイズ",
  delivery_time: "納入時間",
  carrier_name: "搬入者名",
  unit_class: "Unit区分",
  delivery_location: "搬入場所",
  invoice_no: "インボイスナンバー",
  seal_no: "シールNo.",
  berth_number: "バース番号",
  sealed: "封印",
  product_class: "商品分類",
  model_name: "機種名",
  defective_products: "不適合内容",
  person_worker: "作業者名",
  container_status: "コンテナの状態",
  loading_date: "Loading Date",
  warehouse: "Warehouse",
  load_by: "Load by",
  invoice_no_lr: "Invoice No.",
  booking_no: "Booking No.",
  commodity: "Comomdity",
  container_type: "Container Type",
  container_no_lr: "Container No.",
  seal_no_lr: "Seal No.",
  remark: "Remark",
  model_name_lr: "Model Name",
  qty: "Quantity",
  total_qty: "Total Quantity",
  reserve_status: "Reserve Status",
  ngr_date_s: "Date(YYYY/MM/DD)",
  ngr_date_e: "",
  ngr_time_s: "Time",
  ngr_time_e: "",
  ngr_warehouse: "Warehouse",
  ngr_area: "Area",
  ngr_reported_by: "Reported by",
  ngr_customer: "Customer (Shipper)",
  ngr_commodity: "Comomdity",
  ngr_model_name: "Model Name",
  ngr_quantity: "Quantity",
  ngr_serial_no: "Serial No.",
  ngr_damage_location: "Damage Location",
  ngr_cause: "Cause (Site Opinion)",
  ngr_qa_authorizer: "QA Authorizer",
  ngr_occurred_company: "Occurred Company",
  ngr_invoice_no: "Invoice No.",
  ngr_affiliation_of_employee: "Employee's Affiliation",
  ngr_experience_of_employee_years: "Experience's Employee Years",
  ngr_experience_of_employee_months: "Experience's Employee Months",
  ngr_system_transaction_no: "System Transaction No.",
  ngr_waste_claim_category: "Waste /Claim Category",
  ngr_accident_rank: "Accident Rank",
  ngr_cpar_no: "CPAR No.",
  ngr_nonconformity_detail: "Nonconformity Detail",
  ngr_nonconformity_overview: "Nonconformity Overview",
  ngr_occurance_process: "Occurance Process",
  ngr_process_of_root_cause: "Process of Root Cause",
  ngr_responsibility: "Responsibility",
  ngr_department_of_pic: "Department of PIC",
  ngr_process_in_logistics: "Process in Logistics",
  ngr_responsibility_in_process: "Responsibility in Process",
  ngr_classification_of_nonconformity: "Classification of Nonconformity",
  ngr_classification_of_cause: "Classification of Cause",
  ngr_cause_overview: "Cause Overview",
  ngr_root_cause: "Root Cause",
  ngr_logistics_equipments: "Logistics Equipments",
  ngr_corrective_action_improvement_s: "Corrective Action /Improvement",
  ngr_corrective_action_improvement_e: "",
  ngr_scrap_unit: "Scrap Unit",
  ngr_repair_unit: "Repair Unit",
  ngr_no_problem_unit: "No Problem Unit",
  ngr_final_payer: "Final Payer",
  ngr_expected_payment_month: "Expected Payment Month",
  ngr_est_acc: "Est/Acc",
  ngr_amount_cost_est: "Amount Cost (Est)",
  ngr_closing_date_s: "Closing Date",
  ngr_closing_date_e: "",
  ngr_amount_cost_act: "Amount Cost (Act)",
  ngr_actual_payment_month: "Actual Payment Month",
  ngr_insurance_sub_con_amount: "Insurance ,Sub-con Amount",
  ngr_received_invoice_no: "Received Invoice No. ",
  ngr_remark: "Remark",
  ngr_final_tlgt_amount: "Final TLGT Amount",
  date_ac: "Date",
  time_ac: "Time",
  model_name_ac: "Model Name",
  quantity_ac: "Quantity",
  tl_serial_no: "Serial No.",
  reported_by: "Reported by",
  defect: "Defect",
  damage_location_ac: "Damage Location",
  ctc_qa_judgement: "CTC QA Judgement",
  ctc_qa_inspector_by: "CTC QA Inspector by",
  ctc_checked_date: "CTC Checked Date",
  ctc_ma_repair_by: "CTC MA Repair by",
  ctc_ma_inspector_by: "CTC MA Inspector by",
  ctc_inspection_date: "CTC Inspection Date",
  repaired_date: "Repaired Date",
  repaired_ac_receive_by: "Repaired AC Received by",
  returning_date: "Returning Date",
  returning_time: "Returning Time",
  edr_date_s: "日付",
  edr_arrival_date_s: "入荷日",
  edr_location: "外装破損発見場所",
  edr_written_by: "庫内記入者",
  edr_checked_by: "確認者",
  edr_jan_code: "JANコード",
  edr_supplier_code: "仕入先コード",
  edr_supplier_name: "仕入先名",
  edr_product_name: "商品名",
  edr_product_type: "型名",
  edr_total: "総外装破損数量",
  edr_status: "外装破損状況",
  edr_purchasing: "仕入れました",
  edr_order_customer_count: "お客様注文商品数量",
  edr_order_replenishment_count: "補充良品、割当商品数量",
  edr_non_purchasing: "仕入不可",
  edr_non_purchasing_count: "仕入不可商品数量",
  edr_item_check_user: "現品確認",
  edr_store_check_user: "格納確認",
  edr_ledger_type: "帳票種類",
  attached_file_exist: "ファイル添付",
};

interface IRefSortableTable {
  getSortedRow: () => any[];
}

const WorkSearchTlogScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [data, setData] = useState<any[]>([]);
  const [listButtons, setListButtons] = useState<Array<any>>([]);
  const [lastKey, setLastKey] = useState<any | null>(null);
  const [loadMore, setLoadMore] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const check_list = useSelector(
    (state: RootState) => state.tableCustom.check_list,
  );
  const [screenId, setScreenId] = useState<string>("");
  const [openDialog, setOpenDialog] = useState(false);
  const [workSearchForm, setWorkSearchForm] =
    useState<IWorkSearchForm>(initialStateForm);
  const [page, setPage] = useState<number>(0);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [detailData, setDetailData] = useState<any>();
  const dispatch = useDispatch();
  const [selectedHeadCells, setSelectedHeadCells] = useState<HeadCell[]>([]);
  const [childRow, setChildRow] = useState<ChildRowProps>();
  const ref = useRef<IRefSortableTable>();

  // ------------------------------------------------------------------
  // データ取得
  // ------------------------------------------------------------------
  const fetchListWorkItem = async (
    filter_condition?: IWorkSearchForm,
    lastKeyword?: string,
    sort_by?: string | null,
    isMerge: boolean = false,
  ) => {
    LoadingOverlayController.show();

    const filter = filter_condition ?? workSearchForm;
    //帳票ごとの検索項目を追加する必要がある
    let params = {
      BASE_URL: String(BASE_URL),
      screen_id: screenId,
      project_id: filter.project_id,
      applied_at_s: convertParamsDate(filter.applied_at_s, "start"),
      applied_at_e: convertParamsDate(filter.applied_at_e, "end"),
      approved_at_s: convertParamsDate(filter.approved_at_s, "start"),
      approved_at_e: convertParamsDate(filter.approved_at_e, "end"),
      updated_at_s: convertParamsDate(filter.updated_at_s, "start"),
      updated_at_e: convertParamsDate(filter.updated_at_e, "end"),
      work_status: filter.work_status
        .filter((d) => d.isSelected)
        .map((d) => d.id)
        .join(","),
      approver: filter.approver.map((d) => d.id).join(","),
      author: filter.author.map((d) => d.id).join(","),
      template_id: filter.template_id,
      approval_flow: filter.approval_flow,
      next_user: filter.next_user,
      place_found_issue: filter.place_found_issue,
      incident_date_s: filter.incident_date_s,
      incident_date_e: filter.incident_date_e,
      issue_place_floor: filter.issue_place_floor.map((d) => d.id).join(","),
      issue_place_span: filter.issue_place_span.map((d) => d.id).join(","),
      is_product_issue: filter.is_product_issue ? true : undefined,
      is_packaging_issue: filter.is_packaging_issue ? true : undefined,
      responsible_person: filter.responsible_person,
      person_found_issue: filter.person_found_issue,
      product_type: filter.product_type,
      product_name: filter.product_name,
      amount_of_product: filter.amount_of_product,
      product_id: filter.product_id,
      product_packaging_status: filter.product_packaging_status,
      reason_cause_issue: filter.reason_cause_issue,
      warehouse_place_floor: filter.warehouse_place_floor
        .map((d) => d.id)
        .join(","),
      warehouse_place_span: filter.warehouse_place_span
        .map((d) => d.id)
        .join(","),
      bad_place: filter.bad_place,
      vehicles_frame_no: filter.vehicles_frame_no,
      vehicles_workplace: filter.vehicles_workplace,
      vehicles_model: filter.vehicles_model,
      vehicles_fuel_type: filter.vehicles_fuel_type,
      vehicles_name: filter.vehicles_name,
      driver: filter.driver,
      year_month: filter.year_month,
      check_user: filter.check_user,
      memo: filter.memo,
      last_key: lastKeyword ?? lastKey,
      sort_by: sort_by ?? sortBy,
      date_of_delivery_s: filter.date_of_delivery_s,
      date_of_delivery_e: filter.date_of_delivery_e,
      container_no: filter.container_no,
      container_size: filter.container_size,
      delivery_time: filter.delivery_time,
      carrier_name: filter.carrier_name,
      unit_class: filter.unit_class,
      delivery_location: filter.delivery_location,
      invoice_no: filter.invoice_no,
      seal_no: filter.seal_no,
      berth_number: filter.berth_number,
      sealed: filter.sealed,
      product_class: filter.product_class
        .replace(/^\s+|\s+$/g, "")
        .replace(/\s*,\s*/g, ","),
      model_name: filter.model_name,
      defective_products: filter.defective_products.map((d) => d.id).join(","),
      person_worker: filter.person_worker,
      container_status: filter.container_status.map((d) => d.id).join(","),
      loading_date: filter.loading_date,
      warehouse: filter.warehouse,
      load_by: filter.load_by,
      invoice_no_lr: filter.invoice_no_lr,
      booking_no: filter.booking_no,
      commodity: filter.commodity,
      container_type: filter.container_type,
      container_no_lr: filter.container_no_lr,
      seal_no_lr: filter.seal_no_lr,
      remark: filter.remark,
      model_name_lr: filter.model_name_lr,
      qty: filter.qty,
      total_qty: filter.total_qty,
      reserve_status: filter.reserve_status,
      ngr_date_s: filter.ngr_date_s,
      ngr_date_e: filter.ngr_date_e,
      ngr_time_s: filter.ngr_time_s,
      ngr_time_e: filter.ngr_time_e,
      ngr_warehouse: filter.ngr_warehouse.map((d) => d.id).join(","),
      ngr_area: filter.ngr_area.map((d) => d.id).join(","),
      ngr_reported_by: filter.ngr_reported_by,
      ngr_customer: filter.ngr_customer.map((d) => d.id).join(","),
      ngr_commodity: filter.ngr_commodity.map((d) => d.id).join(","),
      ngr_model_name: filter.ngr_model_name,
      ngr_quantity: filter.ngr_quantity,
      ngr_serial_no: filter.ngr_serial_no,
      ngr_damage_location: filter.ngr_damage_location
        .map((d) => d.id)
        .join(","),
      ngr_cause: filter.ngr_cause,
      ngr_qa_authorizer: filter.ngr_qa_authorizer.map((d) => d.id).join(","),
      ngr_occurred_company: filter.ngr_occurred_company
        .map((d) => d.id)
        .join(","),
      ngr_invoice_no: filter.ngr_invoice_no,
      ngr_affiliation_of_employee: filter.ngr_affiliation_of_employee
        .map((d) => d.id)
        .join(","),
      ngr_experience_of_employee_years: filter.ngr_experience_of_employee_years,
      ngr_experience_of_employee_months:
        filter.ngr_experience_of_employee_months,
      ngr_system_transaction_no: filter.ngr_system_transaction_no,
      ngr_waste_claim_category: filter.ngr_waste_claim_category
        .map((d) => d.id)
        .join(","),
      ngr_accident_rank: filter.ngr_accident_rank.map((d) => d.id).join(","),
      ngr_cpar_no: filter.ngr_cpar_no,
      ngr_nonconformity_detail: filter.ngr_nonconformity_detail
        .map((d) => d.id)
        .join(","),
      ngr_nonconformity_overview: filter.ngr_nonconformity_overview,
      ngr_occurance_process: filter.ngr_occurance_process,
      ngr_process_of_root_cause: filter.ngr_process_of_root_cause,
      ngr_responsibility: filter.ngr_responsibility,
      ngr_department_of_pic: filter.ngr_department_of_pic,
      ngr_process_in_logistics: filter.ngr_process_in_logistics,
      ngr_responsibility_in_process: filter.ngr_responsibility_in_process,
      ngr_classification_of_nonconformity:
        filter.ngr_classification_of_nonconformity,
      ngr_classification_of_cause: filter.ngr_classification_of_cause,
      ngr_cause_overview: filter.ngr_cause_overview,
      ngr_root_cause: filter.ngr_root_cause,
      ngr_logistics_equipments: filter.ngr_logistics_equipments
        .map((d) => d.id)
        .join(","),
      ngr_corrective_action_improvement:
        filter.ngr_corrective_action_improvement,
      ngr_completion_improvement_date_s:
        filter.ngr_completion_improvement_date_s,
      ngr_completion_improvement_date_e:
        filter.ngr_completion_improvement_date_e,
      ngr_scrap_unit: filter.ngr_scrap_unit,
      ngr_repair_unit: filter.ngr_repair_unit,
      ngr_no_problem_unit: filter.ngr_no_problem_unit,
      ngr_final_payer: filter.ngr_final_payer.map((d) => d.id).join(","),
      ngr_expected_payment_month: filter.ngr_expected_payment_month
        .map((d) => d.name)
        .join(","),
      ngr_est_acc: filter.ngr_est_acc.map((d) => d.id).join(","),
      ngr_amount_cost_est: filter.ngr_amount_cost_est,
      ngr_closing_date_s: filter.ngr_closing_date_s,
      ngr_closing_date_e: filter.ngr_closing_date_e,
      ngr_amount_cost_act: filter.ngr_amount_cost_act,
      ngr_actual_payment_month: filter.ngr_actual_payment_month
        .map((d) => d.name)
        .join(","),
      ngr_insurance_sub_con_amount: filter.ngr_insurance_sub_con_amount,
      ngr_received_invoice_no: filter.ngr_received_invoice_no,
      ngr_remark: filter.ngr_remark,
      ngr_final_tlgt_amount: filter.ngr_final_tlgt_amount,
      date_ac: filter.date_ac,
      time_ac: filter.time_ac,
      model_name_ac: filter.model_name_ac,
      quantity_ac: filter.quantity_ac,
      tl_serial_no: filter.tl_serial_no,
      reported_by: filter.reported_by,
      defect: filter.defect,
      damage_location_ac: filter.damage_location_ac,
      ctc_qa_judgement: filter.ctc_qa_judgement,
      ctc_qa_inspector_by: filter.ctc_qa_inspector_by,
      ctc_checked_date: filter.ctc_checked_date,
      ctc_ma_repair_by: filter.ctc_ma_repair_by,
      ctc_ma_inspector_by: filter.ctc_ma_inspector_by,
      ctc_inspection_date: filter.ctc_inspection_date,
      repaired_date: filter.repaired_date,
      repaired_ac_receive_by: filter.repaired_ac_receive_by,
      returning_date: filter.returning_date,
      returning_time: filter.returning_time,
      image_exist: filter.image_exist,
      edr_date_s: filter.edr_date_s,
      edr_date_e: filter.edr_date_e,
      edr_arrival_date_s: filter.edr_arrival_date_s,
      edr_arrival_date_e: filter.edr_arrival_date_e,
      edr_location: filter.edr_location,
      edr_written_by: filter.edr_written_by,
      edr_checked_by: filter.edr_checked_by,
      edr_jan_code: filter.edr_jan_code,
      edr_supplier_code: filter.edr_supplier_code,
      edr_supplier_name: filter.edr_supplier_name,
      edr_product_name: filter.edr_product_name,
      edr_product_type: filter.edr_product_type,
      edr_total: filter.edr_total,
      edr_status: filter.edr_status,
      edr_purchasing: filter.edr_purchasing,
      edr_order_customer_count: filter.edr_order_customer_count,
      edr_order_replenishment_count: filter.edr_order_replenishment_count,
      edr_non_purchasing: filter.edr_non_purchasing,
      edr_non_purchasing_count: filter.edr_non_purchasing_count,
      edr_item_check_user: filter.edr_item_check_user,
      edr_store_check_user: filter.edr_store_check_user,
      edr_ledger_type: filter.edr_ledger_type,
      attached_file_exist: filter.attached_file_exist,
    };
    return await getListWorkSearchApi(params)
      .then((res) => {
        const newData = getTableDataList(
          isMerge ? data.concat(res?.data) : res?.data,
        );
        const newChildRow = getTableChildData(
          isMerge ? data.concat(res?.data) : res?.data,
        );
        setData(newData);
        setChildRow(newChildRow);
        const list_buttons = newData.map((item) => ({
          cell_id: "btn_detail",
          button: (
            <Button
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setOpenDetailDialog(true);
                setDetailData(item);
              }}
            >
              詳細
            </Button>
          ),
          id: item.rowNum,
        }));
        setListButtons(list_buttons);

        if (res?.last_key) {
          setLastKey(res?.last_key?.SK ?? null);
          setSortBy(res?.last_key?.sort_by ?? null);
          setLoadMore(true);
        } else {
          setLastKey(null);
          setSortBy(null);
          setLoadMore(false);
        }
        if (res?.length < 10 && res.last_key) {
          fetchListWorkItem(
            filter_condition,
            res?.last_key?.SK,
            res?.last_key?.sort_by,
            true,
          );
        }
        setLoaded(true);
        return newData.length;
      })
      .finally(() => {
        LoadingOverlayController.hide();
      });
  };

  const convertParamsDate = useCallback(
    (date: string, type: "end" | "start") => {
      if (!date) return date;
      const time =
        type === "start" ? "T00:00:00" : type === "end" ? "T23:59:59" : "";
      return formatDateToString(convertJPDateTime(date + time), "YMDTHms");
    },
    [],
  );

  const getTableDataList = useCallback((data: Array<any>, add_item?: any) => {
    return data.map((item: any, index: number) => {
      // 添付写真列の表示用文字列
      let data_is_image_array_text = {};
      if (item.is_image_array !== undefined) {
        data_is_image_array_text = {
          is_image_array_text: item.is_image_array ? "○" : "",
        };
      }
      // 商品分類の表示用文字列を取得
      let data_product_class_text = {};
      if (item.model_info && item.model_info.length > 0) {
        data_product_class_text = {
          product_class_text: item.model_info
            .map((info: any) => info.product_class)
            .join(", "),
        };
      }
      // 添付ファイル列の表示用文字列(ユーザー作成帳票)
      let data_is_attached_file_text = {};
      if (item.is_attached_file !== undefined) {
        data_is_attached_file_text = {
          is_attached_file_text: item.is_attached_file ? "○" : "",
        };
      }
      return {
        ...item,
        applied_at:
          item?.work_status === STATUS_WORK_TLOG.DRAFT
            ? ""
            : formatDateToString(item?.applied_at, "YMDHHmm", true),
        work_status_name:
          TITLE_STATUS_WORK_TLOG[item?.work_status.replace(" ", "_")],
        rowNum: index,
        ...data_is_image_array_text,
        ...data_product_class_text,
        ...data_is_attached_file_text,
        ...add_item,
      };
    });
  }, []);

  const getTableChildData = useCallback((data: Array<any>): ChildRowProps => {
    var rows: Array<any> = [];
    var headCells: HeadCell[] = [
      { id: "step", label: "承認ステップ", sortable: true, width: 100 },
      { id: "user_name", label: "承認者", sortable: true, width: 250 },
      { id: "approved_time", label: "承認日時", sortable: true, width: 250 },
    ];
    data.forEach((item, index) => {
      item?.flow_approve_data?.forEach((flow: any) => {
        rows.push({
          rowNum: index,
          approved_time: formatDateToString(
            flow?.approved_time,
            "YMDHHmm",
            true,
          ),
          step: `ステップ${flow.user_in_step + 1}`,
          user_name: flow.user_name,
        });
      });
    });
    return { rows, headCells, tableWidth: "initial" };
  }, []);

  // ------------------------------------------------------------------
  // CSV出力
  // ------------------------------------------------------------------
  const handleCSV = useCallback(async () => {
    var all_data: any[] = [];
    var max_flow = 0;
    data
      .filter((item) => check_list.some((rowNum) => item.rowNum == rowNum))
      .forEach((item) => {
        if (item?.csv_item) {
          // csv用データがある場合
          all_data = all_data.concat(getTableDataList(item?.csv_item));
        } else {
          // 画面表示内容と同じ場合
          all_data.push(item);
        }
      });
    const csv_data = all_data.map((item) => {
      const { flow_approve_data, max_step } = getFlowApprovedData(item);
      if (max_flow < max_step) max_flow = max_step;
      return {
        ...item,
        report_type: workSearchForm.report_type_name,
        project_id: workSearchForm.project_id_name,
        approved_at: formatDateToString(item?.approved_at, "YMDHHmm", true),
        updated_at: formatDateToString(item?.updated_at, "YMDHHmm", true),
        is_image_exist: item?.is_image_array ? "◯" : "",
        is_attached_file: item?.is_attached_file ? "◯" : "",
        ...flow_approve_data,
      };
    });

    let columns = [...columnsInfo[screenIdSupport[screenId]]];
    // 承認ステップ列を追加
    for (let index = 0; index <= max_flow; index++) {
      columns.push({
        key: `${flow_step_title}${index}`,
        header: `承認ステップ${index + 1}`,
      });
    }

    // 帳票別の処理
    switch (screenIdSupport[screenId]) {
      case TEMPLATE_EDIT_TYPE.RISKY_REPORT:
        riskyReport(csv_data);
        break;
      case TEMPLATE_EDIT_TYPE.INSPECTION_REPORT:
        let copyColumns = [...columns];
        let newColumns = await inspectionReport(csv_data, copyColumns);
        columns = newColumns;
        break;
      case TEMPLATE_EDIT_TYPE.TEMPLATE_FORKLIFT_REPORT:
        await forkliftReport(csv_data);
        break;
      case TEMPLATE_EDIT_TYPE.TEMPLATE_EXCEL_REPORT:
        columns = [...columns];
        const list_columns_input = [
          EXCEL_TEMPLATE_INPUT.TEXT,
          EXCEL_TEMPLATE_INPUT.MASTER,
          EXCEL_TEMPLATE_INPUT.DATE,
          EXCEL_TEMPLATE_INPUT.REPORT_NO,
          EXCEL_TEMPLATE_INPUT.USER_NAME,
          EXCEL_TEMPLATE_INPUT.CALC,
          EXCEL_TEMPLATE_INPUT.NUMBER,
          EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM,
          EXCEL_TEMPLATE_INPUT.RADIO,
          EXCEL_TEMPLATE_INPUT.TIME,
          EXCEL_TEMPLATE_INPUT.BARCODE,
          EXCEL_TEMPLATE_INPUT.CHECKBOX,
          EXCEL_TEMPLATE_INPUT.INPUT_GROUP,
        ];
        const data_info_list = [data[0].data_info].concat(
          data[0].extra_template,
        );
        data_info_list.forEach((data_info: any, template_no) => {
          // 最大ページ数を取得
          let max_page = 1;
          const page_key = getTemplateKey("page", template_no);
          csv_data.forEach((value) => {
            if (value[page_key] && Number(value[page_key]) > max_page) {
              max_page = Number(value[page_key]);
            }
          });
          // ヘッダを設定
          for (let index = 0; index < max_page; index++) {
            data_info.forEach((v: any) => {
              if (list_columns_input.includes(v.input)) {
                columns.push({
                  key: getTemplateKey(
                    getPageKey(v.coordinate, index + 1),
                    template_no,
                  ),
                  header: v.name + (index == 0 ? "" : `(${index + 1}ページ)`),
                });
              }
            });
          }
          // 出力データ整形
          csv_data.forEach((csv) => {
            let tmpStateForm = getStateFormByTemplate(template_no, csv);
            const all_page = tmpStateForm["page"] ?? 1;
            for (let index = 0; index < all_page; index++) {
              data_info.forEach((v: any) => {
                if (v.input === EXCEL_TEMPLATE_INPUT.CALC) {
                  // 計算
                  const coordinate = getPageKey(v.coordinate, index + 1);
                  tmpStateForm[coordinate] = getExcelTemplateCalcText(
                    tmpStateForm,
                    v,
                    data_info,
                    index === 0 ? undefined : index + 1,
                  );
                }
              });
            }
            tmpStateForm = Object.keys(tmpStateForm).map((key) => {
              const newKey = getTemplateKey(key, template_no);
              return { [newKey]: tmpStateForm[key] };
            });
            csv = Object.assign(csv, ...tmpStateForm);
          });
        });
        break;
      case TEMPLATE_EDIT_TYPE.TEMPLATE_INCIDENT_DAMAGE_REPORT:
        let ngrCopyColumns = [...columns];
        let ngrNewColumns = await incidentDamegeReport(
          csv_data,
          ngrCopyColumns,
        );
        columns = ngrNewColumns;
        break;
      case TEMPLATE_EDIT_TYPE.TEMPLATE_RECEIVING_PROBLEM_TAG:
        receivingProblemTag(csv_data);
        break;
      case TEMPLATE_EDIT_TYPE.EXTERIOR_DAMAGED_TC_REPORT:
      case TEMPLATE_EDIT_TYPE.EXTERIOR_DAMAGED_DC_REPORT:
        exteriorDamagedReport(csv_data, columns);
        break;
    }

    const sorted_csv_data = sortByRiskyId(csv_data);

    downloadXlsx({
      data: sorted_csv_data,
      columns: columns,
      filename: `帳票一覧_${formatDateToString(new Date(), "YMDHms")}.csv`,
    });
  }, [check_list, data]);

  // ------------------------------------------------------------------
  // Excel 出力
  // ------------------------------------------------------------------
  const handelExcel = useCallback(async () => {
    let all_data: any[] = [];
    var max_flow = 0;
    data
      .filter((item) => check_list.some((rowNum) => item.rowNum == rowNum))
      .forEach((item) => {
        if (item?.csv_item) {
          // csv用データがある場合
          all_data = all_data.concat(getTableDataList(item?.csv_item));
        } else {
          // 画面表示内容と同じ場合
          all_data.push(item);
        }
      });
    const excel_data = all_data.map((item) => {
      const { flow_approve_data, max_step } = getFlowApprovedData(item);
      if (max_flow < max_step) max_flow = max_step;
      return {
        ...item,
        report_type: workSearchForm.report_type_name,
        project_id: workSearchForm.project_id_name,
        approved_at: formatDateToString(item?.approved_at, "YMDHHmm", true),
        updated_at: formatDateToString(item?.updated_at, "YMDHHmm", true),
        is_image_exist: item?.is_image_array ? "◯" : "",
        is_attached_file: item?.is_attached_file ? "◯" : "",
        ...flow_approve_data,
      };
    });

    let columns = [...columnsInfo[screenIdSupport[screenId]]];
    // 承認ステップ列を追加
    for (let index = 0; index <= max_flow; index++) {
      columns.push({
        key: `${flow_step_title}${index}`,
        header: `承認ステップ${index + 1}`,
      });
    }

    // 帳票別の処理
    switch (screenIdSupport[screenId]) {
      case TEMPLATE_EDIT_TYPE.RISKY_REPORT:
        riskyReport(excel_data);
        break;
      case TEMPLATE_EDIT_TYPE.INSPECTION_REPORT:
        let copyColumns = [...columns];
        let newColumns = await inspectionReport(excel_data, copyColumns);
        columns = newColumns;
        break;
      case TEMPLATE_EDIT_TYPE.TEMPLATE_FORKLIFT_REPORT:
        await forkliftReport(excel_data);
        break;
      case TEMPLATE_EDIT_TYPE.TEMPLATE_EXCEL_REPORT:
        columns = [...columns];
        const list_columns_input = [
          EXCEL_TEMPLATE_INPUT.TEXT,
          EXCEL_TEMPLATE_INPUT.MASTER,
          EXCEL_TEMPLATE_INPUT.DATE,
          EXCEL_TEMPLATE_INPUT.REPORT_NO,
          EXCEL_TEMPLATE_INPUT.USER_NAME,
          EXCEL_TEMPLATE_INPUT.CALC,
          EXCEL_TEMPLATE_INPUT.NUMBER,
          EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM,
          EXCEL_TEMPLATE_INPUT.RADIO,
          EXCEL_TEMPLATE_INPUT.TIME,
          EXCEL_TEMPLATE_INPUT.BARCODE,
          EXCEL_TEMPLATE_INPUT.CHECKBOX,
          EXCEL_TEMPLATE_INPUT.INPUT_GROUP,
        ];
        const data_info_list = [data[0].data_info].concat(
          data[0].extra_template,
        );
        data_info_list.forEach((data_info: any, template_no) => {
          // 最大ページ数を取得
          let max_page = 1;
          const page_key = getTemplateKey("page", template_no);
          excel_data.forEach((value) => {
            if (value[page_key] && Number(value[page_key]) > max_page) {
              max_page = Number(value[page_key]);
            }
          });
          // ヘッダを設定
          for (let index = 0; index < max_page; index++) {
            data_info.forEach((v: any) => {
              if (list_columns_input.includes(v.input)) {
                columns.push({
                  key: getTemplateKey(
                    getPageKey(v.coordinate, index + 1),
                    template_no,
                  ),
                  header: v.name + (index == 0 ? "" : `(${index + 1}ページ)`),
                });
              }
            });
          }
          // 出力データ整形
          excel_data.forEach((excel) => {
            let tmpStateForm = getStateFormByTemplate(template_no, excel);
            const all_page = tmpStateForm["page"] ?? 1;
            for (let index = 0; index < all_page; index++) {
              data_info.forEach((v: any) => {
                if (v.input === EXCEL_TEMPLATE_INPUT.CALC) {
                  // 計算
                  const coordinate = getPageKey(v.coordinate, index + 1);
                  tmpStateForm[coordinate] = getExcelTemplateCalcText(
                    tmpStateForm,
                    v,
                    data_info,
                    index === 0 ? undefined : index + 1,
                  );
                }
              });
            }
            tmpStateForm = Object.keys(tmpStateForm).map((key) => {
              const newKey = getTemplateKey(key, template_no);
              return { [newKey]: tmpStateForm[key] };
            });
            excel = Object.assign(excel, ...tmpStateForm);
          });
        });
        break;
      case TEMPLATE_EDIT_TYPE.TEMPLATE_INCIDENT_DAMAGE_REPORT:
        let ngrCopyColumns = [...columns];
        let ngrNewColumns = await incidentDamegeReport(
          excel_data,
          ngrCopyColumns,
        );
        columns = ngrNewColumns;
        break;
      case TEMPLATE_EDIT_TYPE.TEMPLATE_RECEIVING_PROBLEM_TAG:
        receivingProblemTag(excel_data);
        break;
      case TEMPLATE_EDIT_TYPE.EXTERIOR_DAMAGED_TC_REPORT:
      case TEMPLATE_EDIT_TYPE.EXTERIOR_DAMAGED_DC_REPORT:
        exteriorDamagedReport(excel_data, columns);
        break;
    }

    const sorted_excel_data = sortByRiskyId(excel_data);

    downloadXlsx({
      data: sorted_excel_data,
      columns: columns,
      format: "xlsx",
      filename: `帳票一覧_${formatDateToString(new Date(), "YMDHms")}.xlsx`,
    });
  }, [check_list, data]);

  const addDefectiveProductsColumns = (count: number, columns: any) => {
    for (let i = 0; i < count; i++) {
      let j = i + 1;
      let col = {
        key: "defective_products" + i,
        header: "製品の状態・不適合数内容" + j + "数量",
      };
      //備考と異常報告の間に追加
      columns.splice(-3, 0, col);
    }
  };

  const addPhotosColumns = (count: number, columns: any) => {
    for (let i = 0; i < count; i++) {
      let j = i + 1;
      let col = {
        key: "photo" + i,
        header: "Photo" + i,
      };
      //後ろに追加
      columns.push(col);
    }
  };

  const addDefectiveProducts = (data: any, defectiveProducts: any) => {
    for (let i = 0; i < defectiveProducts.length; i++) {
      let key = "defective_products" + i;
      data[key] =
        defectiveProducts[i]?.defect + ":" + defectiveProducts[i]?.count;
    }
  };

  const addPhoto = (data: any, imageArray: any) => {
    for (let i = 0; i < imageArray.length; i++) {
      let key = "photo" + i;
      data[key] = imageArray[i];
    }
  };

  const formatYearMonth = (date: any) => {
    var targetDate = date instanceof Date ? date : new Date(date);
    var year = targetDate.getFullYear();
    var month = targetDate.getMonth() + 1; // 月は0-indexedなので+1する
    var month_string = month < 10 ? "0" + String(month) : String(month);
    var formattedDate = year + "年" + month_string + "月";
    return formattedDate;
  };

  const riskyReport = (csv_data: any) => {
    csv_data.forEach((data: any) => {
      data.incident_date = formatDateToString(data?.incident_date, "YMD_sl");
      data.date_shipper_take = formatDateToString(
        data?.date_shipper_take,
        "YMD_sl",
      );
      data.completion_confirm_date = formatDateToString(
        data?.completion_confirm_date,
        "YMD_sl",
      );
    });
  };

  const forkliftReport = (csv_data: any) => {
    csv_data.forEach((data: any, index: number) => {
      [...Array(31)].forEach((_, i) => {
        const key = `result_${i + 1}`;
        if (Object.keys(data).includes(key)) {
          //結果の表示を変更
          if (data[key]) {
            data[key] = "〇";
          } else if (data[key] === null) {
            data[key] = "-";
          } else {
            data[key] = "×";
          }
        } else {
          data[key] = "";
        }
      });
      //実施日付の表示を変更
      if (data.year_month) {
        data.year_month = formatYearMonth(data.year_month);
      }
    });
  };

  const inspectionReport = (csv_data: any, columns: any) => {
    let defectiveProductsCount = 0;

    // 異常報告の作成
    const riskyIds = [
      ...new Set(Array.from(csv_data, ({ risky_id }) => risky_id)),
    ];
    riskyIds.forEach((rid: string) => {
      const targets = csv_data.filter((data: any) => data.risky_id === rid);
      const modelInfo = targets.map((t: any) => {
        return t.model_info;
      });

      const memoList = modelInfo.map((item: any) => {
        if (item?.model_name && item?.total_number && item?.memo) {
          const memo = item.memo.split("\n").join("");
          return item.model_name + " " + item.total_number + "台:" + memo;
        }
      });

      const JOIN_TXT = "　";
      targets.forEach((t: any) => {
        t.unit_abnomality_report = memoList
          .join(JOIN_TXT)
          .replace(/,/g, "")
          .trim();
        t.unit_abnomality_report +=
          (t.unit_abnomality_report.length > 0 ? JOIN_TXT : "") +
          t?.abnomality_report;
      });
    });

    csv_data.forEach((data: any, index: number) => {
      if (data.model_info) {
        data = {
          ...data,
          ...data.model_info,
        };
      }
      if (data.model_info?.defective_products.length > 0) {
        //不適合内容にkey(defective_products+連番をふる)
        addDefectiveProducts(data, data.model_info.defective_products);
      }
      if (
        data?.model_info?.defective_products.length > defectiveProductsCount
      ) {
        //csvダウンロードするレコード中の不適合内容の最大値を取得
        defectiveProductsCount = data?.model_info?.defective_products.length;
      }
      //封印の表記を変更
      if (data.sealed) {
        data.sealed = SEALED_MESSAGE.TRUE;
      } else {
        data.sealed = SEALED_MESSAGE.FALSE;
      }
      //V.2段積み冷蔵庫、3段積み洗濯機は2人作業を実施
      if (data.bool_attention) {
        data.bool_attention = "✓";
      } else {
        data.bool_attention = "";
      }
      //入荷予定情報・機種名
      data["model_name_1"] = data["model_name"];

      //コンテナの状態
      if (data.container_status && data.container_status.length) {
        let container_status = data.container_status
          .map((d: any) => d.container_status_name)
          .join(",");
        data.container_status = container_status;
      } else {
        data.container_status = null;
      }
      csv_data[index] = data;
    });

    //不適合内容の分カラムを追加
    addDefectiveProductsColumns(defectiveProductsCount, columns);
    return columns;
  };

  const incidentDamegeReport = (csv_data: any, columns: any) => {
    //添付可能な写真の最大枚数が3なので、imageArrayCountを3に設定する
    let imageArrayCount = 3;
    csv_data.forEach((data: any, index: number) => {
      if (data.image_array?.length > 0) {
        //imageArrayにkey(photo+連番をふる)
        addPhoto(data, data.image_array);
      }
      if (data.image_array?.length > imageArrayCount) {
        //csvダウンロードするレコード中のimageArrayの最大値を取得
        imageArrayCount = data.image_array.length;
      }
      data.serial_no_n =
        data?.serial_no && Array.isArray(data.serial_no)
          ? data.serial_no.join(",")
          : data?.serial_no;
    });

    //不適合内容の分カラムを追加
    addPhotosColumns(imageArrayCount, columns);
    return columns;
  };

  const receivingProblemTag = (csv_data: any) => {
    csv_data.forEach((data: any) => {
      data.tl_serial_no_n =
        data?.tl_serial_no && Array.isArray(data.tl_serial_no)
          ? data.tl_serial_no.join(",")
          : data?.tl_serial_no;
      data.qa_serial_no =
        data?.qa_serial_no && Array.isArray(data.qa_serial_no)
          ? data.qa_serial_no.join(",")
          : data?.qa_serial_no;
    });
  };

  const exteriorDamagedReport = (csv_data: any, columns: any) => {
    csv_data.forEach((data: any) => {
      [...Array(6)].forEach((_, i) => {
        const key = `status_${i + 1}`;
        if (key in data) {
          data[key] = data[key] ? "✓" : "";
        }
      });
      data.purchasing = data?.purchasing ? "✓" : "";
      data.non_purchasing = data?.non_purchasing ? "✓" : "";
      data.project_id = data?.project_name;
    });
  };

  const getFlowApprovedData = (data: any) => {
    let max_step = 0;
    let response: any = {};
    // 承認ステップ毎に承認アカウントをまとめる
    data?.flow_approve_data?.forEach((flow: any) => {
      if (max_step < flow.user_in_step) max_step = flow.user_in_step;
      const approved_time = formatDateToString(
        flow?.approved_time,
        "YMDHHmm",
        true,
      );
      const key = `${flow_step_title}${flow.user_in_step}`;
      let step_text = `${flow?.user_name}(${approved_time})`;
      if (response[key]) step_text = response[key] + "、" + step_text;
      response = {
        ...response,
        [key]: step_text,
      };
    });
    return { flow_approve_data: response, max_step };
  };

  const sortByRiskyId = (dataSource: any) => {
    const sorted_data = dataSource.map((data: any) => {
      const seqIdx = data.risky_id.lastIndexOf("-");
      const tmpSequenceForSort: number = +data.risky_id.slice(seqIdx+1);
      data.tmp_sequence = tmpSequenceForSort;
      return data;
    }).sort((a:any, b:any) => a.tmp_sequence - b.tmp_sequence);

    sorted_data.forEach((data:any) => delete data.tmp_sequence);
    return sorted_data;
  }

  // ------------------------------------------------------------------
  // PDF出力
  // ------------------------------------------------------------------
  const handlePDF = async () => {
    try {
      LoadingOverlayController.show();
      // zipファイル取得
      let list_path_pdf: string[] = [];
      let list_file_name: string[] = [];
      data
        .filter((item) => check_list.some((rowNum) => item.rowNum == rowNum))
        .forEach((item) => {
          list_path_pdf.push(item.path_pdf);
          list_file_name.push(item.risky_id + ".pdf");
        });
      const response = await getCompressedTemplatePDF({
        list_path_pdf,
        list_file_name,
      });
      // zipダウンロード
      const a = document.createElement("a");
      const url = createObjectURL(response);
      a.href = url;
      a.download = `帳票一覧_${formatDateToString(new Date(), "YMDHms")}.zip`;
      a.click();
      a.remove();
      LoadingOverlayController.hide();
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);
    } catch (e) {
      console.log(e);
      LoadingOverlayController.hide();
    }
  };

  // ------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------
  const _handleSearch = (d: IWorkSearchForm) => {
    if (screenIdSupport[screenId] in headCells) {
      dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: [] });
      setSelectedHeadCells(headCells[screenIdSupport[screenId]]);
      fetchListWorkItem(d, "", "");
      setWorkSearchForm(d);
      setPage(0);
    } else {
      ModalController.show({
        message: messages.PROJECT.MSG_PROJECT_IS_INVALID,
        visibleButton2: true,
      });
    }
  };

  const getSearchConditionTitle = useCallback((field: string) => {
    return LIST_SEARCH_CONDITION_TITLE[field] + " : ";
  }, []);

  const SearchCondition = useMemo(() => {
    return workSearchForm
      ? Object.keys(workSearchForm).map((key, index) => {
          const title = getSearchConditionTitle(key);
          var field: keyof IWorkSearchForm = key as any;
          var list_value: string[] = [];
          switch (field) {
            case "work_status":
              list_value = workSearchForm[field]
                .filter((item) => item.isSelected)
                .map((item) => item.name);
              if (list_value.length > 0) {
                return (
                  <Chip label={title + list_value.join(", ")} key={index} />
                );
              } else {
                return null;
              }
            case "approver":
            case "author":
            case "issue_place_floor":
            case "issue_place_span":
            case "warehouse_place_floor":
            case "warehouse_place_span":
            case "container_status":
            case "defective_products":
            case "ngr_warehouse":
            case "ngr_area":
            case "ngr_customer":
            case "ngr_commodity":
            case "ngr_damage_location":
            case "ngr_qa_authorizer":
            case "ngr_occurred_company":
            case "ngr_affiliation_of_employee":
            case "ngr_waste_claim_category":
            case "ngr_accident_rank":
            case "ngr_nonconformity_detail":
            case "ngr_logistics_equipments":
            case "ngr_final_payer":
            case "ngr_expected_payment_month":
            case "ngr_actual_payment_month":
            case "ngr_est_acc":
              // multi select
              list_value = workSearchForm[field].map((item) => item.name);
              if (list_value.length > 0) {
                return (
                  <Chip label={title + list_value.join(", ")} key={index} />
                );
              } else {
                return null;
              }
            case "report_type":
            case "project_id":
            case "approval_flow":
              // select
              if (workSearchForm[field] != "") {
                var field_name: keyof IWorkSearchForm = (field +
                  "_name") as any;
                return (
                  <Chip
                    label={title + workSearchForm[field_name]}
                    key={index}
                  />
                );
              }
              return null;
            case "applied_at_s":
            case "approved_at_s":
            case "updated_at_s":
            case "incident_date_s":
            case "date_of_delivery_s":
            case "ngr_date_s":
            case "ngr_closing_date_s":
            case "ngr_completion_improvement_date_s":
            case "edr_date_s":
            case "edr_arrival_date_s":
              // date
              var field_end: keyof IWorkSearchForm = field.replace(
                "_s",
                "_e",
              ) as any;
              if (
                (workSearchForm[field] == "" &&
                  workSearchForm[field_end] == "") ||
                workSearchForm[field_end] === undefined
              ) {
                return null;
              } else {
                return (
                  <Chip
                    label={
                      title +
                      formatDateToString(workSearchForm[field], "YMD_sl") +
                      " ~ " +
                      formatDateToString(
                        workSearchForm[field_end]?.toString() ?? "",
                        "YMD_sl",
                      )
                    }
                    key={index}
                  />
                );
              }

            case "ngr_time_s":
              // time
              var field_end: keyof IWorkSearchForm = field.replace(
                "_s",
                "_e",
              ) as any;
              if (
                (workSearchForm[field] == "" &&
                  workSearchForm[field_end] == "") ||
                workSearchForm[field_end] === undefined
              ) {
                return null;
              } else {
                return (
                  <Chip
                    label={
                      title +
                      workSearchForm[field] +
                      " ~ " +
                      workSearchForm[field_end]
                    }
                    key={index}
                  />
                );
              }

            case "is_product_issue":
              if (workSearchForm["is_product_issue"]) {
                list_value.push(issueType.product);
              }
              if (workSearchForm["is_packaging_issue"]) {
                list_value.push(issueType.packaging);
              }
              if (list_value.length > 0)
                return (
                  <Chip label={title + list_value.join(", ")} key={index} />
                );
              break;

            case "year_month":
              if (workSearchForm[field]) {
                return (
                  <Chip
                    label={
                      title + formatDateToString(workSearchForm[field], "YM_sl")
                    }
                    key={index}
                  />
                );
              }
              break;

            case "sealed":
              if (workSearchForm[field] === undefined) {
                return null;
              } else {
                return (
                  <Chip
                    label={title + (workSearchForm[field] ? "有" : "無")}
                    key={index}
                  />
                );
              }
            case "loading_date":
            case "date_ac":
            case "ctc_checked_date":
            case "ctc_inspection_date":
            case "repaired_date":
            case "returning_date":
              if (workSearchForm[field] == "") {
                return null;
              } else {
                return (
                  <Chip
                    label={
                      title +
                      formatDateToString(workSearchForm[field], "YMD_sl")
                    }
                    key={index}
                  />
                );
              }
            case "reserve_status":
              if (workSearchForm[field] === undefined) {
                return null;
              } else {
                return (
                  <Chip
                    label={title + (workSearchForm[field] ? "OK" : "NG")}
                    key={index}
                  />
                );
              }
            case "image_exist":
              const imageStatus = workSearchForm[field];
              let imageLabel = "";
              if (imageStatus === IMAGE_EXIST_TYPE.not_attached) {
                imageLabel = "写真添付なし";
              } else if (imageStatus === IMAGE_EXIST_TYPE.attached) {
                imageLabel = "写真添付あり";
              }
              if (imageLabel) {
                return <Chip label={title + imageLabel} key={index} />;
              }
              break;
            case "edr_purchasing":
            case "edr_non_purchasing":
              if (workSearchForm[field] === undefined) {
                return null;
              } else {
                return (
                  <Chip
                    label={title + (workSearchForm[field] ? "あり" : "なし")}
                    key={index}
                  />
                );
              }
            case "edr_ledger_type":
              if (workSearchForm[field] === LEDGER_TYPE.tc) {
                return <Chip label={title + "TC"} key={index} />;
              } else if (workSearchForm[field] === LEDGER_TYPE.dc) {
                return <Chip label={title + "DC"} key={index} />;
              }
              break;
            case "attached_file_exist":
              const attachedFileStatus = workSearchForm[field];
              let attachedFileLabel = "";
              if (attachedFileStatus === IMAGE_EXIST_TYPE.not_designation) {
                return null;
              } else if (attachedFileStatus === IMAGE_EXIST_TYPE.not_attached) {
                attachedFileLabel = "ファイル添付なし";
              } else if (attachedFileStatus === IMAGE_EXIST_TYPE.attached) {
                attachedFileLabel = "ファイル添付あり";
              }
              if (attachedFileLabel) {
                return <Chip label={title + attachedFileLabel} key={index} />;
              }
              break;
            case "next_user":
            case "report_type_name":
            case "project_id_name":
            case "approval_flow_name":
            case "applied_at_e":
            case "approved_at_e":
            case "updated_at_e":
            case "incident_date_e":
            case "is_packaging_issue":
            case "date_of_delivery_e":
            case "ngr_date_e":
            case "ngr_time_e":
            case "ngr_completion_improvement_date_e":
            case "ngr_closing_date_e":
            case "edr_date_e":
            case "edr_arrival_date_e":
              break;
            default:
              if (workSearchForm[field] != "") {
                return (
                  <Chip label={title + workSearchForm[field]} key={index} />
                );
              }
              break;
          }
        })
      : null;
  }, [workSearchForm]);

  const getTemplateInfo = (screen_id: string) => {
    setScreenId(screen_id);
  };

  // ------------------------------------------------------------------
  // PDF結合出力
  // ------------------------------------------------------------------
  const handleMergePDF = async () => {
    if (!ref.current) {
      return;
    }
    try {
      LoadingOverlayController.show();
      // 結合PDFファイル取得
      const sortedData = ref.current.getSortedRow();
      let list_path_pdf: string[] = sortedData
        .filter((item) => check_list.some((rowNum) => item.rowNum == rowNum))
        .map((item) => item.path_pdf);
      const response = await getMergedTemplatePDF({
        list_path_pdf,
      });
      // PDFダウンロード
      const a = document.createElement("a");
      const url = createObjectURL(response);
      const report_type_no = sortedData[0].risky_id.split("-");
      const date_str = formatDateToString(new Date(), "YMDHms");
      a.href = url;
      a.download = `${report_type_no[0]}_${date_str}.pdf`;
      a.click();
      a.remove();
      LoadingOverlayController.hide();
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);
    } catch (e) {
      console.log(e);
      LoadingOverlayController.hide();
    }
  };

  return (
    <GenericTemplate title="帳票検索">
      <SearchDialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        screenId={screenId}
        onGetTemplateInfo={getTemplateInfo}
        onSearch={_handleSearch}
        data={workSearchForm}
      />
      <WorkDetailDialog
        open={openDetailDialog}
        onClose={() => {
          setOpenDetailDialog(false);
        }}
        data={detailData}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "end",
          flexDirection: { xs: "column", md: "row" },
          mb: 1,
        }}
      >
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          sx={{ gap: 1, maxWidth: "100%" }}
          spacing={0}
        >
          {SearchCondition}
        </Stack>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
            width: "100%",
            mt: { xs: 1, md: 0 },
          }}
        >
          <Button
            size="small"
            onClick={() => setOpenDialog(true)}
            sx={{ mx: 1 }}
          >
            条件設定
          </Button>
        </Box>
      </Box>

      {/* =================== 帳票 =================== */}
      <Card sx={{ mb: 5 }}>
        <CardContent sx={{ ":last-child": { pb: 2 } }}>
          {data.length === 0 && loaded ? (
            <Typography>{messages.COMMON.MSG_NOT_EXIST("帳票")} </Typography>
          ) : (
            <SortableTable
              ref={ref}
              rows={data}
              buttons={listButtons}
              headCells={selectedHeadCells}
              order="asc"
              orderByIdName="risky_id"
              idName="rowNum"
              page={page}
              isCheckRow
              paginationActionsComponent={(props) =>
                TablePaginationActions(
                  props,
                  loadMore,
                  async () => {
                    const length = await fetchListWorkItem(
                      workSearchForm,
                      lastKey,
                      sortBy,
                      true,
                    );
                    return length;
                  },
                  setPage,
                )
              }
              onChangeRowsPerPage={(r, c) => {
                if (loadMore && r < c && c > data.length) {
                  fetchListWorkItem(workSearchForm, lastKey, sortBy, true);
                }
              }}
              childRow={childRow}
            />
          )}
        </CardContent>
      </Card>

      {/* =================== 帳票作成ボタン =================== */}
      <Box
        sx={{
          position: "fixed",
          margin: 0,
          top: "auto",
          right: `calc(50% - 521px / 2)`,
          bottom: 20,
          left: "auto",
        }}
      >
        <Button
          onClick={handelExcel}
          color="secondary"
          sx={{
            width: "120px",
            mr: 1,
          }}
          size="large"
          disabled={check_list.length == 0}
        >
          Excel出力
        </Button>
        <Button
          onClick={handleCSV}
          color="secondary"
          sx={{
            width: "120px",
            mr: 1,
          }}
          size="large"
          disabled={check_list.length == 0}
        >
          CSV出力
        </Button>
        <Button
          onClick={handlePDF}
          sx={{ width: "120px", mr: 1 }}
          size="large"
          disabled={check_list.length == 0}
        >
          PDF出力
        </Button>
        <Button
          onClick={handleMergePDF}
          sx={{ width: "135px" }}
          size="large"
          disabled={check_list.length == 0}
        >
          PDF結合出力
        </Button>
      </Box>
    </GenericTemplate>
  );
};

/**
 * ページネーション
 * @param props
 * @param loadMore
 * @param handleSearch
 * @param setPage
 * @returns
 */
function TablePaginationActions(
  props: TablePaginationActionsProps,
  loadMore: boolean,
  handleSearch: () => Promise<any>,
  setPage: (page: number) => void,
) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page - 1);
    setPage(page - 1);
  };

  const handleNextButtonClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    let row_count = count;
    if (loadMore && page >= Math.ceil(count / rowsPerPage) - 1)
      row_count = await handleSearch();

    if (page >= Math.ceil(row_count / rowsPerPage) - 1) {
      onPageChange(event, page);
      setPage(page);
    } else {
      onPageChange(event, page + 1);
      setPage(page + 1);
    }
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={!loadMore && page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Box>
  );
}

export default WorkSearchTlogScreen;
