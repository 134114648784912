import React, { useEffect, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Box,
  Button,
  IconButton,
  ListItemText,
  Tooltip,
  ListItem,
  Typography,
  List,
  MenuItem,
} from "@mui/material";
import { AddCircle, Delete, Edit, Settings } from "@mui/icons-material";
import _ from "lodash";
import messages from "config/messages";
import ModalController from "@shared-components/modal/ModalController";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { getListActivityBase } from "@api/groupMaster";
import GroupEditDialog, { IData } from "./CustomMasterEditDialog";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import { getUserInfo, sort } from "@utils/index";
import SelectLabel from "components/atoms/SelectLabel";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import { USER_ROLES } from "@shared-constants";
import {
  createMasterType,
  deleteMasterType,
  getMasterType,
  updateMasterType,
  updateMasterTypeSort,
} from "@api/masterType";
import formatDateToString from "@utils/DateFormat";

const initial_data: IData = {
  name: "",
  keyitem: false,
  custom_item: [],
};

const CustomMasterListScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [data, setData] = useState<any[]>([]);
  const [originData, setOriginData] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [editData, setEditData] = useState<IData>(_.cloneDeep(initial_data));
  const [disabled, setDisabled] = useState<boolean>(true);
  const [selectDisabled, setSelectDisabled] = useState<boolean>(true);
  const [activityBaseId, setActivityBaseId] = useState<string>("");
  const [listGroup, setListGroup] = useState([]);
  const [userRole, setUserRole] = useState<string>("");
  const history = useHistoryCustom();

  useEffect(() => {
    getListGroup();
  }, []);

  const getListGroup = async (keyword?: string) => {
    const user_info = getUserInfo();
    setUserRole(user_info.user_role);
    if (user_info.user_role == USER_ROLES.OWNER.value) {
      await getListActivityBase(keyword)
        .then((res) => {
          if (res) {
            setListGroup(res);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setActivityBaseId(user_info.location_id);
      setSelectDisabled(false);
      fetchData(user_info.location_id);
    }
  };

  const handleDnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const sorted = sort(data, result.source.index, result.destination.index);
    setData(sorted);
  };

  const handleAdd = () => {
    setEditData(initial_data);
    setOpen(true);
  };

  const handleDelete = (SK: string) => {
    if (!ModalController.isShowing()) {
      const confirmMessage = disabled
        ? messages.COMMON.MSG_COMMON_DELETE_CONFIRM_001
        : messages.COMMON.MSG_COMMON_DELETE_CONFIRM_SORT_001;
      ModalController.show({
        message: confirmMessage,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => handleDeleteMasterType(SK),
      });
    }
    return;
  };

  const handleInfo = (item: any) => {
    history.pushWithRef("/custom-master/info", {
      SK: item.SK,
      master_name: item.name,
      item_name: item.item_name,
      custom_item: item.custom_item,
    });
  };

  const fetchDataConfirm = async () => {
    if (!ModalController.isShowing() && !activityBaseId) {
      ModalController.show({
        message: messages.COMMON.ERROR.MSG_RQUIRED_SELECT("拠点"),
        visibleButton2: true,
      });
    }

    if (!ModalController.isShowing() && !disabled) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_DISPLAY_CONFIRM_SORT_001,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => {
          fetchData(activityBaseId);
          setData([]);
          setOriginData([]);
          setSelectDisabled(true);
        },
      });
    }

    if (disabled) {
      if (selectDisabled) {
        if (activityBaseId) {
          setSelectDisabled(false);
          fetchData(activityBaseId);
        }
      } else {
        setData([]);
        setOriginData([]);
        setSelectDisabled(true);
      }
    }
  };

  const fetchData = async (activityBaseId: string) => {
    LoadingOverlayController.show();
    await getMasterType(activityBaseId, true)
      .then((res) => {
        if (res?.data) {
          setData(res.data);
          setOriginData(res.data);
        }
      })
      .finally(() => LoadingOverlayController.hide());
  };

  useEffect(() => {
    setDisabled(JSON.stringify(data) === JSON.stringify(originData));
  }, [data, originData]);

  const handleEdit = (d: any, i: number) => {
    setEditData({
      name: d.name,
      keyitem: d.keyitem ? d.keyitem : false,
      custom_item: d.custom_item,
      SK: d.SK,
    });
    setEditIndex(i);
    if (!ModalController.isShowing() && !disabled) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_UPDATE_CONFIRM_SORT_001,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => {
          setOpen(true);
        },
      });
    } else {
      setOpen(true);
    }
  };

  const handleChange = async (value: IData) => {
    let newData = JSON.parse(JSON.stringify(data));
    let message = "";
    let res = null;
    const formData = {
      name: value.name,
      keyitem: value.keyitem,
      custom_item: value.custom_item,
      activity_base_id: activityBaseId,
    };
    try {
      LoadingOverlayController.show();
      if (editIndex >= 0) {
        res = await updateMasterType(formData, newData[editIndex].SK);
        if (res) {
          message = "ユーザー作成マスタの更新";
        }
      } else {
        res = await createMasterType(formData);
        if (res) {
          message = "ユーザー作成マスタの登録";
        }
      }
      setEditIndex(-1);
      if (res) {
        setData(newData);
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001(message),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData(activityBaseId);
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleChange", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleSave = async () => {
    try {
      LoadingOverlayController.show();
      let formData: any[] = [];
      data.map((item) => {
        const datilData = {
          PK: item.PK,
          SK: item.SK,
        };
        formData.push(datilData);
      });
      const res = await updateMasterTypeSort(formData);
      if (res) {
        ModalController.show({
          message:
            messages.COMMON.MSG_COMMON_SUCCESS_001(
              "ユーザー作成マスタの並び順の更新",
            ),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData(activityBaseId);
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleSave", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setEditIndex(-1);
  };

  const handleDeleteMasterType = async (SK: string) => {
    try {
      LoadingOverlayController.show();
      const res = await deleteMasterType(SK);
      if (res) {
        ModalController.show({
          message:
            messages.COMMON.MSG_COMMON_DELETE_SUCCESS_001("ユーザー作成マスタ"),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData(activityBaseId);
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleDeleteMasterType", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  return (
    <GenericTemplate title="ユーザー作成マスタ">
      <GroupEditDialog
        data={editData}
        open={open}
        handleButtonOK={handleChange}
        onClose={handleCancel}
        activityBaseId={activityBaseId}
      />
      {userRole == USER_ROLES.OWNER.value && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mb: 3,
            }}
          >
            <SelectLabel
              label="拠点"
              value={activityBaseId}
              isIgnoreExtractRuleLabel={false}
              isIgnoreExtractRuleSelect={true}
              onChange={(e) => setActivityBaseId(e.target.value)}
              sx={{ width: 500 }}
              disabled={!selectDisabled}
            >
              {listGroup.map((item: any) => (
                <MenuItem value={item.SK} key={item.SK}>
                  <span className="IgnoreExtractRuleTarget">
                    {item.activity_base_name}
                  </span>
                </MenuItem>
              ))}
            </SelectLabel>
            <Button
              onClick={fetchDataConfirm}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 3,
                ml: 2,
              }}
            >
              {selectDisabled ? "選択" : "解除"}
            </Button>
          </Box>
        </>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant="body2">ドラッグ＆ドロップで並べ替え</Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Button
          onClick={handleAdd}
          endIcon={<AddCircle />}
          disabled={selectDisabled}
        >
          追加
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          pl: 1,
          pr: "160px",
        }}
      >
        <Typography sx={[styles.header, styles.ellipsis]}>マスタ名</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Typography
          sx={[
            styles.header,
            styles.ellipsis,
            { width: 120, maxWidth: 120, mr: 0.5 },
          ]}
        >
          更新者名
        </Typography>
        <Typography sx={[styles.header, styles.ellipsis, { width: 140 }]}>
          更新日時
        </Typography>
      </Box>
      <Box>
        <DragDropContext onDragEnd={handleDnd}>
          <Droppable droppableId="droppableId-1">
            {(provided: DroppableProvided) => (
              <List
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{ pt: 0.5 }}
              >
                {data.map((item, i) => (
                  <Draggable key={item.SK} draggableId={item.SK} index={i}>
                    {(provided: DraggableProvided) => (
                      <ListItem
                        sx={{ bgcolor: "white" }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        secondaryAction={
                          <>
                            <Tooltip title="詳細設定">
                              <IconButton
                                sx={{ mr: 1 }}
                                onClick={() => handleInfo(item)}
                              >
                                <Settings />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="編集">
                              <IconButton
                                edge="end"
                                sx={{ mx: 1 }}
                                onClick={() => handleEdit(item, i)}
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="削除">
                              <IconButton
                                edge="end"
                                onClick={() => handleDelete(item.SK)}
                                disabled={
                                  !(
                                    (item?.is_deletable ?? true) ||
                                    item?.is_deletable === ""
                                  )
                                }
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                        divider
                      >
                        <ListItemText
                          primary={
                            <Box
                              component={"span"}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                              }}
                            >
                              <Box component={"span"} sx={styles.ellipsis}>
                                {item.name}
                              </Box>
                              <Box sx={{ flexGrow: 1 }} />
                              <Box
                                component={"span"}
                                sx={{
                                  ...styles.ellipsis,
                                  width: 120,
                                  maxWidth: 120,
                                  mr: 0.5,
                                }}
                              >
                                {item.master_updated_by}
                              </Box>
                              <Box
                                component={"span"}
                                sx={{
                                  ...styles.ellipsis,
                                  width: 140,
                                }}
                              >
                                {formatDateToString(
                                  item.master_updated_at,
                                  "YMDHms_sl",
                                  true
                                )}
                              </Box>
                            </Box>
                          }
                          primaryTypographyProps={{
                            style: {
                              marginRight: "110px",
                            },
                          }}
                        />
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box sx={{ height: 30 }} />
      <Box
        sx={{
          position: "fixed",
          margin: 0,
          right: 0,
          bottom: 20,
          textAlign: "center",
          width: "100%",
        }}
      >
        <Button onClick={handleSave} color="secondary" disabled={disabled}>
          保存
        </Button>
      </Box>
    </GenericTemplate>
  );
};

export default CustomMasterListScreen;

const styles = {
  header: {
    fontSize: 14,
    fontWeight: 500,
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
} as const;
