import {
    METHOD_API,
    createConnector,
    getParamsHelp,
    prefixApi,
} from "./Connector";
import { httpClient } from "./HttpClient";

interface LogMaster {
    export_account_id?: string;
    export_account_name?: string;
    export_count?: string;
    export_data_type?: string;
    import_data_type?: string;
    import_account_id?: string;
    import_account_name?: string;
    import_count?: string;
    is_export: boolean; // true→export, false→import
    activity_base_id?: string;
};


export const getListLogExportApi = (
    last_key: string,
    activity_base_id: string,
) => {
    return createConnector(
        METHOD_API.GET,
        `${prefixApi}/get-list-export-log/${encodeURIComponent(
            activity_base_id,
        )}?${getParamsHelp({
            last_key: last_key,
        })}`,
        null,
    );
};

export const getListImportLogApi = (
    last_key: string,
    activity_base_id: string,
) => {
    return createConnector(
        METHOD_API.GET,
        `${prefixApi}/get-list-import-log/${encodeURIComponent(
            activity_base_id,
        )}?${getParamsHelp({
            last_key: last_key,
        })}`,
        null,
    );
};


export const insertLogApi = (dataBody: FormData) => {
    console.log(dataBody)
    return createConnector(
        METHOD_API.UPLOAD_FORM_POST,
        `${prefixApi}/insert-log`,
        dataBody,
    );
};