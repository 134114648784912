import {
  METHOD_API,
  createConnector,
  getParamsHelp,
  prefixApi,
} from "./Connector";

interface ICreateMasterType {
  name: string;
  activity_base_id: string;
  is_usermade?: boolean;
  item_name?: string;
}

interface sortData {
  PK: String;
  SK: String;
}

export const getMasterType = (
  activity_base_id: string,
  is_usermade?: boolean,
) => {
  let url_params = "";
  if (is_usermade !== undefined) {
    const params = { is_usermade: is_usermade };
    url_params = `?${getParamsHelp(params)}`;
  }
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/master-type/${encodeURIComponent(
      activity_base_id,
    )}${url_params}`,
    null,
  );
};

export const createMasterType = (dataBody: ICreateMasterType) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/master-type`,
    dataBody,
  );
};
export const updateMasterType = (dataBody: ICreateMasterType, SK: string) => {
  return createConnector(
    METHOD_API.PUT,
    `${prefixApi}/master-type/${encodeURIComponent(SK)}`,
    dataBody,
  );
};

export const updateMasterTypeSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/master-type/sort`,
    dataBody,
  );
};

export const deleteMasterType = (master_type_id: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/master-type/${encodeURIComponent(master_type_id)}`,
    null,
  );
};

export const getListCustomMaster = (SK: string, last_key?: string) => {
  let params: any = { SK: SK };
  if (last_key !== undefined) {
    params["last_key"] = last_key;
  }
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/custom-master?${getParamsHelp(params)}`,
    null,
  );
};

export const updateCustomMaster = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/custom-master/sort`,
    dataBody,
  );
};

export const deleteCustomMaster = (custom_master_id: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/custom-master/${encodeURIComponent(custom_master_id)}`,
    null,
  );
};

export const getCustomMasterByBarcode = (params: any) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/custom-master/barcode?${getParamsHelp(params)}`,
    null,
  );
};

export const checkMasterTypeExist = (params: any) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/master-type-exist?${getParamsHelp(params)}`,
    null,
  );
};

export const getMasterByProjectId = (project_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-all-custom-master-by-project-id/${encodeURIComponent(
      project_id,
    )}`,
    null,
  );
};

export const getExteriorDamagedProductInfo = (params: {
  activity_base_id: string;
  jan_code: string;
}) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/exterior-damaged-product-info?${getParamsHelp(params)}`,
    null,
  );
};
