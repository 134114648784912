import {
  useState,
  useCallback,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { useDropzone, Accept, FileRejection } from "react-dropzone";
import {
  Alert,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Snackbar,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { Colors } from "@template/style";
import { Cancel, UploadFile } from "@mui/icons-material";
import messages from "config/messages";
import { MAX_CAPACITY } from "@shared-constants";

type IAccept = "image" | "all" | "csv" | "excel";

export interface IDropZoneProps {
  accept?: IAccept | Array<IAccept>;
  maxFiles?: number;
  showFileList?: boolean;
  onChoose?: (response: any) => void;
  onDeleteFile?: (files: File[]) => void;
  value?: File[];
  maxSize?: number;
}

const Dropzone = (
  {
    accept = "image",
    maxFiles = MAX_CAPACITY,
    showFileList = true,
    onChoose,
    onDeleteFile,
    value,
    maxSize = 31457280, // byte(30MB)
  }: IDropZoneProps,
  ref: any,
) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [snackbar, setSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  useEffect(() => {
    if (value && !_.isEqual(value, selectedFiles))
      setSelectedFiles(_.cloneDeep(value));
  }, [value]);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections: FileRejection[]) => {
      // 追加不可
      if (fileRejections.length > 0) {
        if (fileRejections[0].errors[0].code === "file-too-large") {
          setSnackbarMessage(
            messages.ManageFolder.MSG_SECLECT_MAX_SIZE(
              Math.floor(maxSize / (1024 * 1024)), // MBに変換
            ),
          );
          setSnackbar(true);
        }
        if (fileRejections[0].errors[0].code === "too-many-files") {
          setSnackbarMessage(messages.ManageFolder.MSG_SELECT_LIMIT(maxFiles));
          setSnackbar(true);
        }
        if (fileRejections[0].errors[0].code === "file-invalid-type") {
        }
        return;
      }

      if (selectedFiles.length + acceptedFiles.length > maxFiles) {
        setSnackbarMessage(messages.ManageFolder.MSG_SELECT_LIMIT(maxFiles));
        setSnackbar(true);
        return;
      }

      // ファイル追加
      setSelectedFiles([...selectedFiles, ...acceptedFiles]);

      if (acceptedFiles.length > 0) {
        if (typeof onChoose === "function") onChoose(acceptedFiles);
      }
    },
    [selectedFiles, maxFiles, onChoose],
  );

  const dropzpne_accept = useMemo(() => {
    let response: Accept = {};
    let accept_list = typeof accept === "string" ? [accept] : [...accept];
    for (let index = 0; index < accept_list.length; index++) {
      switch (accept_list[index]) {
        case "image":
          response = { ...response, "image/*": [] };
          break;
        case "csv":
          response = { ...response, "text/csv": [] };
          break;
        case "excel":
          response = {
            ...response,
            "application/vnd.ms-excel": [],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              [],
          };
          break;
        case "all":
        default:
          response = {};
          break;
      }
    }
    return response;
  }, [accept]);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, open } =
    useDropzone({
      accept: dropzpne_accept,
      onDrop,
      maxFiles: maxFiles,
      multiple: maxFiles > 1,
      maxSize: maxSize,
      noClick: true,
      noKeyboard: true,
    });

  const handleDelete = (file: any) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setSelectedFiles(newFiles);
    if (typeof onDeleteFile === "function") onDeleteFile(newFiles);
  };

  const files = useMemo(() => {
    return selectedFiles.map((file: any, index) => (
      <ListItem
        key={index}
        sx={{ display: "list-item", p: 0, color: Colors.TEXT }}
      >
        {file.path}
        <IconButton onClick={() => handleDelete(file)}>
          <Cancel />
        </IconButton>
      </ListItem>
    ));
  }, [selectedFiles, handleDelete]);

  useImperativeHandle(ref, () => ({
    getData: () => {
      return selectedFiles;
    },
    delData: (file?: File) => {
      if (file) {
        handleDelete(file);
      } else {
        setSelectedFiles([]);
      }
    },
  }));

  return (
    <>
      <Box
        {...getRootProps()}
        sx={{
          border: 1.5,
          borderStyle: "dashed",
          borderRadius: 1,
          bgcolor: Colors.IMAGE_SELECT_BLOCK,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          py: 2,
          borderColor: isDragAccept
            ? Colors.MAIN_GREEN
            : isDragActive
            ? Colors.ERROR_TEXT
            : "#ccc",
        }}
      >
        <input {...getInputProps()} />
        <UploadFile fontSize="large" />
        <Typography variant="body2">
          ここにファイルをドラッグ&ドロップ
        </Typography>
        <Button
          sx={{ mt: 2 }}
          onClick={open}
          disabled={selectedFiles.length >= maxFiles}
        >
          ファイル選択
        </Button>
      </Box>
      {showFileList && selectedFiles.length > 0 && (
        <List sx={{ listStyleType: "disc", ml: 3 }}>{files}</List>
      )}
      <Snackbar
        open={snackbar}
        autoHideDuration={5000}
        onClose={() => setSnackbar(false)}
      >
        <Alert severity="error">{snackbarMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default forwardRef(Dropzone);
