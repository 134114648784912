import React, { ReactNode } from "react";
import { Container, Dialog, Slide, Toolbar } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Header from "@template/header";
import { theme } from "@template/style";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FullScreenDialogProps {
  open: boolean;
  onClose: () => void;
  onDrawerToggle?: () => void;
  title?: string;
  projectMenu?: string;
  isSearch?: boolean;
  searchPlaceholder?: string;
  onSearch?: (v: string) => void;
  onSearchClear?: () => void;
  showMenu?: boolean;
  children?: ReactNode;
  refDialog?:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null;
}

const FullScreenDialog = ({
  open,
  onClose,
  onDrawerToggle = () => {},
  title = "",
  projectMenu = "",
  isSearch = false,
  searchPlaceholder = "",
  onSearch = () => {},
  onSearchClear = () => {},
  showMenu = false,
  children,
  refDialog,
}: FullScreenDialogProps) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background.default,
        },
        ref: refDialog,
      }}
    >
      <Header
        onDrawerToggle={onDrawerToggle}
        title={title}
        projectMenu={projectMenu}
        isSearch={isSearch}
        searchPlaceholder={searchPlaceholder}
        onSearch={onSearch}
        onSearchClear={onSearchClear}
        showMenu={showMenu}
      />
      <Container sx={{ flex: 1, py: 4, px: 4 }}>
        <Toolbar />
        <Toolbar sx={{ display: { xs: "flex", md: "none" } }} />

        {children}
      </Container>
    </Dialog>
  );
};

export default FullScreenDialog;
