import React, { useEffect, useState } from "react";
import { Image } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  FormGroup,
  FormLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { MAX_CAPACITY } from "@shared-constants";
import { Colors } from "@template/style";
import FileItem from "components/molecules/FileItem";
import TemplateSelectBox from "components/molecules/TemplateSelectBox";
import DropzoneDialog from "components/molecules/DropzoneDialog";
import { createObjectURL } from "@utils/index";
import styles from "./styles";
import ModalController from "@shared-components/modal/ModalController";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";

interface IProps {
  selectedImages: any;
  selectedFiles: any;
  setSelectedFiles: any;
  setSelectedImages: any;
  setFileDelete: any;
  setImageDelete: any;
  fileDelete: any;
  imageDelete: any;
  template: any;
}

interface ISelectImageFileProps {
  selectedImages: any;
  selectedFiles: any;
  setSelectedFiles: any;
  setSelectedImages: any;
  setFileDelete: any;
  setImageDelete: any;
  fileDelete: any;
  imageDelete: any;
}

const SelectFileReport: React.VFC<IProps> = ({
  selectedImages,
  selectedFiles,
  setSelectedFiles,
  setSelectedImages,
  setFileDelete,
  setImageDelete,
  fileDelete,
  imageDelete,
  template,
}: IProps) => {
  return (
    <>
      <Card>
        <CardHeader title="内容" sx={styles.header} />
        <CardContent>
          {/* ============= テンプレート ============= */}
          <TemplateSelectBox
            template={template}
            onClick={() => {
              ModalController.show({
                message: "テンプレート編集",
                visibleButton2: true,
                disableFeedback: true,
              });
            }}
            buttonType="edit"
          />
        </CardContent>
      </Card>

      {/* ============= 添付 ============= */}
      <Card>
        <CardHeader title="添付" sx={styles.header} />
        <CardContent>
          <SelectImageFile
            selectedImages={selectedImages}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            setSelectedImages={setSelectedImages}
            setFileDelete={setFileDelete}
            setImageDelete={setImageDelete}
            fileDelete={fileDelete}
            imageDelete={imageDelete}
          />
        </CardContent>
      </Card>
    </>
  );
};

// ====================================
// 添付コンポーネント
// ====================================
export const SelectImageFile: React.VFC<ISelectImageFileProps> = ({
  selectedImages,
  selectedFiles,
  setSelectedFiles,
  setSelectedImages,
  setFileDelete,
  setImageDelete,
  fileDelete,
  imageDelete,
}: ISelectImageFileProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [disabledImage, setDisableImage] = useState<boolean | undefined>(false);
  const [disabledFile, setDisableFile] = useState<boolean>(false);

  const [openImage, setOpenImage] = useState<boolean>(false);
  const [openFile, setOpenFile] = useState<boolean>(false);

  useEffect(() => {
    if (selectedFiles.length >= MAX_CAPACITY) {
      setDisableFile(true);
    } else {
      setDisableFile(false);
    }
    if (selectedImages.length >= MAX_CAPACITY) {
      setDisableImage(true);
    } else setDisableImage(false);
  }, [selectedFiles, selectedImages]);

  return (
    <Stack>
      {/* =================== 画像 =================== */}
      <FormGroup>
        <FormLabel
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          画像
          <Typography
            sx={{ ml: 1, color: Colors.DISABLE_BTN_TEXT }}
            variant="body2"
          >
            {MAX_CAPACITY}点まで
          </Typography>
        </FormLabel>
        <Button
          onClick={() => setOpenImage(true)}
          endIcon={<Image />}
          sx={{ width: 120, mt: 0.5 }}
          disabled={disabledImage}
        >
          選択
        </Button>
        <Grid container spacing={2} my={1}>
          {selectedImages.map((item: any, index: number) => (
            <Grid item xs={4} sm={2} key={index}>
              <CancelBtnBadge
                onClick={() => {
                  if (item.hasWork) {
                    let list = imageDelete;
                    list.push(item.SK);
                    setImageDelete(list);
                  }
                  let selected = selectedImages;
                  selected.splice(index, 1);
                  setSelectedImages([...selected]);
                }}
                overlap="rectangular"
                sx={{ width: "100%" }}
              >
                <Box
                  component="img"
                  src={
                    item?.path_file_thumb
                      ? item.path_file_thumb
                      : item?.type === "image/svg+xml"
                        ? item?.uriThumb ?? ""
                        : item?.uri ?? createObjectURL(item)
                  }
                  key={item.size}
                  sx={{
                    width: "100%",
                    height: { xs: 80, md: 100 },
                    objectFit: "cover",
                    borderRadius: 1,
                  }}
                />
              </CancelBtnBadge>
            </Grid>
          ))}
        </Grid>
      </FormGroup>

      {/* =================== 資料 =================== */}
      <FormGroup>
        <FormLabel
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          資料
          <Typography
            sx={{ ml: 1, color: Colors.DISABLE_BTN_TEXT }}
            variant="body2"
          >
            {MAX_CAPACITY}点まで
          </Typography>
        </FormLabel>
        <FileItem
          data={selectedFiles}
          onAdd={() => {
            setOpen(true);
          }}
          onRemove={(id: any, item: any) => {
            if (item.hasWork) {
              let list = fileDelete;
              list.push(item.SK);
              setFileDelete(list);
            }
            let selected = selectedFiles;
            selected.splice(id, 1);
            setSelectedFiles([...selected]);
          }}
          disabled={disabledFile}
        />
      </FormGroup>

      {/* =================== 画像添付メニュー =================== */}
      <Dialog
        open={open}
        maxWidth="xs"
        sx={{ "& .MuiDialog-paper": { width: "50%", py: 2 } }}
      >
        <DialogContent>
          <Stack>
            <Button
              onClick={() => {
                setOpen(false);
                setOpenFile(true);
              }}
            >
              アップロード
            </Button>
            <Button variant="outlined" onClick={() => setOpen(false)}>
              キャンセル
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>

      {/* =================== 画像アップロード =================== */}
      <DropzoneDialog
        open={openImage}
        onClose={() => {
          setOpenImage(false);
        }}
        isMultiple={true}
        maxFiles={MAX_CAPACITY}
        onAccept={(res: File[]) => {
          let selected = selectedImages;
          for (let i = 0; i < res.length; i++) {
            if (selected.length >= 10) break;
            let indexObj = selectedImages.findIndex(
              (item: any) => item?.name == res[i].name,
            );
            if (indexObj == -1) {
              selected.push(res[i]);
            }
          }
          setSelectedImages([...selected]);

          setOpenImage(false);
        }}
        accept="image"
      />

      {/* =================== 資料アップロード =================== */}
      <DropzoneDialog
        open={openFile}
        onClose={() => {
          setOpenFile(false);
        }}
        isMultiple={true}
        maxFiles={MAX_CAPACITY}
        onAccept={(res: File[]) => {
          let selected = selectedFiles;
          for (let i = 0; i < res.length; i++) {
            if (selected.length >= 10) break;
            let indexObj = selectedFiles.findIndex(
              (item: any) => item?.name == res[i].name,
            );
            if (indexObj == -1) {
              selected.push(res[i]);
            }
          }
          setSelectedFiles([...selected]);

          setOpenFile(false);
        }}
        accept="all"
      />
    </Stack>
  );
};

export default SelectFileReport;
