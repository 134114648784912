export const TYPES = {
  // auth
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  SET_LOGGEDIN: "SET_LOGGEDIN",
  SET_USER_INFO: "SET_USER_INFO",
  SET_AUTH: "SET_AUTH",
  CLEAR_AUTH: "CLEAR_AUTH",
  SET_CONTRACT_INFO: "SET_CONTRACT_INFO",
  CLEAR_CONTRACT_INFO: "CLEAR_CONTRACT_INFO",
  CONTRACT_LOGIN: "CONTRACT_LOGIN",
  CONTRACT_LOGOUT: "CONTRACT_LOGOUT",

  // filter
  SET_FILTER_CONDITION: "SET_FILTER_CONDITION",
  SET_FILTER_CONDITION_PROJECT: "SET_FILTER_CONDITION_PROJECT",
  REMOVE_FILTER_CONDITION: "REMOVE_FILTER_CONDITION",
  CLEAR_FILTER_CONDITION: "CLEAR_FILTER_CONDITION",
  CLEAR_ALL_FILTER_CONDITION: "CLEAR_ALL_FILTER_CONDITION",
  SET_ALL_FILTER_CONDITION: "SET_ALL_FILTER_CONDITION",
  SET_EACH_FILTER_CONDITION: "SET_EACH_FILTER_CONDITION",

  //sort
  SET_SORT_CONDITION: "SET_SORT_CONDITION",
  SET_SORT_SCREEN: "SET_SORT_SCREEN",
  CLEAR_SORT_CONDITION: "CLEAR_SORT_CONDITION",
  SET_SORT_ALL_SCREEN: "SET_SORT_ALL_SCREEN",

  // project
  SET_SELECTED_PROJECT_ID: "SET_SELECTED_PROJECT_ID",
  SET_SELECTED_WORK_ID: "SET_SELECTED_WORK_ID",
  SET_PROJECT_INFO: "SET_PROJECT_INFO",
  SET_FAVORITE_CONDITION: "SET_FAVORITE_CONDITION",
  SET_FAVORITE_PROJECT_INFO: "SET_FAVORITE_PROJECT_INFO",
  CLEAR_FAVORITE_PROJECT_INFO: "CLEAR_FAVORITE_PROJECT_INFO",

  //status project
  GET_LIST_STATUS_PROJECT: "GET_LIST_STATUS_PROJECT",
  GET_LIST_LOCATION: "GET_LIST_LOCATION",
  SET_STATUS_PROJECT: "SET_MANAGER_DATA",
  SET_TYPE_WORK_ITEM: "SET_TYPE_WORK_ITEM",

  //location
  SET_LOCATION_PROJECT: "SET_LOCATION_PROJECT",

  // Color
  SET_MASTER_COLOR_DATA: "SET_MASTER__DATA",

  // Setting
  SET_FOLDERID_LEVEL: "SET_FOLDERID_LEVEL",

  // Manage
  SET_TAB_INDEX: "SET_TAB_INDEX",
  SET_IS_CHANGED: "SET_IS_CHANGED",

  // Approve
  SET_MEMBER_LIST_SEARCH: "SET_MEMBER_LIST_SEARCH",
  SET_NAME_MEMBER_SEARCH: "SET_NAME_MEMBER_SEARCH",
  CLEAR_MEMBER_LIST_SEARCH: "CLEAR_MEMBER_LIST_SEARCH",
  SET_TEMPLATE: "SET_TEMPLATE",
  SET_APPROVAL_FLOW: "SET_APPROVAL_FLOW",
  SET_APPROVER_FOR_STEP: "SET_APPROVER_FOR_STEP",
  SET_APPROVER_FOR_STEP_AND_DELETE_APPROVER: "SET_APPROVER_FOR_STEP_AND_DELETE_APPROVER",
  SET_CURRENT_STEP_ID: "SET_CURRENT_STEP_ID",
  SET_STEP_LIST: "SET_STEP_LIST",
  SET_OPEN_DIALOG: "SET_OPEN_DIALOG",
  SET_CLICK_ON_SEARCH: "SET_CLICK_ON_SEARCH",
  SET_API_IS_LOADING: "SET_API_IS_LOADING",
  SET_FLOW_LIST: "SET_FLOW_LIST",
  SET_EDITED_FLOW_NAME: "SET_EDITED_FLOW_NAME",
  SET_ON_CLICK_CREAT_NEW_FLOW: "SET_ON_CLICK_CREAT_NEW_FLOW",
  SET_ON_CLICK_DELETE_FLOW: "SET_ON_CLICK_DELETE_FLOW",
  SET_RESET: "SET_RESET",
  SET_LIST_STEP_FOR_FLOW: "SET_LIST_STEP_FOR_FLOW",
  SET_ADD_STEP: "SET_ADD_STEP",
  SET_DELETE_STEP: "SET_DELETE_STEP",
  INIT_APPROVE_STATE: "INIT_APPROVE_STATE",
  SET_SAVE_APPROVE: "SET_SAVE_APPROVE",
  SET_STEPS_READY_FOR_SUBMIT: "SET_STEPS_READY_FOR_SUBMIT",
  SET_FLOW_NAME_READY_FOR_SUBMIT: "SET_FLOW_NAME_READY_FOR_SUBMIT",
  RE_CHECK: "RE_CHECK",
  SET_IS_THE_SAME_ORIGIN: "IS_THE_SAME_ORIGIN",
  SET_MAX_USER_LOCATION: "SET_MAX_USER_LOCATION",
  SET_ORIGIN_STEP_LIST: "SET_ORIGIN_STEP_LIST",
  SET_DELETE_STEP_FLOW_LIST: "SET_DELETE_STEP_FLOW_LIST",

  // TableCustom
  SET_CHECK_TABLE: "SET_CHECK_TABLE",

  // Mail Confirm
  SET_MAIL_WORK_CONFIRM: "SET_MAIL_WORK_CONFIRM",

  SET_2D_BARCODE_LAYOUT: "SET_2D_BARCODE_LAYOUT",

  // DEVAN
  SET_DEVAN_SEARCH: "SET_DEVAN_SEARCH",
  CLEAR_DEVAN_SEARCH: "CLEAR_DEVAN_SEARCH",
  SET_DEVAN_SEARCH_CONDITION: "SET_DEVAN_SEARCH_CONDITION",

  // Edit Template
  SET_EDIT_TEMPLATE: "SET_EDIT_TEMPLATE",
  CLEAR_EDIT_TEMPLATE: "CLEAR_EDIT_TEMPLATE",
  SET_SIGNATURE_PEN_SIZE: "SET_SIGNATURE_PEN_SIZE",

  // Incident Damage Report
  SET_INCIDENT_DAMAGE_REPORT: "SET_INCIDENT_DAMAGE_REPORT",
  CLEAR_INCIDENT_DAMAGE_REPORT: "CLEAR_INCIDENT_DAMAGE_REPORT"
} as const;
