import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { cloneDeep, debounce, isEqual } from "lodash";
import { useSelector } from "react-redux";
import messages from "config/messages";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import SelectLabel from "components/atoms/SelectLabel";
import SignatureInput from "components/atoms/SignatureInput";
import StepperInputLabel from "components/atoms/StepperInputLabel";
import ImagePickerList from "components/molecules/ImagePickerList";
import { getProjectLocationIdStore } from "selector/projectSelector";
import {
  getExteriorDamagedProductInfo,
  getMasterByProjectId,
} from "@api/masterType";
import { getListUserByLocationId } from "@api/User";
import { User } from "services/models";
import { Validation } from "@validation";
import formatDateToString from "@utils/DateFormat";
import { getUserInfo } from "@utils/index";
import { uploadImageToS3 } from "@utils/template";
import {
  ExteriorDamagedTCFormType,
  getImageArray,
  getInfoFromTemplate,
  insertReportLocal,
  makeNewHtml,
} from "@utils/template/exteriorDamagedTC";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";

const maxImage: number = 12;

const REPORTER_STEP = 0;
const APPROVER_STEP_1 = 1;
const APPROVER_STEP_2 = 2;

interface IExteriorDamagedTCReport {
  infoToEdit?: any;
  htmlString: string;
  setHtmlString: Function;
  step: number;
  open: boolean;
  project_id: string;
  isManage: boolean;
}

const originData: ExteriorDamagedTCFormType = {
  risky_id: "",
  date: formatDateToString(new Date(), "YMD"),
  arrival_date: formatDateToString(new Date(), "YMD"),
  exterior_damaged_location: null,
  exterior_damaged_location_other: "",
  written_by: null,
  written_by_other: "",
  checked_by: null,
  checked_by_other: "",
  jan_code: "",
  supplier_code: "",
  supplier_name: "",
  product_name: "",
  product_type: "",
  total: 0,
  status_1: false,
  status_2: false,
  status_3: false,
  status_4: false,
  status_5: false,
  status_6: false,
  status_text_1: "",
  status_text_2: "",
  status_text_3: "",
  status_text_4: "",
  status_text_5: "",
  status_text_6: "",
  image_array: [], //状況写真
  sign_date: null,
  sign_company: null,
  sign_car_no: null,
  sign_name: null,
  purchasing: false,
  order_customer_count: 0,
  order_replenishment_count: 0,
  non_purchasing: false,
  non_purchasing_count: 0,
  memo: "",
  item_check_user: null,
  store_check_user: null,
  //  帳票表示文言 ----------------------------------------------------------
  document_title: "",
  shipper_name: "",
  shipper_name_sub: "",
  shipper_address: "",
  shipper_tel: "",
  shipper_fax: "",
  order_customer_label: "",
  order_customer_text: "",
  order_replenishment_label: "",
  order_replenishment_text: "",
  purchasing_statement: "",
  non_purchasing_statement: "",
};

const SIGNATURE_KEY: Array<keyof ExteriorDamagedTCFormType> = [
  "sign_date",
  "sign_company",
  "sign_car_no",
  "sign_name",
];

interface IFormError {
  date: string;
  arrival_date: string;
  jan_code: string;
  supplier_code: string;
  product_type: string;
  total: string;
  order_customer_count: string;
  order_replenishment_count: string;
  non_purchasing_count: string;
}

const ExteriorDamagedTCReport = (
  {
    infoToEdit,
    htmlString,
    setHtmlString,
    open,
    project_id,
    step,
  }: IExteriorDamagedTCReport,
  ref: any,
) => {
  const [stateForm, setStateForm] = useState<ExteriorDamagedTCFormType>(
    cloneDeep(originData),
  );
  const [originStateForm, setOriginStateForm] =
    useState<ExteriorDamagedTCFormType>(cloneDeep(originData));
  const [formError, setFormError] = useState<IFormError>({
    date: "",
    arrival_date: "",
    jan_code: "",
    supplier_code: "",
    product_type: "",
    total: "",
    order_customer_count: "",
    order_replenishment_count: "",
    non_purchasing_count: "",
  });
  const [listUser, setListUser] = useState<Array<any>>([]);
  const [addIsManualListUser, setAddIsManualListUser] = useState<Array<any>>(
    [],
  );
  const [listReportItem, setListReportItem] = useState<{
    list_check_user: Array<any>;
    list_damage_status: Array<any>;
    list_exterior_damaged_location: Array<any>;
    list_non_purchasing_statement: Array<any>;
    list_order_customer: Array<any>;
    list_order_replenishment: Array<any>;
    list_purchasing_statement: Array<any>;
    list_shipper: Array<any>;
    list_title: Array<any>;
  }>({
    list_check_user: [],
    list_damage_status: [],
    list_exterior_damaged_location: [],
    list_non_purchasing_statement: [],
    list_order_customer: [],
    list_order_replenishment: [],
    list_purchasing_statement: [],
    list_shipper: [],
    list_title: [],
  });
  const [completeGetData, setCompleteGetData] = useState<boolean>(false);
  const location_id = useSelector(getProjectLocationIdStore);
  const user: User = getUserInfo();
  const signatureRef = useRef<any>({});

  useImperativeHandle(ref, () => ({
    getStateForm: () => {
      // プレビュー用の値を削除
      let cloneStateForm = cloneDeep(stateForm);
      SIGNATURE_KEY.forEach((key) => {
        delete cloneStateForm[key]?.base64;
      });
      return cloneStateForm;
    },
    getOriginStateForm: () => originStateForm,
    disabledSubmit: () => Object.values(formError).some((item) => item != ""),
    onSubmit: onSubmit,
  }));

  // データ取得 =====================================
  const addWhiteSpace = (data: Array<any>) => {
    if (data.length == 0) {
      return data;
    }
    data.splice(0, 0, undefined);
    return data;
  };

  const addIsManual = (data: Array<any>) => {
    if (data.length == 0) {
      return data;
    }
    data.splice(1, 0, {
      PK: "0",
      SK: "0",
      full_name: "その他(手入力)",
      is_manual_input: true,
      is_vertical_writing_sign: false,
      seal_display_name: "",
      seal_file_path: null,
    });
    return data;
  };

  const getData = async () => {
    try {
      LoadingOverlayController.show();
      // マスタ一括取得
      let listReportItemClone = cloneDeep(listReportItem);
      const res = await getMasterByProjectId(project_id);
      if (res?.data) {
        const master = res?.data;
        // 外装破損発見場所
        if (master?.exterior_damaged_location) {
          listReportItemClone.list_exterior_damaged_location = addWhiteSpace(
            master.exterior_damaged_location,
          );
        }
        // 確認者
        if (master?.checke_user) {
          listReportItemClone.list_check_user = addWhiteSpace(
            master.checke_user,
          );
        }
        // 外装破損状況
        if (master?.damage_status) {
          listReportItemClone.list_damage_status = master.damage_status;
        }
        // 帳票タイトル
        if (master?.title) {
          listReportItemClone.list_title = master.title;
        }
        // 荷主
        if (master?.shipper) {
          listReportItemClone.list_shipper = master.shipper;
        }
        // 補充商品、割当商品数量文言
        if (master?.order_replenishment) {
          listReportItemClone.list_order_replenishment =
            master.order_replenishment;
        }
        // お客様注文商品文言
        if (master?.order_customer) {
          listReportItemClone.list_order_customer = master.order_customer;
        }
        // 仕入可文言
        if (master?.purchasing_statement) {
          listReportItemClone.list_purchasing_statement =
            master.purchasing_statement;
        }
        // 仕入不可文言
        if (master?.non_purchasing_statement) {
          listReportItemClone.list_non_purchasing_statement =
            master.non_purchasing_statement;
        }
        setListReportItem(listReportItemClone);
      }
      // アカウント取得
      const resListUser = await getListUserByLocationId();
      if (resListUser?.length) {
        const newListUser = addWhiteSpace(resListUser).concat();
        setListUser(cloneDeep(newListUser));
        setAddIsManualListUser(cloneDeep(addIsManual(newListUser)));
      }
      setCompleteGetData(true);
    } catch (err) {
      console.log(err);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    getData();
    return () => {
      setCompleteGetData(false);
    };
  }, []);

  const getCustomMasterDeffault = (data: any[]) => {
    // デフォルト値を取得
    let default_data = data.find((v) => v?.is_default);
    if (!default_data) {
      // デフォルト値がない場合は1つめを取得
      const listMaster = data.filter((v) => v);
      default_data = listMaster.length > 0 ? listMaster[0] : undefined;
    }
    return default_data;
  };

  const getSelectUserDefault = (
    field: keyof ExteriorDamagedTCFormType,
    listObject: Array<any>,
    SK?: string,
  ) => {
    const default_SK = SK ? SK : user.SK;
    const default_data = listObject.find((item) => default_SK === item?.SK);

    if (default_data) {
      return default_data;
    } else {
      return originData[field];
    }
  };

  const setDefaultValue = (
    field: keyof ExteriorDamagedTCFormType,
    listObject: Array<any>,
  ) => {
    const default_data = listObject.find((item) => item?.is_default === true);

    if (default_data) {
      return default_data;
    } else {
      return originData[field];
    }
  };

  useEffect(() => {
    if (open && completeGetData) {
      if (typeof infoToEdit?.memo === "string") {
        let infoEditClone: ExteriorDamagedTCFormType = {
          ...cloneDeep(infoToEdit),
        };
        if (step >= APPROVER_STEP_1 && !infoEditClone.item_check_user) {
          infoEditClone["item_check_user"] = getSelectUserDefault(
            "item_check_user",
            listUser,
          );
        }
        if (step >= APPROVER_STEP_2 && !infoEditClone.store_check_user) {
          infoEditClone["store_check_user"] = getSelectUserDefault(
            "store_check_user",
            listUser,
          );
        }
        setStateForm(cloneDeep(infoEditClone));
        setOriginStateForm(cloneDeep(infoEditClone));
      } else {
        const listTitle = getCustomMasterDeffault(
          listReportItem?.list_title ?? [],
        );
        const listShipper = getCustomMasterDeffault(
          listReportItem?.list_shipper ?? [],
        );
        const listOrderCustomer = getCustomMasterDeffault(
          listReportItem?.list_order_customer ?? [],
        );
        const listOrderReplenishment = getCustomMasterDeffault(
          listReportItem?.list_order_replenishment ?? [],
        );
        const listPurchasingStatement = getCustomMasterDeffault(
          listReportItem?.list_purchasing_statement ?? [],
        );
        const listNonPurchasingStatement = getCustomMasterDeffault(
          listReportItem?.list_non_purchasing_statement ?? [],
        );
        const listDamageStatus = getCustomMasterDeffault(
          listReportItem?.list_damage_status ?? [],
        );
        const infoEditClone = {
          ...cloneDeep(originData),
          risky_id: infoToEdit?.risky_id ?? "",
          exterior_damaged_location: setDefaultValue(
            "exterior_damaged_location",
            listReportItem.list_exterior_damaged_location,
          ),
          written_by: getSelectUserDefault("written_by", addIsManualListUser),
          checked_by: setDefaultValue(
            "checked_by",
            listReportItem.list_check_user,
          ),
          document_title: listTitle?.item1, // 帳票タイトル
          shipper_name: listShipper?.name, // 荷主名
          shipper_name_sub: listShipper?.item1, //荷主拠点名
          shipper_address: listShipper?.item2, // 荷主住所
          shipper_tel: listShipper?.item3, // 荷主TEL
          shipper_fax: listShipper?.item4, // 荷主FAX
          order_customer_label: listOrderCustomer?.item1, // お客様注文商品文言(見出し)
          order_customer_text: listOrderCustomer?.item2, // お客様注文商品文言(内容)
          order_replenishment_label: listOrderReplenishment?.item1, // 補充商品、割当商品文言(見出し)
          order_replenishment_text: listOrderReplenishment?.item2, // 補充商品、割当商品文言(内容)
          purchasing_statement: listPurchasingStatement?.item1, // 仕入可文言
          non_purchasing_statement: listNonPurchasingStatement?.item1, // 仕入不可文言
          status_text_1: listDamageStatus?.item1?.trim(), // 外装破損状況選択肢_1
          status_text_2: listDamageStatus?.item2?.trim(), // 外装破損状況選択肢_2
          status_text_3: listDamageStatus?.item3?.trim(), // 外装破損状況選択肢_3
          status_text_4: listDamageStatus?.item4?.trim(), // 外装破損状況選択肢_4
          status_text_5: listDamageStatus?.item5?.trim(), // 外装破損状況選択肢_5
          status_text_6: listDamageStatus?.item6?.trim(), // 外装破損状況選択肢_6
        };
        setStateForm(cloneDeep(infoEditClone));
        setOriginStateForm(cloneDeep(infoEditClone));
      }
    }
  }, [open, completeGetData, listReportItem, listUser, addIsManualListUser]);

  // テンプレートHTML =====================================
  const getHtmlString = useCallback(
    async (state_form: ExteriorDamagedTCFormType, html_String: string) => {
      setHtmlString(""); // エラーメッセージの変更時に再描画する為、htmlを変更
      const res = getInfoFromTemplate(html_String);
      // 画像をbase64に変換
      const sign_array = SIGNATURE_KEY.map((key) => state_form[key]);
      const sign_array_base64: Array<any> = await getImageArray(sign_array);
      let clone_state_form = cloneDeep(state_form);
      SIGNATURE_KEY.forEach((key, index) => {
        clone_state_form = {
          ...clone_state_form,
          [key]: sign_array_base64[index] ?? null,
        };
      });

      // HTML作成
      let newHtml: string = makeNewHtml(html_String, state_form);
      newHtml = insertReportLocal(newHtml, state_form, res.signatureExample);
      setHtmlString(newHtml);

      if (!isEqual(state_form, clone_state_form)) {
        setStateForm({ ...clone_state_form });
      }
    },
    [],
  );

  const changeForm = useCallback(debounce(getHtmlString, 300), [getHtmlString]);

  useEffect(() => {
    changeForm(stateForm, htmlString);
  }, [stateForm, htmlString, formError]);

  // 入力制御 =====================================
  const onChangeText =
    (field: keyof ExteriorDamagedTCFormType) =>
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setStateForm({ ...stateForm, [field]: e.target.value });
    };

  const onChangeSelect =
    (field: keyof ExteriorDamagedTCFormType, list: any[]) =>
    (e: SelectChangeEvent<string>) => {
      const selected = list.find((item) => item && item.SK === e.target.value);
      setStateForm((prev) => ({ ...prev, [field]: selected }));
    };

  const onChangeDate =
    (field: keyof ExteriorDamagedTCFormType) => (value: string | null) => {
      const mess = validation(field, value);
      if (mess.length == 0) {
        value = formatDateToString(value, "YMD");
      }
      setStateForm((prev) => ({ ...prev, [field]: value }));
    };

  const onBlurDate = (field: keyof IFormError) => {
    if (formError[field]?.length > 0) {
      setStateForm((prev) => ({ ...prev, [field]: "" }));
    }
  };

  const validation = (field: keyof ExteriorDamagedTCFormType, value: any) => {
    let mess: string = "";
    const list_field_name = {
      date: "日付",
      arrival_date: "入荷日",
      jan_code: "JANコード",
      supplier_code: "仕入先コード",
      product_type: "型名",
      total: "総外装破損数量",
      order_customer_count: "お客様注文商品数量",
      order_replenishment_count: "補充商品、割当商品数量",
      non_purchasing_count: "仕入不可商品数量",
    };
    switch (field) {
      case "date":
      case "arrival_date":
        mess = Validation.validateDate(
          value ?? "",
          list_field_name[field],
          false,
        );
        break;
      case "jan_code":
      case "supplier_code":
        mess = Validation.validate({
          type: "alpha_num",
          value: value,
          name: list_field_name[field],
        });
        break;
      case "product_type":
        mess = Validation.validate({
          type: "password",
          value: value,
          name: list_field_name[field],
        });
        break;
      case "total":
      case "order_customer_count":
      case "order_replenishment_count":
      case "non_purchasing_count":
        mess = Validation.validate({
          type: "number",
          value: value,
          name: list_field_name[field],
        });
        break;
    }

    setFormError({ ...formError, [field]: mess });
    return mess;
  };

  // 保存 =====================================
  const onSubmit = async () => {
    try {
      LoadingOverlayController.show();
      let newStateForm = { ...stateForm };
      // 画像アップロード
      let newImageArray: Array<Type.ImageInfoType> =
        stateForm.image_array ?? [];
      if (stateForm.image_array) {
        newImageArray = await uploadImageToS3(stateForm.image_array);
      }
      newStateForm = {
        ...newStateForm,
        image_array: newImageArray,
      };
      // サインアップロード
      for (let index = 0; index < SIGNATURE_KEY.length; index++) {
        const key = SIGNATURE_KEY[index];
        if (stateForm[key]) {
          const newSignDate = await uploadImageToS3(
            [stateForm[key]],
            Date.now(),
          );
          newStateForm = { ...newStateForm, [key]: newSignDate[0] };
        }
      }
      await setStateForm(newStateForm);
    } catch (e) {
      console.log("error onSubmit", e);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // 画面制御 =====================================
  const handleBarcodeProduct = async (value: string) => {
    // JANコードと拠点コードから外装破損商品情報を取得
    if (value) {
      const param = {
        activity_base_id: location_id,
        jan_code: value,
      };
      const productInfo = await getExteriorDamagedProductInfo(param);
      if (productInfo?.data && productInfo.data.length > 0) {
        const data = productInfo.data[0];
        setStateForm({
          ...stateForm,
          jan_code: value,
          product_name: data.item2 ?? "",
          product_type: data.item3 ?? "",
          supplier_code: data.item4 ?? "",
          supplier_name: data.item5 ?? "",
        });
      } else {
        //
        ModalController.show({
          message: messages.TEMPLATE.MSG_CUSTOM_PRODUCT_NOT_EXIST,
          visibleButton2: true,
        });
      }
    }
  };

  const getSignatureUrlImage = (value: Type.ImageInfoType | null) => {
    let url = "";
    if (value?.type === "image/svg+xml") {
      if (value?.non_Local) {
        url = value.uri_jpg ?? "";
      } else {
        url = value.uriThumb ?? "";
      }
    } else {
      url = value?.uri ?? "";
    }
    return url;
  };

  const renderMenuItem = useCallback(
    (list: Array<any>, value: string, name: string) => {
      return list
        ? list.map((item, index) => (
            <MenuItem value={item ? item[value] : ""} key={index}>
              {item ? (
                <span
                  className={
                    item[value] !== "0" ? "IgnoreExtractRuleTarget" : undefined
                  }
                >
                  {item[name]}
                </span>
              ) : (
                <em>未選択</em>
              )}
            </MenuItem>
          ))
        : null;
    },
    [],
  );

  const _renderStatus = React.useCallback(() => {
    return [...Array(6)].map((_, index) => {
      const key = index + 1;
      let status_key = `status_${key}` as keyof ExteriorDamagedTCFormType;
      let text_key = `status_text_${key}` as keyof ExteriorDamagedTCFormType;
      return stateForm[text_key] ? (
        <CheckboxLabel
          label={stateForm[text_key] ?? ""}
          checked={stateForm[status_key] ?? false}
          onChange={(_, checked) => {
            setStateForm((prev) => ({
              ...prev,
              [status_key]: checked,
            }));
          }}
          key={index}
        />
      ) : null;
    });
  }, [stateForm]);

  return (
    <Box height={"auto"}>
      <Stack>
        <Card>
          <CardContent>
            <Stack>
              <FormGroup>
                <DatePickerCustom
                  label="日付"
                  value={stateForm.date}
                  onChange={onChangeDate("date")}
                  onBlur={() => onBlurDate("date")}
                  helperText={formError.date}
                  error={formError.date.length > 0}
                />
              </FormGroup>
              <FormGroup>
                <DatePickerCustom
                  label="入荷日"
                  value={stateForm.arrival_date}
                  onChange={onChangeDate("arrival_date")}
                  onBlur={() => onBlurDate("arrival_date")}
                  helperText={formError.arrival_date}
                  error={formError.arrival_date.length > 0}
                />
              </FormGroup>
              <SelectLabel
                label="外装破損発見場所"
                value={stateForm.exterior_damaged_location?.SK ?? ""}
                onChange={onChangeSelect(
                  "exterior_damaged_location",
                  listReportItem.list_exterior_damaged_location,
                )}
              >
                {renderMenuItem(
                  listReportItem.list_exterior_damaged_location,
                  "SK",
                  "item1",
                )}
              </SelectLabel>
              <TextField
                label={"外装破損発見場所（その他）"}
                value={stateForm.exterior_damaged_location_other}
                disabled={!stateForm.exterior_damaged_location?.is_manual_input}
                inputProps={{
                  maxLength: 8,
                }}
                onChange={onChangeText("exterior_damaged_location_other")}
              />
              <SelectLabel
                label="庫内記入者"
                value={stateForm.written_by?.SK ?? ""}
                onChange={onChangeSelect("written_by", addIsManualListUser)}
              >
                {renderMenuItem(addIsManualListUser, "SK", "full_name")}
              </SelectLabel>
              <TextField
                label={"庫内記入者（その他）"}
                value={stateForm.written_by_other}
                disabled={!stateForm.written_by?.is_manual_input}
                inputProps={{
                  maxLength: 12,
                }}
                onChange={onChangeText("written_by_other")}
              />
              <SelectLabel
                label="確認者"
                value={stateForm.checked_by?.SK ?? ""}
                onChange={onChangeSelect(
                  "checked_by",
                  listReportItem.list_check_user,
                )}
              >
                {renderMenuItem(listReportItem.list_check_user, "SK", "item1")}
              </SelectLabel>
              <TextField
                label={"確認者（その他）"}
                value={stateForm.checked_by_other}
                disabled={!stateForm.checked_by?.is_manual_input}
                inputProps={{
                  maxLength: 12,
                }}
                onChange={onChangeText("checked_by_other")}
              />
              <Box sx={{ display: "flex" }}>
                <TextField
                  label={"JANコード"}
                  value={stateForm.jan_code}
                  inputProps={{
                    maxLength: 13,
                  }}
                  sx={{ flex: 1 }}
                  onChange={onChangeText("jan_code")}
                  onBlur={() => validation("jan_code", stateForm.jan_code)}
                  error={formError.jan_code.length > 0}
                  helperText={formError.jan_code}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignSelf: "flex-start",
                    ml: 1,
                    mt: "36px",
                  }}
                >
                  <Button
                    sx={{ minWidth: 60 }}
                    onClick={() => handleBarcodeProduct(stateForm.jan_code)}
                  >
                    検索
                  </Button>
                </Box>
              </Box>
              <TextField
                label={"仕入先コード"}
                value={stateForm.supplier_code}
                inputProps={{
                  maxLength: 16,
                }}
                onChange={onChangeText("supplier_code")}
                onBlur={() =>
                  validation("supplier_code", stateForm.supplier_code)
                }
                error={formError.supplier_code.length > 0}
                helperText={formError.supplier_code}
              />
              <TextField
                label={"仕入先名"}
                value={stateForm.supplier_name}
                inputProps={{
                  maxLength: 14,
                }}
                onChange={onChangeText("supplier_name")}
              />
              <TextField
                label={"商品名"}
                value={stateForm.product_name}
                inputProps={{
                  maxLength: 20,
                }}
                onChange={onChangeText("product_name")}
              />
              <TextField
                label={"型名"}
                value={stateForm.product_type}
                inputProps={{
                  maxLength: 43,
                }}
                onChange={onChangeText("product_type")}
                onBlur={() =>
                  validation("product_type", stateForm.product_type)
                }
                error={formError.product_type.length > 0}
                helperText={formError.product_type}
              />
              <StepperInputLabel
                label="総外装破損数量"
                value={stateForm.total}
                maxValue={999999999}
                onChange={(v) => {
                  const value_num = isNaN(Number(v)) ? v : Number(v);
                  validation("total", v);
                  setStateForm({ ...stateForm, total: value_num });
                }}
                error={formError.total?.length > 0}
                helperText={formError.total}
                sx={{ width: 140 }}
              />
              <FormControl>
                <InputLabel>外装破損状況</InputLabel>
                {_renderStatus()}
              </FormControl>
              <FormControl>
                <InputLabel>状況写真</InputLabel>
                <ImagePickerList
                  addButtonText={"状況写真を追加"}
                  maxImage={maxImage}
                  imagesList={stateForm.image_array}
                  onChange={(image_array) => {
                    setStateForm((prev) => ({ ...prev, image_array }));
                  }}
                />
              </FormControl>
              <SignatureInput
                ref={(v) => {
                  signatureRef.current = {
                    ...signatureRef.current,
                    sign_date: v,
                  };
                }}
                label={"日付"}
                signPath={getSignatureUrlImage(stateForm.sign_date)}
                onChange={(value) => {
                  setStateForm((pre) => {
                    return { ...pre, sign_date: value || null };
                  });
                }}
                onOpenNext={() => {
                  if (signatureRef.current) {
                    signatureRef.current["sign_company"].openModal();
                  }
                }}
                disabledPrev
              />
              <SignatureInput
                ref={(v) => {
                  signatureRef.current = {
                    ...signatureRef.current,
                    sign_company: v,
                  };
                }}
                label={"運送会社"}
                signPath={getSignatureUrlImage(stateForm.sign_company)}
                onChange={(value) => {
                  setStateForm((pre) => {
                    return { ...pre, sign_company: value || null };
                  });
                }}
                onOpenPrev={() => {
                  if (signatureRef.current) {
                    signatureRef.current["sign_date"].openModal();
                  }
                }}
                onOpenNext={() => {
                  if (signatureRef.current) {
                    signatureRef.current["sign_car_no"].openModal();
                  }
                }}
              />
              <SignatureInput
                ref={(v) => {
                  signatureRef.current = {
                    ...signatureRef.current,
                    sign_car_no: v,
                  };
                }}
                label={"車番"}
                signPath={getSignatureUrlImage(stateForm.sign_car_no)}
                onChange={(value) => {
                  setStateForm((pre) => {
                    return { ...pre, sign_car_no: value || null };
                  });
                }}
                onOpenPrev={() => {
                  if (signatureRef.current) {
                    signatureRef.current["sign_company"].openModal();
                  }
                }}
                onOpenNext={() => {
                  if (signatureRef.current) {
                    signatureRef.current["sign_name"].openModal();
                  }
                }}
              />
              <SignatureInput
                ref={(v) => {
                  signatureRef.current = {
                    ...signatureRef.current,
                    sign_name: v,
                  };
                }}
                label={"氏名"}
                signPath={getSignatureUrlImage(stateForm.sign_name)}
                onChange={(value) => {
                  setStateForm((pre) => {
                    return { ...pre, sign_name: value || null };
                  });
                }}
                onOpenPrev={() => {
                  if (signatureRef.current) {
                    signatureRef.current["sign_car_no"].openModal();
                  }
                }}
                disabledNext
              />
              <FormControl>
                <CheckboxLabel
                  label="①仕入れました"
                  checked={stateForm.purchasing}
                  onChange={(_, checked) => {
                    setStateForm((prev) => ({
                      ...prev,
                      purchasing: checked,
                    }));
                  }}
                />
              </FormControl>
              <StepperInputLabel
                label="お客様注文商品数量"
                value={stateForm.order_customer_count}
                maxValue={9999999}
                onChange={(v) => {
                  const value_num = isNaN(Number(v)) ? v : Number(v);
                  validation("order_customer_count", v);
                  setStateForm({
                    ...stateForm,
                    order_customer_count: value_num,
                  });
                }}
                error={formError.order_customer_count?.length > 0}
                helperText={formError.order_customer_count}
                sx={{ width: 140 }}
                disabled={!stateForm.purchasing}
              />
              <StepperInputLabel
                label="補充商品、割当商品数量"
                value={stateForm.order_replenishment_count}
                maxValue={9999999}
                onChange={(v) => {
                  const value_num = isNaN(Number(v)) ? v : Number(v);
                  validation("order_replenishment_count", v);
                  setStateForm({
                    ...stateForm,
                    order_replenishment_count: value_num,
                  });
                }}
                error={formError.order_replenishment_count?.length > 0}
                helperText={formError.order_replenishment_count}
                sx={{ width: 140 }}
                disabled={!stateForm.purchasing}
              />
              <FormControl>
                <CheckboxLabel
                  label="②仕入不可"
                  checked={stateForm.non_purchasing}
                  onChange={(_, checked) => {
                    setStateForm((prev) => ({
                      ...prev,
                      non_purchasing: checked,
                    }));
                  }}
                />
              </FormControl>
              <StepperInputLabel
                label="仕入不可商品数量"
                value={stateForm.non_purchasing_count}
                maxValue={9999999}
                onChange={(v) => {
                  const value_num = isNaN(Number(v)) ? v : Number(v);
                  validation("non_purchasing_count", v);
                  setStateForm({
                    ...stateForm,
                    non_purchasing_count: value_num,
                  });
                }}
                error={formError.non_purchasing_count?.length > 0}
                helperText={formError.non_purchasing_count}
                sx={{ width: 140 }}
                disabled={!stateForm.non_purchasing}
              />
              <TextField
                label="備考"
                multiline
                value={stateForm.memo}
                rows={2}
                inputProps={{
                  maxLength: 150,
                }}
                onChange={(e) => {
                  if (e.target.value.split("\n").length > 2) {
                    return;
                  }
                  onChangeText("memo")(e);
                }}
                placeholder="メモ欄（最大150文字）"
              />
              {step >= APPROVER_STEP_1 && (
                <SelectLabel
                  label="現品確認"
                  value={stateForm.item_check_user?.SK ?? ""}
                  onChange={onChangeSelect("item_check_user", listUser)}
                >
                  {renderMenuItem(listUser, "SK", "full_name")}
                </SelectLabel>
              )}
              {step >= APPROVER_STEP_2 && (
                <SelectLabel
                  label="格納確認"
                  value={stateForm.store_check_user?.SK ?? ""}
                  onChange={onChangeSelect("store_check_user", listUser)}
                >
                  {renderMenuItem(listUser, "SK", "full_name")}
                </SelectLabel>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
};

export default forwardRef(ExteriorDamagedTCReport);
