import ExcelJS from "exceljs";

export interface IMasterList {
  id: string;
  name: string;
  group: string;
  is_new?: boolean;
}

export const LIST_GROUP = {
  MASTER: { id: "master", name: "マスタ" },
  USER_MASTER: { id: "user_master", name: "ユーザー作成マスタ" },
  ACCOUNT: { id: "account", name: "アカウント" }
};

export const LIST_MASTER: Array<IMasterList> = [
  // 庫内商品事故報告書
  {
    id: "OCCURRENCE_LOCATION_LIST",
    name: "発生場所・格納場所",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "PUBLISHER_LIST",
    name: "発行元",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "PRODUCT_STATUS_LIST",
    name: "商品状態",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "ACCIDENT_REASON_LIST",
    name: "事故発生理由",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "ACCIDENT_HANDLING_LIST",
    name: "事故処理",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "SHIPPER_LIST",
    name: "荷主",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "SPAN_LIST",
    name: "スパン",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "IMPORT_DIVISION_LIST",
    name: "重要度区分",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "PRODUCT_LIST",
    name: "事故商品",
    group: LIST_GROUP.MASTER.name,
  },
  // 日次フォークリフト点検簿
  {
    id: "FUEL",
    name: "燃料・車種名",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "CHECK_SECTION",
    name: "点検箇所",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "CHECK_ITEM",
    name: "点検項目",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "WORKPLACE_LIST",
    name: "事業場",
    group: LIST_GROUP.MASTER.name,
  },
  {
    id: "VEHICLES",
    name: "車両",
    group: LIST_GROUP.MASTER.name,
  },
  //TLGTマスタ
  { id: "TLGT_M01", name: "M1_Warehouse", group: LIST_GROUP.MASTER.name, },
  { id: "TLGT_M02", name: "M2_Area", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M03", name: "M3_Reported by", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M04", name: "M4_Customer(Shipper)", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M05", name: "M5_Commodity", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M06", name: "M6_Damage Location", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M07", name: "M7_Cause(Site Opinion)", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M08", name: "M8_QA Authorizer", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M09", name: "M9_Occurred Company", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M10", name: "M10_Employee's Affiliation", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M11", name: "M11_Waste / Claim Category", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M12", name: "M12_Accident Rank", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M13", name: "M13_Cause Overview", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M14", name: "M14_Root Cause", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M15", name: "M15_Logistics Equipments", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M16", name: "M16_Corrective Action / Improvement", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M17", name: "M17_Final Payer", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M18", name: "M18_Est / Acc", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M19", name: "M19_Defect", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M20", name: "M20_Repaired Cargo Receive by", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M21", name: "M21_Load by", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M22", name: "M22_Container Type", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_M23", name: "M23_Customer Judgement", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_MA", name: "MA", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_NONCONFORMITY_DETAIL", name: "Nonconformity Detail", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_NONCONFORMITY_OVERVIEW", name: "Nonconformity Overview", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_OCCURANCE_PROCESS", name: "Occurance Process", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_PROCESS_OF_ROOT_CAUSE", name: "Process of Root Cause", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_RESPONSIBILITY", name: "Responsibility", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_DEPARTMENT_OF_PIC", name: "Department of PIC", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_PROCESS_IN_LOGISTICS", name: "Process in Logistics", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_RESPONSIBILITY_IN_PROCESS", name: "Responsibility in Process", group: LIST_GROUP.MASTER.name },
  {
    id: "TLGT_CLASSIFICATION_OF_NONCONFORMITY",
    name: "Classification of Nonconformity",
    group: LIST_GROUP.MASTER.name
  },
  { id: "TLGT_CLASSIFICATION_OF_CAUSE", name: "Classification of Cause", group: LIST_GROUP.MASTER.name },
  { id: "TLGT_MODEL_NAME", name: "Model Name", group: LIST_GROUP.MASTER.name },
];

export const LIST_ACCOUNT: Array<IMasterList> = [
  { id: "ACCOUNT_CREATE", name: "アカウント作成", group: LIST_GROUP.ACCOUNT.name }
]

export const LIST_EXPORT_MASTER = LIST_MASTER.slice(0, 14);

export const LIST_EXPORT_ACCOUNT: Array<IMasterList> = [
  { id: "ACCOUNT", name: "アカウント", group: LIST_GROUP.ACCOUNT.name }
]

// ユーザー作成マスタ
export const NewUserMaster: IMasterList = {
  id: "NEW",
  name: "新規作成",
  group: LIST_GROUP.USER_MASTER.name,
  is_new: true,
};

/***
 * 雛形のヘッダ（1列目は各マスタの名称とする）
 */
export const LIST_EXPORT_CSV: { [id: string]: Array<Partial<ExcelJS.Column>> } =
{
  // 庫内商品事故報告書
  OCCURRENCE_LOCATION_LIST: [
    { header: "発生場所・格納場所名（必須）", key: "occurrence_location_name" },
    { header: "手入力有り", key: "is_manual_input" },
    { header: "デフォルト値", key: "is_default" },
  ],
  PUBLISHER_LIST: [
    { header: "発行元名（必須）", key: "publisher_name" },
    { header: "手入力有り", key: "is_manual_input" },
    { header: "デフォルト値", key: "is_default" },
  ],
  PRODUCT_STATUS_LIST: [
    { header: "商品状態名（必須）", key: "product_status_name" },
    { header: "手入力有り", key: "is_manual_input" },
    { header: "デフォルト値", key: "is_default" },
  ],
  ACCIDENT_REASON_LIST: [
    { header: "事故発生理由名（必須）", key: "accident_reason_name" },
    { header: "手入力有り", key: "is_manual_input" },
    { header: "デフォルト値", key: "is_default" },
  ],
  ACCIDENT_HANDLING_LIST: [
    { header: "事故処理名（必須）", key: "accident_handling_name" },
    { header: "手入力有り", key: "is_manual_input" },
    { header: "デフォルト値", key: "is_default" },
  ],
  SHIPPER_LIST: [
    { header: "荷主名（必須）", key: "shipper_name" },
    { header: "手入力有り", key: "is_manual_input" },
    { header: "デフォルト値", key: "is_default" },
  ],
  SPAN_LIST: [
    { header: "スパン名（必須）", key: "span_name" },
    { header: "手入力有り", key: "is_manual_input" },
    { header: "デフォルト値", key: "is_default" },
  ],
  IMPORT_DIVISION_LIST: [
    { header: "重要度区分名（必須）", key: "import_division_name" },
    { header: "手入力有り", key: "is_manual_input" },
    { header: "デフォルト値", key: "is_default" },
  ],
  PRODUCT_LIST: [
    { header: "事故商品名（必須）", key: "product_name" },
    { header: "JANコード（必須）", key: "jan_code" },
    { header: "手入力有り", key: "is_manual_input" },
    { header: "デフォルト値", key: "is_default" },
  ],
  // 日次フォークリフト点検簿
  FUEL: [{ header: "燃料・車種名（必須）", key: "fuel_name" }],
  CHECK_SECTION: [{
    header: "燃料（必須）", key: "fuel_name"
  }, { header: "点検箇所（必須）", key: "check_section_name" }],
  CHECK_ITEM: [
    { header: "燃料（必須）", key: "fuel_name" },
    { header: "点検箇所（必須）", key: "check_section_name" },
    { header: "点検項目（必須）", key: "check_item_name" },
  ],
  WORKPLACE_LIST: [{ header: "事業場名（必須）", key: "workplace_name" }],

  VEHICLES: [
    { header: "車両名称（必須）", key: "vehicles_name" },
    { header: "車体番号（必須）", key: "vehicles_frame_no" },
    { header: "車種（必須）", key: "vehicles_model" },
    { header: "燃料（必須）", key: "vehicles_fuel_type" },
    { header: "使用場所（必須）", key: "vehicles_workplace" },
  ],
  // ユーザー作成マスタ
  [LIST_GROUP.USER_MASTER.id]: [
    { header: "名称（必須）", key: "name" },
    { header: "手入力有り", key: "is_manual_input" },
    { header: "デフォルト値", key: "is_default" },
  ],
  TLGT_M01: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M02: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M03: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M04: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M05: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M06: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M07: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M08: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M09: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M10: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M11: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M12: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M13: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M14: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M15: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M16: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M17: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M18: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M19: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M20: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M21: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M22: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_M23: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_MA: [
    { header: "Nonconformity Detail（必須）" },
    { header: "Nonconformity Overview（必須）" },
    { header: "Occurance Process（必須）" },
    { header: "Process of Root Cause（必須）" },
    { header: "Responsibility（必須）" },
    { header: "Department of PIC（必須）" },
    { header: "Process in Logistics（必須）" },
    { header: "Responsibility in Process（必須）" },
    { header: "Classification of Nonconformity（必須）" },
    { header: "Classification of Cause（必須）" },
    { header: "デフォルト値" }
  ],
  TLGT_NONCONFORMITY_DETAIL: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_NONCONFORMITY_OVERVIEW: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_OCCURANCE_PROCESS: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_PROCESS_OF_ROOT_CAUSE: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_RESPONSIBILITY: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_DEPARTMENT_OF_PIC: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_PROCESS_IN_LOGISTICS: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_RESPONSIBILITY_IN_PROCESS: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_CLASSIFICATION_OF_NONCONFORMITY: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  TLGT_CLASSIFICATION_OF_CAUSE: [{ header: "名称（必須）" }, { header: "デフォルト値" }],
  ACCOUNT_CREATE: [{ header: "ログインID（必須）", key: "preferred_username_cognito" },
  { header: "パスワード(必須)", key: "password" },
  { header: "名前(必須)", key: "full_name" },
  { header: "メールアドレス", key: "email" },
  { header: "権限(必須)", key: "user_role" },
  { header: "グループ名", key: "belong_company_name" },
  { header: "役職", key: "position_name" },
  { header: "ユーザー作成帳票作成可能", key: "can_create_user_report" },
  { header: "電子印の表示名", key: "seal_display_name" }, //スーパーユーザーの場合はいらない
  { header: "電子印の表示名を縦表示", key: "is_vertical_writing_sign" }, //スーパーユーザーの場合はいらない
  { header: "拠点", key: "location_name" },], //スーパーユーザーの場合はいらない
  TLGT_MODEL_NAME: [{ header: "Code（必須）" }, { header: "Model Name（必須）" }, { header: "デフォルト値" }],
};

export const getRequiredColumn = (id: string) => {
  switch (id) {
    case "OCCURRENCE_LOCATION_LIST": //発生場所・格納場所
    case "PUBLISHER_LIST": //発行元名
    case "PRODUCT_STATUS_LIST": //商品状態
    case "ACCIDENT_REASON_LIST": //事故発生理由
    case "ACCIDENT_HANDLING_LIST": //事故処理名
    case "SHIPPER_LIST": //荷主
    case "SPAN_LIST": //スパン
    case "IMPORT_DIVISION_LIST": //"重要度区分
    case "FUEL": //燃料・車種
    case "WORKPLACE_LIST": // 事業場
    case LIST_GROUP.USER_MASTER.id: //ユーザー作成マスタ
    case "TLGT_M01":
    case "TLGT_M02":
    case "TLGT_M03":
    case "TLGT_M04":
    case "TLGT_M05":
    case "TLGT_M06":
    case "TLGT_M07":
    case "TLGT_M08":
    case "TLGT_M09":
    case "TLGT_M10":
    case "TLGT_M11":
    case "TLGT_M12":
    case "TLGT_M13":
    case "TLGT_M14":
    case "TLGT_M15":
    case "TLGT_M16":
    case "TLGT_M17":
    case "TLGT_M18":
    case "TLGT_M19":
    case "TLGT_M20":
    case "TLGT_M21":
    case "TLGT_M22":
    case "TLGT_M23":
    case "TLGT_NONCONFORMITY_DETAIL":
    case "TLGT_NONCONFORMITY_OVERVIEW":
    case "TLGT_OCCURANCE_PROCESS":
    case "TLGT_PROCESS_OF_ROOT_CAUSE":
    case "TLGT_RESPONSIBILITY":
    case "TLGT_DEPARTMENT_OF_PIC":
    case "TLGT_PROCESS_IN_LOGISTICS":
    case "TLGT_RESPONSIBILITY_IN_PROCESS":
    case "TLGT_CLASSIFICATION_OF_NONCONFORMITY":
    case "TLGT_CLASSIFICATION_OF_CAUSE":
      return [0];
    case "PRODUCT_LIST": //事故商品
    case "CHECK_SECTION": //点検箇所
    case "TLGT_MODEL_NAME": //model name
      return [0, 1];
    case "CHECK_ITEM": //点検項目
      return [0, 1, 2];
    case "VEHICLES": //車両
      return [0, 1, 2, 3, 4];
    case "TLGT_MA":
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9,];
    case "CREATE_NOT_SUPER_USER":
      return [0, 1, 2, 4, 8, 10];
    case "CREATE_SUPER_USER":
      return [0, 1, 2, 4];
    default:
      return [];
  }
};

//不必要なカラム
export const getNotRequiredColumn = (id: string) => {
  switch (id) {
    case "CREATE_SUPER_USER":
      return [8, 9, 10];
    default:
      return [];
  }
};

export const getDefaultColumnIndex = (id: string) => {
  switch (id) {
    case "TLGT_M01":
    case "TLGT_M02":
    case "TLGT_M03":
    case "TLGT_M04":
    case "TLGT_M05":
    case "TLGT_M06":
    case "TLGT_M07":
    case "TLGT_M08":
    case "TLGT_M09":
    case "TLGT_M10":
    case "TLGT_M11":
    case "TLGT_M12":
    case "TLGT_M13":
    case "TLGT_M14":
    case "TLGT_M15":
    case "TLGT_M16":
    case "TLGT_M17":
    case "TLGT_M18":
    case "TLGT_M19":
    case "TLGT_M20":
    case "TLGT_M21":
    case "TLGT_M22":
    case "TLGT_M23":
    case "TLGT_NONCONFORMITY_DETAIL":
    case "TLGT_NONCONFORMITY_OVERVIEW":
    case "TLGT_OCCURANCE_PROCESS":
    case "TLGT_PROCESS_OF_ROOT_CAUSE":
    case "TLGT_RESPONSIBILITY":
    case "TLGT_DEPARTMENT_OF_PIC":
    case "TLGT_PROCESS_IN_LOGISTICS":
    case "TLGT_RESPONSIBILITY_IN_PROCESS":
    case "TLGT_CLASSIFICATION_OF_NONCONFORMITY":
    case "TLGT_CLASSIFICATION_OF_CAUSE":
      return 1;
    case "OCCURRENCE_LOCATION_LIST": //発生場所・格納場所
    case "PUBLISHER_LIST": //発行元名
    case "PRODUCT_STATUS_LIST": //商品状態
    case "ACCIDENT_REASON_LIST": //事故発生理由
    case "ACCIDENT_HANDLING_LIST": //事故処理名
    case "SHIPPER_LIST": //荷主
    case "SPAN_LIST": //スパン
    case "IMPORT_DIVISION_LIST": //"重要度区分
    case LIST_GROUP.USER_MASTER.id: //ユーザー作成マスタ
    case "TLGT_MODEL_NAME":
      return 2;
    case "PRODUCT_LIST": //事故商品
      return 3;
    case "TLGT_MA":
      return 10;
    case "FUEL": //燃料・車種
    case "CHECK_SECTION": // 点検箇所
    case "CHECK_ITEM": //点検項目
    case "WORKPLACE_LIST": // 事業場
    case "VEHICLES": //車両
      return null;
    default:
      return null;
  }
};

export const getColumnMaxlength = (
  id: string,
): { [index: string]: { maxlen: number } } => {
  switch (id) {
    case "OCCURRENCE_LOCATION_LIST": //発生場所・格納場所
    case "PUBLISHER_LIST": //発行元名
    case "PRODUCT_STATUS_LIST": //商品状態
    case "ACCIDENT_REASON_LIST": //事故発生理由
    case "ACCIDENT_HANDLING_LIST": //事故処理名
    case "SHIPPER_LIST": //荷主
    case "SPAN_LIST": //スパン
    case "IMPORT_DIVISION_LIST": //"重要度区分
    case LIST_GROUP.USER_MASTER.id: //ユーザー作成マスタ
    case "TLGT_MODEL_NAME":
    case "TLGT_PROCESS_IN_LOGISTICS":
    case "TLGT_RESPONSIBILITY_IN_PROCESS":
      return { "0": { maxlen: 30 } };
    case "TLGT_MODEL_NAME":
      return { "0": { maxlen: 13 }, "1": { maxlen: 30 } };
    case "PRODUCT_LIST": //事故商品
      return { "0": { maxlen: 50 }, "1": { maxlen: 13 } };
    case "FUEL": //燃料・車種
      return { "0": { maxlen: 20 } };
    case "CHECK_SECTION": //点検箇所
      return { "1": { maxlen: 7 } };
    case "CHECK_ITEM": //点検項目
      return { "1": { maxlen: 7 }, "2": { maxlen: 18 } };
    case "WORKPLACE_LIST": // 事業場
      return { "0": { maxlen: 20 } };
    case "VEHICLES": //車両
      return { "0": { maxlen: 50 }, "1": { maxlen: 20 }, "2": { maxlen: 20 } };
    case "TLGT_M01":
    case "TLGT_M09":
    case "TLGT_M10":
      return { "0": { maxlen: 16 } }
    case "TLGT_M02":
    case "TLGT_M11":
    case "TLGT_RESPONSIBILITY":
      return { "0": { maxlen: 24 } }
    case "TLGT_M03":
    case "TLGT_M08":
      return { "0": { maxlen: 40 } }
    case "TLGT_M04":
    case "TLGT_M05":
    case "TLGT_M17":
      return { "0": { maxlen: 12 } }
    case "TLGT_M06":
      return { "0": { maxlen: 3 } }
    case "TLGT_M07":
    case "TLGT_M13":
    case "TLGT_M14":
    case "TLGT_M16":
    case "TLGT_NONCONFORMITY_DETAIL":
    case "TLGT_NONCONFORMITY_OVERVIEW":
    case "TLGT_CLASSIFICATION_OF_NONCONFORMITY":
    case "TLGT_CLASSIFICATION_OF_CAUSE":
      return { "0": { maxlen: 80 } }
    case "TLGT_M12":
      return { "0": { maxlen: 2 } }
    case "TLGT_M15":
      return { "0": { maxlen: 32 } }
    case "TLGT_M18":
      return { "0": { maxlen: 8 } }
    case "TLGT_OCCURANCE_PROCESS":
    case "TLGT_PROCESS_OF_ROOT_CAUSE":
      return { "0": { maxlen: 4 } }
    case "TLGT_DEPARTMENT_OF_PIC":
      return { "0": { maxlen: 6 } }
    case "TLGT_MA":
      return {
        "0": { maxlen: 80 },
        "1": { maxlen: 80 },
        "2": { maxlen: 80 },
        "3": { maxlen: 80 },
        "4": { maxlen: 80 },
        "5": { maxlen: 80 },
        "6": { maxlen: 80 },
        "7": { maxlen: 80 },
        "8": { maxlen: 80 },
        "9": { maxlen: 80 }
      }
    case "ACCOUNT_CREATE":
      return {
        "0": { maxlen: 30 },
        "1": { maxlen: 30 },
        "2": { maxlen: 30 },
        "3": { maxlen: 256 },
        "5": { maxlen: 30 },
        "6": { maxlen: 30 },
        "8": { maxlen: 30 },
        "10": { maxlen: 20 },
      };
    default:
      return {};
  }
};

export const getColumnMinlength = (
  id: string,
): { [index: string]: { minlen: number } } => {
  switch (id) {
    case "ACCOUNT_CREATE":
      return {
        "0": { minlen: 6 },
        "1": { minlen: 6 },
      }
    default:
      return {};
  }
};
