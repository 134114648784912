import ExcelJS from "exceljs";

export type Tformat = "csv" | "xlsx";
export type Tencoding = "SJIS" | "UTF8";
export interface IdownloadXlsx {
  data: any[];
  columns: Array<Partial<ExcelJS.Column>>;
  filename: string;
  format?: Tformat;
  encoding?: Tencoding;
}

export const downloadXlsx = async ({
  data,
  columns,
  filename,
  format = "csv",
  encoding = "UTF8",
}: IdownloadXlsx) => {
  // 出力ファイル作成
  const workbook = new ExcelJS.Workbook();
  workbook.addWorksheet("sheet1");
  const worksheet = workbook.getWorksheet("sheet1");
  worksheet.columns = columns;
  worksheet.addRows(data);
  // テーマフォントの設定
  const font = {
    name: "Meiryo UI", // フォント名
    size: 11, // フォントサイズ
    bold: false, // 太字
    color: { argb: "FF000000" } // 文字色
  };
  worksheet.eachRow((row, _) => {
    row.eachCell((cell, _) => {
        if(cell.value && typeof cell.value === "string"){
          const isStartsHttp = cell.value.startsWith("https://")
          if(isStartsHttp){
            cell.value =  { text: cell.value, hyperlink: cell.value }
          }
        }
        cell.font = font;
    })
  })




  // 文字コード変換
  let buf;
  switch (format) {
    case "csv":
      const options = {
        formatterOptions: {
          quote: true,
          quoteColumns: true,
          quoteHeaders: true,
        },
      };

      if (encoding === "UTF8") {
        const options_utf8 = {
          formatterOptions: {
            ...options.formatterOptions,
            writeBOM: true,
          },
        };
        buf = await workbook.csv.writeBuffer(options_utf8);
      } else {
        const Encoding = require("encoding-japanese");
        buf = new Uint8Array(
          Encoding.convert(await workbook.csv.writeBuffer(options), {
            from: "UTF8",
            to: "SJIS",
          })
        );
      }
      break;

    case "xlsx":
      buf = await workbook.xlsx.writeBuffer();
      break;
  }

  // ファイル出力
  const blob = new Blob([buf], {
    type: "application/octet-binary",
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
  a.remove();
};
