import React, { useMemo, useRef } from "react";
import {
  Box,
  Card,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Typography,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import Dropzone from "components/atoms/Dropzone";
import { TemplateExcelDataInfo } from "services/models";
import { createObjectURL, labeltextBreak } from "@utils/index";
import LabelRequired from "components/atoms/LabelRequired";

interface IAttachedFileField {
  setting: TemplateExcelDataInfo;
  value: any[];
  onChange: (value: any[]) => void;
}

const AttachedFileField = ({
  setting,
  value,
  onChange,
}: IAttachedFileField) => {
  const dropzoneRef = useRef<any>(null);

  const files = useMemo(() => {
    return value?.map((file: any, index) => (
      <React.Fragment key={index}>
        <Box sx={{ display: "flex", flexDirection: "row", py: 2, px: 1 }}>
          <Box>
            <Typography>{file.path}</Typography>
            <Typography variant="body2">
              {file.size ? (Number(file.size) / (1024 * 1024)).toFixed(2) : 0}
              MB
            </Typography>
          </Box>
          <Box flexGrow={1} />
          <IconButton
            onClick={() => {
              if (dropzoneRef.current) {
                dropzoneRef.current.delData(file);
              }
            }}
          >
            <Cancel />
          </IconButton>
        </Box>
        {index + 1 < value.length && <Divider />}
      </React.Fragment>
    ));
  }, [value, dropzoneRef]);

  return (
    <FormControl>
      <InputLabel>
        {setting.required ? (
          <LabelRequired title={labeltextBreak(setting.name)} />
        ) : (
          labeltextBreak(setting.name)
        )}
      </InputLabel>
      <Dropzone
        ref={dropzoneRef}
        accept={"all"}
        maxFiles={setting.maxlen ? Number(setting.maxlen) : undefined}
        showFileList={false}
        onChoose={(res: any[]) => {
          res.forEach((item) => {
            const uri = createObjectURL(item);
            item["uri"] = uri;
          });
          onChange([...value, ...res]);
        }}
        onDeleteFile={(res) => {
          onChange([...res]);
        }}
        value={value}
        maxSize={Number(setting.maxFileSize) * 1024 * 1024} //byte
      />
      {value?.length > 0 && (
        <Card variant="outlined" sx={{ width: "100%", px: 1, mt: 1 }}>
          {files}
        </Card>
      )}
    </FormControl>
  );
};

export default AttachedFileField;
