import React, { VFC, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  TextField,
  Stack,
} from "@mui/material";
import { useEffect } from "react";
import { Validation } from "@validation";
import LabelRequired from "components/atoms/LabelRequired";

export interface IData {
  SK: string;
  type: string;
  name: string;
  check_section_id: string;
}

interface IProps {
  data: IData;
  open: boolean;
  setOpen: Function;
  handleButtonOK: Function;
}

const ForkliftItemEditDialog: VFC<IProps> = ({
  data,
  open,
  setOpen,
  handleButtonOK,
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [name, setName] = useState("");
  const [errorText, setErrorText] = useState("");
  const [disabled, setDisabled] = useState(true);

  const type_create_item = "CHECK_ITEM";

  useEffect(() => {
    const setInitialData = async () => {
      setName(data.name);
      setErrorText("");
      setDisabled(true);
    };
    if (open) {
      setInitialData();
    }
  }, [open, data]);

  // ------------------------------------------------------------------
  // 入力
  // ------------------------------------------------------------------
  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const validName = () => {
    const error_text = Validation.validate({
      type: "text",
      name: data.type === type_create_item ? "点検項目" : "点検箇所",
      value: name,
      required: true,
      max_length: data.type === type_create_item ? 50 : 7,
    });
    setErrorText(error_text);
    setDisabled(error_text.length > 0 || name === data.name);
  };

  return (
    <Dialog
      maxWidth={data.type === type_create_item ? "md" : "sm"}
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <FormGroup>
          <Stack>
            <TextField
              label={
                <>
                  <LabelRequired
                    title={
                      data.type === type_create_item ? "点検項目" : "点検箇所"
                    }
                  />
                </>
              }
              name="master_name"
              value={name}
              onChange={changeName}
              onBlur={validName}
              error={errorText.length > 0}
              helperText={errorText}
              inputProps={{
                maxLength: data.type === type_create_item ? 50 : 7,
              }}
            />
          </Stack>
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          variant="outlined"
        >
          キャンセル
        </Button>

        <Button
          onClick={() => {
            const response: IData = {
              SK: data.SK,
              type: data.type,
              name: name,
              check_section_id: data.check_section_id,
            };
            handleButtonOK(response);
            setOpen(false);
          }}
          disabled={disabled}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForkliftItemEditDialog;
