import { ParamApiPostCreateNewFlow } from "services/models/models";
import { METHOD_API, createConnector, getParamsHelp } from "./Connector";
const prefixApi = "api/v1";

export const apiGetListTemplate = (params: Type.ParamApiGetTemplate) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/template?${getParamsHelp(params)}`,
  );
};

export const apiPostCreateNewFlow = (data: Type.ParamApiPostCreateNewFlow) => {
  return createConnector(METHOD_API.POST, `${prefixApi}/create-new-flow`, data);
};

export const apiGetListApprove = (params: Type.ParamApiGetListApprove) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-list-approve?${getParamsHelp(params)}`,
  );
};

export const apiGetListPosition = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/get-list-position`);
};

export const apiGetListUserByPositionID = (
  params: Type.ParamApiGetListUserByPositionID,
) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-list-user-by-position-id?${getParamsHelp(params)}`,
  );
};

export const apiGetListGroupMaster = (): Promise<Type.MasterGroup[]> => {
  return createConnector(METHOD_API.GET, `${prefixApi}/list-group-master`);
};

export const apiGetChildrenGroup4GroupMaster = (params: {
  group_parentid: string;
}): Promise<Type.GroupInfo[]> => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-list-group?${getParamsHelp(params)}`,
  );
};
export const apiGetMemberByConditions = (params: {
  sk_group: string;
  key_word?: string;
  location_id: string;
}): Promise<Type.UserInfo[]> => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-list-user-by-groupid?${getParamsHelp(params)}`,
  );
};

export const apiGetAprrovalList = (params: {
  template_id: string;
  flow_type: string;
}): Promise<Type.FlowInfo[]> => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-list-flow?${getParamsHelp(params)}`,
  );
};

export const apiPostApprovalFLow = (
  data: ParamApiPostCreateNewFlow,
): Promise<{
  list_approve: Type.StepInstance[][];
  sk_flow: string;
  flow_name: string;
}> => {
  return createConnector(METHOD_API.POST, `${prefixApi}/create-new-flow`, data);
};

export const apiGetStepListForFlow = (params: {
  sk_flow: string;
  location_id: string;
}): Promise<{
  data: {
    data_step: Type.StepInstance[];
    data_max_user: {
      step_id: string;
      max_user: number;
      is_send_approved_mail?: boolean;
      is_auto_approval_performed?: boolean;
    };
  }[];
  max_user_location: number;
}> => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-list-approve?${getParamsHelp(params)}`,
  );
};

export const apiGetMaxStepForFlow = (): Promise<{
  max_step_approver: number;
}> => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/check-max-step-approver?`,
  );
};

export const apiDeleteFlow = (params: {
  sk_flow: string;
}): Promise<{
  max_step_approver: number;
}> => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/delete-flow?${getParamsHelp(params)}`,
  );
};

export const apiDeleteStep = (params: {
  sk_flow: string;
  approver_id: string;
}) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/delete-step-approver?${getParamsHelp(params)}`,
  );
};

export const apiGetIsDaily = (params: {
  template_project_id: string;
}): Promise<{ is_daily: boolean }> => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/project/get-is-daily?${getParamsHelp(params)}`,
  );
};
