import { useEffect, useMemo, useState, VFC } from "react";
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import GenericTemplate from "@template/index";
import { Colors } from "@template/style";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import LabelRequired from "components/atoms/LabelRequired";
import DropzoneDialog from "components/molecules/DropzoneDialog";
import { HeadCell, TableCustom } from "components/organisms/TableCustom";
import {
  checkLoadingReport,
  checkLoadingReportWorkStatus,
  deleteLoadingReport,
  getListLoadingReport,
  updateListWorkPdf,
  updateLoadingReport,
} from "@api/loadingReport";
import formatDateToString from "@utils/DateFormat";
import {
  createFileNameFollowUri,
  getCsvData,
  getExcelData,
  getFileByUrl,
  getUserInfo,
} from "@utils/index";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import { Validation } from "@validation";
import messages from "config/messages";
import { RootState } from "store/reducer";
import { TYPES } from "store/types";
import LoadingRportEditScreen, {
  IStateForm as IEditStateForm,
} from "screens/LoadingReportEdit";
import { screenIdSupportGetImageUrl } from "screens/CreateReportTlog/templateFunctionSupport";
import { screenIdSupportMergeTemplate } from "@shared-constants";

const headCells: HeadCell[] = [
  {
    id: "unmatched_str",
    label: "UM",
    width: 20,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "loading_date",
    label: "Loading Date (YYYY/MM/DD)",
    width: 100,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "warehouse",
    label: "Warehouse",
    width: 100,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "loader",
    label: "Load by",
    width: 100,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "invoice_no",
    label: "Invoice No.",
    width: 130,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "booking_no",
    label: "Booking No.",
    width: 120,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "commodity",
    label: "Commodity",
    width: 120,
    disablePadding: true,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "container_type",
    label: "Container type",
    width: 140,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "container_no",
    label: "Container No.",
    width: 130,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "seal_no",
    label: "Seal No.",
    width: 120,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "remark",
    label: "Remark",
    width: 180,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "model_name",
    label: "Model Name",
    width: 150,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "qty",
    label: "Quantity",
    width: 70,
    numeric: true,
    isIgnoreExtractRuleCell: true,
  },
];

const CSV_INDEX = {
  loading_date: 0,
  warehouse: 1,
  loader: 2,
  invoice_no: 3,
  booking_no: 4,
  commodity: 5,
  container_type: 6,
  container_no: 7,
  seal_no: 8,
  remark: 9,
  model_name: 10,
  qty: 11,
} as const;

interface IStateForm {
  loading_date: string;
  container_no: string;
  unmatched: boolean | null;
}

const UNMATCHED_COLOR = "#FFCCFF" as const;
const EMPTY_COLOR = Colors.MAIN_GREEN_LIGHT;
const COMPLETE_COLOR = Colors.DISABLE_INPUT_BG;

type TDataType = "DB" | "CSV";

const initialState: IStateForm = {
  loading_date: "",
  container_no: "",
  unmatched: null,
};

type LoadingReportCSV = {
  PK: string;
  SK: string;
  loading_date: string;
  warehouse: string;
  loader: string;
  invoice_no: string;
  booking_no: string;
  commodity: string;
  container_type: string;
  container_no: string;
  seal_no: string;
  remark: string;
  model_name: string;
  qty: number | string;
  unmatched: boolean;
  data_type?: TDataType;
  old_unmatched?: boolean;
  unmatched_str?: string;
  is_delete?: boolean;
  is_duplicated?: boolean;
  old_duplicated_sk?: string;
  not_reassign?: boolean;
};

type DuplicatedContainerNoList = {
  SK: string;
  container_no: string;
}

const LoadingReportImportScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [isDateInput, setIsDateInput] = useState<boolean>(false);
  const [listCSV, setListCSV] = useState<LoadingReportCSV[]>([]);
  const [listSearchData, setListSearchData] = useState<LoadingReportCSV[]>([]);
  const [displayData, setDisplayData] = useState<LoadingReportCSV[]>([]);
  const [originSearchData, setOriginSearchData] = useState<LoadingReportCSV[]>(
    [],
  );
  const [stateForm, setStateForm] = useState<IStateForm>(initialState);
  const [formError, setFormError] = useState<IStateForm>(initialState);
  const [editData, setEditData] = useState<any>();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [activityBaseId, setActivityBaseId] = useState<string>("");
  const [duplicatedContainerNo, setDuplicatedContainerNo] = useState<DuplicatedContainerNoList[]>([])
  const [updatedData, setUpdatedData] = useState<LoadingReportCSV[]>([]);
  const [editComplete, setEditComplete] = useState<boolean>(false);
  const { check_list } = useSelector((state: RootState) => state.tableCustom);

  const dispatch = useDispatch();
  const rowCount = useMemo(() => {
    return displayData.length;
  }, [listSearchData, listCSV, displayData]);
  const disabledSave = useMemo(() => {
    return (
      (listCSV.length == 0 &&
        !listSearchData.some(
          (d) => d.old_unmatched == true && d.unmatched == false
        ) &&
        _.isEqual(listSearchData, originSearchData)) ||
      editComplete
    );
  }, [listCSV, listSearchData, originSearchData, editComplete]);
  const disabledDelete = useMemo(() => {
    return check_list.length == 0;
  }, [check_list]);

  useEffect(() => {
    // ログインアカウントの所属拠点を取得
    const user_info = getUserInfo();
    setActivityBaseId(user_info.location_id);
    return () => {
      dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: [] });
    };
  }, []);

  // 検索 ------------------------------------------------
  const fetchData = () => {
    LoadingOverlayController.show();
    const loading_date = formatDateToString(stateForm.loading_date, "YMD");
    getListLoadingReport(activityBaseId, loading_date)
      .then((res) => {
        if (res?.data) {
          const data = res.data.map((item: LoadingReportCSV) => {
            return {
              ...item,
              loading_date: formatDateToString(item.loading_date, "YMD_sl"),
              unmatched_str: item.unmatched ? "○" : "",
              data_type: "DB",
            };
          });
          setListSearchData(data);
          setOriginSearchData(data);
          setDisplayData(data);
        }
      })
      .finally(() => LoadingOverlayController.hide());
  };

  /**
   * 決定・解除
   */
  const handleClickDateBtn = () => {
    if (isDateInput) {
      // 解除
      handleClear();
    } else {
      //決定
      onChangeDate("loading_date")(stateForm.loading_date);
      if (
        stateForm.loading_date.length == 0 ||
        formError.loading_date.length > 0
      ) {
        return;
      }
      fetchData();
    }
    setIsDateInput(!isDateInput);
  };

  /**
   * 画面初期化
   */
  const handleClear = () => {
    setListCSV([]);
    setListSearchData([]);
    setOriginSearchData([]);
    setDisplayData([]);
    setStateForm(initialState);
    setFormError(initialState);
    setFile(null);
    setFileName("");
    setDuplicatedContainerNo([])
    setUpdatedData([])
    dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: [] });
  };

  // 入力制御 ------------------------------------------------
  const onChangeText = (field: keyof IStateForm) => (newText: string) => {
    setStateForm({ ...stateForm, [field]: newText });
  };

  const onChangeDate = (field: string) => (value: string | null) => {
    let displayValue = "";
    if (!value) {
      value = "";
    } else {
      value = formatDateToString(value, "YMD");
      displayValue = formatDateToString(value, "YMD");
    }
    const mess = Validation.validateDate(value ?? "", "Loading Date", true);
    setFormError({ ...formError, [field]: mess });
    setStateForm((prev) => ({ ...prev, [field]: displayValue }));
  };

  const chooseFile = (response: any) => {
    setFileName(response[0].name);
    setFile(response[0]);
    setOpen(false);
  };

  // ファイル取込み ------------------------------------------------
  /**
   * 取込
   */
  const handleImport = () => {
    if (file) {
      //初期化
      setListCSV([]);
      setDuplicatedContainerNo([]);
      handleShowAll(undefined, []);
      //データの整形
      parseFile(file);
    }
  };

  const checkRequiredData = (csv: string[]) => {
    // 必須：loading_date, invoice_no, container_no
    return (
      csv[CSV_INDEX.loading_date] == "" ||
      csv[CSV_INDEX.invoice_no] == "" ||
      csv[CSV_INDEX.container_no] == ""
    );
  };

  const checkExistData = (csv: string[][], index: number) => {
    const row = csv[index];
    const loading_date = formatDateToString(stateForm.loading_date, "YMD");
    return (
      listSearchData.some(
        (value) =>
          value.loading_date == row[CSV_INDEX.loading_date] &&
          value.warehouse == row[CSV_INDEX.warehouse] &&
          value.loader == row[CSV_INDEX.loader] &&
          value.invoice_no == row[CSV_INDEX.invoice_no] &&
          value.booking_no == row[CSV_INDEX.booking_no] &&
          value.commodity == row[CSV_INDEX.commodity] &&
          value.container_type == row[CSV_INDEX.container_type] &&
          value.container_no == row[CSV_INDEX.container_no] &&
          value.seal_no == row[CSV_INDEX.seal_no] &&
          value.remark == row[CSV_INDEX.remark] &&
          value.model_name == row[CSV_INDEX.model_name] &&
          value.qty == row[CSV_INDEX.qty],
      ) ||
      csv.some(
        (value, i) =>
          i != index &&
          formatDateToString(value[CSV_INDEX.loading_date], "YMD") ===
          loading_date &&
          _.isEqual(row, value),
      )
    );
  };

  const validateCSV = (csv: string[], index: number) => {
    let message: string[] = [];
    const mess_line = `${index + 1}行目`;
    csv[CSV_INDEX.loading_date] = formatDateToString(
      csv[CSV_INDEX.loading_date],
      "YMD",
    );

    message.push(
      Validation.validateDate(
        csv[CSV_INDEX.loading_date],
        mess_line + "Loading date",
      ),
    );
    message.push(
      Validation.validate({
        type: "text",
        value: csv[CSV_INDEX.warehouse],
        name: mess_line + "Warehouse",
        max_length: 16,
      }),
    );
    message.push(
      Validation.validate({
        type: "text",
        value: csv[CSV_INDEX.loader],
        name: mess_line + "Loader",
        max_length: 30,
      }),
    );
    message.push(
      Validation.validate({
        type: "text",
        value: csv[CSV_INDEX.invoice_no],
        name: mess_line + "Invoice No.",
        max_length: 30,
      }),
    );
    message.push(
      Validation.validate({
        type: "text",
        value: csv[CSV_INDEX.booking_no],
        name: mess_line + "Booking No.",
        max_length: 30,
      }),
    );
    message.push(
      Validation.validate({
        type: "text",
        value: csv[CSV_INDEX.commodity],
        name: mess_line + "Commodity",
        max_length: 30,
      }),
    );
    message.push(
      Validation.validate({
        type: "text",
        value: csv[CSV_INDEX.container_type],
        name: mess_line + "Container type",
        max_length: 6,
      }),
    );
    message.push(
      Validation.validate({
        type: "text",
        value: csv[CSV_INDEX.container_no],
        name: mess_line + "Container No.",
        max_length: 20,
      }),
    );
    message.push(
      Validation.validate({
        type: "text",
        value: csv[CSV_INDEX.seal_no],
        name: mess_line + "Seal No.",
        max_length: 12,
      }),
    );
    message.push(
      Validation.validate({
        type: "text",
        value: csv[CSV_INDEX.remark],
        name: mess_line + "Remark",
        max_length: 90,
      }),
    );
    message.push(
      Validation.validate({
        type: "text",
        value: csv[CSV_INDEX.model_name],
        name: mess_line + "Model name",
        max_length: 30,
      }),
    );
    message.push(
      Validation.validate({
        type: "number",
        value: csv[CSV_INDEX.qty],
        name: mess_line + "Quantity",
        max_length: 6,
      }),
    );

    return message.filter((item) => item.length > 0).join("\n");
  };

  /**
   * ファイル取込み
   * @param file
   */
  const parseFile = async (file: File) => {
    LoadingOverlayController.show();
    try {
      let list_csv: LoadingReportCSV[] = [];
      let csv_data: string[][] = [];
      // ファイル読み取り
      if (file.type === "text/csv") {
        // CSV
        csv_data = await getCsvData(file);
      } else {
        // Excel
        const sheet = await getExcelData(file);
        sheet.forEach((row) => {
          var result: string[] = [];
          for (var i = 0; i < row.length; i++) {
            const value = row[i] === undefined ? "" : row[i].toString();
            result.push(value);
          }
          csv_data.push(result);
        });
      }

      for (let index = 0; index < csv_data.length; index++) {
        if (index == 0) continue;
        const value = csv_data[index];

        // 値が何も設定されていない行に達した場合は処理終了
        if (!value.length) break;

        // 必須チェック
        if (checkRequiredData(value)) {
          ModalController.show({
            message: messages.COMMON.MSG_NOT_EXIST("必須項目の入力"),
            visibleButton2: true,
          });
          return;
        }
        // 重複チェック
        if (checkExistData(csv_data, index)) {
          ModalController.show({
            message: messages.COMMON.ERROR.MSG_EXISTING("ファイルの内容"),
            visibleButton2: true,
          });
          return;
        }
        // 入力チェック
        const error_message = validateCSV(value, index);
        if (error_message.length > 0) {
          ModalController.show({
            message: error_message,
            visibleButton2: true,
          });
          return;
        }
        // 日付チェック
        if (stateForm.loading_date !== value[CSV_INDEX.loading_date]) {
          continue;
        }
        // ModelName, Quantityが空欄のデータは取込み対象外にする
        if (
          !(CSV_INDEX.model_name in value) ||
          value[CSV_INDEX.model_name].trim() == "" ||
          !(CSV_INDEX.qty in value) ||
          value[CSV_INDEX.qty].trim() == ""
        ) {
          continue;
        }

        list_csv.push({
          PK: "",
          SK: index.toString(),
          loading_date: formatDateToString(
            value[CSV_INDEX.loading_date],
            "YMD_sl",
          ),
          warehouse: value[CSV_INDEX.warehouse],
          loader: value[CSV_INDEX.loader],
          invoice_no: value[CSV_INDEX.invoice_no],
          booking_no: value[CSV_INDEX.booking_no],
          commodity: value[CSV_INDEX.commodity],
          container_type: value[CSV_INDEX.container_type],
          container_no: value[CSV_INDEX.container_no],
          seal_no: value[CSV_INDEX.seal_no],
          remark: value[CSV_INDEX.remark],
          model_name: value[CSV_INDEX.model_name],
          qty: value[CSV_INDEX.qty],
          unmatched: true,
          unmatched_str: "○",
          data_type: "CSV",
        });
      }

      if (list_csv.length == 0) {
        ModalController.show({
          message: "Loading Dateが一致するCSVデータがありません",
          visibleButton2: true,
        });
        return;
      }

      // 引き当て結果を取得
      const result = await reAssignLoadingReport(activityBaseId, list_csv, stateForm.loading_date);
      list_csv = result?.new_list_csv;

      // 重複リストを取得し、old_unmatched,unmatchedを更新
      const originDuplicatedContainerNoList = [...result?.duplicate_container_no_list];
      const updatedResult = updateIsMatingColumn(originDuplicatedContainerNoList, listSearchData, list_csv);
      list_csv = updatedResult.listCSV;

      setListCSV(list_csv);
      setListSearchData(updatedResult.listSearchData);
      setOriginSearchData(updatedResult.listSearchData);
      setDuplicatedContainerNo(originDuplicatedContainerNoList);
      handleShowAll(undefined, list_csv);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // 絞り込み ------------------------------------------------
  const handleFilter = (
    list_search?: LoadingReportCSV[],
    list_csv?: LoadingReportCSV[],
    state_form?: IStateForm,
  ) => {
    list_search = list_search ? list_search : _.cloneDeep(listSearchData);
    list_csv = list_csv ? list_csv : _.cloneDeep(listCSV);
    state_form = state_form ? state_form : _.cloneDeep(stateForm);
    const filter = (item: LoadingReportCSV) => {
      let container_no_chk = true;
      let unmatched_chk = true;
      if (
        state_form?.container_no &&
        state_form.container_no?.length > 0 &&
        item.container_no !== state_form.container_no
      ) {
        container_no_chk = false;
      }
      if (state_form?.unmatched !== null) {
        if (state_form?.unmatched) {
          // unmatched
          unmatched_chk = item.unmatched === true;
        } else {
          // completed
          unmatched_chk = item?.old_unmatched === false;
        }
      }
      return container_no_chk && unmatched_chk;
    };

    const filter_search = list_search.filter(filter);
    const filter_csv = list_csv.filter(filter);
    let filter_data = [...filter_search].filter(data => !data.is_delete).concat([...filter_csv]);
    setDisplayData(_.cloneDeep(filter_data));
  };

  // クリア ------------------------------------------------
  const handleShowAll = (
    list_search?: LoadingReportCSV[],
    list_csv?: LoadingReportCSV[],
  ) => {
    list_search = list_search ? list_search : _.cloneDeep(listSearchData);
    list_csv = list_csv ? list_csv : _.cloneDeep(listCSV);
    let all_data = [...list_search].filter(data => !data.is_delete).concat([...list_csv]);
    setStateForm({ ...stateForm, container_no: "", unmatched: null });
    setDisplayData(_.cloneDeep(all_data));
  };

  // 編集 ------------------------------------------------
  const handleOpenEdit = (row: LoadingReportCSV) => {
    setEditData(row);
    setOpenEdit(true);
  };

  const handleSaveEdit = (data: IEditStateForm) => {
    const new_data: any = { ...editData, ...data };

    const duplicatedContainerNumbers = duplicatedContainerNo.map(duplicatedData => duplicatedData.container_no);
    let oldDuplicatedTarget;
    let updated_new_data;
    // データの編集によりCSVのContainerNoがDB側のどのデータと一致していたかを保持しておく。
    if (duplicatedContainerNo.length > 0 && editData?.data_type === "CSV") {
      const beforeCSVData = listCSV.find(csvData => csvData.SK === editData?.SK);
      const beforeContainerNo = beforeCSVData?.container_no;
      if (beforeContainerNo && duplicatedContainerNumbers.includes(beforeContainerNo)) {
        oldDuplicatedTarget = listSearchData.find(listData => listData.container_no === beforeContainerNo);
      }
    }

    if (editData?.data_type === "DB") {

      // DBデータのContainerNo編集により、既存のDB上のContainerNoと一致した場合、
      // 再引当ての対象から除外するためのフラグを立て、old_unmatch,unmatchの同期を行う。
      updated_new_data = { ...new_data };
      const new_container_no = updated_new_data.container_no;
      const target = listSearchData.find(
        data => data.container_no === new_container_no
      );
      if (target) {
        updated_new_data.old_unmatched = target?.old_unmatched;
        updated_new_data.unmatched = target?.unmatched;
        updated_new_data.not_reassign = true;
      }

      let index = listSearchData.findIndex((item) => item.SK === editData?.SK);
      if (index >= 0) {
        const new_list_search = [...listSearchData];
        new_list_search.splice(index, 1, new_data);
        setListSearchData(new_list_search);
        handleFilter(new_list_search);
      }
    } else if (editData?.data_type === "CSV") {
      let index = listCSV.findIndex((item) => item.SK === editData?.SK);
      if (index >= 0) {
        const new_list_csv = [...listCSV];
        new_list_csv.splice(index, 1, new_data);
        setListCSV(new_list_csv);
        handleFilter(undefined, new_list_csv);
      }
    }

    const index = updatedData.findIndex(item => item.SK === editData?.SK);

    let add_data = {}
    if (oldDuplicatedTarget) {
      add_data = { old_duplicated_sk: oldDuplicatedTarget.SK }
    }
    if (updated_new_data) {
      add_data = { ...updated_new_data }
    }
    const update_data = { ...new_data, ...add_data }
    if (index >= 0) {
      const newUpdatedData = [...updatedData];
      newUpdatedData[index] = update_data;
      setUpdatedData(newUpdatedData);
    } else {
      setUpdatedData(prev => [...prev, update_data]);
    }
    setEditData(undefined);
    setOpenEdit(false);
    setEditComplete(true)
  };

  // 承認済み帳票更新ダイアログ
  const handleSaveConfirm = async () => {
    LoadingOverlayController.show();
    try {
      let work_status;
      if (duplicatedContainerNo.length > 0) {
        const listDuplicatedSearchData = listSearchData.filter(listData =>
          listData.is_duplicated && !listData.old_unmatched
        );
        const response = await checkLoadingReportWorkStatus({
          activity_base_id: activityBaseId,
          csv_data: listDuplicatedSearchData
        });
        work_status = response.is_finish_work;
      }
      LoadingOverlayController.hide();

      if (work_status) {
        ModalController.show({
          message: messages.LOADING_REPORT.MSG_WARNING_EXIST_FINISHED_LOADING_REPORT,
          visibleButton1: true,
          visibleButton2: true,
          handlePressButton1: () => {
            return;
          },
          handlePressButton2: () => {
            setTimeout(async () => await handleSave(), 100);
            return;
          },
        });
      } else {
        await handleSave();
      }
    } catch (e) {
      console.error(e)
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // 保存 ------------------------------------------------
  const handleSave = async () => {
    try {
      if (!ModalController.isShowing()) {
        LoadingOverlayController.show();
      }
      const del_item: Array<keyof LoadingReportCSV> = ["unmatched_str"];
      const param_list_search = listSearchData.map((item) => {
        const newItem = { ...item };
        // 不要項目削除
        del_item.forEach((del_key) => {
          if (del_key in newItem) {
            delete newItem[del_key];
          }
        });
        return newItem;
      });
      const param_list_csv = listCSV.map((item) => {
        const newItem = { ...item };
        // 不要項目削除
        del_item.forEach((del_key) => {
          if (del_key in newItem) {
            delete newItem[del_key];
          }
        });
        return newItem;
      });
      const formData = {
        activity_base_id: activityBaseId,
        csv_data: param_list_search.concat(param_list_csv),
        loading_date: stateForm.loading_date
      };
      // 保存
      const res = await updateLoadingReport(formData);
      // PDF再作成
      if (res?.data) {
        await recreatePDF(res.data);
      }
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_SUCCESS_001("LoadingReportの保存"),
        visibleButton2: true,
        handlePressButton2: () => {
          // 画面再表示
          setListCSV([]);
          setFile(null);
          setFileName("");
          fetchData();
        },
      });
      LoadingOverlayController.hide();
    } catch (error: any) {
      LoadingOverlayController.hide();
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_ERROR_002("LoadingReport"),
        visibleButton2: true,
        handlePressButton2: () => { },
      });
    }
  };

  const recreatePDF = async (data: any[]) => {
    let file_list = [];
    for (let index = 0; index < data.length; index++) {
      const templateData = data[index]["work_template_id"];
      const { template_path, screen_id, work_template_data } = templateData;
      let newStateFormEditInfo = JSON.parse(work_template_data ?? "null");
      const fileName = createFileNameFollowUri(template_path);
      let localFile = template_path;
      let newPath: string | null = null;
      if (newStateFormEditInfo) {
        newStateFormEditInfo = await screenIdSupportGetImageUrl[screen_id](
          newStateFormEditInfo,
        );
        newPath = await screenIdSupportMergeTemplate[screen_id](
          localFile,
          newStateFormEditInfo,
          templateData,
        );
      }
      if (newPath) {
        const f = await getFileByUrl(newPath, fileName, "text/html");
        file_list.push(f);
      }
    }
    if (file_list.length > 0) {
      const formData = new FormData();
      for (let index = 0; index < file_list.length; index++) {
        formData.append("work_template_file", file_list[index]);
      }
      formData.append("template_data", JSON.stringify(data));
      return await updateListWorkPdf(formData);
    } else {
      return true;
    }
  };

  // 削除 ------------------------------------------------
  /**
   * 削除確認ダイアログ
   */
  const handleDeleteConfirm = () => {
    ModalController.show({
      message: messages.COMMON.MSG_COMMON_DELETE_CONFIRM_001,
      visibleButton1: true,
      visibleButton2: true,
      handlePressButton2: () => {
        setTimeout(async () => await handleDelete(), 100);
      },
    });
  };

  /**
   * 削除
   */
  const handleDelete = async () => {
    try {
      LoadingOverlayController.show();

      // 登録済みデータを物理削除
      const formData = listSearchData
        .filter((d) => check_list.includes(d.SK))
        .map(({ PK, SK }) => ({
          PK,
          SK,
        }));
      if (formData.length > 0) {
        await deleteLoadingReport(formData);
      }
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_DELETE_SUCCESS_001("LoadingReport"),
        visibleButton2: true,
        handlePressButton2: async () => {
          LoadingOverlayController.show();
          dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: [] });

          let new_list_search = [...listSearchData];
          let new_list_csv = [...listCSV];
          let new_origin_search = [...originSearchData];
          let new_duplicated_container_no = [...duplicatedContainerNo];

          // チェック行を表示から削除
          new_list_search = new_list_search.filter(
            (d) => !check_list.includes(d.SK),
          );
          new_list_csv = new_list_csv.filter((d) => !check_list.includes(d.SK));
          new_origin_search = new_origin_search.filter(
            (d) => !check_list.includes(d.SK),
          );

          // 再引き当て処理
          if (duplicatedContainerNo.length > 0) {
            //「重複リストが存在する」(=CSV取り込みまで実行している)ならば、再引き当て
            const reAssignedLoadingReport = await reAssignLoadingReport(
              activityBaseId,
              new_list_csv,
              stateForm.loading_date
            );

            new_list_csv = reAssignedLoadingReport?.new_list_csv;
            new_duplicated_container_no = reAssignedLoadingReport?.duplicate_container_no_list;

            // old_unmatch,unmatchカラム更新
            const updatedResult = updateIsMatingColumn(new_duplicated_container_no, new_list_search, new_list_csv);
            new_list_search = updatedResult.listSearchData;
            new_origin_search = updatedResult.listSearchData;
            new_list_csv = updatedResult.listCSV;
          }

          setListSearchData(new_list_search);
          setListCSV(new_list_csv);
          setOriginSearchData(new_origin_search);
          handleFilter(new_list_search, new_list_csv);
          LoadingOverlayController.hide();
        },
      });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // 再引き当て
  const handleReAssignLoadingReport = async () => {
    const loading_date = stateForm.loading_date;
    let newListSearchData = [...listSearchData];
    let newCSVList = [...listCSV];
    const newDuplicatedContainerNoList = [...duplicatedContainerNo];
    const slicedUpdatedData = updatedData.slice();

    // old_unmatch,unmatchの更新
    slicedUpdatedData.forEach((slicedData, index) => {
      const SK = slicedData.SK;
      const foundIndex = newListSearchData.findIndex(listData => listData.SK === SK);
      if (foundIndex >= 0) {
        newListSearchData[foundIndex] = slicedUpdatedData[index];
      }
    })

    // APIに渡すデータを選別する(ContainerNoの変更により、既存のDB側データと重複するものは対象外)
    const filteredReAssignData = slicedUpdatedData.filter(updatedData => !updatedData?.not_reassign);

    try {
      LoadingOverlayController.show();
      const result = await reAssignLoadingReport(activityBaseId, filteredReAssignData, loading_date);
      const responseCSV = result?.new_list_csv;
      const resDuplicatedContainerNoList = [...result?.duplicate_container_no_list];
      if (responseCSV.length > 0) {
        for (const data of responseCSV) {
          const data_type = data?.data_type;
          if (data_type === "DB") {
            const index = listSearchData.findIndex(listData => listData.SK === data.SK);
            newListSearchData[index] = data;
          } else if (data_type == "CSV") {
            const index = listCSV.findIndex(listData => listData.SK === data.SK);
            newCSVList[index] = data;
          }
        }
      }

      // CSV側データのContainerNoの変更により、重複リストの対象から外れた場合。
      // 1. state管理側の重複リストにアクセス
      if (duplicatedContainerNo.length > 0) {
        duplicatedContainerNo.forEach((duplicatedData: DuplicatedContainerNoList, i: number) => {
          const SK = duplicatedData.SK;
          // 2. ContainerNoが重複した対象のDB側のSKをslicedUpdatedDataから検索する。
          const index = slicedUpdatedData.findIndex(updatedData => updatedData?.old_duplicated_sk == SK);
          if (index >= 0) {
            const updated_container_no = slicedUpdatedData[index].container_no;
            const duplicatedNumbers = duplicatedContainerNo.map(duplicatedData => duplicatedData.container_no);
            if (!duplicatedNumbers.includes(updated_container_no)) {
              // 3. 既存の重複ContainerNoListから削除する
              newDuplicatedContainerNoList.splice(i, 1)
            }
          }
        })
      }

      // DB側データのContainerNoの変更により、重複リストの対象が増えた場合。
      if (listCSV.length > 0) {
        const containerNumbersList = slicedUpdatedData
          .filter(slicedData => slicedData.data_type === "DB")
          .map(data => ({ SK: data.SK, container_no: data.container_no }));
        const CSVContainerNumbers = newCSVList.map(csvData => csvData.container_no);
        containerNumbersList.forEach((data, index) => {
          const container_no = data.container_no;
          if (CSVContainerNumbers.includes(container_no)) {
            newDuplicatedContainerNoList.push(containerNumbersList[index]);
          }
        })
      }
      newDuplicatedContainerNoList.push(...resDuplicatedContainerNoList);

      const updatedResult = updateIsMatingColumn(newDuplicatedContainerNoList, newListSearchData, newCSVList);
      newListSearchData = updatedResult.listSearchData;
      newCSVList = updatedResult.listCSV;

      setListCSV(newCSVList);
      setListSearchData(newListSearchData);
      setOriginSearchData(newListSearchData);
      setDuplicatedContainerNo(newDuplicatedContainerNoList);
      handleShowAll(newListSearchData, newCSVList);
      setEditComplete(false);

    } catch (e) {
      console.error(e);
    } finally {
      LoadingOverlayController.hide();
    }

  }

  // LoadingReport再引き当てAPIコール関数
  const reAssignLoadingReport = async (
    activityBaseId: string,
    list_csv: LoadingReportCSV[],
    loading_date: string
  ) => {
    let new_list_csv = [];
    let duplicate_container_no_list = [];
    try {
      const response = await checkLoadingReport({
        activity_base_id: activityBaseId,
        csv_data: list_csv,
        loading_date: loading_date,
      });

      if (response?.data) {
        new_list_csv = response.data.map((item: LoadingReportCSV) => ({
          ...item,
          unmatched_str: item.unmatched ? "○" : "",
        }));
        duplicate_container_no_list = response.duplicate_container_no_list;
      }

      return { new_list_csv, duplicate_container_no_list }

    } catch (e) {
      console.error(e);
    }
  }

  // old_unmatched,unmatched更新用関数
  const updateIsMatingColumn = (
    duplicatedContainerNoList: DuplicatedContainerNoList[],
    listSearchData: LoadingReportCSV[],
    listCSV: LoadingReportCSV[],
  ) => {

    // 重複リストをContainNoのみにする
    const duplicatedContainerNumbers: string[] = duplicatedContainerNoList
      .map((duplicatedData) => duplicatedData.container_no)

    // 重複リストを元にDB上の既存データに削除予定フラグ、重複フラグを立てる
    const updatedListSearchData = listSearchData.map((data: LoadingReportCSV) => {
      const isDuplicated = duplicatedContainerNumbers.includes(data.container_no)
      data.is_duplicated = isDuplicated;
      data.is_delete = isDuplicated;
      return data;
    });

    // CSV側のold_unmatched,unmatchedをDB側と同期させる
    const updatedListCSV = listCSV.map((csvData) => {
      const csvContainerNo = csvData.container_no;
      if (duplicatedContainerNumbers.includes(csvContainerNo)) {
        const syncTargetListSearchData = listSearchData.find(data => data.container_no === csvContainerNo);
        csvData.old_unmatched = syncTargetListSearchData?.old_unmatched;
        csvData.unmatched = syncTargetListSearchData?.unmatched ? true : false;
        csvData.unmatched_str = csvData.unmatched ? "○" : "";
        csvData.is_duplicated = true;
      } else {
        csvData.old_unmatched = undefined;
        csvData.is_duplicated = false;
      }
      return csvData;
    })

    return { listSearchData: updatedListSearchData, listCSV: updatedListCSV }
  }

  return (
    <GenericTemplate title="LoadingReport Excel取込み">
      <DropzoneDialog
        open={open}
        onClose={() => setOpen(false)}
        onChoose={chooseFile}
        accept={["csv", "excel"]}
        maxFiles={1}
      />
      <LoadingRportEditScreen
        data={editData}
        open={openEdit}
        onClose={() => {
          setOpenEdit(false);
        }}
        onSave={handleSaveEdit}
      />
      <Stack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{ alignItems: { xs: undefined, md: "flex-start" } }}
        >
          <FormControl>
            <DatePickerCustom
              label={<LabelRequired title="Loading Date(YYYY/MM/DD)" />}
              value={stateForm.loading_date || null}
              onChange={onChangeDate("loading_date")}
              disabled={isDateInput}
              helperText={formError.loading_date}
              error={formError.loading_date.length > 0}
            />
          </FormControl>
          <Box sx={{ pt: { xs: 0, md: "36px" } }}>
            <Button color="secondary" onClick={handleClickDateBtn}>
              {isDateInput ? "解除" : "決定"}
            </Button>
          </Box>
          <FormControl sx={{ pt: { xs: 0, md: "36px" } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <InputLabel style={{ color: Colors.MAIN_GREEN }}>
                取り込むExcel・CSVファイルを選択してください
              </InputLabel>
              <Button
                onClick={() => {
                  setOpen(true);
                }}
                sx={{ ml: 1 }}
                disabled={!isDateInput}
              >
                ファイル選択
              </Button>
            </Box>
            <Typography variant="body2" sx={{ mr: 1 }}>
              {fileName}
            </Typography>
          </FormControl>
          <Box sx={{ pt: { xs: 0, md: "36px" } }}>
            <Button onClick={handleImport} disabled={!isDateInput}>
              取込
            </Button>
          </Box>
        </Stack>

        <Stack direction={{ xs: "column", md: "row" }}>
          <TextField
            label="ContainerNo."
            value={stateForm.container_no}
            onChange={(e) => onChangeText("container_no")(e.target.value)}
            disabled={!isDateInput}
          />
          <FormControl>
            <InputLabel>引当状態</InputLabel>
            <RadioGroup
              value={stateForm.unmatched === null ? "" : stateForm.unmatched}
              row
              onChange={(_, value) => {
                setStateForm({
                  ...stateForm,
                  unmatched: value == "" ? null : value === "true",
                });
              }}
              sx={{ mt: { xs: 0, md: 1 } }}
            >
              <FormControlLabel
                value={""}
                label="指定無し"
                control={<Radio />}
                disabled={!isDateInput}
              />
              <FormControlLabel
                value={true}
                label="Unmatched"
                control={<Radio />}
                disabled={!isDateInput}
              />
              <FormControlLabel
                value={false}
                label="Completed"
                control={<Radio />}
                disabled={!isDateInput}
              />
            </RadioGroup>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "flex-start",
              justifyContent: "center",
              pt: { xs: 0, md: "36px" },
            }}
          >
            <Button onClick={() => handleFilter()} disabled={!isDateInput}>
              絞り込み
            </Button>
            <Button onClick={() => handleShowAll()} disabled={!isDateInput}>
              クリア
            </Button>
          </Box>
        </Stack>
        {rowCount > 0 && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {rowCount}件
          </div>
        )}
        <TableCustom
          rows={displayData}
          headCells={headCells}
          idName={"SK"}
          isCheckRow={true}
          isEditRow
          rowBgColor={(row) => {
            if (row.unmatched) return UNMATCHED_COLOR;
            if ((row.data_type == "DB" && !row.old_unmatched))
              return COMPLETE_COLOR;
          }}
          cellBgColor={(row, index, id) => {
            const required = [
              "unmatched_str",
              "loading_date",
              "invoice_no",
              "container_no",
              "model_name",
              "qty",
            ];
            if (row[id] == "") {
              if (!required.includes(id)) {
                return EMPTY_COLOR;
              }
            }
          }}
          handleEdit={handleOpenEdit}
          disableCheckBox={(row) => {
            return row.data_type == "DB" && !row.old_unmatched;
          }}
          disableEdit={(row) => {
            return row.data_type == "DB" && !row.old_unmatched;
          }}
        />
      </Stack>
      <Box sx={{ height: 30 }} />
      <Box
        sx={{
          position: "fixed",
          margin: 0,
          right: `calc(50% - 216px / 2)`,
          bottom: 20,
          textAlign: "center",
        }}
      >
        <Button
          onClick={handleReAssignLoadingReport}
          sx={{ mr: 2 }}
          disabled={false}
        >
          再引当
        </Button>
        <Button
          color="secondary"
          onClick={handleSaveConfirm}
          disabled={disabledSave}
          sx={{ mr: 2 }}
        >
          保存
        </Button>
        <Button
          color="error"
          onClick={handleDeleteConfirm}
          disabled={disabledDelete}
        >
          削除
        </Button>
      </Box>
    </GenericTemplate>
  );
};

export default LoadingReportImportScreen;
