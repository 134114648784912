import React, { useEffect, useMemo, useState } from "react";
import FullScreenDialog from "components/atoms/FullScreenDialog";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { Colors } from "@template/style";
import { Close } from "@mui/icons-material";
import { cloneDeep } from "lodash";
import LabelRequired from "components/atoms/LabelRequired";
import messages from "config/messages";
import ModalController from "@shared-components/modal/ModalController";
import { updateDevanReception, getDavanByContainerApi } from "@api/devan";
import { Validation } from "@validation";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";

interface IDevanReception {
  open: boolean;
  onClose: () => void;
  data: any;
  searchHandle: () => void;
}

type IStateForm = {
  berth_number: string;
};

export type DevanModelInfo = {
  SK: string;
  product_class: string; //商品分類
  model_name: string; //機種名
  number_of_moves: string; //搬入数
  [key: string]: string;
};

const devanModelInfo: DevanModelInfo = {
  SK: "",
  product_class: "商品分類",
  model_name: "機種名",
  number_of_moves: "搬入数",
};

interface DevanInfo {
  date_of_delivery: string;
  container_no: string;
  container_size: string;
  delivery_time: string;
  carrier_name: string;
  unit_class: string;
  delivery_location: string;
  invoice_no: string;
  seal_no: string;
  [key: string]: string;
}

const devanInfo: DevanInfo = {
  date_of_delivery: "搬入年月日",
  container_no: "コンテナ番号",
  container_size: "コンテナサイズ",
  delivery_time: "納入時間",
  carrier_name: "搬入者名",
  unit_class: "Unit区分",
  delivery_location: "搬入場所",
  invoice_no: "インボイスナンバー",
  seal_no: "シールNo.",
};

const originData: IStateForm = {
  berth_number: "",
};

const DevanReception: React.FC<IDevanReception> = ({
  open,
  onClose,
  data,
  searchHandle,
}) => {
  const [stateForm, setStateForm] = useState<IStateForm>(cloneDeep(originData));
  const [formError, setFormError] = useState<IStateForm>(cloneDeep(originData));
  const [refDialog, setRefDialog] = useState<HTMLDivElement | null>(null);
  const [listDevanModelItem, setListDevanModelItem] = useState<
    DevanModelInfo[]
  >([]);

  // 初期化 =====================================
  useEffect(() => {
    if (open) {
      setStateForm({ berth_number: data.berth_number ?? "" });
      setFormError(originData);
      //機種情報を取得
      getModelInfo();
    }
  }, [open, data]);

  // 入力 =====================================
  const onChangeText = (field: keyof IStateForm) => (newText: string) => {
    setStateForm({ ...stateForm, [field]: newText });
  };

  const validator = (field: keyof IStateForm) => {
    let mess: string = "";
    switch (field) {
      case "berth_number":
        mess = Validation.validate({
          type: "text",
          name: "バース番号",
          value: stateForm.berth_number,
          required: true,
          max_length: 5,
        });
        break;
    }
    return mess;
  };

  const onValidateText = (field: keyof IStateForm) => {
    const mess = validator(field);
    setFormError({ ...formError, [field]: mess });
  };

  // 閉じる =====================================
  const handleClose = () => {
    onClose();
  };

  // 機種情報取得 =====================================
  const getModelInfo = async () => {
    LoadingOverlayController.show();
    const param = {
      container_no: data.container_no,
      date: data.date_of_delivery,
      time: data.delivery_time,
    };
    await getDavanByContainerApi(param)
      .then((res) => {
        LoadingOverlayController.hide();
        if (res?.data) {
          setListDevanModelItem(
            res?.data.map((item: any) => ({
              SK: item.SK,
              product_class: item.product_class,
              model_name: item.model_name,
              number_of_moves: item.number_of_moves,
            })),
          );
        }
      })
      .catch((error: any) => {
        LoadingOverlayController.hide();
        ModalController.show({
          message: error?.detail ?? messages.COMMON.MSG_COMMON_ERROR_001,
          visibleButton2: true,
        });
      });
  };

  // デバン受付 =====================================
  const handleSave = () => {
    const sort_key_list = listDevanModelItem.map((item) => item.SK);
    const formData = {
      container_sort_key: data.SK,
      sort_key_list: sort_key_list,
      berth_number: stateForm.berth_number,
    };
    updateDevanReception(formData)
      .then((res: any) => {
        LoadingOverlayController.show();
        if (res) {
          LoadingOverlayController.hide();
          if (!ModalController.isShowing()) {
            ModalController.show({
              message:
                messages.COMMON.MSG_COMMON_SUCCESS_001("デバン受付の更新"),
              visibleButton2: true,
              handlePressButton2: () => {
                searchHandle();
                onClose();
              },
              disableFeedback: true,
            });
          }
        }
      })
      .catch((error: any) => {
        LoadingOverlayController.hide();
        if (!ModalController.isShowing()) {
          ModalController.show({
            message: messages.COMMON.MSG_COMMON_ERROR_002("デバン受付"),
            visibleButton2: true,
            disableFeedback: true,
          });
        }
      });
  };

  // ボタン制御 =====================================
  const isDisabled = useMemo(
    () => Boolean(!stateForm.berth_number || formError.berth_number),
    [stateForm, formError],
  );

  return (
    <FullScreenDialog
      title="デバン受付"
      open={open}
      onClose={handleClose}
      refDialog={(ref) => setRefDialog(ref)}
    >
      <Tooltip title="閉じる">
        <IconButton
          onClick={handleClose}
          sx={{
            position: { xs: "absolute", md: "fixed" },
            right: 20,
            top: 15,
            zIndex: 2000,
          }}
        >
          <Close sx={{ color: "white" }} />
        </IconButton>
      </Tooltip>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          alignItems: "center",
        }}
      >
        <TextField
          label={
            <>
              <LabelRequired title={"バース番号"} />
            </>
          }
          value={stateForm.berth_number}
          name="berth_number"
          onChange={(e) => onChangeText("berth_number")(e.target.value)}
          onBlur={() => onValidateText("berth_number")}
          error={formError.berth_number.length > 0}
          helperText={formError.berth_number}
        />
        <Button
          style={{ marginTop: 20 }}
          onClick={handleSave}
          disabled={isDisabled}
          color="secondary"
        >
          {"保存"}
        </Button>
      </div>
      <Card
        variant="outlined"
        sx={{
          my: 5,
        }}
      >
        <CardContent sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid container spacing={2}>
            {data &&
              Object.keys(data).map((key) =>
                Object.keys(devanInfo).includes(key) ? (
                  <React.Fragment key={key}>
                    <Grid item xs={2}>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{
                          color: Colors.DISABLE_BTN_TEXT,
                          display: "block",
                        }}
                      >
                        {devanInfo[key]}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{ color: Colors.TEXT, display: "block" }}
                      >
                        {data[key]}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                ) : null,
              )}
          </Grid>
        </CardContent>
      </Card>
      <Typography variant="h5" sx={{ mb: 1 }}>
        機種情報
      </Typography>
      <Grid container spacing={2} sx={{ pb: 5 }}>
        {listDevanModelItem.length &&
          listDevanModelItem.map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ width: "100%" }}>
                <CardContent>
                  {Object.keys(item).map((key) =>
                    Object.keys(devanModelInfo).includes(key) &&
                    devanModelInfo[key] ? (
                      <Box
                        key={key}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          variant="body1"
                          component="span"
                          sx={{
                            mr: 2,
                            color: Colors.DISABLE_BTN_TEXT,
                            flex: 1,
                          }}
                        >
                          {devanModelInfo[key]}
                        </Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          sx={{ mr: 2, color: Colors.TEXT, flex: 1 }}
                        >
                          {item[key]}
                        </Typography>
                      </Box>
                    ) : null,
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </FullScreenDialog>
  );
};

export default DevanReception;
