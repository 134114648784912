import React, { VFC, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  TextField,
  Stack,
  Typography,
  FormLabel,
  Card,
  Divider,
  FormHelperText,
} from "@mui/material";
import { useEffect } from "react";
import { Validation } from "@validation";
import { checkActivityBaseExists } from "@api/groupMaster";
import _ from "lodash";
import GroupSelectDialog from "screens/GroupSelectDialog";
import ModalController from "@shared-components/modal/ModalController";
import LabelRequired from "components/atoms/LabelRequired";

interface IStateForm {
  activity_base_name: string;
  activity_base_code: string;
  group_sk: string;
  SK?: string;
  group_name?: string;
}

interface IProps {
  data: IStateForm;
  open: boolean;
  onClose: Function;
  onChange: Function;
}

const initialData: IStateForm = {
  activity_base_name: "",
  activity_base_code: "",
  group_sk: "",
  SK: "",
  group_name: "",
};

const ActivityBaseEditDialog: VFC<IProps> = ({
  data,
  open,
  onClose,
  onChange,
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [stateForm, setStateForm] = useState<IStateForm>(initialData);
  const [formError, setFormError] = useState<IStateForm>(initialData);
  const [openGroupSelect, setOpenGroupSelect] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [originCode, setOriginCode] = useState<string>("");

  useEffect(() => {
    const setInitialData = () => {
      setStateForm(data);
      setFormError(initialData);
      setOriginCode(data.activity_base_code);

      if (data.activity_base_name !== "") {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };

    if (open) {
      setInitialData();
    }
  }, [open, data]);

  // ------------------------------------------------------------------
  // 入力
  // ------------------------------------------------------------------
  const onChangeText =
    (field: keyof IStateForm) => (newText: string | undefined) => {
      let newStateForm = { ...stateForm, [field]: newText };
      setStateForm(newStateForm);
    };

  const handleChangeGroup = (data: {
    SK: string;
    name: string;
    nameAll: string;
  }) => {
    setStateForm({ ...stateForm, group_sk: data.SK, group_name: data.nameAll });
    const mess = validator("group_sk", data.SK);
    setFormError({ ...formError, group_sk: mess });
  };


  function convertFullWidthToHalfWidth(inputString: string) {
    return inputString.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (char) {
      return String.fromCharCode(char.charCodeAt(0) - 65248);
    });
  }

  const onChangeTextUpperCase =
    (field: string) =>
      (text: string) => {
        let halfWidthString = convertFullWidthToHalfWidth(text);
        let upperCaseString = halfWidthString.replace(
          /[a-zA-Z]/g,
          function (char) {
            return char.toUpperCase();
          },
        );
        setStateForm({ ...stateForm, [field]: upperCaseString });
        onValidateCheckText("activity_base_code", upperCaseString)
      };

  const onValidateText = (field: keyof IStateForm) => {
    const mess = validator(field, stateForm[field]);
    setFormError({ ...formError, [field]: mess });
  };

  const onValidateCheckText = (field: keyof IStateForm, text: string) => {
    const mess = validator(field, text);
    setFormError({ ...formError, [field]: mess });
  };

  const validator = (field: keyof IStateForm, value?: string) => {
    let mess: string = "";

    switch (field) {
      case "activity_base_name":
        mess = Validation.validate({
          type: "text",
          name: "拠点名",
          value: value,
          required: true,
          max_length: 20,
        });
        break;
      case "activity_base_code":
        mess = Validation.validate({
          type: "alpha_num",
          name: "拠点コード",
          value: value,
          required: true,
          max_length: 5,
        });
        break;
      case "group_sk":
        mess = Validation.validate({
          type: "text",
          name: "グループ",
          value: value,
          required: true,
        });
        break;
    }
    return mess;
  };

  const validationAllCheck = () => {
    let checkOK = true;
    Object.entries(stateForm).some(([key, value]) => {
      let mess = validator(key as keyof IStateForm, value);
      if (mess) {
        checkOK = false;
        //ループ中断
        return true;
      }
    });
    return checkOK;
  };

  useEffect(() => {
    if (validationAllCheck()) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [stateForm, formError]);

  // ------------------------------------------------------------------
  // 保存
  // ------------------------------------------------------------------
  const handleSave = async () => {
    await checkActivityBaseExists({
      activity_base_name: stateForm.activity_base_name,
      activity_base_code: stateForm.activity_base_code,
      group_sk: stateForm.group_sk,
      SK: stateForm.SK ?? "",
      check_user:
        originCode != "" && stateForm.activity_base_code != originCode,
    })
      .then((res: any) => {
        if (res) {
          onChange(stateForm);
          handleClose();
        }
      })
      .catch((error: any) => {
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      });
  };

  // ------------------------------------------------------------------
  // 閉じる
  // ------------------------------------------------------------------
  const handleClose = () => {
    onClose();
    setStateForm(initialData);
  };

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <FormGroup>
          <Stack>
            <TextField
              label={
                <>
                  <LabelRequired title={"拠点コード"} />
                </>
              }
              value={stateForm.activity_base_code}
              onChange={(e) =>
                onChangeText("activity_base_code")(e.target.value)
              }
              onBlur={(e) => {
                onChangeTextUpperCase("activity_base_code")(e.target.value)
              }}
              error={formError.activity_base_code.length > 0}
              helperText={formError.activity_base_code}
              inputProps={{
                maxLength: 5,
              }}
            />
            <TextField
              label={
                <>
                  <LabelRequired title={"拠点名"} />
                </>
              }
              value={stateForm.activity_base_name}
              onChange={(e) =>
                onChangeText("activity_base_name")(e.target.value)
              }
              onBlur={() => onValidateText("activity_base_name")}
              error={formError.activity_base_name.length > 0}
              helperText={formError.activity_base_name}
              inputProps={{
                maxLength: 20,
              }}
            />
            <FormGroup>
              <LabelRequired title={"グループ"} />
              <Card variant="outlined" sx={{ width: "100%", px: 1 }}>
                {stateForm.group_name && (
                  <>
                    <Typography sx={{ py: 2, px: 1 }}>
                      <span className="IgnoreExtractRuleTarget">
                        {stateForm.group_name}
                      </span>
                    </Typography>
                    <Divider />
                  </>
                )}
                <Button
                  variant="text"
                  fullWidth
                  sx={{ my: 1 }}
                  onClick={() => {
                    setOpenGroupSelect(true);
                  }}
                >
                  {"グループ設定"}
                </Button>
              </Card>
              <FormHelperText error={formError.group_sk.length > 0}>
                {formError.group_sk}
              </FormHelperText>
            </FormGroup>
          </Stack>
        </FormGroup>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={handleClose} variant="outlined">
          キャンセル
        </Button>
        <Button onClick={handleSave} disabled={disabled}>
          OK
        </Button>
      </DialogActions>

      <GroupSelectDialog
        open={openGroupSelect}
        setOpen={setOpenGroupSelect}
        onChange={handleChangeGroup}
      />
    </Dialog>
  );
};

export default ActivityBaseEditDialog;
