import React, {
  forwardRef,
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { getProjectLocationIdStore } from "selector/projectSelector";
import { getIncidentDamageItem } from "sagas/setting";
import { useDispatch } from "react-redux";
import { TYPES } from "store/types";
import {
  MenuItem,
  Box,
  Button,
  Card,
  CardContent,
  SelectChangeEvent,
  Stack,
  TextField,
  Autocomplete,
  FormGroup,
  Typography,
  FormLabel,
  CardHeader,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { Validation, isHalfWidthCharacter } from "@validation";
import { getListUserByLocationId } from "@api/User";
import { getUserInfo } from "@utils/index";
import { useSelector } from "react-redux";
import { createObjectURL } from "@utils/index";
import formatDateToString from "@utils/DateFormat";
import Dropzone, { IDropZoneProps } from "components/atoms/Dropzone";
import { Colors } from "@template/style";
import {
  IncidentDamageReportType,
  ErrorIncidentDamageReportType,
  originData,
  originErrorData,
  nameList,
} from "./IncidentDamageReportType";
import { RootState } from "store/reducer";
import TimePickerCustom from "components/atoms/TimePickerCustom";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";
import SelectLabel from "components/atoms/SelectLabel";
import LabelRequired from "components/atoms/LabelRequired";
import { cloneDeep, debounce, isArray, isEqual } from "lodash";
import { AllIncidentReportItem, User } from "services/models";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { uploadImageToS3 } from "@utils/template";
import LabelValueText from "components/atoms/LabelValueText";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import {
  getImageArray,
  getInfoFromTemplate,
  insertReportLocal,
  makeNewHtml,
} from "@utils/template/incidentDamageReport";
import { getModelNameMaster } from "@api/master";

const maxNumberImageDefault: number = 3;
const imageDefault: Type.ImageInfoType = {
  type: "",
  uri: "",
  name: "",
  path_file: "",
};

const REPORTER_STEP = 0;
const APPROVER_STEP_1 = 1;
const APPROVER_STEP_2 = 2;
const APPROVER_STEP_3 = 3;
const APPROVER_STEP_4 = 4;
const APPROVER_STEP_5 = 5;

const yyyyMMddString = "2024-04-24 ";

//数字のリストに空白を追加
const addWhiteSpaceForNumList = (data: Array<any>) => {
  if (data.length == 0) {
    return data;
  }
  data.splice(0, 0, null);
  return data;
};

//1～10のリスト
const quantityList = Array.from({ length: 10 }, (_, index) =>
  String(index + 1),
);

//勤続年数 0～50
const ExperienceYearsOfEmployee = addWhiteSpaceForNumList(
  Array.from({ length: 51 }, (_, index) => String(index)),
);

//勤続月数 0～11
const ExperienceMonthsOfEmployee = addWhiteSpaceForNumList(
  Array.from({ length: 12 }, (_, index) => String(index)),
);

//1～12月
const twelveMonths = addWhiteSpaceForNumList(
  Array.from({ length: 12 }, (_, index) => String(index + 1)),
);

//SerialNoの最大設定数
const maxNumberSerialNoDefault: number = 10;

interface IIncidentDamageReport {
  infoToEdit?: any;
  htmlString: string;
  setHtmlString: Function;
  isManage?: boolean;
  open: boolean;
  step: number;
  handleSetTemplate: () => void;
  handleClose: () => void;
  created_by: string;
}

const IncidentDamageReport = (
  {
    infoToEdit,
    htmlString,
    setHtmlString,
    isManage = false,
    open,
    step = 0,
    handleSetTemplate,
    handleClose,
    created_by,
  }: IIncidentDamageReport,
  ref: React.Ref<unknown>,
) => {
  const dispatch = useDispatch();
  //stateForm
  const [stateForm, setStateForm] = useState<IncidentDamageReportType>(
    cloneDeep(originData),
  );
  const [originStateForm, setOriginStateForm] =
    useState<IncidentDamageReportType>(cloneDeep(originData));

  //formError
  const [formError, setFormError] = useState<ErrorIncidentDamageReportType>(
    cloneDeep(originErrorData),
  );

  //AutoCompleteのinput
  const [inputQuantityValue, setInputQuantityValue] = useState<string>("");
  const [inputCauseValue, setInputCauseValue] = useState<string>("");
  const [inputCauseOverviewValue, setInputCauseOverviewValue] =
    useState<string>("");
  const [inputRootCauseValue, setInputRootCauseValue] = useState<string>("");
  const [
    inputCorrectiveActionImprovementValue,
    setInputCorrectiveActionImprovementValue,
  ] = useState<string>("");
  const [inputInvoiceNoValue, setInputInvoiceNoValue] = useState<string>("");
  const [inputReportedByValue, setInputReportedByValue] = useState<string>("");

  //Last usedを取得
  const lastUsedIncidentDamageReport = useSelector(
    (state: RootState) => state.incidentDamageReport,
  );

  //タブ選択のリスト
  const [warehouseStatus, setWarehouseStatus] = useState<any[]>([]);
  const [areaStatus, setAreaStatus] = useState<any[]>([]);
  const [reportedByStatus, setReportedByStatus] = useState<any[]>([]);
  const [customerStatus, setCustomerStatus] = useState<any[]>([]);
  const [commodityStatus, setCommodityStatus] = useState<any[]>([]);
  const [damageLocationStatus, setDamageLocationStatus] = useState<any[]>([]);
  const [causeStatus, setCauseStatus] = useState<any[]>([]);
  const [QAAuthorizerStatus, setQAAuthorizerStatus] = useState<any[]>([]);
  const [occurredCompanyStatus, setOccurredCompanyStatus] = useState<any[]>([]);
  const [invoiceNoStatus, setInvoiceNoStatus] = useState<any[]>([]);
  const [affiliationOfEmployeeStatus, setAffiliationOfEmployeeStatus] =
    useState<any[]>([]);
  const [wasteClaimStatus, setWasteClaimStatus] = useState<any[]>([]);
  const [accidentRankStatus, setAccidentRankStatus] = useState<any[]>([]);
  const [MAStatus, setMAStatus] = useState<any[]>([]);
  const [nonconformityDetailStatus, setNonconformityDetailStatus] = useState<
    any[]
  >([]);
  const [causeOverviewStatus, setCauseOverviewStatus] = useState<any[]>([]);
  const [rootCauseStatus, setRootCauseStatus] = useState<any[]>([]);
  const [logisticsEquipmentsStatus, setLogisticsEquipmentsStatus] = useState<
    any[]
  >([]);
  const [
    correctiveActionImprovementStatus,
    setCorrectiveActionImprovementStatus,
  ] = useState<any[]>([]);
  const [finalPayerStatus, setFinalPayerStatus] = useState<any[]>([]);
  const [responsibilityStatus, setResponsibilityStatus] = useState<any[]>([]);
  const [nonconformityOverviewStatus, setNonconformityOverviewStatus] =
    useState<any[]>([]);
  const [occuranceProcessStatus, setOccuranceProcessStatus] = useState<any[]>(
    [],
  );
  const [processOfRootCauseStatus, setProcessOfRootCauseStatus] = useState<
    any[]
  >([]);
  const [EstAccStatus, setEstAccStatus] = useState<any[]>([]);
  const [departmentOfPICStatus, setDepartmentOfPICStatus] = useState<any[]>([]);
  const [processInLogisticsStatus, setProcessInLogisticsStatus] = useState<
    any[]
  >([]);
  const [responsibilityInProcessStatus, setResponsibilityInProcessStatus] =
    useState<any[]>([]);
  const [
    classificationOfNonconformityStatus,
    setClassificationOfNonconformityStatus,
  ] = useState<any[]>([]);
  const [classificationOfCauseStatus, setClassificationOfCauseStatus] =
    useState<any[]>([]);
  const [janCode, setJanCode] = useState<string>("");

  const [completeGetData, setCompleteGetData] = useState<boolean>(false);
  const [maxImage, setMaxImage] = useState<number>(maxNumberImageDefault);
  const user_info: User = getUserInfo();
  const location_id = useSelector(getProjectLocationIdStore);
  const nonconformityDetailRef = useRef(false);

  const [dateTime, setDateTime] = useState<Date | null>(null);

  //要修正？
  const [maxSerialNo, setMaxSerialNo] = useState<number>(
    maxNumberSerialNoDefault,
  );

  const isExistError = useMemo(() => {
    const notArrayItems = Object.values(formError).filter(
      (item) => !Array.isArray(item),
    );
    const isExistErrorInNotArrayItems = notArrayItems.some((v) => v !== "");
    if (isExistErrorInNotArrayItems) return true;

    const ArrayItems = Object.values(formError).filter((item) =>
      Array.isArray(item),
    );
    const isExistErrorInArrayItems = ArrayItems.some((data: any) =>
      data.some((v: any) => v !== ""),
    );

    return isExistErrorInArrayItems;
  }, [formError]);

  useImperativeHandle(ref, () => ({
    getStateForm: () => {
      // プレビュー用の値を削除
      let cloneStateForm = cloneDeep(stateForm);
      cloneStateForm.image_array.map((item: any) => {
        delete item.base64;
      });
      return cloneStateForm;
    },
    getOriginStateForm: () => originStateForm,
    onSubmit: onSubmit,
    disabledPreview: () => isExistError,
    disabledSubmit: () => isExistError,
  }));

  // マスタデータ取得 =====================================
  const addWhiteSpace = (data: Array<any>) => {
    if (data.length == 0) {
      return data;
    }
    data.splice(0, 0, { SK: null, name: null });
    return data;
  };

  const getData = async () => {
    try {
      LoadingOverlayController.show();

      // マスタの更新チェック
      let resReportItem: AllIncidentReportItem = await getIncidentDamageItem(
        location_id,
      );
      ///起票者//
      //Warehouse
      if (resReportItem?.warehouseStatus) {
        setWarehouseStatus(addWhiteSpace(resReportItem?.warehouseStatus));
      }

      //Area
      if (resReportItem?.areaStatus) {
        setAreaStatus(addWhiteSpace(resReportItem?.areaStatus));
      }

      //Reported by
      const resListUser = await getListUserByLocationId();

      if (resListUser?.length) {
        setReportedByStatus(resListUser);
      }

      //Customer (Shipper)
      if (resReportItem?.customerStatus) {
        setCustomerStatus(addWhiteSpace(resReportItem?.customerStatus));
      }

      //Commodity
      if (resReportItem?.commodityStatus) {
        setCommodityStatus(addWhiteSpace(resReportItem?.commodityStatus));
      }

      //Damage Location

      if (resReportItem?.damageLocationStatus) {
        setDamageLocationStatus(
          addWhiteSpace(resReportItem?.damageLocationStatus),
        );
      }

      //Cause (Site Opinion)
      if (resReportItem?.causeStatus) {
        setCauseStatus(resReportItem?.causeStatus);
      }

      //QA Authorizer

      if (resReportItem?.QAAuthorizerStatus) {
        setQAAuthorizerStatus(addWhiteSpace(resReportItem?.QAAuthorizerStatus));
      }

      //Occurred Company

      if (resReportItem?.occurredCompanyStatus) {
        setOccurredCompanyStatus(
          addWhiteSpace(resReportItem?.occurredCompanyStatus),
        );
      }

      if (step > REPORTER_STEP) {
        //第1承認者
        //Invoice No.
        setInvoiceNoStatus(["No Invoice"]);

        //Employee's Affiliation
        if (resReportItem?.affiliationOfEmployeeStatus) {
          setAffiliationOfEmployeeStatus(
            addWhiteSpace(resReportItem?.affiliationOfEmployeeStatus),
          );
        }
      }

      if (step > APPROVER_STEP_1) {
        //第2承認者
        //Waste /Claim

        if (resReportItem?.wasteClaimStatus) {
          setWasteClaimStatus(addWhiteSpace(resReportItem?.wasteClaimStatus));
        }

        //Accident Rank

        if (resReportItem?.accidentRankStatus) {
          setAccidentRankStatus(
            addWhiteSpace(resReportItem?.accidentRankStatus),
          );
        }

        //MAmaster
        if (resReportItem?.MAStatus) {
          setMAStatus(resReportItem?.MAStatus);
        }

        //Nonconformity Detail

        if (resReportItem?.nonconformityDetailStatus) {
          setNonconformityDetailStatus(
            addWhiteSpace(resReportItem?.nonconformityDetailStatus),
          );
        }

        //Nonconformity Overview
        if (resReportItem?.nonconformityOverviewStatus) {
          setNonconformityOverviewStatus(
            addWhiteSpace(resReportItem?.nonconformityOverviewStatus),
          );
        }

        //Occurance Process
        if (resReportItem?.occuranceProcessStatus) {
          setOccuranceProcessStatus(
            addWhiteSpace(resReportItem?.occuranceProcessStatus),
          );
        }

        //Process of Root Cause
        if (resReportItem?.processOfRootCauseStatus) {
          setProcessOfRootCauseStatus(
            addWhiteSpace(resReportItem?.processOfRootCauseStatus),
          );
        }

        //Responsibility

        if (resReportItem?.responsibilityStatus) {
          setResponsibilityStatus(
            addWhiteSpace(resReportItem?.responsibilityStatus),
          );
        }

        //Department_of_PIC

        if (resReportItem?.departmentOfPICStatus) {
          setDepartmentOfPICStatus(
            addWhiteSpace(resReportItem?.departmentOfPICStatus),
          );
        }

        //Process_in_Logistics

        if (resReportItem?.processInLogisticsStatus) {
          setProcessInLogisticsStatus(
            addWhiteSpace(resReportItem?.processInLogisticsStatus),
          );
        }

        //Responsibility_in_Process

        if (resReportItem?.responsibilityInProcessStatus) {
          setResponsibilityInProcessStatus(
            addWhiteSpace(resReportItem?.responsibilityInProcessStatus),
          );
        }

        //Classification_of_Nonconformity

        if (resReportItem?.classificationOfNonconformityStatus) {
          setClassificationOfNonconformityStatus(
            addWhiteSpace(resReportItem?.classificationOfNonconformityStatus),
          );
        }

        //Classification_of_Cause

        if (resReportItem?.classificationOfCauseStatus) {
          setClassificationOfCauseStatus(
            addWhiteSpace(resReportItem?.classificationOfCauseStatus),
          );
        }

        //Cause Overview

        if (resReportItem?.causeOverviewStatus) {
          setCauseOverviewStatus(resReportItem?.causeOverviewStatus);
        }

        //Root Cause

        if (resReportItem?.rootCauseStatus) {
          setRootCauseStatus(resReportItem?.rootCauseStatus);
        }

        //Logistics Equipments

        if (resReportItem?.logisticsEquipmentsStatus) {
          setLogisticsEquipmentsStatus(
            addWhiteSpace(resReportItem?.logisticsEquipmentsStatus),
          );
        }

        //Corrective Action /Improvement

        if (resReportItem?.correctiveActionImprovementStatus) {
          setCorrectiveActionImprovementStatus(
            resReportItem?.correctiveActionImprovementStatus,
          );
        }
      }
      //第3承認者はリストなし

      if (step > APPROVER_STEP_3) {
        //第4承認者
        //Final Payer

        if (resReportItem?.finalPayerStatus) {
          setFinalPayerStatus(addWhiteSpace(resReportItem?.finalPayerStatus));
        }

        //Est/Acc

        if (resReportItem?.EstAccStatus) {
          setEstAccStatus(addWhiteSpace(resReportItem?.EstAccStatus));
        }
      }
      //第5承認者はリストなし
      setCompleteGetData(true);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    getData();
    return () => {
      setCompleteGetData(false);
    };
  }, []);

  useEffect(() => {
    if (open && completeGetData) {
      if (typeof infoToEdit?.remark === "string") {
        const date = new Date();
        const dateString = formatDateToString(date, "YMD");

        if (step == APPROVER_STEP_1) {
          //第1次承認者
          infoToEdit = {
            ...infoToEdit,
            affiliation_of_employee:
              infoToEdit.affiliation_of_employee ??
              setDefaultValue(
                "affiliation_of_employee",
                affiliationOfEmployeeStatus,
              ),
          };
        }

        if (step == APPROVER_STEP_2) {
          //第2次承認者
          infoToEdit = {
            ...infoToEdit,
            waste_claim_category:
              infoToEdit.waste_claim_category ??
              setDefaultValue("waste_claim_category", wasteClaimStatus),
            accident_rank:
              infoToEdit.accident_rank ??
              setDefaultValue("accident_rank", accidentRankStatus),
            completion_improvement_date: infoToEdit.completion_improvement_date
              ? infoToEdit.completion_improvement_date
              : dateString,
            cause_overview:
              infoToEdit.cause_overview === ""
                ? setDefaultValue("cause_overview", causeOverviewStatus)
                : infoToEdit.cause_overview,
            root_cause:
              infoToEdit.root_cause === ""
                ? setDefaultValue("root_cause", rootCauseStatus)
                : infoToEdit.root_cause,
            logistics_equipments:
              infoToEdit.logistics_equipments ??
              setDefaultValue(
                "logistics_equipments",
                logisticsEquipmentsStatus,
              ),
            corrective_action_improvement:
              infoToEdit.corrective_action_improvement === ""
                ? setDefaultValue(
                    "corrective_action_improvement",
                    correctiveActionImprovementStatus,
                  )
                : infoToEdit.corrective_action_improvement,
          };
        }

        if (step == APPROVER_STEP_4) {
          //第4次承認者
          infoToEdit = {
            ...infoToEdit,
            closing_date: infoToEdit.closing_date
              ? infoToEdit.closing_date
              : dateString,
            final_payer:
              infoToEdit.final_payer ??
              setDefaultValue("final_payer", finalPayerStatus),
            est_acc:
              infoToEdit.est_acc ?? setDefaultValue("est_acc", EstAccStatus),
          };
        }

        //AutoCustomのinputに値をセット
        setInputInvoiceNoValue(
          typeof infoToEdit.invoice_no === "object" &&
            "name" in infoToEdit.invoice_no
            ? infoToEdit.invoice_no.name
            : infoToEdit.invoice_no,
        );
        setInputQuantityValue(
          typeof infoToEdit.quantity === "object" &&
            "name" in infoToEdit.quantity
            ? infoToEdit.quantity.name
            : infoToEdit.quantity,
        );
        setInputCauseValue(
          typeof infoToEdit.cause === "object" && "name" in infoToEdit.cause
            ? infoToEdit.cause.name
            : infoToEdit.cause,
        );
        setInputCauseOverviewValue(
          typeof infoToEdit.cause_overview === "object" &&
            "name" in infoToEdit.cause_overview
            ? infoToEdit.cause_overview.name
            : infoToEdit.cause_overview,
        );
        setInputRootCauseValue(
          typeof infoToEdit.root_cause === "object" &&
            "name" in infoToEdit.root_cause
            ? infoToEdit.root_cause.name
            : infoToEdit.root_cause,
        );
        setInputCorrectiveActionImprovementValue(
          typeof infoToEdit.corrective_action_improvement === "object" &&
            "name" in infoToEdit.corrective_action_improvement
            ? infoToEdit.corrective_action_improvement.name
            : infoToEdit.corrective_action_improvement,
        );
        setInputReportedByValue(
          typeof infoToEdit.reported_by === "object" &&
            "full_name" in infoToEdit.reported_by
            ? infoToEdit.reported_by.full_name
            : infoToEdit.reported_by,
        );

        //infoToEdit.Timeは「時間:分」の文字列なので、Date型にキャストするために、年月日の情報を追加する
        const dString = yyyyMMddString + infoToEdit.time;
        setDateTime(new Date(dString));

        setStateForm(cloneDeep(infoToEdit));
        setOriginStateForm(cloneDeep(infoToEdit));
      } else {
        if (!step) {
          //起票者のとき(step=0)
          let defaultCommodityStatus = undefined;
          let defaultCustomerStatus = undefined;
          //デフォルト値の設定
          const date = new Date();
          const dateString = formatDateToString(date, "YMD");
          setDateTime(date);
          const timeString = formatDateToString(date, "HHmm");
          if (lastUsedIncidentDamageReport.Customer) {
            let defaultCustomerList = customerStatus.filter(
              (status) =>
                status.SK === lastUsedIncidentDamageReport.customer?.SK,
            );
            if (defaultCustomerList.length) {
              defaultCustomerStatus = defaultCustomerList[0];
            }
          }
          if (lastUsedIncidentDamageReport.Commodity) {
            let defaultCommodityList = commodityStatus.filter(
              (status) =>
                status.SK === lastUsedIncidentDamageReport.commodity?.SK,
            );
            if (defaultCommodityList.length) {
              defaultCommodityStatus = defaultCommodityList[0];
            }
          }
          const info_edit = {
            ...originData,
            date: dateString,
            time: timeString,
            warehouse: setDefaultValue("warehouse", warehouseStatus),
            area: setDefaultValue("area", areaStatus),
            damage_location: setDefaultValue(
              "damage_location",
              damageLocationStatus,
            ),
            customer: defaultCustomerStatus,
            commodity: defaultCommodityStatus,
            reported_by: user_info,
            cause: setDefaultValue("cause", causeStatus),
            occurred_company: setDefaultValue(
              "occurred_company",
              occurredCompanyStatus,
            ),
            qa_authorizer: setDefaultValue("qa_authorizer", QAAuthorizerStatus),
          };
          setInputReportedByValue(user_info.full_name);
          setInputCauseValue(
            typeof info_edit.cause === "object" && "name" in info_edit.cause
              ? info_edit.cause.name
              : "",
          );
          setStateForm(cloneDeep(info_edit));
          setOriginStateForm(cloneDeep(info_edit));
        }
      }
    }
  }, [open, completeGetData]);

  useEffect(() => {
    // nonconformity_detailを入力したとき、ほかの値を連動させる
    const isChangeNonconformityDetail = nonconformityDetailRef.current;
    nonconformityDetailRef.current = false;
    if (!isChangeNonconformityDetail) return;

    //nonconformity_detailで、MAマスタに合致するものを検索する
    let ma_master = MAStatus.filter(
      (status) =>
        status.nonconformity_detail === stateForm.nonconformity_detail?.SK,
    );
    if (ma_master.length) {
      let occurance_process = occuranceProcessStatus.filter(
        (status) => status.SK === ma_master[0].occurance_process,
      );
      let process_of_root_cause = processOfRootCauseStatus.filter(
        (status) => status.SK === ma_master[0].process_of_root_cause,
      );
      let responsibility = responsibilityStatus.filter(
        (status) => status.SK === ma_master[0].responsibility,
      );
      let department_of_pic = departmentOfPICStatus.filter(
        (status) => status.SK === ma_master[0].department_of_pic,
      );
      let process_in_logistics = processInLogisticsStatus.filter(
        (status) => status.SK === ma_master[0].process_in_logistics,
      );
      let responsibility_in_process = responsibilityInProcessStatus.filter(
        (status) => status.SK === ma_master[0].responsibility_in_process,
      );
      let classification_of_nonconformity =
        classificationOfNonconformityStatus.filter(
          (status) =>
            status.SK === ma_master[0].classification_of_nonconformity,
        );
      let classification_of_cause = classificationOfCauseStatus.filter(
        (status) => status.SK === ma_master[0].classification_of_cause,
      );
      setStateForm({
        ...stateForm,
        occurance_process: occurance_process[0],
        process_of_root_cause: process_of_root_cause[0],
        responsibility: responsibility[0],
        department_of_pic: department_of_pic[0],
        process_in_logistics: process_in_logistics[0],
        responsibility_in_process: responsibility_in_process[0],
        classification_of_nonconformity: classification_of_nonconformity[0],
        classification_of_cause: classification_of_cause[0],
      });
    } else {
      setStateForm({
        ...stateForm,
        occurance_process: undefined,
        process_of_root_cause: undefined,
        responsibility: undefined,
        department_of_pic: undefined,
        process_in_logistics: undefined,
        responsibility_in_process: undefined,
        classification_of_nonconformity: undefined,
        classification_of_cause: undefined,
      });
    }
  }, [stateForm.nonconformity_detail]);

  useEffect(() => {
    //小数点がある状態で、計算すると誤差が生じるので、100倍(値は小数第2位までとる)して整数してから差をとり、100で割る
    let amountCostActFloat = parseFloat(stateForm.amount_cost_act) * 100;
    let insuranceSubConAmountFloat =
      parseFloat(stateForm.insurance_sub_con_amount) * 100;
    let finalTLGTAmount = "";
    if (!isNaN(amountCostActFloat) && !isNaN(insuranceSubConAmountFloat)) {
      finalTLGTAmount = String(
        (amountCostActFloat - insuranceSubConAmountFloat) / 100,
      );
    }
    setStateForm({
      ...stateForm,
      final_tlgt_amount: finalTLGTAmount,
    });
  }, [stateForm.amount_cost_act, stateForm.insurance_sub_con_amount]);

  const setDefaultValue = (
    field: keyof IncidentDamageReportType,
    listObject: Array<any>,
  ) => {
    const default_data = listObject.find((item) => item?.is_default === true);

    if (default_data) {
      return default_data;
    } else {
      return originData[field];
    }
  };

  const handleSearchModelNameByJANCode = async () => {
    try {
      LoadingOverlayController.show();
      // JanCode検索
      let model_name = "";
      if (janCode) {
        const param = {
          jan_code: janCode,
        };
        const response = await getModelNameMaster(param);
        if (response?.data && response.data.length > 0) {
          model_name = response.data[0].name;
        } else {
          ModalController.show({
            message: messages.TEMPLATE.MSG_MODEL_NAME_NOT_EXIST,
            visibleButton2: true,
          });
        }
      }

      setStateForm((prevState) => ({
        ...prevState,
        model_name,
      }));
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // HTML取得 =====================================
  const getHtmlString = useCallback(
    async (state_form: IncidentDamageReportType, html_String: string) => {
      setHtmlString(""); // エラーメッセージの変更時に再描画する為、htmlを変更
      const res = getInfoFromTemplate(html_String);
      const image_array = await getImageArray(state_form.image_array);
      let newHtml: string = await makeNewHtml(
        html_String,
        state_form,
        res.qrcodeImageExample,
        res.addPageExample,
      );
      newHtml = insertReportLocal(
        newHtml,
        res.imageProductExample,
        res.pageImageExample,
        image_array,
      );
      setHtmlString(newHtml);
    },
    [],
  );

  const changeForm = useCallback(debounce(getHtmlString, 300), [getHtmlString]);

  useEffect(() => {
    changeForm(stateForm, htmlString);
  }, [stateForm, htmlString, formError]);

  // 入力制御 =====================================
  const onChangeText = (field: string) => (value: any) => {
    if (value === null) value = "";
    setStateForm({ ...stateForm, [field]: value });
  };

  const onChangeSelect =
    (field: string) => (list: any[], e: SelectChangeEvent<string>) => {
      const selected = list.find((item) => item && item.SK === e.target.value);
      setStateForm((prev) => ({ ...prev, [field]: selected }));
    };

  const onChangeImage = async (
    index: number,
    imageInfo: Type.ImageInfoType,
  ) => {
    const newStateForm: IncidentDamageReportType = { ...stateForm };
    newStateForm.image_array[index] = imageInfo;
    setStateForm(newStateForm);
  };

  const validation = (field: keyof IncidentDamageReportType, value: string) => {
    let mess: string = "";
    switch (field) {
      case "date":
        mess = Validation.validateDate(value, nameList[field], false);
        break;
      case "time":
        mess = Validation.validate({
          type: "time",
          name: nameList[field],
          value: value,
          required: false,
        });
        break;
      case "remark":
        mess = Validation.validate({
          type: "text",
          name: nameList[field],
          value: value,
          required: false,
          max_length: 300,
        });
        break;
      case "customer":
        mess = Validation.validate({
          type: "text",
          name: nameList[field],
          value: value,
          required: false,
          max_length: 12,
        });
        break;
      case "cpar_no":
        mess = Validation.validate({
          type: "text",
          name: nameList[field],
          value: value,
          required: false,
          max_length: 12,
        });
        break;
      case "model_name":
      case "serial_no":
        mess = Validation.validate({
          type: "text",
          name: nameList[field],
          value: value,
          required: false,
          max_length: 30,
        });
        break;
      case "quantity":
      case "scrap_unit":
      case "repair_unit":
      case "no_problem_unit":
        mess = Validation.validate({
          type: "number",
          name: nameList[field],
          value: value,
          required: false,
          max_length: 3,
        });
        break;
      case "system_transaction_no":
      case "received_invoice_no":
        mess = Validation.validate({
          type: "text",
          name: nameList[field],
          value: value,
          required: false,
          max_length: 24,
        });
        break;
      case "invoice_no":
        mess = Validation.validate({
          type: "text",
          name: nameList[field],
          value: value,
          required: false,
          max_length: 24,
        });
        break;
      case "corrective_action_improvement":
        mess = Validation.validate({
          type: "text",
          name: nameList[field],
          value: value,
          required: false,
          max_length: 80,
        });
        break;
      case "insurance_sub_con_amount":
      case "amount_cost_act":
      case "amount_cost_est":
        mess = Validation.validate({
          type: "float",
          name: nameList[field],
          value: value,
          required: false,
          max_length: 10,
        });
        break;
      case "cause":
        mess = Validation.validate({
          type: "text",
          name: nameList[field],
          value: value,
          required: false,
          max_length: 80,
        });
        break;
      case "reported_by":
        mess = Validation.validate({
          type: "text",
          name: nameList[field],
          value: value,
          required: false,
          max_length: 40,
        });
        break;
      default:
        mess = Validation.validate({
          type: "text",
          name: nameList[field],
          value: value,
          required: false,
          max_length: 300,
        });
        break;
    }
    return mess;
  };

  const onValidateText = async (field: keyof IncidentDamageReportType) => {
    const mess = await validation(field, String(stateForm[field]));
    setFormError({ ...formError, [field]: mess });
  };

  const onChangeDate =
    (field: keyof IncidentDamageReportType) => (value: string | null) => {
      if (!value) {
        value = "";
      } else {
        value = formatDateToString(value, "YMD");
      }

      let mess = "";
      if (value) {
        mess = validation(field, value);
      }
      setFormError({ ...formError, [field]: mess });
      setStateForm((prev) => ({ ...prev, [field]: value }));
    };

  const onChangeTime =
    (field: keyof IncidentDamageReportType) => (newValue: Date | null) => {
      const time = formatDateToString(newValue, "HHmm");
      setDateTime(newValue);
      setStateForm({ ...stateForm, [field]: time });
    };

  // 保存 =====================================
  const onSubmit = async () => {
    // 画像アップロード
    let newImageArray: Array<Type.ImageInfoType> = stateForm.image_array ?? [];
    if (stateForm.image_array) {
      newImageArray = await uploadImageToS3(stateForm.image_array);
    }
    let newStateForm = {
      ...stateForm,
      image_array: newImageArray,
    };
    // update last used
    dispatch({
      type: TYPES.SET_INCIDENT_DAMAGE_REPORT,
      payload: newStateForm,
    });
    await setStateForm(newStateForm);
    handleSetTemplate();
    handleClose();
    return false;
  };

  const renderMenuItem = useCallback(
    (list: Array<any>, value: string, name: string) => {
      return list
        ? list.map((item, index) => (
            <MenuItem value={item ? item[value] : ""} key={index}>
              {item && item[value] ? (
                <span className="IgnoreExtractRuleTarget">{item[name]}</span>
              ) : (
                <em>未選択</em>
              )}
            </MenuItem>
          ))
        : null;
    },
    [],
  );

  const handleDeleteImageItem = (index: number) => {
    if (index >= 0) {
      const newStateForm: IncidentDamageReportType = { ...stateForm };
      newStateForm.image_array.splice(index, 1);
      setStateForm(newStateForm);
    }
  };

  const handleAddImageItem = () => {
    if (stateForm?.image_array.length < maxImage) {
      setStateForm((prevState) => {
        const new_image_array = [...prevState.image_array, imageDefault];
        return { ...prevState, image_array: new_image_array };
      });
    }
  };

  const renderImageItem = (data: Array<Type.ImageInfoType>) => {
    return data.map((item, index) => (
      <CancelBtnBadge
        onClick={() => {
          if (item.uri !== "") {
            onChangeImage(index, imageDefault);
          } else {
            ModalController.show({
              message: "報告内容を削除しますか?", //修正？
              visibleButton1: true,
              visibleButton2: true,
              button1: { title: messages.COMMON.BUTTON.CANCEL },
              handlePressButton2: () => handleDeleteImageItem(index),
            });
          }
        }}
        key={index}
        sx={{ width: "100%" }}
        componentsProps={{ badge: { style: { right: "7%" } } }}
      >
        {item.uri !== "" ? (
          <Box
            component="img"
            src={
              item?.type === "image/svg+xml"
                ? item?.non_Local
                  ? item.uri_jpg
                  : item.uriThumb
                : item?.uri
            }
            sx={{
              height: 140,
              width: "100%",
              objectFit: "cover",
              borderRadius: 1,
            }}
          />
        ) : (
          <Box width={"100%"}>
            <Dropzone
              onChoose={(res) => {
                const uri = createObjectURL(res[0]);
                res[0]["uri"] = uri;
                onChangeImage(index, res[0]);
              }}
              maxFiles={1}
              showFileList={false}
              key={index}
            />
          </Box>
        )}
      </CancelBtnBadge>
    ));
  };

  const handleAddNewSerialNo = () => {
    if (
      stateForm?.serial_no.length < maxSerialNo &&
      formError?.serial_no.length < maxSerialNo
    ) {
      setStateForm((prevState) => {
        const new_serial_no = [...prevState.serial_no, ""];
        return { ...prevState, serial_no: new_serial_no };
      });
      setFormError((prevState) => {
        const new_serial_no = [...prevState.serial_no, ""];
        return { ...prevState, serial_no: new_serial_no };
      });
    }
  };

  const onChangeTextArray = (index: number) => (newText: string) => {
    let result = "";

    for (const character of newText) {
      if (isHalfWidthCharacter(character) || !isNaN(parseInt(character))) {
        result += character;
      }
    }

    let mess = validation("serial_no", result);

    setFormError((prevState) => {
      const new_serial_no = [...prevState.serial_no];
      new_serial_no[index] = mess;
      return { ...prevState, serial_no: new_serial_no };
    });

    setStateForm((prevState) => {
      const new_serial_no = [...prevState.serial_no];
      new_serial_no[index] = result;
      return { ...prevState, serial_no: new_serial_no };
    });
  };

  const handleRemoveSerialNo = (index: number) => {
    setFormError((prevState) => {
      const new_serial_no_error = [...prevState.serial_no];
      new_serial_no_error.splice(index, 1);
      return { ...prevState, serial_no: new_serial_no_error };
    });

    setStateForm((prevState) => {
      const new_serial_no = [...prevState.serial_no];
      new_serial_no.splice(index, 1);
      return { ...prevState, serial_no: new_serial_no };
    });
  };

  const renderListSerialNo = (data: Array<string>) => {
    return data.map((item, index) => (
      <React.Fragment key={index}>
        <OutlinedInput
          id={"product_id_" + index}
          inputProps={{ maxLength: 30 }}
          value={item}
          sx={{ mb: 1 }}
          fullWidth
          onChange={(e) => {
            onChangeTextArray(index)(e.target.value);
          }}
          endAdornment={
            index > 0 && (
              <IconButton
                onClick={() => {
                  handleRemoveSerialNo(index);
                }}
              >
                <Cancel />
              </IconButton>
            )
          }
          key={index}
          error={
            formError.serial_no[index] && formError.serial_no[index].length > 0
              ? true
              : false
          }
        />
        {formError.serial_no[index] && formError.serial_no[index].length > 0 && (
          // helperTextをPropsとして使用できないためspanタグで代用
          <span style={{ fontSize: "12px", color: "#FF4B01" }}>
            {formError.serial_no[index]}
          </span>
        )}
      </React.Fragment>
    ));
  };

  return (
    <Box height={"auto"}>
      <Stack>
        <Card>
          <Stack>
            <CardContent>
              <FormGroup>
                <Stack sx={styles.formStack}>
                  {/* 起票者の内容 */}

                  <DatePickerCustom
                    label={<LabelRequired title={"Date(YYYY/MM/DD)"} />}
                    value={stateForm?.date}
                    onChange={(e) => {
                      onChangeDate("date")(e);
                    }}
                    onBlur={() => onValidateText("date")}
                    error={formError.date.length > 0}
                    helperText={formError.date}
                  />
                  <TimePickerCustom
                    label={
                      <>
                        <LabelRequired title={"Time"} />
                      </>
                    }
                    value={dateTime}
                    onChange={(e) => {
                      onChangeTime("time")(e);
                    }}
                    onBlur={() => onValidateText("time")}
                    error={formError.time.length > 0}
                    helperText={formError.time}
                  />
                  <SelectLabel
                    label={<LabelRequired title={"Warehouse"} />}
                    value={stateForm.warehouse?.SK ?? ""}
                    isIgnoreExtractRuleLabel={false}
                    isIgnoreExtractRuleSelect={true}
                    onChange={(e) =>
                      onChangeSelect("warehouse")(warehouseStatus, e)
                    }
                  >
                    {renderMenuItem(warehouseStatus, "SK", "name")}
                  </SelectLabel>
                  <SelectLabel
                    label={<LabelRequired title={"Area"} />}
                    value={stateForm.area?.SK ?? ""}
                    isIgnoreExtractRuleLabel={false}
                    isIgnoreExtractRuleSelect={true}
                    onChange={(e) => onChangeSelect("area")(areaStatus, e)}
                  >
                    {renderMenuItem(areaStatus, "SK", "name")}
                  </SelectLabel>
                  <Autocomplete
                    disablePortal
                    id="reported_by"
                    options={reportedByStatus}
                    getOptionLabel={(option) => option.full_name ?? ""}
                    inputValue={inputReportedByValue}
                    onChange={(e, newValue) => {
                      setInputReportedByValue(newValue.full_name ?? "");
                      setStateForm({
                        ...stateForm,
                        reported_by: newValue.full_name ?? "",
                      });
                    }}
                    onInputChange={(e, newValue) => {
                      setInputReportedByValue(newValue);
                      setStateForm({
                        ...stateForm,
                        reported_by: newValue,
                      });
                    }}
                    onBlur={() => onValidateText("reported_by")}
                    renderInput={(params) => (
                      <div>
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            maxLength: 40,
                          }}
                          label={<LabelRequired title={"Reported by"} />}
                        />
                        {formError.reported_by.length > 0 && (
                          <Typography variant="caption" color="error">
                            {formError.reported_by}
                          </Typography>
                        )}
                      </div>
                    )}
                    freeSolo
                  />
                  <SelectLabel
                    label={<LabelRequired title={"Customer(Shipper)"} />}
                    value={stateForm.customer?.SK ?? ""}
                    isIgnoreExtractRuleLabel={false}
                    isIgnoreExtractRuleSelect={true}
                    onChange={(e) =>
                      onChangeSelect("customer")(customerStatus, e)
                    }
                  >
                    {renderMenuItem(customerStatus, "SK", "name")}
                  </SelectLabel>
                  <SelectLabel
                    label={<LabelRequired title={"Commodity"} />}
                    value={stateForm.commodity?.SK ?? ""}
                    isIgnoreExtractRuleLabel={false}
                    isIgnoreExtractRuleSelect={true}
                    onChange={(e) =>
                      onChangeSelect("commodity")(commodityStatus, e)
                    }
                  >
                    {renderMenuItem(commodityStatus, "SK", "name")}
                  </SelectLabel>
                  <Box sx={{ display: "flex" }}>
                    <TextField
                      label={"EAN/JANCode"}
                      value={janCode}
                      onChange={(e) => setJanCode(e.target.value)}
                      sx={{ flex: 1 }}
                    />
                    <Box sx={styles.searchBtn}>
                      <Button
                        sx={{ minWidth: 60 }}
                        onClick={handleSearchModelNameByJANCode}
                      >
                        検索
                      </Button>
                    </Box>
                  </Box>
                  <TextField
                    label={<LabelRequired title={"Model Name"} />}
                    inputProps={{ maxLength: 30 }}
                    value={stateForm.model_name ?? ""}
                    onChange={(e) => {
                      onChangeText("model_name")(e.target.value);
                    }}
                    onBlur={() => onValidateText("model_name")}
                    error={formError.model_name.length > 0}
                    helperText={formError.model_name}
                  />
                  <Autocomplete
                    disablePortal
                    id="quantity"
                    options={quantityList}
                    inputValue={inputQuantityValue}
                    onChange={(e, newValue) => {
                      setInputQuantityValue(newValue ?? "");
                      setStateForm({
                        ...stateForm,
                        quantity: newValue ?? "",
                      });
                    }}
                    onInputChange={(e, newValue) => {
                      setInputQuantityValue(newValue);
                      setStateForm({
                        ...stateForm,
                        quantity: newValue,
                      });
                    }}
                    onBlur={() => onValidateText("quantity")}
                    renderInput={(params) => (
                      <div>
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            maxLength: 3,
                          }}
                          label={<LabelRequired title={"Quantity"} />}
                        />
                        {formError.quantity.length > 0 && (
                          <Typography variant="caption" color="error">
                            {formError.quantity}
                          </Typography>
                        )}
                      </div>
                    )}
                    freeSolo
                  />
                  <Box>
                    <FormLabel>
                      <LabelRequired title={"Serial No."} />
                    </FormLabel>
                    {renderListSerialNo(stateForm.serial_no)}
                  </Box>
                  <Button
                    onClick={handleAddNewSerialNo}
                    disabled={stateForm?.serial_no.length >= maxSerialNo}
                  >
                    Add Serial No.
                  </Button>
                  <SelectLabel
                    label={<LabelRequired title={"Damage Location"} />}
                    value={stateForm.damage_location?.SK ?? ""}
                    isIgnoreExtractRuleLabel={false}
                    isIgnoreExtractRuleSelect={true}
                    onChange={(e) =>
                      onChangeSelect("damage_location")(damageLocationStatus, e)
                    }
                  >
                    {renderMenuItem(damageLocationStatus, "SK", "name")}
                  </SelectLabel>
                  <Autocomplete
                    disablePortal
                    id="cause"
                    options={causeStatus}
                    getOptionLabel={(option) => option.name ?? ""}
                    inputValue={inputCauseValue}
                    onChange={(e, newValue) => {
                      setInputCauseValue(newValue.name ?? "");
                      setStateForm({
                        ...stateForm,
                        cause: newValue.name ?? "",
                      });
                    }}
                    onInputChange={(e, newValue) => {
                      setInputCauseValue(newValue);
                      setStateForm({
                        ...stateForm,
                        cause: newValue,
                      });
                    }}
                    onBlur={() => onValidateText("cause")}
                    renderInput={(params) => (
                      <div>
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            maxLength: 80,
                          }}
                          label={
                            <LabelRequired title={"Cause  (Site Opinion)"} />
                          }
                        />
                        {formError.cause.length > 0 && (
                          <Typography variant="caption" color="error">
                            {formError.cause}
                          </Typography>
                        )}
                      </div>
                    )}
                    freeSolo
                  />
                  <SelectLabel
                    label={<LabelRequired title={"QA Authorizer"} />}
                    value={stateForm.qa_authorizer?.SK ?? ""}
                    isIgnoreExtractRuleLabel={false}
                    isIgnoreExtractRuleSelect={true}
                    onChange={(e) =>
                      onChangeSelect("qa_authorizer")(QAAuthorizerStatus, e)
                    }
                  >
                    {renderMenuItem(QAAuthorizerStatus, "SK", "name")}
                  </SelectLabel>
                  <SelectLabel
                    label={<LabelRequired title={"Occurred Company"} />}
                    isIgnoreExtractRuleLabel={false}
                    isIgnoreExtractRuleSelect={true}
                    value={stateForm.occurred_company?.SK ?? ""}
                    onChange={(e) =>
                      onChangeSelect("occurred_company")(
                        occurredCompanyStatus,
                        e,
                      )
                    }
                  >
                    {renderMenuItem(occurredCompanyStatus, "SK", "name")}
                  </SelectLabel>

                  {step > REPORTER_STEP && (
                    // 第一承認以上で表示
                    <Stack sx={styles.formStack}>
                      <Autocomplete
                        disablePortal
                        id="invoice_no"
                        options={invoiceNoStatus}
                        inputValue={inputInvoiceNoValue}
                        onChange={(e, newValue) => {
                          setInputInvoiceNoValue(newValue ?? "");
                          setStateForm({
                            ...stateForm,
                            invoice_no: newValue ?? "",
                          });
                        }}
                        onBlur={() => onValidateText("invoice_no")}
                        onInputChange={(e, newValue) => {
                          setInputInvoiceNoValue(newValue);
                          setStateForm({
                            ...stateForm,
                            invoice_no: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <div>
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                maxLength: 24,
                              }}
                              label="Invoice No."
                            />
                            {formError.invoice_no.length > 0 && (
                              <Typography variant="caption" color="error">
                                {formError.invoice_no}
                              </Typography>
                            )}
                          </div>
                        )}
                        freeSolo
                      />
                      <SelectLabel
                        label={
                          <LabelRequired title={"Employee's Affiliation"} />
                        }
                        value={stateForm.affiliation_of_employee?.SK ?? ""}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeSelect("affiliation_of_employee")(
                            affiliationOfEmployeeStatus,
                            e,
                          )
                        }
                      >
                        {renderMenuItem(
                          affiliationOfEmployeeStatus,
                          "SK",
                          "name",
                        )}
                      </SelectLabel>
                      <Box>
                        <FormLabel sx={{ pb: "3px" }}>
                          <span className={"IgnoreExtractRuleTarget"}>
                            {"Employee's Experience"}
                          </span>
                        </FormLabel>
                      </Box>
                      <SelectLabel
                        label={<LabelRequired title={"Years"} />}
                        value={stateForm.experience_of_employee_years}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeText("experience_of_employee_years")(
                            e.target.value,
                          )
                        }
                      >
                        {ExperienceYearsOfEmployee.map((item, index) => (
                          <MenuItem value={item} key={index}>
                            {item ? (
                              <span className="IgnoreExtractRuleTarget">
                                {item}
                              </span>
                            ) : (
                              <em>未選択</em>
                            )}
                          </MenuItem>
                        ))}
                      </SelectLabel>
                      <SelectLabel
                        label={<LabelRequired title={"Months"} />}
                        value={stateForm.experience_of_employee_months}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeText("experience_of_employee_months")(
                            e.target.value,
                          )
                        }
                      >
                        {ExperienceMonthsOfEmployee.map((item, index) => (
                          <MenuItem value={item} key={index}>
                            {item ? (
                              <span className="IgnoreExtractRuleTarget">
                                {item}
                              </span>
                            ) : (
                              <em>未選択</em>
                            )}
                          </MenuItem>
                        ))}
                      </SelectLabel>

                      <TextField
                        label={
                          <LabelRequired title={"System Transaction No."} />
                        }
                        inputProps={{ maxLength: 24 }}
                        value={stateForm.system_transaction_no ?? ""}
                        onChange={(e) => {
                          onChangeText("system_transaction_no")(e.target.value);
                        }}
                        onBlur={() => onValidateText("system_transaction_no")}
                        error={formError.system_transaction_no.length > 0}
                        helperText={formError.system_transaction_no}
                      />
                    </Stack>
                  )}
                  {step > APPROVER_STEP_1 && (
                    // 第二次承認以上で表示
                    <Stack sx={styles.formStack}>
                      <SelectLabel
                        label={
                          <LabelRequired title={"Waste /Claim Category"} />
                        }
                        value={stateForm.waste_claim_category?.SK ?? ""}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeSelect("waste_claim_category")(
                            wasteClaimStatus,
                            e,
                          )
                        }
                      >
                        {renderMenuItem(wasteClaimStatus, "SK", "name")}
                      </SelectLabel>
                      <SelectLabel
                        label={<LabelRequired title={"Accident Rank"} />}
                        value={stateForm.accident_rank?.SK ?? ""}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeSelect("accident_rank")(accidentRankStatus, e)
                        }
                      >
                        {renderMenuItem(accidentRankStatus, "SK", "name")}
                      </SelectLabel>
                      <TextField
                        label="CPAR No."
                        inputProps={{ maxLength: 12 }}
                        value={stateForm.cpar_no ?? ""}
                        onChange={(e) => {
                          onChangeText("cpar_no")(e.target.value);
                        }}
                        onBlur={() => onValidateText("cpar_no")}
                        error={formError.cpar_no.length > 0}
                        helperText={formError.cpar_no}
                      />
                      <SelectLabel
                        label={<LabelRequired title={"Nonconformity Detail"} />}
                        value={stateForm.nonconformity_detail?.SK ?? ""}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) => {
                          nonconformityDetailRef.current = true;
                          onChangeSelect("nonconformity_detail")(
                            nonconformityDetailStatus,
                            e,
                          );
                        }}
                      >
                        {renderMenuItem(
                          nonconformityDetailStatus,
                          "SK",
                          "name",
                        )}
                      </SelectLabel>
                      <SelectLabel
                        label={
                          <LabelRequired title={"Nonconformity Overview"} />
                        }
                        value={stateForm.nonconformity_overview?.SK ?? ""}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeSelect("nonconformity_overview")(
                            nonconformityOverviewStatus,
                            e,
                          )
                        }
                      >
                        {renderMenuItem(
                          nonconformityOverviewStatus,
                          "SK",
                          "name",
                        )}
                      </SelectLabel>
                      <SelectLabel
                        label={<LabelRequired title={"Occurance Process"} />}
                        value={stateForm.occurance_process?.SK ?? ""}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeSelect("occurance_process")(
                            occuranceProcessStatus,
                            e,
                          )
                        }
                      >
                        {renderMenuItem(occuranceProcessStatus, "SK", "name")}
                      </SelectLabel>
                      <SelectLabel
                        label={
                          <LabelRequired title={"Process of Root Cause"} />
                        }
                        value={stateForm.process_of_root_cause?.SK ?? ""}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeSelect("process_of_root_cause")(
                            processOfRootCauseStatus,
                            e,
                          )
                        }
                      >
                        {renderMenuItem(processOfRootCauseStatus, "SK", "name")}
                      </SelectLabel>
                      <SelectLabel
                        label={<LabelRequired title={"Responsibility"} />}
                        value={stateForm.responsibility?.SK ?? ""}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeSelect("responsibility")(
                            responsibilityStatus,
                            e,
                          )
                        }
                      >
                        {renderMenuItem(responsibilityStatus, "SK", "name")}
                      </SelectLabel>
                      <SelectLabel
                        label={<LabelRequired title={"Department of PIC"} />}
                        value={stateForm.department_of_pic?.SK ?? ""}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeSelect("department_of_pic")(
                            departmentOfPICStatus,
                            e,
                          )
                        }
                      >
                        {renderMenuItem(departmentOfPICStatus, "SK", "name")}
                      </SelectLabel>
                      <SelectLabel
                        label={<LabelRequired title={"Process in Logistics"} />}
                        value={stateForm.process_in_logistics?.SK ?? ""}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeSelect("process_in_logistics")(
                            processInLogisticsStatus,
                            e,
                          )
                        }
                      >
                        {renderMenuItem(processInLogisticsStatus, "SK", "name")}
                      </SelectLabel>
                      <SelectLabel
                        label={
                          <LabelRequired title={"Responsibility in Process"} />
                        }
                        value={stateForm.responsibility_in_process?.SK ?? ""}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeSelect("responsibility_in_process")(
                            responsibilityInProcessStatus,
                            e,
                          )
                        }
                      >
                        {renderMenuItem(
                          responsibilityInProcessStatus,
                          "SK",
                          "name",
                        )}
                      </SelectLabel>
                      <SelectLabel
                        label={
                          <LabelRequired
                            title={"Classification of Nonconformity"}
                          />
                        }
                        value={
                          stateForm.classification_of_nonconformity?.SK ?? ""
                        }
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeSelect("classification_of_nonconformity")(
                            classificationOfNonconformityStatus,
                            e,
                          )
                        }
                      >
                        {renderMenuItem(
                          classificationOfNonconformityStatus,
                          "SK",
                          "name",
                        )}
                      </SelectLabel>
                      <SelectLabel
                        label={
                          <LabelRequired title={"Classification of Cause"} />
                        }
                        value={stateForm.classification_of_cause?.SK ?? ""}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeSelect("classification_of_cause")(
                            classificationOfCauseStatus,
                            e,
                          )
                        }
                      >
                        {renderMenuItem(
                          classificationOfCauseStatus,
                          "SK",
                          "name",
                        )}
                      </SelectLabel>
                      <Autocomplete
                        disablePortal
                        id="cause_overview"
                        options={causeOverviewStatus}
                        getOptionLabel={(option) => option.name ?? ""}
                        inputValue={inputCauseOverviewValue}
                        onChange={(e, newValue) => {
                          setInputCauseOverviewValue(newValue.name ?? "");
                          setStateForm({
                            ...stateForm,
                            cause_overview: newValue.name ?? "",
                          });
                        }}
                        onBlur={() => onValidateText("cause_overview")}
                        onInputChange={(e, newValue) => {
                          setInputCauseOverviewValue(newValue);
                          setStateForm({
                            ...stateForm,
                            cause_overview: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <div>
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                maxLength: 300,
                              }}
                              label={<LabelRequired title={"Cause Overview"} />}
                            />
                            {formError.cause_overview.length > 0 && (
                              <Typography variant="caption" color="error">
                                {formError.cause_overview}
                              </Typography>
                            )}
                          </div>
                        )}
                        freeSolo
                      />
                      <Autocomplete
                        disablePortal
                        id="root_cause"
                        options={rootCauseStatus}
                        onBlur={() => onValidateText("root_cause")}
                        getOptionLabel={(option) => option.name ?? ""}
                        inputValue={inputRootCauseValue}
                        onChange={(e, newValue) => {
                          setInputRootCauseValue(newValue.name ?? "");
                          setStateForm({
                            ...stateForm,
                            root_cause: newValue.name ?? "",
                          });
                        }}
                        onInputChange={(e, newValue) => {
                          setInputRootCauseValue(newValue);
                          setStateForm({
                            ...stateForm,
                            root_cause: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <div>
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                maxLength: 300,
                              }}
                              label={<LabelRequired title={"Root Cause"} />}
                            />
                            {formError.root_cause.length > 0 && (
                              <Typography variant="caption" color="error">
                                {formError.root_cause}
                              </Typography>
                            )}
                          </div>
                        )}
                        freeSolo
                      />
                      <SelectLabel
                        label={<LabelRequired title={"Logistics Equipments"} />}
                        value={stateForm.logistics_equipments?.SK ?? ""}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeSelect("logistics_equipments")(
                            logisticsEquipmentsStatus,
                            e,
                          )
                        }
                      >
                        {renderMenuItem(
                          logisticsEquipmentsStatus,
                          "SK",
                          "name",
                        )}
                      </SelectLabel>
                      <Autocomplete
                        disablePortal
                        id="corrective_action_improvement"
                        onBlur={() =>
                          onValidateText("corrective_action_improvement")
                        }
                        options={correctiveActionImprovementStatus}
                        getOptionLabel={(option) => option.name ?? ""}
                        inputValue={inputCorrectiveActionImprovementValue}
                        onChange={(e, newValue) => {
                          setInputCorrectiveActionImprovementValue(
                            newValue.name ?? "",
                          );
                          setStateForm({
                            ...stateForm,
                            corrective_action_improvement: newValue.name ?? "",
                          });
                        }}
                        onInputChange={(e, newValue) => {
                          setInputCorrectiveActionImprovementValue(newValue);
                          setStateForm({
                            ...stateForm,
                            corrective_action_improvement: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <div>
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                maxLength: 80,
                              }}
                              label={
                                <LabelRequired
                                  title={"Corrective Action /Improvement"}
                                />
                              }
                            />
                            {formError.corrective_action_improvement.length >
                              0 && (
                              <Typography variant="caption" color="error">
                                {formError.corrective_action_improvement}
                              </Typography>
                            )}
                          </div>
                        )}
                        freeSolo
                      />
                      <DatePickerCustom
                        label={
                          <LabelRequired
                            title={"Completion improvement Date"}
                          />
                        }
                        value={stateForm?.completion_improvement_date}
                        onChange={(e) => {
                          onChangeDate("completion_improvement_date")(e);
                        }}
                        onBlur={() =>
                          onValidateText("completion_improvement_date")
                        }
                        error={formError.completion_improvement_date.length > 0}
                        helperText={formError.completion_improvement_date}
                      />
                    </Stack>
                  )}
                  {step > APPROVER_STEP_2 && (
                    <Stack sx={styles.formStack}>
                      {/* 第三承認者の内容 */}
                      <TextField
                        label={<LabelRequired title={"Scrap Unit"} />}
                        inputProps={{ maxLength: 3 }}
                        value={stateForm.scrap_unit ?? ""}
                        onChange={(e) => {
                          onChangeText("scrap_unit")(e.target.value);
                        }}
                        onBlur={() => onValidateText("scrap_unit")}
                        error={formError.scrap_unit.length > 0}
                        helperText={formError.scrap_unit}
                      />
                      <TextField
                        label={<LabelRequired title={"Repair Unit"} />}
                        inputProps={{ maxLength: 3 }}
                        value={stateForm.repair_unit ?? ""}
                        onChange={(e) => {
                          onChangeText("repair_unit")(e.target.value);
                        }}
                        onBlur={() => onValidateText("repair_unit")}
                        error={formError.repair_unit.length > 0}
                        helperText={formError.repair_unit}
                      />
                      <TextField
                        label={<LabelRequired title={"No Problem Unit"} />}
                        inputProps={{ maxLength: 3 }}
                        value={stateForm.no_problem_unit ?? ""}
                        onChange={(e) => {
                          onChangeText("no_problem_unit")(e.target.value);
                        }}
                        onBlur={() => onValidateText("no_problem_unit")}
                        error={formError.no_problem_unit.length > 0}
                        helperText={formError.no_problem_unit}
                      />
                    </Stack>
                  )}
                  {step > APPROVER_STEP_3 && (
                    <Stack sx={styles.formStack}>
                      {/* 第四承認者の内容 */}
                      <SelectLabel
                        label={<LabelRequired title={"Final Payer"} />}
                        value={stateForm.final_payer?.SK ?? ""}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeSelect("final_payer")(finalPayerStatus, e)
                        }
                      >
                        {renderMenuItem(finalPayerStatus, "SK", "name")}
                      </SelectLabel>
                      <SelectLabel
                        label={
                          <LabelRequired title={"Expected Payment Month"} />
                        }
                        value={stateForm.expected_payment_month}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeText("expected_payment_month")(e.target.value)
                        }
                      >
                        {twelveMonths.map((item, index) => (
                          <MenuItem value={item} key={index}>
                            {item ? (
                              <span className="IgnoreExtractRuleTarget">
                                {item}
                              </span>
                            ) : (
                              <em>未選択</em>
                            )}
                          </MenuItem>
                        ))}
                      </SelectLabel>
                      <SelectLabel
                        label={<LabelRequired title={"Est/Acc"} />}
                        value={stateForm.est_acc?.SK ?? ""}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeSelect("est_acc")(EstAccStatus, e)
                        }
                      >
                        {renderMenuItem(EstAccStatus, "SK", "name")}
                      </SelectLabel>
                      <TextField
                        label={<LabelRequired title={"Amount Cost (Est)"} />}
                        inputProps={{ maxLength: 10 }}
                        value={stateForm.amount_cost_est ?? ""}
                        onChange={(e) => {
                          onChangeText("amount_cost_est")(e.target.value);
                        }}
                        onBlur={() => onValidateText("amount_cost_est")}
                        error={formError.amount_cost_est.length > 0}
                        helperText={formError.amount_cost_est}
                      />
                    </Stack>
                  )}
                  {step > APPROVER_STEP_4 && (
                    <Stack sx={styles.formStack}>
                      <TextField
                        label={<LabelRequired title={"Amount Cost (Act)"} />}
                        inputProps={{ maxLength: 10 }}
                        value={stateForm.amount_cost_act ?? ""}
                        onChange={(e) => {
                          onChangeText("amount_cost_act")(e.target.value);
                        }}
                        onBlur={() => onValidateText("amount_cost_act")}
                        error={formError.amount_cost_act.length > 0}
                        helperText={formError.amount_cost_act}
                      />
                      <SelectLabel
                        label={<LabelRequired title={"Actual Payment Month"} />}
                        value={stateForm.actual_payment_month}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={true}
                        onChange={(e) =>
                          onChangeText("actual_payment_month")(e.target.value)
                        }
                      >
                        {twelveMonths.map((item, index) => (
                          <MenuItem value={item} key={index}>
                            {item ? (
                              <span className="IgnoreExtractRuleTarget">
                                {item}
                              </span>
                            ) : (
                              <em>未選択</em>
                            )}
                          </MenuItem>
                        ))}
                      </SelectLabel>
                      <TextField
                        label={
                          <LabelRequired title={"Insurance,Sub-con Amount"} />
                        }
                        inputProps={{ maxLength: 10 }}
                        value={stateForm.insurance_sub_con_amount ?? ""}
                        onChange={(e) => {
                          onChangeText("insurance_sub_con_amount")(
                            e.target.value,
                          );
                        }}
                        onBlur={() =>
                          onValidateText("insurance_sub_con_amount")
                        }
                        error={formError.insurance_sub_con_amount.length > 0}
                        helperText={formError.insurance_sub_con_amount}
                      />
                      <TextField
                        label={<LabelRequired title={"Received Invoice No."} />}
                        inputProps={{ maxLength: 24 }}
                        value={stateForm.received_invoice_no ?? ""}
                        onChange={(e) => {
                          onChangeText("received_invoice_no")(e.target.value);
                        }}
                        onBlur={() => onValidateText("received_invoice_no")}
                        error={formError.received_invoice_no.length > 0}
                        helperText={formError.received_invoice_no}
                      />
                      <LabelValueText
                        label="Final TLGT Amount"
                        value={stateForm.final_tlgt_amount}
                      />
                    </Stack>
                  )}

                  {step > APPROVER_STEP_3 && (
                    //第四次承認以上
                    <DatePickerCustom
                      label={<LabelRequired title={"Closing Date"} />}
                      value={stateForm?.closing_date}
                      onChange={(e) => {
                        onChangeDate("closing_date")(e);
                      }}
                      onBlur={() => onValidateText("closing_date")}
                      error={formError.closing_date.length > 0}
                      helperText={formError.closing_date}
                    />
                  )}

                  <TextField
                    label="Remark"
                    value={stateForm?.remark}
                    name="Remark"
                    multiline
                    rows={3}
                    inputProps={{
                      maxLength: 300,
                    }}
                    onChange={(e) => onChangeText("remark")(e.target.value)}
                    onBlur={() => onValidateText("remark")}
                    //placeholder="メモや備考を記入できます（最大200文字）"
                    error={formError.remark.length > 0}
                    helperText={formError.remark}
                  />
                </Stack>
              </FormGroup>
            </CardContent>
          </Stack>
        </Card>
        <Card>
          <CardHeader title="Defective part (photos)" sx={styles.header} />
          <CardContent>
            <FormGroup>
              <Stack sx={styles.formStack}>
                {renderImageItem(stateForm.image_array)}
                <Button
                  onClick={handleAddImageItem}
                  disabled={stateForm?.image_array.length >= maxImage}
                >
                  add photos
                </Button>
              </Stack>
            </FormGroup>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
};

export default forwardRef(IncidentDamageReport);

const styles = {
  formStack: { pb: 0, width: "100%" },
  header: { bgcolor: Colors.MAIN_GREEN_LIGHT },
  searchBtn: {
    display: "flex",
    alignSelf: "flex-end",
    ml: 1,
    mb: "10px",
  },
};
