import React from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { Colors } from "@template/style";
import formatDateToString from "@utils/DateFormat";

const STATUS = {
  APPROVED: {
    title: "承認",
    color: "#005AFF",
  },
  PROCESSING: {
    title: "承認待ち",
    color: "#4CC4FF",
  },
  NOTAPPROVE: {
    title: "未承認",
    color: "#BDBDBD",
  },
  CANCLE: {
    title: "却下",
    color: "#FF4B00",
  },
};

interface IProps {
  index: number;
  isFinish: boolean;
  item: Type.FlowDataItemType;
  isCancle: boolean;
}

const STATUSKEY = {
  APPROVED: "APPROVED",
  PROCESSING: "PROCESSING",
  NOTAPPROVE: "NOTAPPROVE",
  CANCLE: "CANCLE",
};

export const renderUserFlowTlog = (
  flowApproveUser: Type.FlowDataType,
  stepCancle: number,
  isWorkCancle: boolean,
) => {
  return (
    <>
      {flowApproveUser.map((item, index) => (
        <StatusUserFlow
          isFinish={index == flowApproveUser.length - 1}
          item={item}
          isCancle={isWorkCancle && stepCancle == index}
          key={`flow_approve_item_${index}`}
          index={index}
        />
      ))}
    </>
  );
};

export default function StatusUserFlow({
  isFinish = false,
  item,
  isCancle,
  index,
}: IProps) {
  const getStatusStep = (item: Type.FlowDataItemType) => {
    if (isCancle) return STATUSKEY.CANCLE as Type.UserFlowStatusApprove;
    if (item?.data_max_user?.total_user_approved == 0) {
      return STATUSKEY.NOTAPPROVE as Type.UserFlowStatusApprove;
    }
    if (
      item?.data_max_user?.total_user_approved < item?.data_max_user?.max_user
    ) {
      return STATUSKEY.PROCESSING as Type.UserFlowStatusApprove;
    }
    if (
      item?.data_max_user?.total_user_approved >= item?.data_max_user?.max_user
      //自動承認のとき、total_user_approved>data_max_userとなる場合がある
    ) {
      return STATUSKEY.APPROVED as Type.UserFlowStatusApprove;
    }
    return STATUSKEY.CANCLE as Type.UserFlowStatusApprove;
  };
  const status: Type.UserFlowStatusApprove = getStatusStep(item);

  const ItemUserApprove = ({ nameUser = "", date = "" }: any) => {
    return (
      <Box display={"flex"} flexDirection={"row"}>
        <Typography
          variant="body1"
          sx={{ color: Colors.DISABLE_BTN_TEXT, wordBreak: "break-all" }}
          className="IgnoreExtractRuleTarget"
        >
          {nameUser}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Typography
          variant="body1"
          sx={{
            minHeight: 24,
            color: Colors.DISABLE_BTN_TEXT,
            alignSelf: "flex-end",
          }}
          className="IgnoreExtractRuleTarget"
        >
          {date ? formatDateToString(date, "YMD_sl", true) : ""}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      {index !== 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 1 }}>
          <KeyboardArrowDown sx={{ color: Colors.MAIN_GREEN }} />
        </Box>
      )}
      <Card
        variant="outlined"
        sx={{
          display: { xs: "inline", md: "flex" },
          borderWidth: 2,
          borderColor: STATUS[status].color,
        }}
      >
        <CardMedia
          sx={{ backgroundColor: STATUS[status].color, p: 1, minWidth: 100 }}
        >
          <Box
            sx={{ display: "flex", flexDirection: { xs: "row", md: "column" } }}
          >
            <Typography sx={{ color: "white" }}>
              {STATUS[status].title}
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }} />
            <Typography sx={{ color: "white" }}>
              (残
              {item?.data_max_user?.max_user -
                item?.data_max_user?.total_user_approved <
                0
                ? 0
                : item?.data_max_user?.max_user -
                item?.data_max_user?.total_user_approved}
              名)
            </Typography>
          </Box>
        </CardMedia>
        <CardContent
          sx={{ width: "100%", alignSelf: "center", ":last-child": { pb: 2 } }}
        >
          {item?.data_step?.map((item: Type.DataStepUserType, i) => (
            <ItemUserApprove
              nameUser={item?.full_name}
              date={item?.is_approved ? item?.updated_at : ""}
              key={i}
            />
          ))}
        </CardContent>
      </Card>
    </>
  );
}
