import { useEffect, useState } from "react";
import { Box, Card } from "@mui/material";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { getListHistoryWorkApi } from "@api/work";
import {
  HeadCell,
  SortableTable,
} from "components/organisms/SortableTable/SortableTable";
import formatDateToString from "@utils/DateFormat";

interface IProps {
  work_id: string;
}

type IFlowItem = {
  user_in_step: string;
  user_name: string;
  version: string;
  user_id: string;
  user_action: string;
  created_at: string;
  SK: string;
  PK: string;
  no: number;
  version_item: string;
  user_action_name: string;
};

const titleStatus: {
  [fieldName: string]: {
    code: string;
    title: string;
  };
} = {
  REQUEST: {
    code: "REQUEST",
    title: "申請",
  },
  APPROVE: {
    code: "APPROVE",
    title: "承認",
  },
  REJECT: {
    code: "REJECT",
    title: "差戻",
  },
  DESTROY: {
    code: "DESTROY",
    title: "却下",
  },
  FINISH: {
    code: "FINISH",
    title: "完了",
  },
  CANCEL_REQUEST: {
    code: "CANCEL_REQUEST",
    title: "申請キャンセル",
  },
  CANCEL_REMAIN: {
    code: "CANCEL_REMAIN",
    title: "承認キャンセル",
  },
  CANCEL_REJECT: {
    code: "CANCEL_REJECT",
    title: "差戻キャンセル",
  },
};

const ListApproveHistory = ({ work_id }: IProps) => {
  const [listHistory, setListHistory] = useState<Array<IFlowItem>>([]);

  const getData = async () => {
    try {
      LoadingOverlayController.show();
      const res = await getListHistoryWorkApi(work_id).then((res) => {
        if (!res?.data) return [];

        const response: Array<IFlowItem> = res.data;
        const data = response.map((item, index) => {
          const versionItem =
            item.user_action == titleStatus.CANCEL_REQUEST.code ||
            item.user_action == titleStatus.REQUEST.code
              ? `バージョン${item?.version}`
              : `ステップ${item?.user_in_step + 1}`;
          return {
            ...item,
            no: response.length - index,
            version_item: versionItem,
            user_action_name: titleStatus[item.user_action]?.title,
            created_at: formatDateToString(item.created_at, "YMDHHmm", true),
          };
        });
        return data;
      });

      setListHistory(res);
    } catch (e) {
      console.log("get list history error", e);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    if (work_id) getData();
  }, []);

  const headCells: HeadCell[] = [
    // {
    //   id: "no",
    //   label: "No.",
    //   numeric: true,
    //   sortable: true,
    //   width: 30,
    //   align: "left",
    // },
    {
      id: "version_item",
      label: "申請ﾊﾞｰｼﾞｮﾝ/承認ｽﾃｯﾌﾟ",
      sortable: true,
      width: 150,
    },
    {
      id: "user_action_name",
      label: "実行内容",
      sortable: true,
      width: 150,
    },
    {
      id: "user_name",
      label: "実行者名",
      sortable: true,
      width: 180,
      isIgnoreExtractRuleCell: true,
    },
    {
      id: "created_at",
      label: "実行日時",
      sortable: true,
      width: 180,
      isIgnoreExtractRuleCell: true,
    },
  ];

  return (
    <Card style={{ minHeight: 420 }}>
      <Box sx={{ m: 2, overflow: "auto" }}>
        <SortableTable
          rows={listHistory}
          headCells={headCells}
          idName={"SK"}
          order={"desc"}
          orderByIdName={"created_at"}
          isCheckRow={false}
        />
      </Box>
    </Card>
  );
};

export default ListApproveHistory;
