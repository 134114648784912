import { useCallback, useEffect } from "react";
import {
  Checkbox,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { TYPES } from "store/types";
import InfiniteScroll from "react-infinite-scroller";
import ScrollLoader from "components/atoms/ScrollLoader";
import formatDateToString, {
  IFormatKey as IDateFormatKey,
} from "@utils/DateFormat";
import { StandardCSSProperties } from "@mui/system";
import { useScrollable } from "shared/hook/useScrollable";

export interface HeadCell {
  id: string;
  label: string;
  width?: string | number;
  format?: CellFormat;
  disablePadding?: boolean;
  align?: "inherit" | "left" | "center" | "right" | "justify" | undefined;
  orderBy?: string; // [id]以外でソートする場合、ソートキーを指定
  isIgnoreExtractRuleCell?: boolean;
  sx?: SxProps<Theme>;
}

export interface TableButton {
  cell_id: string;
  button: React.ReactChild;
  id: string;
}

export interface TableCustomProps {
  rows: any[]; // テーブルデータ
  headCells: HeadCell[]; // テーブルヘッダー
  buttons?: TableButton[]; // ボタンデータ
  idName: string; // 一意になるデータのid
  isCheckRow?: boolean; // true:チェックボックス表示/false:チェックボックス非表示
  maxHeight?: string | number;
  headCellsBgcolor?: StandardCSSProperties["backgroundColor"];
  noWrap?: boolean;
  showTooltip?: boolean;
  message?: string;
  initialLoad?: boolean;
  loadMore?: (page: number) => void;
  hasMore?: boolean;
  isLoading?: boolean;
}

type CellFormat = "numeric" | IDateFormatKey;

interface TableHeadProps {
  headCells: HeadCell[];
  rowCount: number;
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isCheckRow: boolean;
  bgColor?: StandardCSSProperties["backgroundColor"];
}

interface MessageRowProps {
  message: string;
  colspan: number;
}

/**
 * テーブルヘッダー
 * @param param0
 * @returns TableHead
 */
const TableHeader = ({
  headCells,
  rowCount,
  numSelected,
  onSelectAllClick,
  isCheckRow,
  bgColor = "white",
}: TableHeadProps) => {
  return (
    <TableHead>
      <TableRow>
        {isCheckRow && (
          <TableCell
            align="center"
            padding="none"
            sx={{ width: 50, maxWidth: 50, bgcolor: bgColor }}
          >
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.align ?? "inherit"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sx={{ minWidth: headCell.width, bgcolor: bgColor }}
            >
              {headCell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

/**
 * メッセージ行
 * @param param0
 * @returns TableRow
 */
const MessageRow = ({ message, colspan }: MessageRowProps) => {
  return (
    <TableRow>
      <TableCell colSpan={colspan} align="center" padding="normal">
        {message}
      </TableCell>
    </TableRow>
  );
};

/**
 * データ整形
 * @param value
 * @param format
 * @returns
 */
const formatValue = (value: string, format: CellFormat) => {
  let response = value;
  switch (format) {
    case "numeric":
      response = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      break;
    default:
      response = formatDateToString(value, format, true);
  }
  return response;
};

/**
 * テーブル
 * @param props
 * @returns TableContainer
 */
export const TableInfiniteScroll = (props: TableCustomProps) => {
  const {
    rows,
    headCells,
    buttons = [],
    idName,
    isCheckRow = false,
    maxHeight = 440,
    headCellsBgcolor,
    noWrap,
    showTooltip,
    message,
    initialLoad = true,
    loadMore = () => { },
    hasMore,
    isLoading = true,
  } = props;
  const { check_list = [] } = useSelector(
    (state: RootState) => state.tableCustom,
  );
  const dispatch = useDispatch();
  const [scrollable, ref, scrollComponent] = useScrollable(rows);

  useEffect(() => {
    // 初期データがテーブルの高さより低い場合、スクロールでデータを読み込めないため次のデータを取得する
    if (!scrollComponent || isLoading) return;
    if (!scrollable && hasMore) {
      loadMore(1);
    }
  }, [isLoading, scrollable, hasMore, scrollComponent]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isCheckRow) return;
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => {
        const _n = JSON.parse(JSON.stringify(n));
        return _n[idName];
      });

      dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: newSelecteds });
    }
    if (check_list.length > 0 && check_list.length <= rows.length) {
      dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: [] });
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    if (!isCheckRow) return;
    const selectedIndex = check_list.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(check_list, name);
    } else {
      newSelected = newSelected.concat(
        check_list.slice(0, selectedIndex),
        check_list.slice(selectedIndex + 1),
      );
    }
    dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: newSelected });
  };

  const isSelected = (name: string) => check_list.indexOf(name) !== -1;

  const disabledTooltip = useCallback(
    (is_button: boolean) => !showTooltip || is_button,
    [showTooltip],
  );

  return (
    <TableContainer sx={{ maxHeight: maxHeight, overflow: "auto" }} ref={ref}>
      <InfiniteScroll
        useWindow={false}
        initialLoad={initialLoad}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={<ScrollLoader key={0} />}
      >
        <Table stickyHeader size="medium" sx={{ maxHeight: maxHeight }}>
          <TableHeader
            headCells={headCells}
            numSelected={check_list.length}
            rowCount={rows.length}
            onSelectAllClick={handleSelectAllClick}
            isCheckRow={isCheckRow}
            bgColor={headCellsBgcolor}
          />
          <TableBody sx={{ flexDirection: "column" }}>
            {message ? (
              <MessageRow
                message={message}
                colspan={headCells.length + (isCheckRow ? 1 : 0)}
              />
            ) : (
              rows.map((row, index) => {
                const rowjson = JSON.parse(JSON.stringify(row));
                const id = rowjson[idName];
                const isItemSelected = isSelected(id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, id)}
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    {isCheckRow && (
                      <TableCell align="center" padding="none">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                    )}
                    {headCells.map((headCell) => {
                      const keyname = headCell.id;
                      const ignoreExtractRuleCell =
                        headCell.isIgnoreExtractRuleCell
                          ? "IgnoreExtractRuleTarget"
                          : "";
                      let value;
                      let is_button = false;
                      if (keyname in rowjson) {
                        value = rowjson[keyname];
                      } else {
                        const button = buttons.find(
                          (item) => item.cell_id === keyname && item.id === id,
                        );

                        if (button) {
                          value = button.button;
                          is_button = true;
                        }
                      }
                      if (headCell.format)
                        value = formatValue(value, headCell.format);

                      return (
                        <TableCell
                          key={headCell.id}
                          align={headCell.align ?? "inherit"}
                          padding={headCell.disablePadding ? "none" : "normal"}
                          width={headCell.width}
                          sx={[
                            noWrap
                              ? {
                                ...styles.ellipsis,
                                maxWidth: headCell.width,
                              }
                              : styles.wrap,
                          ]}
                        >
                          <Tooltip
                            title={value}
                            disableFocusListener={disabledTooltip(is_button)}
                            disableHoverListener={disabledTooltip(is_button)}
                            disableInteractive={disabledTooltip(is_button)}
                            disableTouchListener={disabledTooltip(is_button)}
                          >
                            <span className={ignoreExtractRuleCell}>
                              {value}
                            </span>
                          </Tooltip>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </InfiniteScroll>
    </TableContainer>
  );
};

const styles = {
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  wrap: {
    wordBreak: "break-all",
  },
} as const;
