import React, { useEffect, useMemo, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SelectLabel from "components/atoms/SelectLabel";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import LabelRequired from "components/atoms/LabelRequired";
import { useSize } from "shared/hook/useSize";
import { Colors } from "@template/style";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import {
  createFormUpload,
  getFileByUrl,
  getUserId,
  getUserInfo as getUserInfoStorage,
} from "@utils/index";
import { Template, TemplateExcel, User } from "services/models";
import ModalListTemplate from "components/molecules/ModalListTemplate";
import messages from "config/messages";
import TemplateSelectBox from "components/molecules/TemplateSelectBox";
import { createExcelTemplateApi, getTemplateApi } from "@api/template";
import { useDispatch, useSelector } from "react-redux";
import { TYPES } from "store/types";
import {
  listLocationProject,
  listStatusProject,
} from "selector/managerMasterSelector";
import _ from "lodash";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import ModalController from "@shared-components/modal/ModalController";
import {
  createProjectApi,
  getDetailProject,
  getDetailProjectTlog,
  getListReport,
} from "@api/project";
import { Validation } from "@validation";
import formatDateToString from "@utils/DateFormat";
import { useRouterPrompt } from "shared/hook/useRouterPrompt";
import { useLocation } from "react-router-dom";
import ProjectGroupInfoOption, {
  IGroupEditData,
} from "components/organisms/ProjectGroupInfoOption";
import { USER_ROLES } from "@shared-constants";
import TemplateCreateBox from "./TemplateCreateBox/TemplateCreateBox";

let selectedInit: User[] = [];
let user_info: User;

type IStateForm = {
  project_name: string;
  report_id: string;
  project_location_id: string;
  project_start_date: string;
  project_end_date: string;
  template: null | Template;
  memo: string;
  report_type_code: string;
  report_name: string;
  report_number: string;
  report_type_id: string;
  is_daily: boolean;
  is_corp_standard: boolean;
  open_state: boolean;
  group_info: IGroupEditData[];
  print_2d_barcode: boolean;
};

type IFormError = {
  project_name: string;
  template: string;
  project_start_date: string;
  project_end_date: string;
  memo: string;
};

const CreateProjectTlogScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [selectedUser, setSelectedUser] = useState<User[]>([]);
  const [flowUser, setFlowUser] = useState<User[]>([]);
  const listStatus = useSelector(listStatusProject);
  const listLocation = useSelector(listLocationProject);
  const [listReport, setListReport] = useState<any[]>([]);
  const [loading, setLoading] = useState<number>(0);
  const [openTemplate, setOpenTemplate] = useState<boolean>(false);
  const [stateForm, setStateForm] = useState<IStateForm>({
    project_name: "",
    report_id: "",
    project_location_id: listLocation[0]?.SK,
    project_start_date: "",
    project_end_date: "",
    template: null,
    memo: "",
    report_type_code: "",
    report_name: "",
    report_number: "",
    report_type_id: "",
    is_daily: false,
    is_corp_standard: false,
    open_state: true,
    group_info: [],
    print_2d_barcode: false,
  });
  const [originData, setOriginData] = useState<IStateForm>({
    project_name: "",
    report_id: listReport[0]?.SK,
    project_location_id: listLocation[0]?.SK,
    project_start_date: "",
    project_end_date: "",
    template: null,
    memo: "",
    report_type_code: listReport[0]?.report_type_code,
    report_name: listReport[0]?.report_name,
    report_number: listReport[0]?.report_number,
    report_type_id: listReport[0]?.report_type,
    is_daily: false,
    is_corp_standard: false,
    open_state: true,
    group_info: [],
    print_2d_barcode: false,
  });
  const location = useLocation<any>();
  const typeAction = location.state?.typeAction;
  const is_user_made = typeAction === "custom";
  const project_id = location.state?.project_id;
  const [formError, setFormError] = useState<IFormError>({
    project_name: "",
    template: "",
    project_start_date: "",
    project_end_date: "",
    memo: "",
  });
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [templateExcel, setTemplateExcel] = useState<TemplateExcel>();
  const [isErrorTemplate, setIsErrorTemplate] = useState<boolean>(false);
  const [templateProjectID, setTemplateProjectID] = useState<string>();
  const dispatch = useDispatch();
  const { isSmUp } = useSize();
  const history = useHistoryCustom();
  const groupInfoRef = React.useRef<any>();
  const TemplateCreateRef = React.useRef<{
    stateForm: any;
    formError: { [key: string]: string };
    validationAllCheck: (update?: boolean) => boolean;
    hasUpdateData: () => boolean;
  }>();
  const isFetchDataRef = React.useRef<boolean>(true);

  // let originData: IStateForm = {
  //   project_name: "",
  //   report_id: listReport[0]?.SK,
  //   project_location_id: listLocation[0]?.SK,
  //   project_start_date: "",
  //   project_end_date: "",
  //   template: null,
  //   memo: "",
  //   report_type_code: listReport[0]?.report_type_code,
  //   report_name: listReport[0]?.report_name,
  //   report_number: listReport[0]?.report_number,
  //   report_type_id: listReport[0]?.report_type,
  //   is_daily: false,
  //   is_corp_standard: false,
  //   open_state: true,
  //   group_info: [],
  // };

  const hasUpdateData = () => {
    return (
      (stateForm && originData && !_.isEqual(stateForm, originData)) ||
      (selectedInit &&
        selectedUser &&
        !_.isEqual(selectedUser, selectedInit)) ||
      (TemplateCreateRef.current
        ? TemplateCreateRef.current.hasUpdateData()
        : false)
    );
  };

  const onChangeBooleanOption = (field: string, value: boolean) => {
    setStateForm((prevState) => {
      return { ...prevState, [field]: value };
    });
  };

  useEffect(() => {
    setIsEdited(hasUpdateData());
  }, [stateForm, selectedUser, originData]);

  // データ取得 ------------------------------------------------
  const getUserInfo = async () => {
    const userInfo: any = getUserInfoStorage();
    return {
      avatar: userInfo?.avatar ?? null,
      SK: userInfo?.SK ?? null,
      full_name: userInfo?.full_name ?? null,
      user_role: userInfo?.user_role ?? null,
      location_id: userInfo?.location_id ?? undefined,
    };
  };

  const fetchGroupInfo = async () => {
    if (groupInfoRef && groupInfoRef.current) {
      await groupInfoRef.current.handlefetchList();
    }
  };

  const getDetailProject = async () => {
    try {
      const res = await getDetailProjectTlog(project_id);
      if (res?.data) {
        const dataRes = res?.data;
        const updateStateForm = {
          project_id: project_id,
          project_name: dataRes?.project_name,
          report_id: dataRes?.report_id,
          project_location_id:
            is_user_made && user_info?.user_role != USER_ROLES.OWNER.value
              ? user_info.location_id
              : dataRes?.project_location_id, // スーパーユーザー以外は他拠点の帳票作成が不可なので、複製先のログイン者の拠点に置換
          project_start_date: dataRes?.project_start_date,
          project_end_date: dataRes?.project_end_date,
          template: dataRes?.template_project_id ?? null,
          memo: dataRes?.memo,
          report_type_code: dataRes?.report_type_code,
          report_name: dataRes?.report_name,
          report_number: dataRes?.report_number,
          report_type_id: dataRes?.report_type_id,
          is_daily: dataRes?.is_daily,
          is_corp_standard: dataRes?.is_corp_standard,
          open_state: dataRes?.open_state,
          group_info: [],
          print_2d_barcode: dataRes?.print_2d_barcode,
        };
        const originStateForm = {
          ...updateStateForm,
          project_location_id: dataRes?.project_location_id, // スーパーユーザー以外が他拠点の帳票を複製した際は、作成ボタンが活性になる
        };
        setStateForm(updateStateForm);
        setOriginData(originStateForm);
        setTemplateExcel({
          PK: dataRes?.template_project_id?.PK,
          SK: dataRes?.template_project_id?.SK,
          excel_path: dataRes?.template_project_id?.excel_path,
          excel_name: dataRes?.template_project_id?.excel_name,
          start_col: dataRes?.template_project_id?.start_col,
          start_row: dataRes?.template_project_id?.start_row,
          end_col: dataRes?.template_project_id?.end_col,
          end_row: dataRes?.template_project_id?.end_row,
          sheet_name: dataRes?.template_project_id?.sheet_name,
          font: dataRes?.template_project_id?.font,
          paper_size: dataRes?.template_project_id?.paper_size,
          horizontal_pixel_size:
            dataRes?.template_project_id?.horizontal_pixel_size,
          vertical_pixel_size:
            dataRes?.template_project_id?.vertical_pixel_size,
          is_landscape: dataRes?.template_project_id?.is_landscape,
          data_info: dataRes?.template_project_id?.data_info,
          excel: {
            path: dataRes?.template_project_id?.excel_name,
            uri: dataRes?.template_project_id?.excel_path_s3,
            non_Local: true,
          },
          extra_template: dataRes?.template_project_id?.extra_template,
          is_multiple: dataRes?.template_project_id?.is_multiple,
        });
        await fetchGroupInfo();
      }
    } catch (error: any) {
      console.log("error getDetailProject", error);
      ModalController.show({
        message: error?.detail.message,
        visibleButton2: true,
      });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const fetchData = async () => {
    try {
      LoadingOverlayController.show();
      await getListReport().then((res) => {
        if (res?.data) {
          setListReport(res.data);
          if (!project_id && res.data.length > 0) {
            setStateForm({
              ...stateForm,
              report_id: res.data[0].SK,
              report_type_code: res.data[0].report_type_code,
              report_name: res.data[0].report_name,
              report_number: res.data[0].report_number,
              report_type_id: res.data[0]?.report_type,
            });
            setOriginData({
              ...originData,
              report_id: res.data[0].SK,
              report_type_code: res.data[0].report_type_code,
              report_name: res.data[0].report_name,
              report_number: res.data[0].report_number,
              report_type_id: res.data[0]?.report_type,
            });
          }
        }
      });
      getUserInfo().then((userInfo) => {
        selectedInit = [userInfo];
        user_info = { ...userInfo };
        setSelectedUser([{ ...userInfo }]);
      });
      if (project_id) {
        await getDetailProject();
      } else {
        setTimeout(async () => {
          await fetchGroupInfo();
        }, 500);
      }
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({ type: TYPES.GET_LIST_STATUS_PROJECT });
    dispatch({ type: TYPES.GET_LIST_LOCATION });
    fetchData();
  }, []);

  useEffect(() => {
    if (loading === 2) {
      setStateForm({
        ...stateForm,
        project_location_id: listLocation[0]?.SK,
      });
      setOriginData({
        ...originData,
        project_location_id: listLocation[0]?.SK,
      });
      setLoading(-1);
    } else {
      if (loading >= 0) setLoading((prev) => prev + 1);
    }
  }, [listStatus, listLocation]);

  const getTemplateData = async (template: Template) => {
    try {
      const res = await getTemplateApi(template.SK);
      if (res?.data) {
        setFlowUser(res.data?.list_approver ?? []);
        setStateForm({
          ...stateForm,
          template: res?.data,
        });
      }
    } catch (err) {
      console.log("get detail templae error", err);
    }
  };

  // ボタン制御 ------------------------------------------------
  const isActiveButton = () => {
    if (hasUpdateData()) {
      const error =
        Object.values(formError).filter((item: string) => item !== "").length >
        0;
      if (
        stateForm.project_name.trim() !== "" &&
        ((!is_user_made && stateForm.template) ||
          (is_user_made && !isErrorTemplate)) &&
        stateForm.project_location_id !== "" &&
        [...usersDefault, ...usersSelectedFilter].length >= 1 &&
        !isDisable &&
        !error
      ) {
        return true;
      }
    }
    return false;
  };

  // テンプレート入力 ------------------------------------------------
  const handleChooseTemplate = (item: Template) => {
    if (item.SK === stateForm?.template?.SK) {
      checkTemplate();
      setStateForm({
        ...stateForm,
        template: null,
      });
      setFlowUser([]);
    } else {
      checkTemplate(item);
      setStateForm({
        ...stateForm,
        template: item,
      });
      getTemplateData(item);
    }
  };

  const usersDefault = useMemo(() => {
    const result: User[] = [...selectedInit];
    flowUser.map((user2) => {
      if (
        selectedInit.findIndex((user1) => {
          return getUserId(user1) === getUserId(user2);
        }) === -1
      ) {
        result.push(user2);
      }
    });
    return result;
  }, [selectedInit, flowUser]);

  const usersSelectedFilter = useMemo(() => {
    const result: User[] = [];
    selectedUser.map((user2) => {
      if (
        usersDefault.findIndex((user1) => {
          return getUserId(user1) === getUserId(user2);
        }) === -1
      ) {
        result.push(user2);
      }
    });
    return result;
  }, [selectedUser, usersDefault]);

  // 拠点入力 ------------------------------------------------
  const onChangeLevel1 = (item: any, group_info: IGroupEditData[]) => {
    if (isFetchDataRef.current) {
      setOriginData((pre) => {
        return {
          ...pre,
          group_info: group_info ?? [],
        };
      });
      isFetchDataRef.current = false;
    }
  };

  // 入力制御 ------------------------------------------------
  const onChangeText = (field: keyof IStateForm) => (newText: string) => {
    let newStateForm = { ...stateForm, [field]: newText };

    setIsEdited(!_.isEqual(newStateForm, originData));
    setStateForm((prevState) => {
      return { ...prevState, [field]: newText };
    });
  };

  const handleChangeDate = (
    newValue: string | null,
    field: keyof IStateForm,
  ) => {
    if (!newValue) {
      newValue = "";
    } else {
      newValue = formatDateToString(newValue, "YMD");
    }
    let mess = "";
    let newStateForm = { ...stateForm, [field]: newValue };
    if (newValue) {
      mess = validator(field, newValue);
    }
    setFormError({ ...formError, [field]: mess });
    if (mess.length === 0) {
      checkProjrctDate(
        newStateForm.project_start_date,
        newStateForm.project_end_date,
      );
    }

    setIsEdited(!_.isEqual(newStateForm, originData));
    setStateForm(newStateForm);
  };

  const onValidateText = (field: keyof IStateForm) => {
    const mess = validator(field, String(stateForm[field]));
    setFormError({ ...formError, [field]: mess });
  };

  const validator = (field: keyof IStateForm, value: string) => {
    let mess: string = "";

    switch (field) {
      case "project_name":
        mess = Validation.validate({
          type: "text",
          value: value,
          name: "帳票名",
          required: true,
          max_length: 50,
        });
        break;
      case "project_start_date":
        mess = Validation.validateDate(value, "期間開始", false);
        break;
      case "project_end_date":
        mess = Validation.validateDate(value, "期間終了", false);
        break;
      case "memo":
        mess = Validation.validate({
          type: "text",
          name: "備考",
          value: value,
          required: false,
          max_length: 200,
        });
        break;
    }
    return mess;
  };

  const checkTemplate = (template: null | Template = null) => {
    let isError = false;
    let message = "";

    if (!template) {
      isError = true;
      message = messages.COMMON.ERROR.MSG_RQUIRED_SELECT("テンプレート");
    }
    setFormError((prev) => {
      return { ...prev, template: message };
    });
    return isError;
  };

  const checkProjrctDate = (start: string, end: string) => {
    let isError = false;
    let message_start = "";
    let message_end = "";

    if (!start && end) {
      isError = true;
      message_start = messages.PROJECT.MSG_ONLY_END_DATE;
    }

    if (start && end) {
      message_start = Validation.validateDateRange(
        new Date(start),
        new Date(end),
      );
      if (message_start) {
        message_end = message_start;
        isError = true;
      }
    }
    setFormError((prev) => {
      return {
        ...prev,
        project_start_date: message_start,
        project_end_date: message_end,
      };
    });
    return isError;
  };

  const validationAllCheck = () => {
    let response = true;

    const mess_project_name = validator("project_name", stateForm.project_name);
    const mess_project_start_date = validator(
      "project_start_date",
      stateForm.project_start_date,
    );
    const mess_project_end_date = validator(
      "project_end_date",
      stateForm.project_end_date,
    );
    const mess_memo = validator("memo", stateForm.memo);

    if (
      mess_project_name.length > 0 ||
      mess_project_start_date.length > 0 ||
      mess_project_end_date.length > 0 ||
      mess_memo.length > 0
    )
      response = false;

    setFormError({
      ...formError,
      project_name: mess_project_name,
      project_start_date: mess_project_start_date,
      project_end_date: mess_project_end_date,
      memo: mess_memo,
    });

    if (!is_user_made && checkTemplate(stateForm.template)) response = false;

    if (
      mess_project_start_date.length === 0 &&
      mess_project_end_date.length === 0
    ) {
      if (
        checkProjrctDate(
          stateForm.project_start_date,
          stateForm.project_end_date,
        )
      )
        response = false;
    }

    if (TemplateCreateRef.current) {
      if (!TemplateCreateRef.current.validationAllCheck()) {
        response = false;
      }
    }

    return response;
  };

  // 保存 ------------------------------------------------
  const handleCreateProject = async () => {
    try {
      if (!validationAllCheck()) {
        return false;
      }

      LoadingOverlayController.show();
      setIsDisable(true);
      // let users = [...usersDefault, ...usersSelectedFilter]
      //   .map((item) => getUserId(item))
      //   .join(",");

      let template_project_id = templateProjectID;
      if (is_user_made && !template_project_id) {
        template_project_id = await createExcelTemplate();
        if (!template_project_id) {
          return false;
        }
        setTemplateProjectID(template_project_id);
      }

      const data = {
        project_name: stateForm.project_name,
        report_id: stateForm.report_id,
        report_type_code: stateForm.report_type_code,
        report_number: stateForm.report_number,
        report_name: stateForm.report_name,
        template_project_id: is_user_made
          ? template_project_id
          : stateForm.template?.SK,
        project_start_date: stateForm.project_start_date,
        project_end_date: stateForm.project_end_date,
        memo: stateForm.memo,
        report_type_id: stateForm.report_type_id,
        is_daily: stateForm.is_daily,
        project_location_id: stateForm.project_location_id,
        open_location_list: JSON.stringify(
          stateForm.group_info.map((item) => item.SK),
        ),
        is_user_made: is_user_made,
        is_corp_standard: stateForm.is_corp_standard,
        open_state: stateForm.open_state,
        group_info: JSON.stringify(
          stateForm.group_info.map((item) => ({
            SK_group: item.SK,
            group_parentid: item.group_parentid,
          })),
        ),
        print_2d_barcode: stateForm.print_2d_barcode,
        screen_id: stateForm.template?.screen_id,
      };
      const formData = createFormUpload(null, data);
      const res = await createProjectApi(formData);
      if (res?.data) {
        // const res_p = await getDetailProject(res?.data);
        // dispatch({
        //   type: TYPES.SET_SELECTED_PROJECT_ID,
        //   selected_project_id: res_p?.data.SK,
        //   selected_project_name: res_p?.data.project_name,
        // });
        // dispatch({ type: TYPES.SET_PROJECT_INFO, payload: res_p?.data });
        ModalController.show({
          message: messages.PROJECT.MSG_CREATE_SUCCESS(stateForm.project_name),
          visibleButton2: true,
          handlePressButton2: () => {
            setIsEdited(false);
            setTimeout(() => {
              if (is_user_made) {
                history.pushWithRef("/barcode-layout", {
                  project_id: res.data,
                });
              } else {
                history.goBack();
              }
            }, 100);
          },
        });
      }
      return false;
    } catch (error: any) {
      console.log("error handleCreateProject", error);
      ModalController.show({
        message: error?.detail?.message,
        visibleButton2: true,
      });
      return false;
    } finally {
      LoadingOverlayController.hide();
      setIsDisable(false);
    }
  };

  // 保存確認ポップアップ
  const { setIsEdited } = useRouterPrompt({ onOK: handleCreateProject });

  const createExcelTemplate = async () => {
    try {
      if (!TemplateCreateRef.current) return false;
      const excelform = TemplateCreateRef.current.stateForm;
      const data = {
        excel_name: excelform.excel.path,
        start_col: excelform.start_col,
        start_row: excelform.start_row,
        end_col: excelform.end_col,
        end_row: excelform.end_row,
        sheet_name: excelform.sheet_name,
        font: excelform.font,
        paper_size: excelform.paper_size,
        vertical_pixel_size: excelform.vertical_pixel_size,
        horizontal_pixel_size: excelform.horizontal_pixel_size,
        is_landscape: excelform.is_landscape,
        data_info: excelform.data_info,
        template_name: stateForm.project_name,
        extra_template: excelform.extra_template,
        is_multiple: excelform.is_multiple || false,
      };
      let excel = excelform.excel;
      if (excel.non_Local) {
        // 複製、編集時はファイルを取得
        excel = await getFileByUrl(
          excel.uri,
          excel.path,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        );
      }
      const formData = createFormUpload(excel, data);
      for (let index = 0; index < excelform.extra_file.length; index++) {
        const item = excelform.extra_file[index];
        let f = item;
        if (item.non_Local) {
          f = await getFileByUrl(
            item.uri,
            item.fileName ? item.fileName : item.name,
            item.type,
          );
        }
        formData.append("extra_file", f);
      }
      const res = await createExcelTemplateApi(formData);
      if (res?.data) {
        return res.data;
      }
      return false;
    } catch (error: any) {
      console.log("error createExcelTemplate", error);
      ModalController.show({
        message: error?.detail ?? messages.COMMON.MSG_COMMON_ERROR_001,
        visibleButton2: true,
      });
      return false;
    }
  };

  return (
    <GenericTemplate title="帳票作成">
      <Stack>
        <Card>
          <CardHeader
            title="基本情報"
            sx={{ bgcolor: Colors.MAIN_GREEN_LIGHT }}
          />
          <CardContent>
            <FormGroup>
              <Stack sx={{ pb: 0 }}>
                <TextField
                  label={
                    <>
                      <LabelRequired title={"帳票名"} />
                    </>
                  }
                  name="project_name"
                  value={stateForm.project_name}
                  onChange={(e) => onChangeText("project_name")(e.target.value)}
                  onBlur={() => onValidateText("project_name")}
                  error={formError.project_name.length > 0}
                  helperText={formError.project_name}
                  inputProps={{
                    maxLength: 50,
                  }}
                  placeholder="帳票名"
                />

                <SelectLabel
                  label={
                    <>
                      <LabelRequired title={"帳票"} />
                    </>
                  }
                  value={stateForm.report_id}
                  onChange={(e) => {
                    const selected = listReport.find(
                      (d) => d.SK == e.target.value,
                    );
                    onChangeText("report_name")(selected.report_name);
                    onChangeText("report_type_code")(selected.report_type_code);
                    onChangeText("report_number")(selected.report_number);
                    onChangeText("report_type_id")(selected.report_type);
                    onChangeText("report_id")(e.target.value);
                  }}
                >
                  {listReport.map((item: any) => (
                    <MenuItem value={item.SK} key={item.SK}>
                      {item?.report_type_code +
                        item?.report_number +
                        ":" +
                        item?.report_name}
                    </MenuItem>
                  ))}
                </SelectLabel>
                <ProjectGroupInfoOption
                  ref={groupInfoRef}
                  stateForm={stateForm}
                  setStateForm={setStateForm}
                  allowEditTopLocation={
                    !is_user_made ||
                    (is_user_made &&
                      user_info?.user_role == USER_ROLES.OWNER.value)
                  }
                  allowEditGroup={is_user_made}
                  handleChangeLevel1={onChangeLevel1}
                />
                <FormControl>
                  <InputLabel>
                    <LabelRequired title="帳票部類" />
                  </InputLabel>
                  <RadioGroup
                    name="daily-radio-group"
                    value={stateForm.is_daily}
                    row
                    onChange={(_, value) => {
                      setStateForm({
                        ...stateForm,
                        is_daily: value === "true" ? true : false,
                      });
                    }}
                  >
                    <FormControlLabel
                      value={false}
                      label="一般帳票"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value={true}
                      label="日次帳票"
                      control={<Radio />}
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl>
                  <InputLabel>帳票様式</InputLabel>
                  <Box>
                    <CheckboxLabel
                      label={"全社標準"}
                      checked={stateForm.is_corp_standard}
                      disabled={!is_user_made}
                      onChange={(_, checked) => {
                        setStateForm((prev) => ({
                          ...prev,
                          is_corp_standard: checked,
                        }));
                      }}
                    />
                  </Box>
                </FormControl>
                <FormControl>
                  <InputLabel>
                    <LabelRequired title="公開状態" />
                  </InputLabel>
                  <RadioGroup
                    name="open-radio-group"
                    value={stateForm.open_state}
                    row
                    onChange={(_, value) => {
                      setStateForm({
                        ...stateForm,
                        open_state: value === "true" ? true : false,
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      label="公開"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value={false}
                      label="非公開"
                      control={<Radio />}
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl>
                  <InputLabel>公開期間</InputLabel>
                  <Stack direction={isSmUp ? "row" : "column"}>
                    <DatePickerCustom
                      value={stateForm.project_start_date}
                      onChange={(v) =>
                        handleChangeDate(v, "project_start_date")
                      }
                      label="開始"
                      helperText={formError.project_start_date}
                      error={formError.project_start_date.length > 0}
                    />
                    {isSmUp && (
                      <Typography sx={{ alignSelf: "center", pt: 2 }}>
                        ～
                      </Typography>
                    )}
                    <DatePickerCustom
                      value={stateForm.project_end_date}
                      onChange={(v) => handleChangeDate(v, "project_end_date")}
                      label="終了"
                      helperText={formError.project_end_date}
                      error={formError.project_end_date.length > 0}
                    />
                  </Stack>
                </FormControl>

                <FormControl>
                  <InputLabel>
                    <LabelRequired title="２次元バーコード利用" />
                  </InputLabel>
                  <RadioGroup
                    name="print-radio-group"
                    value={stateForm.print_2d_barcode ?? false}
                    row
                    onChange={(_, value) => {
                      setStateForm({
                        ...stateForm,
                        print_2d_barcode: value === "true" ? true : false,
                      });
                    }}
                  >
                    <FormControlLabel
                      value={false}
                      label="なし"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value={true}
                      label="あり"
                      control={<Radio />}
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
            </FormGroup>
          </CardContent>
        </Card>

        <Card>
          <CardHeader
            title="テンプレート"
            sx={{ bgcolor: Colors.MAIN_GREEN_LIGHT }}
          />
          <CardContent>
            <FormGroup>
              {!is_user_made ? (
                <TemplateSelectBox
                  onClick={() => {
                    setOpenTemplate(true);
                  }}
                  template={stateForm.template}
                  helperText={formError.template}
                  error={formError.template.length > 0}
                />
              ) : (
                <TemplateCreateBox
                  ref={TemplateCreateRef}
                  template={templateExcel}
                  onChange={() => {
                    setIsEdited(hasUpdateData());
                  }}
                  onError={(value: boolean) => {
                    setIsErrorTemplate(value);
                  }}
                  isNew
                  activityBaseId={stateForm.project_location_id}
                />
              )}
              <ModalListTemplate
                open={openTemplate}
                setOpen={setOpenTemplate}
                templateType={"TEMPLATE_WORK"}
                onChooseTemplate={handleChooseTemplate}
                templateId={stateForm?.template?.SK}
              />
            </FormGroup>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title="備考" sx={{ bgcolor: Colors.MAIN_GREEN_LIGHT }} />
          <CardContent>
            <FormGroup>
              <TextField
                label="備考"
                name="memo"
                multiline
                value={stateForm.memo}
                rows={3}
                inputProps={{
                  maxLength: 200,
                }}
                onChange={(e) => onChangeText("memo")(e.target.value)}
                onBlur={() => onValidateText("memo")}
                placeholder="メモや備考を記入できます（最大200文字）"
                error={formError.memo.length > 0}
                helperText={formError.memo}
              />
            </FormGroup>
          </CardContent>
        </Card>
      </Stack>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
        <Button
          onClick={handleCreateProject}
          disabled={!isActiveButton()}
          color="secondary"
        >
          {"作成"}
        </Button>
      </Box>
    </GenericTemplate>
  );
};
export default CreateProjectTlogScreen;
