import React, { VFC, useState, useMemo, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  TextField,
  MenuItem,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { useEffect } from "react";
import { Validation } from "@validation";
import LabelRequired from "components/atoms/LabelRequired";
import { cloneDeep } from "lodash";
import SelectLabel from "components/atoms/SelectLabel";

export interface IData {
  name: string;
  frame_no: string;
  model: string;
  workplace_id: string;
  workplace: string;
  fuel_type_id: string;
  fuel_type: string;
}

interface IProps {
  data: IData;
  open: boolean;
  onClose: Function;
  onChange: Function;
  listWorkplace: any[];
  listFuel: any[];
}

const WorkplaceEditDialog: VFC<IProps> = ({
  data,
  open,
  onClose,
  onChange,
  listWorkplace,
  listFuel,
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [formError, setFormError] = useState<{
    name: string;
    frame_no: string;
    model: string;
    workplace_id: string;
    fuel_type_id: string;
  }>({
    name: "",
    frame_no: "",
    model: "",
    workplace_id: "",
    fuel_type_id: "",
  });
  const [disabled, setDisabled] = useState(true);
  const [stateForm, setStateForm] = useState<IData>(cloneDeep(data));
  const [originData, setOriginData] = useState<IData>(cloneDeep(data));

  useEffect(() => {
    const setInitialData = async () => {
      setDisabled(true);
      setStateForm(data);
      setOriginData(data);
      setFormError({
        name: "",
        frame_no: "",
        model: "",
        workplace_id: "",
        fuel_type_id: "",
      });
    };

    if (open) {
      setInitialData();
    }
  }, [open, data]);

  // ------------------------------------------------------------------
  // 入力
  // ------------------------------------------------------------------

  const onChangeText = (field: keyof IData) => (newText: string) => {
    let newStateForm = { ...stateForm, [field]: newText };
    setStateForm(newStateForm);
  };

  const onValidateText = (field: keyof IData, value?: string) => {
    const mess = validator(field, value ?? stateForm[field]);
    setFormError({ ...formError, [field]: mess });
  };

  const onChangeSelect =
    (field: string) =>
    (list: any[], e: SelectChangeEvent<string>, field_name: string) => {
      const selected = list.find((item) => item && item.SK === e.target.value);
      setStateForm((prev) => ({
        ...prev,
        [field]: selected[field_name],
      }));
    };

  const renderMenuItem = useCallback(
    (list: Array<any>, value: string, name: string) => {
      return list
        ? list.map((item, index) => (
            <MenuItem value={item ? item[value] : ""} key={index}>
              <span className="IgnoreExtractRuleTarget">{item[name]}</span>
            </MenuItem>
          ))
        : null;
    },
    [],
  );

  const validator = (field: keyof IData, value?: string) => {
    let mess: string = "";
    if (!value) value = "";

    switch (field) {
      case "name":
        mess = Validation.validate({
          type: "text",
          name: "名称",
          value: value,
          required: true,
          max_length: 20,
        });
        break;
      case "frame_no":
        mess = Validation.validate({
          type: "text",
          name: "車体番号",
          value: value,
          required: true,
          max_length: 20,
        });
        break;
      case "model":
        mess = Validation.validate({
          type: "text",
          name: "車種",
          value: value,
          required: true,
          max_length: 20,
        });
        break;
      case "fuel_type_id":
        mess = Validation.validate({
          type: "text",
          name: "燃料",
          value: value,
          required: true,
        });
        break;
      case "workplace_id":
        mess = Validation.validate({
          type: "text",
          name: "使用場所",
          value: value,
          required: true,
        });
        break;
    }
    return mess;
  };

  const arrayDataError = useMemo(() => {
    let messages: Array<string> = [];
    Object.values(formError).forEach((errorText) => {
      if (errorText !== "") messages.push(errorText);
    });
    return messages;
  }, [formError]);

  useEffect(() => {
    if (
      Object.values(stateForm).some((d) => d == "") ||
      arrayDataError.length > 0 ||
      JSON.stringify(stateForm) === JSON.stringify(originData)
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [stateForm, arrayDataError]);

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <FormGroup>
          <Stack sx={styles.formStack}>
            <TextField
              label={
                <>
                  <LabelRequired title={"名称"} />
                </>
              }
              value={stateForm.name}
              onChange={(e) => {
                onChangeText("name")(e.target.value);
              }}
              onBlur={() => onValidateText("name")}
              error={formError.name.length > 0}
              helperText={formError.name}
              inputProps={{
                maxLength: 20,
              }}
            />
            <TextField
              label={
                <>
                  <LabelRequired title={"車体番号"} />
                </>
              }
              value={stateForm.frame_no}
              onChange={(e) => {
                onChangeText("frame_no")(e.target.value);
              }}
              onBlur={() => onValidateText("frame_no")}
              error={formError.frame_no.length > 0}
              helperText={formError.frame_no}
              inputProps={{
                maxLength: 20,
              }}
            />
            <TextField
              label={
                <>
                  <LabelRequired title={"車種"} />
                </>
              }
              value={stateForm.model}
              onChange={(e) => {
                onChangeText("model")(e.target.value);
              }}
              onBlur={() => onValidateText("model")}
              error={formError.model.length > 0}
              helperText={formError.model}
              inputProps={{
                maxLength: 20,
              }}
            />
            <SelectLabel
              label={
                <>
                  <LabelRequired title={"燃料"} />
                </>
              }
              value={stateForm.fuel_type_id ?? ""}
              onChange={(e) => {
                onChangeText("fuel_type_id")(e.target.value);
                onValidateText("fuel_type_id", e.target.value);
                onChangeSelect("fuel_type")(listFuel, e, "fuel_name");
              }}
            >
              {renderMenuItem(listFuel, "SK", "fuel_name")}
            </SelectLabel>
            <SelectLabel
              label={
                <>
                  <LabelRequired title={"使用場所"} />
                </>
              }
              value={stateForm.workplace_id ?? ""}
              onChange={(e) => {
                onChangeText("workplace_id")(e.target.value);
                onValidateText("workplace_id", e.target.value);
                onChangeSelect("workplace")(listWorkplace, e, "workplace_name");
              }}
            >
              {renderMenuItem(listWorkplace, "SK", "workplace_name")}
            </SelectLabel>
          </Stack>
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
        >
          キャンセル
        </Button>

        <Button
          onClick={() => {
            onChange(stateForm);
            onClose();
          }}
          disabled={disabled}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkplaceEditDialog;

const styles = {
  formStack: { pb: 0, width: "100%" },
} as const;
