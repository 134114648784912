import { cloneDeep } from "lodash";
import { TYPES } from "store/types";

export type ApproveState = {
  templateInfo: Type.TemplateInfo;
  flowInfo: Type.FlowInfo;
  stepList: Type.StepDetail[];
  textSearch: string;
  currentStepId: string;
  openMemberSearchDialog: boolean;
  clickOnSearch: boolean;
  apiLoading: boolean;
  flowList: Type.FlowInfo[];
  editedFlowName: string;
  onClickCreateNew: boolean;
  onClickDelete: boolean;
  deletedStepList: {
    SK: string;
    PK: string;
  }[];
  stepsReadyForSubmit: boolean;
  flowNameReadyForSubmit: boolean;
  recheck: boolean;
  isTheSameOrigin: boolean;
  maxUserLocation: number;
  newFlowSK: string;
  originStepList: Type.StepDetail[];
  deleteStepFlowList: {
    sk_flow: string;
    approver_id: string;
  }[];
  requireGetStepListForFlow: boolean;
};

const initialState: ApproveState = {
  isTheSameOrigin: true,
  recheck: false,
  onClickDelete: false,
  onClickCreateNew: false,
  editedFlowName: "",
  textSearch: "",
  templateInfo: {
    created_at: "",
    flow_name: "",
    template_size: "",
    template_path: "",
    template_image: "",
    order: 1,
    template_preview_path: "",
    template_image_thumb: "",
    updated_at: "",
    flow_id: "",
    template_name: "",
    screen_id: "",
    template_id: "",
    SK: "",
    PK: "",
    template_type: "",
  },
  flowInfo: {
    template_name: "",
    updated_at: "",
    flow_id: "",
    created_at: "",
    template_id: "",
    flow_name: "",
    SK: "",
    PK: "",
    location_id: "",
    is_used_daily_application: true,
    is_used_final_application: true,
  },
  stepList: [],
  currentStepId: "",
  openMemberSearchDialog: false,
  clickOnSearch: false,
  apiLoading: false,
  flowList: [],
  deletedStepList: [],
  stepsReadyForSubmit: false,
  flowNameReadyForSubmit: false,
  maxUserLocation: 1,
  newFlowSK: "",
  originStepList: [],
  deleteStepFlowList: [],
  requireGetStepListForFlow: false,
};

export const approveFlowReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TYPES.INIT_APPROVE_STATE:
      return {
        ...cloneDeep(initialState),
      };
    case TYPES.SET_RESET:
      return {
        ...state,
        onClickDelete: false,
        onClickCreateNew: false,
      };
    case TYPES.SET_MEMBER_LIST_SEARCH:
      return {
        ...state,
        apiLoading: false,
        stepList: action.payload,
      };

    case TYPES.CLEAR_MEMBER_LIST_SEARCH:
      return {
        ...state,
        clickOnSearch: false,
      };
    case TYPES.SET_NAME_MEMBER_SEARCH:
      return {
        ...state,
        textSearch: action.payload,
        clickOnSearch: false,
      };

    case TYPES.SET_TEMPLATE:
      return {
        ...state,
        templateInfo: action.payload,
        recheck: !state.recheck,
      };
    case TYPES.SET_APPROVAL_FLOW:
      return {
        ...state,
        flowInfo: action.payload,
        editedFlowName: action.payload.flow_name,
        flowNameReadyForSubmit: false,
        recheck: !state.recheck,
        newFlowSK: "",
      };
    case TYPES.SET_CURRENT_STEP_ID:
      return {
        ...state,
        currentStepId: action.payload,
        openMemberSearchDialog: true,
        clickOnSearch: false,
      };
    case TYPES.SET_STEP_LIST:
      return {
        ...state,
        requireGetStepListForFlow: false,
        stepList: action.payload,
      };
    case TYPES.SET_OPEN_DIALOG:
      return {
        ...state,
        openMemberSearchDialog: action.payload,
      };
    case TYPES.SET_CLICK_ON_SEARCH:
      return {
        ...state,
        clickOnSearch: true,
        apiLoading: true,
      };
    case TYPES.SET_API_IS_LOADING:
      return {
        ...state,
        apiLoading: action.payload,
      };
    case TYPES.SET_FLOW_LIST:
      return {
        ...state,
        flowList: action.payload,
      };
    case TYPES.SET_EDITED_FLOW_NAME:
      return {
        ...state,
        editedFlowName: action.payload,
      };
    case TYPES.SET_ON_CLICK_CREAT_NEW_FLOW:
      return {
        ...state,
        onClickCreateNew: true,
        flowInfo: action.payload,
        stepList: [],
        editedFlowName: "",
        flowNameReadyForSubmit: false,
      };
    case TYPES.SET_ON_CLICK_DELETE_FLOW:
      return {
        ...state,
        onClickDelete: true,
      };
    case TYPES.SET_APPROVER_FOR_STEP:
      return {
        ...state,
        stepList: action.payload,
        openMemberSearchDialog: false,
      };
    case TYPES.SET_APPROVER_FOR_STEP_AND_DELETE_APPROVER:
      return {
        ...state,
        stepList: action.payload.stepList,
        deletedStepList: [...state.deletedStepList, ...action.payload.deletedStepList],
        openMemberSearchDialog: false,
      };
    case TYPES.SET_ADD_STEP:
      return {
        ...state,
        stepList: [...state.stepList, action.payload],
      };
    case TYPES.SET_DELETE_STEP:
      return {
        ...state,
        deletedStepList: [...state.deletedStepList, ...action.payload],
      };
    case TYPES.SET_STEPS_READY_FOR_SUBMIT:
      return {
        ...state,
        stepsReadyForSubmit: action.payload,
      };
    case TYPES.SET_FLOW_NAME_READY_FOR_SUBMIT:
      return {
        ...state,
        flowNameReadyForSubmit: action.payload,
      };
    case TYPES.SET_SAVE_APPROVE:
      return {
        ...state,
        originStepList: cloneDeep(state.stepList),
        templateInfo: action.payload.selectedTemplate,
        newFlowSK: action.payload.sk_flow,
        stepsReadyForSubmit: false,
        flowNameReadyForSubmit: false,
        deletedStepList: [],
        requireGetStepListForFlow: true,
      };

    case TYPES.RE_CHECK:
      return {
        ...state,
        recheck: !state.recheck,
      };
    case TYPES.SET_IS_THE_SAME_ORIGIN:
      return {
        ...state,
        isTheSameOrigin: action.payload,
      };
    case TYPES.SET_MAX_USER_LOCATION:
      return {
        ...state,
        maxUserLocation: action.payload,
      };
    case TYPES.SET_ORIGIN_STEP_LIST:
      return {
        ...state,
        originStepList: action.payload,
        requireGetStepListForFlow: false,
      };
    case TYPES.SET_DELETE_STEP_FLOW_LIST:
      return {
        ...state,
        deleteStepFlowList: action.payload,
      };
    default:
      return state;
  }
};
