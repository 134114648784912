import { getSignedUrlFile } from "@api/template";
import { EXCEL_TEMPLATE_INPUT } from "@shared-constants";
import { createFileNameFollowUri } from "@utils/index";
import {
  getInputGroupKey,
  getPageKey,
  getTemplateKey,
} from "@utils/template/excelTemplate";
import messages from "config/messages";
import { title } from "process";
import { TemplateExcelDataInfo } from "services/models";

const getImageDataFromPathFile = async (pathFile: string) => {
  try {
    const res = await getSignedUrlFile(pathFile);
    if (res?.link_url) {
      return res.link_url;
    } else {
      throw Error("fail to get image data");
    }
  } catch (err) {
    throw Error("fail to get image data");
  }
};

const getImageUrlReportType2 = async (newStateFormEditInfo: any) => {
  const getUrlImage = async () => {
    try {
      const originalImageArray = newStateFormEditInfo?.image_array;
      for (const [index, item] of originalImageArray.entries()) {
        if (item?.path_file) {
          const newUri = await getImageDataFromPathFile(item.path_file);
          const new_image_array = [...originalImageArray];
          new_image_array[index].uri = newUri;
          new_image_array[index].non_Local = true;
          newStateFormEditInfo = {
            ...newStateFormEditInfo,
            image_array: new_image_array,
          };
        }
        if (item?.path_file_jpg) {
          const newUri = await getImageDataFromPathFile(item.path_file_jpg);
          const new_image_array = [...originalImageArray];
          new_image_array[index].uri_jpg = newUri;
          new_image_array[index].non_Local = true;
          newStateFormEditInfo = {
            ...newStateFormEditInfo,
            image_array: new_image_array,
          };
        }
      }
    } catch (err) {
      console.log("err :>> ", err);
    }
  };
  if (newStateFormEditInfo?.image_array?.length > 0) {
    await getUrlImage();
  }
  return newStateFormEditInfo;
};

const getImageUrlReportType3 = async (newStateFormEditInfo: any) => {
  const getUrlImage = async () => {
    try {
      const originalAbnomalityImage = newStateFormEditInfo?.abnomality_image;
      for (const [index, item] of originalAbnomalityImage.entries()) {
        if (item?.path_file) {
          const newUri = await getImageDataFromPathFile(item.path_file);
          const new_image_array = [...originalAbnomalityImage];
          new_image_array[index].uri = newUri;
          new_image_array[index].non_Local = true;
          newStateFormEditInfo = {
            ...newStateFormEditInfo,
            abnomality_image: new_image_array,
          };
          // }
        }
        if (item?.path_file_jpg) {
          const newUri = await getImageDataFromPathFile(item.path_file_jpg);
          const new_image_array = [...originalAbnomalityImage];
          new_image_array[index].uri_jpg = newUri;
          new_image_array[index].non_Local = true;
          newStateFormEditInfo = {
            ...newStateFormEditInfo,
            abnomality_image: new_image_array,
          };
        }
      }
    } catch (err) {
      console.log("err :>> ", err);
    }
  };
  if (newStateFormEditInfo?.abnomality_image?.length > 0) {
    await getUrlImage();
  }
  return newStateFormEditInfo;
};

const getImageUrlReportType4 = async (newStateFormEditInfo: any) => {
  const getUrlImage = async (image_array: any) => {
    try {
      for (const [index, item] of image_array.entries()) {
        if (item?.path_file) {
          const newUri = await getImageDataFromPathFile(item.path_file);
          image_array[index].uri = newUri;
          image_array[index].non_Local = true;
        }
        if (item?.path_file_jpg) {
          const newUri = await getImageDataFromPathFile(item.path_file_jpg);
          image_array[index].uri_jpg = newUri;
          image_array[index].non_Local = true;
        }
      }
      return image_array;
    } catch (err) {
      console.log("err :>> ", err);
      return image_array;
    }
  };

  const keys = Object.keys(newStateFormEditInfo);
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    if (key == "image_array" || key == "custom_image") {
      const url_image = await getUrlImage(newStateFormEditInfo[key]);
      newStateFormEditInfo = {
        ...newStateFormEditInfo,
        [key]: url_image,
      };
    } else if (
      newStateFormEditInfo[key].path_file ||
      newStateFormEditInfo[key].path_file_jpg
    ) {
      const url_image = await getUrlImage([newStateFormEditInfo[key]]);
      newStateFormEditInfo = {
        ...newStateFormEditInfo,
        [key]: url_image.length > 0 ? url_image[0] : newStateFormEditInfo[key],
      };
    }
  }

  return newStateFormEditInfo;
};

const getImageUrlReportType5 = async (newStateFormEditInfo: any) => {
  const getUrlImage = async () => {
    try {
      /** Container Condition Photo */
      const originalContainerConditionPhoto =
        newStateFormEditInfo?.container_condition_photo;
      for (const [index, item] of originalContainerConditionPhoto.entries()) {
        if (item?.path_file) {
          const newUri = await getImageDataFromPathFile(item.path_file);
          const new_image_array = [...originalContainerConditionPhoto];
          new_image_array[index].uri = newUri;
          new_image_array[index].non_Local = true;
          newStateFormEditInfo = {
            ...newStateFormEditInfo,
            container_condition_photo: new_image_array,
          };
        }
        if (item?.path_file_jpg) {
          const newUri = await getImageDataFromPathFile(item.path_file_jpg);
          const new_image_array = [...originalContainerConditionPhoto];
          new_image_array[index].uri_jpg = newUri;
          new_image_array[index].non_Local = true;
          newStateFormEditInfo = {
            ...newStateFormEditInfo,
            container_condition_photo: new_image_array,
          };
        }
      }

      /** Other Document Photo */
      const originalContainerOtherDocumentPhoto =
        newStateFormEditInfo?.other_document_photo;
      for (const [
        index,
        item,
      ] of originalContainerOtherDocumentPhoto.entries()) {
        if (item?.path_file) {
          const newUri = await getImageDataFromPathFile(item.path_file);
          const new_image_array = [...originalContainerOtherDocumentPhoto];
          new_image_array[index].uri = newUri;
          new_image_array[index].non_Local = true;
          newStateFormEditInfo = {
            ...newStateFormEditInfo,
            other_document_photo: new_image_array,
          };
        }
        if (item?.path_file_jpg) {
          const newUri = await getImageDataFromPathFile(item.path_file_jpg);
          const new_image_array = [...originalContainerOtherDocumentPhoto];
          new_image_array[index].uri_jpg = newUri;
          new_image_array[index].non_Local = true;
          newStateFormEditInfo = {
            ...newStateFormEditInfo,
            other_document_photo: new_image_array,
          };
        }
      }
    } catch (err) {
      console.log("err :>> ", err);
    }
  };
  if (
    newStateFormEditInfo?.container_condition_photo?.length > 0 ||
    newStateFormEditInfo?.other_document_photo?.length
  ) {
    await getUrlImage();
  }
  return newStateFormEditInfo;
};

const getImageUrlReportType6 = async (newStateFormEditInfo: any) => {
  const getUrlImage = async () => {
    try {
      /** DamageLocationPhoto */
      const originalDamageLocationPhoto =
        newStateFormEditInfo?.damage_location_photo;
      for (const [index, item] of originalDamageLocationPhoto.entries()) {
        if (item?.path_file) {
          const newUri = await getImageDataFromPathFile(item.path_file);
          const new_image_array = [...originalDamageLocationPhoto];
          new_image_array[index].uri = newUri;
          new_image_array[index].non_Local = true;
          newStateFormEditInfo = {
            ...newStateFormEditInfo,
            damage_location_photo: new_image_array,
          };
        }
        if (item?.path_file_jpg) {
          const newUri = await getImageDataFromPathFile(item.path_file_jpg);
          const new_image_array = [...originalDamageLocationPhoto];
          new_image_array[index].uri_jpg = newUri;
          new_image_array[index].non_Local = true;
          newStateFormEditInfo = {
            ...newStateFormEditInfo,
            damage_location_photo: new_image_array,
          };
        }
      }

      /** QA MA CTC PART Photo */
      const originalQaMaCtcPartPhoto =
        newStateFormEditInfo?.qa_ma_ctc_part_photo;
      for (const [index, item] of originalQaMaCtcPartPhoto.entries()) {
        if (item?.path_file) {
          const newUri = await getImageDataFromPathFile(item.path_file);
          const new_image_array = [...originalQaMaCtcPartPhoto];
          new_image_array[index].uri = newUri;
          new_image_array[index].non_Local = true;
          newStateFormEditInfo = {
            ...newStateFormEditInfo,
            qa_ma_ctc_part_photo: new_image_array,
          };
        }
        if (item?.path_file_jpg) {
          const newUri = await getImageDataFromPathFile(item.path_file_jpg);
          const new_image_array = [...originalQaMaCtcPartPhoto];
          new_image_array[index].uri_jpg = newUri;
          new_image_array[index].non_Local = true;
          newStateFormEditInfo = {
            ...newStateFormEditInfo,
            qa_ma_ctc_part_photo: new_image_array,
          };
        }
      }
    } catch (err) {
      console.log("err :>> ", err);
    }
  };
  if (
    newStateFormEditInfo?.damage_location_photo.length > 0 ||
    newStateFormEditInfo?.qa_ma_ctc_part_photo?.length
  ) {
    await getUrlImage();
  }
  return newStateFormEditInfo;
};

const getImageUrlReportType7 = async (newStateFormEditInfo: any) => {
  const getUrlImage = async (image_array: any) => {
    try {
      for (const [index, item] of image_array.entries()) {
        if (item?.path_file) {
          const newUri = await getImageDataFromPathFile(item.path_file);
          image_array[index].uri = newUri;
          image_array[index].non_Local = true;
        }
        if (item?.path_file_jpg) {
          const newUri = await getImageDataFromPathFile(item.path_file_jpg);
          image_array[index].uri_jpg = newUri;
          image_array[index].non_Local = true;
        }
      }
      return image_array;
    } catch (err) {
      console.log("err :>> ", err);
      return image_array;
    }
  };
  const SIGNATURE_KEY = [
    "sign_date",
    "sign_company",
    "sign_car_no",
    "sign_name",
  ];
  if (newStateFormEditInfo?.image_array) {
    const url_image = await getUrlImage(newStateFormEditInfo.image_array);
    newStateFormEditInfo = {
      ...newStateFormEditInfo,
      image_array: url_image,
    };
  }
  for (let index = 0; index < SIGNATURE_KEY.length; index++) {
    const key = SIGNATURE_KEY[index];
    if (
      newStateFormEditInfo[key] &&
      (newStateFormEditInfo[key].path_file ||
        newStateFormEditInfo[key].path_file_jpg)
    ) {
      const url_image = await getUrlImage([newStateFormEditInfo[key]]);
      newStateFormEditInfo = {
        ...newStateFormEditInfo,
        [key]: url_image.length > 0 ? url_image[0] : newStateFormEditInfo[key],
      };
    }
  }
  return newStateFormEditInfo;
};

export const screenIdSupportGetImageUrl: { [fieldName: string]: Function } = {
  // SCREEN_TEMPLATE_01: getImageUrlReportType1,
  SCREEN_TEMPLATE_06: getImageUrlReportType2,
  SCREEN_TEMPLATE_07: (newStateFormEditInfo: any) => newStateFormEditInfo,
  default: (newStateFormEditInfo: any) => newStateFormEditInfo,
  SCREEN_TEMPLATE_08: getImageUrlReportType3,
  SCREEN_TEMPLATE_09: getImageUrlReportType4,
  SCREEN_TEMPLATE_10: getImageUrlReportType5,
  SCREEN_TEMPLATE_11: getImageUrlReportType2,
  SCREEN_TEMPLATE_12: getImageUrlReportType6,
  SCREEN_TEMPLATE_13: getImageUrlReportType7,
  SCREEN_TEMPLATE_14: getImageUrlReportType7,
};

const checkType = {
  input: "input",
  checkbox: "checkbox",
  is_manual_input: "is_manual_input",
  array: "array",
  input_bool: "input_bool",
};

const checkInput = (dataAttribute: any) => {
  if (
    dataAttribute === undefined ||
    dataAttribute === null ||
    dataAttribute === "" ||
    dataAttribute === false ||
    dataAttribute?.length === 0
  ) {
    return true;
  }
};

const checkDefaultData = (dataAttribute: any) => {
  if (
    !dataAttribute ||
    typeof dataAttribute !== "object" ||
    !("SK" in dataAttribute)
  ) {
    return false;
  }
  return (
    dataAttribute.SK === "" ||
    dataAttribute.SK === null ||
    dataAttribute.SK === undefined
  );
};

const checkCheckbox = (dataAttributeFirst: any, dataAttributeSecond: any) => {
  return !(dataAttributeFirst || dataAttributeSecond);
};

const checkInputBool = (dataAttribute: any) => {
  if (
    dataAttribute === undefined ||
    dataAttribute === null ||
    dataAttribute === ""
  ) {
    return true;
  }
};

const checkInputInfo = (checkInfo: any, templateEditInfo: any) => {
  let isError = false;
  let message = [];
  for (const item of checkInfo) {
    const { type } = item;
    if (type === checkType.input) {
      const { attribute, title } = item;
      const dataAttribute = templateEditInfo[attribute];
      if (checkInput(dataAttribute)) {
        isError = true;
        message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
      }
    } else if (type === checkType.checkbox) {
      const { attributeFirst, attributeSecond, title } = item;
      const dataAttributeFirst = templateEditInfo[attributeFirst];
      const dataAttributeSecond = templateEditInfo[attributeSecond];
      if (checkCheckbox(dataAttributeFirst, dataAttributeSecond)) {
        isError = true;
        message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
      }
    } else if (type === checkType.is_manual_input) {
      const { attributeFirst, attributeSecond, title } = item;
      const dataAttributeFirst = templateEditInfo[attributeFirst];
      const dataAttributeSecond = templateEditInfo[attributeSecond];
      if (dataAttributeFirst) {
        if (dataAttributeFirst["is_manual_input"]) {
          if (checkInput(dataAttributeSecond)) {
            isError = true;
            message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
          }
        }
      }
    } else if (type === checkType.array) {
      const { attributeFirst, attributeSecond, title } = item;
      const dataAttributeFirst = templateEditInfo[attributeFirst];
      const dataAttributeSecond = templateEditInfo[attributeSecond];
      if (Array.isArray(dataAttributeFirst)) {
        if (dataAttributeFirst.some((elm) => elm?.is_manual_input)) {
          if (checkInput(dataAttributeSecond)) {
            isError = true;
            message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
          }
        }
      } else if (
        typeof dataAttributeFirst === "object" &&
        dataAttributeFirst !== null
      ) {
        if (dataAttributeFirst.is_manual_input) {
          if (checkInput(dataAttributeSecond)) {
            isError = true;
            message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
          }
        }
      }
    } else if (type === checkType.input_bool) {
      const { attribute, title } = item;
      const dataAttribute = templateEditInfo[attribute];
      if (checkInputBool(dataAttribute)) {
        isError = true;
        message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
      }
    }
  }

  return { isError, message };
};

const CheckRequiredFieldReportType7 = (templateEditInfo: any) => {
  const checkInfo = [
    {
      attribute: "vehicle_id",
      title: "車体番号",
      type: checkType.input,
    },
    {
      attribute: "driver",
      title: "担当者名",
      type: checkType.input,
    },
    {
      attributeFirst: "driver",
      attributeSecond: "driver_other",
      title: "その他(担当者名)",
      type: checkType.is_manual_input,
    },
    {
      attribute: "year_month",
      title: "実施年月",
      type: checkType.input,
    },
  ];
  const checkInfo_result = [
    {
      attribute: "check_user",
      title: "点検実施者",
      type: checkType.input,
    },
    {
      attributeFirst: "check_user",
      attributeSecond: "check_user_other",
      title: "その他(点検実施者)",
      type: checkType.is_manual_input,
    },
  ];
  let isError = false;
  let message: string[] = [];
  const res = checkInputInfo(checkInfo, templateEditInfo);

  isError = res.isError;
  message = res.message;
  Object.keys(templateEditInfo.check_result).map((key) => {
    const res_check = checkInputInfo(
      checkInfo_result,
      templateEditInfo.check_result[key],
    );
    if (res_check.message.length > 0) {
      res_check.message[0] += `（${Number(key) + 1}日）`;
    }

    isError = res_check.isError || isError;
    message = message.concat(res_check.message);
  });

  return { isError, message };
};

const CheckRequiredFieldReportType8 = (templateEditInfo: any) => {
  const checkInfo = [
    { attribute: "container_no", title: "デバン", type: checkType.input },
    {
      attribute: "sealed",
      title: "封印",
      type: checkType.input_bool,
    },
    {
      attribute: "person_worker",
      title: "作業者名",
      type: checkType.input,
    },
    {
      attributeFirst: "person_worker",
      attributeSecond: "person_worker_other",
      title: "その他(作業者名)",
      type: checkType.is_manual_input,
    },
  ];
  if (templateEditInfo["seal_no"].length > 0) {
    checkInfo.push({
      attribute: "bool_seal_no",
      title: "シールNo.確認",
      type: checkType.input,
    });
  }

  return checkInputInfo(checkInfo, templateEditInfo);
};

const CheckRequiredFieldReportType9 = (
  templateEditInfo: any,
  template: any,
  step: number,
) => {
  let isError: boolean = false;
  let message: string[] = [];
  let data_info = [...template.data_info];
  // 追加ページの入力項目設定をdata_infoに追加
  if (templateEditInfo["page"]) {
    const page = Number(templateEditInfo["page"] ?? 1);
    for (let index = 1; index < page; index++) {
      template.data_info.forEach((item: TemplateExcelDataInfo) => {
        const cloneItem = { ...item };
        cloneItem.coordinate = getPageKey(item.coordinate, index + 1);
        data_info.push(cloneItem);
      });
    }
  }
  // 追加テンプレートの入力項目設定をdata_infoに追加
  template.extra_template?.map((extra_template: any, template_no: number) => {
    extra_template?.data_info?.forEach((item: TemplateExcelDataInfo) => {
      const page_key = getTemplateKey("page", template_no + 1);
      const all_page = templateEditInfo[page_key] ?? 1;
      for (let index = 0; index < all_page; index++) {
        const cloneItem = { ...item };
        const coordinate = getPageKey(item.coordinate, index + 1);
        cloneItem.coordinate = getTemplateKey(coordinate, template_no + 1);
        data_info.push(cloneItem);
      }
    });
  });
  data_info.forEach((item: TemplateExcelDataInfo) => {
    if (item.input == EXCEL_TEMPLATE_INPUT.INPUT_GROUP && item.group_info) {
      // 入力グループの入力項目設定をdata_infoに追加
      let tmpDataInfo: TemplateExcelDataInfo[] = [];
      const listGroupIndex: number[] =
        templateEditInfo[item.coordinate + "_group"] ?? [];
      listGroupIndex.forEach((count) => {
        item.group_info?.forEach((e) => {
          let setting = { ...e };
          setting.coordinate = getInputGroupKey(
            item.coordinate,
            setting.coordinate,
            count,
          );
          tmpDataInfo.push(setting);
        });
      });
      data_info = data_info.concat(tmpDataInfo);
    }
  });

  data_info.forEach((item: TemplateExcelDataInfo) => {
    const required =
      item.required && item.required_step
        ? item.required_step <= step
        : item.required;
    if (required) {
      var dataAttribute = "";
      switch (item.input) {
        case EXCEL_TEMPLATE_INPUT.TEXT:
        case EXCEL_TEMPLATE_INPUT.DATE:
        case EXCEL_TEMPLATE_INPUT.NUMBER:
        case EXCEL_TEMPLATE_INPUT.TIME:
        case EXCEL_TEMPLATE_INPUT.BARCODE:
        case EXCEL_TEMPLATE_INPUT.INPUT_GROUP:
          dataAttribute = templateEditInfo[item.coordinate];
          break;
        case EXCEL_TEMPLATE_INPUT.MASTER:
          dataAttribute = templateEditInfo[item.coordinate]?.name;
          break;
        case EXCEL_TEMPLATE_INPUT.IMAGE:
        case EXCEL_TEMPLATE_INPUT.DRAW_IMAGE:
          dataAttribute = templateEditInfo[item.coordinate]?.path_file;
          break;
        case EXCEL_TEMPLATE_INPUT.USER_NAME:
          dataAttribute = templateEditInfo[item.coordinate]?.name;
          break;
        case EXCEL_TEMPLATE_INPUT.SIGNATURE:
          dataAttribute = templateEditInfo[item.coordinate]?.path_file;
          break;
        case EXCEL_TEMPLATE_INPUT.CHECKBOX:
          dataAttribute = templateEditInfo[item.coordinate]?.checked;
          break;
      }
      if (checkInput(dataAttribute)) {
        isError = true;
        message.push(messages.COMMON.ERROR.MSG_RQUIRED(item.name));
      }
    }
  });

  return { isError, message };
};

const CheckRequiredFieldReportType10 = (templateEditInfo: any) => {
  let isError: boolean = false;
  let message: string[] = [];

  const checkInfo = [
    {
      attribute: "loading_date",
      title: "Loading Date",
      type: checkType.input,
    },
    {
      attribute: "warehouse",
      title: "Warehouse",
      type: checkType.input,
    },
    {
      attribute: "load_by",
      title: "Load By",
      type: checkType.input,
    },
    {
      attribute: "commodity",
      title: "Commodity",
      type: checkType.input,
    },
    {
      attribute: "container_no",
      title: "Container No.",
      type: checkType.input,
    },
  ];
  const res = checkInputInfo(checkInfo, templateEditInfo);
  isError = res.isError;
  message = res.message;
  if (templateEditInfo["model_and_qty"].length > 0) {
    const regex = /^[0-9]+$/;
    templateEditInfo["model_and_qty"].forEach(
      ({ qty }: { qty: string }, index: number) => {
        const result = regex.test(qty);
        if (!result) {
          const msg = `${index + 1}番目のQuantityは数値で入力してください`;
          message.push(msg);
          isError = true;
        }
      },
    );
  }
  return { isError, message };
};

const CheckRequiredFieldReportType11 = (
  templateEditInfo: any,
  template: any,
  step: number,
  isReject: boolean,
) => {
  const checkInfo = [
    [
      {
        attribute: "date",
        title: "Date",
        type: checkType.input,
      },
      {
        attribute: "time",
        title: "Time",
        type: checkType.input,
      },
      {
        attribute: "warehouse",
        title: "Warehouse",
        type: checkType.input,
      },
      {
        attribute: "area",
        title: "Area",
        type: checkType.input,
      },
      {
        attribute: "reported_by",
        title: "Reported_by",
        type: checkType.input,
      },
      {
        attribute: "customer",
        title: "Customer",
        type: checkType.input,
      },
      {
        attribute: "commodity",
        title: "Commodity",
        type: checkType.input,
      },
      {
        attribute: "model_name",
        title: "Model Name",
        type: checkType.input,
      },
      {
        attribute: "quantity",
        title: "Quantity",
        type: checkType.input,
      },
      {
        attributeFirst: "serial_no",
        attributeSecond: "",
        title: "Serial No",
        type: checkType.array,
      },
      {
        attribute: "damage_location",
        title: "Damage Location",
        type: checkType.input,
      },
      {
        attribute: "cause",
        title: "Cause",
        type: checkType.input,
      },
      {
        attribute: "qa_authorizer",
        title: "QA Authorizer",
        type: checkType.input,
      },
      {
        attribute: "occurred_company",
        title: "Occurred Company",
        type: checkType.input,
      },
    ],
    [
      {
        attribute: "affiliation_of_employee",
        title: "Employee's Affiliation",
        type: checkType.input,
      },
      {
        attribute: "experience_of_employee_years",
        title: "Years",
        type: checkType.input,
      },
      {
        attribute: "experience_of_employee_months",
        title: "Months",
        type: checkType.input,
      },
      {
        attribute: "system_transaction_no",
        title: "System Transaction No",
        type: checkType.input,
      },
    ],
    [
      {
        attribute: "waste_claim_category",
        title: "Waste Clai Category",
        type: checkType.input,
      },
      {
        attribute: "accident_rank",
        title: "Accident Rank",
        type: checkType.input,
      },
      {
        attribute: "nonconformity_detail",
        title: "Nonconformity Detail",
        type: checkType.input,
      },
      {
        attribute: "nonconformity_overview",
        title: "Nonconformity Overview",
        type: checkType.input,
      },
      {
        attribute: "occurance_process",
        title: "Occurance Process",
        type: checkType.input,
      },
      {
        attribute: "process_of_root_cause",
        title: "Process of Root Cause",
        type: checkType.input,
      },
      {
        attribute: "responsibility",
        title: "Responsibility",
        type: checkType.input,
      },
      {
        attribute: "department_of_pic",
        title: "Department of PIC",
        type: checkType.input,
      },
      {
        attribute: "process_in_logistics",
        title: "Process in Logistics",
        type: checkType.input,
      },
      {
        attribute: "responsibility_in_process",
        title: "Responsibility in Process",
        type: checkType.input,
      },
      {
        attribute: "classification_of_nonconformity",
        title: "Classification of Nonconformity",
        type: checkType.input,
      },
      {
        attribute: "classification_of_cause",
        title: "Classification of Cause",
        type: checkType.input,
      },
      {
        attribute: "cause_overview",
        title: "Cause Overview",
        type: checkType.input,
      },
      {
        attribute: "root_cause",
        title: "Root Cause",
        type: checkType.input,
      },
      {
        attribute: "logistics_equipments",
        title: "Logistics Equipments",
        type: checkType.input,
      },
      {
        attribute: "corrective_action_improvement",
        title: "Corrective Action Improvement",
        type: checkType.input,
      },
      {
        attribute: "completion_improvement_date",
        title: "Completion improvement Date",
        type: checkType.input,
      },
    ],
    [
      {
        attribute: "scrap_unit",
        title: "Scrap Unit",
        type: checkType.input,
      },
      {
        attribute: "repair_unit",
        title: "Repair Unit",
        type: checkType.input,
      },
      {
        attribute: "no_problem_unit",
        title: "No Problem Unit",
        type: checkType.input,
      },
    ],
    [
      {
        attribute: "final_payer",
        title: "Final Payer",
        type: checkType.input,
      },
      {
        attribute: "expected_payment_month",
        title: "Expected Payment Month",
        type: checkType.input,
      },
      {
        attribute: "est_acc",
        title: "Est/Acc",
        type: checkType.input,
      },
      {
        attribute: "amount_cost_est",
        title: "Amount Cost Est",
        type: checkType.input,
      },
      {
        attribute: "closing_date",
        title: "Closing Date",
        type: checkType.input,
      },
    ],
    [
      {
        attribute: "amount_cost_act",
        title: "Amount Cost Act",
        type: checkType.input,
      },
      {
        attribute: "actual_payment_month",
        title: "Actual Payment Month",
        type: checkType.input,
      },
      {
        attribute: "insurance_sub_con_amount",
        title: "Insurance Sub con Amount",
        type: checkType.input,
      },
      {
        attribute: "final_tlgt_amount",
        title: "Final TLGT Amount",
        type: checkType.input,
      },
      {
        attribute: "received_invoice_no",
        title: "Received Invoice No",
        type: checkType.input,
      },
    ],
  ];
  //差戻のときは、前のステップまでの未入力チェックを行う
  const checkArray = checkInfo.slice(0, isReject ? step : step + 1);
  let isError = false;
  let message: string[] = [];

  for (const checkItems of checkArray) {
    for (const item of checkItems) {
      const { type } = item;
      if (type === checkType.input) {
        const { attribute, title } = item;
        if (!attribute) continue;
        const dataAttribute = templateEditInfo[attribute];
        if (checkInput(dataAttribute) || checkDefaultData(dataAttribute)) {
          isError = true;
          message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
        }
      } else if (type === checkType.array) {
        const { attributeFirst, attributeSecond, title } = item;
        if (!attributeFirst) continue;
        const dataAttributeFirst = templateEditInfo[attributeFirst];
        if (Array.isArray(dataAttributeFirst)) {
          for (let i = 0; i < dataAttributeFirst.length; i++) {
            if (
              dataAttributeFirst[i] === "" ||
              dataAttributeFirst[i] === null
            ) {
              isError = true;
              message.push(
                messages.COMMON.ERROR.MSG_RQUIRED_FOR_MULTI(title, i + 1),
              );
            }
          }
        }
      }
    }
  }
  return { isError, message };
};

const CheckRequiredFieldReportType13 = (templateEditInfo: any) => {
  const checkInfo = [
    {
      attributeFirst: "exterior_damaged_location",
      attributeSecond: "exterior_damaged_location_other",
      title: "外装破損発見場所(その他)",
      type: checkType.is_manual_input,
    },
    {
      attributeFirst: "written_by",
      attributeSecond: "written_by_other",
      title: "庫内記入者(その他)",
      type: checkType.is_manual_input,
    },
    {
      attributeFirst: "checked_by",
      attributeSecond: "checked_by_other",
      title: "確認者(その他)",
      type: checkType.is_manual_input,
    },
  ];
  return checkInputInfo(checkInfo, templateEditInfo);
};

export const screenIdSupportCheckRequiredFieldTemplate: {
  [fieldName: string]: Function;
} = {
  SCREEN_TEMPLATE_06: (templateEditInfo: any) => {
    const checkInfo = [
      {
        attribute: "place_found_issue",
        title: "発行元",
        type: checkType.input,
      },
      {
        attributeFirst: "place_found_issue",
        attributeSecond: "place_found_issue_other",
        title: "その他(発行元)",
        type: checkType.is_manual_input,
      },
      {
        attribute: "incident_date",
        title: "発生年月日",
        type: checkType.input,
      },
      {
        attributeFirst: "is_product_issue",
        attributeSecond: "is_packaging_issue",
        title: "事故区分",
        type: checkType.checkbox,
      },
      {
        attribute: "responsible_person",
        title: "事故当事者（事故責任）",
        type: checkType.input,
      },
      {
        attributeFirst: "responsible_person",
        attributeSecond: "responsible_person_other",
        title: "その他(事故当事者)",
        type: checkType.is_manual_input,
      },
      {
        attribute: "person_found_issue",
        title: "発見者氏名（当事者氏名）",
        type: checkType.input,
      },
      {
        attributeFirst: "person_found_issue",
        attributeSecond: "person_found_issue_other",
        title: "その他(発見者氏名)",
        type: checkType.is_manual_input,
      },
      {
        attributeFirst: "product_type",
        attributeSecond: "product_type_other",
        title: "その他(分類)",
        type: checkType.is_manual_input,
      },
      { attribute: "product_name", title: "商品名", type: checkType.input },
      { attribute: "amount_of_product", title: "数量", type: checkType.input },
      {
        attribute: "product_packaging_status",
        title: "荷姿状態",
        type: checkType.input,
      },
      {
        attributeFirst: "product_packaging_status",
        attributeSecond: "product_packaging_status_other",
        title: "その他(荷姿状態)",
        // type: checkType.is_manual_input,
        type: checkType.array,
      },
      {
        attribute: "reason_cause_issue",
        title: "事故発生理由",
        type: checkType.input,
      },
      {
        attributeFirst: "reason_cause_issue",
        attributeSecond: "reason_cause_issue_other",
        title: "その他(事故発生理由)",
        type: checkType.is_manual_input,
      },
      {
        attribute: "is_move_to_KF",
        title: "不良ロケに移動しましたか",
        type: checkType.input,
      },
      {
        attribute: "is_move_product",
        title: "現物移動しましたか",
        type: checkType.input,
      },
    ];

    let isError = false;
    let message = [];

    for (const item of checkInfo) {
      const { type } = item;
      if (type === checkType.input) {
        const { attribute, title } = item;
        if (!attribute) continue;
        const dataAttribute = templateEditInfo[attribute];
        if (checkInput(dataAttribute)) {
          isError = true;
          message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
        }
      } else if (type === checkType.checkbox) {
        const { attributeFirst, attributeSecond, title } = item;
        if (!attributeFirst) continue;
        const dataAttributeFirst = templateEditInfo[attributeFirst];
        const dataAttributeSecond = templateEditInfo[attributeSecond];
        if (checkCheckbox(dataAttributeFirst, dataAttributeSecond)) {
          isError = true;
          message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
        }
      } else if (type === checkType.is_manual_input) {
        const { attributeFirst, attributeSecond, title } = item;
        if (!attributeFirst) continue;
        const dataAttributeFirst = templateEditInfo[attributeFirst];
        const dataAttributeSecond = templateEditInfo[attributeSecond];
        if (dataAttributeFirst) {
          if (dataAttributeFirst["is_manual_input"]) {
            if (checkInput(dataAttributeSecond)) {
              isError = true;
              message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
            }
          }
        }
      } else if (type === checkType.array) {
        const { attributeFirst, attributeSecond, title } = item;
        if (!attributeFirst) continue;
        const dataAttributeFirst = templateEditInfo[attributeFirst];
        const dataAttributeSecond = templateEditInfo[attributeSecond];
        if (Array.isArray(dataAttributeFirst)) {
          if (dataAttributeFirst.some((elm) => elm?.is_manual_input)) {
            if (checkInput(dataAttributeSecond)) {
              isError = true;
              message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
            }
          }
        } else if (
          typeof dataAttributeFirst === "object" &&
          dataAttributeFirst !== null
        ) {
          if (dataAttributeFirst.is_manual_input) {
            if (checkInput(dataAttributeSecond)) {
              isError = true;
              message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
            }
          }
        }
      }
    }

    return { isError, message };
  },
  SCREEN_TEMPLATE_07: CheckRequiredFieldReportType7,
  SCREEN_TEMPLATE_08: CheckRequiredFieldReportType8,
  SCREEN_TEMPLATE_09: CheckRequiredFieldReportType9,
  SCREEN_TEMPLATE_10: CheckRequiredFieldReportType10,
  SCREEN_TEMPLATE_11: CheckRequiredFieldReportType11,
  SCREEN_TEMPLATE_13: CheckRequiredFieldReportType13,
  SCREEN_TEMPLATE_14: CheckRequiredFieldReportType13,
};
