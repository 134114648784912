import { call, select } from "redux-saga/effects";
import { getUserInfo, storeCondition } from "shared/utils";

export function* setIncidentDamageReportSaga(action: any): any {
    try {
        const updatedIncidentDamageReport = yield select(
            (state: any) => state.incidentDamegeReport,
        );
        const userId = yield getUserInfo()?.user_id;
        yield call(
            storeCondition,
            "incidentDamegeReport",
            updatedIncidentDamageReport,
            userId,
        );
    } catch (error) {
        console.log("function*setIncidentDamageReportSaga ~ error", error);
    }
}
