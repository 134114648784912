import {
  ManualInput,
} from "services/models";
import { createDateText, createHtmlForMultipleText } from "./";
import _ from "lodash";
import { createObjectURL, getBase64Image, getFileByUrl } from "..";

interface IPersonFoundIssue extends User, ManualInput {}
export interface ImageInfoTypePreview extends Type.ImageInfoType {
  base64?: string;
}

export interface User {
  PK?: string;
  SK: string;
  avatar: string | null;
  create_at?: string;
  phone_number?: string | null;
  username?: string;
  address?: string | null;
  full_name: string;
  email?: string;
  user_role?: string;
  company_id?: string;
  user_id?: string;
  location_id?: string;
  can_create_user_report?: boolean;
}

export type StateFormType = {
  risky_id: string;
  loading_date: string,
  warehouse: any,
  load_by: any,
  invoice_no: string,
  booking_no: string,
  commodity: any,
  container_type: any,
  container_no: string,
  seal_no: string,
  remark: string,
  model_and_qty : Array<ModelAndQty>,
  total_qty: number,
  container_condition_photo: Array<any>,
  other_document_photo : Array<any>,
}

export type ModelAndQty = {
  model: string;
  qty: number | string;
};

export const getInfoFromTemplate = (htmlString: string) => {
  let imageProductExample: string = "";
  let imageConditionContainer: string = "";
  let imageOtherDocument: string = "";
  let addTableAreaExample: string = "";
  let qtyTotalExample: string = "";
  let insertTableRowExample: string = "";

  const imageProductExampleArray = htmlString.split("image_product_example");
  if (imageProductExampleArray.length >= 3) {
    imageProductExample = imageProductExampleArray[1];
  }

  const containerImageExampleArray = htmlString.split("image_condition_eir");
  if (containerImageExampleArray.length >= 3) {
    imageConditionContainer = containerImageExampleArray[1];
  }

  const otherDocumentImageArray = htmlString.split("other_document");
  if (otherDocumentImageArray.length >= 3) {
    imageOtherDocument = otherDocumentImageArray[1];
  }

  const addTableAreaExampleArray = htmlString.split("add_table_example");
  if(addTableAreaExampleArray.length >= 3){
    addTableAreaExample = addTableAreaExampleArray[1];
  }

  const qtyTotalExampleArray = htmlString.split("qty_total");
  if(qtyTotalExampleArray.length >= 3){
    qtyTotalExample = qtyTotalExampleArray[1];
  }

  const insertTableRowExampleArray = htmlString.split("insert_table_row");
  if(insertTableRowExampleArray.length >= 3){
    insertTableRowExample = insertTableRowExampleArray[1];
  }

  return {
    imageProductExample,
    imageConditionContainer,
    imageOtherDocument,
    addTableAreaExample,
    qtyTotalExample,
    insertTableRowExample
  };
};


export const makeNewHtml = (
  newHtml: string,
  data: StateFormType,
  addTableAreaExample: string,
  qtyTotalExample: string,
  insertTableRowExample: string,
) => {
  let {
    loading_date, 
    warehouse,
    load_by,
    invoice_no,
    booking_no,
    commodity,
    container_type,
    container_no,
    seal_no,
    remark,
    model_and_qty,
    total_qty,
    container_condition_photo,
    other_document_photo,
  } = data;
 // Totalを除いて1ページで収まる時の項目数。
  const maxFirstPageItemCount = 90;
  // Totalを除いて2ページ目以降で収まる時の項目数。
  const maxBeyondFirstPageItemCount = 108;
  const ModelAndQtyLength = model_and_qty.length;
  let totalPageCount = 1;
  let currentPageCount = 1;

  const ModelAndQty = model_and_qty.slice()

  // 処理の複雑性回避のため、項目数が奇数個の場合+1項目追加する
  if(ModelAndQtyLength > 0 && ModelAndQtyLength % 2 !== 0){
    ModelAndQty.push({ model: "" , qty: ""});
  }

  if(container_condition_photo.length > 0){
    totalPageCount++
  }

  if(other_document_photo.length > 0){
    totalPageCount++
  }
  

  // 1ページ目の境界値 90 項目を取得。
  const modelsForFirstPage = ModelAndQty.slice().splice(0, maxFirstPageItemCount);
  // 2ページ目以降の境界値 108 項目でパーティション。
  const chunkedArray = _.chunk(ModelAndQty.slice(maxFirstPageItemCount), maxBeyondFirstPageItemCount);

  if(loading_date){
    newHtml = newHtml.replace("<!-- {loading_date} -->", loading_date ?? "");
  }

  if(warehouse){
    newHtml = newHtml.replace("<!-- {w_h} -->", warehouse.name ?? "");
  }

  if(load_by){
    newHtml = newHtml.replace("<!-- {loader} -->", load_by.name ?? "")
  }

  if(invoice_no){
    newHtml = newHtml.replace("<!-- {invoice_no} -->", invoice_no ?? "");
  }

  if(booking_no){
    newHtml = newHtml.replace("<!-- {booking_no} -->", booking_no ?? "");
  }

  if(commodity){
    newHtml = newHtml.replace("<!-- {commodity} -->", commodity.name ?? "");
  }

  if(container_type){
    newHtml = newHtml.replace("<!-- {container_type} -->", container_type.name ?? "");
  }
  
  if(container_no){
    newHtml = newHtml.replace("<!-- {container_no} -->", container_no ?? "");
  }

  if(seal_no){
    newHtml = newHtml.replace("<!-- {seal_no} -->", seal_no ?? "");
  }

  if(remark){
    newHtml = newHtml.replace("<!-- {remark} -->", remark ?? "");
  }


    // ======== ①固定枠の中身を作成 ここから ======
  if(modelsForFirstPage.length >= 0){
    const fixedDisplay = modelsForFirstPage.slice().splice(0, 18);
    newHtml = fixedDisplay.reduce((newHtml, current, index) => {    
      newHtml = newHtml.replace(`<!-- {model_name_${index+1}} -->`, current.model ?? "")
      newHtml = newHtml.replace(`<!-- {qty_${index+1}} -->`, current.qty.toString() ?? "");
      return newHtml;
    }, newHtml)

    // ======== ①固定枠の中身を作成 ここまで ======

    // ======== ②Modelのデータ+Totalを含んで1ページ目で収まるパターンの作成 ここから ======
    // maxFirstPageItemCount + Total分1行で90項目分の行数
    if(modelsForFirstPage.length <= maxFirstPageItemCount-2){
      const addModel =  modelsForFirstPage.slice(18);
      for(let i = 0; i < addModel.length; i += 2){
        // 項番19より後を流し込む
        const oddOrder = i + 19;
        const evenOrder = oddOrder + 1;
        let cloneInsertTableRowExample = insertTableRowExample;
        cloneInsertTableRowExample 
          = cloneInsertTableRowExample.replace(`{No_odd}`, oddOrder.toString() ?? "");
        cloneInsertTableRowExample
          = cloneInsertTableRowExample.replace(`{No_even}`, evenOrder.toString() ?? "");
        cloneInsertTableRowExample
          = cloneInsertTableRowExample.replace(`{model_name_odd}`, addModel[i].model ?? "");
        cloneInsertTableRowExample
          = cloneInsertTableRowExample.replace(`{model_name_even}`, addModel[i+1].model ?? "");
        cloneInsertTableRowExample
          = cloneInsertTableRowExample.replace(`{qty_odd}`, addModel[i].qty.toString() ?? "");
        cloneInsertTableRowExample
          = cloneInsertTableRowExample.replace(`{qty_even}`, addModel[i+1].qty.toString() ?? "");
        newHtml = newHtml.replace(
          "<!-- {more_table_row_area} -->",
          `
          ${cloneInsertTableRowExample}
          <!-- {more_table_row_area} -->
          `
        )
      }
      let qtyTotalExampleClone = qtyTotalExample;
      qtyTotalExampleClone = qtyTotalExampleClone.replace(`{sum_qty}`, total_qty.toString());
      newHtml = newHtml.replace(
        "<!-- {more_table_row_area} -->",
        `
        ${qtyTotalExampleClone}
        <!-- {more_table_row_area} -->
        `
      )
      newHtml = newHtml.replace("<!-- {current_page} -->", "1");
      newHtml = newHtml.replace("<!-- {total_page} -->", totalPageCount.toString());

      // ======== ②Modelのデータ+Totalを含んで1ページ目で収まるパターンの作成 ここまで ======
    }else{
        // ======== ③Modelのデータが境界値90項目)のパターンの作成(=>境界値をまたいで2ページ目先頭にTotalがくる) ここから ======
      const addModel =  modelsForFirstPage.slice(18);
      let addTableAreaExampleClone = addTableAreaExample;
      let qtyTotalExampleClone = qtyTotalExample;
      for(let i = 0; i < addModel.length; i += 2){
        const oddOrder = i + 19;
        const evenOrder = oddOrder + 1;
        let cloneInsertTableRowExample = insertTableRowExample;
        cloneInsertTableRowExample
          = cloneInsertTableRowExample.replace(`{No_odd}`, oddOrder.toString() ?? "");
        cloneInsertTableRowExample
          = cloneInsertTableRowExample.replace(`{No_even}`, evenOrder.toString() ?? "");
        cloneInsertTableRowExample
          = cloneInsertTableRowExample.replace(`{model_name_odd}`, addModel[i].model ?? "");
        cloneInsertTableRowExample
          = cloneInsertTableRowExample.replace(`{model_name_even}`, addModel[i+1].model ?? "");
        cloneInsertTableRowExample
          = cloneInsertTableRowExample.replace(`{qty_odd}`, addModel[i].qty.toString() ?? "");
        cloneInsertTableRowExample
          = cloneInsertTableRowExample.replace(`{qty_even}`, addModel[i+1].qty.toString() ?? "");

        newHtml = newHtml.replace(
          "<!-- {more_table_row_area} -->",
          `
          ${cloneInsertTableRowExample}
          <!-- {more_table_row_area} -->
          `
        )
      }
      if(chunkedArray.length < 1){
        // Modelの項目数が90項目(境界値) or それ以上かどうかを model_and_qtyのindex:96以降から114項目づつchunkした結果で分岐
        qtyTotalExampleClone = qtyTotalExampleClone.replace(`{sum_qty}`, total_qty.toString());

        addTableAreaExampleClone = addTableAreaExampleClone.replace(
          "{add_more_table_body}",
          `
          ${qtyTotalExampleClone}
          <!-- {totalPageCount} -->
          `
          )
        
        currentPageCount++
        totalPageCount++
        addTableAreaExampleClone = addTableAreaExampleClone.replace("{add_page_count}", `${currentPageCount}`);

  
        newHtml = newHtml.replace(
          "<!-- {add_more_area} -->",
          `
          ${addTableAreaExampleClone}
          <!-- {add_more_area} -->
          `
        )
        newHtml = newHtml.replace("<!-- {current_page} -->", "1");
        newHtml = newHtml.replace("<!-- {total_page} -->", `${totalPageCount}`)

      // ======== ③Modelのデータが境界値(90項目)のパターンの作成(=>境界値をまたいで2ページ目先頭にTotalがくる) ここまで ======
      }else{
      // ======== ④2ページ目以降の作成 ここから ======
        const chunkedArrayLength = chunkedArray.length;
          // 108項目でChunkした結果の配列の末尾が閾値の境界値となっているかのフラグ
        const isBoundaryMaxCount = chunkedArray[chunkedArrayLength-1].length === maxBeyondFirstPageItemCount;
        const fitOnePage = chunkedArray.slice(0, chunkedArrayLength-1);
         // 項番91以降の作成。
         // maxFirstPageItemCount+1
        if(fitOnePage.length > 0){
          let startNo = maxFirstPageItemCount+1;
          let pageCount = 0;
          for(const onePage of fitOnePage){
            let addTableAreaExampleClone = addTableAreaExample; 
            for(let i = 0; i < onePage.length; i += 2){
              let cloneInsertTableRowExample = insertTableRowExample;
              const oddNo = startNo + (maxBeyondFirstPageItemCount * pageCount) + i;
              const evenNo = oddNo + 1;

              cloneInsertTableRowExample
                = cloneInsertTableRowExample.replace(`{No_odd}`, oddNo.toString() ?? "");
              cloneInsertTableRowExample
                = cloneInsertTableRowExample.replace(`{No_even}`, evenNo.toString() ?? "");
              cloneInsertTableRowExample
                = cloneInsertTableRowExample.replace(`{model_name_odd}`, onePage[i].model ?? "");
              cloneInsertTableRowExample
                = cloneInsertTableRowExample.replace(`{model_name_even}`, onePage[i+1].model ?? "");
              cloneInsertTableRowExample
                = cloneInsertTableRowExample.replace(`{qty_odd}`, onePage[i].qty.toString() ?? "");
              cloneInsertTableRowExample
                = cloneInsertTableRowExample.replace(`{qty_even}`, onePage[i+1].qty.toString() ?? "");

              addTableAreaExampleClone = addTableAreaExampleClone.replace(
                "{add_more_table_body}",
                `
                ${cloneInsertTableRowExample}
                {add_more_table_body}
                `
                )
            }
            pageCount++

            currentPageCount++
            totalPageCount++
            addTableAreaExampleClone
              = addTableAreaExampleClone.replace("{add_page_count}", `${currentPageCount}`);
            addTableAreaExampleClone
              = addTableAreaExampleClone.replace( "{add_more_table_body}", "<!-- {tmp_delete} -->")
            newHtml = newHtml.replace(
              "<!-- {add_more_area} -->",
              `
              ${addTableAreaExampleClone}
              <!-- {add_more_area} -->
              `
            )
            newHtml = newHtml.replace("<!-- {current_page} -->", "1");
            addTableAreaExampleClone
              = addTableAreaExampleClone.replace( "<!-- {tmp_delete} -->", "{add_more_table_body}")

        }

        if(isBoundaryMaxCount){
          // TrueならchunkedArray[chunkedArray.length-1]の中身を流し込み + 次ページ先頭でTotalを入れる。
          const restModels = chunkedArray[chunkedArray.length-1];
          let addTableAreaExampleClone = addTableAreaExample;
          for(let i = 0; i < restModels.length; i += 2){
            const oddNo = maxFirstPageItemCount + ( maxBeyondFirstPageItemCount * pageCount ) + 1 + i
            const evenNo = oddNo + 1;
            let cloneInsertTableRowExample = insertTableRowExample;
            cloneInsertTableRowExample
              = cloneInsertTableRowExample.replace(`{No_odd}`, oddNo.toString() ?? "");
            cloneInsertTableRowExample
              = cloneInsertTableRowExample.replace(`{No_even}`, evenNo.toString() ?? "");
            cloneInsertTableRowExample
              = cloneInsertTableRowExample.replace(`{model_name_odd}`, restModels[i].model ?? "");
            cloneInsertTableRowExample  
              = cloneInsertTableRowExample.replace(`{model_name_even}`, restModels[i+1].model ?? "");
            cloneInsertTableRowExample
              = cloneInsertTableRowExample.replace(`{qty_odd}`, restModels[i].qty.toString() ?? "");
            cloneInsertTableRowExample
              = cloneInsertTableRowExample.replace(`{qty_even}`, restModels[i+1].qty.toString() ?? "");
            addTableAreaExampleClone = addTableAreaExampleClone.replace(
              "{add_more_table_body}",
              `
              ${cloneInsertTableRowExample}
              {add_more_table_body}
              `
              )
          }

          addTableAreaExampleClone
            = addTableAreaExampleClone.replace("{add_more_table_body}", "<!-- {comment_out} -->")
          currentPageCount++
          totalPageCount++
          addTableAreaExampleClone
            = addTableAreaExampleClone.replace("{add_page_count}", `${currentPageCount}`);
          newHtml = newHtml.replace(
            "<!-- {add_more_area} -->",
            `
            ${addTableAreaExampleClone}
            <!-- {add_more_area} -->
            `
          )

          let addTableAreaExampleCloneForNextPage = addTableAreaExample;
          let qtyTotalExampleClone = qtyTotalExample
          qtyTotalExampleClone
            = qtyTotalExampleClone.replace(`{sum_qty}`, total_qty.toString());
          addTableAreaExampleCloneForNextPage = addTableAreaExampleCloneForNextPage.replace(
            "{add_more_table_body}",
            `
            ${qtyTotalExampleClone}
            {add_more_table_body}
            `
          )
          newHtml = newHtml.replace("<!-- {current_page} -->", "1");
          currentPageCount++
          totalPageCount++
          addTableAreaExampleCloneForNextPage
            = addTableAreaExampleCloneForNextPage.replace("{add_page_count}", `${currentPageCount}`);
          addTableAreaExampleCloneForNextPage
            = addTableAreaExampleCloneForNextPage.replace("{add_more_table_body}", "<!-- {comment_out} -->")
          newHtml = newHtml.replace(
            "<!-- {add_more_area} -->",
            `
            ${addTableAreaExampleCloneForNextPage}
            <!-- {add_more_area} -->
            `
          )


        }else{
          // Falseなら次のページでchunkedArray[chunkedArray.length-1]の中身を流し込み + そのページでTotalを入れる。
          const restModels = chunkedArray[chunkedArray.length-1];
          let addTableAreaExampleClone = addTableAreaExample;
          for(let i = 0; i < restModels.length; i += 2){
            const oddNo = maxFirstPageItemCount + ( maxBeyondFirstPageItemCount * pageCount ) + 1 + i
            const evenNo = oddNo + 1;
            let cloneInsertTableRowExample = insertTableRowExample;
            cloneInsertTableRowExample
              = cloneInsertTableRowExample.replace(`{No_odd}`, oddNo.toString() ?? "");
            cloneInsertTableRowExample
              = cloneInsertTableRowExample.replace(`{No_even}`, evenNo.toString() ?? "");
            cloneInsertTableRowExample
              = cloneInsertTableRowExample.replace(`{model_name_odd}`, restModels[i].model ?? "");
            cloneInsertTableRowExample
              = cloneInsertTableRowExample.replace(`{model_name_even}`, restModels[i+1].model ?? "");
            cloneInsertTableRowExample
              = cloneInsertTableRowExample.replace(`{qty_odd}`, restModels[i].qty.toString() ?? "");
            cloneInsertTableRowExample
              = cloneInsertTableRowExample.replace(`{qty_even}`, restModels[i+1].qty.toString() ?? "");

            addTableAreaExampleClone = addTableAreaExampleClone.replace(
              "{add_more_table_body}",
              `
              ${cloneInsertTableRowExample}
              {add_more_table_body}
              `
              )
          }
          let qtyTotalExampleClone = qtyTotalExample;
          qtyTotalExampleClone = qtyTotalExampleClone.replace(`{sum_qty}`, total_qty.toString());
          addTableAreaExampleClone = addTableAreaExampleClone.replace(
            "{add_more_table_body}",
            `
            ${qtyTotalExampleClone}
            {add_more_table_body}
            `
          )
          addTableAreaExampleClone = addTableAreaExampleClone.replace( "{add_more_table_body}", "<!-- {tmp_delete} -->")
          currentPageCount++
          totalPageCount++
          addTableAreaExampleClone = addTableAreaExampleClone.replace("{add_page_count}", `${currentPageCount}`);
          newHtml = newHtml.replace(
            "<!-- {add_more_area} -->",
            `
            ${addTableAreaExampleClone}
            <!-- {add_more_area} -->
            `
          )
        }
      }else{
        // 2ページ目の作成。
        // 以降では2ページ目でTotalまで収まる or 3ページ目先頭にTotalが来るパターンで分岐する。
        const onePageModels = chunkedArray[0];
        let startNo = maxFirstPageItemCount+1;
        let addTableAreaExampleClone = addTableAreaExample;
        for(let i = 0; i < onePageModels.length; i += 2){
          let cloneInsertTableRowExample = insertTableRowExample;
          const oddNo = startNo + i;
          const evenNo = oddNo + 1;

          cloneInsertTableRowExample
            = cloneInsertTableRowExample.replace(`{No_odd}`, oddNo.toString() ?? "");
          cloneInsertTableRowExample
            = cloneInsertTableRowExample.replace(`{No_even}`, evenNo.toString() ?? "");
          cloneInsertTableRowExample
            = cloneInsertTableRowExample.replace(`{model_name_odd}`, onePageModels[i].model ?? "");
          cloneInsertTableRowExample
            = cloneInsertTableRowExample.replace(`{model_name_even}`, onePageModels[i+1].model ?? "");
          cloneInsertTableRowExample
            = cloneInsertTableRowExample.replace(`{qty_odd}`, onePageModels[i].qty.toString() ?? "");
          cloneInsertTableRowExample
            = cloneInsertTableRowExample.replace(`{qty_even}`, onePageModels[i+1].qty.toString() ?? "");

          addTableAreaExampleClone = addTableAreaExampleClone.replace(
            "{add_more_table_body}",
            `
            ${cloneInsertTableRowExample}
            {add_more_table_body}
            `
            )
        }
        currentPageCount = 2;
        totalPageCount++;
        addTableAreaExampleClone = addTableAreaExampleClone.replace("{add_page_count}", `${currentPageCount}`);

        if(!isBoundaryMaxCount){
          let qtyTotalExampleClone = qtyTotalExample;
          qtyTotalExampleClone = qtyTotalExampleClone.replace(`{sum_qty}`, total_qty.toString());
          addTableAreaExampleClone = addTableAreaExampleClone.replace(
            "{add_more_table_body}",
            `
            ${qtyTotalExampleClone}
            <!-- {add_more_table_body} -->
            `
          )
          newHtml = newHtml.replace("<!-- {current_page} -->", "1");
          addTableAreaExampleClone = addTableAreaExampleClone.replace("{add_page_count}", `${currentPageCount}`);
          newHtml = newHtml.replace(
            "<!-- {add_more_area} -->",
            `
            ${addTableAreaExampleClone}
            <!-- {add_more_area} -->
            `
          )
        }else{
          addTableAreaExampleClone = addTableAreaExampleClone.replace(`{add_more_table_body}`,"<!-- {comment_out} -->")
          newHtml = newHtml.replace(
            "<!-- {add_more_area} -->",
            `
            ${addTableAreaExampleClone}
            <!-- {add_more_area} -->
            `
          )
          let addTableAreaExampleCloneForNextPage = addTableAreaExample;
          let qtyTotalExampleClone = qtyTotalExample;
          qtyTotalExampleClone = qtyTotalExampleClone.replace(`{sum_qty}`, total_qty.toString());
          addTableAreaExampleCloneForNextPage = addTableAreaExampleCloneForNextPage.replace(
            "{add_more_table_body}",
            `
            ${qtyTotalExampleClone}
            <!-- {add_more_table_body} -->
            `
          )
          newHtml = newHtml.replace("<!-- {current_page} -->", "1");
          currentPageCount++
          totalPageCount++
          addTableAreaExampleCloneForNextPage
            = addTableAreaExampleCloneForNextPage.replace("{add_page_count}", `${currentPageCount}`);
          newHtml = newHtml.replace(
            "<!-- {add_more_area} -->",
            `
            ${addTableAreaExampleCloneForNextPage}
            <!-- {add_more_area} -->
            `
          )
        }
      }
    }
  }
}


newHtml = newHtml.replace("<!-- {total_page} -->",`${totalPageCount}`);
newHtml = newHtml.replaceAll("{total_page}",`${totalPageCount}`);
  
  
  return newHtml;
};

export const insertReportLocal = (
  newHtml: string,
  data: any,
  imageProductExample: string,
  imageConditionContainer: string,
  imageOtherDocument: string,
) => {
  let {
    container_condition_photo,
    other_document_photo
  } = data;

     // コメントアウトされているclass="page"分を差し引く
    let totalPageCount = (newHtml.match(/class="page"/g) || []).length - 3; 

    if(container_condition_photo?.length === 0 && other_document_photo.length === 0){
      return newHtml;
    }else{

      if(container_condition_photo.length > 0) {
        let pageImageExampleClone = imageConditionContainer;
        for(const container_condition_image of container_condition_photo){

          let imageProductExampleClone = imageProductExample;
          if(container_condition_image?.uri){
            imageProductExampleClone = imageProductExampleClone.replace(
              "{image_url}",
              `'${container_condition_image?.base64 ?
                container_condition_image?.base64 :
                container_condition_image?.uri_jpg ?? container_condition_image?.uri}'`,
            );
            pageImageExampleClone = pageImageExampleClone.replace(
              "{image_product_waiting}",
              imageProductExampleClone,
            );
          }else{
            pageImageExampleClone = pageImageExampleClone.replace(
              "{image_product_waiting}",
              "",
            );
          }
        }

        pageImageExampleClone = pageImageExampleClone.replaceAll(
          "{image_product_waiting}",
          "",
        );

        totalPageCount++
        pageImageExampleClone = pageImageExampleClone.replace("{image_condition_page}", `${totalPageCount}`)



        newHtml = newHtml.replace(
          "<!-- {add_more_area} -->",
          `
          ${pageImageExampleClone}
          <!-- {add_more_area} -->
        `,
        );

      }

      if(other_document_photo.length > 0){
        let pageImageExampleClone = imageOtherDocument;
        for(const other_document_photo_image of other_document_photo){

          let imageProductExampleClone = imageProductExample;
          if(other_document_photo_image?.uri){
            imageProductExampleClone = imageProductExampleClone.replace(
              "{image_url}",
              `'${other_document_photo_image?.base64 ?
                other_document_photo_image?.base64 :
                other_document_photo_image?.uri_jpg ?? other_document_photo_image?.uri}'`,
            );
            pageImageExampleClone = pageImageExampleClone.replace(
              "{image_product_waiting}",
              imageProductExampleClone,
            );
          }else{
            pageImageExampleClone = pageImageExampleClone.replace(
              "{image_product_waiting}",
              "",
            );
          }
        }

        pageImageExampleClone = pageImageExampleClone.replaceAll(
          "{image_product_waiting}",
          "",
        );
      totalPageCount++
      pageImageExampleClone = pageImageExampleClone.replace("{other_image_page}", `${totalPageCount}`)
      newHtml = newHtml.replace(
        "<!-- {add_more_area} -->",
        `
        ${pageImageExampleClone}
        <!-- {add_more_area} -->
      `,
      );
    }

  }

  newHtml = newHtml.replaceAll("{total_page}", `${totalPageCount}`)

  return newHtml;
}

export const getImageArray = async (data: Array<any>) => {
  let image_array: Array<any> = [];
  for (let index = 0; index < data.length; index++) {
    const item = data[index];
    const uri = item?.uri_jpg ?? item?.uri;
    if ((!item.base64 || item.base64.length === 0) && uri && uri.length > 0) {
      item.base64 = await getBase64Image(uri);
    }
    image_array.push(item);
  }
  return image_array;
};

export const mergeTemplateLoadingReport = async (
  templateUri: string,
  data: StateFormType,
) => {
  try {
    const f = await getFileByUrl(templateUri, "template.html", "text/html");
    let html_string = await f.text();
    const res = getInfoFromTemplate(html_string);
    const {
      addTableAreaExample,
      qtyTotalExample,
      insertTableRowExample,
      imageProductExample,
      imageConditionContainer,
      imageOtherDocument,
    } = res;
    html_string = makeNewHtml(
      html_string,
      data,
      addTableAreaExample,
      qtyTotalExample,
      insertTableRowExample
    );
    html_string = insertReportLocal(
      html_string,
      data,
      imageProductExample,
      imageConditionContainer,
      imageOtherDocument
    );
    const doc = document.createElement("div");
    doc.appendChild(
      document.createRange().createContextualFragment(html_string),
    );
    const html_blob = new Blob([html_string], { type: "text/html" });
    templateUri = createObjectURL(html_blob);
  } catch (err) {
    console.log(err);
    return null;
  }
  return templateUri;
};

export const handleEditInfoLoadingReport = (templateEditInfo: any) => {
  const newTemplateEditInfo = { ...templateEditInfo };
  return newTemplateEditInfo;
};
