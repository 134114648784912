import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  Table,
  TableBody,
  TablePagination,
  Checkbox,
  TableContainer,
  Collapse,
  IconButton,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { StandardCSSProperties } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { TYPES } from "store/types";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Colors } from "@template/style";

//-------------------------------------------------

const ROWS_PER_PAGE = [5, 10, 25] as const;

type Order = "asc" | "desc";
type TRowsPerPage = typeof ROWS_PER_PAGE[number];
export interface HeadCell {
  id: string;
  label: string;
  sortable: boolean;
  width: string | number;
  numeric?: boolean;
  disablePadding?: boolean;
  align?: "inherit" | "left" | "center" | "right" | "justify" | undefined;
  orderBy?: string; // [id]以外でソートする場合、ソートキーを指定
  isIgnoreExtractRuleCell?: boolean;
}

export interface ChildRowProps {
  rows: any[]; // テーブルデータ
  headCells: HeadCell[]; // テーブルヘッダー
  idName?: string;
  bgColor?: StandardCSSProperties["backgroundColor"];
  order?: Order;
  orderByIdName?: string;
  tableWidth?: StandardCSSProperties["width"];
}

interface TableHeadProps {
  headCells: HeadCell[];
  order: Order;
  orderBy: string;
  rowCount: number;
  numSelected: number;
  onRequestSort: (property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isCheckRow: boolean;
  hasChildRow: boolean;
  bgColor?: StandardCSSProperties["backgroundColor"];
}

//sort --------------------------------------------
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
//sort --------------------------------------------
//Header ------------------------------------------
const TableHeader = (prop: TableHeadProps) => {
  const {
    headCells,
    order,
    orderBy,
    rowCount,
    numSelected,
    onRequestSort,
    onSelectAllClick,
    isCheckRow,
    hasChildRow,
    bgColor = "white",
  } = prop;

  const handleSort = (property: string) => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {isCheckRow && (
          <TableCell padding="checkbox" sx={{ bgcolor: bgColor }}>
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        )}
        {hasChildRow && (
          <TableCell padding="checkbox" sx={{ bgcolor: bgColor }}></TableCell>
        )}
        {headCells.map((headCell) => {
          headCell.orderBy = headCell.orderBy ? headCell.orderBy : headCell.id;
          return (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.orderBy ? order : false}
              sx={{ minWidth: headCell.width, bgcolor: bgColor }}
            >
              {headCell.sortable ? (
                <TableSortLabel
                  active={orderBy === headCell.orderBy}
                  direction={orderBy === headCell.orderBy ? order : "asc"}
                  onClick={() => {
                    handleSort(headCell.id);
                  }}
                >
                  {headCell.label}
                  {orderBy === headCell.orderBy ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
//Header ------------------------------------------
//Row  --------------------------------------------
const SortableTableRow = ({
  row,
  idName,
  index,
  isCheckRow,
  headCells,
  buttons = [],
  childRow,
  handleClick = () => {},
}: {
  row: any;
  idName: string;
  index: number;
  isCheckRow: boolean;
  headCells: HeadCell[];
  buttons?: { cell_id: string; button: React.ReactChild; id: string }[];
  childRow?: ChildRowProps;
  handleClick?: (event: React.MouseEvent<unknown>, id: string) => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { check_list = [] } = useSelector(
    (state: RootState) => state.tableCustom,
  );

  useEffect(() => setOpen(false), [row]);

  const isSelected = (name: string) => check_list.indexOf(name) !== -1;

  const rowjson = JSON.parse(JSON.stringify(row));
  const id = rowjson[idName];
  const isItemSelected = isSelected(id);
  const labelId = `enhanced-table-checkbox-${index}`;
  const child = childRow
    ? childRow.rows.filter((value) => value && value[idName] === id)
    : [];

  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={(event) => handleClick(event, id)}
        role="checkbox"
        tabIndex={-1}
        key={index}
      >
        {isCheckRow && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                "aria-labelledby": labelId,
              }}
            />
          </TableCell>
        )}
        {childRow && (
          <TableCell padding="checkbox">
            {child.length > 0 && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                {open ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )}
          </TableCell>
        )}
        {headCells.map((headCell) => {
          const keyname = headCell.id;
          let value;
          if (keyname in rowjson) {
            value = rowjson[keyname];
            if (typeof value === "string" && value?.trim() === "undefined") {
              //undefinedが表示されないように修正
              value = undefined;
            }
          } else {
            const button = buttons.find(
              (item) => item.cell_id === keyname && item.id === id,
            );

            if (button) value = button.button;
          }

          if (headCell.numeric && value) {
            value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }

          const ignoreExtractRuleCell = headCell.isIgnoreExtractRuleCell
            ? "IgnoreExtractRuleTarget"
            : "";

          return (
            <TableCell
              key={headCell.id}
              align={
                headCell.align !== undefined
                  ? headCell.align
                  : headCell.numeric
                  ? "right"
                  : "left"
              }
              className={ignoreExtractRuleCell}
            >
              {Array.isArray(value)
                ? value.map((v, index) => <div key={index}>{v}</div>)
                : value ?? ""}
            </TableCell>
          );
        })}
      </TableRow>
      {childRow && child.length > 0 && (
        <TableRow
          sx={{
            height: open ? undefined : 0,
            bgcolor: childRow.bgColor ?? Colors.HEADER_COLOR_GREEN,
          }}
        >
          <TableCell
            sx={{
              height: open ? undefined : 0,
              border: open ? undefined : 0,
              p: open ? undefined : 0,
            }}
            colSpan={headCells.length + 1 + (isCheckRow ? 1 : 0)}
          >
            <ChildRowContent
              open={open}
              data={child}
              headCells={childRow.headCells || []}
              bgColor={childRow.bgColor}
              order_type={childRow.order}
              orderByIdName={childRow.orderByIdName}
              tableWidth={childRow.tableWidth}
              idName={childRow.idName}
            />
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};
//Row  --------------------------------------------
//ChildRowContent  ---------------------------------
const ChildRowContent = ({
  open,
  data,
  headCells,
  order_type = "asc",
  orderByIdName = "",
  idName = "",
  bgColor = Colors.HEADER_COLOR_GREEN,
  tableWidth,
}: {
  open: boolean;
  data: any[];
  headCells: HeadCell[];
  order_type?: Order;
  orderByIdName?: string;
  idName?: string;
  bgColor?: StandardCSSProperties["backgroundColor"];
  tableWidth?: StandardCSSProperties["width"];
}) => {
  const [order, setOrder] = useState<Order>(order_type);
  const [orderBy, setOrderBy] = useState<string>(orderByIdName);
  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  return (
    <Collapse in={open}>
      <Table sx={{ width: tableWidth }}>
        <TableHeader
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          numSelected={0}
          rowCount={data.length}
          onRequestSort={handleRequestSort}
          onSelectAllClick={() => {}}
          isCheckRow={false}
          hasChildRow={false}
          bgColor={bgColor}
        />
        <TableBody>
          {stableSort(data, getComparator(order, orderBy)).map((row, index) => {
            return (
              <SortableTableRow
                row={row}
                index={index}
                idName={idName}
                isCheckRow={false}
                headCells={headCells}
                key={index}
              />
            );
          })}
        </TableBody>
      </Table>
    </Collapse>
  );
};
//ChildRowContent  --------------------------------------------
//Table -------------------------------------------
interface SortableTableProps {
  rows: any[]; // テーブルデータ
  headCells: HeadCell[]; // テーブルヘッダー
  buttons?: { cell_id: string; button: React.ReactChild; id: string }[]; // ボタンデータ
  idName: string; // 一意になるデータのid
  order: Order;
  orderByIdName: string;
  page?: number; // ページ初期表示
  rowsPerPageDef?: TRowsPerPage; // ページあたりの行数の初期値
  isCheckRow?: boolean; // true:チェックボックス表示/false:チェックボックス非表示
  headCellsBgcolor?: StandardCSSProperties["backgroundColor"];
  maxHeight?: string | number;
  paginationActionsComponent?: React.ElementType<TablePaginationActionsProps>;
  onChangeRowsPerPage?: (
    rows_per_page: number,
    changed_rows_per_page: number,
  ) => void;
  childRow?: ChildRowProps;
}
export const SortableTable = forwardRef(function table(
  props: SortableTableProps,
  ref: any,
) {
  const {
    rows,
    headCells,
    buttons = [],
    idName,
    isCheckRow = false,
    rowsPerPageDef = 10,
    headCellsBgcolor,
    maxHeight = 440,
    page: props_page,
    paginationActionsComponent,
    onChangeRowsPerPage = () => {},
    childRow,
  } = props;
  const { check_list = [] } = useSelector(
    (state: RootState) => state.tableCustom,
  );
  const [order, setOrder] = useState<Order>(props.order);
  const [orderBy, setOrderBy] = useState<string>(props.orderByIdName);
  const [page, setPage] = useState<number>(props_page ? props_page : 0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageDef);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    getSortedRow: () => {
      return stableSort(rows, getComparator(order, orderBy));
    },
  }));

  useEffect(() => {
    setPage(props_page ?? 0);
  }, [props_page]);

  const numSelected = useMemo(() => {
    //rowsのidの要素と、check_listの要素を比較して、check_listh⊇すべてのrows.idであればAllCheckをtrueにする
    const rowsIds = rows.map((n: any) => {
      const _n = JSON.parse(JSON.stringify(n));
      return _n[idName];
    });
    return check_list.filter((id) => rowsIds.includes(id)).length;
  }, [rows, check_list]);

  const handleRequestSort = (property: string) => {
    const button = buttons.find((item) => item.cell_id === property);
    if (button) {
      const headCell = headCells.find((item) => item.id === property);
      if (headCell && headCell.orderBy) {
        property = headCell.orderBy;
      }
    }

    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    onChangeRowsPerPage(rowsPerPage, parseInt(event.target.value, 10));
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isCheckRow) return;
    const rowsIds = rows.map((n: any) => {
      const _n = JSON.parse(JSON.stringify(n));
      return _n[idName];
    });
    if (event.target.checked) {
      // 全選択
      const newSelecteds = [...rowsIds];
      if (check_list.length > 0) {
        check_list.forEach((el) => {
          if (!newSelecteds.includes(el)) {
            newSelecteds.push(el);
          }
        });
      }
      dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: newSelecteds });
    } else {
      // 全選択解除
      if (check_list.length > 0) {
        const filteredCheckList = check_list.filter(
          (el) => !rowsIds.includes(el),
        );
        dispatch({
          type: TYPES.SET_CHECK_TABLE,
          check_list: filteredCheckList,
        });
      }
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    if (!isCheckRow) return;
    const selectedIndex = check_list.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(check_list, name);
    } else {
      newSelected = newSelected.concat(
        check_list.slice(0, selectedIndex),
        check_list.slice(selectedIndex + 1),
      );
    }
    dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: newSelected });
  };

  useEffect(() => {
    // clear check list
    dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: [] });
  }, [dispatch]);

  return (
    <>
      <TableContainer sx={{ maxHeight: maxHeight }}>
        <Table stickyHeader size="medium">
          <TableHeader
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            numSelected={numSelected}
            rowCount={rows.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
            isCheckRow={isCheckRow}
            hasChildRow={childRow ? true : false}
            bgColor={headCellsBgcolor}
          />
          <TableBody sx={{ flexDirection: "column" }}>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <SortableTableRow
                    row={row}
                    index={index}
                    idName={idName}
                    buttons={buttons}
                    isCheckRow={isCheckRow}
                    headCells={headCells}
                    childRow={childRow}
                    handleClick={handleClick}
                    key={index}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[...ROWS_PER_PAGE]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ p: 0 }}
        style={{ flex: "none" }}
        ActionsComponent={paginationActionsComponent}
      />
    </>
  );
});
//Table -------------------------------------------
