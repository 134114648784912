import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  FormControl,
} from "@mui/material";
import _ from "lodash";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import LabelRequired from "components/atoms/LabelRequired";
import { Validation } from "@validation";
import formatDateToString from "@utils/DateFormat";

interface ILoadingRportEditScreen {
  data: IStateForm;
  open: boolean;
  onClose: () => void;
  onSave: (data: IStateForm) => void;
}

export interface IStateForm {
  loading_date: string;
  warehouse: string;
  loader: string;
  invoice_no: string;
  booking_no: string;
  commodity: string;
  container_type: string;
  container_no: string;
  seal_no: string;
  remark: string;
  model_name: string;
  qty: number | string;
}

const initalForm = {
  loading_date: "",
  warehouse: "",
  loader: "",
  invoice_no: "",
  booking_no: "",
  commodity: "",
  container_type: "",
  container_no: "",
  seal_no: "",
  remark: "",
  model_name: "",
  qty: "",
};

const LoadingRportEditScreen = ({
  data,
  open,
  onClose,
  onSave,
}: ILoadingRportEditScreen) => {
  const [stateForm, setStateForm] = useState<IStateForm>(initalForm);
  const [originData, setOriginData] = useState<IStateForm>(initalForm);
  const [formError, setFormError] = useState(initalForm);

  // 入力制御 ------------------------------------------------
  const onChangeText =
    (field: keyof IStateForm) => (newText: string | null) => {
      setStateForm({ ...stateForm, [field]: newText });
    };

  const onChangeDate =
    (field: keyof IStateForm) => (newValue: string | null) => {
      let displayValue = "";
      if (!newValue) {
        newValue = "";
      } else {
        newValue = formatDateToString(newValue, "YMD");
        displayValue = formatDateToString(newValue, "YMd");
      }
      const mess = Validation.validateDate(
        newValue ?? "",
        "Loading Date",
        true,
      );
      setFormError({ ...formError, [field]: mess });
      setStateForm((prev) =>
        prev ? { ...prev, [field]: displayValue } : prev,
      );
    };

  useEffect(() => {
    setOriginData(data);
    setStateForm(data);
    setFormError(initalForm);
  }, [data]);

  const hasUpdateData = useMemo(() => {
    return stateForm && originData && !_.isEqual(stateForm, originData);
  }, [stateForm, originData]);

  const disabledSaveBtn = useMemo(() => {
    const error_messages = Object.values(formError).filter(
      (item) => item.length > 0,
    );
    if (!hasUpdateData || error_messages.length > 0) return true;
    return false;
  }, [formError, hasUpdateData]);

  const onValidateText = (field: keyof IStateForm) => {
    const mess = validator(field, String(stateForm[field]));
    setFormError({ ...formError, [field]: mess });
  };

  const validator = (field: keyof IStateForm, value: string) => {
    let mess: string = "";
    switch (field) {
      case "loading_date":
        mess = Validation.validateDate(value, "Loading date", true);
        break;
      case "warehouse":
        mess = Validation.validate({
          type: "text",
          name: "Warehouse",
          value: value,
          max_length: 16,
        });
        break;
      case "loader":
        mess = Validation.validate({
          type: "text",
          name: "Loader",
          value: value,
          max_length: 40,
        });
        break;
      case "invoice_no":
        mess = Validation.validate({
          type: "text",
          name: "Invoice No.",
          value: value,
          required: true,
          max_length: 30,
        });
        break;
      case "booking_no":
        mess = Validation.validate({
          type: "number",
          name: "Booking No.",
          value: value,
          max_length: 30,
        });
        break;
      case "commodity":
        mess = Validation.validate({
          type: "text",
          name: "Commodity",
          value: value,
          max_length: 30,
        });
        break;
      case "container_type":
        mess = Validation.validate({
          type: "text",
          name: "Container type",
          value: value,
          max_length: 6,
        });
        break;
      case "container_no":
        mess = Validation.validate({
          type: "text",
          name: "Container No.",
          value: value,
          required: true,
          max_length: 12,
        });
        break;
      case "seal_no":
        mess = Validation.validate({
          type: "text",
          name: "Seal No.",
          value: value,
          max_length: 12,
        });
        break;
      case "remark":
        mess = Validation.validate({
          type: "text",
          name: "Remark",
          value: value,
          max_length: 90,
        });
        break;
      case "model_name":
        mess = Validation.validate({
          type: "text",
          name: "Model name",
          value: value,
          required: true,
          max_length: 30,
        });
        break;
      case "qty":
        mess = Validation.validate({
          type: "number",
          name: "Quantity",
          value: value,
          required: true,
          max_length: 6,
        });
        break;
    }
    return mess;
  };

  return (
    <Dialog
      maxWidth="lg"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <Stack>
          <FormControl>
            <DatePickerCustom
              label={<LabelRequired title="Loading Date(YYYY/MM/DD)" />}
              value={stateForm?.loading_date || null}
              onChange={onChangeDate("loading_date")}
              helperText={formError.loading_date}
              error={formError.loading_date.length > 0}
            />
          </FormControl>
          <TextField
            label={"Warehouse"}
            value={stateForm?.warehouse}
            name="warehouse"
            onChange={(e) => onChangeText("warehouse")(e.target.value)}
            onBlur={() => onValidateText("warehouse")}
            error={formError.warehouse.length > 0}
            helperText={formError.warehouse}
            inputProps={{
              maxLength: 16,
            }}
          />
          <TextField
            label={"Load by"}
            value={stateForm?.loader}
            name="loader"
            onChange={(e) => onChangeText("loader")(e.target.value)}
            onBlur={() => onValidateText("loader")}
            error={formError.loader.length > 0}
            helperText={formError.loader}
            inputProps={{
              maxLength: 40,
            }}
          />
          <TextField
            label={<LabelRequired title="Invoice No." />}
            value={stateForm?.invoice_no}
            name="invoice_no"
            onChange={(e) => onChangeText("invoice_no")(e.target.value)}
            onBlur={() => onValidateText("invoice_no")}
            error={formError.invoice_no.length > 0}
            helperText={formError.invoice_no}
            inputProps={{
              maxLength: 30,
            }}
          />
          <TextField
            label={"Booking No."}
            value={stateForm?.booking_no}
            name="booking_no"
            onChange={(e) => onChangeText("booking_no")(e.target.value)}
            onBlur={() => onValidateText("booking_no")}
            error={formError.booking_no.length > 0}
            helperText={formError.booking_no}
            inputProps={{
              maxLength: 30,
            }}
          />
          <TextField
            label={"Commodity"}
            value={stateForm?.commodity}
            name="commodity"
            onChange={(e) => onChangeText("commodity")(e.target.value)}
            onBlur={() => onValidateText("commodity")}
            error={formError.commodity.length > 0}
            helperText={formError.commodity}
            inputProps={{
              maxLength: 30,
            }}
          />
          <TextField
            label={"Container type"}
            value={stateForm?.container_type}
            name="container_type"
            onChange={(e) => onChangeText("container_type")(e.target.value)}
            onBlur={() => onValidateText("container_type")}
            error={formError.container_type.length > 0}
            helperText={formError.container_type}
            inputProps={{
              maxLength: 6,
            }}
          />
          <TextField
            label={<LabelRequired title={"Container No."} />}
            value={stateForm?.container_no}
            name="container_no"
            onChange={(e) => onChangeText("container_no")(e.target.value)}
            onBlur={() => onValidateText("container_no")}
            error={formError.container_no.length > 0}
            helperText={formError.container_no}
            inputProps={{
              maxLength: 12,
            }}
          />
          <TextField
            label={"Seal No."}
            value={stateForm?.seal_no}
            name="seal_no"
            onChange={(e) => onChangeText("seal_no")(e.target.value)}
            onBlur={() => onValidateText("seal_no")}
            error={formError.seal_no.length > 0}
            helperText={formError.seal_no}
            inputProps={{
              maxLength: 12,
            }}
          />
          <TextField
            label={"Remark"}
            value={stateForm?.remark}
            name="remark"
            onChange={(e) => onChangeText("remark")(e.target.value)}
            onBlur={() => onValidateText("remark")}
            error={formError.remark.length > 0}
            helperText={formError.remark}
            inputProps={{
              maxLength: 90,
            }}
          />
          <TextField
            label={<LabelRequired title={"Model Name"} />}
            value={stateForm?.model_name}
            name="model_name"
            onChange={(e) => onChangeText("model_name")(e.target.value)}
            onBlur={() => onValidateText("model_name")}
            error={formError.model_name.length > 0}
            helperText={formError.model_name}
            inputProps={{
              maxLength: 30,
            }}
          />
          <TextField
            label={<LabelRequired title={"Quantity"} />}
            value={stateForm?.qty}
            name="qty"
            onChange={(e) => onChangeText("qty")(e.target.value)}
            onBlur={() => onValidateText("qty")}
            error={formError.qty.length > 0}
            helperText={formError.qty}
            inputProps={{
              maxLength: 6,
            }}
          />
          <Box sx={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                onSave({
                  ...stateForm,
                  loading_date: formatDateToString(
                    stateForm.loading_date,
                    "YMD_sl",
                  ),
                });
              }}
              disabled={disabledSaveBtn}
              color="secondary"
            >
              保存
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingRportEditScreen;
