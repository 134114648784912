import {
  METHOD_API,
  createConnector,
  getParamsHelp,
  prefixApi,
} from "./Connector";

export const getContractInfoApi = (contract_id?: string) => {
  let url_params = "";
  if (contract_id !== undefined) {
    const params = { contract_id: contract_id };
    url_params = `?${getParamsHelp(params)}`;
  }
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/contract-usage-info${url_params}`,
    null
  );
};

export const getContractApi = (contract_id?: string) => {
  let url_params = "";
  if (contract_id !== undefined) {
    const params = { contract_id: contract_id };
    url_params = `?${getParamsHelp(params)}`;
  }
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/contract${url_params}`,
    null
  );
};

export const getContractInfoListApi = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get_contract_info`,
    null,
  );
};