import React, { VFC, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  TextField,
} from "@mui/material";
import { Validation } from "@validation";
import LabelRequired from "components/atoms/LabelRequired";
import CheckboxLabel from "components/atoms/CheckboxLabel";

export interface IModelNameMasterData {
  name: string;
  code: string;
  isDefault: boolean;
}

interface IErrorModelNameMasterData {
  name: string;
  code: string;
}

interface IProps {
  data: IModelNameMasterData;
  open: boolean;
  onClose: Function;
  onChange: Function;
}

const TlgtMasterModelNameEditDialog: VFC<IProps> = ({
  data,
  open,
  onClose,
  onChange,
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [errorText, setErrorText] = useState<IErrorModelNameMasterData>({
    name: "",
    code: "",
  });
  const [disabled, setDisabled] = useState(true);
  const [isDefault, setIsDefault] = useState<boolean>(false);

  useEffect(() => {
    const setInitialData = async () => {
      setCode(data.code);
      setName(data.name);
      setIsDefault(data.isDefault)
      setErrorText({ name: "", code: "" });
      setDisabled(true);
    };

    if (open) {
      setInitialData();
    }
  }, [open, data]);

  // ------------------------------------------------------------------
  // 入力
  // ------------------------------------------------------------------
  const changeModelName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const changeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const validName = () => {
    const error_text = Validation.validate({
      type: "text",
      name: "Model Name",
      value: name,
      required: true,
      max_length: 80,
    });
    setErrorText((prev) => ({ ...prev, name: error_text }));
    setDisabled(
      error_text.length > 0 ||
      errorText.code.length > 0 ||
      code === "" ||
      (data.name === name && data.code === code)
    );
  };

  const validCode = () => {
    const error_text = Validation.validate({
      type: "text",
      name: "Code",
      value: code,
      required: true,
      max_length: 18,
    });
    setErrorText((prev) => ({ ...prev, code: error_text }));
    setDisabled(
      error_text.length > 0 ||
      errorText.name.length > 0 ||
      name === "" ||
      (data.name === name && data.code === code)
    );
  };

  const checkIsDisabled = (is_default: boolean) => {
    const error_name_text = Validation.validate({
      type: "text",
      name: "名称",
      value: name,
      required: true,
      max_length: 80,
    });
    const error_code_text = Validation.validate({
      type: "text",
      name: "Code",
      value: code,
      required: true,
      max_length: 13,
    });
    if (!(error_code_text.length || error_name_text.length)) {
      setDisabled(data.isDefault === is_default);
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <FormGroup>
          <TextField
            label={
              <>
                <LabelRequired title={"Code"} />
              </>
            }
            value={code}
            onChange={changeCode}
            onBlur={validCode}
            error={errorText.code.length > 0}
            helperText={errorText.code}
            inputProps={{
              maxLength: 80,
            }}
          />
          <TextField
            label={
              <>
                <LabelRequired title={"Model Name"} />
              </>
            }
            value={name}
            onChange={changeModelName}
            onBlur={validName}
            error={errorText.name.length > 0}
            helperText={errorText.name}
            inputProps={{
              maxLength: 80,
            }}
          />
          <div>
            <CheckboxLabel
              label="デフォルト値"
              checked={isDefault}
              onChange={(e) => {
                setIsDefault(e.target.checked);
                checkIsDisabled(e.target.checked)
              }}
            />
          </div>
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
        >
          キャンセル
        </Button>

        <Button
          onClick={() => {
            const response: IModelNameMasterData = {
              name: name,
              code: code,
              isDefault: isDefault
            };
            onChange(response);
            onClose();
          }}
          disabled={disabled}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TlgtMasterModelNameEditDialog;
