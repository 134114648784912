import React, { VFC, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  TextField,
  Stack,
} from "@mui/material";
import { useEffect } from "react";
import { Validation } from "@validation";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import LabelRequired from "components/atoms/LabelRequired";

export interface IData {
  type: string;
  name: string;
  jan_code: string;
  is_manual_input: boolean;
  is_default?: boolean;
}

interface IProps {
  data: IData;
  open: boolean;
  onChange: Function;
  onClose: Function;
}

const ProductEditDialog: VFC<IProps> = ({
  data,
  open,
  onChange,
  onClose,
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [name, setName] = useState("");
  const [jan_code, setJanCodeValue] = useState("");
  const [errorTextProduct, setErrorTextProduct] = useState("");
  const [errorTextJan, setErrorTextJan] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [type, setType] = useState("");
  const [isManualInput, setIsManualInput] = useState(false);
  const [isDefault, setIsDefault] = useState<boolean>(false);

  useEffect(() => {
    setJanCodeValue(data.jan_code);
    setName(data.name);
    setType(data.type);
    setIsManualInput(data.is_manual_input);
    setIsDefault(data?.is_default ?? false);
    setErrorTextProduct("");
    setErrorTextJan("");
  }, [open, data]);

  // ------------------------------------------------------------------
  // 入力
  // ------------------------------------------------------------------
  const changeJanCodeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJanCodeValue(e.target.value);
  };

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const validName = () => {
    const error_text = Validation.validate({
      type: "text",
      name: type,
      value: name,
      required: true,
      max_length: 50,
    });
    setErrorTextProduct(error_text);
    setDisabled(
      error_text.length > 0 || errorTextJan.length > 0 || jan_code.length == 0,
    );
  };

  const validJanCodeValue = () => {
    const error_text = Validation.validate({
      type: "alpha_num",
      name: "JANコード",
      value: jan_code,
      required: true,
      max_length: 13,
    });
    setErrorTextJan(error_text);
    setDisabled(
      error_text.length > 0 || errorTextProduct.length > 0 || name.length == 0,
    );
  };

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <FormGroup>
          <Stack>
            <TextField
              label={
                <>
                  <LabelRequired title={type} />
                </>
              }
              name="report_type_name"
              value={name}
              onChange={changeName}
              onBlur={validName}
              error={errorTextProduct.length > 0}
              helperText={errorTextProduct}
              inputProps={{
                maxLength: 50,
              }}
            />
            <TextField
              label={
                <>
                  <LabelRequired title={"JANコード"} />
                </>
              }
              name="jan_code"
              value={jan_code}
              onChange={changeJanCodeValue}
              onBlur={validJanCodeValue}
              error={errorTextJan.length > 0}
              helperText={errorTextJan}
              inputProps={{
                maxLength: 13,
              }}
              sx={{
                "& .MuiInputBase-formControl": {
                  width: "160px",
                },
              }}
            />
          </Stack>
          <CheckboxLabel
            label="手入力有り"
            checked={isManualInput}
            onChange={(e) => {
              setIsManualInput(e.target.checked);
              setDisabled(
                errorTextJan.length > 0 ||
                  errorTextProduct.length > 0 ||
                  name.length === 0 ||
                  jan_code.length === 0,
              );
            }}
            formSx={{ pt: 2 }}
          />
          <CheckboxLabel
            label="デフォルト値"
            checked={isDefault}
            onChange={(e) => {
              setIsDefault(e.target.checked);
              setDisabled(
                errorTextJan.length > 0 ||
                  errorTextProduct.length > 0 ||
                  name.length == 0 ||
                  jan_code.length === 0,
              );
            }}
            // formSx={{ pt: 2 }}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            onClose();
            setDisabled(true);
          }}
          variant="outlined"
        >
          キャンセル
        </Button>

        <Button
          onClick={() => {
            const response: IData = {
              type: type,
              name: name,
              jan_code: jan_code,
              is_manual_input: isManualInput,
              is_default: isDefault,
            };
            onChange(response);
            onClose();
            setDisabled(true);
          }}
          disabled={disabled}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductEditDialog;
