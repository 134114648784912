import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import {
  BUTTON_EDIT_TEMPLATE_COMPLETE,
  TYPE_USER_REPORT,
  screenIdSupport,
} from "@shared-constants";
import TemplateSelectBox from "components/molecules/TemplateSelectBox";
import styles from "./styles";
import EditTemplate from "screens/EditTemplate";
import { ICreateReportStateForm } from ".";

interface IProps {
  stateForm: ICreateReportStateForm;
  template: any;
  setStateForm: any;
  riskyId: string;
  errorMessage: string[];
  userType: string;
  work_id: string;
  step: number;
}

const SelectFileReport: React.VFC<IProps> = ({
  stateForm,
  setStateForm,
  template,
  riskyId,
  errorMessage,
  userType,
  work_id,
  step,
}: IProps) => {
  const [openTemplate, setOpenTemplate] = useState(false);
  const [errorMessageList, setErrorMessageList] = useState(errorMessage);

  useEffect(() => {
    if (!openTemplate) setErrorMessageList([]);
  }, [openTemplate]);

  useEffect(() => {
    setErrorMessageList(errorMessage);
  }, [errorMessage]);

  return (
    <>
      <Card>
        <CardHeader title="内容" sx={styles.header} />
        <CardContent>
          {/* ============= テンプレート ============= */}
          <TemplateSelectBox
            template={template}
            onClick={(ticketing_start_time:string = "") => {
              setStateForm((prev: any) => {
                if(prev.ticketing_start_time === ""){
                  return {...prev, ticketing_start_time: ticketing_start_time }
                }
                return prev;
              })
              setOpenTemplate(true);
            }}
            buttonType={stateForm?.is_first_create ? "create" : "edit"}
            helperText={errorMessageList.map((v, i) => (
              <React.Fragment key={i}>
                <span>{v}</span>
                <br />
              </React.Fragment>
            ))}
            error={errorMessageList.length > 0}
          />
        </CardContent>
        <EditTemplate
          open={openTemplate}
          setOpen={setOpenTemplate}
          type={screenIdSupport[template?.screen_id]}
          infoToEdit={
            stateForm?.template_file?.templateEditInfo
              ? {
                  ...stateForm?.template_file?.templateEditInfo,
                  risky_id: riskyId,
                }
              : {
                  risky_id: riskyId,
                }
          }
          template_path={template?.template_path}
          onHandleTemplate={(newTemplate, template_image) => {
            setStateForm((prev: any) => ({
              ...prev,
              template_file: {
                ...newTemplate,
              },
            }));
            template.template_image = template_image;
          }}
          isManage={userType === TYPE_USER_REPORT.APPROVE}
          completeBtnTitle={
            stateForm?.is_first_create
              ? BUTTON_EDIT_TEMPLATE_COMPLETE.CREATE
              : BUTTON_EDIT_TEMPLATE_COMPLETE.EDIT
          }
          template_id={template?.SK}
          work_id={work_id}
          is_hand_over={stateForm.is_hand_over}
          data_info={stateForm?.templateInfo?.data_info ?? template?.data_info}
          is_landscape={template?.is_landscape}
          step={step}
          created_by=""
          project_id={stateForm.project_id}
          isMultiple={template?.is_multiple}
          extraPage={
            stateForm?.templateInfo?.extra_template ?? template?.extra_template ?? []
          }
        />
      </Card>
    </>
  );
};

export default SelectFileReport;
