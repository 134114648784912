import { METHOD_API, createConnector, getParamsHelp, prefixApi } from "./Connector";
interface IDataUpdateMaster {
  type: string;
  name: string;
  color: string;
  color_id: string;
  color_name: string;
  index: string;
  parent: string;
  activity_base_id: string;
}

// 登録
export const insertMaster = (dataBody: IDataUpdateMaster) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/master`,
    dataBody,
  );
};

// 更新
export const updateMaster = (dataBody: IDataUpdateMaster, SK: string) => {
  return createConnector(
    METHOD_API.PUT,
    `${prefixApi}/master/${encodeURIComponent(SK)}`,
    dataBody,
  );
};

// CSV取込
export const importMaster = (dataBody: any) => {
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/master/import`,
    dataBody,
  );
};

// マスタ出力
export const exportMaster = (dataBody: any) => {
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/master/export`,
    dataBody,
  );
};

export const getM1 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m1`,
    null,
  );
};

export const getM2 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m2`,
    null,
  );
};

export const getM3 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m3`,
    null,
  );
};

export const getM4 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m4`,
    null,
  );
};

export const getM5 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m5`,
    null,
  );
};

export const getM6 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m6`,
    null,
  );
};

export const getM7 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m7`,
    null,
  );
};

export const getM8 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m8`,
    null,
  );
};

export const getM9 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m9`,
    null,
  );
};

export const getM10 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m10`,
    null,
  );
};

export const getM11 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m11`,
    null,
  );
};


export const getM12 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m12`,
    null,
  );
};


export const getM13 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m13`,
    null,
  );
};


export const getM14 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m14`,
    null,
  );
};


export const getM15 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m15`,
    null,
  );
};


export const getM16 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m16`,
    null,
  );
};

export const getM17 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m17`,
    null,
  );
};



export const getM18 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m18`,
    null,
  );
};


export const getM19 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m19`,
    null,
  );
};


export const getM20 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m20`,
    null,
  );
};

export const getM21 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m21`,
    null,
  );
};

export const getM22 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m22`,
    null,
  );
};


export const getM23 = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/m23`,
    null,
  );
};

export const getResponsibility = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/responsibility`,
    null,
  );
};

export const getDepartmentOfPIC = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/department_of_pic`,
    null,
  );
};

export const getProcessInLogistics = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/process_in_logistics`,
    null,
  );
};

export const getResponsibilityInProcess = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/responsibility_in_process`,
    null,
  );
};


export const getClassificationOfNonconformity = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/classification_of_nonconformity`,
    null,
  );
};

export const getClassificationOfCause = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/classification_of_cause`,
    null,
  );
};

export const getMAMaster = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/tlgt/ma_master`,
    null,
  );
};

export const getModelNameMaster = (params:
  { jan_code?: string, last_key_PK?: string, last_key_SK?: string, masterExtra?: string, seq?: string } = {},
) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/setting/model_name?${getParamsHelp(params)}`,
    null,
  );
};