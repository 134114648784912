import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormGroup,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  ButtonGroup,
} from "@mui/material";
import { RootState } from "store/reducer";
import messages from "config/messages";
import LabelValueText from "components/atoms/LabelValueText";
import { Template, User } from "services/models";
import { getUserInfo } from "@utils/index";
import SelectFileReport from "./SelectFileReport";
import styles from "./styles";
import ModalApproveHistory from "screens/WorkConfirmTlog/ModalApproveHistory";
import { FlowInfo } from "services/models/models";
import { getListApproveApi, getListFlowApi } from "@api/template";
import { renderUserFlowCreateTlog } from "components/atoms/StatusUserFlow/StatusUserFlowCreateTlog";
import { getProjectLocationIdStore } from "selector/projectSelector";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { useSelector } from "react-redux";
import ModalSearchUser from "components/molecules/ModalSearchUser";
import { screenIdSupportCheckRequiredFieldTemplate } from "../templateFunctionSupport";
import { TEMPLATE_EDIT_TYPE, screenIdSupport } from "@shared-constants";

const REPORTER_STEP = 0;

export interface ICreateReportStateForm {
  work_name: string;
  category_id: string;
  memo: string;
  work_template_id: string;
  template_file: {
    type: string;
    uri: string;
    name: string;
    templateEditInfo: any;
  };
  templateInfo?: {
    name: string;
    file: string;
    screen_id?: string | "default";
    data_info?: any;
    extra_template?: any;
  };
  work_status: string;
  work_start_date: string;
  work_end_date: string;
  work_inprogress_id?: "";
  template_work_sk?: string;
  is_first_create?: boolean;
  is_hand_over?: boolean;
  project_id: string;
  rejected_in_step?: string;
  ticketing_start_time?: string,
  application_time?: string,
}

export const FlowType = {
  DAILY: "DAILY",
  FINAL: "FINAL",
};

interface IProps {
  riskyId: string;
  stateForm: ICreateReportStateForm;
  setStateForm: Function;
  template?: Template;
  workId: string | null;
  is_inactive: boolean;
  userType: string;
  version: number;
}

export const getUserIdFromSK = (SK: string) => {
  let listId: string[] = SK.split("|");
  return listId[listId.length - 1];
};

export const getListUserStepByFlowApprove = (
  flowApprove: Type.FlowDataType,
) => {
  return flowApprove.map((itemFlow) => {
    return itemFlow.data_step.map((itemStep) => {
      return {
        ...itemStep,
        SK: itemStep.user_approver_id,
        full_name: itemStep.full_name,
        avatar: itemStep.avatar,
        isDefault: true,
      };
    });
  });
};

const FormContextReport = (
  {
    riskyId,
    stateForm,
    setStateForm,
    template,
    workId,
    is_inactive,
    userType,
    version,
  }: IProps,
  ref?: React.Ref<any>,
) => {
  const location_id = useSelector(getProjectLocationIdStore);
  const [userInfo, setUserInfo] = useState<User>();
  const [formError, setFormError] = useState<{
    list_approve: Array<string>;
    template: Array<string>;
  }>({
    list_approve: [],
    template: [],
  });

  const [listFlow, setListFlow] = useState<FlowInfo[] | undefined>(undefined);
  const [flowSK, setFlowSK] = useState<string>("");
  const [flow, setFlow] = useState<FlowInfo>();
  const [dailyFlow, setDailyFlow] = useState<FlowInfo>();
  const [finalFlow, setFinalFlow] = useState<FlowInfo>();
  const [dailyListFlow, setDailyListFlow] = useState<FlowInfo[] | undefined>(
    undefined,
  );
  const [finalListFlow, setFinalListFlow] = useState<FlowInfo[] | undefined>(
    undefined,
  );
  const [showFlow, setShowFlow] = useState<keyof typeof FlowType>("DAILY");
  const [listApprove, setListApprove] = useState<Type.FlowDataType>();
  const [listApproveDefault, setListApproveDefault] =
    useState<Type.FlowDataType>();
  const [listUserStep, setListUserStep] = useState<
    Array<Array<Type.DataStepUserType>>
  >([]);

  const [listDailyApprove, setListDailyApprove] = useState<Type.FlowDataType>();
  const [listDailyApproveDefault, setListDailyApproveDefault] =
    useState<Type.FlowDataType>();
  const [listDailyUserStep, setListDailyUserStep] = useState<
    Array<Array<Type.DataStepUserType>>
  >([]);

  const [listFinalApprove, setListFinalApprove] = useState<Type.FlowDataType>();
  const [listFinalApproveDefault, setListFinalApproveDefault] =
    useState<Type.FlowDataType>();
  const [listFinalUserStep, setListFinalUserStep] = useState<
    Array<Array<Type.DataStepUserType>>
  >([]);

  const [indexStep, setIndexStep] = useState<number>(0);
  const [visibleUser, setVisibleUser] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User[]>([]);
  const [selectedU, setSelectedU] = useState<User[]>(selectedUser);
  const is_daily = useSelector(
    (state: RootState) => state.projectInfo.is_daily,
  );

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const userInfoStorage = getUserInfo();
    setUserInfo(userInfoStorage);
  }, []);

  const checkNumberUserStepFlow = () => {
    let isError = false;
    let list_approve_error: Array<string> = [];
    if (listApprove) {
      for (let n = 0; n < listApprove.length; n++) {
        const { index, data_max_user } = listApprove[n];
        if (listUserStep[index]?.length === 0) {
          list_approve_error.push("承認ステップに承認者を追加してください");
          isError = true;
          break;
        }
        if (listUserStep[index]?.length < data_max_user?.max_user) {
          list_approve_error.push(
            `承認ステップ${index + 1}の承認者が不足しています`,
          );
          isError = true;
        }
      }
    }
    setFormError((prev) => ({ ...prev, list_approve: list_approve_error }));

    return isError;
  };

  const checkRequiredFieldTemplate = (
    screenId: string,
    templateEditInfo: any,
  ) => {
    const functionRequiredFieldTemplate =
      screenIdSupportCheckRequiredFieldTemplate[screenId];

    let isError = false;
    let message: string[] = [];

    if (functionRequiredFieldTemplate === undefined) {
      return false;
    }

    if (templateEditInfo === null) {
      message.push(messages.WORK_ITEM.MSG_CONFIRM_REQUIRED_TEMPLATE);
      setFormError((prev) => ({ ...prev, template: message }));
      return true;
    }

    const result = functionRequiredFieldTemplate(
      templateEditInfo,
      stateForm.templateInfo ?? template,
      getStepApprove(),
      false, //差戻のときtrue
    );
    isError = result.isError;
    message = result.message;
    setFormError((prev) => ({ ...prev, template: message }));
    return isError;
  };

  const validationAllCheck = () => {
    let response = true;

    let check_user_step = checkNumberUserStepFlow();
    let check_template = checkRequiredFieldTemplate(
      template?.screen_id ?? "",
      stateForm?.template_file?.templateEditInfo,
    );

    response = check_user_step || check_template;
    return response;
  };

  useImperativeHandle(ref, () => ({
    formError: formError,
    validationAllCheck: () => validationAllCheck(),
    changeListApprove: (
      flow_approve_data: Type.FlowDataType,
      flow_sk: string,
    ) => {
      setListApproveAndUserStep(flow_approve_data, showFlow);
      setFlowSK(flow_sk);
    },
    listApprove: listApprove,
    listUserStep: listUserStep,
    listApproveDefault: listApproveDefault,
    flow: flow,

    listDailyApprove: listDailyApprove,
    listDailyUserStep: listDailyUserStep,
    listDailyApproveDefault: listDailyApproveDefault,
    dailyFlow: dailyFlow,

    listFinalApprove: listFinalApprove,
    listFinalUserStep: listFinalUserStep,
    listFinalApproveDefault: listFinalApproveDefault,
    finalFlow: finalFlow,
  }));

  // 承認フロー
  const getListFlow = async (flow_type?: string) => {
    try {
      if (template) {
        let param = {
          template_id: template.SK,
          flow_type: flow_type ?? "",
        };
        const res: FlowInfo[] = await getListFlowApi(param);
        if (showFlow === flow_type || flow_type === undefined) setListFlow(res);
        switch (flow_type) {
          case FlowType.DAILY:
            setDailyListFlow(res);
            break;
          case FlowType.FINAL:
            setFinalListFlow(res);
            break;
        }
        if (res.length > 0) {
          if (flowSK) {
            const flow_selected = res.find((item) => item.SK === flowSK);
            if (
              (flow_selected && showFlow === flow_type) ||
              (flow_selected && flow_type === undefined)
            )
              setFlow(flow_selected);
            switch (flow_type) {
              case FlowType.DAILY:
                setDailyFlow(flow_selected);
                break;
              case FlowType.FINAL:
                setFinalFlow(flow_selected);
                break;
            }
          } else {
            if (showFlow === flow_type || flow_type === undefined)
              setFlow(res[0]);
            switch (flow_type) {
              case FlowType.DAILY:
                setDailyFlow(res[0]);
                break;
              case FlowType.FINAL:
                setFinalFlow(res[0]);
                break;
            }
          }
        }
      }
    } catch (e) {
      console.log("err flow", e);
    }
  };

  const getListApprove = async (
    SK: string,
    flowType: keyof typeof FlowType,
  ) => {
    try {
      LoadingOverlayController.show();
      let params = {
        sk_flow: SK,
        location_id: location_id,
      };
      const res = await getListApproveApi(params);
      if (res?.data) {
        if (showFlow === flowType || flowType === undefined)
          setListApproveDefault(res?.data);
        switch (flowType) {
          case FlowType.DAILY:
            setListDailyApproveDefault(res?.data);
            break;
          case FlowType.FINAL:
            setListFinalApproveDefault(res?.data);
            break;
        }
        if (
          flowSK === "" ||
          (is_daily && flowType !== undefined && showFlow !== flowType)
        ) {
          setListApproveAndUserStep(res?.data, flowType);
        }
      }
    } catch (e) {
      console.log("err approve", e);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const setListApproveAndUserStep = async (
    data: any,
    flowType: keyof typeof FlowType,
  ) => {
    if (showFlow === flowType || flowType === undefined) setListApprove(data);
    let listUser = await getListUserStepByFlowApprove(data);
    if (showFlow === flowType || flowType === undefined)
      setListUserStep(listUser);

    switch (flowType) {
      case FlowType.DAILY:
        setListDailyUserStep(listUser);
        setListDailyApprove(data);
        break;
      case FlowType.FINAL:
        setListFinalApprove(data);
        setListFinalUserStep(listUser);
        break;
    }
  };

  const onChooseUser = (itemStep: Type.FlowDataItemType, indexStep: number) => {
    setVisibleUser(true);
    setIndexStep(indexStep);
    let dataChangSK = itemStep?.data_step;
    if (!listUserStep[indexStep]) {
      dataChangSK?.map((item, index) => {
        dataChangSK[index] = {
          ...item,
          SK: item?.SK?.startsWith("USER#")
            ? item?.SK
            : getUserIdFromSK(item?.SK),
          isDefault: true,
        };
      });
    }
    setSelectedU(listUserStep[indexStep] ?? dataChangSK);
    setSelectedUser(listUserStep[indexStep] ?? dataChangSK);
  };

  const onUpdateUser = () => {
    if (!listApprove) return;
    let listUser = listUserStep;
    let flagChangeList: any = selectedUser;
    selectedUser.map((userSelectedItem, indexUserSelected) => {
      listApprove[indexStep]?.data_step?.map((dataStepItem) => {
        if (userSelectedItem?.SK == getUserIdFromSK(dataStepItem?.SK)) {
          flagChangeList[indexUserSelected] = {
            ...flagChangeList[indexUserSelected],
            isDefault: true,
          };
        }
      });
    });
    listUser[indexStep] = flagChangeList;
    setListUserStep([...listUser]);
    switch (showFlow) {
      case FlowType.DAILY:
        setListDailyUserStep([...listUser]);
        break;
      case FlowType.FINAL:
        setListFinalUserStep([...listUser]);
        break;
    }

    setSelectedU(flagChangeList);
    checkNumberUserStepFlow();
  };

  const getStepApprove = () => {
    if (stateForm?.rejected_in_step) {
      return Number(stateForm.rejected_in_step) - 1;
    } else {
      return REPORTER_STEP;
    }
  };

  useEffect(() => {
    if (is_daily && dailyListFlow === undefined) {
      const getListDailyFlow = async () => {
        try {
          if (template) {
            let paramDaily = {
              template_id: template.SK,
              flow_type: FlowType.DAILY,
            };
            const resDaily: FlowInfo[] = await getListFlowApi(paramDaily);
            setDailyListFlow(resDaily);
            if (showFlow === FlowType.DAILY) setListFlow(resDaily);
            if (resDaily.length > 0) {
              const flow_selected = resDaily.find((item) => item.SK === flowSK);
              if (flow_selected) {
                setShowFlow("DAILY");
                setFlow(flow_selected);
                setDailyFlow(flow_selected);
                setListFlow(resDaily);
              } else {
                if (showFlow === FlowType.DAILY) setFlow(resDaily[0]);
                setDailyFlow(resDaily[0]);
              }
            }
          }
        } catch (e) {
          console.log("err flow", e);
        }
      };
      getListDailyFlow();
    }

    if (is_daily && finalListFlow === undefined) {
      const getListFinalFlow = async () => {
        try {
          if (template) {
            let paramFinal = {
              template_id: template.SK,
              flow_type: FlowType.FINAL,
            };
            const resFinal: FlowInfo[] = await getListFlowApi(paramFinal);
            setFinalListFlow(resFinal);
            if (showFlow === FlowType.FINAL) setListFlow(resFinal);
            if (resFinal.length > 0) {
              const flow_selected = resFinal.find((item) => item.SK === flowSK);
              if (flow_selected) {
                setShowFlow("FINAL");
                setListFlow(resFinal);
                setFlow(flow_selected);
                setFinalFlow(flow_selected);
              } else {
                if (showFlow === FlowType.FINAL) setFlow(resFinal[0]);
                setFinalFlow(resFinal[0]);
              }
            }
          }
        } catch (e) {
          console.log("err flow", e);
        }
      };
      getListFinalFlow();
    }

    if (!is_daily && listFlow === undefined) {
      getListFlow();
    }
  }, [template?.SK, flowSK, is_daily]);

  useEffect(() => {
    if (flow?.SK) {
      getListApprove(flow?.SK, showFlow);
    } else if (flow === undefined) {
      setListApproveDefault(undefined);
      setListApprove(undefined);
      setListUserStep([]);
    }
  }, [flow?.SK]);

  useEffect(() => {
    if (
      dailyFlow?.SK &&
      (!listDailyApproveDefault?.length || !listDailyApprove?.length)
    ) {
      getListApprove(dailyFlow?.SK, "DAILY");
    } else if (dailyFlow === undefined) {
      setListDailyApproveDefault(undefined);
      setListDailyApprove(undefined);
      setListDailyUserStep([]);
    }
  }, [dailyFlow?.SK]);

  useEffect(() => {
    if (
      finalFlow?.SK &&
      (!listFinalApproveDefault?.length || !listFinalApprove?.length)
    ) {
      getListApprove(finalFlow?.SK, "FINAL");
    } else if (finalFlow === undefined) {
      setListFinalApproveDefault(undefined);
      setListFinalApprove(undefined);
      setListFinalUserStep([]);
    }
  }, [finalFlow?.SK]);

  return (
    <>
      <Card>
        <CardHeader title="基本情報" sx={styles.header} />
        <CardContent>
          <FormGroup>
            <Stack sx={{ pb: 0 }}>
              <TextField
                label={
                  screenIdSupport[template?.screen_id || ""] ==
                  TEMPLATE_EDIT_TYPE.RISKY_REPORT
                    ? "事故番号"
                    : "帳票番号"
                }
                disabled
                value={riskyId}
              />
              <LabelValueText
                label="報告者"
                value={userInfo?.full_name ?? ""}
                isIgnoreExtractRule={true}
              />
            </Stack>
          </FormGroup>
        </CardContent>
      </Card>

      <Card>
        <CardHeader title="承認フロー" sx={styles.header} />
        <CardContent>
          {is_daily ? null : (
            <Select
              value={flow?.SK ?? ""}
              fullWidth
              disabled={is_inactive}
              onChange={(e) => {
                const selected = listFlow?.find((v) => v.SK === e.target.value);
                if (selected) {
                  if (selected?.SK !== flowSK && flowSK !== "") {
                    setFlowSK("");
                  }
                  setFlow(selected);
                }
              }}
            >
              {listFlow?.map((item) => (
                <MenuItem
                  value={item.SK}
                  key={item.SK}
                  className="IgnoreExtractRuleTarget"
                >
                  {item.flow_name}
                </MenuItem>
              ))}
            </Select>
          )}

          <Box
            component={"div"}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            my={1}
          >
            <Typography>バージョン {version}</Typography>
            <Box flexGrow={1} />
            {version > 1 && (
              <Button
                onClick={() => {
                  setOpen(true);
                }}
              >
                履歴
              </Button>
            )}
          </Box>
          {is_daily ? (
            <Stack sx={{ marginBottom: "20px" }}>
              <ButtonGroup>
                <Button
                  variant={
                    showFlow === FlowType.DAILY || showFlow === undefined
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    setFlowSK("");
                    setDailyFlow(dailyListFlow ? dailyListFlow[0] : undefined);
                    setFlow(dailyListFlow ? dailyListFlow[0] : undefined);
                    setShowFlow("DAILY");
                    setListFlow(dailyListFlow ?? []);
                  }}
                  sx={{ width: "50%" }}
                  disabled={is_inactive}
                >
                  日次申請
                </Button>
                <Button
                  variant={
                    showFlow === FlowType.FINAL ? "contained" : "outlined"
                  }
                  onClick={() => {
                    setFlowSK("");
                    setFinalFlow(finalListFlow ? finalListFlow[0] : undefined);
                    setFlow(finalListFlow ? finalListFlow[0] : undefined);
                    setShowFlow("FINAL");
                    setListFlow(finalListFlow ?? []);
                  }}
                  sx={{ width: "50%" }}
                  disabled={is_inactive}
                >
                  最終申請
                </Button>
              </ButtonGroup>
              <Select
                value={flow?.SK ?? ""}
                fullWidth
                disabled={is_inactive}
                onChange={(e) => {
                  const selected = listFlow?.find(
                    (v) => v.SK === e.target.value,
                  );
                  if (selected) {
                    if (selected?.SK !== flowSK && flowSK !== "") {
                      setFlowSK("");
                    }
                    setFlow(selected);
                    switch (showFlow) {
                      case FlowType.DAILY:
                        setDailyFlow(selected);
                        break;
                      case FlowType.FINAL:
                        setFinalFlow(selected);
                        break;
                    }
                  }
                }}
              >
                {listFlow?.map((item) => (
                  <MenuItem
                    value={item.SK}
                    key={item.SK}
                    className="IgnoreExtractRuleTarget"
                  >
                    {item.flow_name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          ) : null}

          <FormGroup>
            {listApprove
              ? renderUserFlowCreateTlog(
                  listApprove,
                  onChooseUser,
                  listUserStep,
                  is_inactive,
                )
              : null}
          </FormGroup>
          <FormHelperText error={true}>
            {formError.list_approve.map((v, i) => (
              <>
                <span key={i}>{v}</span>
                <br />
              </>
            ))}
          </FormHelperText>
        </CardContent>
      </Card>

      <SelectFileReport
        stateForm={stateForm}
        setStateForm={setStateForm}
        template={template}
        riskyId={riskyId}
        errorMessage={formError.template}
        userType={userType}
        work_id={workId ?? ""}
        step={getStepApprove()}
      />

      <ModalApproveHistory
        open={open}
        setOpen={setOpen}
        work_id={workId ?? ""}
      />
      <ModalSearchUser
        visibleUser={visibleUser}
        setVisibleUser={setVisibleUser}
        onBack={() => {
          // 選択済みユーザーを元に戻す
          setVisibleUser(false);
          setSelectedUser(selectedU);
        }}
        selected={selectedUser}
        setSelected={setSelectedUser}
        onUpdateUser={onUpdateUser}
        locationId={location_id}
        ignore_me={true}
      />
    </>
  );
};

export default forwardRef(FormContextReport);
