import { TYPES } from "store/types";
import {
    IncidentDamageReportType,
    originData,
    originErrorData
} from "screens/EditTemplate/TemplateIncidentDamageReport/IncidentDamageReportType";
import _, { cloneDeep, concat } from "lodash";

//SKを保存する？
const initialIncidentDamageReport: IncidentDamageReportType = {
    ...cloneDeep(originData)
};

export const incidentDamageReportReducer = (
    state = initialIncidentDamageReport,
    { payload, type }: any,
) => {
    switch (type) {
        case TYPES.SET_INCIDENT_DAMAGE_REPORT: {
            return { ...state, ...payload };
        }
        case TYPES.CLEAR_INCIDENT_DAMAGE_REPORT: {
            return { ...initialIncidentDamageReport };
        }
        default:
            return state;
    }
};
