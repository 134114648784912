import {
  METHOD_API,
  createConnector,
  getParamsHelp,
  prefixApi,
} from "./Connector";
import { httpClient } from "./HttpClient";

interface IParams {
  template_type: string;
}

export interface ParamProjectID {
  project_id: string;
}

export type ParamGetBB = {
  project_id: string;
  sort_type: string;
  key_word: string;
  last_key: string;
};

export type ParamDeleteBB = {
  blackboard_id: string;
};

export type ParamUpdateLastUseBBDefault = {
  template_blackboard_id: string;
};

// export type SignedUrlFileQRCode = {
//   path_file: string;
//   works_project_id: string;
//   works_work_id: string;
// };

export const getListTemplate = (params: IParams) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/template?${getParamsHelp(params)}`,
    null,
  );
};

export const getTemplateApi = (templateId: any) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/template/${encodeURIComponent(templateId)}`,
    null,
  );
};

export const uploadImageForTemplate = (data: FormData) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/upload-file-to-s3`,
    data,
  );
};

export const uploadTemplateAssignmentApi = (data: FormData) => {
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/create-work-for-folder`,

    data,
  );
};

export const getTemplateBlackBoardApi = (paramsGetBB: ParamGetBB) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-list-blackboard-data?${getParamsHelp(paramsGetBB)}`,

    null,
  );
};

export const getTemplateBlackBoardDefaultApi = (params: ParamGetBB) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-list-template-blackboard?${getParamsHelp(params)}`,

    null,
  );
};

export const getTemplateBlackBoardCameraApi = (params: ParamProjectID) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-blackboard-info-last-use?${getParamsHelp(params)}`,

    null,
  );
};

export const deleteTemplateBlackboardApi = (params: ParamDeleteBB) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/delete-blackboard-data?${getParamsHelp(params)}`,
  );
};

export const updateLastUseBBDataApi = (params: ParamDeleteBB) => {
  return createConnector(
    METHOD_API.PUT,
    `${prefixApi}/update-last-use-blackboard?${getParamsHelp(params)}`,
  );
};

export const updateLastUseBBTemplateApi = (
  params: ParamUpdateLastUseBBDefault,
) => {
  return createConnector(
    METHOD_API.PUT,
    `${prefixApi}/update-last-use-template-blackboard?${getParamsHelp(params)}`,
  );
};

export const insertUpdateBBTemplateApi = (params: FormData) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/insert-or-update-template-blackboard-data`,

    params,
  );
};

// export const getSignedUrlFile = (path_file: string) => {
//   return createConnector(
//     `${prefixApi}/signed-url-file?path_file=${encodeURIComponent(path_file)}`,
//     HttpMethod.Get,
//     null,
//     {
//       "Content-Type": "multipart/form-data",
//       Accept: "application/json",
//     },
//   );
// };

export const getListFlowApi = (params: object) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-list-flow?${getParamsHelp(params)}`,
  );
};

export const getListApproveApi = (params: object) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-list-approve?${getParamsHelp(params)}`,
  );
};

export const getMaxNumberMachine = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/max-mumber-machine`);
};

export const getMaxNumberImage = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/max-mumber-images`);
};

export const getSignedUrlFile = (path_file: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/signed-url-file?path_file=${encodeURIComponent(path_file)}`,
  );
};

export const createExcelTemplateApi = (params: FormData) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/create-excel-template-data`,
    params,
  );
};

export const updateExcelTemplateApi = (params: FormData) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_PUT,
    `${prefixApi}/update-excel-template-data`,
    params,
  );
};

export const createExcelTemplate = (dataBody: any) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/excel-template`,
    dataBody,
  );
};

export const createTemplatePDF = async (dataBody: any) => {
  const DOMAIN = process.env.REACT_APP_API_URL;
  return await httpClient
    .post(`${DOMAIN}/${prefixApi}/pdf-template`, dataBody, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      responseType: "blob",
    })
    .then((x) => (x ? x.data : undefined));
};

export const getS3UploadUrl = (data: any) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/s3-upload-url`,
    data,
  );
};

export const getS3UploadUrlV2 = (data: any) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/s3-upload-url-v2`,
    data,
  );
};

export const getMultipleS3UploadUrl = (data: any) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/multiple-s3-upload-url`,
    data,
  );
};

export const getMultipleS3UploadUrlV2 = (data: any) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/multiple-s3-upload-url-v2`,
    data,
  );
};

// export const getSignedUrlFileQRCode = (params: SignedUrlFileQRCode) => {
//   return createConnector(
//     METHOD_API.GET,
//     `${prefixApi}/signed-url-file-qrcode?${getParamsHelp(params)}`,
//   );
// };

// export const getQRImageApi = (data: any) => {
//   return createConnector(
//     METHOD_API.GET,
//     `${prefixApi}/qr-image?${getParamsHelp(data)}`,
//   );
// };
