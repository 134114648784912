import { User } from "services/models";
export type IncidentDamageReportType = {
    "risky_id": string;
    "date": string;
    "time": string;
    "warehouse": MMaster | undefined;
    "area": MMaster | undefined;
    "reported_by": User | MMaster | string;
    "customer": MMaster | undefined;
    "commodity": MMaster | undefined;
    "model_name": string;
    "quantity": string;
    "serial_no": string[];
    "damage_location": MMaster | undefined;
    "cause": MMaster | string;
    "qa_authorizer": MMaster | undefined;
    "occurred_company": MMaster | undefined;//～起票者
    "invoice_no": MMaster | string;
    "affiliation_of_employee": MMaster | undefined;
    "experience_of_employee_years": string;
    "experience_of_employee_months": string;
    "system_transaction_no": string;
    "waste_claim_category": MMaster | undefined;
    "accident_rank": MMaster | undefined;
    "cpar_no": string;
    "nonconformity_detail": Nonconformity_Detail | undefined;
    "nonconformity_overview": MMaster | undefined;
    "occurance_process": MMaster | undefined;
    "process_of_root_cause": MMaster | undefined;
    "responsibility": MMaster | undefined;
    "department_of_pic": MMaster | undefined;
    "process_in_logistics": MMaster | undefined;
    "responsibility_in_process": MMaster | undefined;
    "classification_of_nonconformity": MMaster | undefined;
    "classification_of_cause": MMaster | undefined;
    "cause_overview": MMaster | string;
    "root_cause": MMaster | string;
    "logistics_equipments": MMaster | undefined;
    "corrective_action_improvement": MMaster | string;
    "completion_improvement_date": string;
    "scrap_unit": string;
    "repair_unit": string;
    "no_problem_unit": string;
    "final_payer": MMaster | undefined;
    "expected_payment_month": string;
    "est_acc": MMaster | undefined;
    "amount_cost_est": string;
    "amount_cost_act": string;
    "actual_payment_month": string;
    "insurance_sub_con_amount": string;
    "final_tlgt_amount": string;
    "received_invoice_no": string;
    "closing_date": string;
    "remark": string;
    "image_array": Array<any>//不適合写真
    "qrcode": string;
};

export type ErrorIncidentDamageReportType = {
    "risky_id": string;
    "date": string;
    "time": string;
    "warehouse": string;
    "area": string;
    "reported_by": string;
    "customer": string;
    "commodity": string;
    "model_name": string;
    "quantity": string;
    "serial_no": string[];
    "damage_location": string;
    "cause": string;
    "qa_authorizer": string;
    "occurred_company": string;
    "invoice_no": string;
    "affiliation_of_employee": string;
    "experience_of_employee_years": string;
    "experience_of_employee_months": string;
    "system_transaction_no": string;
    "waste_claim_category": string;
    "accident_rank": string;
    "cpar_no": string;
    "nonconformity_detail": string;
    "nonconformity_overview": string;
    "occurance_process": string;
    "process_of_root_cause": string;
    "responsibility": string;
    "department_of_pic": string;
    "process_in_logistics": string;
    "responsibility_in_process": string;
    "classification_of_nonconformity": string;
    "classification_of_cause": string;
    "cause_overview": string;
    "root_cause": string;
    "logistics_equipments": string;
    "corrective_action_improvement": string;
    "completion_improvement_date": string;
    "scrap_unit": string;
    "repair_unit": string;
    "no_problem_unit": string;
    "final_payer": string;
    "expected_payment_month": string;
    "est_acc": string;
    "amount_cost_est": string;
    "amount_cost_act": string;
    "actual_payment_month": string;
    "insurance_sub_con_amount": string;
    "final_tlgt_amount": string;
    "received_invoice_no": string;
    "closing_date": string;
    "remark": string;
    "image_array": string;//不適合写真
    "qrcode": string;
};


export type MMaster = {
    "PK"?: string,
    "SK": string,
    "activity_base_id"?: string,
    "created_at"?: string,
    "created_by"?: string,
    "is_default"?: boolean,
    "is_manual_input"?: boolean,
    "name": string,
    "seq"?: number,
    "updated_at"?: string,
    "updated_by"?: string
}


export type Nonconformity_Detail = {
    "PK": string;
    "SK": string;
    "name": string;
    "activity_base_id": string;
    "created_at": string;
    "created_by": string;
    "nonconformity_detail": string;
    "nonconformity_overview": string;
    "occurance_process": string;
    "process_of_root_cause": string;
    "responsibility": string;
    "department_of_pic": string;
    "process_in_logistics": string;
    "responsibility_in_process": string;
    "classification_of_nonconformity": string;
    "classification_of_cause": string;
    "is_default": boolean;
    "is_manual_input": boolean;
    "seq": number
    "updated_at": string;
    "updated_by": string;
}


export const originData = {
    "risky_id": "",
    "date": "",
    "time": "",
    "warehouse": undefined,
    "area": undefined,
    "reported_by": "",
    "customer": undefined,
    "commodity": undefined,
    "model_name": "",
    "quantity": "",
    "serial_no": [""],
    "damage_location": undefined,
    "cause": "",
    "qa_authorizer": undefined,
    "occurred_company": undefined,//～起票者
    "invoice_no": "",
    "affiliation_of_employee": undefined,
    "experience_of_employee_years": "",
    "experience_of_employee_months": "",
    "system_transaction_no": "",//～第一次承認
    "waste_claim_category": undefined,
    "accident_rank": undefined,
    "cpar_no": "",
    "nonconformity_detail": undefined,
    "nonconformity_overview": undefined,
    "occurance_process": undefined,
    "process_of_root_cause": undefined,
    "responsibility": undefined,
    "department_of_pic": undefined,
    "process_in_logistics": undefined,
    "responsibility_in_process": undefined,
    "classification_of_nonconformity": undefined,
    "classification_of_cause": undefined,
    "cause_overview": "",
    "root_cause": "",
    "logistics_equipments": undefined,
    "corrective_action_improvement": "",
    "completion_improvement_date": "",
    "scrap_unit": "",
    "repair_unit": "",
    "no_problem_unit": "",
    "final_payer": undefined,
    "expected_payment_month": "",
    "est_acc": undefined,
    "amount_cost_est": "",
    "amount_cost_act": "",
    "actual_payment_month": "",
    "insurance_sub_con_amount": "",
    "final_tlgt_amount": "",
    "received_invoice_no": "",
    "closing_date": "",
    "remark": "",
    "image_array": [],//不適合写真
    "qrcode": ""
}

export const originErrorData = {
    "risky_id": "",
    "date": "",
    "time": "",
    "warehouse": "",
    "area": "",
    "reported_by": "",
    "customer": "",
    "commodity": "",
    "model_name": "",
    "quantity": "",
    "serial_no": [""],
    "damage_location": "",
    "cause": "",
    "qa_authorizer": "",
    "occurred_company": "",//～起票者
    "invoice_no": "",
    "affiliation_of_employee": "",
    "experience_of_employee_years": "",
    "experience_of_employee_months": "",
    "system_transaction_no": "",//～第一次承認
    "waste_claim_category": "",
    "accident_rank": "",
    "cpar_no": "",
    "nonconformity_detail": "",
    "nonconformity_overview": "",
    "occurance_process": "",
    "process_of_root_cause": "",
    "responsibility": "",
    "department_of_pic": "",
    "process_in_logistics": "",
    "responsibility_in_process": "",
    "classification_of_nonconformity": "",
    "classification_of_cause": "",
    "cause_overview": "",
    "root_cause": "",
    "logistics_equipments": "",
    "corrective_action_improvement": "",
    "completion_improvement_date": "",
    "scrap_unit": "",
    "repair_unit": "",
    "no_problem_unit": "",
    "final_payer": "",
    "expected_payment_month": "",
    "est_acc": "",
    "amount_cost_est": "",
    "amount_cost_act": "",
    "actual_payment_month": "",
    "insurance_sub_con_amount": "",
    "final_tlgt_amount": "",
    "received_invoice_no": "",
    "closing_date": "",
    "remark": "",
    "image_array": "",//不適合写真
    "qrcode": ""
}


export const nameList = {
    "risky_id": "ReportNo.",
    "date": "Date(YYYY/MM/DD)",
    "time": "Time",
    "warehouse": "Warehouse",
    "area": "Area",
    "reported_by": "Reported by",
    "customer": "Customer (Shipper)",
    "commodity": "Comomdity",
    "model_name": "Model Name",
    "quantity": "Quantity",
    "serial_no": "Serial No.",
    "damage_location": "Damage Location",
    "cause": "Cause (Site Opinion)",
    "qa_authorizer": "QA Authorizer",
    "occurred_company": "Occurred Company",//～起票者
    "invoice_no": "Invoice No.",
    "affiliation_of_employee": "Employee's Affiliation",
    "experience_of_employee_years": "Employee's Experience Years",
    "experience_of_employee_months": "Employee's Experience Months",
    "system_transaction_no": "System Transaction No.",//～第一次承認
    "waste_claim_category": "Waste /Claim Category",
    "accident_rank": "Accident Rank",
    "cpar_no": "CPAR No.",
    "nonconformity_detail": "Nonconformity Detail",
    "nonconformity_overview": "Nonconformity Overview",
    "occurance_process": "Occurance Process",
    "process_of_root_cause": "Process of Root Cause",
    "responsibility": "Responsibility",
    "department_of_pic": "Department of PIC",
    "process_in_logistics": "Process in Logistics",
    "responsibility_in_process": "Responsibility in Process",
    "classification_of_nonconformity": "Classification of Nonconformity",
    "classification_of_cause": "Classification of Cause",
    "cause_overview": "Cause Overview",
    "root_cause": "Root Cause",
    "logistics_equipments": "Logistics Equipments",
    "corrective_action_improvement": "Corrective Action /Improvement",
    "completion_improvement_date": "Completion improvement Date",
    "scrap_unit": "Scrap Unit",
    "repair_unit": "Repair Unit",
    "no_problem_unit": "No Problem Unit",
    "final_payer": "Final Payer",
    "expected_payment_month": "Expected Payment Month",
    "est_acc": "Est/Acc",
    "amount_cost_est": "Amount Cost (Est)",
    "amount_cost_act": "Amount Cost (Act)",
    "actual_payment_month": "Actual Payment Month",
    "insurance_sub_con_amount": "Insurance ,Sub-con Amount",
    "final_tlgt_amount": "Final TLGT Amount",
    "received_invoice_no": "Received Invoice No. ",
    "closing_date": "Closing Date",
    "remark": "Remark",
    "image_array": "Defective Parts(photos)",//不適合写真
    "qrcode": ""
}


