import _ from "lodash";
import { createObjectURL, getBase64Image, getFileByUrl } from "..";
import { User } from "services/models";

export interface ImageInfoTypePreview extends Type.ImageInfoType {
  base64?: string;
}

export type StateFormType = {
  risky_id: string;
  date: string;
  time: string;
  model_name: string;
  quantity: number;
  tl_serial_no: Array<string>;
  reported_by: User | string; // デフォルト アカウント名
  defect: any;
  damage_location: any;
  damage_location_photo: Array<any>;
  ctc_qa_judgement: any;
  ctc_qa_inspector_by: string;
  ctc_checked_date: string;
  checked_quantity: number;
  qa_serial_no: Array<string>;
  ctc_ma_repair_by: string;
  ctc_ma_inspector_by: string;
  ctc_inspection_date: string;
  repaired_quantity: number;
  repaired_date: string;
  repaired_time: string;
  qa_ma_ctc_part_photo: Array<any>;
  repaired_ac_receive_by: any;
  returning_quantity: number;
  returning_date: string;
  returning_time: string;
  qrcode: string;
};

export const getInfoFromTemplate = (htmlString: string) => {
  let imageProductExample: string = "";
  let pageImageExample: string = "";
  let qrcodeImageExample: string = "";
  let addMorePageExample: string = "";
  let addPageImageExample: string = "";
  let addCtcImagePageExample: string = "";

  const imageProductExampleArray = htmlString.split("image_product_example");
  if (imageProductExampleArray.length >= 3) {
    imageProductExample = imageProductExampleArray[1];
  }

  const pageImageExampleArray = htmlString.split("page_image_example");
  if (pageImageExampleArray.length >= 3) {
    pageImageExample = pageImageExampleArray[1];
  }

  const qrcodeImageExampleArray = htmlString.split("image_qrcode_example");
  if (qrcodeImageExampleArray.length >= 3) {
    qrcodeImageExample = qrcodeImageExampleArray[1];
  }

  const addMorePageExampleArray = htmlString.split("add_more_page_example");
  if (addMorePageExampleArray.length >= 3) {
    addMorePageExample = addMorePageExampleArray[1];
  }

  const addPageImageImageExampleArray = htmlString.split(
    "add_page_image_example",
  );
  if (addPageImageImageExampleArray.length >= 3) {
    addPageImageExample = addPageImageImageExampleArray[1];
  }

  const addCtcImagePageExampleArray = htmlString.split(
    "add_ctc_image_page_example",
  );
  if (addCtcImagePageExampleArray.length >= 3) {
    addCtcImagePageExample = addCtcImagePageExampleArray[1];
  }

  return {
    imageProductExample,
    pageImageExample,
    qrcodeImageExample,
    addMorePageExample,
    addPageImageExample,
    addCtcImagePageExample,
  };
};

export const makeNewHtml = (
  newHtml: string,
  data: StateFormType,
  imageQrcodeExample: string,
  addMorePageExample: string,
  addCtcImagePageExample: string,
) => {
  let {
    risky_id,
    date,
    time,
    tl_serial_no,
    model_name,
    quantity,
    reported_by,
    defect,
    damage_location,
    ctc_qa_judgement,
    ctc_qa_inspector_by,
    ctc_checked_date,
    checked_quantity,
    qa_serial_no,
    ctc_ma_repair_by,
    ctc_ma_inspector_by,
    repaired_date,
    repaired_time,
    repaired_ac_receive_by,
    returning_quantity,
    returning_date,
    returning_time,
    qrcode,
    qa_ma_ctc_part_photo,
  } = data;

  const perPageSerialNoCount = 4;
  const isExistQaSerialNoCount = 1;
  let totalPageCount = 1;
  let currentPage = 1;

  // Serial No.の個数によって、改ページの枚数が決定し、Serial No.以外の項目に関しては
  // 全て同じものを出力するため処理の初めにSerial No.を反映させる。

  if (tl_serial_no.length > 0 || qa_serial_no.length > 0) {
    //  報告作成の段階でのserial_noのデータはtl_serial_noで受け付ける。
    //  帳票反映自体は、取得したHTMLのserial_no_[index]に反映する。
    const isExistQaSerialNo: boolean =
      qa_serial_no.length > isExistQaSerialNoCount;
    // 1ページの最大個数でChunkする。
    const chunkedArray = _.chunk(tl_serial_no, perPageSerialNoCount);
    const onePageSerialNo = chunkedArray[0];
    let joinedSerialNo = "";
    for (const serial_no of onePageSerialNo) {
      if (serial_no !== "") {
        joinedSerialNo += serial_no + ",";
      }
    }
    joinedSerialNo = joinedSerialNo.slice(0, joinedSerialNo.length - 1);
    newHtml = newHtml.replace("<!-- {tl_serial_no} -->", joinedSerialNo ?? "");
    if (isExistQaSerialNo) {
      // 承認ステップ1以降でのserial_noのデータはqa_serial_noで同期させる。
      // 帳票反映自体は、取得したHTMLのserial_no_[index]に反映する。
      newHtml = newHtml.replace(
        "<!-- {qa_serial_no} -->",
        joinedSerialNo ?? "",
      );
    }
    if (tl_serial_no.length > perPageSerialNoCount) {
      const copiedSerialNo = chunkedArray.slice(1);
      for (let index = 0; index < copiedSerialNo.length; index++) {
        let joinedAddSerialNo = "";
        let cloneAddPageExample = addMorePageExample;
        const serialNoArray = copiedSerialNo[index];
        for (const serial_no of serialNoArray) {
          if (serial_no !== "") {
            joinedAddSerialNo += serial_no + ",";
          }
        }
        joinedAddSerialNo = joinedAddSerialNo.slice(
          0,
          joinedAddSerialNo.length - 1,
        );
        cloneAddPageExample = cloneAddPageExample.replace(
          "{tl_serial_no_i}",
          `tl_serial_no_${index}`,
        );
        cloneAddPageExample = cloneAddPageExample.replace(
          `tl_serial_no_${index}`,
          joinedAddSerialNo,
        );
        if (isExistQaSerialNo) {
          // QA Serialが存在する場合、反映する。
          cloneAddPageExample = cloneAddPageExample.replace(
            "{qa_serial_no_j}",
            `qa_serial_no_${index}`,
          );
          cloneAddPageExample = cloneAddPageExample.replace(
            `qa_serial_no_${index}`,
            joinedAddSerialNo,
          );
        }
        currentPage++;
        cloneAddPageExample = cloneAddPageExample.replace(
          "{current_page}",
          currentPage.toString(),
        );

        newHtml = newHtml.replace(
          "<!-- {add_more_area} -->",
          `
          ${cloneAddPageExample}
          <!-- {add_more_area} -->
          `,
        );
        totalPageCount++;
      }
    }
  }

  // QA/MA CTC Part Photo
  if (qa_ma_ctc_part_photo?.length > 0) {
    addCtcImagePageExample;
    let cloneAddCtcImagePageExample = addCtcImagePageExample;
    cloneAddCtcImagePageExample = cloneAddCtcImagePageExample.replace(
      "{qa_ma_ctc_image_url}",
      `'${
        qa_ma_ctc_part_photo[0]?.base64
          ? qa_ma_ctc_part_photo[0]?.base64
          : qa_ma_ctc_part_photo[0]?.uri_jpg ?? qa_ma_ctc_part_photo[0]?.uri
      }'`,
    );
    currentPage++;
    cloneAddCtcImagePageExample = cloneAddCtcImagePageExample.replace(
      "{current_page}",
      currentPage.toString(),
    );
    newHtml = newHtml.replace(
      "<!-- {add_more_area} -->",
      `
        ${cloneAddCtcImagePageExample}
        <!-- {add_more_area} -->
        `,
    );
    totalPageCount++;
  }

  if (risky_id) {
    newHtml = newHtml.replace("<!-- {risky_id} -->", risky_id ?? "");
    newHtml = newHtml.replaceAll("{add_risky_id}", risky_id ?? "");
  } else {
    newHtml = newHtml.replaceAll(
      "{add_risky_id}",
      "<span style='display: none'>{risky_id}<span>",
    );
  }

  // TLGT Part
  if (date) {
    newHtml = newHtml.replace("<!-- {date} -->", date ?? "");
    newHtml = newHtml.replaceAll("{date}", date ?? "");
  }

  if (time) {
    newHtml = newHtml.replace("<!-- {time} -->", time ?? "");
    newHtml = newHtml.replaceAll("{time}", time ?? "");
  }

  if (model_name) {
    newHtml = newHtml.replace("<!-- {model_name} -->", model_name ?? "");
    newHtml = newHtml.replaceAll("{model_name}", model_name ?? "");
  }

  if (quantity) {
    newHtml = newHtml.replace("<!-- {quantity} -->", quantity.toString() ?? "");
    newHtml = newHtml.replaceAll("{quantity}", quantity.toString() ?? "");
  }

  if (reported_by) {
    let reportedByText = "";
    if (typeof reported_by === "string") {
      reportedByText = reported_by;
    } else {
      if ("full_name" in reported_by) {
        reportedByText = reported_by.full_name;
      }
    }
    newHtml = newHtml.replace("<!-- {reported_by} -->", reportedByText);
    newHtml = newHtml.replaceAll("{reported_by}", reportedByText);
  }

  if (defect) {
    newHtml = newHtml.replace("<!-- {defect} -->", defect.name ?? "");
    newHtml = newHtml.replaceAll("{defect}", defect.name ?? "");
  }

  if (damage_location) {
    newHtml = newHtml.replace(
      "<!-- {damage_location} -->",
      damage_location.name ?? "",
    );
    newHtml = newHtml.replaceAll(
      "{damage_location}",
      damage_location.name ?? "",
    );
  }

  // QA CTC PART
  if (ctc_qa_judgement) {
    newHtml = newHtml.replace(
      "<!-- {ctc_qa_judgement} -->",
      ctc_qa_judgement.name ?? "",
    );
    newHtml = newHtml.replaceAll(
      "{ctc_qa_judgement}",
      ctc_qa_judgement.name ?? "",
    );
  }

  if (ctc_qa_inspector_by) {
    newHtml = newHtml.replace(
      "<!-- {ctc_qa_inspector_by} -->",
      ctc_qa_inspector_by ?? "",
    );
    newHtml = newHtml.replaceAll(
      " {ctc_qa_inspector_by}",
      ctc_qa_inspector_by ?? "",
    );
  }

  if (ctc_checked_date) {
    newHtml = newHtml.replace(
      "<!-- {ctc_checked_date} -->",
      ctc_checked_date ?? "",
    );
    newHtml = newHtml.replaceAll("{ctc_checked_date}", ctc_checked_date ?? "");
  }

  if (checked_quantity) {
    newHtml = newHtml.replace(
      "<!-- {checked_quantity} -->",
      checked_quantity.toString() ?? "",
    );
    newHtml = newHtml.replaceAll(
      "{checked_quantity}",
      checked_quantity.toString() ?? "",
    );
  }

  // MA CTC PART
  if (ctc_ma_repair_by) {
    newHtml = newHtml.replace(
      "<!-- {ctc_ma_repair_by} -->",
      ctc_ma_repair_by ?? "",
    );
    newHtml = newHtml.replaceAll("{ctc_ma_repair_by}", ctc_ma_repair_by ?? "");
  }

  if (ctc_ma_inspector_by) {
    newHtml = newHtml.replace(
      "<!-- {ctc_ma_inspector_by} -->",
      ctc_ma_inspector_by ?? "",
    );
    newHtml = newHtml.replaceAll(
      "{ctc_ma_inspector_by}",
      ctc_ma_inspector_by ?? "",
    );
  }

  const repaired_date_time  = repaired_date + " " + repaired_time;

  if (repaired_date_time) {
    newHtml = newHtml.replace("<!-- {repaired_date} -->", repaired_date_time ?? "");
    newHtml = newHtml.replaceAll("{repaired_date}", repaired_date_time ?? "");
  }

  // TLGT2 PART
  if (repaired_ac_receive_by) {
    newHtml = newHtml.replace(
      "<!-- {repaired_ac_receive_by} -->",
      repaired_ac_receive_by.name ?? "",
    );
    newHtml = newHtml.replaceAll(
      "{repaired_ac_receive_by}",
      repaired_ac_receive_by.name ?? "",
    );
  }

  if (returning_quantity) {
    newHtml = newHtml.replace(
      "<!-- {returning_quantity} -->",
      returning_quantity.toString() ?? "",
    );
    newHtml = newHtml.replaceAll(
      "{returning_quantity}",
      returning_quantity.toString() ?? "",
    );
  }

  const returning_date_time = returning_date + " " + returning_time;

  if (returning_date_time) {
    newHtml = newHtml.replace(
      "<!-- {returning_date} -->",
      returning_date_time ?? "",
    );
    newHtml = newHtml.replaceAll("{returning_date}", returning_date_time ?? "");
  }

  if (qrcode) {
    let imageQrcodeExampleClone = imageQrcodeExample;
    imageQrcodeExampleClone = imageQrcodeExampleClone.replace(
      "{qr_image_url}",
      qrcode,
    );
    newHtml = newHtml.replace(
      " <!-- {image_qrcode_example} -->",
      imageQrcodeExampleClone,
    );
    newHtml = newHtml.replaceAll(
      "{add_image_qrcode_example}",
      imageQrcodeExampleClone,
    );
  } else {
    newHtml = newHtml.replaceAll(
      "{add_image_qrcode_example}",
      "<span style='display: none'>{add_image_qrcode_example}<span>",
    );
  }

  newHtml = newHtml.replaceAll(
    "<!-- {total_page} -->",
    totalPageCount.toString(),
  );
  newHtml = newHtml.replaceAll("{total_page}", totalPageCount.toString());

  return newHtml;
};

export const insertReportLocal = (
  newHtml: string,
  data: any,
  imageProductExample: string,
  pageImageExample: string,
  addPageImageExample: string,
) => {
  const {
    damage_location_photo,
    // qa_ma_ctc_part_photo,
  } = data;

  if (damage_location_photo?.length === 0) {
    newHtml = clearHTMLComment(newHtml);
    newHtml = newHtml.replaceAll(
      "{add_page_image_area}",
      "<span style='display: none'>{add_page_image_area}<span>",
    );
    return newHtml;
  } else {
    if (damage_location_photo.length > 0) {
      let pageImageExampleClone = pageImageExample;
      let addPageImageExampleClone = addPageImageExample;
      for (const damage_location_photo_image of damage_location_photo) {
        let imageProductExampleClone1 = imageProductExample;
        let imageProductExampleClone2 = imageProductExample;

        if (damage_location_photo_image?.uri) {
          imageProductExampleClone1 = imageProductExampleClone1.replace(
            "{image_url}",
            `'${
              damage_location_photo_image?.base64
                ? damage_location_photo_image?.base64
                : damage_location_photo_image?.uri_jpg ??
                  damage_location_photo_image?.uri
            }'`,
          );
          imageProductExampleClone2 = imageProductExampleClone2.replace(
            "{image_url}",
            `'${
              damage_location_photo_image?.base64
                ? damage_location_photo_image?.base64
                : damage_location_photo_image?.uri_jpg ??
                  damage_location_photo_image?.uri
            }'`,
          );

          // image_product_waitingを画像データに置換
          pageImageExampleClone = pageImageExampleClone.replace(
            "{image_product_waiting}",
            imageProductExampleClone1,
          );
          addPageImageExampleClone = addPageImageExampleClone.replace(
            "{add_image_product_waiting}",
            imageProductExampleClone2,
          );
        } else {
          pageImageExampleClone = pageImageExampleClone.replace(
            "{image_product_waiting}",
            "",
          );
          addPageImageExampleClone = addPageImageExampleClone.replace(
            "{add_image_product_waiting}",
            "",
          );
        }
      }

      pageImageExampleClone = pageImageExampleClone.replaceAll(
        "{image_product_waiting}",
        "",
      );
      addPageImageExampleClone = addPageImageExampleClone.replaceAll(
        "{add_image_product_waiting}",
        "",
      );

      newHtml = newHtml.replace(
        "<!-- {add_page_example} -->",
        `
        ${pageImageExampleClone}
        <!-- {add_page_example} -->
      `,
      );
      newHtml = newHtml.replaceAll(
        "{add_page_image_area}",
        `
          ${addPageImageExampleClone}
        `,
      );
    }
  }

  newHtml = clearHTMLComment(newHtml);
  return newHtml;
};

export const getImageArray = async (data: Array<any>) => {
  let image_array: Array<any> = [];
  for (let index = 0; index < data.length; index++) {
    const item = data[index];
    const uri = item?.uri_jpg ?? item?.uri;
    if ((!item.base64 || item.base64.length === 0) && uri && uri.length > 0) {
      item.base64 = await getBase64Image(uri);
    }
    image_array.push(item);
  }
  return image_array;
};

export const mergeTemplateACReceivingTag = async (
  templateUri: string,
  data: StateFormType,
) => {
  try {
    const f = await getFileByUrl(templateUri, "template.html", "text/html");
    let html_string = await f.text();
    const res = getInfoFromTemplate(html_string);
    const {
      imageProductExample,
      pageImageExample,
      qrcodeImageExample,
      addMorePageExample,
      addPageImageExample,
      addCtcImagePageExample,
    } = res;
    html_string = makeNewHtml(
      html_string,
      data,
      qrcodeImageExample,
      addMorePageExample,
      addCtcImagePageExample,
    );
    html_string = insertReportLocal(
      html_string,
      data,
      imageProductExample,
      pageImageExample,
      addPageImageExample,
    );
    const doc = document.createElement("div");
    doc.appendChild(
      document.createRange().createContextualFragment(html_string),
    );
    const html_blob = new Blob([html_string], { type: "text/html" });
    templateUri = createObjectURL(html_blob);
  } catch (err) {
    console.log(err);
    return null;
  }
  return templateUri;
};

export const handleEditInfoLoadingReport = (templateEditInfo: any) => {
  const newTemplateEditInfo = { ...templateEditInfo };
  return newTemplateEditInfo;
};

const clearHTMLComment = (newHtml: string) => {
  newHtml = newHtml.replaceAll("{date}", "");
  newHtml = newHtml.replaceAll("{time}", "");
  newHtml = newHtml.replaceAll("{model_name}", "");
  newHtml = newHtml.replaceAll("{quantity}", "");
  newHtml = newHtml.replaceAll("{reported_by}", "");
  newHtml = newHtml.replaceAll("{defect}", "");
  newHtml = newHtml.replaceAll("{damage_location}", "");
  newHtml = newHtml.replaceAll("{ctc_qa_judgement}", "");
  newHtml = newHtml.replaceAll("{ctc_qa_inspector_by}", "");
  newHtml = newHtml.replaceAll("{ctc_checked_date}", "");
  newHtml = newHtml.replaceAll("{checked_quantity}", "");
  newHtml = newHtml.replaceAll("{tl_serial_no_i}", "");
  newHtml = newHtml.replaceAll("{qa_serial_no_j}", "");
  newHtml = newHtml.replaceAll("{ctc_ma_repair_by}", "");
  newHtml = newHtml.replaceAll("{ctc_ma_inspector_by}", "");
  newHtml = newHtml.replaceAll("{ctc_inspection_date}", "");
  newHtml = newHtml.replaceAll("{repaired_quantity}", "");
  newHtml = newHtml.replaceAll("{repaired_date}", "");
  newHtml = newHtml.replaceAll("{qa_ma_ctc_part_photo}", "");
  newHtml = newHtml.replaceAll("{repaired_ac_receive_by}", "");
  newHtml = newHtml.replaceAll("{returning_quantity}", "");
  newHtml = newHtml.replaceAll("{returning_date}", "");
  newHtml = newHtml.replaceAll("{returning_time}", "");
  // newHtml = newHtml.replaceAll("{image_product_waiting}", "");
  return newHtml;
};
