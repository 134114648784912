import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  MenuItem,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AvatarRes from "components/atoms/AvatarRes";
import messages from "config/messages";
import { getUserInfo } from "@utils/index";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { RootState } from "store/reducer";
import SelectLabel from "components/atoms/SelectLabel";
import { SortableTable } from "components/organisms/SortableTable/SortableTable";
import {
  apiGetChildrenGroup4GroupMaster,
  apiGetListGroupMaster,
  apiGetMemberByConditions,
} from "@api/flowApproval";
import { TYPES } from "store/types";

interface IProps {
  open: boolean;
  onClose: () => void;
  onChoose?: (users: Array<Type.UserInfo>) => void;
  locationId?: string; //TLOG
}

const headCells = [
  {
    id: "full_name_disp",
    disablePadding: false,
    label: "名前",
    sortable: true,
    width: 150,
    orderBy: "full_name",
  },
  {
    id: "linkedGroup",
    disablePadding: false,
    label: "所属グループ",
    sortable: true,
    width: 150,
  },
];

type MasterGroupFilter = {
  masterGroupId: string;
  isDisable: boolean;
  options: Type.GroupInfo[];
};

const ModalSearchUserByLocation = ({
  open,
  onClose,
  onChoose = () => {},
  locationId = "",
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [listUsers, setListUsers] = useState<Type.UserInfo[]>([]);
  const [listTable, setListTable] = useState<Array<any>>([]);
  const [listButtons, setListButtons] = useState<Array<any>>([]);
  const [message, setMessage] = useState<string>("");
  const [keyword, setKeyword] = useState<string>("");
  const [masterGroups, setMasterGroups] = useState<Type.MasterGroup[]>([]);
  const [targetLocationId, setTargetLocationId] = useState<string>("");
  const [theLastSelectedOption, setTheLastSelectedOption] = useState({
    masterGroupIndex: -1,
    optionId: "",
  });
  const [optionsPerGroupMasterList, setOptionsPerGroupMasterList] = useState<
    MasterGroupFilter[]
  >([]);
  const { check_list } = useSelector((state: RootState) => state.tableCustom);
  const origin_location_id = useRef<string>("");
  const dispatch = useDispatch();

  const handleSetValueToStates = async () => {
    const masterGroupsRes = await apiGetListGroupMaster();
    const numberOfGroups = masterGroupsRes.length;
    const optionsPerGroupMasterListAtFirstTime = [];
    const groupInfoDefault: Type.GroupInfo = {
      updated_at: "",
      group_master_id: "",
      created_at: "",
      created_by: "",
      group_parentid: "",
      location_id: "",
      SK: "defaultValue",
      PK: "",
      seq: -1,
      group_id: "",
      group_name: "未選択",
      isSelected: true,
    };
    if (optionsPerGroupMasterList.length === 0) {
      if (numberOfGroups > 0) {
        const theFirstMasterGroup = masterGroupsRes[0];
        const targetId = theFirstMasterGroup.PK;
        const groupsInfo4MasterGroup = await apiGetChildrenGroup4GroupMaster({
          group_parentid: targetId,
        });
        const optionsOfTheFirstGroup = {
          masterGroupId: theFirstMasterGroup.SK,
          isDisable: false,
          options: [groupInfoDefault, ...groupsInfo4MasterGroup],
        };
        optionsPerGroupMasterListAtFirstTime.push(optionsOfTheFirstGroup);
      }
      for (let i = 1; i < numberOfGroups; i++) {
        const item = {
          masterGroupId: masterGroupsRes[i].SK,
          isDisable: true,
          options: [groupInfoDefault],
        };
        optionsPerGroupMasterListAtFirstTime.push(item);
      }
    }
    if (!locationId) {
      const userInfo = getUserInfo();
      origin_location_id.current = userInfo.location_id;
      setTargetLocationId(userInfo.location_id);
    } else {
      origin_location_id.current = locationId;
      setTargetLocationId(locationId);
    }
    const params = {
      sk_group: masterGroupsRes[0].PK,
      key_word: undefined,
      location_id: origin_location_id.current,
    };
    let allUser = await apiGetMemberByConditions(params);
    setListUsers(allUser);
    setMasterGroups(masterGroupsRes);
    if (optionsPerGroupMasterListAtFirstTime.length !== 0)
      setOptionsPerGroupMasterList(optionsPerGroupMasterListAtFirstTime);
    dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: [] });
  };

  useEffect(() => {
    if (open) {
      handleSetValueToStates();
    }
  }, [open]);

  // ------------------------------------------------------------------
  // データ取得
  // ------------------------------------------------------------------
  const fetchData = async () => {
    try {
      LoadingOverlayController.show();
      let optionID = "";
      if (theLastSelectedOption.optionId === "defaultValue") {
        if (theLastSelectedOption.masterGroupIndex > 0) {
          const selectedOption = optionsPerGroupMasterList[
            theLastSelectedOption.masterGroupIndex - 1
          ].options.find((item) => item.isSelected);
          optionID = selectedOption ? selectedOption.SK : "";
        }
      } else {
        optionID = theLastSelectedOption.optionId;
      }
      let memberListRes: Type.UserInfo[] = [];
      if (optionID) {
        const params = {
          sk_group: optionID === "defaultValue" ? masterGroups[0].PK : optionID,
          key_word: keyword ? keyword : undefined,
          location_id: targetLocationId,
        };
        memberListRes = await apiGetMemberByConditions(params);
      } else {
        const params = {
          sk_group: masterGroups[0].PK,
          key_word: keyword ? keyword : undefined,
          location_id: targetLocationId,
        };
        memberListRes = await apiGetMemberByConditions(params);
      }
      const list_buttons: any[] = [];
      const list_table: any[] = [];
      const linkedGroup: string[] = [];
      optionsPerGroupMasterList.forEach((item) => {
        for (let i = 0; i < item.options.length; i++) {
          if (
            item.options[i].isSelected &&
            item.options[i].SK !== "defaultValue"
          ) {
            linkedGroup.push(item.options[i].group_name);
            break;
          }
        }
      });

      memberListRes.forEach((d) => {
        let targetLinkedGroup = "";
        const optionId = theLastSelectedOption.optionId;
        if (optionId === "" || optionId === "defaultValue") {
          const location_id = d.group_id;
          const parentGroup = optionsPerGroupMasterList[0].options.find(
            (item) => item.SK === location_id,
          );
          const prefixLinkedGroup =
            theLastSelectedOption.masterGroupIndex == 0
              ? parentGroup
                ? parentGroup.group_name
                : ""
              : "";
          targetLinkedGroup = `${prefixLinkedGroup} ${linkedGroup.join(" ")} ${d.path_group
            }`;
        } else {
          targetLinkedGroup = `${linkedGroup.join(" ")} ${d.path_group}`;
        }

        list_buttons.push({
          cell_id: "full_name_disp",
          button: (
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <AvatarRes src={d.avatar} size="sm" sx={{ mr: 1 }} />
              <Typography
                sx={{ flexGrow: 1 }}
                className="IgnoreExtractRuleTarget"
              >
                {d.full_name}
              </Typography>
            </Box>
          ),
          id: d.PK,
        });
        list_table.push({
          id: optionID === "" ? d.SK : d.PK,
          full_name_disp: d.full_name,
          linkedGroup: targetLinkedGroup,
        });
      });
      setListTable(list_table);
      setListButtons(list_buttons);
      let result_message =
        memberListRes.length > 0 ? "" : messages.ManageScreen.MSG_NO_ITEM;
      setMessage(result_message);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // ------------------------------------------------------------------
  // 拠点
  // ------------------------------------------------------------------
  const handleSelectOption = (
    e: SelectChangeEvent<string | number>,
    index: number,
  ) => {
    const value = e.target.value;
    if (index === 0) {
      let targetLocationId = "";
      if (value.toString() !== "defaultValue") {
        targetLocationId = value.toString();
      } else {
        targetLocationId = origin_location_id.current;
      }
      setTargetLocationId(targetLocationId);
    }
    setTheLastSelectedOption({
      masterGroupIndex: index,
      optionId: value.toString(),
    });
  };

  const getSelectedOption = useCallback(
    (index: number) => {
      if (optionsPerGroupMasterList.length === 0) {
        return -1;
      }
      const option =
        optionsPerGroupMasterList[index].options.find(
          (item) => item.isSelected,
        ) || optionsPerGroupMasterList[index].options[0];
      return option.SK;
    },
    [optionsPerGroupMasterList],
  );

  const renderLocationSelect = useMemo(() => {
    const filterArea: any = [];
    if (optionsPerGroupMasterList.length === 0) {
      return filterArea;
    }
    const numberOfMasterGroups = masterGroups.length;
    for (let i = 0; i < numberOfMasterGroups; i++) {
      const item = (
        <SelectLabel
          key={masterGroups[i].SK}
          label={masterGroups[i].group_master_name}
          sx={{ minWidth: 300, mr: 2 }}
          onChange={(e) => {
            handleSelectOption(e, i);
          }}
          value={getSelectedOption(i)}
          disabled={optionsPerGroupMasterList[i].isDisable}
          isIgnoreExtractRuleLabel={true}
          isIgnoreExtractRuleSelect={true}
        >
          {optionsPerGroupMasterList[i].options.map((option) => {
            return (
              <MenuItem
                key={option.SK}
                value={option.SK}
                className={
                  option.SK != "defaultValue"
                    ? "IgnoreExtractRuleTarget"
                    : undefined
                }
              >
                {option.group_name}
              </MenuItem>
            );
          })}
        </SelectLabel>
      );
      filterArea.push(item);
    }
    return filterArea;
  }, [optionsPerGroupMasterList, masterGroups, theLastSelectedOption]);

  const handleGetOptions4GroupMaster = useCallback(async () => {
    const masterGroupIndex = theLastSelectedOption.masterGroupIndex;
    if (masterGroupIndex !== -1) {
      const optionId = theLastSelectedOption.optionId;
      const groupsInfo4MasterGroup = await apiGetChildrenGroup4GroupMaster({
        group_parentid: optionId,
      });
      const clonedOptionsPerGroupMasterList = [...optionsPerGroupMasterList];
      clonedOptionsPerGroupMasterList[masterGroupIndex].options.forEach(
        (item) => {
          if (item.SK === optionId) {
            item.isSelected = true;
          } else {
            item.isSelected = false;
          }
        },
      );
      const numOfMasterGroup = masterGroups.length;
      const groupInfoDefault: Type.GroupInfo = {
        updated_at: "",
        group_master_id: "",
        created_at: "",
        created_by: "",
        group_parentid: "",
        location_id: "",
        SK: "defaultValue",
        PK: "",
        seq: -1,
        group_id: "",
        group_name: "未選択",
        isSelected: false,
      };
      if (masterGroupIndex + 1 < numOfMasterGroup) {
        clonedOptionsPerGroupMasterList[masterGroupIndex + 1].options = [
          groupInfoDefault,
          ...groupsInfo4MasterGroup,
        ];
        clonedOptionsPerGroupMasterList[masterGroupIndex + 1].isDisable =
          optionId === "defaultValue";
      }
      for (let i = masterGroupIndex + 2; i < numOfMasterGroup; i++) {
        clonedOptionsPerGroupMasterList[i].isDisable = true;
        clonedOptionsPerGroupMasterList[i].options = [groupInfoDefault];
      }
      setOptionsPerGroupMasterList(clonedOptionsPerGroupMasterList);
    }
  }, [theLastSelectedOption]);

  useEffect(() => {
    handleGetOptions4GroupMaster();
  }, [handleGetOptions4GroupMaster]);

  // ------------------------------------------------------------------
  // 閉じる
  // ------------------------------------------------------------------
  const handleClose = () => {
    onClose();
  };

  // ------------------------------------------------------------------
  // 選択
  // ------------------------------------------------------------------
  const handleChoose = () => {
    onChoose(listUsers.filter((item) => check_list.includes(item["SK"])));
    handleClose();
  };

  return (
    <Dialog
      maxWidth="lg"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Stack flex={1}>
          <TextField
            label="アカウント名"
            value={keyword}
            sx={{ alignItems: "flex-start" }}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <Box sx={{ whiteSpace: "nowrap", overflowX: "auto" }}>
            {renderLocationSelect}
          </Box>

          <Divider />
          <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
            <Button onClick={fetchData}>検索</Button>
          </Box>
          {listTable.length > 0 && (
            <SortableTable
              rows={listTable}
              headCells={headCells}
              buttons={listButtons}
              idName="id"
              order="asc"
              orderByIdName="full_name"
              isCheckRow
              maxHeight={320}
            />
          )}
          {message != "" && (
            <Typography textAlign={"center"}>{message}</Typography>
          )}
        </Stack>
        <Box sx={{ textAlign: "center", mt: 1 }}>
          <Button onClick={handleChoose} color="secondary">
            選択
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalSearchUserByLocation;
