import React, { VFC, useState, useMemo, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  TextField,
  MenuItem,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { useEffect } from "react";
import { Validation } from "@validation";
import LabelRequired from "components/atoms/LabelRequired";
import SelectLabel from "components/atoms/SelectLabel";
import { cloneDeep } from "lodash";
import CheckboxLabel from "components/atoms/CheckboxLabel";

export interface IMaMasterData {
  nonconformity_detail: string;
  occurance_process: string;
  process_of_root_cause: string;
  responsibility: string;
  responsibility_name: string;
  department_of_pic: string;
  department_of_pic_name: string;
  process_in_logistics: string;
  process_in_logistics_name: string;
  responsibility_in_process: string;
  responsibility_in_process_name: string;
  classification_of_nonconformity: string;
  classification_of_nonconformity_name: string;
  classification_of_cause: string;
  classification_of_cause_name: string;
  is_default: boolean;
}

/* Dropdown項目用Interface */
interface IListData {
  SK: string;
  name: string;
}

interface IProps {
  data: IMaMasterData;
  open: boolean;
  onClose: Function;
  onChange: Function;
  listNonconformityDetail: IListData[];
  listNonconformityOverview: IListData[];
  listOccuranceProcess: IListData[];
  listProcessOfRootCause: IListData[];
  listResponsibility: IListData[];
  listDepartmentOfPic: IListData[];
  listProcessInLogistics: IListData[];
  listResponsibilityInProcess: IListData[];
  listClassificationOfNonconformity: IListData[];
  listClassificationOfCause: IListData[];
}

const TlgtMasterMaEditDialog: VFC<IProps> = ({
  data,
  open,
  onClose,
  onChange,
  listNonconformityDetail,
  listOccuranceProcess,
  listProcessOfRootCause,
  listResponsibility,
  listDepartmentOfPic,
  listProcessInLogistics,
  listResponsibilityInProcess,
  listClassificationOfNonconformity,
  listClassificationOfCause,
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [formError, setFormError] = useState<{
    nonconformity_detail: string;
    occurance_process: string;
    process_of_root_cause: string;
    responsibility: string;
    department_of_pic: string;
    process_in_logistics: string;
    responsibility_in_process: string;
    classification_of_nonconformity: string;
    classification_of_cause: string;
  }>({
    nonconformity_detail: "",
    occurance_process: "",
    process_of_root_cause: "",
    responsibility: "",
    department_of_pic: "",
    process_in_logistics: "",
    responsibility_in_process: "",
    classification_of_nonconformity: "",
    classification_of_cause: "",
  });

  const [disabled, setDisabled] = useState(true);
  const [stateForm, setStateForm] = useState<IMaMasterData>(cloneDeep(data));
  const [originData, setOriginData] = useState<IMaMasterData>(cloneDeep(data));

  useEffect(() => {
    const setInitialData = async () => {
      setDisabled(true);
      setStateForm(data);
      setOriginData(data);
      setFormError({
        nonconformity_detail: "",
        occurance_process: "",
        process_of_root_cause: "",
        responsibility: "",
        department_of_pic: "",
        process_in_logistics: "",
        responsibility_in_process: "",
        classification_of_nonconformity: "",
        classification_of_cause: "",
      });
    };

    if (open) {
      setInitialData();
    }
  }, [open, data]);

  const arrayDataError = useMemo(() => {
    let messages: Array<string> = [];
    Object.values(formError).forEach((errorText) => {
      if (errorText !== "") messages.push(errorText);
    });
    return messages;
  }, [formError]);

  useEffect(() => {
    if (
      Object.values(stateForm).some((d) => d === "") ||
      arrayDataError.length > 0 ||
      JSON.stringify(stateForm) === JSON.stringify(originData)
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [stateForm, arrayDataError]);

  // ------------------------------------------------------------------
  // 入力
  // ------------------------------------------------------------------

  const onChangeText = (field: keyof IMaMasterData) => (newText: string) => {
    let newStateForm = { ...stateForm, [field]: newText };
    setStateForm(newStateForm);
  };

  const onValidateText = (field: keyof IMaMasterData, value?: string) => {
    if (field !== "is_default") {
      const mess = validator(field, value ?? stateForm[field]);
      setFormError({ ...formError, [field]: mess });
    }
  };

  const onChangeSelect =
    (field: string) =>
    (list: any[], e: SelectChangeEvent<string>, field_name: string) => {
      const selected = list.find((item) => item && item.SK === e.target.value);
      setStateForm((prev) => ({
        ...prev,
        [field]: selected[field_name],
      }));
    };

  const renderMenuItem = useCallback(
    (list: Array<any>, value: string, name: string) => {
      return list
        ? list.map((item, index) => (
            <MenuItem value={item ? item[value] : ""} key={index}>
              <span className="IgnoreExtractRuleTarget">{item[name]}</span>
            </MenuItem>
          ))
        : null;
    },
    [],
  );

  const validator = (field: keyof IMaMasterData, value?: string) => {
    let mess: string = "";
    if (!value) value = "";

    switch (field) {
      case "nonconformity_detail":
        mess = Validation.validate({
          type: "text",
          name: "Nonconformity Detail",
          value: value,
          required: true,
          // max_length: 30,
        });
        break;
      case "occurance_process":
        mess = Validation.validate({
          type: "text",
          name: "Occurance Process",
          value: value,
          required: true,
          // max_length: 4,
        });
        break;
      case "process_of_root_cause":
        mess = Validation.validate({
          type: "text",
          name: "Process of Root Cause",
          value: value,
          required: true,
          // max_length: 4,
        });
        break;
      case "responsibility":
        mess = Validation.validate({
          type: "text",
          name: "Responsibility",
          value: value,
          required: true,
        });
        break;
      case "department_of_pic":
        mess = Validation.validate({
          type: "text",
          name: "Department of PIC",
          value: value,
          required: true,
        });
        break;
      case "process_in_logistics":
        mess = Validation.validate({
          type: "text",
          name: "Process in Logistics",
          value: value,
          required: true,
        });
        break;
      case "responsibility_in_process":
        mess = Validation.validate({
          type: "text",
          name: "Responsibility in Process",
          value: value,
          required: true,
        });
        break;
      case "classification_of_nonconformity":
        mess = Validation.validate({
          type: "text",
          name: "Classification of Nonconformity",
          value: value,
          required: true,
        });
        break;
      case "classification_of_cause":
        mess = Validation.validate({
          type: "text",
          name: "Classification of Cause",
          value: value,
          required: true,
        });
        break;
    }
    return mess;
  };

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <Stack>
          <SelectLabel
            label={
              <>
                <LabelRequired title={"Nonconformity Detail"} />
              </>
            }
            value={stateForm.nonconformity_detail ?? ""}
            onChange={(e) => {
              onChangeText("nonconformity_detail")(e.target.value);
              onValidateText("nonconformity_detail", e.target.value);
            }}
          >
            {renderMenuItem(listNonconformityDetail, "SK", "name")}
          </SelectLabel>
          <SelectLabel
            label={
              <>
                <LabelRequired title={"Occurance Process"} />
              </>
            }
            value={stateForm.occurance_process ?? ""}
            onChange={(e) => {
              onChangeText("occurance_process")(e.target.value);
              onValidateText("occurance_process", e.target.value);
            }}
          >
            {renderMenuItem(listOccuranceProcess, "SK", "name")}
          </SelectLabel>

          <SelectLabel
            label={
              <>
                <LabelRequired title={"Process of Root Cause"} />
              </>
            }
            value={stateForm.process_of_root_cause ?? ""}
            onChange={(e) => {
              onChangeText("process_of_root_cause")(e.target.value);
              onValidateText("process_of_root_cause", e.target.value);
            }}
          >
            {renderMenuItem(listProcessOfRootCause, "SK", "name")}
          </SelectLabel>
          <SelectLabel
            label={
              <>
                <LabelRequired title={"Responsibility"} />
              </>
            }
            value={stateForm.responsibility ?? ""}
            onChange={(e) => {
              onChangeText("responsibility")(e.target.value);
              onValidateText("responsibility", e.target.value);
              onChangeSelect("responsibility_name")(
                listResponsibility,
                e,
                "name",
              );
            }}
          >
            {renderMenuItem(listResponsibility, "SK", "name")}
          </SelectLabel>
          <SelectLabel
            label={
              <>
                <LabelRequired title={"Department of PIC"} />
              </>
            }
            value={stateForm.department_of_pic ?? ""}
            onChange={(e) => {
              onChangeText("department_of_pic")(e.target.value);
              onValidateText("department_of_pic", e.target.value);
              onChangeSelect("department_of_pic_name")(
                listDepartmentOfPic,
                e,
                "name",
              );
            }}
          >
            {renderMenuItem(listDepartmentOfPic, "SK", "name")}
          </SelectLabel>
          <SelectLabel
            label={
              <>
                <LabelRequired title={"Process in Logistics"} />
              </>
            }
            value={stateForm.process_in_logistics ?? ""}
            onChange={(e) => {
              onChangeText("process_in_logistics")(e.target.value);
              onValidateText("process_in_logistics", e.target.value);
              onChangeSelect("process_in_logistics_name")(
                listProcessInLogistics,
                e,
                "name",
              );
            }}
          >
            {renderMenuItem(listProcessInLogistics, "SK", "name")}
          </SelectLabel>
          <SelectLabel
            label={
              <>
                <LabelRequired title={"Responsibility in Process"} />
              </>
            }
            value={stateForm.responsibility_in_process ?? ""}
            onChange={(e) => {
              onChangeText("responsibility_in_process")(e.target.value);
              onValidateText("responsibility_in_process", e.target.value);
              onChangeSelect("responsibility_in_process_name")(
                listResponsibilityInProcess,
                e,
                "name",
              );
            }}
          >
            {renderMenuItem(listResponsibilityInProcess, "SK", "name")}
          </SelectLabel>
          <SelectLabel
            label={
              <>
                <LabelRequired title={"Classification of Nonconformity"} />
              </>
            }
            value={stateForm.classification_of_nonconformity ?? ""}
            onChange={(e) => {
              onChangeText("classification_of_nonconformity")(e.target.value);
              onValidateText("classification_of_nonconformity", e.target.value);
              onChangeSelect("classification_of_nonconformity_name")(
                listClassificationOfNonconformity,
                e,
                "name",
              );
            }}
          >
            {renderMenuItem(listClassificationOfNonconformity, "SK", "name")}
          </SelectLabel>
          <SelectLabel
            label={
              <>
                <LabelRequired title={"Classification of Cause"} />
              </>
            }
            value={stateForm.classification_of_cause ?? ""}
            onChange={(e) => {
              onChangeText("classification_of_cause")(e.target.value);
              onValidateText("classification_of_cause", e.target.value);
              onChangeSelect("classification_of_cause_name")(
                listClassificationOfCause,
                e,
                "name",
              );
            }}
          >
            {renderMenuItem(listClassificationOfCause, "SK", "name")}
          </SelectLabel>
          <div>
            <CheckboxLabel
              label="デフォルト値"
              checked={stateForm.is_default}
              onChange={(e) => {
                setStateForm({ ...stateForm, is_default: e.target.checked });
              }}
            />
          </div>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
        >
          キャンセル
        </Button>

        <Button
          onClick={() => {
            onChange(stateForm);
            onClose();
          }}
          disabled={disabled}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TlgtMasterMaEditDialog;
