import React, { useEffect, useMemo, useState } from "react";
import GenericTemplate from "@template/index";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import LabelRequired from "components/atoms/LabelRequired";
import PopupSort from "components/atoms/PopupSort";
import SelectLabel from "components/atoms/SelectLabel";
import ScrollLoader from "components/atoms/ScrollLoader";
import { DevanSchedule } from "services/models";
import DevanReception from "./DevanReception";
import {
  DEVAN_SEARCH_TYPE,
  LIST_TYPE_SORT_DEVAN_SCHEDULE,
} from "@shared-constants";
import messages from "config/messages";
import DevanItem from "../DevanItem";
import ModalController from "@shared-components/modal/ModalController";
import {
  getDevanPlanCount,
  getDevanReceptionConditionList,
  searchDevanPlan,
} from "@api/devan";
import { RootState } from "store/reducer";
import { TYPES } from "store/types";
import { getSortOfScreen } from "selector/sortSelector";
import { useSize } from "shared/hook/useSize";
import formatDateToString from "@utils/DateFormat";
import { Validation } from "@validation";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";

type DevanSearchFormType = {
  date_of_delivery: string; //搬入年月日
  delivery_location: string; //搬入場所
  container_no: string; //コンテナ番号
  berth_number: string; //バース番号
  search_type: string; //受付状態
};

const initialSelectList = ["未設定"];

const originData: DevanSearchFormType = {
  date_of_delivery: "",
  delivery_location: initialSelectList[0],
  container_no: "",
  berth_number: "",
  search_type: "",
};

type SearchType = "DEVANIST" | "RECEPTIONIST" | "accepted" | "not_accepted";

const searchType = {
  DEVANIST: "accepted", //DEVANISTのデフォルト・受付済み
  RECEPTIONIST: "", //RECEPTIONISTのデフォルト・全て
  accepted: "accepted", //受付済み
  not_accepted: "not_accepted", //未受付
};

const DevanSearchScreen = () => {
  const [data, setData] = useState<Array<DevanSchedule>>([]);
  const [selectedDevanData, setSelectedDevanData] = useState<
    DevanSchedule | undefined
  >(undefined);
  const [dataCount, setDataCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [lastKey, setLastKey] = useState<any | null>(null);
  const [openDevanSearch, setOpenDevanSearch] = useState<boolean>(false);
  const [listOptionContainerNo, setListOptionContainerNo] = useState<any[]>([]);
  const [listDeliveryLocation, setListDeliveryLocation] =
    useState<string[]>(initialSelectList);
  const [stateForm, setStateForm] = useState<DevanSearchFormType>(
    cloneDeep(originData),
  );
  const [searchedForm, setSearchedForm] = useState<
    DevanSearchFormType | undefined
  >();
  const [formError, setFormError] = useState<DevanSearchFormType>(
    cloneDeep(originData),
  );
  const [inputValue, setInputValue] = useState<string>("");
  const { isMdUp } = useSize();
  const [dateConditionList, setDateConditionList] = useState<string>("");
  const devanSearch = useSelector((state: RootState) => state.devanSearch);
  const defaultSearchType: SearchType =
    DEVAN_SEARCH_TYPE.RECEPTIONIST as SearchType;
  const typeScreen = "devan_search";
  const typeSort = useSelector(getSortOfScreen(typeScreen));
  const dispatch = useDispatch();

  // 初期化 =====================================
  useEffect(() => {
    const data: DevanSearchFormType = {
      date_of_delivery: devanSearch.date_of_delivery,
      delivery_location: devanSearch.delivery_location || initialSelectList[0],
      container_no: devanSearch.container_no || "",
      berth_number: devanSearch.berth_number,
      search_type: searchType[defaultSearchType] ?? "",
    };
    setStateForm(data);
    setInputValue(devanSearch.container_no || "")
  }, [devanSearch, searchType]);

  // 検索条件 =====================================
  useEffect(() => {
    const getReceptionConditionList = async () => {
      await getDevanReceptionConditionList({
        date_of_delivery: stateForm.date_of_delivery,
      })
        .then((res: any) => {
          if (res) {
            const list = [...initialSelectList];
            setListOptionContainerNo(
              res.container_no.map((item: any) => ({
                label: item,
                value: item,
              })),
            );
            setListDeliveryLocation(list.concat(res.delivery_location));
          }
        })
        .catch((error: any) => {
          ModalController.show({
            message: error?.response?.detail.message,
            visibleButton2: true,
          });
        });
    };
    if (
      stateForm.date_of_delivery &&
      formError.date_of_delivery.length == 0 &&
      stateForm.date_of_delivery != dateConditionList
    ) {
      getReceptionConditionList();
      setDateConditionList(stateForm.date_of_delivery);
      if (dateConditionList) {
        setStateForm({
          ...stateForm,
          delivery_location: initialSelectList[0],
          container_no: "",
        });
      }
    }
  }, [
    stateForm.date_of_delivery,
    formError.date_of_delivery,
    dateConditionList,
  ]);

  // 検索 =====================================
  const searchHandle = async (sortBy?: string) => {
    LoadingOverlayController.show();
    setLoading(true);
    const formData = {
      date_of_delivery: stateForm.date_of_delivery,
      delivery_location:
        stateForm.delivery_location === listDeliveryLocation[0]
          ? ""
          : stateForm.delivery_location,
      container_no:
        stateForm.container_no === initialSelectList[0]
          ? ""
          : stateForm.container_no,
      berth_number: stateForm.berth_number,
      search_type: stateForm.search_type,
      sort_by: sortBy ?? typeSort,
      last_key: "",
    };
    setData([]);
    setSearchedForm({ ...stateForm });
    let response = await getDevanPlanCount(formData).catch((error: any) => {
      ModalController.show({
        message: error?.response?.detail.message,
        visibleButton2: true,
      });
      setLoading(false);
      LoadingOverlayController.hide();
    });
    setDataCount(response?.data);
    if (response?.data) {
      setTimeout(() => {
        searchDevan(formData);
      }, 500);
    } else {
      setLoading(false);
      LoadingOverlayController.hide();
    }
  };

  const searchDevan = async (formData: any) => {
    await searchDevanPlan(formData)
      .then((res: any) => {
        setData((prevValue) => [...prevValue, ...res?.data]);
        if (res?.data) {
          if (res?.last_key) {
            setLastKey(res?.last_key ? res?.last_key : "");
            setLoadMore(true);
          } else {
            setLastKey(null);
            setLoadMore(false);
          }
        }
      })
      .catch((error: any) => {
        ModalController.show({
          message: error?.response?.detail.message,
          visibleButton2: true,
        });
      })
      .finally(() => {
        setLoading(false);
        LoadingOverlayController.hide();
      });
  };

  const handleSort = (type_sort: string) => {
    const payload = {
      type_screen: typeScreen,
      value: type_sort,
    };
    dispatch({ type: TYPES.SET_SORT_SCREEN, payload });
    dispatch({ type: TYPES.SET_SORT_CONDITION, payload });
    searchHandle(type_sort);
  };

  const sortDefaultIndex = useMemo(
    () => {
      const defaultSort = Object.values(LIST_TYPE_SORT_DEVAN_SCHEDULE).filter(
        (item) => item.value === typeSort,
      );
      if (defaultSort.length === 0) {
        return 0;
      } else {
        return Object.values(LIST_TYPE_SORT_DEVAN_SCHEDULE).indexOf(
          defaultSort[0],
        );
      }
    },
    [] /* 画面初期表示時のみ判定 */,
  );

  const showLoadMore = useMemo(() => {
    return loadMore && lastKey && data.length > 0;
  }, [data, loadMore, lastKey]);

  const handleLoadMore = () => {
    setLoading(true);
    const formData = {
      date_of_delivery: searchedForm?.date_of_delivery,
      delivery_location:
        searchedForm?.delivery_location === initialSelectList[0]
          ? ""
          : searchedForm?.delivery_location,
      container_no:
        searchedForm?.container_no === initialSelectList[0]
          ? ""
          : searchedForm?.container_no,
      berth_number: searchedForm?.berth_number,
      search_type: searchedForm?.search_type,
      sort_by: typeSort,
      last_key: lastKey,
    };
    setSearchedForm({ ...stateForm });
    setTimeout(() => {
      searchDevan(formData);
    }, 500);
  };

  // 入力 =====================================
  const onChangeDate =
    (field: keyof DevanSearchFormType) => (value: string | null) => {
      if (!value) {
        value = "";
      } else {
        value = formatDateToString(value, "YMD");
      }

      let mess = "";
      if (value) {
        mess = validator(field, value);
      }
      setFormError({ ...formError, [field]: mess });
      setStateForm((prev) => ({ ...prev, [field]: value }));
    };

  const validator = (field: keyof DevanSearchFormType, value: string) => {
    let mess: string = "";
    switch (field) {
      case "date_of_delivery":
        mess = Validation.validateDate(value, "搬入年月日", true);
        break;
    }
    return mess;
  };

  // 閉じる =====================================
  const handleClose = () => {
    const formData = {
      date_of_delivery: stateForm.date_of_delivery,
      delivery_location: stateForm.delivery_location,
      container_no: stateForm.container_no,
      berth_number: stateForm.berth_number,
    };
    dispatch({ type: TYPES.SET_DEVAN_SEARCH, payload: formData });
    dispatch({
      type: TYPES.SET_DEVAN_SEARCH_CONDITION,
      payload: formData,
    });

    setOpenDevanSearch(true);
  };

  return (
    <GenericTemplate title="デバン検索">
      <Stack>
        <DevanReception
          open={openDevanSearch}
          onClose={() => {
            setOpenDevanSearch(false);
          }}
          data={selectedDevanData}
          searchHandle={searchHandle}
        />
        <Accordion
          disableGutters
          sx={{ borderRadius: 1, ":before": { height: 0 } }}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            検索条件
          </AccordionSummary>
          <AccordionDetails>
            <Grid container columnSpacing={3} rowSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth={isMdUp ? false : true}>
                  <InputLabel>
                    <LabelRequired title="搬入年月日" />
                  </InputLabel>
                  <DatePickerCustom
                    value={stateForm.date_of_delivery}
                    onChange={onChangeDate("date_of_delivery")}
                    error={formError.date_of_delivery.length > 0}
                    helperText={formError.date_of_delivery}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  component={"div"}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <SelectLabel
                    label="搬入場所"
                    value={stateForm.delivery_location}
                    onChange={(e) => {
                      setStateForm({
                        ...stateForm,
                        delivery_location: e.target.value,
                      });
                    }}
                  >
                    {listDeliveryLocation.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
                  </SelectLabel>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Autocomplete
                    disablePortal
                    id="container_no"
                    options={listOptionContainerNo}
                    inputValue={inputValue}
                    onChange={(e, newValue) => {
                      setInputValue(newValue?.value ?? "");
                      setStateForm({
                        ...stateForm,
                        container_no: newValue?.value ?? "",
                      });
                    }}
                    onInputChange={(e, newValue) => {
                      setInputValue(newValue);
                      setStateForm({
                        ...stateForm,
                        container_no: newValue,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="コンテナ番号" />
                    )}
                    freeSolo
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="バース番号"
                  value={stateForm.berth_number}
                  inputProps={{
                    maxLength: 5,
                  }}
                  onChange={(e) => {
                    setStateForm({
                      ...stateForm,
                      berth_number: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <InputLabel>受付状態</InputLabel>
                  <RadioGroup
                    name="search-type-radio-group"
                    value={stateForm.search_type}
                    row
                    onChange={(_, value) => {
                      setStateForm({
                        ...stateForm,
                        search_type: value,
                      });
                    }}
                  >
                    <FormControlLabel
                      value={""}
                      label="全て"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value={searchType.accepted}
                      label="受付済み"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value={searchType.not_accepted}
                      label="未受付"
                      control={<Radio />}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Box sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => searchHandle()}
            disabled={
              !stateForm.date_of_delivery ||
              formError.date_of_delivery.length > 0
            }
          >
            検索
          </Button>
        </Box>

        <Box
          sx={{
            mt: 1,
            display: !loading && data.length > 0 ? "flex" : "none",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <PopupSort
            data={Object.values(LIST_TYPE_SORT_DEVAN_SCHEDULE).map((item) => {
              return {
                title: item.title,
                onClick: () => {
                  handleSort(item.value);
                },
              };
            })}
            defaultIndex={sortDefaultIndex}
          />
          <Typography variant="body2">{dataCount + "件"}</Typography>
        </Box>
        <InfiniteScroll
          loadMore={() => {
            if (loadMore && lastKey && data.length > 0 && !loading) {
              handleLoadMore();
            }
          }}
          hasMore={showLoadMore}
          useWindow={true}
          initialLoad={false}
          loader={<ScrollLoader key={0} />}
        >
          <Grid container spacing={3} sx={{ pt: 2, pb: 5 }}>
            {data.map((d: DevanSchedule, i) => {
              return (
                <Grid item xs={12} md={4} lg={4} key={i}>
                  <DevanItem
                    data={d}
                    onClick={() => {
                      setSelectedDevanData(d);
                      handleClose();
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </InfiniteScroll>
        {data.length === 0 && !loading && searchedForm && (
          <Typography>{messages.COMMON.MSG_NOT_EXIST("検索結果")}</Typography>
        )}
      </Stack>
    </GenericTemplate>
  );
};

export default DevanSearchScreen;
