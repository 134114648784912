import React, { VFC, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { Validation } from "@validation";
import LabelRequired from "components/atoms/LabelRequired";

export interface IData {
  name: string;
}

interface IProps {
  data: IData;
  open: boolean;
  onClose: Function;
  onChange: Function;
}

const FuelEditDialog: VFC<IProps> = ({
  data,
  open,
  onClose,
  onChange,
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [name, setName] = useState("");
  const [errorText, setErrorText] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const setInitialData = async () => {
      setName(data.name);
      setErrorText("");
      setDisabled(true);
    };

    if (open) {
      setInitialData();
    }
  }, [open, data]);

  // ------------------------------------------------------------------
  // 入力
  // ------------------------------------------------------------------
  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const validName = () => {
    const error_text = Validation.validate({
      type: "text",
      name: "燃料・車種名",
      value: name,
      required: true,
      max_length: 20,
    });
    setErrorText(error_text);
    setDisabled(error_text.length > 0 || data.name == name);
  };

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <FormGroup>
          <TextField
            label={
              <>
                <LabelRequired title={"燃料・車種名"} />
              </>
            }
            value={name}
            onChange={changeName}
            onBlur={validName}
            error={errorText.length > 0}
            helperText={errorText}
            inputProps={{
              maxLength: 20,
            }}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
        >
          キャンセル
        </Button>

        <Button
          onClick={() => {
            const response: IData = {
              name: name,
            };
            onChange(response);
            onClose();
          }}
          disabled={disabled}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FuelEditDialog;
