import { useEffect, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
  Button,
  Typography,
  MenuItem,
} from "@mui/material";
import {
  DraggableProvided,
  DroppableProvided,
  DropResult,
  Draggable,
  Droppable,
  DragDropContext,
} from "react-beautiful-dnd";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import { sort, getUserInfo } from "@utils/index";
import VehiclesEditDialog, { IData } from "../VehiclesEdit/VehiclesEditDialog";
import {
  getListMaster,
  updateMasterSort,
  deleteMaster,
  insertVehicles,
  updateVehicles,
  getFuelAll,
} from "@api/itemReport";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import { LIST_FUEL, USER_ROLES } from "@shared-constants";
import SelectLabel from "components/atoms/SelectLabel";
import { getListActivityBase } from "@api/groupMaster";

const typeScreen = "vehicles";
const vehicles = "車両";
const vehicles_name = "車両名";
const initial_data: IData = {
  name: "",
  frame_no: "",
  model: "",
  workplace_id: "",
  workplace: "",
  fuel_type_id: "",
  fuel_type: "",
};

const VehiclesEditScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [data, setData] = useState<any[]>([]);
  const [originData, setOriginData] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [editData, setEditData] = useState<IData>(initial_data);
  const [activityBaseId, setActivityBaseId] = useState<string>("");
  const [listWorkplace, setListWorkplace] = useState<any[]>([]);
  const [listFuel, setListFuel] = useState<any[]>(LIST_FUEL);
  const [userRole, setUserRole] = useState<string>("");
  const [selectDisabled, setSelectDisabled] = useState<boolean>(true);
  const [listGroup, setListGroup] = useState([]);

  const handleDnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const sorted = sort(data, result.source.index, result.destination.index);
    setData(sorted);
  };

  const handleAdd = () => {
    setEditData(initial_data);
    setOpen(true);
  };

  const handleDelete = (SK: string) => {
    if (!ModalController.isShowing()) {
      const confirmMessage = disabled
        ? messages.COMMON.MSG_COMMON_DELETE_CONFIRM_001
        : messages.COMMON.MSG_COMMON_DELETE_CONFIRM_SORT_001;
      ModalController.show({
        message: confirmMessage,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => handleDeleteVehicles(SK),
      });
    }
    return;
  };

  const handleDeleteVehicles = async (SK: string) => {
    try {
      LoadingOverlayController.show();
      0;
      const res = await deleteMaster(SK, typeScreen, activityBaseId);
      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_DELETE_SUCCESS_001(vehicles),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData(activityBaseId);
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleDeleteVehicles", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleEdit = (d: any, i: number) => {
    setEditData({
      name: d.vehicles_name ?? "",
      frame_no: d.vehicles_frame_no ?? "",
      model: d.vehicles_model ?? "",
      workplace_id: d.vehicles_workplace_id ?? "",
      workplace: d.vehicles_workplace ?? "",
      fuel_type_id: d.vehicles_fuel_type_id ?? "",
      fuel_type: d.vehicles_fuel_type ?? "",
    });
    setEditIndex(i);
    if (!ModalController.isShowing() && !disabled) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_UPDATE_CONFIRM_SORT_001,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => {
          setOpen(true);
        },
      });
    } else {
      setOpen(true);
    }
  };

  const handleChange = async (value: IData) => {
    let newData = JSON.parse(JSON.stringify(data));
    let message = "";
    let res = null;
    const formData = {
      name: value.name,
      frame_no: value.frame_no,
      model: value.model,
      workplace_id: value.workplace_id,
      workplace: value.workplace,
      fuel_type_id: value.fuel_type_id,
      fuel_type: value.fuel_type,
      activity_base_id: activityBaseId,
      is_default: false,
      is_manual_input: false,
    };
    try {
      LoadingOverlayController.show();
      if (editIndex >= 0) {
        res = await updateVehicles(formData, newData[editIndex].SK);
        if (res) {
          message = vehicles_name + "の更新";
        }
      } else {
        res = await insertVehicles(formData);
        if (res) {
          message = vehicles_name + "の登録";
        }
      }
      setEditIndex(-1);
      if (res) {
        setData(newData);
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001(message),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData(activityBaseId);
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleUpdateAccount", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setEditIndex(-1);
  };

  const handleSave = async () => {
    try {
      LoadingOverlayController.show();
      let formData: any[] = [];
      data.map((item) => {
        const detailData = {
          PK: item.PK,
          SK: item.SK,
        };
        formData.push(detailData);
      });
      const res = await updateMasterSort(typeScreen, formData);
      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001(
            vehicles + "の並び順の更新",
          ),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData(activityBaseId);
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleSave", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    getListGroup();
  }, []);

  const fetchData = async (activity_base_id: string) => {
    LoadingOverlayController.show();
    await getListMaster(typeScreen, activity_base_id)
      .then((res) => {
        if (res?.data) {
          setData(res.data);
          setOriginData(res.data);
        }
      })
      .finally(() => LoadingOverlayController.hide());
  };

  const getListGroup = async () => {
    const user_info = getUserInfo();
    setUserRole(user_info.user_role);
    if (user_info.user_role == USER_ROLES.OWNER.value) {
      await getListActivityBase()
        .then((res) => {
          if (res) {
            setListGroup(res);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setActivityBaseId(user_info.location_id);
      setSelectDisabled(false);
      fetchData(user_info.location_id);
      getWorkplace(user_info.location_id);
      getListFuel();
    }
  };

  const fetchDataConfirm = async () => {
    if (!ModalController.isShowing() && !activityBaseId) {
      ModalController.show({
        message: messages.COMMON.ERROR.MSG_RQUIRED_SELECT("拠点"),
        visibleButton2: true,
      });
    }

    if (!ModalController.isShowing() && !disabled) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_DISPLAY_CONFIRM_SORT_001,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => {
          fetchData(activityBaseId);
          getWorkplace(activityBaseId);
          getListFuel();
          setData([]);
          setOriginData([]);
          setSelectDisabled(true);
        },
      });
    }
    if (disabled) {
      if (selectDisabled) {
        if (activityBaseId) {
          setSelectDisabled(false);
          fetchData(activityBaseId);
          getWorkplace(activityBaseId);
          getListFuel();
        }
      } else {
        setData([]);
        setOriginData([]);
        setSelectDisabled(true);
      }
    }
  };
  const getWorkplace = async (activity_base_id: string) => {
    await getListMaster("workplace", activity_base_id).then((res) => {
      if (res?.data) {
        setListWorkplace(res.data);
      }
    });
  };

  const getListFuel = async () => {
    await getFuelAll().then((res) => {
      if (res?.data) {
        setListFuel(res.data.length > 0 ? res.data : LIST_FUEL);
      }
    });
  };

  useEffect(() => {
    setDisabled(JSON.stringify(data) === JSON.stringify(originData));
  }, [data, originData]);

  return (
    <GenericTemplate title="マスタ管理">
      <Typography variant="h6">{vehicles}</Typography>

      <VehiclesEditDialog
        data={editData}
        open={open}
        onChange={handleChange}
        onClose={handleCancel}
        listWorkplace={listWorkplace}
        listFuel={listFuel}
      />

      {userRole == USER_ROLES.OWNER.value && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: 3,
              mb: 3,
            }}
          >
            <SelectLabel
              label="拠点"
              value={activityBaseId}
              isIgnoreExtractRuleLabel={false}
              isIgnoreExtractRuleSelect={true}
              onChange={(e) => setActivityBaseId(e.target.value)}
              sx={{ width: 500 }}
              disabled={!selectDisabled}
            >
              {listGroup.map((item: any) => (
                <MenuItem value={item.SK} key={item.SK}>
                  <span className="IgnoreExtractRuleTarget">
                    {item.activity_base_name}
                  </span>
                </MenuItem>
              ))}
            </SelectLabel>
            <Button
              onClick={fetchDataConfirm}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 3,
                ml: 2,
              }}
            >
              {selectDisabled ? "選択" : "解除"}
            </Button>
          </Box>
        </>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant="body2">ドラッグ＆ドロップで並べ替え</Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Button onClick={handleAdd} endIcon={<AddCircle />}>
          追加
        </Button>
      </Box>
      <Box>
        <DragDropContext onDragEnd={handleDnd}>
          <Droppable droppableId="droppableId-1">
            {(provided: DroppableProvided) => (
              <List ref={provided.innerRef} {...provided.droppableProps}>
                {data.map((item, i) => (
                  <Draggable key={item.SK} draggableId={item.SK} index={i}>
                    {(provided: DraggableProvided) => (
                      <ListItem
                        sx={{ bgcolor: "white" }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        secondaryAction={
                          <>
                            <Tooltip title="編集">
                              <IconButton
                                edge="end"
                                sx={{ mx: 1 }}
                                onClick={() => handleEdit(item, i)}
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="削除">
                              <IconButton
                                edge="end"
                                onClick={() => handleDelete(item.SK)}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                        divider
                      >
                        <span className="IgnoreExtractRuleTarget">
                          <ListItemText
                            primary={item.vehicles_name}
                            primaryTypographyProps={{
                              style: {
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                marginRight: "40px",
                              },
                            }}
                          />
                        </span>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box sx={{ height: 30 }} />
      <Box
        sx={{
          position: "fixed",
          margin: 0,
          right: 0,
          bottom: 20,
          textAlign: "center",
          width: "100%",
        }}
      >
        <Button onClick={handleSave} color="secondary" disabled={disabled}>
          保存
        </Button>
      </Box>
    </GenericTemplate>
  );
};

export default VehiclesEditScreen;
