import {
  METHOD_API,
  createConnector,
  prefixApi,
  getParamsHelp,
} from "./Connector";
import { IStateForm } from "screens/DevanList";

export interface IDataDevanPlan {
  date_of_delivery: string;
  container_no: string;
  product_class: string;
  model_name: string;
  number_of_moves: string;
  container_size: string;
  delivery_time: string;
  carrier_name: string;
  unit_class: string;
  delivery_location: string;
  invoice_no: string;
  seal_no: string;
}

interface DevanPlanKey {
  PK: string;
  SK: string;
}

interface IUpdateDataDevanPlan extends IDataDevanPlan, DevanPlanKey {}

// 登録
export const createDevanPlan = (dataBody: any) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/create-devan-schedule`,
    dataBody,
  );
};

// 範囲検索
export const getDateRangeDevanPlan = (dataBody: IStateForm) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/get-date-devan-plan`,
    dataBody,
  );
};

// デバン予定更新
export const updateDevanPlan = (dataBody: IUpdateDataDevanPlan) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/update-devan-plan`,
    dataBody,
  );
};

//デバン予定取得(PK)
export const getDevanPlan = (PK: string, SK: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-devan-plan/${encodeURIComponent(PK)}/${encodeURIComponent(
      SK,
    )}`,
    null,
  );
};

//デバン予定削除
export const deleteDevanSchedule = (dataBody: DevanPlanKey[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/delete-devan-schedule`,
    dataBody,
  );
};

// デバン表存在確認
export const getExistDevanPlan = (dataBody: any) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/check-devan-schedule-exist?`,
    dataBody,
  );
};
export enum ExistDevanPlanResultCode {
  NONE = 0,
  EXISTS = 1,
  EXISTS_DELIVERED = 2,
}

//コンテナIDごとにデータを取得
export const getDevanByContainer = (dataBody: any) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-devan-by-container?${getParamsHelp(dataBody)}`,
    null,
  );
};

// 機種検索
export const getDavanByContainerApi = (dataBody: any) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-devan-by-container?${getParamsHelp(dataBody)}`,
    null,
  );
};

// 検索
export const searchDevanPlan = (dataBody: any) => {
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/search-devan-plan`,
    dataBody,
  );
};

// デバン件数取得API
export const getDevanPlanCount = (dataBody: any) => {
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/get-devan-plan-count`,
    dataBody,
  );
};

// デバン検索条件取得API
export const getDevanReceptionConditionList = (dataBody: any) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-devan-reception-condition-list?${getParamsHelp(
      dataBody,
    )}`,
    null,
  );
};

// デバンの搬入状態を取得
export const getDevanIsDelivered = (dataBody: any) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-devan-is-delivered?${getParamsHelp(dataBody)}`,
    null,
  );
};

// デバン受付更新API
export const updateDevanReception = (dataBody: any) => {
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/update-devan-reception`,
    dataBody,
  );
};
