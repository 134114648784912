import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Colors } from "@template/style";
import {
  useState,
} from "react";

interface IAccordionSection {
  title: React.ReactNode;
  children?: React.ReactNode;
  defaultExpanded?: boolean;
  isIgnoreExtractRule?: boolean;
}
const AccordionSection = (
  { title, children, defaultExpanded = false, isIgnoreExtractRule = false }: IAccordionSection
) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  const handleAccordionToggle = () => {
    setExpanded(!expanded);
  };

  const ignoreExtractRuleClass = isIgnoreExtractRule
  ? "IgnoreExtractRuleTarget"
  : "";
  return (
    <Accordion disableGutters sx={{ boxShadow: "none" }} expanded={expanded} onChange={handleAccordionToggle}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          bgcolor: Colors.MAIN_GREEN_LIGHT,
          ":focus-visible": { bgcolor: Colors.MAIN_GREEN_LIGHT },
        }}
      >
        <Typography variant="h6" sx={{ wordBreak: "break-all" }} className={ignoreExtractRuleClass}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionSection;
