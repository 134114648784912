import { VFC, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    Box,
    Button,
    Container,
    CssBaseline,
    FormControl,
    FormLabel,
    TextField,
    Stack,
    Select,
    MenuItem,
} from "@mui/material";
import logoImg from "assets/logo.png";
import PasswordInput from "components/atoms/PasswordInput";
import { TYPES } from "store/types";
import { useSize } from "shared/hook/useSize";
import { theme } from "@template/style"; // TLOG
import LabelRequired from "components/atoms/LabelRequired";
import { getContractInfoListApi } from "@api/contract";

const ContractLogin: VFC = () => {
    // ------------------------------------------------------------------
    // 初期化
    // ------------------------------------------------------------------
    const [contractLoginId, setContractLoginId] = useState<any>({});
    const [listContractInfo, setListContractInfo] = useState<any[]>([]);
    const [password, setPassword] = useState<string>("");
    const dispatch = useDispatch();
    const { isSmUp } = useSize();
    const [disableBtn, setDisableBtn] = useState<boolean>(true);

    const theme_label: any = theme.components?.MuiInputLabel?.styleOverrides;
    const styles = {
        label: {
            minWidth: 130,
            width: isSmUp ? 130 : "100%",
            alignSelf: isSmUp ? "center" : "flex-start",
            paddingRight: 1,
            mb: isSmUp ? undefined : theme_label.formControl.marginBottom,
        },
    } as const;

    // ------------------------------------------------------------------
    // データ取得
    // ------------------------------------------------------------------

    useEffect(() => {
        getListContractInfo();
    }, []);

    const addWhiteSpace = (data: Array<any>) => {
        if (data.length == 0) {
            return data;
        }
        data.splice(0, 0, undefined);
        return data;
    };

    const getListContractInfo = async () => {
        try {
            const res = await getContractInfoListApi();
            if (res.data.length > 0) {
                const contractInfo = addWhiteSpace(res.data);
                setListContractInfo(contractInfo);
                return contractInfo;
            } else {
                return [];
            }
        } catch (e) {
            console.log(e);
            return [];
        }
    };

    // ------------------------------------------------------------------
    // ログイン
    // ------------------------------------------------------------------
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch({
            type: TYPES.CONTRACT_LOGIN,
            payload: {
                contract_login_id: contractLoginId,
                contract_password: password,
            },
        });
    };

    // ------------------------------------------------------------------
    // ボタン制御
    // ------------------------------------------------------------------
    useEffect(() => {
        if (contractLoginId && password) {
            setDisableBtn(false);
        } else {
            setDisableBtn(true);
        }
    }, [contractLoginId, password]);

    return (
        <>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box
                        component="form"
                        action="post"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            mt: 1,
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        onSubmit={handleSubmit}
                    >
                        <img src={logoImg} alt="logo" height="252px" width="441px" />
                        <FormControl fullWidth margin="normal">
                            <Stack direction={isSmUp ? "row" : "column"} spacing={0}>
                                <FormLabel sx={styles.label}>
                                    <LabelRequired title={"契約名"} />
                                </FormLabel>
                                {/* <TextField
                                    id="contractLoginId"
                                    name="contractLoginId"
                                    type="text"
                                    fullWidth
                                    onChange={(e) => setContractLoginId(e.target.value)}
                                    inputProps={{ maxLength: 20 }}
                                /> */}
                                <Select
                                    fullWidth
                                    autoFocus
                                    value={contractLoginId}
                                    onChange={(e) => setContractLoginId(e.target.value)}
                                >
                                    {listContractInfo.map((item, index) => (
                                        <MenuItem
                                            value={item?.contract_login_id}
                                            key={item?.contract_login_id + index}
                                        >
                                            <span className="IgnoreExtractRuleTarget">
                                                {item?.contract_name}
                                            </span>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <Stack direction={isSmUp ? "row" : "column"} spacing={0}>
                                <FormLabel sx={styles.label}>
                                    <LabelRequired title={"パスワード"} />
                                </FormLabel>
                                <PasswordInput
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    label=""
                                    fullWidth={true}
                                />
                            </Stack>
                        </FormControl>
                        <Button
                            disabled={disableBtn}
                            type="submit"
                            sx={{ mt: 3, mb: 2, py: 2, px: 5 }}
                        >
                            決定
                        </Button>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default ContractLogin;