import React, { VFC, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  TextField,
  Stack,
} from "@mui/material";
import { cloneDeep } from "lodash";
import { Validation } from "@validation";
import LabelRequired from "components/atoms/LabelRequired";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import ListCancelBadge from "components/molecules/ListCancelBadge";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import { checkMasterTypeExist } from "@api/masterType";

export interface IData {
  name: string;
  keyitem: boolean;
  custom_item: Array<ICustomItem>;
  SK?: string;
}

interface ICustomItem {
  name: string;
  barcode: boolean;
  required: boolean;
  keyitem: boolean;
}

interface IProps {
  data: IData;
  open: boolean;
  handleButtonOK: Function;
  onClose: Function;
  activityBaseId: string;
}

const CustomMasterEditDialog: VFC<IProps> = ({
  data,
  open,
  handleButtonOK,
  onClose,
  activityBaseId,
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [name, setName] = useState("");
  const [keyitem, setKeyitem] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [listCustomItem, setListCustomItem] = useState<Array<ICustomItem>>([]);
  const [errorCustomItem, setErrorCustomItem] = useState<
    Array<{ name: string }>
  >([]);
  const [updateFlg, setUpdateFlg] = useState(false);

  useEffect(() => {
    const setInitialData = async () => {
      setName(data.name);
      setKeyitem(data.keyitem);
      setListCustomItem(data.custom_item || []);
      setErrorText("");
      setErrorCustomItem(
        data.custom_item ? data.custom_item.map(() => ({ name: "" })) : [],
      );
      setDisabled(true);
      setUpdateFlg(data.name != "");
    };
    if (open) {
      setInitialData();
    }
  }, [open, data]);

  // ------------------------------------------------------------------
  // 入力
  // ------------------------------------------------------------------
  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const validName = () => {
    const error_text = Validation.validate({
      type: "text",
      name: "マスタ名",
      value: name,
      required: true,
      max_length: 30,
    });
    setErrorText(error_text);
  };

  const changeCustomItem = (
    field: keyof ICustomItem,
    index: number,
    value: any,
  ) => {
    setListCustomItem((prev) => {
      var new_value = cloneDeep(prev);
      new_value[index] = { ...new_value[index], [field]: value };
      return new_value;
    });
  };

  const validCustomItemName = (index: number) => () => {
    const error_text = Validation.validate({
      type: "text",
      name: "項目名",
      value: listCustomItem[index]["name"],
      required: true,
      max_length: 30,
    });
    var newErrorCustomItem = cloneDeep(errorCustomItem);
    newErrorCustomItem[index] = {
      ...newErrorCustomItem[index],
      name: error_text,
    };
    setErrorCustomItem(newErrorCustomItem);
  };

  useEffect(() => {
    if (updateFlg) {
      // 更新
      setDisabled(
        errorText.length > 0 ||
          errorCustomItem.some((value) => value.name != "") ||
          (name === data.name &&
            !listCustomItem.some((value, index) => {
              const name_text =
                data.custom_item.length > index
                  ? data.custom_item[index].name
                  : "";
              return value.name !== name_text;
            })),
      );
    } else {
      // 追加
      setDisabled(
        errorText.length > 0 ||
          name === data.name ||
          errorCustomItem.some((value) => value.name != "") ||
          listCustomItem.some((value, index) => {
            const name_text =
              data.custom_item.length > index
                ? data.custom_item[index].name
                : "";
            return value.name == name_text;
          }),
      );
    }
  }, [errorText, name, errorCustomItem, listCustomItem, data, updateFlg]);

  const handleAdd = () => {
    var newListCustomItem = cloneDeep(listCustomItem);
    newListCustomItem.push({
      name: "",
      barcode: false,
      required: false,
      keyitem: false,
    });
    var newErrorCustomItem = cloneDeep(errorCustomItem);
    newErrorCustomItem.push({ name: "" });
    setListCustomItem(newListCustomItem);
    setErrorCustomItem(newErrorCustomItem);
  };

  const handleDelelte = (index: number) => {
    var newListCustomItem = cloneDeep(listCustomItem);
    newListCustomItem.splice(index, 1);
    var newErrorCustomItem = cloneDeep(errorCustomItem);
    newErrorCustomItem.splice(index, 1);
    setListCustomItem(newListCustomItem);
    setErrorCustomItem(newErrorCustomItem);
  };

  const onClickOK = async () => {
    // マスタ名の重複チェック
    let params = {
      master_type_name: name,
      activity_base_id: activityBaseId,
      master_type_id: "",
    };
    if (data.SK) {
      params["master_type_id"] = data.SK;
    }
    const master_type_exist = await checkMasterTypeExist(params);
    if (master_type_exist) {
      ModalController.show({
        message: messages.COMMON.ERROR.MSG_EXISTING("マスタ名"),
        visibleButton2: true,
      });
      return;
    }
    // バーコード検索項目の件数チェック
    const count_barcode = listCustomItem.filter(
      (value) => value.barcode == true,
    ).length;
    if (count_barcode > 1) {
      ModalController.show({
        message: messages.MASTER.MSG_ERROR_BARCODE_LIMIT_1,
        visibleButton2: true,
      });
      return;
    }
    // キー項目の件数チェック
    const count_keyitem = listCustomItem.filter(
      (value) => value.keyitem == true,
    ).length;
    if (count_keyitem > 1 || (count_keyitem == 1 && keyitem)) {
      ModalController.show({
        message: messages.MASTER.MSG_ERROR_KEYITEM_LIMIT_1,
        visibleButton2: true,
      });
      return;
    }
    const response: IData = {
      name: name,
      keyitem: keyitem,
      custom_item: listCustomItem,
    };
    handleButtonOK(response);
    onClose();
  };

  const renderListItem = (value: ICustomItem, index: number) => {
    return (
      <Stack sx={{ width: "100%", mt: 1 }} spacing={1}>
        <TextField
          label={<LabelRequired title={"項目名"} />}
          value={value.name}
          onChange={(e) => {
            changeCustomItem("name", index, e.target.value);
          }}
          onBlur={validCustomItemName(index)}
          error={errorCustomItem[index]["name"].length > 0}
          helperText={errorCustomItem[index]["name"]}
          inputProps={{
            maxLength: 30,
          }}
        />
        <FormGroup sx={{ flexDirection: "row" }}>
          <CheckboxLabel
            label="キー項目"
            checked={value.keyitem}
            onChange={(_, checked) => {
              changeCustomItem("keyitem", index, checked);
              if (checked) changeCustomItem("required", index, checked);
            }}
            disabled={updateFlg}
          />
          <CheckboxLabel
            label="バーコード検索する"
            checked={value.barcode}
            onChange={(_, checked) => {
              changeCustomItem("barcode", index, checked);
              if (checked) changeCustomItem("required", index, checked);
            }}
            disabled={updateFlg}
          />
          <CheckboxLabel
            label="必須"
            checked={value.required}
            onChange={(_, checked) => {
              changeCustomItem("required", index, checked);
            }}
            disabled={updateFlg}
          />
        </FormGroup>
      </Stack>
    );
  };

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <Stack>
          <TextField
            label={<LabelRequired title={"マスタ名"} />}
            name="master_name"
            value={name}
            onChange={changeName}
            onBlur={validName}
            error={errorText.length > 0}
            helperText={errorText}
            inputProps={{
              maxLength: 30,
            }}
          />
          <TextField label="項目名" value="名称" disabled />
          <FormGroup>
            <CheckboxLabel
              label="キー項目"
              checked={keyitem}
              onChange={(_, checked) => {
                setKeyitem(checked);
              }}
              disabled={updateFlg}
            />
          </FormGroup>
          <ListCancelBadge
            data={listCustomItem}
            renderItem={renderListItem}
            handleCancel={handleDelelte}
            cancelBtnDisabled={updateFlg}
            addBtnTitle="項目追加"
            handleAdd={handleAdd}
            addBtnDisabled={updateFlg}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
        >
          キャンセル
        </Button>

        <Button onClick={onClickOK} disabled={disabled}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomMasterEditDialog;
